import moment from 'moment';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import BackIcon from '../../assets/images/back-btn-arrow.png';

function ResultsTopCard({ globalSearchDetail, personalDetails, getEntityCertificate }) {
    const navigate = useNavigate();
    const entityClientId = personalDetails?.entity_client_id;

    const navigateToPrevious = () => {
        console.log(entityClientId, 'entityClientId');
        navigate('/individual_screening/individual_screening_details', {
            state: {
                activeTab: '5',
                entityClientId
            }
        });
        window.location.reload();
    };

    return (
        <>
        <button onClick={navigateToPrevious} className='back-button text-blue'><img src={BackIcon} style={{width:8,marginBottom:2,marginRight:4,objectFit:'contain'}}/> Back</button>
        <div className='white-background p-3'>
             <div className='d-flex align-items-center justify-content-between'>
                    <h1 className='text-blue px-2 fw-normal'>{globalSearchDetail?.name}</h1>
                    <h4 className='text-blue dark-blue-small-bg fw-normal' onClick={() => getEntityCertificate(entityClientId)}>View Entity Certificate</h4>
                </div>
            <hr />

            <div className='row'>
                <div className='col-lg-4 mt-2'>
                    <div className='d-flex align-items-center'>
                        <h4 className='text-black fw-bold'>Created At:</h4>
                        <h4 className='text-grey mx-3'>{personalDetails?.create_search_details?.created_at ? moment(personalDetails?.create_search_details?.created_at).format('MMM DD, YYYY h:mm A') : ''}</h4>
                    </div>
                </div>
                <div className='col-lg-4 mt-2'>
                    <div className='d-flex align-items-center'>
                        <h4 className='text-black fw-bold'>Search Ref:</h4>
                        <h4 className='text-grey mx-3'>{personalDetails?.create_search_details?.ref ??" "}</h4>
                    </div>
                </div>
                <div className='col-lg-4 mt-2'>
                    <div className='d-flex align-items-center'>
                        <h4 className='text-black fw-bold'>Search Term:</h4>
                        <h4 className='text-grey mx-3'>{personalDetails?.create_search_details?.search_term || ''}</h4>
                    </div>
                </div>
                <div className='col-lg-4 mt-2'>
                    <div className='d-flex align-items-center'>
                        <h4 className='text-black fw-bold'>Entity ID:</h4>
                        <h4 className='text-grey mx-3'>{globalSearchDetail?.unique_id}</h4>
                    </div>
                </div>
                <div className='col-lg-4 mt-2'>
                    <div className='d-flex align-items-center'>
                        <h4 className='text-black fw-bold'>Fuzziness Interval:</h4>
                        <h4 className='text-grey mx-3'>{(personalDetails?.create_search_details?.filters?.fuzziness)*100 ??" "}%</h4>
                    </div>
                </div>
            </div>

            <hr />

            <div className='d-flex align-items-center' style={{gap:15}}>
                {
                    globalSearchDetail?.search_results?.doc?.types.map((item, i) =>
                        <div className='danger-light-background' key={i}>
                            <h5 className='text-danger'>{item}</h5>
                        </div>
                    )
                }
            </div>
        </div>
        </>
    )
}

export default ResultsTopCard
