import React,{useEffect} from 'react'
import { useLocation } from 'react-router-dom';

const OomeroUtils = {

    ScrollToTop(){
        const { pathname } = useLocation();
      
        useEffect(() => {
          // "document.documentElement.scrollTo" is the magic for React Router Dom v6
          document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
          });
        }, [pathname]);
      
        return null;
      },

  getUnmatchedKeyValuePairs(obj1, obj2) {
    const differingPairs = {};
    
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
          const value1 = obj1[key];
          const value2 = obj2[key];

          // Convert the values to the same type for comparison
          if (typeof value1 !== typeof value2) {
              // Assuming you want to convert everything to strings for comparison
              if (typeof value1 === 'number') {
                  obj1[key] = value1.toString();
              } else if (typeof value2 === 'number') {
                  obj2[key] = value2.toString();
              }
          }

          if (obj1[key] !== obj2[key]) {
              differingPairs[key] = obj1[key];
          }
      }
  }

    return differingPairs;
  }
    
}

export default OomeroUtils
