import React, { useEffect, useState } from 'react'
import Logo from '../../../assets/images/logo.png'
import WelcomeLogo from '../../../assets/images/welcome_logo.png'
import { useNavigate, useLocation } from 'react-router-dom';
import { decryptQueryParam } from '../../../utils/EncryptionUtils';
import './WebForm.scss'
import { toast } from 'react-toastify';
const WelcomeScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [decryptedValue, setDecryptedValue] = useState(null);
    const [token, setToken] = useState(null);

        const routeChange = (urltoken, selectedCountry, entityId, isOomero) => {
            let path = `${(window.innerWidth >= 500) ? "/webform/individual_personal_details" : "/individual_onboarding_web_permission"}`;
            navigate(path, { state: { countryId: selectedCountry, entityId, isOomero, webformToken: urltoken} });
        } 
   
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('token')) {
            const decryptValue = async () => {
                try {
                    const decrypted = await decryptQueryParam(searchParams.get('token'));
                    setDecryptedValue(decrypted);
                    setToken(searchParams.get('token'));
                } catch (error) {
                    toast.error("Invalid Token");
                }
            };
            decryptValue();
        } else {
            toast.error("Token not found")
        }
    }, [location]);

    return (
        <div style={{ overflowX: 'hidden' }} className='webform-css'>
            <div className='white-background py-2 position-fixed w-100' style={{ borderRadius: 0 }}>
                <div className="d-flex justify-content-center">
                    <img src={Logo} style={{ objectFit: 'contain', width: 150 }} />
                </div>
            </div>
            {(decryptedValue &&  decryptedValue?.userId) ? (
                <div>
                    <div className='row justify-content-center align-items-center h-100'>
                        <div className='col-6'>
                            <div className="d-flex justify-content-center mt-5">
                                <img src={WelcomeLogo} className='welcome-img' />
                            </div>
                            <div className="d-flex justify-content-center mt-4">
                                <div className='text-blue-header'>Welcome {decryptedValue?.firstName} {decryptedValue?.lastName}</div>
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                                <div className='text-blue-description'>You are about to kickstart the onboarding process for Individual Client</div>
                            </div>
                            <div className="d-flex justify-content-center mt-4">
                                <button onClick={()=>routeChange(token, decryptedValue.countryId, decryptedValue.entityId, decryptedValue?.isOomero)} className='btn-primary w-100'>Proceed</button>
                            </div>
                        </div>
                    </div>
                </div>) : (
                <div className='text-center' style={{ height: '90vh' }}>
                    <div className='row justify-content-center align-items-center h-100'>
                        Invalid URL
                    </div>
                </div>
            )}
        </div>
    )
}

export default WelcomeScreen;
