import React from 'react'
import ScreenActionIcon from '../../../assets/images/screeing_action.png'
import CommentIcon from '../../../assets/images/comment_icon.png'

function ScreenInfo() {
    return (
        <div>
            <div className='d-flex align-items-center mt-3'>
                <img src={ScreenActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                <h2 className='text-ambed text-center'>Screening Action</h2>
            </div>
            <hr />
            <div className='row'>
                <div className='col-lg-4'>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label class="form-check-label" for="flexCheckDefault">
                            Whitelist this customer for all future TXNs
                        </label>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label class="form-check-label" for="flexCheckDefault">
                            Blacklist this customer for all future TXNs
                        </label>
                    </div>
                </div>
            </div>
            <div className='col-lg-3'>
                <div className="form-group mt-3">
                    <label className="form-label" for="exampleFormControlSelect1">Risk Status</label>
                    <select id="statusDropdown" className="form-control form-select">
                        <option selected disabled value="">Select Risk Status</option>
                        <option>Green</option>
                        <option>Ambed</option>
                        <option>Red</option>
                    </select>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='row align-items-end'>
                        <div className='col-lg-7'>
                            <div className="form-group mt-3">
                                <label className="form-label" for="exampleFormControlSelect1">Upload Document</label>
                                <select id="statusDropdown" className="form-control form-select">
                                    <option selected disabled value="">Select Document</option>
                                    <option>Png</option>
                                    <option>Pdf</option>
                                    <option>Excel</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <button className='btn-primary fw-normal'>Choose a File</button>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='row align-items-end'>
                        <div className='col-lg-6'>
                            <div className="form-group mt-3">
                                <label className="form-label" for="exampleFormControlSelect1">Request Document</label>
                                <select id="statusDropdown" className="form-control form-select">
                                    <option selected disabled value="">Select Document</option>
                                    <option>Png</option>
                                    <option>Pdf</option>
                                    <option>Excel</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <button className='btn-primary fw-normal'>Request Document</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='row align-items-end'>
                        <div className='col-lg-9' style={{ width: '82%' }}>
                            <div className="form-group mt-3">
                                <label className="form-label" for="exampleInputEmail1">Add Comment</label>
                                <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Add Comment" rows="6" />
                            </div>
                        </div>
                        <div className='col-lg-3' style={{ width: '18%' }}>
                            <button className='btn-grey fw-normal'>Add Comment</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-center my-4 pb-3'>
                <button className='btn-primary fw-normal'>Send to Checker</button>
            </div>

            <hr />
            <div className='comments pb-3'>
                <div className='d-flex align-items-center'>
                    <img src={CommentIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                    <h2 className='text-blue text-center'>Comments History</h2>
                </div>
                <hr />
                <div className='white-background mt-2 p-3' style={{ marginBottom: 20 }}>
                    <h5 className='text-blue'>21-02-2022  02:26:19</h5>
                    <h5 className='text-black'>Moving From Maker 1 to Checker 1 Test Case Amber</h5>
                    <h5 className='text-blue text-end'>- maker 1 maker 1</h5>
                </div>
                <div className='white-background mt-2 p-3' style={{ marginBottom: 20 }}>
                    <h5 className='text-blue'>21-02-2022  02:26:19</h5>
                    <h5 className='text-black'>Moving From Maker 1 to Checker 1 Test Case Amber</h5>
                    <h5 className='text-blue text-end'>- maker 1 maker 1</h5>
                </div>
            </div>
        </div>
    )
}

export default ScreenInfo
