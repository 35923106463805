import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    isLoading: false,
    error: null,
    currencyList : [],
    fxRatesList : {rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200},
    fxRateDetail : {}, 
    fxRateHistoryList : [],
    qrData : ''
}

export const generateGoogleAuthenticatorQR = createAsyncThunk("user/generateGoogleAuthenticatorQR", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.generateGoogleAuthenticatorQR
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const manageGoogleAuthenticator = createAsyncThunk("user/manageGoogleAuthenticator", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.manageGoogleAuthenticator,
            data : requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

const MyAccountSlice = createSlice({
    name: "myaccount",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(generateGoogleAuthenticatorQR.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.qrData = payload.data;
            })
            .addCase(manageGoogleAuthenticator.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addMatcher(isAnyOf(generateGoogleAuthenticatorQR.pending, manageGoogleAuthenticator.pending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(generateGoogleAuthenticatorQR.rejected, manageGoogleAuthenticator.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = (error) ? error.message : "Request Failed Please Try Again ";
            })
    }
});

const myAccountReducer = MyAccountSlice.reducer;
export default myAccountReducer;