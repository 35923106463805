import React, { useState, useEffect } from 'react'
import '../Profile.scss';
import OnboardClientBar from '../../../components/OnboardClientBar/OnboardClientBar';
import BlackInfoIcon from '../../../assets/images/black _Info Circle_.png'
import CircleBlueTick from '../../../assets/images/circle_blue_tick.png'
import ApproveTick from '../../../assets/images/Approve_tick.png'
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from "../../../assets/images/logo.png"

function IndividualAppicationDone() {
    let navigate = useNavigate();
    const { state } = useLocation();
    const [oomeroId, setOomeroId] = useState(null);
    const [show, setShow] = useState(false);
    const [showAddressStep, setShowAddressStep] = useState(false);
    const routeChange = () => {
        // let path = `/create_profile`;
        let path = `${window.innerWidth<=500 ? "/webform_thank_you" : "/create_profile"}`;
        navigate(path);
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        if (state?.oomeroId) {
            setOomeroId(state?.oomeroId);
        }else{
            navigate('/profile/individual_kyc_verification', { state: { entityClientId: state?.entityClientId, countryId: state?.countryId} }); 
        }
    }, [])

    return (
      <>
        <div className='white-background py-2 position-fixed w-100 ' style={{ borderRadius: 0 }}>
            <div className="d-flex justify-content-center">
              <img src={Logo} style={{ objectFit: 'contain', width: 150 }} />
          </div>
        </div>
      
        <div className='grey-background webform-css'>
            <OnboardClientBar
                mainTitle={`Onboard Individual Client`}
                title1={`PERSONAL DETAILS`}
                title2={`ADDRESS`}
                title3={`BANK DETAILS`}
                title4={`KYC Verification`}
                backgroundColor1={'#037AD0'}
                backgroundColor2={'#037AD0'}
                backgroundColor3={'#037AD0'}
                backgroundColor4={'#037AD0'}
            />
            <div className='row mt-4'>
                <div className='col-lg-4'>
                <div className={window.innerWidth <= 500 ? '':'white-background  p-3'}>
                <div className='text-black mb-3 hide-in-desktop'>
                    <h1 className='fw-normal'>KYC Verification Process</h1>
                </div>
              <div className='text-blue mb-3 hide-in-mobile'>
                <h1 className='fw-normal'>KYC Verification</h1>
              </div>
              <hr className='hide-in-mobile' />
              <div className={window.innerWidth <= 500 ? 'd-flex justify-content-center align-items-center':''}>
              <div className={window.innerWidth <= 500 ? 'white-background  consistent-width me-2 p-2':'row'}>
                <div className={window.innerWidth <= 500 ? 'text-center':'col-2'}>
                  <img src={CircleBlueTick} style={{ width: 20, objectFit: 'contain' }} />
                </div>
                <div className='col-10'>
                  <div className={`d-flex align-items-start justify-content-between ${window.innerWidth <= 500 ? 'mt-2' : ''}`}>
                    <div className='text-black-bold text-center'>Photo Proof</div>
                    <img onClick={handleShow} src={BlackInfoIcon} className='blackInfoIcon-css' />
                  </div>
                  <h4 className='text-black fw-normal hide-in-mobile'>A Government issued document which has your photo on it is required such as a Driving Licence, Passport or National ID</h4>
                </div>
              </div>
              {/* <div className={`row ${showAddressStep ? '' : 'opacity-25'} mt-3`}> */}
              <div className={window.innerWidth <= 500 ? `white-background  consistent-width p-2 me-2`: `row`}>
                <div className={window.innerWidth <= 500 ? 'text-center':'col-2'}>
                  <img src={CircleBlueTick} style={{ width: 20, objectFit: 'contain' }} />
                </div>
                <div className='col-10'>
                  <div className={`d-flex align-items-start justify-content-between ${window.innerWidth <= 500 ? 'mt-2' : ''}`}>
                    <div className='text-black-bold text-center'>Proof Of Address</div>
                    <img src={BlackInfoIcon} className='blackInfoIcon-css' />
                  </div>
                  <h4 className='text-black fw-normal hide-in-mobile'>A document which confirms your residentioal address in your country such as a Utility Bill or Bank Statement </h4>
                </div>
              </div>
              <div className={window.innerWidth <= 500 ? 'white-background consistent-width p-2':'row'}>
                <div className={window.innerWidth <= 500 ? 'text-center':'col-2'}>
                  <img src={CircleBlueTick} style={{ width: 20, objectFit: 'contain' }} />
                </div>
                <div className='col-10'>
                  <div className={`d-flex align-items-start justify-content-between ${window.innerWidth <= 500 ? 'mt-2' : ''}`}>
                    <div className='text-black-bold text-center'>Application Done</div>
                    <img src={BlackInfoIcon} className='blackInfoIcon-css' />
                  </div>
                  <h4 className='text-black fw-normal hide-in-mobile'>Your application has been successfully submitted</h4>
                </div>
              </div>
              </div>
            </div>
                </div>
                <div className={window.innerWidth <= 500 ? 'col-lg-8 mt-3 webform-css' : 'col-lg-8'}>
                    <div className='white-background white-background-resposive p-3'>
                        <div className='text-blue mb-3'>
                            <h1 className='fw-normal hide-in-mobile'>Application Done</h1>
                        </div>
                        <hr className='hide-in-mobile' />
                        <div className='d-flex align-items-center flex-column'>
                            <img src={ApproveTick} style={{ width: 90, objectFit: 'contain', marginTop: 20 }} />
                            <h1 className='text-blue text-center fw-normal mt-4'>Application has been successfully
                                <br />submitted for processing</h1>
                            <p className='text-black text-center fw-bold mt-4 border-dash' style={{ fontSize: 36 }}>
                                oomero ID<br />
                                {oomeroId}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-flex align-items-center justify-content-end my-4 hide-in-mobile'>
                <div className='d-flex align-items-center'>
                    <button onClick={routeChange} className='btn-primary'>Continue</button>
                </div>
            </div>
            <div className='my-4 hide-in-desktop'>
              <button onClick={routeChange} className='btn-primary w-100'>Save</button>
            </div>
        </div>
        </>
    )
}

export default IndividualAppicationDone
