import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm, useWatch } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import '../Profile.scss';
import OnboardClientBar from '../../../components/OnboardClientBar/OnboardClientBar';
import CurrentAddress from '../../../assets/images/current_address.png'
import CorporateAddress from '../../../assets/images/corpporate_address.png'
import PreviousAddress from '../../../assets/images/previous_address.png'
import AddBlueButton from '../../../assets/images/Add_blue_button.png'
import UploadCircleIcon from '../../../assets/images/upload-circle-icon.png'
import { countryList } from '../../Registration/registrationSlice';
import Loader from '../../../components/Loader/Loader';
import Logo from "../../../assets/images/logo.png"
import { saveIndividualAddress, fetchEntityClientAddress, resetAutoSearchAddress, autoSearchAddress, resetPrevAutoSearchAddress, autoPrevSearchAddress } from '../profileSlice';
import { decryptQueryParam } from '../../../utils/EncryptionUtils';
import AmlCheckInfo from '../../../assets/images/aml_check_inro.png'
import Select from 'react-select'
import moment from 'moment';
import DatePicker from 'react-date-picker';

function IndividualAddress() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const [lessThan3Year, setLessThan3Year] = useState(false);
    const [entityClientId, setEntityClientId] = useState(false);
    const { isLoading: countryLoading, countryData } = useSelector(state => state.registerReducer);
    const { isLoading, autoSearchAddressData, autoPrevSearchAddressData } = useSelector(state => state.ProfileReducer);
    const [renderPreviousData, setRenderPreviousData] = useState(false);
    const [decryptedValue, setDecryptedValue] = useState(null);
    const containerRef = useRef(null);

    const handleBlur = () => {
        // setTimeout(() => setShowSuggestions(false), 200); // To allow click on suggestions
        dispatch(resetAutoSearchAddress());
        dispatch(resetPrevAutoSearchAddress());
    };

    const routeChange = () => {
        let path = `/profile/individual_bank_details`;
        navigate(path,);
    }
    const optionalCountryCodes = [
        14, 21, 33, 53, 56, 57, 58, 70, 75, 76, 82, 85, 98, 104, 107, 120, 126, 127,
        135, 154, 175, 176, 180, 200, 201, 207, 213
      ];
      const isOptionalCountry = (countryId) => optionalCountryCodes.includes(countryId)

    const goBack = () => {
        navigate((state?.webformToken) ? '/webform/individual_personal_details' : '/profile/individual_personal_details', { state: { entityClientId: state?.entityClientId, countryId: state?.countryId, webformToken: state?.webformToken, entityId: state?.entityId, isOomero : state?.isOomero } }); // This navigates back to the previous page
    };

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        // Pad month and day with leading zeros if needed
        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }

        return `${year}-${month}-${day}`;
    };

    // Function to calculate total days for all objects in the array
    const calculateTotalDaysForArray = (dataArray) => {
        let totalDays = 0;
        dataArray.forEach(async (item) => {
            const start = new Date(item.date_from);
            const end = new Date(item.date_to);
            const diffInMilliseconds = Math.abs(end - start);
            const days = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
            totalDays += days;
        });

        if (totalDays < 1095) {
            return true;
        } else {
            return false
        }

    }
    useEffect(() => {
        if (state?.webformToken) {
            const decryptValue = async () => {
                try {
                    const decrypted = await decryptQueryParam(state.webformToken);
                    setDecryptedValue(decrypted);
                } catch (error) {
                    console.log("Invalid Token");
                }
            };
            decryptValue();
        }

        dispatch(countryList());
        if (state?.entityClientId) {
            setEntityClientId(state?.entityClientId);
            dispatch(fetchEntityClientAddress({ queryParam: `?entity_client_id=${state?.entityClientId}`, webformToken: state?.webformToken })).unwrap().then(async (res) => {
                const dataArray = res?.data;
                const [currentAddress] = dataArray.filter((res) => res.type === 'current');
                const [previousAddress] = dataArray.filter((res) => res.type === 'previous');
                const ls3Year = await calculateTotalDaysForArray(dataArray);
                (ls3Year && dataArray.length > 0) ? setLessThan3Year(true) : setLessThan3Year(false);
                if (currentAddress) {
                    setRenderPreviousData(true);
                    setValue('currentAddress.type', currentAddress.type);
                    setValue('currentAddress.country', currentAddress.country);
                    setValue('currentAddress.zip', currentAddress.zip);
                    setValue('currentAddress.date_from', currentAddress.date_from);
                    setValue('currentAddress.date_to', currentAddress.date_to);
                    setValue('currentAddress.address', currentAddress.address);
                    setValue('currentAddress.state', currentAddress.state);
                    setValue('currentAddress.city', currentAddress.city);
                    setValue('currentAddress.address_type', currentAddress.address_type);
                }
                if (previousAddress) {
                    setRenderPreviousData(true);
                    setValue('previousAddress.type', previousAddress.type);
                    setValue('previousAddress.country', previousAddress.country);
                    setValue('previousAddress.zip', previousAddress.zip);
                    setValue('previousAddress.date_from', previousAddress.date_from);
                    setValue('previousAddress.date_to', previousAddress.date_to);
                    setValue('previousAddress.state', previousAddress.state);
                    setValue('previousAddress.address', previousAddress.address);
                    setValue('previousAddress.city', previousAddress.city);
                    setValue('previousAddress.address_type', previousAddress.address_type);
                }

            }).catch((error) => {
                toast.error(error?.message);
            })
        } else {
            navigate('/profile/individual_personal_details');
        }
    }, [])

    const addressSchema = yup.object().shape({
        currentAddress: yup.object().shape({
            type: yup.string().default('current'),
            country: yup.number().typeError('Invalid country').required('Country is required'),
            zip: yup.string().required('Zip Code is required'),
            date_from: yup.string().required('Date from is required'),
            date_to: yup.string().required('Date to is required'),
            address: yup.string().required('Address is required'),
            state:(isOptionalCountry(state.countryId))? yup.string().required('State is required'):yup.string().notRequired(),
            city: yup.string().required('City is required'),
            address_type: yup.string().required('Address type is required')
        }),
        previousAddress: (lessThan3Year) ? yup.object().shape({
            type: yup.string().default('previous'),
            country: yup.number().typeError('Invalid country').required('country is required'),
            zip: yup.string().required('Zip code is required'),
            date_from: yup.string().required('Date from is required'),
            date_to: yup.string().required('Date to is required'),
            address: yup.string().required('Address is required'),
            state:(isOptionalCountry(state.countryId))? yup.string().required('State is required'):yup.string().notRequired(),
            city: yup.string().required('City is required'),
            address_type: yup.string().required('Address type is required'),
        }) : yup.object().shape({}),
    });
    const { control, register, handleSubmit, formState: { errors }, reset, setValue, trigger, watch } = useForm({
        resolver: yupResolver(addressSchema),
        mode: 'onChange'
    });
    const currentAddress = watch('currentAddress.address');
    const country = watch('currentAddress.country');
    const prevAddress = watch('previousAddress.address');
    const prevCountry = watch('previousAddress.country');

    const handleKeyDown = () => {
        dispatch(resetAutoSearchAddress());
        if(currentAddress && country){
            const countrycode = countryData.find((obj)=> obj.country_id == country).iso_codes_2_digit;
            dispatch(autoSearchAddress({queryParam : `?countryCode=${countrycode}&searchText=${currentAddress}`, webformToken: state?.webformToken})).unwrap().catch((error) => {
                toast.error(error?.message);
            });
        }
    };

    const handlePreviousKeyDown = () => {
        dispatch(resetPrevAutoSearchAddress());
        if(prevAddress && prevCountry){
            const countrycode = countryData.find((obj)=> obj.country_id == prevCountry).iso_codes_2_digit;
            dispatch(autoPrevSearchAddress({queryParam : `?countryCode=${countrycode}&searchText=${prevAddress}`, webformToken: state?.webformToken})).unwrap().catch((error) => {
                toast.error(error?.message);
            });
        }
    }

    const selectedAddress = (address) => {
        setValue('currentAddress.address', address);
        trigger('currentAddress.address');
        dispatch(resetAutoSearchAddress());
    }

    const selectedPrevAddress = (address) => {
        setValue('previousAddress.address', address);
        trigger('previousAddress.address');
        dispatch(resetPrevAutoSearchAddress());
    }

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            dispatch(resetAutoSearchAddress());
            dispatch(resetPrevAutoSearchAddress());
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    

    const onSelectFromDate = async (currentDateFrom) => {
        const currentDate = await getCurrentDate();
        const isPreviousAddressRequired = await checkPreviousAddressRequired(currentDateFrom, currentDate);
        addressSchema.fields.currentAddress = addressSchema.fields.currentAddress.shape({ date_from: yup.string().required('Date from is required') });
        if (isPreviousAddressRequired) {
            setLessThan3Year(true);
            // Update validation for previous address fields
            addressSchema.fields.previousAddress = addressSchema.fields.previousAddress
                .shape({
                    country: yup.number().typeError('Invalid country').required('country is required'),
                    zip: yup.string().required('Zip code is required'),
                    date_from: yup.string().required('Date from is required'),
                    date_to: yup.string().required('Date to is required'),
                    address: yup.string().required('Address is required'),
                    state:(isOptionalCountry(state.countryId))? yup.string().required('State is required'):yup.string().notRequired(),
                    city: yup.string().required('City is required'),
                    address_type: yup.string().required('Address type is required'),
                })
                .required();
                setValue('previousAddress.date_to', currentDateFrom);
            trigger(['previousAddress.country', 'previousAddress.zip', 'previousAddress.date_from', 'previousAddress.date_to', 'previousAddress.address', 'previousAddress.state', 'previousAddress.city', 'previousAddress.address_type']);
        } else {
            setLessThan3Year(false);
            // // Reset validation for previous address fields
            addressSchema.fields.previousAddress = yup.object().shape({});
            setValue('previousAddress.country', null);
            setValue('previousAddress.zip', null);
            setValue('previousAddress.date_from', '');
            setValue('previousAddress.date_to', '');
            setValue('previousAddress.address', '');
            setValue('previousAddress.state', '');
            setValue('previousAddress.city', '');
            setValue('previousAddress.address_type', '');
            trigger(['previousAddress.country', 'previousAddress.zip', 'previousAddress.date_from', 'previousAddress.date_to', 'previousAddress.address', 'previousAddress.state', 'previousAddress.city', 'previousAddress.address_type']);
        }
    };

    const checkPreviousAddressRequired = (fromDate, toDate) => {
        // Your logic to determine if previous address is required based on dates
        // For example, if the difference between toDate and fromDate is less than 3 years
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);
        const threeYearsInMilliseconds = 3 * 365 * 24 * 60 * 60 * 1000;
        return toDateObj - fromDateObj < threeYearsInMilliseconds;
    };

    const onSubmit = async (formData) => {
        let requestData = [];
        requestData.push({ ...formData.currentAddress, entity_client_id: entityClientId });
        const retunVal = await calculateTotalDaysForArray([formData.currentAddress]);
        if (retunVal) {
            const retunNewVal = await calculateTotalDaysForArray([formData.currentAddress, formData.previousAddress]);
            if (retunNewVal) {
                addressSchema.fields.previousAddress = addressSchema.fields.previousAddress
                    .shape({
                        country: yup.number().typeError('Invalid country').required('country is required'),
                        zip: yup.string().required('Zip code is required'),
                        date_from: yup.string().required('Date from is required'),
                        date_to: yup.string().required('Date to is required'),
                        address: yup.string().required('Address is required'),
                        state:(isOptionalCountry(state.countryId))? yup.string().required('State is required'):yup.string().notRequired(),
                        city: yup.string().required('City is required'),
                        address_type: yup.string().required('Address type is required'),
                    })
                    .required();
                trigger(['previousAddress.country', 'previousAddress.zip', 'previousAddress.date_from', 'previousAddress.date_to', 'previousAddress.address', 'previousAddress.state', 'previousAddress.city', 'previousAddress.address_type']);
                toast.error("The Total Address Duration should be greater than or equal to 3 years")
                return;
            } else {
                requestData.push({ ...formData.previousAddress, entity_client_id: entityClientId });
            }
        }

        dispatch(saveIndividualAddress({ requestBody: requestData, entityClientId, webformToken: state?.webformToken })).unwrap().then((res) => {
            toast.success(res?.message);
            let path = (state?.webformToken) ? '/webform/individual_bank_details' : `/profile/individual_bank_details`;
            navigate(path, { state: { entityClientId: (entityClientId) ? entityClientId : res?.data?.entityClientId, countryId: state?.countryId, webformToken: state?.webformToken, entityId: state?.entityId, isOomero: state?.isOomero ,address : formData.currentAddress?.address } });
        }).catch((error) => {
            toast.error(error?.message);
        })
    };
    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
            boxShadow: 'none',
            '&:hover': {
                border: '2px solid #B3B3B3',
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#B3B3B3',
        }),
    };
    return (
        <>
            {decryptedValue &&
                <div className='white-background py-2 position-fixed w-100' style={{ borderRadius: 0 }}>
                    <div className="d-flex justify-content-center">
                        <img src={Logo} style={{ objectFit: 'contain', width: 150 }} />
                    </div>
                </div>
            }
            <div className='grey-background  webform-css'>
                <div className=' mt-5 mb-3 p-3 w-100 hide-background hide-in-desktop'>
                    <p className='text-blue fw-normal'>Hi <b>{decryptedValue?.email}</b> ,<br></br> below is the webform to onboard individual client</p>
                </div>
                <div className={window.innerWidth <= 500 ? 'mt-4' : 'mt-1'}></div>
                {isLoading || countryLoading && <Loader />}
                <OnboardClientBar
                    mainTitle={`Onboard Individual Client`}
                    title1={`PERSONAL DETAILS`}
                    title2={`ADDRESS`}
                    title3={`BANK DETAILS`}
                    title4={`KYC Verification`}
                    backgroundColor1={'#037AD0'}
                    backgroundColor2={'#037AD0'} />

                <div className='d-flex align-items-center hide-in-desktop mt-4'>
                    <img src={CorporateAddress} style={{ width: 35, objectFit: 'contain', marginRight: 10 }} />
                    <h1 className='text-ambed fw-normal'>Current Residential Address </h1>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 d-flex'>
                            <div className='white-background p-3 mt-4'>
                                <div className='d-flex align-items-center hide-in-mobile'>
                                    <img src={CurrentAddress} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                                    <div>
                                        <h1 className='text-blue fw-normal'>Current Residential Address</h1>
                                        <div className='d-flex align-items-center'>
                                            <img src={AmlCheckInfo} style={{ width: 18, objectFit: 'contain', marginRight: 5 }} />
                                            <div className='text-black' style={{ fontSize: 14 }}>Please note we require 3 years of address history.</div>
                                        </div>
                                    </div>
                                </div>
                                <hr className='hide-in-mobile' />
                                <div className='form'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className={window.innerWidth <= 500 ? "form-group" : "form-group"}>
                                                <label className="form-label">Country</label>
                                                <Controller
                                                    name="currentAddress.country"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            id="currentAddress.country"
                                                            placeholder="Select Country of Residency"
                                                            className={`${errors.currentAddress?.country ? 'is-invalid' : ''}`}
                                                            options={countryData.map(country => ({
                                                                label: country.name,
                                                                value: country.country_id
                                                            }))}
                                                            styles={customStyles}
                                                            value={field.value ? { label: countryData.find(country => country.country_id == field.value)?.name, value: field.value } : ''}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption?.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors?.currentAddress?.country && (
                                                    <div className="invalid-feedback">{errors?.currentAddress?.country?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12' style={window.innerWidth >= 500 ? {} : { width: '50%' }}>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >Date From</label>
                                                <Controller
                                                    name="currentAddress.date_from"
                                                    control={control}
                                                    render={({ field }) => (
                                                        // <input
                                                        //     type="date"
                                                        //     className={`form-control ${errors?.currentAddress?.date_from ? 'is-invalid' : ''}`}
                                                        //     value={value || ''}
                                                        //     onChange={(e) => {
                                                        //         const selectedDate = e.target.value;
                                                        //         onChange(selectedDate);
                                                        //         onSelectFromDate(e.target.value);
                                                        //     }}
                                                        //     onBlur={onBlur}
                                                        // />
                                                        <DatePicker
                                                            {...field}
                                                            className={`form-control form-control-lg custom-datepicker ${errors?.currentAddress?.date_from ? 'is-invalid' : ''}`}
                                                            onChange={(date) => {
                                                                const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                field.onChange(formattedDate);
                                                                onSelectFromDate(formattedDate);
                                                            }}
                                                            value={field.value}
                                                            maxDate={new Date()}
                                                            clearIcon
                                                            format='dd-MM-yyyy'
                                                            yearPlaceholder='yyyy'
                                                            monthPlaceholder='mm'
                                                            dayPlaceholder='dd'
                                                        />
                                                    )}
                                                />
                                                {errors?.currentAddress?.date_from && (
                                                    <div className="invalid-feedback">{errors?.currentAddress?.date_from?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6' style={window.innerWidth >= 500 ? {} : { width: '50%' }}>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >Date To</label>
                                                <input type="date" className={`form-control ${errors?.currentAddress?.date_to ? 'is-invalid' : ''}`} {...register('currentAddress.date_to')} defaultValue={getCurrentDate()} readOnly />
                                                {errors?.currentAddress?.date_to && (
                                                    <div className="invalid-feedback">{errors?.currentAddress?.date_to?.message}</div>
                                                )}
                                            </div>
                                        </div>

                                        <div className='col-lg-12 col-md-12' style={{position:'relative'}} >
                                            <div className="form-group mt-3">
                                                <label className="form-label" >Address</label>
                                                <textarea type="text" onKeyDown={handleKeyDown} onBlur={handleBlur} className={`form-control ${errors?.currentAddress?.address ? 'is-invalid' : ''} ${autoSearchAddressData.length > 0 ? 'form-control-radius' : 'form-label'}`} {...register('currentAddress.address')} placeholder="Address" rows="5" />
                                                {errors?.currentAddress?.address && (
                                                    <div className="invalid-feedback">{errors?.currentAddress?.address?.message}</div>
                                                )}
                                            </div>
                                            {autoSearchAddressData.length > 0 &&
                                                <div className='suggestions-box' ref={containerRef}>
                                                    {
                                                        autoSearchAddressData.map((obj)=> (
                                                            <div className='text-box' onClick={()=>selectedAddress(obj?.Address.Address)}>
                                                                <div className='suggestions-text'>{obj.Address.Address}</div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                        </div>

                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >State</label>
                                                <input type="text" className={`form-control ${errors?.currentAddress?.state ? 'is-invalid' : ''}`} {...register('currentAddress.state')} placeholder="Enter State" />
                                                {errors?.currentAddress?.state && (
                                                    <div className="invalid-feedback">{errors?.currentAddress?.state?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >City </label>
                                                <input type="text" className={`form-control ${errors?.currentAddress?.city ? 'is-invalid' : ''}`} {...register('currentAddress.city')} placeholder="Enter City" />
                                                {errors?.currentAddress?.city && (
                                                    <div className="invalid-feedback">{errors?.currentAddress?.city?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >ZIP / Post / PIN Code</label>
                                                <input type="text" className={`form-control ${errors?.currentAddress?.zip ? 'is-invalid' : ''}`} placeholder="Zip" {...register('currentAddress.zip')} />

                                                {errors?.currentAddress?.zip && (
                                                    <div className="invalid-feedback">{errors?.currentAddress?.zip?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-md-12 mt-3'>
                                            <label className="form-label" >Address Type </label>
                                            <div className='row justify-content-between'>
                                                <div className='col-lg-9 col-md-9'>
                                                    <div className={`d-flex align-items-center justify-content-between ${errors?.currentAddress?.address_type ? 'is-invalid' : ''}`} style={{ gap: 40 }}>
                                                        <div className="d-flex align-items-center form-check mt-3">
                                                            <Controller
                                                                name="currentAddress.address_type"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <input
                                                                            className={`form-check-input ${errors?.currentAddress?.address_type ? 'is-invalid' : ''}`}
                                                                            type="radio"
                                                                            id="flexRadioDefault1"
                                                                            {...field}
                                                                            value="rent"
                                                                            checked={field.value === 'rent'}
                                                                        />
                                                                        <label className="form-check-label d-flex" htmlFor="flexRadioDefault1">Rent</label>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="d-flex align-items-center form-check mt-3">
                                                            <Controller
                                                                name="currentAddress.address_type"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <input
                                                                            className={`form-check-input ${errors?.currentAddress?.address_type ? 'is-invalid' : ''}`}
                                                                            type="radio"
                                                                            id="flexRadioDefault2"
                                                                            {...field}
                                                                            value="owned"
                                                                            checked={field.value === 'owned'}
                                                                        />
                                                                        <label className="form-check-label d-flex" htmlFor="flexRadioDefault2">Owned</label>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="d-flex align-items-center form-check mt-3">
                                                            <Controller
                                                                name="currentAddress.address_type"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <input
                                                                            className={`form-check-input ${errors?.currentAddress?.address_type ? 'is-invalid' : ''}`}
                                                                            type="radio"
                                                                            id="lived_with_parent"
                                                                            {...field}
                                                                            value="lived_with_parent"
                                                                            checked={field.value === 'lived_with_parent'}
                                                                        />
                                                                        <label className="form-check-label d-flex" htmlFor="lived_with_parent">Living with parent's</label>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errors?.currentAddress?.address_type && (
                                                        <div className="invalid-feedback">{errors?.currentAddress?.address_type?.message}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center hide-in-desktop mt-4'>
                            <img src={PreviousAddress} style={{ width: 35, objectFit: 'contain', marginRight: 10 }} />
                            <h1 className='text-ambed fw-normal'>Previous Residential Address</h1>
                        </div>
                        <div className='col-lg-6 col-md-12 d-flex'>
                            <div className='white-background p-3 mt-4'>
                                <div className='d-flex align-items-center hide-in-mobile mt-2'>
                                    <img src={PreviousAddress} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                                    <h1 className='text-ambed fw-normal'>Previous Residential Address</h1>
                                </div>
                                <hr className='hide-in-mobile mt-4' />
                                <div className='form'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group">
                                                <label className="form-label">Country</label>
                                                <Controller
                                                    name="previousAddress.country"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            id="previousAddress.country"
                                                            placeholder="Select Country of Residency"
                                                            className={`${errors.previousAddress?.country ? 'is-invalid' : ''}`}
                                                            options={countryData.map(country => ({
                                                                label: country.name,
                                                                value: country.country_id
                                                            }))}
                                                            styles={customStyles}
                                                            value={field.value ? { label: countryData.find(country => country.country_id == field.value)?.name, value: field.value } : ''}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption?.value);
                                                            }}
                                                            isDisabled={!lessThan3Year}
                                                        />
                                                    )}
                                                />
                                                {errors?.previousAddress?.country && (
                                                    <div className="invalid-feedback">{errors?.previousAddress?.country?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12' style={window.innerWidth >= 500 ? {} : { width: '50%' }}>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >Date From</label>
                                                {/* <input type="date" className={`form-control ${errors?.previousAddress?.date_from ? 'is-invalid' : ''}`} {...register('previousAddress.date_from')} disabled={!lessThan3Year} /> */}
                                                <Controller
                                                    name="previousAddress.date_from"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            {...field}
                                                            className={`form-control form-control-lg custom-datepicker ${errors?.previousAddress?.date_from ? 'is-invalid' : ''}`}
                                                            onChange={(date) => {
                                                                const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                field.onChange(formattedDate);
                                                                onSelectFromDate(formattedDate);
                                                            }}
                                                            value={field.value}
                                                            maxDate={new Date()}
                                                            clearIcon
                                                            format='dd-MM-yyyy'
                                                            yearPlaceholder='yyyy'
                                                            monthPlaceholder='mm'
                                                            dayPlaceholder='dd'
                                                            disabled={!lessThan3Year}
                                                        />
                                                    )}
                                                />
                                                {errors?.previousAddress?.date_from && (
                                                    <div className="invalid-feedback">{errors?.previousAddress?.date_from?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12' style={window.innerWidth >= 500 ? {} : { width: '50%' }}>
                                            <div className="form-group mt-3">
                                                {/* value={(lessThan3Year)?watch('currentAddress.date_from'):''} */}
                                                <label className="form-label" >Date To</label>
                                                <input type="date" className={`form-control ${errors?.previousAddress?.date_to ? 'is-invalid' : ''}`} {...register('previousAddress.date_to')} disabled={true} />
                                                {errors?.previousAddress?.date_to && (
                                                    <div className="invalid-feedback">{errors?.previousAddress?.date_to?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-md-12' style={{position:'relative'}}>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >Address</label>
                                                <textarea type="text" onKeyDown={handlePreviousKeyDown} onBlur={handleBlur} className={`form-control ${errors?.previousAddress?.address ? 'is-invalid' : ''}`} {...register('previousAddress.address')} placeholder="Address" rows="5" disabled={!lessThan3Year} />
                                                {errors?.previousAddress?.address && (
                                                    <div className="invalid-feedback">{errors?.previousAddress?.address?.message}</div>
                                                )}
                                            </div>
                                            {autoPrevSearchAddressData.length > 0 &&
                                                <div className='suggestions-box' ref={containerRef}>
                                                    {
                                                        autoPrevSearchAddressData.map((obj)=> (
                                                            <div className='text-box' onClick={()=>selectedPrevAddress(obj?.Address.Address)}>
                                                                <div className='suggestions-text'>{obj.Address.Address}</div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                        </div>

                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >State</label>
                                                <input type="text" className={`form-control ${errors?.previousAddress?.state ? 'is-invalid' : ''}`} {...register('previousAddress.state')} placeholder="Enter State" disabled={!lessThan3Year} />
                                                {errors?.previousAddress?.state && (
                                                    <div className="invalid-feedback">{errors?.previousAddress?.state?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >City </label>
                                                <input type="text" className={`form-control ${errors?.previousAddress?.city ? 'is-invalid' : ''}`} {...register('previousAddress.city')} placeholder="Enter City" disabled={!lessThan3Year} />
                                                {errors?.previousAddress?.city && (
                                                    <div className="invalid-feedback">{errors?.previousAddress?.city?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >ZIP / Post / PIN Code</label>
                                                <input type="text" className={`form-control ${errors?.previousAddress?.zip ? 'is-invalid' : ''}`} placeholder="Zip" {...register('previousAddress.zip')} disabled={!lessThan3Year} />

                                                {errors?.previousAddress?.zip && (
                                                    <div className="invalid-feedback">{errors?.previousAddress?.zip?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-12 col-md-12 mt-3'>
                                            <label className="form-label" >Address Type </label>
                                            <div className='row justify-content-between'>
                                                <div className='col-lg-9 col-md-9 py-0'>
                                                    <div className={`d-flex align-items-center justify-content-between ${errors?.previousAddress?.address_type ? 'is-invalid' : ''}`} style={{ gap: 40 }}>
                                                        <div className="d-flex align-items-center form-check mt-3">
                                                            <Controller
                                                                name="previousAddress.address_type"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <input
                                                                            className={`form-check-input ${errors?.previousAddress?.address_type ? 'is-invalid' : ''}`}
                                                                            type="radio"
                                                                            id="flexRadioDefault1"
                                                                            {...field}
                                                                            value="rent"
                                                                            checked={field.value === 'rent'}
                                                                            disabled={!lessThan3Year}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault1">Rent</label>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="d-flex align-items-center form-check mt-3">
                                                            <Controller
                                                                name="previousAddress.address_type"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <input
                                                                            className={`form-check-input ${errors?.previousAddress?.address_type ? 'is-invalid' : ''}`}
                                                                            type="radio"
                                                                            id="flexRadioDefault2"
                                                                            {...field}
                                                                            value="owned"
                                                                            checked={field.value === 'owned'}
                                                                            disabled={!lessThan3Year}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault2">Owned</label>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="d-flex align-items-center form-check mt-3">
                                                            <Controller
                                                                name="previousAddress.address_type"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <input
                                                                            className={`form-check-input ${errors?.previousAddress?.address_type ? 'is-invalid' : ''}`}
                                                                            type="radio"
                                                                            id="lived_with_parent2"
                                                                            {...field}
                                                                            value="lived_with_parent"
                                                                            checked={field.value === 'lived_with_parent'}
                                                                            disabled={!lessThan3Year}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="lived_with_parent2">Living with parent's</label>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errors?.previousAddress?.address_type && (
                                                        <div className="invalid-feedback">{errors?.previousAddress?.address_type?.message}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bottom-button'>
                        <button onClick={goBack} className={window.innerWidth <= 500 ? 'w-100 btn-secondary' : 'btn-secondary'} style={{ marginRight: 16 }}>Previous</button>
                        <button type='submit' className={window.innerWidth <= 500 ? 'w-100 btn-primary' : 'btn-primary'}>Save & Next</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default IndividualAddress