import React from 'react'
import PrintIcon from '../../assets/images/Print_Icon.png'
import ViewIcon from '../../assets/images/view_icon.png'
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap'
import FilteredTableData from '../../components/Screening/FilteredTableData'
import SearchIcon from '../../assets/images/search_icon.png'
import CorporateDataTable from '../../components/ViewScreening/CorporateDataTable';

function ViewAllCorporateList() {
    const tabArray = [
        { tabTitle: 'Green' },
        { tabTitle: 'Amber' },
        { tabTitle: 'Red' },
        { tabTitle: 'Grey' },
        { tabTitle: 'White Listed' },
        { tabTitle: 'Black Listed' },
        { tabTitle: 'Green(RC)' },
        { tabTitle: 'Amber(RC)' },
        { tabTitle: 'Red(RC)' },
    ];
    return (
        <div className='grey-background corporate-screening-page'>
            <div className='col-lg-12 col-md-12 mt-3'>
                <div className='white-background p-3'>
                    <Tabs defaultActiveKey="all" className="mb-3 corporate-tabs" >
                        <Tab eventKey="all" title="All">
                            <div className='white-background p-3 mb-4'>
                                <h1 className='text-blue px-2 fw-normal'>List of Screening Corporate</h1>
                                <hr />
                                <div className='row'>
                                    <div className='col-lg-10 col-md-12'>
                                        <div className="form-group position-relative">
                                            <input type="text" className="form-control" placeholder='Search'></input>
                                            <div className="position-absolute" style={{ right: 20, top: '20%' }}>
                                                <img src={SearchIcon} style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-12'>
                                        <button className="btn btn-primary w-100" style={{ fontSize: 14 }}>Search</button>
                                    </div>
                                </div>
                            </div>
                            <CorporateDataTable ViewIcon={ViewIcon} PrintIcon={PrintIcon} />
                        </Tab>
                        {tabArray.map((item, i) => {
                            return (
                                <Tab eventKey={item.tabTitle} title={item.tabTitle} key={'screen-tab' + i}>
                                    <div className='white-background p-3 mb-4'>
                                        <h1 className='text-blue px-2 fw-normal'>List of Screening Corporate</h1>
                                        <hr />
                                        <div className='row'>
                                            <div className='col-lg-10 col-md-12'>
                                                <div className="form-group position-relative">
                                                    <input type="text" className="form-control" placeholder='Search'></input>
                                                    <div className="position-absolute" style={{ right: 20, top: '20%' }}>
                                                        <img src={SearchIcon} style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-2 col-md-12'>
                                                <button className="btn btn-primary w-100" style={{ fontSize: 14 }}>Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    <CorporateDataTable ViewIcon={ViewIcon} PrintIcon={PrintIcon} />
                                </Tab>
                            )
                        })}
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default ViewAllCorporateList

