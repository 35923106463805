import React from 'react'
import ResultsTopCard from '../../components/Aml/ResultsTopCard'
import ResultsTab from '../../components/Aml/ResultsTab'

function SingleResultsData() {

  return (
    <div className='grey-background'>
      <ResultsTopCard/>
      <div className='mt-4'>
        <ResultsTab/>
      </div>
    </div>
  )
}

export default SingleResultsData
