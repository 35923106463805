import React, { useEffect, useState } from 'react'
import EditIcon from '../../assets/images/edit_icon.png'
import DeleteIcon from '../../assets/images/delete_icon.png'
import EuroIcon from '../../assets/images/euro_icon.png'
import DollarIcon from '../../assets/images/dollar_icon.png'
import RupeeIcon from '../../assets/images/rupee_icon.png'
import { useNavigate } from 'react-router-dom';
import { deleteFxRate, getCurrencyList, getFxRateBasedByBaseCurrency, resetFxList } from './fxRatesSlice'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { toast } from 'react-toastify'
import Loader from '../../components/Loader/Loader'
import { Modal } from 'react-bootstrap'
import CancelIcon from '../../assets/images/Cancel_icon.png'
import { decodeToken } from "react-jwt";

const FxRates = () => {
    const [show, setShow] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [selectedCheckbox, setSelectedCheckbox] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [deleteFxRateId, setDeleteFxRateId] = useState(null);
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);
    const handleShow = (fxRateId) => {
        setDeleteFxRateId(fxRateId);
        setShow(true);
    };
    const { isLoading, currencyList, fxRatesList } = useSelector(state => state.fxRatesReducer);
    const routeChange = () => {
        let path = `/rates/manage_fxrates`;
        navigate(path,{ state: { baseCurrencyId: selectedCurrency } });
    }
    const routeChange2 = () => {
        let path = `/rates/history_rates`;
        if (selectedCurrency != null && selectedCheckbox != null) {
            navigate(path, { state: { baseCurrencyId: selectedCurrency, baseCurrency : currencyList.find(currency => currency.country_currency_id === selectedCurrency) ,currencyId: selectedCheckbox, currency:  currencyList.find(currency => currency.country_currency_id == selectedCheckbox)} });
        }else{
            toast.error("Please select currency and record first");
        }
    }

    useEffect(() => {
        if (!((decodedToken?.roles?.permissions?.fetch_fx_rate && decodedToken?.isOomero) || decodedToken?.isSuperAdmin)) {
            toast.error("You don't have access for fx rates");
            navigate('/');
        }
    }, [decodedToken]);

    const handleCurrencyChange = (event) => {
        const selectedValue = (event.target.value && event.target.value !== '') ? event.target.value : 0;
        setSelectedCheckbox(null);
        setSelectedCurrency(selectedValue);
        localStorage.setItem('fxBaseCurrencyId', selectedValue);
    };

    useEffect(() => {
        dispatch(getCurrencyList()).unwrap().catch((error) => {
            toast.error(error?.message);
        });
        dispatch(resetFxList());
    }, []);

    useEffect(() => {
        if(selectedCurrency != null){
            let queryParam = `?fxBaseCurrencyId=${selectedCurrency}&limit=10&page=1`;
            dispatch(getFxRateBasedByBaseCurrency(queryParam)).unwrap().catch((error) => {
                toast.error(error?.message);
            });
        }
    }, [selectedCurrency]);

    const handleEditFxRate = (fxrates_id) => {
        let path = `/rates/manage_fxrates`;
        navigate(path, { state: { fxratesId: fxrates_id } });
    }

    // const handleDeleteFxRate = (fxrates_id) => {
    //     const fxRateId = `?fxrateId=${fxrates_id}`;
    //     dispatch(deleteFxRate(fxRateId)).unwrap()
    //         .then((res) => {
    //             toast.success(res?.message);
    //             if(selectedCurrency != ''){
    //                 let queryParam = `?fxBaseCurrencyId=${selectedCurrency}&limit=10&page=1`;
    //                 dispatch(getFxRateBasedByBaseCurrency(queryParam));
    //             }
    //         }).catch((error) => {
    //             toast.error(error?.message);
    //         })
    // }
    const confirmDeleteFxRate = () => {
        const fxRateId = `?fxrateId=${deleteFxRateId}`;
        dispatch(deleteFxRate(fxRateId)).unwrap()
            .then((res) => {
                toast.success(res?.message);
                setShow(false);
                if(selectedCurrency != ''){
                    let queryParam = `?fxBaseCurrencyId=${selectedCurrency}&limit=10&page=1`;
                    dispatch(getFxRateBasedByBaseCurrency(queryParam));
                }
            }).catch((error) => {
                toast.error(error?.message);
            })
    }

    const handleCheckboxChange = (index) => {
        setSelectedCheckbox(index);
    };

    useEffect(()=>{
        if(localStorage.getItem('fxBaseCurrencyId')){
            setSelectedCurrency(localStorage.getItem('fxBaseCurrencyId'));
        }else{
            // localStorage.setItem('fxBaseCurrencyId', 9);
            // setSelectedCurrency(9);
        }
    });

    const filteredCurrencyList = currencyList.filter(({ currency, country_name, currency_symbol }) => {
        const searchTermLower = searchTerm.toLowerCase();
        return [currency, country_name, currency_symbol].some(
            value => value.toLowerCase().includes(searchTermLower)
        );
    });
    
    return (
        <div className='grey-background vh-101'>
            {isLoading && <Loader/>}
            <div className='white-background p-3 mb-3'>
                <h1 className='text-blue px-2 fw-normal'>FX Rates Setup</h1>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <div className="p-2">
                            <h1 className='text-black px-2 fw-normal'>Base Currency</h1>
                        </div>
                        <div className="p-2">
                            <div className="form-group search-dropdown">
                                <div className="dropdown">
                                    <button
                                        className="btn btn-secondary-dropdown dropdown-toggle"
                                        type="button"
                                        onClick={() => setShowDropdown(!showDropdown)}
                                    >
                                        {selectedCurrency ? `${currencyList.find(currency => currency.country_currency_id === selectedCurrency)?.country_name} (${currencyList.find(currency => currency.country_currency_id === selectedCurrency)?.currency} ${currencyList.find(currency => currency.country_currency_id === selectedCurrency)?.currency_symbol})` : "Select Currency"}
                                    </button>
                                    {showDropdown && (
                                        <div className="dropdown-menu show p-2"
                                        style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search currency..."
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                            {filteredCurrencyList.map((currency) => (
                                                <button
                                                    key={currency.country_currency_id}
                                                    className="dropdown-item"
                                                    onClick={() => {
                                                        handleCurrencyChange({
                                                            target: { value: currency.country_currency_id }
                                                        });
                                                        setShowDropdown(false);
                                                    }}
                                                >
                                                     { currency.country_name} ({currency.currency} {currency.currency_symbol})
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {((decodedToken?.roles?.permissions?.create_fx_rate && decodedToken?.isOomero) || decodedToken?.isSuperAdmin) &&
                        <div>
                            <button className="btn btn-primary" onClick={routeChange}>Add New</button>
                        </div>
                    }
                </div>
            </div>
            <div className='white-background p-3 mb-3'>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <div className="p-2">
                            <h1 className='text-blue px-2 fw-normal'>FX Rates</h1>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-center'>
                        {((decodedToken?.roles?.permissions?.print_fx_history && decodedToken?.isOomero) || decodedToken?.isSuperAdmin) &&
                            <h6 className='dark-blue-small-bg text-blue me-3' onClick={routeChange2}>Print History Rate</h6>
                        }
                        {((decodedToken?.roles?.permissions?.print_current_rates && decodedToken?.isOomero) || decodedToken?.isSuperAdmin) &&
                            <h6 className='dark-blue-small-bg text-blue'>Print Current Rates</h6>
                        }
                    </div>
                </div>
                <hr />
                {((decodedToken?.roles?.permissions?.fetch_fx_rate && decodedToken?.isOomero) || decodedToken?.isSuperAdmin) &&
                <div className='col-lg-12 col-md-12 mt-3'>
                    <div className='custom-table'>
                        <div className='custom-table-head'>
                            <div className='row'>
                                <div className='col-1'>
                                    <h6 className='text-blue'></h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue'>Base Country</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue'>Base Currency</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue'>Country</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue'>Currency</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue'>Symbol</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue'>Last Update Date</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue'>Exchange Rate</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue'></h6>
                                </div>
                            </div>
                        </div>

                        {
                            fxRatesList['rows'].map((value, index) => {
                                return <div className='custom-table-body' key={index}>
                                    <div className='row align-items-center'>
                                        <div className='col-1'>
                                            <div className="form-check">
                                                {/* <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" /> */}
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id={`checkbox${index}`}
                                                    checked={selectedCheckbox === value?.currency_id}
                                                    onChange={() => handleCheckboxChange(value?.currency_id)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <h6 className='text-black'>{value?.base_country}</h6>
                                        </div>
                                        <div className='col'>
                                            <h6 className='text-black'>{value?.base_currency} {'(' + value?.base_currency_symbol+ ')'}</h6>
                                        </div>
                                        <div className='col'>
                                            <h6 className='text-black'>{value?.country}</h6>
                                        </div>
                                        <div className='col'>
                                            <h6 className='text-black'>{value?.currency}</h6>
                                        </div>
                                        <div className='col'>
                                            <div className='d-flex justify-content-start'>
                                                {/* <img src={DollarIcon} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer' }} /> */}
                                                {value?.symbol}
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <h6 className='text-black'>{(value?.last_update_date) ? moment(value?.last_update_date).format('DD/MM/YYYY') : moment(value?.created_at).format('DD/MM/YYYY')} </h6>
                                        </div>
                                        <div className='col'>
                                            <h6 className='text-black'>{value?.exchange_rate}</h6>
                                        </div>
                                        <div className='col'>
                                            <div className='d-flex justify-content-evenly'>
                                                {((decodedToken?.roles?.permissions?.edit_fx_rate && decodedToken?.isOomero) || decodedToken?.isSuperAdmin) &&
                                                    <img src={EditIcon} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer' }} onClick={() => handleEditFxRate(value?.fxrates_id)} />
                                                }
                                                {((decodedToken?.roles?.permissions?.delete_fx_rate && decodedToken?.isOomero) || decodedToken?.isSuperAdmin) &&
                                                    <img src={DeleteIcon} style={{ width: 11.64, height: 15, objectFit: 'contain', cursor: 'pointer' }} onClick={() => handleShow(value?.fxrates_id)}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }

                    </div>
                </div>
                }
            </div>
            <Modal show={show} onHide={handleClose} size='md' centered>
                <Modal.Header >
                    <div className="modal-title text-center text-blue" style={{fontSize:20}}><b>Delete</b></div>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="d-flex justify-content-center form-group p-2">
                            <h6>Are you sure you want to delete?</h6>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer >
                    <div className='d-flex align-items-center'>
                        <button onClick={handleClose} type="button" className="btn btn-primary me-3 px-5">No</button>
                        <button onClick={confirmDeleteFxRate} type="button" className="btn btn-primary px-5">Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default FxRates
