import React, { useState } from 'react'
import '../../Profile/Profile.scss';
import Logo from '../../../assets/images/logo.png'
import OnboardClientBar from '../../../components/OnboardClientBar/OnboardClientBar';
import UploadCircleIcon from '../../../assets/images/upload-circle-icon.png'
import AddBlueIcon from '../../../assets/images/Add_blue_button.png'
import BankDetails from '../../../assets/images/bank_details.png'
import UploadCompanyDocuments from '../../../assets/images/upload_company_documnets.png'
import { useNavigate, useHistory } from 'react-router-dom';

function CorporateWebformBankDetails() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };
    const routeChange = () => {
        let path = `/corporate_webform_directorlist`;
        navigate(path);
    }
    return (
        <div>
            <div className='white-background py-2 position-fixed w-100' style={{ borderRadius: 0 }}>
                <div className="d-flex justify-content-center">
                    <img src={Logo} style={{ objectFit: 'contain', width: 150 }} />
                </div>
            </div>
            <div style={{ padding: 20 }}>
                <div className='mt-5'>

                </div>
                <OnboardClientBar
                    mainTitle={`Onboard Business Client `}
                    title1={`COMPANY DETAILS`}
                    title2={`COMPANY INFORMATION`}
                    title3={`DIRECTOR LIST`}
                    title4={`SHAREHOLDER LIST`}
                    backgroundColor1={'#037AD0'}
                    backgroundColor2={'#037AD0'}
                    backgroundColor3={'#037AD0'} />

                <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                        <div className='white-background p-3 mt-4'>
                            <div className='d-flex align-items-center'>
                                <img src={BankDetails} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                                <h1 className='text-success fw-normal'>Bank Details</h1>
                            </div>
                            <hr />
                            <div className='form'>
                                <form>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group">
                                                <label className="form-label" for="exampleFormControlSelect1">Bank Name</label>
                                                <select id="statusDropdown" className="form-control form-select">
                                                    <option selected>HDFC</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleInputEmail1">Account No</label>
                                                <input type="text" className="form-control" placeholder="Account No" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleInputEmail1">Sort Code</label>
                                                <input type="text" className="form-control" placeholder="Sort Code" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleInputEmail1">Swift/BIC Code</label>
                                                <input type="text" className="form-control" placeholder="Swift/BIC Code" rows="5" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleInputEmail1">IBAN</label>
                                                <div className='row'>
                                                    <div className='col-2'>
                                                        <input type="text" className="form-control" placeholder="" />
                                                    </div>
                                                    <div className='col-2'>
                                                        <input type="text" className="form-control" placeholder="" />
                                                    </div>
                                                    <div className='col-2'>
                                                        <input type="text" className="form-control" placeholder="" />
                                                    </div>
                                                    <div className='col-3'>
                                                        <input type="text" className="form-control" placeholder="" />
                                                    </div>
                                                    <div className='col-3'>
                                                        <input type="text" className="form-control" placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleInputEmail1">Address Registered with Bank</label>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <div className=' border-grey-form'>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" style={{ width: 18, height: 18, marginTop: 12, marginRight: 20, marginLeft: -15 }} />
                                                                <label className="form-check-label" for="defaultCheck1" >
                                                                    Registered
                                                                    <br></br>
                                                                    Address
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className=' border-grey-form'>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" style={{ width: 18, height: 18, marginTop: 12, marginRight: 20, marginLeft: -15 }} />
                                                                <label className="form-check-label" for="defaultCheck1" >
                                                                    Trading
                                                                    <br></br>
                                                                    Address
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className=' border-grey-form'>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" style={{ width: 18, height: 18, marginTop: 12, marginRight: 20, marginLeft: -15 }} />
                                                                <label className="form-check-label" for="defaultCheck1" >
                                                                    Other
                                                                    <br></br>
                                                                    Address
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <textarea type="text" className="form-control mt-4" id="exampleInputEmail1" placeholder="" rows="4" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between my-2'>
                                        <button className='btn-primary fw-normal mt-4' style={{ marginRight: 16 }}>Link Another Account</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className='white-background p-3 mt-4'>
                            <div className='d-flex align-items-center'>
                                <img src={UploadCompanyDocuments} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                                <h1 className='text-ambed fw-normal'>Upload Documents</h1>
                            </div>
                            <hr />
                            <div className='form'>
                                <form>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className=' border-grey-form px-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <label className="form-label mb-0" for="exampleFormControlSelect1">Upload Certificate of Incorporation</label>
                                                    <p className='btn-primary fw-normal mb-0' style={{ height: 'auto', cursor: 'pointer' }}>Choose a File</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className='row'>
                                                <div className='col-lg-7'>
                                                    <div className="form-group">
                                                        <label className="form-label" for="exampleFormControlSelect1">Upload Company Document</label>
                                                        <select id="statusDropdown" className="form-control form-select">
                                                            <option selected>Select Document</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-lg-5 align-self-end'>
                                                    <p className='btn-primary fw-normal mb-0 text-center' style={{ height: 'auto', cursor: 'pointer' }}>Choose a File</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className='row'>
                                                <div className='col-lg-7'>
                                                    <div className="form-group">
                                                        <label className="form-label" for="exampleFormControlSelect1">Upload Address Document</label>
                                                        <select id="statusDropdown" className="form-control form-select">
                                                            <option selected>Select Document</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-lg-5 align-self-end'>
                                                    <p className='btn-primary fw-normal mb-0 text-center' style={{ height: 'auto', cursor: 'pointer' }}>Choose a File</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className='row'>
                                                <div className='col-lg-7'>
                                                    <div className="form-group">
                                                        <label className="form-label" for="exampleFormControlSelect1">Upload any License Document</label>
                                                        <select id="statusDropdown" className="form-control form-select">
                                                            <option selected>Select Document</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-lg-5 align-self-end'>
                                                    <p className='btn-primary fw-normal mb-0 text-center' style={{ height: 'auto', cursor: 'pointer' }}>Choose a File</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className=' border-grey-form px-3'>
                                                <div className='row'>
                                                    <div className='col-lg-7'>
                                                        <div className="form-group">
                                                            <label className="form-label" for="exampleInputEmail1">Upload Other Documents</label>
                                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Document Name" style={{ border: 0, borderBottomWidth: 1, borderColor: '#D9D9D9', borderRadius: 0, borderStyle: 'groove' }} />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-5 align-self-end'>
                                                        <p className='btn-primary fw-normal mb-0 text-center' style={{ height: 'auto', cursor: 'pointer' }}>Choose a File</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row my-4'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className=' border-grey-form px-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <label className="form-label mb-0" for="exampleFormControlSelect1">Upload Company Logo</label>
                                                    <p className='btn-primary fw-normal mb-0' style={{ height: 'auto', cursor: 'pointer' }}>Choose a File</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row mb-2'>
                                        <div className='col-lg-12'>
                                            <div className="form-group">
                                                <label className="form-label" for="exampleInputEmail1">Company AccountsDocuments</label>
                                                <div className='row align-items-end'>
                                                    <div className='col-lg-4'>
                                                        <input type="date" className="form-control" id="exampleInputEmail1" />
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <input type="date" className="form-control" id="exampleInputEmail1" />
                                                    </div>
                                                    <div className='col-lg-2'>
                                                        <img src={UploadCircleIcon} style={{ width: 50, objectFit: 'contain' }} />
                                                    </div>
                                                    <div className='col-lg-2'>
                                                        <img src={AddBlueIcon} style={{ width: 50, objectFit: 'contain' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-end my-4'>
                    <div className='d-flex align-items-center'>
                        <button onClick={routeChange} className='btn-primary'>Save & Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CorporateWebformBankDetails
