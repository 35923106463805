import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import BackIcon from '../../assets/images/back-btn-arrow.png';
import { entityPreviousInvoiceList, getEntityDetails, resetData } from './viewClientBillingSlice';
import Loader from '../../components/Loader/Loader';
import TablePagination from '../../components/Pagination';

function PreviousBills() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [yearList, setYearList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPge, SetPostsPerPage] = useState(10);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const { isLoading, entityInvoiceList, entityDetails } = useSelector(state => state.viewClientBillingReducer);
    const { state } = useLocation();

    const navigateToPrevious = () => {
        navigate(-1);
    }

    useEffect(() => {
        if(state?.entityId){
            dispatch(entityPreviousInvoiceList(`?entityId=${state?.entityId}&page=${currentPage}&limit=${postsPerPge}&year=${selectedYear}`)).unwrap().catch((error) => {
                toast.error(error?.message);
            });
            dispatch(getEntityDetails(`?entityId=${state?.entityId}`))
        }
        generateYearList();
    }, [state?.entityId, selectedYear, currentPage]);

    const handlePagination = (event, value) => {
        setCurrentPage(value);
    }

    const handleView = (id) => {
        let path = `/billing/view_client_biling`;
        dispatch(resetData());
        navigate(path, { state: { invoiceId: id, viewOnly : true } });
    }

    const generateYearList = () => {
        const currentYear = new Date().getFullYear();
        const years = [];

        for (let i = 0; i < 6; i++) {
            years.push(currentYear - i);
        }

        setYearList(years);
    };

    return (
        <div className='grey-background'>
            {isLoading && <Loader />}
            <button onClick={navigateToPrevious} className='back-button text-blue'><img src={BackIcon} style={{width:8,marginBottom:2,marginRight:4,objectFit:'contain'}}/> Back</button>
            <div className='white-background p-3 my-3'>
                <h1 className='text-blue px-2 fw-normal'>View Previous Bills</h1>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <div>
                            <h1 className='text-blue px-2 fw-normal'>Client Name : {entityDetails?.entity_name}</h1>
                        </div>
                    </div>
                    <div >
                        <div className="form-group">
                            <select id="statusDropdown" className="form-control form-select" style={{ width: 125 }} onChange={(e)=>setSelectedYear(e.target.value)} defaultValue={selectedYear}>
                                <option disabled value="">Select Year</option>
                                {yearList && yearList.length > 0 && yearList?.map((res, i)=>(
                                    <option value={res} key={'years'+i}>{res}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='white-background p-3 mb-3'>
                <div className='col-lg-12 col-md-12 mt-3'>
                    <div className='custom-table'>
                        <div className='custom-table-head'>
                            <div className='row'>
                                <div className='col'>
                                    <h6 className='text-blue'>Bill Cycle</h6>
                                </div>
                                <div className='col-2'>
                                    <h6 className='text-blue'>Status</h6>
                                </div>
                                <div className='col-2'>
                                    <h6 className='text-blue'>Bill Amount</h6>
                                </div>
                                <div className='col-1'>
                                    <h6 className='text-blue'>Action</h6>
                                </div>
                            </div>
                        </div>
                        {entityInvoiceList && entityInvoiceList.rows && entityInvoiceList.rows.length > 0 &&
                            (entityInvoiceList.rows).map((item, index) => (
                                <div className='custom-table-body' key={'list_'+index}>
                                    <div className='row align-items-center'>
                                        <div className='col'>
                                            <h6 className='text-black'>{(new Date(item?.from_date)).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })} to {(new Date(item?.to_date)).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}</h6>
                                        </div>
                                        <div className='col-2'>
                                            <h6 className='text-black'>{item?.payment_status}</h6>
                                        </div>

                                        <div className='col-2'>
                                            <h6 className='text-black'>{item?.amount_due} {item?.currency_code}</h6>
                                        </div>
                                        <div className='col-1'>
                                            <button className="btn btn-primary" style={{ marginLeft: -15 }} onClick={()=>handleView(item?.invoice_id)} >View</button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {entityInvoiceList && entityInvoiceList.rows && entityInvoiceList.rows.length < 1 &&
                            <div className='custom-table-body text-center'>
                                Data Not found
                            </div>
                        }
                    </div>
                </div>
            </div>
            {entityInvoiceList && entityInvoiceList?.rows?.length > 0 &&
                <TablePagination totalPages={parseInt(entityInvoiceList?.totalPages)} currentPage={currentPage} color="primary" handlePagination={handlePagination} />
            }
        </div>
    )
}

export default PreviousBills
