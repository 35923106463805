import React from 'react'
import Logo from '../../../assets/images/logo.png'
import WelcomeLogo from '../../../assets/images/welcome_logo.png'
import { useNavigate } from 'react-router-dom';

function CorporateWelcomeScreen() {
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/corporate_webform_information`;
        navigate(path);
    }
    return (
        <div>
            <div className='white-background py-2 position-fixed w-100' style={{ borderRadius: 0 }}>
                <div className="d-flex justify-content-center">
                    <img src={Logo} style={{ objectFit: 'contain', width: 150 }} />
                </div>
            </div>
            <div style={{ height: '90vh' }}>
                <div className='row justify-content-center align-items-center h-100'>
                    <div className='col-4'>
                        <div className="d-flex justify-content-center mt-5">
                            <img src={WelcomeLogo} style={{ objectFit: 'contain', width: 350 }} />
                        </div>
                        <div className="d-flex justify-content-center mt-5">
                            <h1 className='text-blue fs-1'>Welcome ABC</h1>
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                            <h6 className='text-blue fw-normal text-center'>You are about to kickstart the onboarding process for Business Client</h6>
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                            <button onClick={routeChange} className='btn-primary w-100'>Proceed</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CorporateWelcomeScreen
