import React, { useEffect, useState } from 'react'
import BankDetailsIcon from '../../../assets/images/bank_details.png'
import LinkCompanyIcon from '../../../assets/images/Link_company.png'
import ViewIcon from '../../../assets/images/view_icon.png'
import EditIcon from '../../../assets/images/edit_icon.png'
import ScreenActionIcon from '../../../assets/images/screeing_action.png'
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '../../../assets/images/Cancel_icon.png'
import LinkCompnay from '../../../assets/images/Link_company.png'
import { fetchDataEntityClientCompany } from '../../Profile/profileSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader/Loader'
import { addScreeningComments, getEntityClientCompanyDetails, updateIndividualPersonalDetailsWithReason } from '../IndividualScreeningSlice'
import { useForm } from 'react-hook-form'
import * as  yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import { countryList } from '../../Registration/registrationSlice'
import { decodeToken, useJwt } from "react-jwt";

function IndividualCompanyDetails({entityClientId, viewOnly}) {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        reset();
    };
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    const { isLoading : screeningLoader, entityClientCompanyDetails } = useSelector(state => state.individualScreeningReducer);
    const [currentValues, setCurrentValues] = useState(entityClientCompanyDetails);
    const { isLoading, entityClientCompany } = useSelector((state) => state.ProfileReducer);
    const { isLoading: countryLoading, countryData } = useSelector(state => state.registerReducer);
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);
    const navigate = useNavigate();

    const companySchema = yup.object().shape({
        country_id: yup.number().required('Country name is required').typeError("Select Country name"),
        company_name: yup.string().required('Company is required'),
        company_reg_no: yup.string().required('Register number is required'),
        designation: yup.string().required('Designation is required'),
        type_of_director: yup.string().required('Type of director is required'),
        shareholder: yup.string().required('Shareholder type is require'),
        ultimate_beneficial_owner: yup.string().required('Ultimate beneficial owner is required'),
        reason: yup.string().required('Reason is required'),
    });

    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm({
        resolver: yupResolver(companySchema),
    });

    useEffect(() => {
        if (!entityClientId) {
            navigate(`/individual_screening`);
            return;
        }

        if (entityClientId) {
            dispatch(fetchDataEntityClientCompany({queryParam : `?entity_client_id=${entityClientId}`})).unwrap().catch((error) => {
                toast.error(error?.message);
            });

            dispatch(countryList());
        }
    }, [entityClientId]);

    useEffect(() => {
        if (currentValues?.country_id) setValue('country_id', currentValues.country_id);
        if (currentValues?.company_name) setValue('company_name', currentValues.company_name);
        if (currentValues?.company_reg_no) setValue('company_reg_no', currentValues.company_reg_no);
        if (currentValues?.designation) setValue('designation', currentValues.designation);
        if (currentValues?.shareholder !== undefined) setValue('shareholder', String(currentValues.shareholder));
        if (currentValues?.type_of_director) setValue('type_of_director', currentValues.type_of_director);
        if (currentValues?.ultimate_beneficial_owner !== undefined) setValue('ultimate_beneficial_owner', String(currentValues.ultimate_beneficial_owner));
    }, [currentValues]);

    const onEditClick = (id) => {
        reset();
        if (id) {
            dispatch(getEntityClientCompanyDetails(`?entity_client_id=${entityClientId}&entity_company_id=${id}`)).unwrap().then((res) => {
                setValue('id', id);
                setCurrentValues(res?.data);
                handleShow();
            }).catch((error) => {
                toast.error(error?.message);
            });
        }
    }

    const handleChange = (field, value, displayName) => {
        setCurrentValues({
            ...currentValues,
            [field]: (displayName) ? `${displayName}@|${value}` : value
        });
    };

    const onFormSubmit = (data) => {
        if(Object.keys(entityClientCompanyDetails).length < 1){
            toast.error("Something Went Wrong");
            return;
        }

        const changes = Object.entries(currentValues).filter(([field, value]) => {
            const valueAsString = String(value);
            const actualValue = valueAsString.includes('@|') ? value.split('@|')[1] : valueAsString;
            return actualValue !== String(entityClientCompanyDetails[field]);
        }).map(([field, value]) => ({ 
            column_name : field,
            old_value : entityClientCompanyDetails[field],
            new_value : String(value).includes('@|') ? String(value).split('@|')[1] : value,
            screen : "company_details",
            reason : data?.reason
        }));

        if (changes.length > 0) {
            dispatch(updateIndividualPersonalDetailsWithReason({ payload: {
                entity_client_company : changes
            }, queryParam: `?entityClientId=${entityClientId}&entityClientCompanyId=${data?.id}` })).unwrap().then((obj) => {
                toast.success(obj?.message);
                handleClose();
                reset();
                dispatch(fetchDataEntityClientCompany({queryParam : `?entity_client_id=${entityClientId}`})).unwrap().catch((error) => {
                    toast.error(error?.message);
                });
            }).catch(error => {
                toast.error(error?.message || 'Unable to update the record');
            });
        } else {
            toast.info("No Fields were updated");
        }
    }

    const commentSchema = yup.object().shape({
        commentType: yup.string().required('Comment Type is required'),
        comment: yup.string().required('Comment is required'),
    });

    const { control: controlCommentForm, register: registerCommentForm, handleSubmit: handleSubmitCommentForm, formState: { errors: errorsCommentForm }, reset: resetCommentForm, setValue: setValueComment } = useForm({
        resolver: yupResolver(commentSchema),
    });

    const onCommentFormSubmit = (data) => {
        resetCommentForm();
        dispatch(addScreeningComments({payload:data})).unwrap().then((obj)=>{
            toast.success(obj?.message || 'Comment Added Successfully');
        }).catch(error => {
            toast.error(error.message || 'Unable to add comments');
        });
    }

    return (
        <>
            {(isLoading || countryLoading || screeningLoader) && <Loader />}
            <div>
                <div className='d-flex align-items-center justify-content-end mt-3'>
                    {/* <img src={EditIcon} style={{ width: 15, objectFit: 'contain', cursor: 'pointer' }} /> */}
                </div>
                <div className='d-flex align-items-center pt-3'>
                    <img src={LinkCompanyIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                    <h2 className='text-ambed text-center'>Link Company</h2>
                </div>
                <hr />
                <div className='custom-table pb-3'>
                    <div className='custom-table-head'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <h6 className='text-blue'>Company Name</h6>
                            </div>
                            <div className='col-lg-2'>
                                <h6 className='text-blue'>Company Country</h6>
                            </div>
                            <div className='col-lg-2'>
                                <h6 className='text-blue'>Company  Reg No</h6>
                            </div>
                            <div className='col-lg-2'>
                                <h6 className='text-blue'>Designation</h6>
                            </div>
                            <div className='col-lg-1'>
                                <h6 className='text-blue'>Shareholder</h6>
                            </div>
                            <div className='col-lg-1'>
                                <h6 className='text-blue'>UBO</h6>
                            </div>
                            {!viewOnly &&
                            <div className='col-lg-1'>
                                <h6 className='text-blue'>Action</h6>
                            </div>
                            }
                        </div>
                    </div>
                    {entityClientCompany.map((obj) =>
                        <div className='custom-table-body' key={obj?.id}>
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <h6 className='text-black'>{obj?.company_name}</h6>
                                </div>
                                <div className='col-lg-2'>
                                    <h6 className='text-black'>{obj?.country_name}</h6>
                                </div>
                                <div className='col-lg-2'>
                                    <h6 className='text-black'>{obj?.company_reg_no}</h6>
                                </div>
                                <div className='col-lg-2'>
                                    <h6 className='text-black'>{obj?.designation}</h6>
                                </div>
                                <div className='col-lg-1'>
                                    <h6 className='text-black'>{(obj?.shareholder)?'true':'false'}</h6>
                                </div>
                                <div className='col-lg-1'>
                                    <h6 className='text-black'>{(obj?.ultimate_beneficial_owner)?'true':'false'}</h6>
                                </div>
                                {!viewOnly &&
                                <div className='col-lg-1'>
                                    <img src={EditIcon} onClick={() => onEditClick(obj?.id)} style={{ width: 15, objectFit: 'contain', cursor: 'pointer' }} />
                                </div>
                                }
                            </div>
                        </div>
                    )}

                </div>
                {!viewOnly &&
                <div className='white-background p-3 mb-3'>
                    <form className='form' onSubmit={handleSubmitCommentForm(onCommentFormSubmit)}>
                        <div className='d-flex align-items-center'>
                            <img src={ScreenActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                            <h2 className='text-ambed text-center'>Add Comments</h2>
                        </div>
                        <hr />
                        <div className={`d-flex mb-2 ${errorsCommentForm?.commentType ? 'is-invalid' : ''}`}>
                            { (decodedToken?.roles?.permissions?.add_general_comment || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
                                <div style={{ marginRight: 30 }}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" {...registerCommentForm('commentType')} id="flexRadioDefault2" value="open" />
                                        <label className="form-check-label" for="flexRadioDefault1">Open</label>
                                    </div>
                                </div>
                            }
                            {(decodedToken?.roles?.permissions?.add_aml_comments_screening_dashboard || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
                                <div style={{ marginRight: 30 }}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" {...registerCommentForm('commentType')} id="flexRadioDefault1" value="aml" />
                                        <label className="form-check-label" for="flexRadioDefault1">AML</label>
                                    </div>
                                </div>
                            }                            
                            {
                            (decodedToken?.roles?.permissions?.add_ghost_comment || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
                                <div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" {...registerCommentForm('commentType')} id="flexRadioDefault3" value="ghost"/>
                                        <label className="form-check-label" for="flexRadioDefault1">Ghost</label>
                                    </div>
                                </div>
                            }
                        </div>

                        <input type="hidden" {...registerCommentForm(`entityClientId`, { value: entityClientId })} />
                        <input type="hidden" {...registerCommentForm(`screen`, { value: 'company_details' })} />

                        {errorsCommentForm?.commentType &&
                            <span className="invalid-feedback" >Comment Type is required</span>
                        }
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='row align-items-end'>
                                    <div className='col-lg-9' style={{ width: '80%' }}>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Add Comment</label>
                                            <textarea type="text" {...registerCommentForm('comment')} className={`form-control ${errorsCommentForm?.comment ? 'is-invalid' : ''}`} id="exampleInputEmail1" placeholder="Add Comment" rows="2" />
                                            {errorsCommentForm?.comment &&
                                                <span className="invalid-feedback" >Comment is required</span>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-lg-3' style={{ width: '20%' }}>
                                        <button className='btn-primary fw-normal w-100'>Add Comment</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            }
            </div>
            <Modal show={show} size='md' onHide={handleClose} centered scrollable style={{ maxHeight: '90%', marginTop: 50 }}>
                <Modal.Body className=' mt-2 pb-3 p-4'>
                    <div className='form'>
                        <form className='form' onSubmit={handleSubmit(onFormSubmit)}>
                        <input type="hidden" {...register("id")} />
                            <div className='d-flex align-items-center mb-3'>
                                <img src={LinkCompnay} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                                <h1 className='text-ambed fw-normal'>Link Company</h1>
                            </div>
                            <div className="form-check" style={{paddingLeft:0}}>
                                <div className='row'>
                                    <div className='col-1'>
                                        <input className="form-check-input" style={{marginLeft:0}} type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                                    <div className='col-11 p-0'>
                                        <label className="form-check-label" htmlFor="flexCheckDefault" style={{ fontSize: 12 }}>
                                            Only add if you are a director / on board or a major shareholder - above 15%
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <div className="form-group mt-3">
                                        <label className={`form-label ${errors?.country_id ? 'is-invalid' : ' '}`} htmlFor="country_id">Company Country</label>
                                        <select id="country_id" className={`form-control ${errors?.country_id ? 'is-invalid' : ''}`}{...register("country_id", {onChange : (e) => 
                                            handleChange('country_id', e.target.value) // Your custom onChange handler
                                        })}
                                        >
                                            <option>Select Country</option>
                                            {countryData.map((country) => (
                                                <option key={country.country_id} value={country.country_id}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                        {errors?.country_id && (
                                            <div className="invalid-feedback">{errors?.country_id?.message}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <div className="form-group mt-3">
                                        <label className="form-label" htmlFor="company_name">Company Name</label>
                                        <input type="text" className={`form-control ${errors?.company_name ? 'is-invalid' : ''}`} placeholder="Enter company Name" {...register("company_name")} onChange={(e) => {
                                            handleChange('company_name', e.target.value); // Your custom onChange handler
                                        }}/>
                                        {errors?.company_name &&
                                            <span className="invalid-feedback" >Company name is required</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <div className="form-group mt-3">
                                        <label className="form-label" htmlFor="company_reg_no">Company Reg No.</label>
                                        <input type="text" className={`form-control ${errors?.company_reg_no ? 'is-invalid' : ''}`} placeholder="Enter company Reg No." {...register("company_reg_no")} onChange={(e) => {
                                            handleChange('company_reg_no', e.target.value); // Your custom onChange handler
                                        }}/>
                                        {errors?.company_reg_no && <span className="invalid-feedback" >Company Reg No. is required</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <div className="form-group mt-3">
                                        <label className="form-label" htmlFor="designation">Designation</label>
                                        <select id="statusDropdown" className={`form-control ${errors?.designation ? 'is-invalid' : ''}`} {...register("designation", {onChange : (e) => 
                                            handleChange('designation', e.target.value) // Your custom onChange handler
                                        })}>
                                            <option value="">Select Designation</option>
                                            <option value="C-Suite Job (CXO) - Other">C-Suite Job (CXO) - Other</option>
                                            <option value="Chief Commercial Officer (CCO)">Chief Commercial Officer (CCO)</option>
                                            <option value="Chief Executive Officer (CEO)">Chief Executive Officer (CEO)</option>
                                            <option value="Chief Financial Officer (CFO)">Chief Financial Officer (CFO)</option>
                                            <option value="Chief Investment Officer (CIO)">Chief Investment Officer (CIO)</option>
                                            <option value="Chief Marketing Officer (CMO)">Chief Marketing Officer (CMO)</option>
                                            <option value="Chief Operating Officer (COO)">Chief Operating Officer (COO)</option>
                                            <option value="Chief Technical Officer (CTO)">Chief Technical Officer (CTO)</option>
                                            <option value="Director">Director</option>
                                            <option value="Managing Director">Managing Director</option>
                                            <option value="Managing Partner">Managing Partner</option>
                                            <option value="Non-Executive Director">Non-Executive Director</option>
                                            <option value="Partner">Partner</option>
                                        </select>
                                        {errors?.designation &&
                                            <span className="invalid-feedback" >Designation is required</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <div className="form-group mt-3">
                                        <label className="form-label" htmlFor="type_of_director">Type of Director</label>
                                        <select id="statusDropdown" className={`form-control ${errors?.type_of_director ? 'is-invalid' : ''}`} {...register("type_of_director", {onChange : (e) => 
                                            handleChange('type_of_director', e.target.value) // Your custom onChange handler
                                        })}>
                                            <option value="">Select Director Type</option>
                                            <option value="Board Member">Board Member</option>
                                            <option value="non-executive director">Non-executive Director</option>
                                        </select>
                                        {errors?.type_of_director &&
                                            <span className="invalid-feedback" >Type of Director is required</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12 d-flex'>
                                    <div className={`form-group mt-3 form-control ${errors?.shareholder ? 'is-invalid' : ''}`}>
                                        <label className='mb-3' style={{fontSize:14}}>Shareholder</label>
                                        <div className='row mt-1'>
                                            <div className='col-6'>
                                                <div className='border-grey-form'>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="shareholderYes"
                                                            value={true} // Set value to true
                                                            {...register('shareholder')}
                                                            onChange={(e) => {
                                                                handleChange('shareholder', e.target.value); // Your custom onChange handler
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="shareholderYes">Yes</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='border-grey-form'>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="shareholderNo"
                                                            value={false} // Set value to false
                                                            {...register('shareholder')}
                                                            onChange={(e) => {
                                                                handleChange('shareholder', e.target.value); // Your custom onChange handler
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="shareholderNo">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {errors?.shareholder &&
                                        <span className="invalid-feedback" >Shareholder is required</span>
                                    }
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <div className={`form-group mt-3 form-control ${errors?.ultimate_beneficial_owner ? 'is-invalid' : ''}`}>
                                        <label style={{fontSize:14}}>Ultimate Beneficial Owner</label>
                                        <div className='row mt-1'>
                                            <div className='col-6'>
                                                <div className='border-grey-form'>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="beneficialOwnerYes"
                                                            value={true} // Set value to true
                                                            {...register('ultimate_beneficial_owner')}
                                                            onChange={(e) => {
                                                                handleChange('ultimate_beneficial_owner', e.target.value);
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="beneficialOwnerYes">Yes</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='border-grey-form'>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="beneficialOwnerNo"
                                                            value={false} // Set value to false
                                                            {...register('ultimate_beneficial_owner')}
                                                            onChange={(e) => {
                                                                handleChange('ultimate_beneficial_owner', e.target.value);
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="beneficialOwnerNo">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {errors?.ultimate_beneficial_owner &&
                                        <span className="invalid-feedback" >{errors?.ultimate_beneficial_owner?.message}</span>
                                    }
                                    
                                </div>
                            </div>
                            <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <div className="form-group mt-3">
                                                    <label className="form-label" htmlFor="reason">Comment</label><input type="text" className={`form-control ${errors.reason ? 'is-invalid' : ''}`} placeholder="Enter Reason" {...register("reason")} />
                                                    {errors?.reason && (
                                                        <div className="invalid-feedback">{errors?.reason?.message}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                            <div className='d-flex align-items-center justify-content-between mt-3'>
                                {/* <button className='btn-secondary fw-normal' style={{ marginRight: 16 }}>Link Another Account</button> */}
                                <button type="submit" className='btn-primary'>Save</button>
                            </div>
                        </form>
                    </div>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 6, marginRight: 8, cursor: 'pointer' }} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default IndividualCompanyDetails