import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import ThumbIcon from '../../assets/images/thumb_icon.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { decodeToken, useJwt } from "react-jwt";
import permissions from './permissions.json';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createRole, fetchRoleById, updateRole } from './roleSlice';
import Loader from '../../components/Loader/Loader';
import { fetchListOfOomeroEntity } from '../ManageUsers/manageUserSlice';
import BackIcon from '../../assets/images/back-btn-arrow.png';

function AddNewUserRoles() {
  const { oomeroEntityList } = useSelector(state => state.manageUserReducer);
  const [show, setShow] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [readOnlyAll, setReadOnlyAll] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const userToken = localStorage.getItem('token');
  const decodedToken = decodeToken(userToken);
  const { isLoading, roleDetails } = useSelector(state => state.roleReducer);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const routeChange = () => {
    let path = `/user_roles`;
    navigate(path);
  }
  const [selectAllStates, setSelectAllStates] = useState({});
  const [selectedObject, setSelectedObject] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const { isEnterpriseAdmin, isSuperAdmin, isOomero } = decodedToken;

  const schema = yup.object().shape({
    roleName: yup.string().required('Role Name is required'),
    description: yup.string(),
    entity_id: state?.isOomero ? yup.number().required("Please select Company") : yup.number().optional()
  });

  const { control, register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({
    resolver: yupResolver(schema)
  });
  
  useEffect(() => {
    if (state?.isOomero) {
      dispatch(fetchListOfOomeroEntity());
     
    }
  }, [dispatch, state?.isOomero]);

  const [isReadOnly, setIsReadOnly] = useState(false);


  const onSubmit = (data) => {
    let requestData = Object.assign({}, data);
    let payload = {
      roleName: requestData.roleName,
      description: requestData.description,
      entity_id : requestData.entity_id
    };

    delete requestData.roleName;
    delete requestData.description;
    delete requestData.entity_id;
    
    payload.permissions = requestData;

    dispatch(createRole(payload)).unwrap().then((res) => {
      setSuccessMessage('Role added successfully!');
      handleShow();
    }).catch((error) => {
      toast.error(error?.message);
    });
  }

  const onUpdate = (data) => {
    let payload = {
      roleName: data.roleName,
      description: data.description,
      entity_id : data.entity_id
    };

    delete data.roleName;
    delete data.description;
    delete data.entity_id;

    payload.permissions = data;
    dispatch(updateRole({ id: state?.roleId, payload })).unwrap().then((res) => {
      setSuccessMessage('Role Updated Successfully!');
      handleShow();
    }).catch((error) => {
      toast.error(error?.message);
    });
  }

  useEffect(() => {
    if (state?.roleId) {
      dispatch(fetchRoleById(state?.roleId)).unwrap()
        .then((res) => {
          setValue('roleName', res?.data?.role_name);
          if(res?.data?.description){
            setValue('description', res?.data?.description);
          }
          setValue('entity_id', res?.data?.entity_id);
        }).catch((error) => {
          toast.error(error?.message);
        })
    }
  }, [state?.roleId]);

  useEffect(() => {
    // Update the form values if roleDetails changes
    if (roleDetails?.permissions) {
      Object.entries(roleDetails.permissions).forEach(([permission, value]) => {
        setValue(permission, value);
      });
    }
  }, [roleDetails, setValue]);

  useEffect(()=>{
    if(state?.viewOnly){
      setIsReadOnly(true);
    }
  },[]);

  useEffect(() => {
    const newSelectAllStates = {};
    permissions.forEach(obj => {
        let show = obj.checkboxes.filter(checkbox =>
          (checkbox?.visible['isEnterpriseAdmin'] === true && (state?.isEnterpriseAdmin && !state?.isOomero && !state?.isSuperAdmin)) ||
          (checkbox?.visible['isEnterpriseAdmin'] === true && (!state?.isEnterpriseAdmin && !state?.isOomero && !state?.isSuperAdmin)) ||
          (checkbox?.visible['isSuperAdmin'] === true && state?.isSuperAdmin) ||
          (checkbox?.visible['isOomero'] === true && state?.isOomero)
        );
      
      const isAllChecked = show && show.length > 0
        ? show.every(checkbox => roleDetails?.permissions && roleDetails.permissions[checkbox.key])
        : false;
      newSelectAllStates[obj.header] = isAllChecked;
    });
    setSelectAllStates(newSelectAllStates);
  }, [roleDetails, permissions]);

  const onChangeFunc = (header, checkboxes) => {
    setSelectAllStates(prevStates => ({
      ...prevStates,
      [header]: !prevStates[header],
    }));

    checkboxes.forEach(checkbox => {
      setValue(checkbox.key, !selectAllStates[header]);
    });
  }

  useEffect(() => {
    const formValues = getValues();
    if (Object.keys(selectedObject).length > 0) {
      const isAllChecked = selectedObject?.checkboxes.every(obj => formValues[obj['key']]);
      setSelectAllStates({
        ...selectAllStates,
        [selectedObject.header]: isAllChecked,
      });
    }
  }, [selectedObject, getValues, setValue]);

  const handleCheckboxChange = (obj) => {
    setSelectedObject({ ...obj });
  }
  const navigateToPrevious = () => {
    navigate(-1);
  }

  return (
    <div className='grey-background'>
      {isLoading && <Loader/>}
      <button onClick={navigateToPrevious} className='back-button text-blue'><img src={BackIcon} style={{width:8,marginBottom:2,marginRight:4,objectFit:'contain'}}/> Back</button>
      <form onSubmit={handleSubmit((state?.roleId)? onUpdate:onSubmit)}>
        <div className='white-background p-3 my-3'>
          <h2 className='text-blue fw-normal pt-2'>{(state?.roleId)?'Edit ':'Add New '}Roles</h2>
          <hr />
            <div className="row g-3 mb-3">
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label className="form-label" for="exampleInputEmail1">Role Name</label>
                <input
                  type="text"
                  className={`form-control form-control-lg ${errors.roleName ? 'is-invalid' : ''}`}
                  placeholder="Role Name" disabled={isReadOnly || (Object.keys(roleDetails).length > 0 && roleDetails?.is_editable === false)}
                  {...register('roleName')}
                />
                {errors?.roleName && (
                  <div className="invalid-feedback">{errors?.roleName?.message}</div>
                )}
                </div>
              </div>
            {state?.isOomero ? (
              <div className="col-lg-6 col-md-12">
                <div className="form-group ">
                  <label className="form-label" >Assign to Oomero Company</label>
                  <Controller
                    name="entity_id"
                    control={control}
                    render={({ field }) => (
                      <select
                        id="entityDropdown"
                        className={`form-control form-select ${errors.entity_id ? 'is-invalid' : ''}`}
                        {...field}
                        onChange={(e) => {
                          setSelectedEntity(e.target.value);
                          field.onChange(e);
                        }}
                        disabled={isReadOnly}
                      >
                        <option value={''}>Select Company Name</option>
                        {oomeroEntityList && oomeroEntityList.length > 0 &&
                          oomeroEntityList.map((res, i) => (
                            <option key={'entityId' + i} value={res?.entity_id}>
                              {res?.entity_name}
                            </option>
                          ))}
                      </select>
                    )}
                  />
                  {errors?.entity_id && (
                    <div className="invalid-feedback">{errors?.entity_id?.message}</div>
                  )}
                </div>
              </div> ) : state?.roleId && (
                  <Controller
                    name="entity_id"
                    control={control}
                    defaultValue={roleDetails?.entity_id} // You should set entityId with the desired value
                    render={({ field }) => (
                      <input
                        type="hidden"
                        {...field}
                        value={roleDetails?.entity_id} // Ensure entityId has the correct value
                      />
                    )}
                  />
                )}
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label className="form-label" for="exampleInputEmail1">Description</label>

                  <textarea disabled={isReadOnly} className={`form-control form-control-lg ${errors.description ? 'is-invalid' : ''}`} placeholder="Description" rows={3} {...register('description')}></textarea>
                  {errors?.description && (
                    <div className="invalid-feedback">{errors?.description?.message}</div>
                  )}
                </div>
              </div>
            </div>
        </div>
        <div className='white-background p-3'>
          <h2 className='text-blue fw-normal pt-2'>Permission Assigned </h2>
          <hr />
          {
            permissions.length > 0 && permissions.map((obj, index) => {
              let show = obj.checkboxes.filter(checkbox => {
                return ((checkbox?.visible['isEnterpriseAdmin'] === true && (state?.isEnterpriseAdmin && !state?.isOomero && !state?.isSuperAdmin)) ||
                (checkbox?.visible['isEnterpriseAdmin'] === true && (!state?.isEnterpriseAdmin && !state?.isOomero && !state?.isSuperAdmin)) ||
                (checkbox?.visible['isSuperAdmin'] === true && state?.isSuperAdmin) ||
                (checkbox?.visible['isOomero'] === true && state?.isOomero))
              });
              return show.length > 0 && (<>
                <h2 className='text-blue fw-normal pt-2'>{obj.header}</h2>
                <div className="row p-2">
                  <div className='col-lg-3 col-md-12 my-3' >
                    <div className="form-check">
                      <input type="checkbox" name={obj.header.toLowerCase().replace(/\s/g, '_')} className="form-check-input" checked={selectAllStates[obj.header] || false} onChange={() => onChangeFunc(obj.header, show)} disabled={isReadOnly} />
                      <label className="form-check-label" for="defaultCheck1">
                        All
                      </label>
                    </div>
                  </div>
                  {obj.checkboxes.map((checkbox, checkboxIndex) => (
                    ((checkbox?.visible['isEnterpriseAdmin'] === true && (!state?.isEnterpriseAdmin && !state?.isOomero && !state?.isSuperAdmin)) || (checkbox?.visible['isEnterpriseAdmin'] === true && (state?.isEnterpriseAdmin && !state?.isOomero && !state?.isSuperAdmin)) || (checkbox?.visible['isSuperAdmin'] === true && state?.isSuperAdmin) || (checkbox?.visible['isOomero'] === true && state?.isOomero)) && (<div className='col-lg-3 col-md-12 my-3' key={checkboxIndex}>
                      <div className="form-check">
                        <Controller
                          name={checkbox?.key}
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={checkbox?.key}
                              disabled={isReadOnly}
                              checked={field.value !== undefined ? field.value : checkbox?.defaultValue || false}
                              onChange={(e) => {
                                handleCheckboxChange({ ...obj, checkboxes: show }, e.target.checked);
                                field.onChange(e);
                              }}
                            />
                          )}
                        />

                        <label className="form-check-label" for="defaultCheck1">
                          {checkbox?.label}
                        </label>
                      </div>
                    </div>)
                  ))}
                </div>
              </>)
            })
          }

          {/* <h2 className='text-blue fw-normal pt-2'>Dasboard</h2>
            <hr />
            <div className="row p-2">
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">                  
                  <Controller
                    name="view_screening_dashboard"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="view_screening_dashboard"
                        {...field}
                      />
                    )}
                  />

                  {errors?.view_screening_dashboard && (
                    <div className="invalid-feedback">{errors?.view_screening_dashboard?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    View Screening
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                      name="print_screening_dashboard"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="print_screening_dashboard"
                          {...field}
                        />
                      )}
                    />

                    {errors?.print_screening_dashboard && (
                      <div className="invalid-feedback">{errors?.print_screening_dashboard?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print Screening
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                      name="edit_screening_dashboard"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="edit_screening_dashboard"
                          {...field}
                        />
                      )}
                    />

                    {errors?.edit_screening_dashboard && (
                      <div className="invalid-feedback">{errors?.edit_screening_dashboard?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    Edit Screening
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                      name="start_screening_dashboard"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="start_screening_dashboard"
                          {...field}
                        />
                      )}
                    />

                    {errors?.start_screening_dashboard && (
                      <div className="invalid-feedback">{errors?.start_screening_dashboard?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    Start Screening
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                      name="continuous_monitoring_screening_dashboard"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="continuous_monitoring_screening_dashboard"
                          {...field}
                        />
                      )}
                    />

                    {errors?.continuous_monitoring_screening_dashboard && (
                      <div className="invalid-feedback">{errors?.continuous_monitoring_screening_dashboard?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    Continuous Monitoring (on/off)
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                      name="add_general_comments_screening_dashboard"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="add_general_comments_screening_dashboard"
                          {...field}
                        />
                      )}
                    />

                    {errors?.add_general_comments_screening_dashboard && (
                      <div className="invalid-feedback">{errors?.add_general_comments_screening_dashboard?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    Add General Comments
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                      name="add_aml_comments_screening_dashboard"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="add_aml_comments_screening_dashboard"
                          {...field}
                        />
                      )}
                    />

                    {errors?.add_aml_comments_screening_dashboard && (
                      <div className="invalid-feedback">{errors?.add_aml_comments_screening_dashboard?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    Add AML Comments
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                      name="edit_profile_screening_dashboard"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="edit_profile_screening_dashboard"
                          {...field}
                        />
                      )}
                    />

                    {errors?.edit_profile_screening_dashboard && (
                      <div className="invalid-feedback">{errors?.edit_profile_screening_dashboard?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    Edit Profile
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                      name="print_general_comments_screening_dashboard"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="print_general_comments_screening_dashboard"
                          {...field}
                        />
                      )}
                    />

                    {errors?.print_general_comments_screening_dashboard && (
                      <div className="invalid-feedback">{errors?.print_general_comments_screening_dashboard?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print General Comments
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                      name="print_aml_comments_screening_dashboard"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="print_aml_comments_screening_dashboard"
                          {...field}
                        />
                      )}
                    />

                    {errors?.print_aml_comments_screening_dashboard && (
                      <div className="invalid-feedback">{errors?.print_aml_comments_screening_dashboard?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print AML Comments
                  </label>
                </div>
              </div>
            </div>


            <h2 className='text-blue fw-normal pt-2'>Screening Corporate</h2>
            <hr />
            <div className="row p-2">
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                    <Controller
                      name="view_corporate_screening"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="view_corporate_screening"
                          {...field}
                        />
                      )}
                    />

                    {errors?.view_corporate_screening && (
                      <div className="invalid-feedback">{errors?.view_corporate_screening?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    View Screening
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                    <Controller
                      name="print_corporate_screening"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="print_corporate_screening"
                          {...field}
                        />
                      )}
                    />
                    {errors?.print_corporate_screening && (
                      <div className="invalid-feedback">{errors?.print_corporate_screening?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print Screening
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="edit_corporate_screening"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="edit_corporate_screening"
                        {...field}
                      />
                    )}
                  />
                  {errors?.edit_corporate_screening && (
                    <div className="invalid-feedback">{errors?.edit_corporate_screening?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Edit Screening
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                    <Controller
                      name="start_corporate_screening"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="start_corporate_screening"
                          {...field}
                        />
                      )}
                    />
                    {errors?.start_corporate_screening && (
                      <div className="invalid-feedback">{errors?.start_corporate_screening?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    Start Screening
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="continuous_monitoring_corporate_screening"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="continuous_monitoring_corporate_screening"
                        {...field}
                      />
                    )}
                  />
                  {errors?.continuous_monitoring_corporate_screening && (
                    <div className="invalid-feedback">{errors?.continuous_monitoring_corporate_screening?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Continuous Monitoring (on/off)
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="add_general_comments_corporate_screening"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="add_general_comments_corporate_screening"
                        {...field}
                      />
                    )}
                  />
                  {errors?.add_general_comments_corporate_screening && (
                    <div className="invalid-feedback">{errors?.add_general_comments_corporate_screening?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Add General Comments
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="add_aml_comments_corporate_screening"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="add_aml_comments_corporate_screening"
                        {...field}
                      />
                    )}
                  />
                  {errors?.add_aml_comments_corporate_screening && (
                    <div className="invalid-feedback">{errors?.add_aml_comments_corporate_screening?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Add AML Comments
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="edit_profile_corporate_screening"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="edit_profile_corporate_screening"
                        {...field}
                      />
                    )}
                  />
                  {errors?.edit_profile_corporate_screening && (
                    <div className="invalid-feedback">{errors?.edit_profile_corporate_screening?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Edit Profile
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="print_general_comments_corporate_screening"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="print_general_comments_corporate_screening"
                        {...field}
                      />
                    )}
                  />
                  {errors?.print_general_comments_corporate_screening && (
                    <div className="invalid-feedback">{errors?.print_general_comments_corporate_screening?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print General Comments
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="print_aml_comments_corporate_screening"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="print_aml_comments_corporate_screening"
                        {...field}
                      />
                    )}
                  />
                  {errors?.print_aml_comments_corporate_screening && (
                    <div className="invalid-feedback">{errors?.print_aml_comments_corporate_screening?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print AML Comments
                  </label>
                </div>
              </div>
            </div>

            <h2 className='text-blue fw-normal pt-2'>Onboarding</h2>
            <hr />
            <div className="row p-2">
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="create_individual_onboarding"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="create_individual_onboarding"
                        {...field}
                      />
                    )}
                  />
                  {errors?.create_individual_onboarding && (
                    <div className="invalid-feedback">{errors?.create_individual_onboarding?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Create Individual
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="create_business_onboarding"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="create_business_onboarding"
                        {...field}
                      />
                    )}
                  />
                  {errors?.create_business_onboarding && (
                    <div className="invalid-feedback">{errors?.create_business_onboarding?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Create Business
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="create_asset_onboarding"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="create_asset_onboarding"
                        {...field}
                      />
                    )}
                  />
                  {errors?.create_asset_onboarding && (
                    <div className="invalid-feedback">{errors?.create_asset_onboarding?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Create Asset
                  </label>
                </div>
              </div>
            </div>

            <h2 className='text-blue fw-normal pt-2'>Package</h2>
            <hr />
            <div className="row p-2">
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="create_package"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="create_package"
                        {...field}
                      />
                    )}
                  />
                  {errors?.create_package && (
                    <div className="invalid-feedback">{errors?.create_package?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Create Package
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="view_package"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="view_package"
                        {...field}
                      />
                    )}
                  />
                  {errors?.view_package && (
                    <div className="invalid-feedback">{errors?.view_package?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    View Package
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="edit_package"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="edit_package"
                        {...field}
                      />
                    )}
                  />
                  {errors?.edit_package && (
                    <div className="invalid-feedback">{errors?.edit_package?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Edit Package
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="deactivate_package"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="deactivate_package"
                        {...field}
                      />
                    )}
                  />
                  {errors?.deactivate_package && (
                    <div className="invalid-feedback">{errors?.deactivate_package?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Deactive Package
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="delete_package"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="delete_package"
                        {...field}
                      />
                    )}
                  />
                  {errors?.delete_package && (
                    <div className="invalid-feedback">{errors?.delete_package?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Delete Package
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="assign_package"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="assign_package"
                        {...field}
                      />
                    )}
                  />
                  {errors?.assign_package && (
                    <div className="invalid-feedback">{errors?.assign_package?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Assign Package
                  </label>
                </div>
              </div>
            </div>

            <h2 className='text-blue fw-normal pt-2'>View Client Billing</h2>
            <hr />
            <div className="row p-2">
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="print_client_invoice"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="print_client_invoice"
                        {...field}
                      />
                    )}
                  />
                  {errors?.print_client_invoice && (
                    <div className="invalid-feedback">{errors?.print_client_invoice?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print Client Invoice
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="print_all_invoice"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="print_all_invoice"
                        {...field}
                      />
                    )}
                  />
                  {errors?.print_all_invoice && (
                    <div className="invalid-feedback">{errors?.print_all_invoice?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print all invoices
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="download_all_invoice"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="download_all_invoice"
                        {...field}
                      />
                    )}
                  />
                  {errors?.download_all_invoice && (
                    <div className="invalid-feedback">{errors?.download_all_invoice?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Download All invoices
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="download_invoice"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="download_invoice"
                        {...field}
                      />
                    )}
                  />
                  {errors?.download_invoice && (
                    <div className="invalid-feedback">{errors?.download_invoice?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Download Invoice
                  </label>
                </div>
              </div>
            </div>

            <h2 className='text-blue fw-normal pt-2'>Roles</h2>
            <hr />
            <div className="row p-2">
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="create_user_role"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="create_user_role"
                        {...field}
                      />
                    )}
                  />
                  {errors?.create_user_role && (
                    <div className="invalid-feedback">{errors?.create_user_role?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Create Role
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="edit_user_role"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="edit_user_role"
                        {...field}
                      />
                    )}
                  />
                  {errors?.edit_user_role && (
                    <div className="invalid-feedback">{errors?.edit_user_role?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Edit Role
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="view_user_role"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="view_user_role"
                        {...field}
                      />
                    )}
                  />
                  {errors?.view_user_role && (
                    <div className="invalid-feedback">{errors?.view_user_role?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    View Role
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="deactivate_user_role"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="deactivate_user_role"
                        {...field}
                      />
                    )}
                  />
                  {errors?.deactivate_user_role && (
                    <div className="invalid-feedback">{errors?.deactivate_user_role?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Deactivate role
                  </label>
                </div>
              </div>
            </div>

            <h2 className='text-blue fw-normal pt-2'>FX Rate</h2>
            <hr />
            <div className="row p-2">
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="create_fx_rate"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="create_fx_rate"
                        {...field}
                      />
                    )}
                  />
                  {errors?.create_fx_rate && (
                    <div className="invalid-feedback">{errors?.create_fx_rate?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Create FX Rate
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="fetch_fx_rate"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="fetch_fx_rate"
                        {...field}
                      />
                    )}
                  />
                  {errors?.fetch_fx_rate && (
                    <div className="invalid-feedback">{errors?.fetch_fx_rate?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    View FX Rate
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="fetch_detail_fx_rate"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="fetch_detail_fx_rate"
                        {...field}
                      />
                    )}
                  />
                  {errors?.fetch_detail_fx_rate && (
                    <div className="invalid-feedback">{errors?.fetch_detail_fx_rate?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    View FX Rate Detail
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="edit_fx_rate"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="edit_fx_rate"
                        {...field}
                      />
                    )}
                  />
                  {errors?.edit_fx_rate && (
                    <div className="invalid-feedback">{errors?.edit_fx_rate?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Edit FX Rate
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="delete_fx_rate"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="delete_fx_rate"
                        {...field}
                      />
                    )}
                  />
                  {errors?.delete_fx_rate && (
                    <div className="invalid-feedback">{errors?.delete_fx_rate?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Delete FX Rate
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="print_current_rates"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="print_current_rates"
                        {...field}
                      />
                    )}
                  />
                  {errors?.print_current_rates && (
                    <div className="invalid-feedback">{errors?.print_current_rates?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print FX Current Rate
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="print_fx_history"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="print_fx_history"
                        {...field}
                      />
                    )}
                  />
                  {errors?.print_fx_history && (
                    <div className="invalid-feedback">{errors?.print_fx_history?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print FX History
                  </label>
                </div>
              </div>
            </div>

            <h2 className='text-blue fw-normal pt-2'>Tax Rate</h2>
            <hr />
            <div className="row p-2">
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="create_tax"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="create_tax"
                        {...field}
                      />
                    )}
                  />
                  {errors?.create_tax && (
                    <div className="invalid-feedback">{errors?.create_tax?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Create Tax
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="edit_tax"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="edit_tax"
                        {...field}
                      />
                    )}
                  />
                  {errors?.edit_tax && (
                    <div className="invalid-feedback">{errors?.edit_tax?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Edit Tax
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="print_tax"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="print_tax"
                        {...field}
                      />
                    )}
                  />
                  {errors?.print_tax && (
                    <div className="invalid-feedback">{errors?.print_tax?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print Tax
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="delete_tax"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="delete_tax"
                        {...field}
                      />
                    )}
                  />
                  {errors?.delete_tax && (
                    <div className="invalid-feedback">{errors?.delete_tax?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Delete Tax
                  </label>
                </div>
              </div>
            </div>

            <h2 className='text-blue fw-normal pt-2'>Email masters</h2>
            <hr />
            <div className="row p-2">
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="create_email_master"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="create_email_master"
                        {...field}
                      />
                    )}
                  />
                  {errors?.create_email_master && (
                    <div className="invalid-feedback">{errors?.create_email_master?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Create Email Master
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="view_email_master"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="view_email_master"
                        {...field}
                      />
                    )}
                  />
                  {errors?.view_email_master && (
                    <div className="invalid-feedback">{errors?.view_email_master?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    View Email Master
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                    <Controller
                      name="edit_email_master"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="edit_email_master"
                          {...field}
                        />
                      )}
                    />
                    {errors?.edit_email_master && (
                      <div className="invalid-feedback">{errors?.edit_email_master?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    Edit Email Master
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                    <Controller
                      name="print_email_master"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="print_email_master"
                          {...field}
                        />
                      )}
                    />
                    {errors?.print_email_master && (
                      <div className="invalid-feedback">{errors?.print_email_master?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print Email Master
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                    <Controller
                      name="deactivate_email_master"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="deactivate_email_master"
                          {...field}
                        />
                      )}
                    />
                    {errors?.deactivate_email_master && (
                      <div className="invalid-feedback">{errors?.deactivate_email_master?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    Deactivate Email Master
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                    <Controller
                      name="delete_email_master"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="delete_email_master"
                          {...field}
                        />
                      )}
                    />
                    {errors?.delete_email_master && (
                      <div className="invalid-feedback">{errors?.delete_email_master?.message}</div>
                    )}
                  <label className="form-check-label" for="defaultCheck1">
                    Delete Email Master
                  </label>
                </div>
              </div>
            </div>

            <h2 className='text-blue fw-normal pt-2'>Manage Clients</h2>
            <hr />
            <div className="row p-2">
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="setup_new_client"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="setup_new_client"
                        {...field}
                      />
                    )}
                  />
                  {errors?.setup_new_client && (
                    <div className="invalid-feedback">{errors?.setup_new_client?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Setup New Client
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="view_client"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="view_client"
                        {...field}
                      />
                    )}
                  />
                  {errors?.view_client && (
                    <div className="invalid-feedback">{errors?.view_client?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    View Client Details
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="edit_client"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="edit_client"
                        {...field}
                      />
                    )}
                  />
                  {errors?.edit_client && (
                    <div className="invalid-feedback">{errors?.edit_client?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Edit Client Details
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="print_client"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="print_client"
                        {...field}
                      />
                    )}
                  />
                  {errors?.print_client && (
                    <div className="invalid-feedback">{errors?.print_client?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print Client Details
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="reactivate_client"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="reactivate_client"
                        {...field}
                      />
                    )}
                  />
                  {errors?.reactivate_client && (
                    <div className="invalid-feedback">{errors?.reactivate_client?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Reactivate Client Details
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="deactivate_client"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="deactivate_client"
                        {...field}
                      />
                    )}
                  />
                  {errors?.deactivate_client && (
                    <div className="invalid-feedback">{errors?.deactivate_client?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Deactivate Client Details
                  </label>
                </div>
              </div>
            </div>

          <h2 className='text-blue fw-normal pt-2'>Manage Users</h2>
          <hr />
          <div className="row p-2">
            <div className='col-lg-3 col-md-12 my-3'>
              <div className="form-check">
                <Controller
                  name="add_oomero_user"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="add_oomero_user"
                      {...field}
                    />
                  )}
                />
                {errors?.add_oomero_user && (
                  <div className="invalid-feedback">{errors?.add_oomero_user?.message}</div>
                )}
                <label className="form-check-label" for="defaultCheck1">
                  Add Oomero User
                </label>
              </div>
            </div>
            <div className='col-lg-3 col-md-12 my-3'>
              <div className="form-check">
                <Controller
                  name="edit_oomero_user"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="edit_oomero_user"
                      {...field}
                    />
                  )}
                />
                {errors?.edit_oomero_user && (
                  <div className="invalid-feedback">{errors?.edit_oomero_user?.message}</div>
                )}
                <label className="form-check-label" for="defaultCheck1">
                  Edit Oomero User
                </label>
              </div>
            </div>
            <div className='col-lg-3 col-md-12 my-3'>
              <div className="form-check">
                <Controller
                  name="print_oomero_user"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="print_oomero_user"
                      {...field}
                    />
                  )}
                />
                {errors?.print_oomero_user && (
                  <div className="invalid-feedback">{errors?.print_oomero_user?.message}</div>
                )}
                <label className="form-check-label" for="defaultCheck1">
                  Print Oomero User
                </label>
              </div>
            </div>
            <div className='col-lg-3 col-md-12 my-3'>
              <div className="form-check">
                <Controller
                  name="reactivate_oomero_user"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="reactivate_oomero_user"
                      {...field}
                    />
                  )}
                />
                {errors?.reactivate_oomero_user && (
                  <div className="invalid-feedback">{errors?.reactivate_oomero_user?.message}</div>
                )}
                <label className="form-check-label" for="defaultCheck1">
                  Reactivate Oomero User
                </label>
              </div>
            </div>
            <div className='col-lg-3 col-md-12 my-3'>
              <div className="form-check">
                <Controller
                  name="deactivate_oomero_user"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="deactivate_oomero_user"
                      {...field}
                    />
                  )}
                />
                {errors?.deactivate_oomero_user && (
                  <div className="invalid-feedback">{errors?.deactivate_oomero_user?.message}</div>
                )}
                <label className="form-check-label" for="defaultCheck1">
                  Deactivate Oomero User
                </label>
              </div>
            </div>
            <div className='col-lg-3 col-md-12 my-3'>
              <div className="form-check">
                <Controller
                  name="delete_oomero_user"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="delete_oomero_user"
                      {...field}
                    />
                  )}
                />
                {errors?.delete_oomero_user && (
                  <div className="invalid-feedback">{errors?.delete_oomero_user?.message}</div>
                )}
                <label className="form-check-label" for="defaultCheck1">
                  Delete Oomero User
                </label>
              </div>
            </div>
            <div className='col-lg-3 col-md-12 my-3'>
              <div className="form-check">
                <Controller
                  name="add_client_user"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="add_client_user"
                      {...field}
                    />
                  )}
                />
                {errors?.add_client_user && (
                  <div className="invalid-feedback">{errors?.add_client_user?.message}</div>
                )}
                <label className="form-check-label" for="defaultCheck1">
                  Add User
                </label>
              </div>
            </div>
            <div className='col-lg-3 col-md-12 my-3'>
              <div className="form-check">
                <Controller
                  name="edit_client_user"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="edit_client_user"
                      {...field}
                    />
                  )}
                />
                {errors?.edit_client_user && (
                  <div className="invalid-feedback">{errors?.edit_client_user?.message}</div>
                )}
                <label className="form-check-label" for="defaultCheck1">
                  Edit User
                </label>
              </div>
            </div>
            <div className='col-lg-3 col-md-12 my-3'>
              <div className="form-check">
                <Controller
                  name="print_client_user"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="print_client_user"
                      {...field}
                    />
                  )}
                />
                {errors?.print_client_user && (
                  <div className="invalid-feedback">{errors?.print_client_user?.message}</div>
                )}
                <label className="form-check-label" for="defaultCheck1">
                  Print User
                </label>
              </div>
            </div>
            <div className='col-lg-3 col-md-12 my-3'>
              <div className="form-check">
                <Controller
                  name="reactivate_client_user"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="reactivate_client_user"
                      {...field}
                    />
                  )}
                />
                {errors?.reactivate_client_user && (
                  <div className="invalid-feedback">{errors?.reactivate_client_user?.message}</div>
                )}
                <label className="form-check-label" for="defaultCheck1">
                  Reactivate User
                </label>
              </div>
            </div>
            <div className='col-lg-3 col-md-12 my-3'>
              <div className="form-check">
                <Controller
                  name="deactivate_client_user"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="deactivate_client_user"
                      {...field}
                    />
                  )}
                />
                {errors?.deactivate_client_user && (
                  <div className="invalid-feedback">{errors?.deactivate_client_user?.message}</div>
                )}
                <label className="form-check-label" for="defaultCheck1">
                  Deactivate User
                </label>
              </div>
            </div>
            <div className='col-lg-3 col-md-12 my-3'>
              <div className="form-check">
                <Controller
                  name="delete_client_user"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="delete_client_user"
                      {...field}
                    />
                  )}
                />
                {errors?.delete_client_user && (
                  <div className="invalid-feedback">{errors?.delete_client_user?.message}</div>
                )}
                <label className="form-check-label" for="defaultCheck1">
                  Delete User
                </label>
              </div>
            </div>
          </div>

          <h2 className='text-blue fw-normal pt-2'>Company Check</h2>
          <hr />
          <div className="row p-2">
            <div className='col-lg-3 col-md-12 my-3'>
              <div className="form-check">
                <Controller
                  name="company_check"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="company_check"
                      {...field}
                    />
                  )}
                />
                {errors?.company_check && (
                  <div className="invalid-feedback">{errors?.company_check?.message}</div>
                )}
                <label className="form-check-label" for="defaultCheck1">
                  Company Check
                </label>
              </div>
            </div>
          </div>
          <h2 className='text-blue fw-normal pt-2'>AML Check</h2>
          <hr />
          <div className="row p-2">
            <div className='col-lg-3 col-md-12 my-3'>
              <div className="form-check">
                <Controller
                  name="aml_check"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="aml_check"
                      {...field}
                    />
                  )}
                />
                {errors?.aml_check && (
                  <div className="invalid-feedback">{errors?.aml_check?.message}</div>
                )}
                <label className="form-check-label" for="defaultCheck1">
                  AML Check
                </label>
              </div>
            </div>
          </div>

            <h2 className='text-blue fw-normal pt-2'>Ghost Mode</h2>
            <hr />
            <div className="row p-2">
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="create_ghost_role"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="create_ghost_role"
                        {...field}
                      />
                    )}
                  />
                  {errors?.create_ghost_role && (
                    <div className="invalid-feedback">{errors?.create_ghost_role?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Create Ghost Role
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="edit_ghost_role"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="edit_ghost_role"
                        {...field}
                      />
                    )}
                  />
                  {errors?.edit_ghost_role && (
                    <div className="invalid-feedback">{errors?.edit_ghost_role?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Edit Ghost Role
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="add_ghost_comment"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="add_ghost_comment"
                        {...field}
                      />
                    )}
                  />
                  {errors?.add_ghost_comment && (
                    <div className="invalid-feedback">{errors?.add_ghost_comment?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Add Ghost Comments
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="print_ghost_comment"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="print_ghost_comment"
                        {...field}
                      />
                    )}
                  />
                  {errors?.print_ghost_comment && (
                    <div className="invalid-feedback">{errors?.print_ghost_comment?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print Ghost Comments
                  </label>
                </div>
              </div>
            </div>

            <h2 className='text-blue fw-normal pt-2'>Indivduals Screening</h2>
            <hr />
            <div className="row p-2">
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="view_individual_screening"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="view_individual_screening"
                        {...field}
                      />
                    )}
                  />
                  {errors?.view_individual_screening && (
                    <div className="invalid-feedback">{errors?.view_individual_screening?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    View details
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="edit_individual_screening"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="edit_individual_screening"
                        {...field}
                      />
                    )}
                  />
                  {errors?.edit_individual_screening && (
                    <div className="invalid-feedback">{errors?.edit_individual_screening?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Edit Details
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="delete_individual_screening"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="delete_individual_screening"
                        {...field}
                      />
                    )}
                  />
                  {errors?.delete_individual_screening && (
                    <div className="invalid-feedback">{errors?.delete_individual_screening?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Delete Details
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="deactivate_individual_screening"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="deactivate_individual_screening"
                        {...field}
                      />
                    )}
                  />
                  {errors?.deactivate_individual_screening && (
                    <div className="invalid-feedback">{errors?.deactivate_individual_screening?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Deactivate Details
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="continuous_monitoring_individual_screening"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="continuous_monitoring_individual_screening"
                        {...field}
                      />
                    )}
                  />
                  {errors?.continuous_monitoring_individual_screening && (
                    <div className="invalid-feedback">{errors?.continuous_monitoring_individual_screening?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Continuous Monitoring (on/off)
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="print_individual_screening"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="print_individual_screening"
                        {...field}
                      />
                    )}
                  />
                  {errors?.print_individual_screening && (
                    <div className="invalid-feedback">{errors?.print_individual_screening?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print Individual Screening
                  </label>
                </div>
              </div>
          </div>

          <h2 className='text-blue fw-normal pt-2'>My Account</h2>
          <hr />
          <div className="row p-2">
            <div className='col-lg-3 col-md-12 my-3'>
              <div className="form-check">
                <Controller
                  name="view_invoices"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="view_invoices"
                      {...field}
                    />
                  )}
                />
                {errors?.view_invoices && (
                  <div className="invalid-feedback">{errors?.view_invoices?.message}</div>
                )}
                <label className="form-check-label" for="defaultCheck1">
                  View Invoices
                </label>
              </div>
            </div>
            <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="view_audit_report"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="view_audit_report"
                        {...field}
                      />
                    )}
                  />
                  {errors?.view_audit_report && (
                    <div className="invalid-feedback">{errors?.view_audit_report?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    View Audit Report
                  </label>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 my-3'>
                <div className="form-check">
                  <Controller
                    name="print_audit_report"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="print_audit_report"
                        {...field}
                      />
                    )}
                  />
                  {errors?.print_audit_report && (
                    <div className="invalid-feedback">{errors?.print_audit_report?.message}</div>
                  )}
                  <label className="form-check-label" for="defaultCheck1">
                    Print Audit Report
                  </label>
                </div>
              </div>
          </div> */}

        </div>
        <div className="d-flex flex-row-reverse">
          <button type="submit" disabled={isReadOnly} className="btn btn-primary mt-3 mb-1">Save Role</button>
        </div>
      </form>

      <Modal show={show} onHide={handleClose} centered >
        <Modal.Body>
          <div className='d-flex justify-content-center'>
            <img src={ThumbIcon} style={{ width: 70, height: 70, objectFit: 'contain' }} />
          </div>
          <h3 className='text-blue text-center mt-3 mb-0 fw-normal'>{successMessage}</h3>
          <div className="d-flex justify-content-center">
            <button onClick={routeChange} className="btn-primary w-50 my-3">Ok</button>
          </div>
        </Modal.Body>
      </Modal>


    </div>
  )
}

export default AddNewUserRoles
