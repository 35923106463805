import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import CompnayDetails from './ScreeningTabs/CompnayDetails'
import BankDetails from './ScreeningTabs/BankDetails'
import DocumentsDetails from './ScreeningTabs/DocumentsDetails'
import AmlDetails from './ScreeningTabs/AmlDetails'
import DirectorAndShareholderList from './ScreeningTabs/DirectorAndShareholderList'
import ScreenInfo from './ScreeningTabs/ScreenInfo'
import AuditTrail from './ScreeningTabs/AuditTrail'
import OtherInfo from './ScreeningTabs/OtherInfo'

function ScreeningCompanyDetails() {
  return (
    <div className='grey-background'>
      <div className='white-background px-3 py-3 oomero-tabs'>
        <Tabs defaultActiveKey="1" className="corporate-tabs" >
          <Tab eventKey="1" title="Company Info">
            <CompnayDetails />
          </Tab>
          <Tab eventKey="2" title="Bank Details">
            <BankDetails />
          </Tab>
          <Tab eventKey="3" title="Documents">
            <DocumentsDetails />
          </Tab>
          <Tab eventKey="4" title="AML Info">
            <AmlDetails />
          </Tab>
          <Tab eventKey="5" title="Dirs / Shldrs">
            <DirectorAndShareholderList />
          </Tab>
          <Tab eventKey="6" title="Screening Action">
            <ScreenInfo />
          </Tab>
          <Tab eventKey="7" title="Audit Trail">
            <AuditTrail />
          </Tab>
          <Tab eventKey="8" title="Old Data">
            <OtherInfo /> 
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default ScreeningCompanyDetails
