import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    isLoading: false,
    error: null,
    clientList: [],
}

export const fetchClientList = createAsyncThunk("entity/getAllEntities", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAllEntities + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const changeEntityStatus = createAsyncThunk("entity/changeEntityStatus", async (requestBody) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.changeEntityStatus,
            data: requestBody
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

const manageClientSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchClientList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.clientList = payload?.data;
            })
            .addCase(changeEntityStatus.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })

            .addMatcher(isAnyOf(fetchClientList.pending, changeEntityStatus.pending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(fetchClientList.rejected, changeEntityStatus.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again ";

            })
    }
});

const manageClientReducer = manageClientSlice.reducer;

export default manageClientReducer;