import React from 'react'
import AuditTrailIcon from '../../../assets/images/audit_trail.png'
import { Tab, Tabs } from 'react-bootstrap'

function AuditTrail() {
    return (
        <div>
            <div className='d-flex align-items-center mt-3'>
                <img src={AuditTrailIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                <h2 className='text-success text-center'>Audit Trail</h2>
            </div>
            <hr />
            <div className='oomero-tabs'>
                <Tabs defaultActiveKey="1" className="corporate-tabs" >
                    <Tab eventKey="1" title="Open">
                        <div className='custom-table mt-4'>
                            <div className='custom-table-head'>
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <h6 className='text-blue'>Action Date Time</h6>
                                    </div>
                                    <div className='col-lg-2'>
                                        <h6 className='text-blue'>Action Type</h6>
                                    </div>
                                    <div className='col-lg-6'>
                                        <h6 className='text-blue'>Action Description</h6>
                                    </div>
                                    <div className='col-lg-2'>
                                        <h6 className='text-blue'>User Details</h6>
                                    </div>
                                </div>
                            </div>
                            {[
                                1, 2, 3, 4, 5, 6, 7
                            ].map((item, i) =>
                                <div className='custom-table-body'>
                                    <div className='row'>
                                        <div className='col-lg-2'>
                                            <h4 className='text-black'>26/07/2020</h4>
                                            <h4 className='text-black'>12:22:00</h4>
                                        </div>
                                        <div className='col-lg-2'>
                                            <h4 className='text-black'>Document</h4>
                                        </div>
                                        <div className='col-lg-6'>
                                            <h4 className='text-black'>Maker Uploaded the new passport from user name MAKER </h4>
                                            <h4 className='text-black fw-bold mt-2'>Comment:</h4>
                                            <h4 className='text-black mt-2'>Checker check the document and found photo copy not maching</h4>
                                        </div>
                                        <div className='col-lg-2'>
                                            <h4 className='text-black'>Corem@gmail.com</h4>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </Tab>
                    <Tab eventKey="2" disabled title="Ghost">
                        <div className='custom-table mt-4'>
                            <div className='custom-table-head'>
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <h6 className='text-blue'>Action Date Time</h6>
                                    </div>
                                    <div className='col-lg-2'>
                                        <h6 className='text-blue'>Action Type</h6>
                                    </div>
                                    <div className='col-lg-6'>
                                        <h6 className='text-blue'>Action Description</h6>
                                    </div>
                                    <div className='col-lg-2'>
                                        <h6 className='text-blue'>User Details</h6>
                                    </div>
                                </div>
                            </div>
                            {[
                                1, 2, 3, 4, 5, 6, 7
                            ].map((item, i) =>
                                <div className='custom-table-body'>
                                    <div className='row'>
                                        <div className='col-lg-2'>
                                            <h4 className='text-black'>26/07/2020</h4>
                                            <h4 className='text-black'>12:22:00</h4>
                                        </div>
                                        <div className='col-lg-2'>
                                            <h4 className='text-black'>Document</h4>
                                        </div>
                                        <div className='col-lg-6'>
                                            <h4 className='text-black'>Maker Uploaded the new passport from user name MAKER </h4>
                                            <h4 className='text-black fw-bold mt-2'>Comment:</h4>
                                            <h4 className='text-black mt-2'>Checker check the document and found photo copy not maching</h4>
                                        </div>
                                        <div className='col-lg-2'>
                                            <h4 className='text-black'>Corem@gmail.com</h4>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </Tab>
                    <Tab eventKey="3" title="AML">
                        {/* <div className='custom-table mt-4'>
                    <div className='custom-table-head'>
                        <div className='row'>
                            <div className='col-lg-2'>
                                <h6 className='text-blue'>Action Date Time</h6>
                            </div>
                            <div className='col-lg-2'>
                                <h6 className='text-blue'>Action Type</h6>
                            </div>
                            <div className='col-lg-6'>
                                <h6 className='text-blue'>Action Description</h6>
                            </div>
                            <div className='col-lg-2'>
                                <h6 className='text-blue'>User Details</h6>
                            </div>
                        </div>
                    </div>
                    {[
                        1, 2, 3, 4, 5, 6, 7
                    ].map((item, i) =>
                        <div className='custom-table-body'>
                            <div className='row'>
                                <div className='col-lg-2'>
                                    <h4 className='text-black'>26/07/2020</h4>
                                    <h4 className='text-black'>12:22:00</h4>
                                </div>
                                <div className='col-lg-2'>
                                    <h4 className='text-black'>Document</h4>
                                </div>
                                <div className='col-lg-6'>
                                    <h4 className='text-black'>Maker Uploaded the new passport from user name MAKER </h4>
                                    <h4 className='text-black fw-bold mt-2'>Comment:</h4>
                                    <h4 className='text-black mt-2'>Checker check the document and found photo copy not maching</h4>
                                </div>
                                <div className='col-lg-2'>
                                    <h4 className='text-black'>Corem@gmail.com</h4>
                                </div>
                            </div>
                        </div>
                    )}

                </div> */}
                        <div>
                            {/* <h1 className='text-black my-3'>Comments</h1> */}
                            <div className='row mt-3'>
                                <div className='col-lg-2' style={{ width: '11.666667%' }}>
                                    <h2>Key Data :</h2>
                                </div>
                                <div className='col-lg-10' style={{ width: '88.333333%' }}>
                                    <h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</h4>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-lg-2' style={{ width: '11.666667%' }}>
                                    <h2>AM :</h2>
                                </div>
                                <div className='col-lg-10' style={{ width: '88.333333%' }}>
                                    <h2>1, 3, 7, 9 :</h2>
                                    <h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</h4>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-lg-2' style={{ width: '11.666667%' }}>
                                    <h2>Docs :</h2>
                                </div>
                                <div className='col-lg-10' style={{ width: '88.333333%' }}>
                                    <h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</h4>
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default AuditTrail
