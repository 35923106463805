import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../../utils/apiUrls";
import api from "../../../utils/api";

const initialState = {
    searchCompanyList : [],
    isLoading: false,
    error: null
};

export const searchCorporateCompany = createAsyncThunk("medication_history", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.searchCorporateCompany + queryParam,
        };
        const response = await api(data);
        return response.data.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

const corporateOnboardingSlice = createSlice({
    name: "corporateOnboarding",
    initialState,
    reducers: {
        setUserName: (state, action) => {
            state.userName = action.payload
        },
    },
    extraReducers: (builder) => {
        const handleLoading = (state, action) => {
            state.isLoading = action.meta.requestStatus === 'pending';
        };

        builder
            .addCase(searchCorporateCompany.fulfilled, (state, action) => {
                state.searchCompanyList = action.payload;
            })
            .addMatcher(
                (action) =>
                    action.type === searchCorporateCompany.pending.type ||
                    action.type === searchCorporateCompany.fulfilled.type ||
                    action.type === searchCorporateCompany.rejected.type ,
                handleLoading
            );
    }

});

export const { setUserName } = corporateOnboardingSlice.actions;
export default corporateOnboardingSlice.reducer;