import React, { useEffect, useState } from 'react';
import ScreenActionIcon from '../../../assets/images/screeing_action.png';
import Accordion from 'react-bootstrap/Accordion';
import ViewIcon from '../../../assets/images/view_icon.png';
import PassportImage from '../../../assets/images/passport_img.png';
import { useDispatch, useSelector } from 'react-redux';
import { getIndividualScreeningClientHistory } from '../IndividualScreeningSlice';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';

function IndividualOtherInfo({ entityClientId }) {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const dispatch = useDispatch();
    const { isLoading, oldData } = useSelector(state => state.individualScreeningReducer);
    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };
    function formatColumnName(columnName) {
        return columnName.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                let query = `?entity_client_id=${entityClientId}`;
                await dispatch(getIndividualScreeningClientHistory(query)).unwrap();
            } catch (error) {
                toast.error(error?.message);
            }
        };

        fetchData();
    }, [entityClientId, dispatch]);


    return (
        <div>
            {isLoading && <Loader />}
            <div className='d-flex align-items-center mt-3'>
                <img src={ScreenActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} alt="Screen Action Icon" />
                <h2 className='text-ambed text-center'>Old Data</h2>
            </div>
            <hr />
            <Accordion className='client-billing-accordion client-services'>
                {Object.entries(oldData).map(([key, value]) => (
                    <Accordion.Item key={key} eventKey={key}>
                        <Accordion.Header>
                            <div className="d-flex" style={{ width: '99%' }}>
                                <div>
                                    <h2 className='text-black fw-normal'>{formatColumnName(key)}</h2>
                                </div>
                                <div className="ms-auto">
                                    <i className="fa fa-chevron-down ms-3"></i>
                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div style={{ marginLeft: 40 }}>
                                {value.map((item, index) => (
                                    <div className="row mt-2" key={index}>
                                        <div className="col-4">
                                            <h2 className='text-black fw-normal'><b>{formatColumnName(item.column_name)} : </b>{item.old_value}</h2>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
                {/* Additional Accordions */}
                {/* <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <div className="d-flex" style={{ width: '99%' }}>
                            <div>
                                <h2 className='text-black fw-normal'>Documents</h2>
                            </div>

                            <div className="ms-auto">
                                <i className="fa fa-chevron-down ms-3"></i>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <Accordion className='aml-accordion documnets-tab-accordion' defaultActiveKey={0} flush>
                                {[
                                    { key: 1, status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', title: 'Passport' },

                                ].map((item) =>
                                    <Accordion.Item eventKey={item.key} style={{ paddingLeft: 30, paddingRight: 30 }}>
                                        <Accordion.Header>
                                            {/* <div className="d-flex align-items-center">
                                                <h2 className='text-blue'>{item.title}</h2>
                                                <p className='statusBtn_small' style={{ backgroundColor: item.statusBg, color: item.statusColor, fontSize: 12, marginLeft: 10 }}>{item.status}</p>
                                            </div> */}
                {/* <div className="d-flex" style={{ width: '99%' }}>
                                                <div>
                                                    <div className="d-flex align-items-center">
                                                        <h2 className='text-blue'>{item.title}</h2>
                                                        <p className='statusBtn_small' style={{ backgroundColor: item.statusBg, color: item.statusColor, fontSize: 12, marginLeft: 10 }}>{item.status}</p>
                                                    </div>
                                                </div>
                                                <div className="ms-auto">
                                                    <i className="fa fa-chevron-down ms-3"></i>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className='custom-table'>
                                                <div className='custom-table-head'>
                                                    <div className='row'>
                                                        <div className='col-lg-3'>
                                                            <h6 className='text-blue'>Checks Done</h6>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6 className='text-blue'>View Documents</h6>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6 className='text-blue'>Provider</h6>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6 className='text-blue'>Result</h6>
                                                        </div>
                                                        <div className='col-lg-3'>
                                                            <h6 className='text-blue'>Action</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Accordion className='client-billing-accordion client-services'>
                                                    {[
                                                        { key: 1, status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', check_done: 'ID Document', action: 'Request or Upload Additional Document' },
                                                        { key: 2, status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', check_done: 'Liveness', action: 'Re-KYC' },
                                                        { key: 3, status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', check_done: 'ID Document - Face', action: 'Re-KYC' },
                                                        { key: 4, status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', check_done: 'OCR Content', action: 'Request or Upload Additional Document' },
                                                    ].map((item) =>
                                                        <Accordion.Item eventKey={item.key}>
                                                            <Accordion.Header onClick={toggleAccordion}>
                                                                <div className='row align-items-center w-100'>
                                                                    <div className='col-lg-3'>
                                                                        <h6 className='text-black'>{item.check_done}<span>
                                                                            <i className={`fa fa-chevron-down ms-3 ${isAccordionOpen ? 'rotate-chevron' : ''}`}></i>
                                                                        </span></h6>
                                                                    </div>
                                                                    <div className='col-lg-2'>
                                                                        <img src={PassportImage} style={{ width: 55, objectFit: 'contain' }} />
                                                                    </div>
                                                                    <div className='col-lg-2'>
                                                                        <h6 className='text-black'>Yoti</h6>
                                                                    </div>
                                                                    <div className='col-lg-2'>
                                                                        <p className='statusBtn' style={{ backgroundColor: item.statusBg, color: item.statusColor }}>{item.status}</p>
                                                                    </div>
                                                                    <div className='col-lg-3'>
                                                                        <p style={{ backgroundColor: '#E6F2FB', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'fit-content', fontSize: 14, }} className='text-blue mb-0'>{item.action}</p>
                                                                    </div>
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className='row'>

                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    )}
                                                </Accordion>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                            </Accordion>
                        </div>
                    </Accordion.Body>
                </Accordion.Item> */}
            </Accordion>
        </div>
    );
}

export default IndividualOtherInfo;
