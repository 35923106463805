import React, { useState } from 'react'
import '../../pages/AmlCheck/Aml.scss'
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '../../assets/images/Cancel_icon.png'

function ResultCard(props) {
    const { onClick, data, saveRelevantResult, removeRelevantResult } = props;
    const [check, setCheck] = useState(data?.checked || false);
    const [showAllDOB, setShowAllDOB] = useState(false);
    const [showAllMatched, setShowAllMatched] = useState(false);
    const [isRelevant, setIsrelevant] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [show2, setShow2] = useState(false);

    const handleCheckboxChange = (value, globalSearchResultId) => {
        if (value) {
            saveRelevantResult(globalSearchResultId);
        } else {
            removeRelevantResult(globalSearchResultId);
        }
        // Toggle the 'check' state when the checkbox is clicked
        setCheck(!check);
    };

    const dobFields = data?.search_results?.doc?.fields
        .filter(field => field.tag === "date_of_birth")
        .map(field => field.value);

    const limitedDOBFields = showAllDOB ? dobFields : dobFields.slice(0, 3);

    const matchedTypes =data?.search_results?.doc?.types
        ?.map(type => type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()));

    const limitedMatchedTypes = showAllMatched ? matchedTypes : matchedTypes.slice(0, 3);

    return (
        <div className={`${!data?.checked ? 'dark-grey-background' : 'white-background'} p-3 mt-4 card-aml-class`} style={{ border: data?.checked ? '2px solid #037AD0' : '0px', background: data?.checked ? '#E8F5FF' : '#fff', boxShadow: '0px 3px 10px 0px #0000001A'
        }} >
            {data?.checked &&
                <div className='d-flex align-items-center justify-content-between mb-4'>
                    <div className='relevant-btn'>
                        Relevant
                    </div>
                    <div style={{color: '#000000'}}>From: {data?.oomero_relevant ? 'Oomero' : 'Enterprise'}</div>
                </div> 
            }
            {!data?.checked &&
                <div className='d-flex justify-content-start mb-4'>
                    <div className='not-relevant-btn'>
                        Not Relevant
                    </div>
                </div> 
            }
            <div className='d-flex align-items-center justify-content-between'>
                <div>
                    <h6 className='text-black top-left-tag'>{(data?.search_results?.doc?.entity_type || '').replace(/\b\w/g, char => char.toUpperCase())}</h6>
                </div>
                <div class="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={data?.checked}
                        onChange={(e) => handleCheckboxChange(e.target.checked, data?.global_search_results_id)} />
                </div>
            </div>

            <h1 onClick={onClick} className='text-blue fw-bold my-3' style={{ cursor: 'pointer' }}>{data?.name}</h1>

            <div className='row mb-2'>
                <div className='col-lg-4'>
                    <h5 className='text-black'>Matched:</h5>
                </div>
                <div className='col'>
                    <div className='row g-2'>
                        {/* {
                            data?.search_results?.doc?.types.map((item, i) =>
                                <div className='col' key={i}>
                                    <div className='text-danger danger-light-background text-center' style={{fontSize:12}}>{item}</div>
                                </div>
                            )
                        } */}
                        {limitedMatchedTypes.map((item, i) =>
                            <div className='col' key={i}>
                                <div className='text-danger danger-light-background text-center' style={{fontSize: 12}}>{item}</div>
                            </div>
                        )}
                        {matchedTypes.length > 3 && (
                            <a
                                className="view-more"
                                onClick={handleShow2}
                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                            >
                                {'...view more'}
                            </a>
                        )}
                    </div>
                </div>
            </div>

            <div className='row mb-2'>
                <div className='col-lg-4'>
                    <h5 className='text-black'>Relevance:</h5>
                </div>
                <div className='col'>
                    <h5 className='text-grey'>{data?.search_results?.match_types?.map(type => type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())).join(', ') || ''}</h5>
                </div>
            </div>
            <div className='row mb-2'>
                <div className='col-lg-4'>
                    <h5 className='text-black'>Countries:</h5>
                </div>
                <div className='col'>
                    <h5 className='text-grey'>{data?.search_results?.doc?.fields.find(field => field.tag === "country_names")?.value || null}</h5>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-4'>
                    <h5 className='text-black'>DOB:</h5>
                </div>
                <div className='col'>
                    <h5 className='text-grey'>
                        {limitedDOBFields.join(", ")}
                        {dobFields.length > 3 && (
                            <a
                                className="view-more"
                                onClick={() => handleShow()}
                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                            >
                                {'...view more'}
                            </a>
                        )}
                    </h5>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <h5 className='text-grey'>
                        {data?.search_results?.is_whitelisted ? "Whitelisted" : "Not Whitelisted"}
                    </h5>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-4'>
                    <h5 className='text-black'>MatchStatus:</h5>
                </div>
                <div className='col'>
                <h5 className='text-grey'>
            {data?.search_results?.match_status
                ? data.search_results.match_status
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')
                : '-'
            }
        </h5>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} centered scrollable size='lg'>
                <Modal.Header className='model-header mt-2 pb-3'>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginRight: 8, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className='card-aml-class'>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <h5 className='text-black'>DOB:</h5>
                                </div>
                                <div className='col'>
                                    <h5 className='text-grey'>
                                        {data?.search_results?.doc?.fields
                                            .filter(field => field.tag === "date_of_birth")
                                            .map(field => field.value)
                                            .join(", ") || null}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
            <Modal show={show2} onHide={handleClose2} centered scrollable size='lg'>
                <Modal.Header className='model-header mt-2 pb-3'>
                    <div onClick={handleClose2} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginRight: 8, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className='row mb-2'>
                            <div className='col-lg-4'>
                                <h5 className='text-black'>Matched:</h5>
                            </div>
                            <div className='col'>
                                <div className='row g-2'>
                                    {
                                        data?.search_results?.doc?.types.map((item, i) =>
                                            <div className='col' key={i}>
                                                <div className='text-danger danger-light-background text-center'    style={{fontSize:12}}>{item}</div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ResultCard
