import React from 'react'
import SearchIcon from '../../assets/images/search_icon.png'
import ViewIcon from '../../assets/images/view_icon.png'
import PrintIcon from '../../assets/images/Print_Icon.png'

function PreviouslyReports() {
    return (
        <div className='grey-background'>
            <div className='white-background p-3 mb-3'>
                <h1 className='text-blue px-2 fw-normal'>Previously Downloaded Reports</h1>
                <hr />
                <div className='row'>
                    <div className='col-lg-10 col-md-10'>
                        <div className="form-group position-relative">
                            <input type="text" className="form-control" placeholder='Search'></input>
                            <div className="position-absolute" style={{ right: 20, top: '20%' }}>
                                <img src={SearchIcon} style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 justify-content-end'>
                        <button className="btn btn-primary w-100" style={{ fontSize: 14 }}>Search</button>
                    </div>
                </div>
            </div>
            <div className='col-lg-12 col-md-12 mt-3'>
                <div className='custom-table'>
                    <div className='custom-table-head'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='text-blue'>Download Date</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Company</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Company Reg No.</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Report Type</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Credit Score</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Company Monitored</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue text-center'>Action</h6>
                            </div>
                        </div>
                    </div>
                    <div className='custom-table-body'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='text-black'>12-02-2020</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Fable</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>UYRUI70</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Credit Safe</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>20</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Yes</h6>
                            </div>
                            <div className='col'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <h6 className='text-blue'><u>Recheck</u></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='custom-table-body'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='text-black'>12-02-2020</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Avenues</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>UYRUI70</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Credit Safe</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>25</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Yes</h6>
                            </div>
                            <div className='col'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <h6 className='text-blue'><u>Recheck</u></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='custom-table-body'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='text-black'>12-02-2020</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Avenues</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>UYRUI70</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Credit Safe</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>25</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Yes</h6>
                            </div>
                            <div className='col'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <h6 className='text-blue'><u>Recheck</u></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='custom-table-body'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='text-black'>12-02-2020</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Avenues</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>UYRUI70</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Credit Safe</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>25</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Yes</h6>
                            </div>
                            <div className='col'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <h6 className='text-blue'><u>Recheck</u></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='custom-table-body'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='text-black'>12-02-2020</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Avenues</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>UYRUI70</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Credit Safe</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>25</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Yes</h6>
                            </div>
                            <div className='col'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <h6 className='text-blue'><u>Recheck</u></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='custom-table-body'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='text-black'>12-02-2020</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Avenues</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>UYRUI70</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Credit Safe</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>25</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Yes</h6>
                            </div>
                            <div className='col'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <h6 className='text-blue'><u>Recheck</u></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='custom-table-body'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='text-black'>12-02-2020</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Avenues</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>UYRUI70</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Credit Safe</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>25</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Yes</h6>
                            </div>
                            <div className='col'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <h6 className='text-blue'><u>Recheck</u></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='custom-table-body'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='text-black'>12-02-2020</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Avenues</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>UYRUI70</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Credit Safe</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>25</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>Yes</h6>
                            </div>
                            <div className='col'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    <h6 className='text-blue'><u>Recheck</u></h6>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PreviouslyReports
