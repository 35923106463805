import React from 'react'
import './InformationBox.scss'

function InformationBox({ customBg, FromText, boxtext, fromlabel, fromname, personlabel, person_name, matched_label, matched, relevancelabel, relevancetext, countrieslabel, countriesname, doblabel, dob_date, boxColor, showBox1 }) {
  return (
    <div className='informationbox-css'>
      <div className='mainbox' style={{ backgroundColor: boxColor }}>
        <div className='d-flex justify-content-between align-items-center'>
          {showBox1 && <div className='personbox' style={{ backgroundColor: customBg }}>
            <p className='boxtext'>{boxtext}</p>
          </div>}
          {FromText && <p className='texthead' style={{ marginBottom: 16 }}>{fromlabel}<span className='sidetext'>{fromname}</span></p>}
        </div>
        <div className='personbox'>
          <p className='boxtext'>{personlabel}</p>
        </div>
        <p className='text-heading' style={{ marginBottom: 16 }}>{person_name}</p>
        <div className='d-flex'>
          <p className='texthead'>{matched_label} </p>
          <div className='d-flex flex-wrap mainboxes'>
            {matched.map((item) => (
              <div className='personbox redbox'>
                <p className='boxtext redtext'>{item}</p>
              </div>
            ))}
            {/* <div className='personbox redbox'>
              <p className='boxtext redtext'>{warningtext}</p>
            </div>
            <div className='personbox redbox'>
              <p className='boxtext redtext'>{peptext}</p>
            </div>
            <div className='personbox redbox'>
              <p className='boxtext redtext'>{adversemedia_text}</p>
            </div> */}
          </div>
        </div>
        <div className='d-flex' style={{ marginBottom: 13 }}>
          <p className='texthead'>{relevancelabel}</p>
          <p className='textsubhead'> {relevancetext}</p>
        </div>
        <div className='d-flex' style={{ marginBottom: 13 }}>
          <p className='texthead'>{countrieslabel}</p>
          <p className='textsubhead'> {countriesname}</p>
        </div>
        <div className='d-flex' style={{ marginBottom: 13 }}>
          <p className='texthead'>{doblabel}</p>
          <p className='textsubhead'>{dob_date}</p>
        </div>
      </div>

    </div>

  )
}

export default InformationBox