import React, { useEffect, useState } from 'react'
import QRCode from '../../assets/images/qrcode.png'
import { generateGoogleAuthenticatorQR, manageGoogleAuthenticator } from './myAccountSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import Loader from '../../components/Loader/Loader';
import { getSignedUrls } from '../../utils/awsHelper';

function GoogleAuthenticator() {
    const dispatch = useDispatch();
    const { isLoading, qrData } = useSelector(state => state.myAccountReducer);
    const [isEnabled, setIsEnabled] = useState(false);

    const schema = yup.object().shape({
        pin: yup.string().required('Pin is required'),
        password: yup
            .string()
            .required('Password is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/,
                'Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.'
            ),
        is_enabled: yup.string().required('Enable value is required')
    });

    const { control, register, handleSubmit, formState: { errors }, setValue, reset } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        dispatch(generateGoogleAuthenticatorQR()).unwrap().then((res) => {
            setValue('is_enabled', ((res?.data?.is_enabled) ? false : true));
            setIsEnabled(((res?.data?.is_enabled) ? false : true));
        }).catch((error) => {
            toast.error(error?.message);
        });
    }, []);

    const onSubmit = (data) => {
        dispatch(manageGoogleAuthenticator(data)).unwrap().then(async (res) => {
            if (res.error) {
                toast.error(res?.error?.message);
                return;
            }
            setValue('is_enabled', !isEnabled);
            setIsEnabled(!isEnabled);
            toast.success(res?.message);
        }).catch((error) => {
            toast.error(error?.message);
        });
    }

    return (
        <div className='grey-background'>
            {isLoading && <Loader />}
            <div className='white-background p-3 mb-3'>
                <h1 className='text-blue px-2 fw-normal mb-4'><b>2Factor TOTP - {(!isEnabled) ? 'Enabled' : 'Disabled'}</b></h1>
                <div class="row px-2">
                    <div class="col-4 text-center">
                        <img src={qrData?.qrcode} style={{ width: 278, height: 278, objectFit: 'contain', cursor: 'pointer' }} />
                        <p><a class="link" href={"#"}>Can't scan? Copy the key.</a></p>
                    </div>
                    <div class="col-8">
                        <form className='form' onSubmit={handleSubmit(onSubmit)}>
                            <h1 className='text-black px-2 fw-normal mb-3 mt-3'><b>Scan the QR code on your authenticator app</b></h1>
                            <h6 className='text-black px-2 fw-normal mb-3'>Once scanned, the app should give you a 6 digit TOTP. Enter it here. <a class="link" href="#">Need help?</a></h6>
                            <div className='row px-2 mb-4'>
                                <div className='col'>
                                    <div className="form-group">
                                        <label className="form-label" for="exampleInputEmail1">6 digit TOTP</label>
                                        {/* <input type="text" className="form-control"></input> */}
                                        <input
                                            type='text'
                                            className={`form-control form-control-lg ${errors.pin ? 'is-invalid' : ''}`}
                                            {...register('pin')}
                                        />

                                        {errors?.pin && (
                                            <div className="invalid-feedback">{errors?.pin?.message}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className="form-group">
                                        <label className="form-label" for="exampleInputEmail1">Current password</label>
                                        <input type="hidden" className="form-control" {...register('is_enabled')}></input>

                                        <input
                                            type={'password'}
                                            className={`form-control form-control-lg ${errors.password ? 'is-invalid' : ''}`}
                                            placeholder="Enter Password"
                                            {...register('password')}
                                        />

                                        {errors?.password && (
                                            <div className="invalid-feedback">{errors?.password?.message}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-black px-2 fw-normal mb-3'>IMPORTANT: Once TOTP is setup, you have to use the same mobile authenticator app to generate a new 6-digit TOTP every time you login.</h6>
                            <div className='d-flex px-2'>
                                <button type="submit" className="btn-primary mb-3" style={{ marginRight: 20 }}>{(!isEnabled) ? 'Disable' : 'Enable'}</button>
                                <button type="button" className="btn-secondary mb-3">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GoogleAuthenticator
