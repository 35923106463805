import React, { useEffect, useState } from 'react'
import PrintIcon from '../../assets/images/Print_Icon.png'
import ViewIcon from '../../assets/images/view_icon.png'
import { useNavigate } from 'react-router-dom';
import "../Screening/corporateScreening.scss";
import { Tab, Tabs } from 'react-bootstrap'
import IndividualTableData from '../../components/Screening/IndividualTableData'
import { useDispatch, useSelector } from 'react-redux';
import { fetchIndividualScreeningList, listPendingKYCIndividualScreening, manageContinuousMonitoring, manageEntityClientStatus, resetIndividualScreeningList, resetListPendingKYCIndividualScreening, updateIndividualScreeningList, updateListPendingKYCIndividualScreening,downloadClientDocZipFiles } from './IndividualScreeningSlice'
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRef } from 'react';
import DatePicker from "react-date-picker";
import { decodeToken } from 'react-jwt';
import moment from 'moment';

function IndividualScreening() {
  const tabArray = [
    { tabTitle: 'Green', boxColor: '#037924', value: 'green' },
    { tabTitle: 'Amber', boxColor: '#9C6802', value: 'amber' },
    { tabTitle: 'Red', boxColor: '#ED4337', value: 'red' },
    { tabTitle: 'Grey', boxColor: '#949595', value: 'grey' },
    { tabTitle: 'White Listed', boxColor: '#FFFFFF', value: 'whitelisted' },
    { tabTitle: 'Black Listed', boxColor: '#262626', value: 'blacklisted' },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, individualScreeningList, pendingKYCindividualScreeningList } = useSelector(state => state.individualScreeningReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPge, SetPostsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [activeTab, setActiveTab] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const inputRef = useRef(null);
  const userToken = localStorage.getItem('token');
  const decodedToken = decodeToken(userToken);

  const handleTabSelect = (tabKey) => {
    setCurrentPage(1);
    setActiveTab(tabKey);
  };


  useEffect(() => {
    let queryParam = `?limit=${postsPerPge}&page=${currentPage}&onboarded=false`;
    if (search) {
      queryParam += `&search=${search}`;
    }

    if (startDate != '' & endDate != '') {
      queryParam += `&startDate=${startDate}&endDate=${endDate}`;
    }

    if (activeTab != '' && activeTab != 'rekyc') {
      queryParam += `&flag=${activeTab}`;
    }

    let dispatchFunction = (activeTab != 'rekyc') ? dispatch(fetchIndividualScreeningList(queryParam)) : dispatch(listPendingKYCIndividualScreening(queryParam));

    dispatchFunction.unwrap().catch((error) => {
      toast.error(error?.message);
    });

    dispatch(resetIndividualScreeningList());
    dispatch(resetListPendingKYCIndividualScreening());
  }, [search, activeTab, currentPage, startDate, endDate]);

  const manageEntityClient = (data) => {

    const isSuperAdmin = decodedToken?.isSuperAdmin;
    const isEnterpriseAdmin = decodedToken?.isEnterpriseAdmin;
    const hasActivatePermission = decodedToken?.roles?.permissions?.activate_individual_screening;
    const hasDeActivatePermission = decodedToken?.roles?.permissions?.deactivate_individual_screening;

    if(data?.status && !(isSuperAdmin || isEnterpriseAdmin || hasActivatePermission)){
      toast.error('You are not authorized for activating the record');
      return;
    }

    if(!data?.status && !(isSuperAdmin || isEnterpriseAdmin || hasDeActivatePermission)){
      toast.error('You are not authorized for deactivate the record');
      return;
    }

    dispatch(manageEntityClientStatus({ queryParams: `?entity_client_id=${data?.entityClientId}&onboarded=false`, payload: { status: data?.status } })).unwrap().then((obj) => {
      let object = (activeTab != 'rekyc') ? individualScreeningList : pendingKYCindividualScreeningList;

      const updatedRows = object?.rows.map(row => {
        if (row.entity_client_id === data?.entityClientId) {
          return {
            ...row,
            is_active: data?.status
          };
        }
        return row;
      });

      // Create a new object with the updated rows
      const updatedObject = {
        ...object,
        rows: updatedRows
      };

      let dispatchFunc = (activeTab != 'rekyc') ? (updateIndividualScreeningList(updatedObject)) : updateListPendingKYCIndividualScreening(updatedObject);

      dispatch(dispatchFunc);

      toast.success(obj?.message || 'Successfully done');
    }).catch(error => {
      toast.error(error.message || 'Something Went Wrong');
    });
  }

  const manageContinuousMonitoringFunc = (data) => {
    dispatch(manageContinuousMonitoring({ queryParams: `?entity_client_id=${data?.entityClientId}&onboarded=false`, payload: { continous_monitoring: data?.continous_monitoring } })).unwrap().then((obj) => {
      let object = (activeTab != 'rekyc') ? individualScreeningList : pendingKYCindividualScreeningList;

      const updatedRows = object?.rows.map(row => {
        if (row.entity_client_id === data?.entityClientId) {
          return {
            ...row,
            continous_monitoring: data?.continous_monitoring
          };
        }
        return row;
      });

      // Create a new object with the updated rows
      const updatedObject = {
        ...object,
        rows: updatedRows
      };

      let dispatchFunc = (activeTab != 'rekyc') ? (updateIndividualScreeningList(updatedObject)) : updateListPendingKYCIndividualScreening(updatedObject);

      dispatch(dispatchFunc);

      toast.success(obj?.message || 'Successfully done');
    }).catch(error => {
      toast.error(error.message || 'Something Went Wrong');
    });
  }

  const startEndFilter = yup.object().shape({
    startDate: yup.string().required('Start date is required'),
    endDate: yup.string().required('End date is required').test('is-greater', 'End date must be greater than start date', function (value) {
      const { startDate } = this.parent;
      // Convert dates to JavaScript Date objects for comparison
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(value);

      // Compare the dates
      return endDateObj >= startDateObj;
    })
  });

  const { control, register, handleSubmit, formState: { errors }, reset, setValue, trigger ,watch} = useForm({
    resolver: yupResolver(startEndFilter),
  });

  const renterTabTitle = (title, bgColor) => {
    return (
      <div className='d-flex align-items-center'>
        <div style={{ width: 20, height: 20, backgroundColor: bgColor, marginRight: 5 }} className='tabTitle' /> {title}
      </div>
    )
  }

  const resetDateFilter = () =>{
    setStartDate('');
    setEndDate('')
    reset();
  }

  const handlePagination = (event, value) => {
    setCurrentPage(value);
  }

  const onSubmit = (data) => {
    setStartDate(data.startDate);
    setEndDate(data.endDate);
  }

  const handleOnSearch = () => {
    if (inputRef.current) {
      setSearch(inputRef.current.value);
    }
  }
const downloadZip = (entityClientId) => {
    dispatch(downloadClientDocZipFiles(`?entity_client_id=${entityClientId}&onboarded=false`))
        .unwrap()
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `client_${entityClientId}_documents.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch((error) => {
            toast.error(error.message || 'Something Went Wrong');
        });
};
const customDatePickerStyles = {
  padding: '8px 12px',
  fontSize: '14px',
  fontWeight: '400',
  borderRadius: '10px',
  height:"35px",
  border: '2px solid #D9D9D9',
  backgroundColor: '#FFFFFF',
  color: '#262626',
};
  return (
    <div className='grey-background corporate-screening-page'>
      {isLoading && <Loader />}
      <div className='white-background p-3 mb-3'>
        <h1 className='text-blue px-2 fw-normal'>Individual Screening</h1>
        <hr />
        <div className='row'>
          <div className='col-lg-9 col-md-9'>
            <form className='form' onSubmit={handleSubmit(onSubmit)}>
              <div className='row mb-sm'>
                <div className='d-flex' style={{ width: "max-content", alignItems: 'center' }}> Start Date </div>
                <div className='col-md-3 col-sm-4 pb-1'>
                  {/* <input type="date" {...register('startDate')} className={`form-control ${errors?.startDate ? 'is-invalid' : ''}`} id="exampleInputEmail1" /> */}
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            {...field}
                            className={`form-control form-control-lg custom-datepicker ${errors.startDate ? 'is-invalid' : ''}`}
                            onChange={(date) => {
                              const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                              field.onChange(formattedDate);
                            }}
                            value={field.value}
                            clearIcon
                            maxDate={new Date()} 
                            format='dd-MM-yyyy'
                            yearPlaceholder='yyyy'
                            monthPlaceholder='mm'
                            dayPlaceholder='dd'
                        />
                      )}
                  />
                  {errors?.startDate && (
                      <div className="invalid-feedback">{errors?.startDate?.message}</div>
                  )}
                </div>
                <div className='d-flex' style={{ width: "max-content", alignItems: 'center' }}>  End Date </div>
                <div className='col-md-3 col-sm-3 pb-1'>
                  {/* <input type="date" {...register('endDate')} className={`form-control ${errors?.endDate ? 'is-invalid' : ''}`} id="exampleInputEmail1" /> */}
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            {...field}
                            className={`form-control form-control-lg custom-datepicker ${errors.endDate ? 'is-invalid' : ''}`}
                            onChange={(date) => {
                              const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                              field.onChange(formattedDate);
                            }}
                            value={field.value}
                            maxDate={new Date()} 
                            clearIcon
                            format='dd-MM-yyyy'
                            yearPlaceholder='yyyy'
                            monthPlaceholder='mm'
                            dayPlaceholder='dd'
                        />
                      )}
                  />
                  {errors?.endDate && (
                    <div className="invalid-feedback">{errors?.endDate?.message}</div>
                  )}
                </div>
                <button type="submit" className="btn btn-primary mb-1 p-2 pl-4 pr-4" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10 }}>
                  <i className="fa fa-search"></i>
                </button>
                <button type='button' className="btn btn-secondory mb-1 p-2 pl-4 pr-4" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10 }} onClick={() => resetDateFilter()}>
                  <i className="fa fa-rotate-right"></i>
                </button>
              </div>
            </form>
          </div>
          <div className='col-lg-3 col-md-3 '>
            <div className='row'>
              <div className='col'>
                <input type="text" className="form-control" placeholder='Search' ref={inputRef}></input>
              </div>
              <button className="btn btn-primary mb-1 p-2 pl-4 pr-4" style={{ width: 40, height: 40, marginLeft: 10 }} onClick={handleOnSearch}>
                <i className="fa fa-search"></i>
              </button>
              <button className="btn btn-secondory mb-1 p-2 pl-4 pr-4" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10 }} onClick={() => { setSearch(''); inputRef.current.value = ''; }}>
                <i className="fa fa-rotate-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-12 col-md-12 mt-3'>
        <div className='white-background p-3'>
          <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="mb-3 corporate-tabs">
            <Tab eventKey="" title="All">
              <IndividualTableData ViewIcon={ViewIcon} manageEntityClient={manageEntityClient} manageContinuousMonitoring={manageContinuousMonitoringFunc} PrintIcon={PrintIcon} listData={individualScreeningList} handlePagination={handlePagination} currentPage={currentPage}  downloadZip={downloadZip} decodedToken={decodedToken}/>
            </Tab>
            {tabArray.map((item, i) => {
              return (
                <Tab eventKey={item.value} title={renterTabTitle(item.tabTitle, item.boxColor)} key={'screen-tab' + i}>
                  <IndividualTableData ViewIcon={ViewIcon} manageEntityClient={manageEntityClient} manageContinuousMonitoring={manageContinuousMonitoringFunc} PrintIcon={PrintIcon} listData={individualScreeningList} handlePagination={handlePagination} currentPage={currentPage}  downloadZip={downloadZip} decodedToken={decodedToken}/>
                </Tab>
              )
            })}
            <Tab eventKey="rekyc" title="Re KYC">
              <IndividualTableData ViewIcon={ViewIcon} PrintIcon={PrintIcon} manageEntityClient={manageEntityClient} manageContinuousMonitoring={manageContinuousMonitoringFunc} listData={pendingKYCindividualScreeningList} handlePagination={handlePagination} currentPage={currentPage}  downloadZip={downloadZip} decodedToken={decodedToken}/>
            </Tab>
            {/* <Tab eventKey="Adverse Media" title={renterTabTitle('Green','#037924')}>
            <FilteredTableData ViewIcon={ViewIcon} PrintIcon={PrintIcon} />
            </Tab>
            <Tab eventKey="Documents" title="Documents">
            <FilteredTableData ViewIcon={ViewIcon} PrintIcon={PrintIcon} />
            </Tab> */}
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default IndividualScreening


