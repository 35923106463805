import React from 'react';
import './TableComponent.scss';

function TableComponent2({ keyname, valuename, address}) {
    return (
        <div className='tablecomp-css'>
            <div className='d-flex'>
                <div className='col-4 columnbox'>
                    <p className='text1'>{keyname}</p>
                </div>
                <div className='col-8 columnbox'>
                    {keyname === 'registered business address' ? (
                        <div>
                            <p className='text3'>{address}</p>
                        </div>
                    ) : (
                        <p className='text2'>{valuename}</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TableComponent2;
