import React, { useEffect, useState } from 'react';
import './OnboardClientBar.scss';
import NextIcon from '../../assets/images/next-icon.png';
import NextIconBlue from '../../assets/images/next-icon-blue.png';

function OnboardClientBar(props) {
    const [isMobile, setIsMobile] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };
        const handleScroll = () => {
            setIsVisible(window.scrollY <= 210);
        };

        handleResize();
        handleScroll();
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const { mainTitle, backgroundColor1, backgroundColor2, backgroundColor3, backgroundColor4, title1, title2, title3, title4 } = props;

    const textColor = isMobile ? { color: backgroundColor1 ? '#037AD0' : '#949595'} : { color: backgroundColor1 ? '#262626' : '#949595' };

    const textColor2 = isMobile ? {color: backgroundColor2 ? '#037AD0' : '#949595'} : { color: backgroundColor2 ? '#262626' : '#949595' };

    const textColor3 = isMobile ? {color: backgroundColor3 ? '#037AD0' : '#949595'} : { color: backgroundColor3 ? '#262626' : '#949595' };

    const textColor4 = isMobile ? {color: backgroundColor4 ? '#037AD0' : '#949595'} : { color: backgroundColor4 ? '#262626' : '#949595' };

    const steps = [backgroundColor1, backgroundColor2, backgroundColor3, backgroundColor4];
    const completedSteps = steps.filter(color => color).length;
    const progressPercentage = (completedSteps / steps.length) * 100;

    return (
        <div className={isMobile ? 'white-background white-background-responsive': 'white-background white-background-responsive p-3'}>
            <div className='text-blue mb-3'>
                <h1 className='fw-normal main-title-responsive'>{mainTitle}</h1>
            </div>
            <div className='row'>
                <div className='d-flex align-items-center justify-content-around'>
                    <div className='d-flex'>
                        <div className='number-box d-flex align-items-center justify-content-center' style={{ backgroundColor: backgroundColor1 ? backgroundColor1 : 'transparent', borderColor: backgroundColor1 ? 'white' : '#949595' }}>
                            <h4 className='fw-bold' style={{ color: backgroundColor1 ? '#FFFFFF' : '#949595' }}>1</h4>
                        </div>
                        <div className={isMobile ? 'd-flex align-items-center me-2' : 'd-flex align-items-center mx-2'}>
                            <h4 className='fw-bold  text-center' style={textColor}>{title1}</h4>
                        </div>
                    </div>
                    <img src={backgroundColor2 ? NextIconBlue : NextIcon} className='next-button-img hide-in-desktop' />
                    <div className='d-flex'>
                        <div className='number-box d-flex align-items-center justify-content-center' style={{ backgroundColor: backgroundColor2 ? backgroundColor2 : 'transparent', borderColor: backgroundColor2 ? 'white' : '#949595' }}>
                            <h4 className='fw-bold' style={{ color: backgroundColor2 ? '#FFFFFF' : '#949595' }}>2</h4>
                        </div>
                        <div className='d-flex align-items-center mx-2'>
                            <h4 className='fw-bold  text-center' style={textColor2}>{title2}</h4>
                        </div>
                    </div>
                    <img src={backgroundColor3 ? NextIconBlue : NextIcon} className='next-button-img hide-in-desktop' />
                    <div className='d-flex'>
                        <div className='number-box d-flex align-items-center justify-content-center' style={{ backgroundColor: backgroundColor3 ? backgroundColor3 : 'transparent', borderColor: backgroundColor3 ? 'white' : '#949595' }}>
                            <h4 className='fw-bold' style={{ color: backgroundColor3 ? '#FFFFFF' : '#949595' }}>3</h4>
                        </div>
                        <div className='d-flex align-items-center mx-2'>
                            <h4 className='fw-bold text-center' style={textColor3}>{title3}</h4>
                        </div>
                    </div>
                    <img src={backgroundColor4 ? NextIconBlue : NextIcon} className='next-button-img hide-in-desktop' />
                    <div className='d-flex'>
                        <div className='number-box d-flex align-items-center justify-content-center' style={{ backgroundColor: backgroundColor4 ? backgroundColor4 : 'transparent', borderColor: backgroundColor4 ? 'white' : '#949595' }}>
                            <h4 className='fw-bold' style={{ color: backgroundColor4 ? '#FFFFFF' : '#949595' }}>4</h4>
                        </div>
                        <div className='d-flex align-items-center mx-2'>
                            <h4 className='fw-bold  text-center' style={textColor4}>{title4}</h4>
                        </div>
                    </div>

                </div>
                {isVisible && (
                    <div className='progress-bar-container mx-2 hide-in-desktop'>
                        <div className='progress-bar' style={{ width: `${progressPercentage}%` }}></div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default OnboardClientBar