export const METHOD_TYPE = {
    get: "get",
    post: "post",
    put: "put",
    delete: "delete",
};

export const API_ENDPOINTS = {
    verifyCredentialsAndSendOtp: '/user/verifyCredentialsAndSendOtp',
    verifyLoginOtp: '/user/verifyLoginOtp',
    register: '/user/register',
    sendSignupOtp: '/user/sendSignupOtp',
    getCountryList: '/fxrates/getCountryList',
    fetchAllTax: '/tax/fetchAllTax',
    addNewTax: '/tax/create',
    fetchTaxDetails: '/tax/fetchTaxDetails',
    updateTaxData: '/tax/updateTax',
    deleteTax: '/tax/delete',
    fetchActivePlan: '/package/getActivePackage',
    sendCustomPackageInquiryEmail: '/package/sendCustomPackageInquiryEmail',
    initiateOrder: '/order/initiateOrder',
    placeOrder: '/order/placeOrder',
    fetchActiveRoles: '/role/fetchEntityActiveRoles',
    fetchAllRoles: '/role/fetchAllRoles',
    fetchRoleDetails: '/role/fetchById',
    deleteRole: '/role/delete',
    addNewRole: '/role/create',
    getCurrencyList: '/fxrates/getCurrencyList',
    getFxRateBasedByBaseCurrency: '/fxrates/fetchFxRateByBaseCurrency',
    createFxRate: '/fxrates/create',
    deleteFxRate: '/fxrates/delete',
    fetchFxRateDetailById: '/fxrates/fetchFxRateDetailById',
    updateFxRate: '/fxrates/update',
    fetchFxRateHistoryList: '/fxrates/fetchFxRateHistoryList',
    fetchUserList: '/user/fetchUserList',
    generateGoogleAuthenticatorQR: '/user/generateGoogleAuthenticatorQR',
    manageGoogleAuthenticator: '/user/manageGoogleAuthenticator',
    loginWithGoogleAuthenticator: '/user/loginWithGoogleAuthenticator',
    getEntityActivePackage: '/package/getEntityActivePackage',
    deactivateUser: '/user/deactivateUser',
    reactivateUser: '/user/reactivateUser',
    deleteUser: '/user/deleteUser',
    createRole: '/role/create',
    fetchOomeroEntity: '/entity/getOomeroEntity',
    addNewUser: '/user/addUser',
    fetchClientEntity: '/entity/getClientEntity',
    fetchUserDetails: '/user/fetchUserDetails',
    updateUserDetails: '/user/updateUser',
    fetchRoleById: '/role/fetchById',
    updateRole: '/role/update',
    getAllEntities: '/entity/getAllEntities',
    changeEntityStatus: '/entity/changeEntityStatus',
    setUserPassword: '/user/setInviteUserPassword',
    changePassword: '/user/changePassword',
    fetchAllEntityBillingList: '/invoice/fetchAllEntityBillingList',
    sendPayBalanceOtp: '/invoice/sendPayBalanceOtp',
    verifyPayBalanceOtp: '/invoice/verifyPayBalanceOtp',
    fetchInvoiceDetail: '/invoice/fetchInvoiceDetail',
    entityPreviousInvoiceList: '/invoice/entityInvoiceList',
    getEntityDetails: '/entity/getEntityDetails',
    sendForgotPasswordCode: "user/sendForgotPasswordCode",
    resetPasswordOtpVerify: "/user/verifyForgotPasswordCode",
    changeForgetPassword: "user/setNewPassword",
    getAllPackage: "package/getAllPackage",
    deactivatePackage: "package/deactivatePackage",
    updatePackage: "package/update",
    createNewPackage: "package/create",
    getInvoiceData: "/invoice/getMyInvoices",
    saveIndividualPersonalDetails: "/individual_onboarding/saveIndividualPersonalDetails",
    saveIndividualAddress: "/individual_onboarding/saveIndividualAddress",
    uploadEntityClientDocument: "/individual_onboarding/uploadEntityClientDocument",
    saveEntityClientKycDetail: "/individual_onboarding/saveEntityClientKycDetail",
    getEntityClientCompany: "/individual_onboarding/getEntityClientCompany",
    getEntityClientBank: "/individual_onboarding/getEntityClientBank",
    postEntityClientCompany: "/individual_onboarding/createEntityClientCompany",
    postEntityClientBank: "/individual_onboarding/createEntityClientBanks",
    fetchEntityClientPersonalDetail: "individual_onboarding/fetchEntityClientPersonalDetail",
    fetchEntityClientAddress: "/individual_onboarding/fetchEntityClientAddress",
    updateIndividualPersonalDetails: "/individual_onboarding/updateIndividualPersonalDetails",
    generateOnboardingInviteLink: "/individual_onboarding/generateOnboardingInviteLink",
    fetchEntityData: '/individual_onboarding/fetchEntityData',
    deductKYCLivelynessCharge: '/individual_onboarding/chargeKYCLivelyness',
    getIndividualScreeninglist: '/individual_screening/getIndividualScreeninglist',
    listPendingKYCIndividualScreening: '/individual_screening/listPendingKYCIndividualScreening',
    updateIndividualPersonalDetailsWithReason: '/individual_screening/updateIndividualPersonalDetailsWithReason',
    addScreeningComments: '/individual_screening/addScreeningComments',
    getEntityClientBankDetails: '/individual_onboarding/getEntityClientBankDetails',
    getEntityClientCompanyDetails: '/individual_onboarding/getEntityClientCompanyDetails',
    listKycDocuments: '/individual_screening/listKycDocuments',
    requestDocument: '/individual_screening/requestDocument',
    uploadDocument: '/individual_screening/uploadDocument',
    updateScreeningInfo: '/individual_screening/updateScreeningInfo',
    getScreeningComments: '/individual_screening/getScreeningComments',
    getFieldHistory: '/individual_screening/getFieldHistory',
    getGlobalSearchById: '/individual_screening/getGlobalSearchById',
    getAllGlobalSearchById: '/individual_screening/getAllGlobalSearchById',
    getIndividualScreeningClientHistory: "/individual_screening/getIndividualScreeningClientHistory",
    saveRelevantResult : "/individual_screening/saveRelevantResult",
    removeRelevantResult : "/individual_screening/removeRelevantResult",
    getSearchCertificate : "/individual_screening/getSearchCertificate",
    manageScreeningAction: "/individual_screening/manageScreeningAction",
    getAdverseMedia : "/individual_screening/getAdverseMedia",
    manageContinuousMonitoring : "/individual_screening/manageContinuousMonitoring",
    saveExcludedAdverseMedia : "/individual_screening/saveExcludedAdverseMedia",
    checkUKVatNo : "/individual_onboarding/checkUKVatNo",
    manageEntityClientStatus : "/individual_screening/manageEntityClientStatus",
    refreshToken: `/user/refreshToken`,
    saveAmlInfoComment:"/individual_screening/saveAmlInfoComment",
    saveAdverseMediaComment:"/individual_screening/saveAdverseMediaComment",
    downloadClientDocZipFiles:"/individual_screening/downloadClientDocZipFiles",
    resendToCheckerMaker : "/individual_screening/resendToCheckerMaker",
    packageUpdate : "/package/update",
    getPackageDetails:"/package/getPackageDetails",
    fetchAmlComments:"/individual_screening/fetchAmlComments",
    fetchUploadedDocuments: "/individual_screening/fetchUploadedDocuments",
    fetchUploadedDocuments: "/individual_screening/fetchUploadedDocuments",
    uploadMediaImage : "/individual_screening/uploadMediaImage",
    autoSearchAddress : "/individual_onboarding/autoSearchAddress",
    resendToScreening : "/individual_screening/resendToScreening",
    checkEUVatNo : "/individual_onboarding/checkEUVatNo",
    getIndividualScreeningPercentage : "/dashboard/getIndividualScreeningPercentage",
    getKeyStatsForGraph : "/dashboard/getKeyStatsForGraph",
    getStatsByGraph : "/dashboard/getStatsByGraph",
    sendToCheckerMlro : "/individual_screening/sendToCheckerMlro",
    checkPanUrl : "/individual_onboarding/checkPan",
    checkAadhaarUrl : "/individual_onboarding/checkAadhaar",
    getOnboardedClientReport : "/individual_onboarding/getOnboardedClientReportData",
    getEntityCertificate: "/individual_screening/getEntityCertificate",
    searchCorporateCompany: "/corporate_onboarding/searchCorporatePersonalDetails"
}