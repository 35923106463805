import React, { useEffect, useState } from 'react'
import BankDetails from '../Screening/ScreeningTabs/BankDetails'
import DocumentsDetails from '../Screening/ScreeningTabs/DocumentsDetails'
import AmlDetails from '../Screening/ScreeningTabs/AmlDetails'
import DirectorAndShareholderList from '../Screening/ScreeningTabs/DirectorAndShareholderList'
import { Tab, Tabs, Dropdown } from 'react-bootstrap'
import IndividualDetails from './IndividualScreeingDetails/IndividualDetails'
import ScreenActionIcon from '../../assets/images/screeing_action.png'
import IndividualBankDetails from './IndividualScreeingDetails/IndividualBankDetails'
import IndividualDocuments from './IndividualScreeingDetails/IndividualDocuments'
import IndividualAmlDetails from './IndividualScreeingDetails/IndividualAmlDetails'
import IndividualOtherInfo from './IndividualScreeingDetails/IndividualOtherInfo'
import IndividualScreenInfo from './IndividualScreeingDetails/IndividualScreenInfo'
import IndiviudalAuditTrail from './IndividualScreeingDetails/IndiviudalAuditTrail'
import { useLocation, useNavigate } from 'react-router-dom'
import IndividualCompanyDetails from './IndividualScreeingDetails/IndividualCompanyDetails'
import BackIcon from '../../assets/images/back-btn-arrow.png';
import IndividualUploadDocuments from './IndividualScreeingDetails/IndividualUploadDocuments'

function IndividualScreeningDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState('1');
  const [selectedDropdownKey, setSelectedDropdownKey] = useState(null);

  useEffect(() => {
    if (state?.activeTab) {
      setActiveTab(state.activeTab);
    }
  }, [state]);

  const handleDropdownSelect = (eventKey) => {
    setSelectedDropdownKey(eventKey)
    setTimeout(() => {
      setActiveTab(eventKey)
    }, 50);
  }

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (key !== '8' && key !== '9') {
      setSelectedDropdownKey(null)
    }
  };

  const navigateToPrevious = () => {
    navigate(-1);
  }
  return (
    <div className='grey-background'>
      <button onClick={navigateToPrevious} className='back-button text-blue'><img src={BackIcon} style={{width:8,marginBottom:2,marginRight:4,objectFit:'contain'}}/> Back</button>
      <div className='white-background p-3 mt-3 oomero-tabs'>
        <Tabs activeKey={activeTab} onSelect={handleTabChange} className="corporate-tabs" >
          <Tab eventKey="1" title="Basic Info">
            {activeTab === "1" && <IndividualDetails entityClientId={state?.entityClientId} viewOnly={state?.viewOnly}/>}
          </Tab>
          <Tab eventKey="2" title="Bank Details">
            {activeTab === "2" && <IndividualBankDetails entityClientId={state?.entityClientId} viewOnly={state?.viewOnly}/>}
          </Tab>
          <Tab eventKey="3" title="Company Details">
            {activeTab === "3" && <IndividualCompanyDetails entityClientId={state?.entityClientId} viewOnly={state?.viewOnly}/>}
          </Tab>
          <Tab eventKey="4" title="ID Documents">
            {activeTab === "4" && <IndividualDocuments entityClientId={state?.entityClientId} viewOnly={state?.viewOnly}/>}
          </Tab>
          <Tab eventKey="5" title="AML Info">
            {activeTab === "5" && <IndividualAmlDetails entityClientId={state?.entityClientId} viewOnly={state?.viewOnly}/>}
          </Tab>
          <Tab eventKey="6" title="Screening Action">
            {activeTab === "6" && <IndividualScreenInfo entityClientId={state?.entityClientId} viewOnly={state?.viewOnly}/>}
          </Tab>
          <Tab eventKey="7" title="Audit Trail">
            {activeTab === "7" && <IndiviudalAuditTrail entityClientId={state?.entityClientId} viewOnly={state?.viewOnly}/>}
          </Tab>
          <Tab eventKey={selectedDropdownKey} key={selectedDropdownKey} 
            title={
              <Dropdown onSelect={handleDropdownSelect} style={{ height: 21 }}>
                <Dropdown.Toggle variant='link' id='dropdown-basic' className='p-0 m-0' style={{ color:selectedDropdownKey ? '#FFFFFF' : '#262626', fontSize: 14, textDecoration: 'none' }}>
                  More
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey='8'>Archive</Dropdown.Item>
                  <Dropdown.Item eventKey='9'>Uploaded Documents</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            }
          >
            {activeTab === '8' && <IndividualOtherInfo entityClientId={state?.entityClientId} viewOnly={state?.viewOnly}/>}
            {activeTab === '9' && <IndividualUploadDocuments entityClientId={state?.entityClientId} viewOnly={state?.viewOnly}/>}
          </Tab>
        </Tabs>
      </div>

      {/* <div className='white-background p-3 mt-5'>
        <div className='d-flex align-items-center'>
          <img src={ScreenActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
          <h2 className='text-ambed text-center'>Screen Info</h2>
        </div>
        <hr />
        <div className='row'>
          <div className='col-lg-12'>
            <div className='row align-items-end'>
              <div className='col-lg-9' style={{ width: '82%' }}>
                <div className="form-group mt-3">
                  <label className="form-label" for="exampleInputEmail1">Add Comment</label>
                  <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Add Comment" rows="2" />
                </div>
              </div>
              <div className='col-lg-3' style={{ width: '18%' }}>
                <button className='btn-primary fw-normal'>Add Comment</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default IndividualScreeningDetails
