import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import './Login.scss'
import Logo from '../../assets/images/logo.png'
import LoginLeftImage from '../../assets/images/login_left_image.png'
import axios from 'axios';
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup';
import { verifyResetPasswordOtp, changeForgetPassword, sendForgetOpt } from './loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Loader from '../../components/Loader/Loader';

function ForgetPassword() {

  const [showPanle, setShowPanle] = useState(true);
  const [showPanleTwo, setShowPanleTwo] = useState(false);
  const [final, setFinal] = useState(false);
  const { isLoading } = useSelector(state => state.loginReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    email: yup.string().email('Invalid Email Adddress').matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email address').required('Email is Required'),
    otp: ((!showPanle && showPanleTwo && !final)) ? yup
      .string()
      .required('Code is required') : yup.string(),
    newPassword: (!showPanle && !showPanleTwo && final) ? yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.'
      ) : yup.string()
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSubmitFunc = async (data) => {
    const dispatchFunction = (showPanle && !showPanleTwo && !final) ? dispatch(sendForgetOpt({
      email: data?.email
    })) : ((!showPanle && showPanleTwo && !final) ? dispatch(verifyResetPasswordOtp({ email: data?.email, otp: data?.otp })) : dispatch(changeForgetPassword({ email: data?.email, newPassword: data?.newPassword, otp: data?.otp })))

    dispatchFunction.unwrap().then((res) => {
      if (showPanle && !showPanleTwo && !final) {
        setShowPanle(false);
        setShowPanleTwo(true);
      } else if (!showPanle && showPanleTwo && !final) {
        setShowPanleTwo(false);
        setFinal(true);
      } else {
        setShowPanle(false);
        setShowPanleTwo(false);
        setFinal(false);
        navigate("/login");
      }

      toast.success(res?.message);
    }).catch(error => {
      toast.error(error?.message || "Some Error Accured !");
    });
  }

  return (
    <section className="vh-100">
      {isLoading && <Loader />}
      <div className="container-fluid h-100">
        <div className="row d-flex justify-content-between align-items-center h-100 " >
          <div className="col-md-8 col-lg-7 col-xl-6 h-100 d-flex justify-content-center align-items-center imagecontainer" style={{ backgroundColor: '#E4F1F6', borderTopRightRadius: 50, borderBottomRightRadius: 50 }}>
            <img src={LoginLeftImage} className='loginImage' />
          </div>
          <div className="col-md-7 col-lg-5 col-xl-5 form-login my-2">
            <form onSubmit={handleSubmit(handleSubmitFunc)}>
              <div>
                <img src={Logo} className="logo mt-3" alt="Sample image" />
              </div>
              <h1 className='text-blue text-blue-login my-4'>
                Forgot Password
              </h1>
              <div className="form-outline">
                <label className="form-label" for="form3Example3">Email</label>
              </div>

              <input
                type="email"
                id="email"
                className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`}
                placeholder="Enter Email"
                disabled={!showPanle}
                {...register('email')}
                style={{
                  border: !showPanle ? '1px solid #D9D9D9' : '',
                  color: !showPanle ? '#262626' : '',
                }}
              />
              {errors?.email && (
                <div className="invalid-feedback">{errors?.email?.message}</div>
              )}


              {!showPanle && showPanleTwo && <div className="form-outline mb-4">
                <label className="form-label mt-3" for="form3Example3">Verification Code</label>
                <input type="text" id="otp" className={`form-control form-control-lg ${errors.otp ? 'is-invalid' : ''}`}
                  placeholder="Enter Verification Code" disabled={!showPanleTwo} {...register('otp')}
                  style={{
                    border: !showPanle && showPanleTwo ? '1px solid #D9D9D9' : '',
                    color: !showPanle && showPanleTwo ? '#262626' : '#D9D9D9',
                  }} />
                {errors?.otp && (
                  <div className="invalid-feedback">{errors?.otp?.message}</div>
                )}
              </div>}
              {final && <div className="form-outline mb-4 mt-3">
                <label className="form-label" for="form3Example3">Enter New Password </label>
                <input type="password" id="password" className={`form-control form-control-lg ${errors.newPassword ? 'is-invalid' : ''}`}
                  placeholder="Enter a New password" {...register('newPassword')}
                  style={{
                    border: final ? '1px solid #D9D9D9' : '',
                    color: final ? '#262626' : '#D9D9D9',
                  }} />
                {errors?.newPassword && (
                  <div className="invalid-feedback">{errors?.newPassword?.message}</div>
                )}
              </div>}
              <div className="text-center text-lg-start mt-4 pt-2 w-100">
                {showPanle && !showPanleTwo && <button type="submit" className="btn btn-primary btn-lg btn-block w-100">Send OTP</button>}
                {!showPanle && (showPanleTwo || final) && <button type="submit" className="btn btn-primary btn-lg btn-block w-100">Continue</button>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ForgetPassword
