import React, { useEffect, useState } from 'react'
import '../Profile.scss';
import OnboardClientBar from '../../../components/OnboardClientBar/OnboardClientBar';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '../../../assets/images/Cancel_icon.png'
import document_1 from '../../../assets/images/document_1.png'
import document_2 from '../../../assets/images/document_2.png'
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import Loader from '../../../components/Loader/Loader';
import { countryList } from '../../Registration/registrationSlice';
import { toast } from 'react-toastify';
import { searchCorporateCompany } from './corporateSlice';

function CorporatePersonalDetails() {
    const [show, setShow] = useState(false);
    const [showModalTwo, setShowModalTwo] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [selectedCheckbox, setSelectedCheckbox] = useState(null);
    const [companyRegNo, setCompanyRegNo] = useState(null);
    const [showList, setShowList] = useState(false);
    const { isLoading: countryLoading, countryData } = useSelector(state => state.registerReducer);
    const { isLoading, searchCompanyList } = useSelector(state => state.corporateOnboardingSlice);
    const routeCompanyDetails = () => {
        let path = `/profile/corporate_company_information`;
        navigate(path, { state: { companyRegNo } });
    }
    const goBack = () => {
        navigate(-1); // This navigates back to the previous page
    };

    const handleClose = () => setShow(false);

    const handleShow = (reg_no) => {
        setCompanyRegNo(reg_no)
        setShow(true);
    }

    const handleCloseModalTwo = () => setShowModalTwo(false);
    const handleShowModalTwo = () => setShowModalTwo(true);

    useEffect(() => {
        dispatch(countryList()).unwrap().catch((error) => {
            toast.error(error?.message)
        });
    }, []);

    const handleCheckboxChange = (event) => {
        setValue('select_source', event.target.id, { shouldValidate: true })
        setSelectedCheckbox(event.target.id);
    };

    const { control, handleSubmit, formState: { errors }, reset: resetForm, watch, setValue } = useForm({
        resolver: yupResolver(yup.object().shape({
            company_country_id: yup.string().required("Country is required"),
            company_reg_no: yup.string().required("Company Reg.No is required").min(8, "Company Reg.No must have 8 characters"),
            company_name: yup.string().required("Company Reg.No is required"),
            select_source: yup.string().required("Source is required"),
        }))
    });

    const onClickSearch = (data) => {
        if (selectedCheckbox === 'noProvider') {
            routeCompanyDetails()
        } else if (selectedCheckbox === 'creditSafe') {
            const queryParam = `?company_country_id=${data?.company_country_id}&company_name=${data?.company_name}&select_source=${data?.select_source}`
            dispatch(searchCorporateCompany(queryParam)).unwrap().catch((error) => {
                toast.error(error?.message)
            })
            setShowList(true)
        } else if (selectedCheckbox === 'companiesHouse') {
            const queryParam = `?company_country_id=${data?.company_country_id}&company_name=${data?.company_name}&select_source=${data?.select_source}`
            dispatch(searchCorporateCompany(queryParam)).unwrap().catch((error) => {
                toast.error(error?.message)
            })
            setShowList(true)
        }
    }
    return (
        <div className='grey-background'>
            {(isLoading || countryLoading) && <Loader />}
            <OnboardClientBar
                mainTitle={`Onboard Business Client `}
                title1={`COMPANY DETAILS`}
                title2={`COMPANY INFORMATION`}
                title3={`DIRECTOR LIST`}
                title4={`SHAREHOLDER LIST`}
                backgroundColor1={'#037AD0'} />

            <div className='row'>
                <div className='col-lg-12 col-md-12'>
                    <div className='white-background mt-4 p-3'>
                        <h1 className='text-blue px-2 fw-normal'>Select a source from where you want to fetch company information from</h1>
                        <hr />
                        <div className='row justify-content-between'>
                            <div className='col-lg-4 col-md-12'>
                                <div className="form-check">
                                    <input className={`form-check-input  ${errors.select_source ? 'is-invalid' : ''}`} type="checkbox" id="creditSafe" checked={selectedCheckbox === 'creditSafe'} onChange={handleCheckboxChange} />
                                    <label className="form-check-label" for="creditSafe">
                                        Credit Safe
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12'>
                                <div className="form-check">
                                    <input className={`form-check-input  ${errors.select_source ? 'is-invalid' : ''}`} type="checkbox" id="companiesHouse" checked={selectedCheckbox === 'companiesHouse'} onChange={handleCheckboxChange} />
                                    <label className="form-check-label" for="companiesHouse">
                                        Companies House
                                    </label>
                                    {/* {errors?.select_source && (
                                    <div className="invalid-feedback">{errors?.select_source?.message}</div>
                                )} */}
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12'>
                                <div className="form-check">
                                    <input className={`form-check-input  ${errors.select_source ? 'is-invalid' : ''}`} type="checkbox" id="noProvider" checked={selectedCheckbox === 'noProvider'} onChange={handleCheckboxChange} />
                                    <label className="form-check-label" for="noProvider">
                                        No Provider
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {watch('company_country_id') === '233' &&
                <div className='row mt-3'>
                    <div className='col-lg-12 col-md-12'>
                        <div className='darkgrey-bg px-3 py-2'>
                            <h5 className='text-black fw-bold'>If you are a Sole Trader / Proprietor then please use the Individual Onboarding form  </h5>
                        </div>
                    </div>
                </div>
            }
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='white-background p-3 mt-4'>
                        <h1 className='text-blue fw-normal'>Company Details</h1>
                        <hr />
                        <div className='form'>
                            <form>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleFormControlSelect1">Company Country</label>
                                            <Controller
                                                name="company_country_id"
                                                control={control}
                                                render={({ field: { value, onChange, onBlur } }) => (
                                                    <select value={value || ''} onChange={onChange} onBlur={onBlur} id="statusDropdown" className={`form-control form-select ${errors.company_country_id ? 'is-invalid' : ''}`}>
                                                        <option disabled value={''}>Select Country</option>
                                                        {
                                                            countryData?.map((item) => (
                                                                <option value={item?.country_id}>{item?.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                )}
                                            />
                                            {errors?.company_country_id && (
                                                <div className="invalid-feedback">{errors?.company_country_id?.message}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Company Reg. No</label>
                                            <Controller
                                                name="company_reg_no"
                                                control={control}
                                                render={({ field: { value, onChange, onBlur } }) => (
                                                    <input type="text" value={value} onChange={onChange} onBlur={onBlur} className={`form-control form-control-lg ${errors.company_reg_no ? 'is-invalid' : ''}`} id="exampleInputEmail1" placeholder="Enter Company Reg. No" />
                                                )}
                                            />
                                            {errors?.company_reg_no && (
                                                <div className="invalid-feedback">{errors?.company_reg_no?.message}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Company Name</label>
                                            <Controller
                                                name="company_name"
                                                control={control}
                                                render={({ field: { value, onChange, onBlur } }) => (
                                                    <input type="text" value={value} onChange={onChange} onBlur={onBlur} className={`form-control form-control-lg ${errors.company_name ? 'is-invalid' : ''}`} id="exampleInputEmail1" placeholder="Enter Company Reg. No" />
                                                )}
                                            />
                                            {errors?.company_name && (
                                                <div className="invalid-feedback">{errors?.company_name?.message}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end my-4'>
                <div className='d-flex align-items-center'>
                    <button type='submit' onClick={handleSubmit(onClickSearch)} className='btn-primary'>Search Company Results</button>
                </div>
            </div>

            {showList &&
                <div className='row'>
                    <div className='col-lg-12 col-md-12'>
                        <div className='white-background mt-2 p-3'>
                            <h1 className='text-blue px-2 fw-normal'>List of Companies</h1>
                            <div className='col-lg-12 col-md-12 mt-3'>
                                <div className='custom-table'>
                                    <div className='custom-table-head'>
                                        <div className='row'>
                                            <div className='col-2'>
                                                <h6 className='text-blue'>Company </h6>
                                            </div>
                                            <div className='col-3'>
                                                <h6 className='text-blue'>Company Reg No.</h6>
                                            </div>
                                            <div className='col-3'>
                                                <h6 className='text-blue'>Company Name</h6>
                                            </div>
                                            <div className='col-4'>
                                                <h6 className='text-blue'>Action</h6>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        searchCompanyList?.items?.length > 0 &&
                                        searchCompanyList?.items?.map((item, index) => (
                                            <div className='custom-table-body'>
                                                <div className='row align-items-center'>
                                                    <div className='col-2'>
                                                        <h6 className='text-black'>{index + 1}</h6>
                                                    </div>
                                                    <div className='col-3'>
                                                        <h6 className='text-black'>{item?.company_number}</h6>
                                                    </div>
                                                    <div className='col-3'>
                                                        <h6 className='text-black'>{item?.title}</h6>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className='d-flex align-items-center'>
                                                            <h6 className='text-black green-small-bg'>View Report</h6>
                                                            <h6 onClick={() => handleShow(item?.company_number)} className='text-black light-blue-small-bg'>Use Report</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Modal className='modal-lg' show={show} onHide={handleClose} centered>
                <Modal.Header >
                    <h5 className="modal-title text-center text-blue mt-4"><b>The service you are selecting is chargeable. Are you sure you want to proceed?</b></h5>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 6, marginRight: 8, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Footer >
                    <div className='row w-50'>
                        <div className='col'>
                            <button onClick={handleClose} type="button" className="btn-secondary w-100 mb-3">Cancel</button>
                        </div>
                        <div className='col'>
                            <button onClick={() => { handleClose(); handleShowModalTwo() }} type="button" className="btn-primary w-100 mb-3">Proceed</button>
                        </div>

                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalTwo} onHide={handleCloseModalTwo} centered >
                <Modal.Header className='model-header mt-2 pb-3'>
                    <h3 className='text-blue text-center mt-2 mb-0'>How would you like to onboard a client ?</h3>
                    <div onClick={handleCloseModalTwo} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 6, marginRight: 8, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Footer >
                    <div className='footer-btn d-flex align-items-center justify-content-between'>
                        <div type="button" className="coustom-btn"  >
                            <div className='manual-btn d-flex align-items-center justify-content-center' onClick={routeCompanyDetails}>
                                <div className='btn_img' style={{ backgroundColor: "#FFF6E9" }}>
                                    <img src={document_1}
                                        style={{ width: "20px", height: "20px" }}
                                        alt="document_1" />
                                </div>
                                <div>
                                    <p className='btn-title mb-0'>Manual</p>
                                </div>
                            </div>
                        </div>
                        <div type="button" className="coustom-btn"  >
                            <div className='manual-btn d-flex align-items-center justify-content-center'>
                                <div className='btn_img' style={{ backgroundColor: "#E6F8F2" }}>
                                    <img src={document_2}
                                        style={{ width: "20px", height: "20px" }}
                                        alt="document_2" />
                                </div>
                                <div>
                                    <p className='btn-title mb-0'>Webform</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default CorporatePersonalDetails
