import React, { useEffect, useState } from 'react'
import AmlCheckFilter from '../../assets/images/aml_check_filter.png'
import AmlCheckInfo from '../../assets/images/aml_check_inro.png'
import AmlCheckComment from '../../assets/images/aml_check_comment.png'
import AmlCheckMonitor from '../../assets/images/aml_check_monitor.png'
import CancelIcon from '../../assets/images/Cancel_icon.png'
import SendComment from '../../assets/images/send_comment_icon.png'
import FilterImg from '../../assets/images/aml_check_filter.png'
import Modal from 'react-bootstrap/Modal';
import { Button, Nav, Tab } from 'react-bootstrap'
import '../../pages/AmlCheck/Aml.scss'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup'
import moment from 'moment';
import ViewIcon from '../../assets/images/view_icon.png'
import DownloadIcon from '../../assets/images/zip_download_img.png'
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux'
import TablePagination from '../Pagination'
import { getSignedUrls } from '../../utils/awsHelper'
import { TransformWrapper,TransformComponent } from "react-zoom-pan-pinch";
import Loader from '../Loader/Loader'
import { toast } from 'react-toastify'
function TopResults(props) {
    const navigate = useNavigate();
    const { onClickComments, titleofMasterComments, personalDetails, getSearchCertificate, updateSearch,getAmlDocuments } = props;
    const [show, setShow] = useState(false);
    const [fullScreenImageSrc, setFullScreenImageSrc] = useState(null);
    const [showImg, setShowImg] = useState(false);
    const [showModalTwo, setShowModalTwo] = useState(false);
    const [showModalThree, setShowModalthree] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPge, setPostsPerPage] = useState(10);
    const [showImgLoader,setShowLoaderImg]=useState(false)
    const handleShowImg = () => setShowImg(true);
    const handleCloseImg = () => setShowImg(false);
    
    const { isLoading, fetchDocuments } = useSelector(state => state.individualScreeningReducer);
    // Define the custom method for oneOfRequired
    yup.addMethod(yup.object, 'oneOfRequired', function(fields, message) {
        return this.test('oneOfRequired', message, function(value) {
            const { path, createError } = this;
            const valid = fields.some(field => value && value[field]);
            return valid || createError({ path: fields[0], message }); // Attach the error to the first field
        });
    });

    const schema = yup.object().shape({
        risk_level: yup
            .string()
            .typeError('Risk Level must be a string')
            .nullable()
            .notRequired(),
        match_status: yup
            .string()
            .typeError('Match Status must be a string')
            .nullable()
            .notRequired(),
        search: yup.string().notRequired(),
    }).oneOfRequired(['risk_level', 'match_status'], 'Either Risk Level or Match Status is required');

    const { control, register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseModalTwo = () => setShowModalTwo(false);
    const handleShowModalTwo = () => setShowModalTwo(true);

    const handleCloseModalThree = () => setShowModalthree(false);
    const handleShowModalthree = () => setShowModalthree(true);


    const routeChange = () => {
        let path = `/aml/master_comments`;
        navigate(path);
    }

    const onSubmit = (data) => {
        updateSearch(data);
    }

    const handlePagination = (event, value) => {
        setCurrentPage(value);
      }
     
      useEffect(() => {
          if (personalDetails?.entity_client_id) {
            getAmlDocuments(personalDetails?.entity_client_id, currentPage, postsPerPge);
            }
        }, [personalDetails?.entity_client_id, currentPage, postsPerPge]);

        const handleClick = () => {
            getAmlDocuments(personalDetails?.entity_client_id, currentPage, postsPerPge);
            handleShowModalthree(); 
        };
    const formatUploadedBy = (str) => {
        if (!str) return '';
        return str
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    const handleDownload = async (documentUrl) => {
        const signedUrl =await getSignedUrls(documentUrl);
        window.open(signedUrl, '_blank');
    };

    const handleImageClick = async (imgUrl) => {
        setShowLoaderImg(true); 
        try {
            const signedUrl = await getSignedUrls(imgUrl);
            if (typeof signedUrl === 'string') {
                setFullScreenImageSrc(signedUrl);
                handleShowImg(true);
            } else {
                toast.error('Invalid image URL:', signedUrl);
            }
            setShowLoaderImg(false); 
        } catch (error) {
            toast.error('Error fetching signed URL:', error);
            setShowLoaderImg(false);
        }
    };
    return (
        <>
            {showImgLoader && <Loader />}

            <div className='d-flex align-items-center justify-content-between'>
                <h1 className='text-blue px-2 fw-normal'>Search Results for "{(personalDetails?.first_name || '') + " "+(personalDetails?.middle_name ? ' ' + personalDetails.middle_name : '') + " " +(personalDetails?.last_name || '')}"</h1>
                <div className='d-flex align-items-center'>
                    {/* <h4 onClick={onClickComments} className='text-blue dark-blue-small-bg fw-normal' style={{marginRight:15}}>{titleofMasterComments}</h4> */}
                    {
                        personalDetails?.entity_client_id && <h4 onClick={() => getSearchCertificate(personalDetails?.entity_client_id)} className='text-blue dark-blue-small-bg fw-normal'>View Search Certificate</h4>
                    }
                 <h4 onClick={handleClick } className='text-blue dark-blue-small-bg fw-normal mr-2 ms-2'>View Aml Documents</h4>
                </div>
            </div>
            <hr />
            { personalDetails?.continous_monitoring && 
            <div className='white-background w-100 p-3'>
                <div className='row align-items-end'>
                    {/* <div className='d-flex align-items-center'>
                        <img onClick={handleShow} src={FilterImg} style={{ width: 24, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                        <div className='form-label mb-0'>Filters</div>
                    </div> */}
                    <div className='col-lg-9 col-md-12'>
                        <form className='form' onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            {/* <div className='col-lg-4 col-md-12'>
                                <div className="form-group mt-3">
                                    <label className="form-label" for="exampleFormControlSelect1">Assigned To</label>
                                    <select id="statusDropdown" className="form-control form-select">
                                        <option>Sandeep Golechha</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </div>
                            </div> */}
                            {/* <div className='col-lg-2 col-md-12'>
                                <div className="form-group mt-3">
                                    <label className="form-label" for="exampleFormControlSelect1">Fuziness</label>
                                    <img src={AmlCheckInfo} style={{ width: 18, objectFit: 'contain', cursor: 'pointer', marginTop: 2, marginLeft: 10 }} />
                                    <select id="statusDropdown" className="form-control form-select">
                                        <option>0.5</option>
                                        <option value="unknown">0.5</option>
                                        <option value="low">0.5</option>
                                        <option value="medium">0.5</option>
                                        <option value="high">0.5</option>
                                    </select>
                                </div>
                            </div> */}
                                <div className='col-lg-4 col-md-12'>
                                    <div className="form-group mt-3">
                                        <label className="form-label" for="exampleFormControlSelect1">Risk Level</label>
                                        <img src={AmlCheckInfo} style={{ width: 18, objectFit: 'contain', cursor: 'pointer', marginTop: 2, marginLeft: 10 }} />

                                        <Controller
                                            name="risk_level"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <select id="statusDropdown" className={`form-control form-select ${errors.risk_level ? 'is-invalid' : ''}`} {...field}>
                                                    <option value="">Please Select Risk Status</option>
                                                    <option value="unknown">Unknown</option>
                                                    <option value="low">Low</option>
                                                    <option value="medium">Medium</option>
                                                    <option value="high">High</option>
                                                </select>
                                            )}
                                        />
                                        {errors?.risk_level && (
                                            <div className="invalid-feedback">{errors?.risk_level?.message}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-12'>
                                    <div className="form-group mt-3">
                                        <div className='d-flex align-items-start'>
                                            <label className="form-label" for="exampleFormControlSelect1">Match Status</label>
                                            <img onClick={handleShowModalTwo} src={AmlCheckInfo} style={{ width: 18, objectFit: 'contain', cursor: 'pointer', marginTop: 2, marginLeft: 10 }} />
                                        </div>

                                        <Controller
                                            name="match_status"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <select id="statusDropdown" className={`form-control form-select mt-1 ${errors.match_status ? 'is-invalid' : ''}`} {...field}>
                                                    <option value="">Please select Match Status</option>
                                                    <option value="unknown">Unknown</option>
                                                    <option value="no_match">No Match</option>
                                                    <option value="potential_match">Potential Match</option>
                                                    <option value="false_positive">False Positive</option>
                                                    <option value="true_positive">True Positive</option>
                                                    <option value="true_positive_approve">True Positive - Approve</option>
                                                    <option value="true_positive_reject">True Positive - Reject</option>
                                                </select>
                                            )}
                                        />
                                        {errors?.match_status && (
                                            <div className="invalid-feedback">{errors?.match_status?.message}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-3 d-flex align-items-end'>
                                    <button className='btn-primary w-100'>Update Search</button>
                                </div>
                        </div>
                        </form>
                    </div>
                    <div className='col-lg-3 col-md-12'>
                        <div className='row'>
                            {/* <div className='col-lg-3'>
                                <img onClick={handleShow} src={AmlCheckFilter} style={{ width: 35, objectFit: 'contain', cursor: 'pointer' }} />
                            </div> */}
                            {/* <div className='col-lg-3'>
                                <img onClick={handleShow} src={AmlCheckInfo} style={{ width: 35, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                            <div className='col-lg-3'>
                                <img onClick={handleShow} src={AmlCheckComment} style={{ width: 35, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                            <div className='col-lg-3'>
                                <img onClick={handleShow} src={AmlCheckMonitor} style={{ width: 35, objectFit: 'contain', cursor: 'pointer' }} />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            }
            <Modal show={showModalThree} onHide={handleCloseModalThree} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Uploaded Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='custom-table pb-3'>
                        <div className='custom-table-head'>
                            <div className='row'>
                                <div className='col'>
                                    <h6 className='text-blue'>Action Date Time</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue'>File Uploaded</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue'>Document Type</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue'>Uploaded By</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue'>Action</h6>
                                </div>
                            </div>
                        </div>
                        {fetchDocuments?.rows?.map((data) =>
                            <div className='custom-table-body'>
                                <div className='row'>
                                    <div className='col'>
                                        <h6 className='text-black'>
                                            <h4 className='text-black'>{(data?.created_at) ? moment(data?.created_at).format("DD/MM/YYYY") : ''} - {(data?.created_at) ? moment(data?.created_at).format("HH:mm:ss") : ''}</h4>
                                        </h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>{data?.filename}</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>{formatUploadedBy(data?.document_type)}</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>{data?.user_type}</h6>
                                    </div>
                                    <div className='col'>
                                        <img src={DownloadIcon} onClick={() => handleDownload(data.document_url)} style={{ width: 15, objectFit: 'contain', cursor: 'pointer' }} />
                                        <img src={ViewIcon} onClick={() => {
                                            handleShowImg();
                                            handleImageClick(data.document_url);
                                        }} style={{ width: 15, objectFit: 'contain', cursor: 'pointer', marginLeft: 12 }} />

                                    </div>
                                </div>
                            </div>
                        )}
                        {fetchDocuments && fetchDocuments?.rows?.length > 0 &&
                            <TablePagination totalPages={parseInt(fetchDocuments?.totalPages)} currentPage={currentPage} color="primary" handlePagination={handlePagination} />
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalThree}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal className='modal-lg aml-fliter-modal' show={show} onHide={handleClose} centered style={{ overflow: 'hidden' }}>
                <Modal.Header style={{ borderBottom: '1px solid #D9D9D9 ' }}>
                    <h5 className="modal-title text-center text-blue mt-4"><b>Search Information</b></h5>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 6, marginRight: 8, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-center justify-content-center' style={{ gap: 150 }}>
                        <div class="form-check mt-3">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label class="form-check-label" for="flexRadioDefault1">Relevant</label>
                        </div>
                        <div class="form-check mt-3">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label class="form-check-label" for="flexRadioDefault1">Non-Relevant</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ display: 'block', borderTop: '1px solid #D9D9D9 ', padding: 0 }}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className='row'>
                            <div className='col-lg-3' style={{ width: '11.666667%', borderRight: '1px solid #D9D9D9 ', padding: 10 }}>
                                <Nav variant="pills" className="flex-column" >
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">
                                            <img src={AmlCheckInfo} style={{ width: 40, objectFit: 'contain', }} />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">
                                            <img src={AmlCheckComment} style={{ width: 40, objectFit: 'contain', }} />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">
                                            <img src={AmlCheckMonitor} style={{ width: 40, objectFit: 'contain', }} />
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className='col-lg-9' style={{ width: '88.333333%', padding: 0 }}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first" style={{ padding: 32 }}>
                                        <div className='row'>
                                            <div className='col-lg-5'>
                                                <h1 className='text-black'>Search Term:</h1>
                                            </div>
                                            <div className='col-lg-5'>
                                                <h1 className='text-grey fw-normal'>username</h1>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-lg-5'>
                                                <h1 className='text-black'>Created At:</h1>
                                            </div>
                                            <div className='col-lg-5'>
                                                <h1 className='text-grey fw-normal'>Jul 17, 2023 4:00 PM</h1>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-lg-5'>
                                                <h1 className='text-black'>Search Ref: </h1>
                                            </div>
                                            <div className='col-lg-5'>
                                                <h1 className='text-grey fw-normal'>1689589838-4yoQ9R5y</h1>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-lg-5'>
                                                <h1 className='text-black'>Fuzziness Interval: </h1>
                                            </div>
                                            <div className='col-lg-5'>
                                                <h1 className='text-grey fw-normal'>50%</h1>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second" >
                                        <div className='position-absolute' style={{ width: '88%', bottom: 0 }}>
                                            <div className='d-flex justify-content-between align-items-end p-3' style={{ backgroundColor: '#EEEEEE', borderBottomRightRadius: 20 }}>
                                                <h1 className='text-black'>Type a message to comment on the whole case.</h1>
                                                <img src={SendComment} style={{ width: 30, objectFit: 'contain', }} />
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </Modal.Footer>
            </Modal>



            <Modal className='modal-lg' style={{height:600 , marginTop: 100}} show={showModalTwo} onHide={handleCloseModalTwo} centered>
                <Modal.Header style={{ borderBottom: '1px solid #D9D9D9 ' }}>
                    <h5 className="modal-title text-center text-blue mt-4"><b>Match Status</b></h5>
                    <div onClick={handleCloseModalTwo} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 6, marginRight: 8, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body >
                    <div className='custom-table'>
                        <div className='custom-table-head mt-3'>
                            <div className='row'>
                                <div className='col-2'>
                                    <h6 className='text-blue'>Match Status</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-blue'>Best Practice - Case Level</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-blue'>Best Practice - Entity Level</h6>
                                </div>
                            </div>
                        </div>
                        <div className='custom-table-body'>
                            <div className='row align-items-start'>
                                <div className='col-2'>
                                    <h6 className='text-black'>No Match</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-black'>This is returned by default when there aren't any results available. If the initial search comes
                                        back as a Potential Match and none of the results are the persons or the companies that you
                                        are looking for, it is recommended to use False Positive rather than No Match</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-black'>If there are entities that do not match your criteria then it's better to mark the entity as False Positive</h6>
                                </div>
                            </div>
                        </div>
                        <div className='custom-table-body'>
                            <div className='row align-items-start'>
                                <div className='col-2'>
                                    <h6 className='text-black'>Potential Match</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-black'>This is returned by default when at least one individual or company is available. You can filter on Potential Matches to create a list of cases that need to be reviewed, and then update the match status as appropriate once reviewed</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-black'>Returned by default for all entities returned on a search. Use this status to track which entities have not yet been reviewed</h6>
                                </div>
                            </div>
                        </div>
                        <div className='custom-table-body'>
                            <div className='row align-items-start'>
                                <div className='col-2'>
                                    <h6 className='text-black'>False Positive</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-black'>Use this match status to mark a search that has returned the person or the company that you were NOT looking for even though the entities returned matched the search criteria used</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-black'>This match status is usually used along with Whitelisting feature</h6>
                                </div>
                            </div>
                        </div>
                        <div className='custom-table-body'>
                            <div className='row align-items-start'>
                                <div className='col-2'>
                                    <h6 className='text-black'>True Positive</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-black'>Use this match status to mark a search that has returned the person or the company that you were looking for but where a decision on what action to take has not yet been made</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-black'>Use this to mark that a specific entity is a match for the person or a company you made the search for</h6>
                                </div>
                            </div>
                        </div>
                        <div className='custom-table-body'>
                            <div className='row align-items-start'>
                                <div className='col-2'>
                                    <h6 className='text-black'>True Positive - Approve</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-black'>Use this to mark a search that has returned the person or the company you have searched for and you have decided to start or continue doing business with them after reviewing the match
                                        (similar cases apply to onboarding customers or any other relevant examples)</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-black'>Not available
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className='custom-table-body'>
                            <div className='row align-items-start'>
                                <div className='col-2'>
                                    <h6 className='text-black'>True Positive - Reject</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-black'>Use this to mark true positives that are outside of your risk tolerance and you decide not to do business with</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-black'>Not available
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className='custom-table-body'>
                            <div className='row align-items-start'>
                                <div className='col-2'>
                                    <h6 className='text-black'>Unknown</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-black'>Use this for potential matches that have been reviewed, but where the review is inconclusive or more information is required before making a decision. Unknown is best used as a temporary match status</h6>
                                </div>
                                <div className='col-5'>
                                    <h6 className='text-black'>Use this for potential matches that have been reviewed, but where the review is inconclusive or more information is required before making a decision. Unknown is best used as a temporary match status
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showImg} onHide={handleCloseImg} centered scrollable size='md'>
                <Modal.Header className='model-header mt-2 pb-3'>
                    <div onClick={handleCloseImg} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginRight: 8, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="fullscreen-overlay active">
                            <TransformWrapper
                                initialScale={1}
                                initialPositionX={0}
                                initialPositionY={0}
                                centerOnInit={true}
                            >
                                <TransformComponent wrapperStyle={{ width: '100%' }}>
                                    <img
                                        src={fullScreenImageSrc}
                                        alt="Full-Screen View"
                                        style={{ width: '100%', objectFit: 'contain', cursor: 'pointer', height: 400}}
                                    />
                                </TransformComponent>
                            </TransformWrapper>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TopResults
