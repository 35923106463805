import React, { useState, useEffect } from 'react'
import { decodeToken } from "react-jwt";
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Controller, useForm, useWatch } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { countryList } from '../../Registration/registrationSlice';
import OnboardClientBar from '../../../components/OnboardClientBar/OnboardClientBar';
import Tech5SDK from '../../../tech5-sdk-web/dist/Tech5SDK.main';
import { fetchData } from '../../../utils/getToken';
import '../Profile.scss';
import CancelIcon from '../../../assets/images/Cancel_icon.png'
import UploadCircleIcon from '../../../assets/images/upload-circle-icon.png'
import UploadLineIcon from '../../../assets/images/upload _Line_icon.png'
import BlackInfoIcon from '../../../assets/images/black _Info Circle_.png'
import CircleBlueTick from '../../../assets/images/circle_blue_tick.png'
import TickBlackIcon from '../../../assets/images/tick_black_icon.png'
import VideoIcon from '../../../assets/images/video-icon.png'
import cancelBtn from '../../../assets/images/upload-box-cancel-btn.png'
import uploadDocDone from '../../../assets/images/upload-doc-done.png'
import { adjustRetryCount, deductKYCLivelynessCharge, fetchEntityClientPersonalDetail, fetchEntityData, saveEntityClientKycDetail } from '../profileSlice';
import Loader from '../../../components/Loader/Loader';
import Logo from "../../../assets/images/logo.png"
import UploadIcon from '../../../assets/images/upload-icon.png'
import uploadUserPhoto from '../../../assets/images/upload-photos-img.png'
import greenIcon from '../../../assets/images/greenRightIcon.png'
import { decryptQueryParam } from '../../../utils/EncryptionUtils';
import Select from 'react-select';

function IndividualKYCVerification() {
    const options = [
    //{ value: 'pancard', label: 'Pancard' }, 
    { value: 'aadhaarcard', label: 'Aadhaar card' },
    { value: 'bank_statement', label: 'Bank, Building Society or Credit Union Statement' },
    { value: 'tenancy_agreement', label: 'Council or Housing Association Tenancy Agreement' },
    { value: 'council_tax_bill', label: 'Council Tax Bill' },
    { value: 'credit_card_statement', label: 'Credit Card Statement' },
    { value: 'driving_licence', label: 'Driving Licence' },
    { value: 'benefits_letter', label: 'Government Benefits Entitlement letter' },
    { value: 'benefit_book', label: 'Government Benefit Book' },
    { value: 'motor_insurance', label: 'Motor Insurance Certificate / Renewal Notice / Current Policy' },
    { value: 'household_insurance', label: 'Household Insurance Certificate / Renewal Notice / Current Policy' },
    { value: 'hmrc_tax', label: 'HMRC Tax Notification (for non residents from their tax authority)' },
    { value: 'landline_letter', label: 'Letter from Landline Services providers' },
    { value: 'housing_benefits', label: 'Letter from Local Authority confirming entitlement to Housing Benefits' },
    { value: 'voters_roll', label: 'Letter from Local Council re Voters Roll' },
    { value: 'life_assurance', label: 'Life Assurance cover documentation' },
    { value: 'mortgage_statement', label: 'Mortgage Statement' },
    { value: 'national_id', label: 'National ID Card' },
    { value: 'ni_card', label: 'National Insurance Card' },
    { value: 'shotgun_licence', label: 'Police Issued Shot Gun Licence' },
    { value: 'tv_licence', label: 'TV Licence Renewal' },
    { value: 'utility_bill', label: 'Utility Bill (Gas, Electricity)' },
    { value: 'tv_supplier', label: 'Satellite or Cable TV or Land Line Telephone Supplier' },
    { value: 'voters_polling_card', label: 'Voters Polling Card (Local or National Elections)' },
  ];
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const userToken = localStorage.getItem('token');
  const decodedToken = (state.webformToken)? {} :decodeToken(userToken);
  const { personalDetails } = useSelector(state => state.ProfileReducer);
  const { countryData } = useSelector(state => state.registerReducer);
  const [proofOfIdentity, setProofOfIdentity] = useState(null);
  const [proofOfAddress, setProofOfAddress] = useState(null);
  const [sdk, setSdk] = useState();
  const [response, setResponse] = useState();
  const [firstIdentityUploadBoxes, setFirstIdentityUploadBoxes] = useState([]);
  const [secondIdentityUploadBoxes, setSecondIdentityUploadBoxes] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState('');
  const [selectedDocument2, setSelectedDocument2] = useState('');
  const [showAddressStep, setShowAddressStep] = useState(false);
  const [secondIdentityDropDown, setSecondIdentityDropDown] = useState([]);
  const [addressDropDown, setAddressDropDown] = useState([]);
  const [entityClientId, setEntityClientId] = useState();
  const [addressObject, setAddressObject] = useState({});
  const { isLoading, firstIdentityRetryAttempt, secondIdentityRetryAttempt } = useSelector(state => state.ProfileReducer);
  const [kycCheckDone, setKycCheckDone] = useState(false);
  const [packageService, setPackageService] = useState(null);
  const [decryptedValue, setDecryptedValue] = useState(null);

  const [identityType, setIdentityType] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleCloseAddress = () => setShow3(false);
  const handleShowAddress = () => setShow3(true);
  const handleShow2 = (type) => {
    setIdentityType(type);
    setShow2(true);
  };

  useEffect(() => {
    dispatch(countryList());
    if (state?.entityClientId) {
      setEntityClientId(state?.entityClientId);
      dispatch(fetchEntityClientPersonalDetail({ queryParam: `?entity_client_id=${state?.entityClientId}`, webformToken: state?.webformToken })).catch((error) => {
        toast.error(error?.message);
      });
    } else {
      navigate('/profile/individual_address');
    }
  }, [])

  const kycSchema = yup.object().shape({
    firstIdentity: yup.object().shape({
      country_id: yup.number().typeError('Country is required').required('Country is required'),
      entity_client_id: yup.number().typeError('Entity Client ID is required').required('Entity Client ID is required'),
      proof_type: yup.string().when('proofOfIdentity', {
        is: val => val === 1 || val === 2,
        then: yup.string().required('Proof Type is required')
      }).default('identity'),
      identity_task_id: yup.string(),
      identity_document_type: yup.string(),
      identity_document_selfie: yup.string().when('identity_document_type', {
        is: (type) => (type === 'card' || type === 'passport'),
        then: () => yup.string().required('Selfie URL is required'),
        otherwise: () => yup.string()
      }),
      identity_document_frontid: yup.string().when('identity_document_type', {
        is: 'card',
        then: () => yup.string().required('Front ID URL is required'),
        otherwise: () => yup.string()
      }),
      identity_document_backid: yup.string().when('identity_document_type', {
        is: 'card',
        then: () => yup.string().required('Back ID URL is required'),
        otherwise: () => yup.string()
      }),
      identity_document_passport: yup.string().when('identity_document_type', {
        is: 'passport',
        then: () => yup.string().required('Passport URL is required'),
        otherwise: () => yup.string()
      }),
      type: yup.string().oneOf(["manual", "live"], 'KYC check is mandatory').required('KYC check is mandatory'),
      provider_details : yup.object().shape().required('Provider Details is required')
    }),
    secondIdentity: (proofOfIdentity == 2) ? yup.object().shape({
      country_id: yup.number().typeError('Country is required').required('Country is required'),
      entity_client_id: yup.number().typeError('Entity Client ID is required').required('Entity Client ID is required'),
      proof_type: yup.string().required('Proof Type is required'),
      identity_document_type: yup.string(),
      identity_task_id: yup.string(),
      identity_document_selfie: yup.string().when('identity_document_type', {
        is: (type) => (type === 'card' || type === 'passport'),
        then: () => yup.string().required('Selfie URL is required'),
        otherwise: () => yup.string()
      }),
      identity_document_frontid: yup.string().when('identity_document_type', {
        is: 'card',
        then: () => yup.string().required('Front ID URL is required'),
        otherwise: () => yup.string()
      }),
      identity_document_backid: yup.string().when('identity_document_type', {
        is: 'card',
        then: () => yup.string().required('Back ID URL is required'),
        otherwise: () => yup.string()
      }),
      identity_document_passport: yup.string().when('identity_document_type', {
        is: 'passport',
        then: () => yup.string().required('Passport URL is required'),
        otherwise: () => yup.string()
      }),
      type: yup.string().oneOf(["manual", "live"], 'KYC check is mandatory').required('KYC check is mandatory'),
      provider_details : yup.object().shape().required('Provider Details is required')
    }) : yup.object().shape({}),
    address1: (proofOfAddress >= 1 && showAddressStep) ? yup.object().shape({
      entity_client_id: yup.number().typeError('Entity Client ID is required').required('Entity Client ID is required'),
      proof_type: yup.string().required('Proof Type is required').default('address'),
      address_document_type: yup.string().optional(),
      address_document: yup.string().optional(),
    }) : yup.object().shape({}),
    address2: (proofOfAddress == 2 && showAddressStep) ? yup.object().shape({
      entity_client_id: yup.number().typeError('Entity Client ID is required').required('Entity Client ID is required'),
      proof_type: yup.string().required('Proof Type is required').default('address'),
      address_document_type: yup.string().optional(),
      address_document: yup.string().optional(),
    }) : yup.object().shape({})
  });

  const { control, register, handleSubmit, formState: { errors }, reset, setValue, trigger } = useForm({
    resolver: yupResolver(kycSchema),
  });

  console.log("errors logs", errors);

  useEffect(() => {
    if (decodedToken?.entityId || state?.entityId) {
      let entityId = (state?.webformToken) ? state?.entityId : decodedToken?.entityId;
      dispatch(fetchEntityData({ queryParams: `?entityId=${entityId}`, webformToken: state?.webformToken })).unwrap().then((rep) => {
        setProofOfIdentity((rep?.data?.package_type === 'prepaid') ? 1 : rep?.data?.proof_of_identity);
        setProofOfAddress((rep?.data?.package_type === 'prepaid') ? 0 : rep?.data?.proof_of_address);
        setPackageService(rep?.data);
      }).catch((error) => {
        toast.error(error?.message);
      });
    }
    dispatch(countryList());
  }, [decodedToken?.entityId, state?.entityId]);

  useEffect(() => {
    if (proofOfIdentity) {
      const updateIdentityFields = (identityType) => {
        kycSchema.fields[identityType] = kycSchema.fields[identityType].shape({
          country_id: yup.number().typeError('Country is required').required('Country is required'),
          entity_client_id: yup.number().typeError('Entity client id is required').required('Entity client id is required'),
          proof_type: yup.string().required('Proof type is required'),
        }).required();
      };
      if (proofOfIdentity && proofOfIdentity >= 1) {
        updateIdentityFields('firstIdentity');
      }
      if (proofOfIdentity && proofOfIdentity === 2) {
        updateIdentityFields('secondIdentity');
      }
    }
  }, [proofOfIdentity]);

  const handleDocumentChange = (type, event) => {
    const setDocumentAndUploadBoxes = (selectedDocument, uploadBoxes) => {
      const documentFields = ['identity_document_selfie', 'identity_document_frontid', 'identity_document_backid', 'identity_document_passport'];
      documentFields.forEach(field => setValue(`${type}.${field}`, ''));
      if (type === 'firstIdentity') {
        setSelectedDocument(selectedDocument);
        setValue('firstIdentity.type', 'manual');
        setFirstIdentityUploadBoxes(uploadBoxes);
        trigger([`firstIdentity.type`]);
        setSecondIdentityDropDown([]);
        event.target.value === 'passport' && setSecondIdentityDropDown([{ name: 'Card', value: 'card' }]);
        event.target.value === 'card' && setSecondIdentityDropDown([{ name: 'Passport', value: 'passport' }]);
      } else if (type === 'secondIdentity') {
        setSelectedDocument2(selectedDocument);
        setSecondIdentityUploadBoxes(uploadBoxes);
        setValue('secondIdentity.type', 'manual');
        trigger([`secondIdentity.type`]);
      }
    };
    // setSelectedDocument2([]);
    if (event.target.value === 'passport') {
      setDocumentAndUploadBoxes('passport', [
        { type: 'selfie', imageName: 'Upload selfie', imageSrc: UploadLineIcon },
        { type: 'passport', imageName: 'Upload passport', imageSrc: UploadLineIcon }
      ]);
    } else if (event.target.value === 'card') {
      setDocumentAndUploadBoxes('card', [
        { type: 'selfie', imageName: 'Upload selfie', imageSrc: UploadLineIcon },
        { type: 'frontid', imageName: 'Upload frontid', imageSrc: UploadLineIcon },
        { type: 'backid', imageName: 'Upload backid', imageSrc: UploadLineIcon }
      ]);
    } else {
      setValue(`${type}.type`, '');
      type === 'secondIdentity' && setSelectedDocument2(null);
      type === 'firstIdentity' && setSelectedDocument(null);
      const documentShape = {
        identity_document_selfie: yup.string(),
        identity_document_frontid: yup.string(),
        identity_document_backid: yup.string(),
        identity_document_passport: yup.string()
      };
      kycSchema.fields['firstIdentity'] = kycSchema.fields['firstIdentity'].shape(documentShape).required();
      kycSchema.fields['secondIdentity'] = kycSchema.fields['secondIdentity'].shape(documentShape).required();
      trigger([`firstIdentity.identity_document_selfie`, `firstIdentity.identity_document_frontid`, `firstIdentity.identity_document_backid`, `firstIdentity.identity_document_passport`]);
      trigger([`secondIdentity.identity_document_selfie`, `secondIdentity.identity_document_frontid`, `secondIdentity.identity_document_backid`, `secondIdentity.identity_document_passport`]);
      trigger([`firstIdentity.type`, `secondIdentity.type`]);
    }
  };

  const customStyles = {
    control: (provided) => ({
        ...provided,
        // padding: '0.4rem 0.50rem',
        borderRadius: '10px',
        border: '1px solid #bcdae6',
        lineHeight: 1.5,
        color: '#037AD0',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        '&:hover': {
            border: '1px solid #B3B3B3',
        },
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: '10px',
        border: '2px solid #D9D9D9',
        color: '#037AD0',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#037AD0',
    }),
};

  const handleFileChange = (e, type, field, index) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    if (type === 'firstIdentity') {
      firstIdentityUploadBoxes[index].imageName = file.name;
      firstIdentityUploadBoxes[index].imageSrc = uploadDocDone;
    }
    if (type === 'secondIdentity') {
      secondIdentityUploadBoxes[index].imageName = file.name;
      secondIdentityUploadBoxes[index].imageSrc = uploadDocDone;
    }
    reader.onload = () => {
      const base64String = reader.result.split(',')[1];
      const base64Image = `data:${file.type};base64,${base64String}`;
      setValue(`${type}.${field}`, base64Image);
      trigger([`${type}.${field}`]);
    };

    reader.readAsDataURL(file);
  };

  const handleAddressDocumentChange = (type, value) => {
    if (proofOfAddress) {
      let document = {};
  
      if (type === 'address1') {
        if (value) {
          // Filter out the selected value for the second dropdown
          const filteredOptions = options.filter(option => option.value !== value);
          setAddressDropDown(filteredOptions);
        } else {
          // Reset the second dropdown if no value is selected
          setAddressDropDown(options);
        }
      }
  
      if (value === '') {
        setValue(`${type}.address_document`, null);
        setValue(`${type}.address_document_type`, null);
        document.address_document = yup.string().required('Address document is required');
        document.address_document_type = yup.string().required('Address document type is required');
        setAddressObject({ [`${type}_imageSrc`]: '', [`${type}_imageName`]: '' });
      } else if (value) {
        setValue(`${type}.address_document`, null);
        document.address_document = yup.string().required('Address document is required');
      }
  
      kycSchema.fields[type] = kycSchema.fields[type].shape(document).required();
      trigger([`${type}.address_document`, `${type}.address_document_type`]);
    }
  };
  


  const handleAddressFileChange = (e, type, field) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.split(',')[1];
      const base64Image = `data:${file.type};base64,${base64String}`;
      setValue(`${type}.${field}`, base64Image);
      trigger([`${type}.${field}`]);
      setAddressObject(prevState => ({ ...prevState, [`${type}_imageSrc`]: uploadDocDone, [`${type}_imageName`]: file.name }));
    };
    reader.readAsDataURL(file);
  };

  const handleAddressRemoveFileChange = (type, field) => {
    setValue(`${type}.${field}`, '');
    setAddressObject({ [`${type}_imageSrc`]: '', [`${type}_imageName`]: '' });
  }

  useEffect(() => {
    if (state?.webformToken) {
      const decryptValue = async () => {
        try {
          const decrypted = await decryptQueryParam(state.webformToken);
          setDecryptedValue(decrypted);
        } catch (error) {
          console.log("Invalid Token");
        }
      };
      decryptValue();
    }
    const sdk = new Tech5SDK('https://bekyc.tech5.tech', fetchData);
    setSdk(sdk)
    sdk.initializeMarkUp();
    return () => {
      setSdk(null)
    }
  }, [])

  const kyc = (type) => {
    console.log(`KYC process for ${type}`);
    const { wallet_balance, package_service, package_type } = packageService;
    const kycLivenessService = package_service?.kyc_liveness;

    let message = '';
    let isOomero =(state?.webformToken) ? state?.isOomero : decodedToken?.isOomero;
    if(!isOomero){
      if (!package_service || kycLivenessService?.is_checked === false) {
        message = 'No service provider active';
      } else if (kycLivenessService && parseFloat(wallet_balance) < parseFloat(kycLivenessService.price)) {
        message = "There is a low balance in your wallet. Please top up your balance.";
      }
    }

    if (isOomero || package_type == 'postpaid' || (package_service?.kyc_liveness?.is_checked) && parseFloat(wallet_balance) >= parseFloat(package_service?.kyc_liveness?.price) && (kycLivenessService?.service_provider && kycLivenessService?.service_provider !== '')) {
      trigger([`${type}.type`]);

      if (type === 'firstIdentity' && firstIdentityRetryAttempt <= 0) {
        toast.error('Maximum Attempt Exhausted Please again after sometime');
        setValue(`${type}.type`, '');
        return;
      }

      if (type === 'secondIdentity' && secondIdentityRetryAttempt <= 0) {
        toast.error('Maximum Attempt Exhausted Please again after sometime');
        setValue(`${type}.type`, '');
        return;
      }

      handleClose2();
      sdk.kyc({ aml: true }).then(async (response) => {
        // setResponse(response);
        const { parsedResponse, rawResponse } = response;
        if (parsedResponse?.status === 200 && parsedResponse?.consolidated) {
        // if (true) {
          let requestData = { serviceProvider: kycLivenessService?.service_provider };
          let error = null;

          // Validate the tech5 name against the name entered in personal details;
          if (Object.keys(personalDetails).length <= 0) {
            error = { message: "No Personal Detail Found" };
            return;
          }

          const { document, liveness, compare, date_created, date_done } = rawResponse?.result?.data;

          if (!isOomero){
            await dispatch(deductKYCLivelynessCharge({ requestData, webformToken: state?.webformToken })).unwrap().catch((err) => {
              error = err;
              return null;
            })
          }

          if(error){
            toast.error(error?.message);
            setKycCheckDone(false);
            setValue(`${type}.type`, '');
            setValue(`${type}.provider_details`, '');
            return;
          }

          const fullName = `${personalDetails?.first_name} ${personalDetails?.middle_name || ''} ${personalDetails?.last_name}`.toLowerCase();
          const documentName = document.unified_fields.full_name.toLowerCase();

          // Compare the lowercased strings
          // if (fullName !== documentName) {
          //   toast.error('Document Mismatch');
          //   setKycCheckDone(false);
          //   setValue(`${type}.type`, '');
          //   setValue(`${type}.provider_details`, '');
          //   return;
          // }

            setValue(`${type}.type`, 'live');
            trigger([`${type}.type`]);
            

            if(document?.flag){
              setKycCheckDone(true);
              setValue(`${type}.provider_details`, {
                    document_name: document?.['DocumentName'] || '',
                    document_number: document?.['Document Number'] || '',
                    card_number: document?.['unified_fields']?.card_number || '',
                    date_of_issue: document?.['Date of Issue'] || '',
                    first_issue_date: document?.['First Issue Date'] || '',
                    date_of_expiry: document?.['Date of Expiry'] || '',
                    address_street: document?.['Address Street'] || '',
                    address_city: document?.['Address City'] || '',
                    address_postal_code: document?.['Address Postal Code'] || '',
                    country: document?.['unified_fields']?.country || '',
                    is_expired: document?.['is_expired'] || '',
                    date_of_birth: document?.['Date of Birth'] || '',
                    place_of_birth: document?.['Place of Birth'] || '',
                    surname: document?.['Surname'] || '',
                    given_names: document?.['Given Names'] || '',
                    surname_and_given_name: document?.['Surname And Given Names'] || '',
                    nationality: document?.['Nationality'] || '',
                    sex: document?.['Sex'] || '',
                    address: document?.['Address'] || '',
                    authority: document?.['Authority'] || '',
                    passport_number: document?.['unified_fields']?.passport_number || '',
                    nationality_code: document?.MRZ?.['Nationality Code'] || '',
                    mrz_strings: document?.MRZ?.['MRZ Strings'] || '',
                    mrz_type: document?.MRZ?.['MRZ Type'] || '',
                    document_issuance_code: document?.MRZ?.['Document Class Code'] || '',
                    card_issuance_number: document?.['Card issuance number'] || '',
                    issuing_state_name: document?.['Issuing State Name'] || '',
                    issuing_state_code: document?.['Issuing State Code'] || '',
                    liveness_uscore: liveness?.['data']?.['scorePercent'] || '',
                    score_percent: compare?.['uscore'] || '',
                    document_uscore: document?.['uscore'] || '',
                    document_portrait: document?.['portrait'] || '',
                    document_portrait_url: document?.['portrait_url'] || '',
                    father_name: document?.['Fathers Name'] || '',
                    age_of_issue: document?.['Age at Issue'] || '',
                    booklet_number: document?.['Booklet Number'] || '',
                    mrz_number: document?.MRZ?.['MRZ number'] || '',
                    reference_number: document?.['Reference Number'] || '',
                    date_created: date_created || '',
                    date_done: date_done || ''
              });

              setValue(`${type}.identity_document_type`, (response?.action === 'kyc_card_aml') ? 'card' : 'passport');
              setValue(`${type}.identity_document_selfie`, response?.images?.selfie);

              if (response.action === 'kyc_card_aml') {
                setValue(`${type}.identity_document_frontid`, response?.images?.frontId);
                setValue(`${type}.identity_document_backid`, response?.images?.backId);
              } else {
                setValue(`${type}.identity_document_passport`, response?.images?.passport_image);
              }
              toast.success('KYC Done Successfully');
            } else {
              toast.error('Unable to extract data');
              setKycCheckDone(false);
              setValue(`${type}.type`, '');
              setValue(`${type}.provider_details`, '');
            }            
          
        } else {
          dispatch(adjustRetryCount(type));
          toast.error(parsedResponse?.consolidatedMessage);
          setValue(`${type}.type`, '');
          trigger([`${type}.type`]);
          setValue(`${type}.provider_details`, '');
        }
      }).catch((err) => {
        dispatch(adjustRetryCount(type));
        toast.error(err.message);
        setValue(`${type}.type`, '');
        setValue(`${type}.provider_details`, '');
        trigger([`${type}.type`]);
        setKycCheckDone(false);
      });
    } else {
      dispatch(adjustRetryCount(type));
      toast.error(message || 'Service is not active or Credit Exhausted');
      setValue(`${type}.type`, '');
      setValue(`${type}.provider_details`, '');
    }
  }

  const onSubmit = (formData) => {
    if (showAddressStep) {
      let requestData = [];
      if (proofOfAddress === 1) {
        requestData.push(formData.address1);
        if (formData.address2.address_document_type && formData.address2.address_document_type !== '') {
          requestData.push(formData.address2);
        }
      } else if (proofOfAddress === 2) {
        requestData.push(formData.address1);
        requestData.push(formData.address2);
      }
      if (proofOfIdentity === 1) {
        requestData.push(formData.firstIdentity);
        if (formData.secondIdentity.identity_document_type && formData.secondIdentity.identity_document_type !== '') {
          requestData.push(formData.secondIdentity);
        }
      } else if (proofOfIdentity === 2) {
        requestData.push(formData.firstIdentity);
        requestData.push(formData.secondIdentity);
      }

      if (requestData.length > 0) {
        dispatch(saveEntityClientKycDetail({ requestData, entityClientId, webformToken: state?.webformToken })).unwrap().then((res) => {
          toast.success(res?.message);
          if (res?.data?.oomeroId) {
            let path = (state?.webformToken) ? '/webform/individual_application_done' : `/profile/individual_application_done`;
            navigate(path, { state: { entityClientId: entityClientId, oomeroId: res?.data?.oomeroId, webformToken: state?.webformToken } });
          }
        }).catch((error) => {
          toast.error(error?.message);
        })
      } else {
        toast.error("Invalid Payload Detected! Please refresh and try again");
      }
    } else {
      setShowAddressStep(true);
    }
  };

  const goBack = () => {
    let path = (state?.webformToken) ? '/webform/individual_bank_details' : `/profile/individual_bank_details`;
    navigate(path, { state: { entityClientId: state?.entityClientId, countryId: state?.countryId, webformToken: state?.webformToken, entityId: state?.entityId, address: state?.address, isOomero : state?.isOomero } });
  };

  const backToIdentity = (proofOfAddress) => {
    setShowAddressStep(false)
    setValue(`address1.address_document`, null);
    setValue(`address2.address_document`, null);
    setValue(`address1.address_document_type`, null);
    setValue(`address2.address_document_type`, null);
    setAddressObject({});
  }


  return (
    <>
        {decryptedValue &&
          <div className='white-background py-2 position-fixed w-100' style={{ borderRadius: 0 }}>
            <div className="d-flex justify-content-center">
              <img src={Logo} style={{ objectFit: 'contain', width: 150 }} />
            </div>
          </div>
        }
        <div className='grey-background IndividualKYCVerification  webform-css'>
        <div className=' mt-5 mb-3 p-3 w-100 hide-background hide-in-desktop'>
                    <p className='text-blue fw-normal'>Hi <b>{decryptedValue?.email}</b> ,<br></br> below is the webform to onboard individual client</p>
                </div>
          <div className={window.innerWidth <= 500 ? 'mt-4' : 'mt-1'}></div>
      {isLoading && <Loader />}     
      <OnboardClientBar
        mainTitle={`Onboard Individual Client`}
        title1={`PERSONAL DETAILS`}
        title2={`ADDRESS`}
        title3={`BANK DETAILS`}
        title4={`KYC Verification`}
        backgroundColor1={'#037AD0'}
        backgroundColor2={'#037AD0'}
        backgroundColor3={'#037AD0'}
        backgroundColor4={'#037AD0'}
      />
      <div className="sdk-mark-up w-9/12">
        <div id="tech5-sdk-loader"></div>
        <div id="tech5-sdk-camera"></div>
        <div id="tech5-sdk-document-options"></div>
        <div id="tech5-sdk-upload-scan-options"></div>
        <div id="tech5-sdk-aml"></div>
        <div id="tech5-sdk-upload"></div>
        <div id="tech5-sdk-cryptograph-manual"></div>
      </div>
      {/* <div>
        <pre>{JSON.stringify(response, null, 2)}</pre>
      </div> */}
      <form onSubmit={handleSubmit(onSubmit)} className='webform-css'>
        <div className='row mt-4'>
          <div className='col-lg-4'>
            <div className='text-black mb-3 hide-in-desktop'>
              <h1 className='fw-normal'>KYC Verification</h1>
            </div>
            <div className={window.innerWidth <= 500 ? '':'white-background  p-3'}>
              <div className='text-blue mb-3 hide-in-mobile'>
                <h1 className='fw-normal'>KYC Verification</h1>
              </div>
              <hr className='hide-in-mobile' />
              <div className={window.innerWidth <= 500 ? 'd-flex justify-content-center align-items-center':''}>
              <div className={window.innerWidth <= 500 ? 'white-background  consistent-width me-2 p-2':'row'}>
                <div className={window.innerWidth <= 500 ? 'text-center':'col-2'}>
                  <img src={CircleBlueTick} style={{ width: 20, objectFit: 'contain' }} />
                </div>
                <div className='col-10'>
                  <div className={`d-flex align-items-start justify-content-between ${window.innerWidth <= 500 ? 'mt-2' : ''}`}>
                    <div className='text-black-bold text-center'>Photo Proof</div>
                    <img onClick={handleShow} src={BlackInfoIcon} className='blackInfoIcon-css' />
                  </div>
                  <h4 className='text-black fw-normal hide-in-mobile'>A Government issued document which has your photo on it is required such as a Driving Licence, Passport or National ID</h4>
                </div>
              </div>
              {/* <div className={`row ${showAddressStep ? '' : 'opacity-25'} mt-3`}> */}
              <div className={window.innerWidth <= 500 ? `white-background  consistent-width p-2 me-2 ${showAddressStep ? '' : 'opacity-25'}`: `row ${showAddressStep ? '' : 'opacity-25 mt-3'}`}>
                <div className={window.innerWidth <= 500 ? 'text-center':'col-2'}>
                  <img src={CircleBlueTick} style={{ width: 20, objectFit: 'contain' }}/>
                </div>
                <div className='col-10'>
                  <div className={`d-flex align-items-start justify-content-between ${window.innerWidth <= 500 ? 'mt-2' : ''}`}>
                    <div className='text-black-bold text-center'>Proof Of Address</div>
                    <img src={BlackInfoIcon} className='blackInfoIcon-css'  onClick={handleShowAddress}/>
                  </div>
                  <h4 className='text-black fw-normal hide-in-mobile'>A document which confirms your residentioal address in your country such as a Utility Bill or Bank Statement </h4>
                </div>
              </div>
              <div className={window.innerWidth <= 500 ? 'white-background consistent-width p-2 opacity-25':'row opacity-25 mt-3'}>
                <div className={window.innerWidth <= 500 ? 'text-center':'col-2'}>
                  <img src={CircleBlueTick} style={{ width: 20, objectFit: 'contain' }} />
                </div>
                <div className='col-10'>
                  <div className={`d-flex align-items-start justify-content-between ${window.innerWidth <= 500 ? 'mt-2' : ''}`}>
                    <div className='text-black-bold text-center'>Application Done</div>
                    <img src={BlackInfoIcon} className='blackInfoIcon-css' />
                  </div>
                  <h4 className='text-black fw-normal hide-in-mobile'>Your application has been successfully submitted</h4>
                </div>
              </div>
              </div>
            </div>
          </div>
          {!showAddressStep && <>
            <div className={window.innerWidth <= 500 ? 'col-lg-4 my-3' : 'col-lg-4'}>
              <div className='white-background p-3'>
                <div className='text-blue mb-3'>
                  <h1 className='fw-normal'>Upload a Proof of your Identity</h1>
                </div>
                <hr />
                <div className="form-group mt-3">
                  <input type="hidden" {...register('firstIdentity.entity_client_id')} value={state?.entityClientId} />
                  <input type="hidden" {...register('firstIdentity.proof_type')} value={'identity'} />
                  <label className="form-label" for="countryOfResidency">Select Country</label>
                  <Controller
                    name="firstIdentity.country_id"
                    control={control}
                    render={({ field }) => (
                      // <select
                      //   id="countryOfResidency"
                      //   className={`form-control form-select ${errors?.firstIdentity?.country_id ? 'is-invalid' : ''}`}
                      //   onChange={(e) => field.onChange(e)}
                      //   value={field.value}
                      // >
                      //   <option value=''>Select Country</option>
                      //   {countryData && countryData.map((country) => (
                      //     <option key={'residency_country_' + country.country_id} value={country.country_id}>
                      //       {country.name}
                      //     </option>
                      //   ))}
                      // </select>
                      <Select
                        placeholder="Select Country"
                        id="countryOfResidency"
                        options={countryData.map((country) => ({
                          label: country.name,
                          value: country.country_id
                        }))}
                        value={ countryData.country_id }
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value);
                        }}
                        className={`${errors?.firstIdentity?.country_id ? 'is-invalid' : ''}`}
                        styles={customStyles}
                      />
                    )}
                  />
                  {errors?.firstIdentity?.country_id && (
                    <div className="invalid-feedback">{errors?.firstIdentity?.country_id?.message}</div>
                  )}
                </div>
                {/* <hr />
                <div className="form-group mt-3 mb-3">
                  <label className="form-label" for="firstIdentity_ocument_type">Select Document</label>
                  <Controller
                    name="firstIdentity.identity_document_type"
                    control={control}
                    render={({ field }) => (
                      <select
                        id="firstIdentity_document_type"
                        className={`form-control form-select ${errors?.firstIdentity?.identity_document_type ? 'is-invalid' : ''}`}
                        onChange={(e) => { field.onChange(e); handleDocumentChange('firstIdentity', e,) }}
                        value={field.value}
                      >
                        <option value=''>Select Document Type</option>
                        <option value={'card'}>Card</option>
                        <option value={'passport'}>Passport</option>
                      </select>
                    )}
                  />
                  {errors?.firstIdentity?.identity_document_type && (
                    <div className="invalid-feedback">{errors?.firstIdentity?.identity_document_type?.message}</div>
                  )}
                </div> */}
                {/* <div>
                  {((selectedDocument === 'passport') || (selectedDocument === 'card')) && (
                    <label className="form-label" for="exampleFormControlSelect1">Upload Document </label>
                  )}
                  <div className='row px-1 mb-3'>
                    {(selectedDocument === 'passport' || selectedDocument === 'card') &&
                      firstIdentityUploadBoxes.map((field, index) => (
                        <div className='col-lg-4 row-pad-custom' key={index}>
                          <input type="file" className={`${errors?.firstIdentity?.[`identity_document_${field.type}`] ? 'is-invalid' : ''}`} {...register(`firstIdentity.identity_document_${field.type}`)} id={`fileInput_${field.type}`} style={{ display: 'none' }} onChange={(e) => handleFileChange(e, 'firstIdentity', `identity_document_${field.type}`, index)} />
                          <label className='upload-box mt-2' style={{ padding: '14px 10px' }} htmlFor={`fileInput_${field.type}`}>
                            <div className='d-flex justify-content-center align-items-center'>
                              <div className='text-center'>
                                {<img src={field.imageSrc ? field.imageSrc : UploadLineIcon} width={18} style={{ marginBottom: 10 }} />}
                                <div className='upload-img-text-small'>{field.imageName}</div>
                              </div>
                            </div>
                          </label>
                          {errors?.firstIdentity?.[`identity_document_${field.type}`] && (
                            <div className="invalid-feedback">{errors?.firstIdentity?.[`identity_document_${field.type}`]?.message}</div>
                          )}
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className='row justify-content-center align-items-center'>
                  <div className='col-lg-5'>
                    <div style={{ borderBottom: 'dashed 1px #D9D9D9' }}></div>
                  </div>
                  <div className='col-lg-2'>
                    <div className='py-2' style={{ textAlign: 'center', borderRadius: '100%', border: 'dashed 1px #D9D9D9' }}>OR</div>
                  </div>
                  <div className='col-lg-5'>
                    <div style={{ borderBottom: 'dashed 1px #D9D9D9' }}></div>
                  </div>
                </div> */}
                {/* <button type="button" style={{ width: '100%' }} className='btn-success-light fw-normal mt-3' onClick={() => kyc('firstIdentity')}>
                  <div className='d-flex align-items-center justify-content-center'>
                    <span className='me-2'>Start Video Session</span>
                    <img src={VideoIcon} width={26} />
                  </div>
                </button> */}
                <button type="button" style={{ width: '100%' }} className='btn-success-light fw-normal mt-3' onClick={() => handleShow2('firstIdentity')}>
                  <div className='d-flex align-items-center justify-content-center'>
                    <span className='me-2'>Start Video Session</span>
                    <img src={VideoIcon} width={26} />
                  </div>
                </button>
                <input type="hidden" {...register('firstIdentity.type')} className={`${errors?.firstIdentity?.type ? 'is-invalid' : ''}`} />
                <input type="hidden" {...register('firstIdentity.provider_details')} className={`${errors?.firstIdentity?.provider_details ? 'is-invalid' : ''}`} />
                {errors?.firstIdentity?.type && (
                  <div className="invalid-feedback">{errors?.firstIdentity?.type?.message}</div>
                )}
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='white-background p-3'>
                <div className='text-blue mb-3'>
                  <h1 className='fw-normal'>Upload a Proof of your Identity</h1>
                </div>
                <hr />
                <div className="form-group mt-3">
                  <input type="hidden" {...register('secondIdentity.entity_client_id')} value={state?.entityClientId} />
                  <input type="hidden" {...register('secondIdentity.proof_type')} value={'identity'} />
                  <label className="form-label" for="countryOfResidency">Select Country</label>
                  <Controller
                    name="secondIdentity.country_id"
                    control={control}
                    render={({ field }) => (
                      // <select
                      //   id="countryOfResidency"
                      //   className={`form-control form-select ${errors?.secondIdentity?.country_id ? 'is-invalid' : ''}`}
                      //   onChange={(e) => field.onChange(e)}
                      //   value={field.value}
                      // >
                      //   <option value=''>Select Country</option>
                      //   {countryData && countryData.map((country) => (
                      //     <option key={'residency_country_' + country.country_id} value={country.country_id}>
                      //       {country.name}
                      //     </option>
                      //   ))}
                      // </select>
                      <Select
                        placeholder="Select Country"
                        id="countryOfResidency"
                        options={countryData.map((country) => ({
                          label: country.name,
                          value: country.country_id
                        }))}
                        value={ countryData.country_id }
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value);
                        }}
                        className={`${errors?.secondIdentity?.country_id ? 'is-invalid' : ''}`}
                        styles={customStyles}
                      />
                    )}
                  />
                  {errors?.secondIdentity?.country_id && (
                    <div className="invalid-feedback">{errors?.secondIdentity?.country_id?.message}</div>
                  )}
                </div>
                {/* <hr />
                <div className="form-group mt-3 mb-3">
                  <label className="form-label" for="secondIdentity_ocument_type">Select Document</label>
                  <Controller
                    name="secondIdentity.identity_document_type"
                    control={control}
                    render={({ field }) => (
                      <select
                        id="secondIdentity_document_type"
                        className={`form-control form-select ${errors?.secondIdentity?.identity_document_type ? 'is-invalid' : ''}`}
                        onChange={(e) => { field.onChange(e); handleDocumentChange('secondIdentity', e) }}
                        value={field.value}
                      >
                        <option value=''>Select Document Type</option>
                        {secondIdentityDropDown && secondIdentityDropDown.map((item)=>(
                          <option value={item?.value}>{item?.name}</option>
                        ))}
                      </select>
                    )}
                  />
                  {errors?.secondIdentity?.identity_document_type && (
                    <div className="invalid-feedback">{errors?.secondIdentity?.identity_document_type?.message}</div>
                  )}
                </div>
                <div>
                  {((selectedDocument2 === 'passport') || (selectedDocument2 === 'card')) && (
                    <label className="form-label" for="exampleFormControlSelect1">Upload Document </label>
                  )}
                  <div className='row px-1 mb-3' >
                    {(selectedDocument2 === 'passport' || selectedDocument2 === 'card') &&
                      secondIdentityUploadBoxes.map((field, index) => (
                        <div className='col-lg-4 row-pad-custom' key={index}>
                          <input type="file" className={`${errors?.secondIdentity?.[`identity_document_${field.type}`] ? 'is-invalid' : ''}`} {...register(`secondIdentity.identity_document_${field.type}`)} id={`secondIdentity_fileInput_${field.type}`} style={{ display: 'none' }} onChange={(e) => handleFileChange(e, 'secondIdentity', `identity_document_${field.type}`, index)} />
                          <label className='upload-box mt-2' style={{ padding: '14px 10px' }} htmlFor={`secondIdentity_fileInput_${field.type}`}>
                            <div className='d-flex justify-content-center align-items-center'>
                              <div className='text-center'>
                                {<img src={field.imageSrc ? field.imageSrc : UploadLineIcon} width={18} style={{ marginBottom: 10 }} />}
                                <div className='upload-img-text-small'>{field.imageName}</div>
                              </div>
                            </div>
                          </label>
                          {errors?.secondIdentity?.[`identity_document_${field.type}`] && (
                            <div className="invalid-feedback">{errors?.secondIdentity?.[`identity_document_${field.type}`]?.message}</div>
                          )}
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className='row justify-content-center align-items-center'>
                  <div className='col-lg-5'>
                    <div style={{ borderBottom: 'dashed 1px #D9D9D9' }}></div>
                  </div>
                  <div className='col-lg-2'>
                    <div className='py-2' style={{ textAlign: 'center', borderRadius: '100%', border: 'dashed 1px #D9D9D9' }}>OR</div>
                  </div>
                  <div className='col-lg-5'>
                    <div style={{ borderBottom: 'dashed 1px #D9D9D9' }}></div>
                  </div>
                </div> */}
                {/* <button type="button" style={{ width: '100%' }} className='btn-success-light fw-normal mt-3' onClick={() => kyc('secondIdentity')}>
                  <div className='d-flex align-items-center justify-content-center'>
                    <span className='me-2'>Start Video Session</span>
                    <img src={VideoIcon} width={26} />
                  </div>
                </button> */}
                <button type="button" style={{ width: '100%' }} className='btn-success-light fw-normal mt-3' onClick={() => handleShow2('secondIdentity')}>
                  <div className='d-flex align-items-center justify-content-center'>
                    <span className='me-2'>Start Video Session</span>
                    <img src={VideoIcon} width={26} />
                  </div>
                </button>
                <input type="hidden" {...register('secondIdentity.type')} className={`${errors?.secondIdentity?.type ? 'is-invalid' : ''}`} />
                <input type="hidden" {...register('secondIdentity.provider_details')} className={`${errors?.firstIdentity?.provider_details ? 'is-invalid' : ''}`} />
                {errors?.secondIdentity?.type && (
                  <div className="invalid-feedback">{errors?.secondIdentity?.type?.message}</div>
                )}
              </div>
            </div>
          </>}
          {showAddressStep &&
            <>
              <div className='col-lg-4'>
                <input type="hidden" {...register('address1.entity_client_id')} value={state.entityClientId} />
                <input type="hidden" {...register('address1.proof_type')} value={'address'} />
                <div className={window.innerWidth <= 500 ? 'white-background p-3 my-3' : 'white-background p-3'}>
                  <div className='text-blue mb-3'>
                    <h1 className='fw-normal'>Upload a Proof of your Address 1</h1>
                  </div>
                  <hr className='hide-in-mobile' />
                  <div className="form-group mb-3">
                    <label className="form-label" for="address1address_document_type">Select Document</label>
                    <Controller
                      name="address1.address_document_type"
                      control={control}
                      render={({ field }) => (
                        <Select
                          id="address1address_document_type"
                          classNamePrefix="react-select"
                          options={options}
                          styles={customStyles}
                          onChange={(selectedOption) => {
                            const value = selectedOption ? selectedOption.value : '';
                            field.onChange(value);
                            handleAddressDocumentChange('address1', value);  // Passing value directly
                          }}
                          value={options.find(option => option.value === field?.value)}
                          isInvalid={!!errors?.address1?.address_document_type}
                        />
                      )}
                    />
                    {errors?.address1?.address_document_type && (
                      <div className="invalid-feedback">{errors?.address1?.address_document_type?.message}</div>
                    )}
                  </div>
                  <div>
                    <input type="file" className={`${errors?.address1?.address_document ? 'is-invalid' : ''}`} {...register(`address1.address_document`)} id={`fileInput_address1`} style={{ display: 'none' }} onChange={(e) => handleAddressFileChange(e, 'address1', `address_document`)} />
                    <label className={`upload-box d-flex justify-content-center`} style={{ padding: 16 }} htmlFor={`fileInput_address1`}>
                      <div className='d-flex justify-content-center align-items-center'>
                        <div className='text-center'>
                          {<img src={(addressObject && addressObject?.[`address1_imageSrc`]) ? addressObject?.[`address1_imageSrc`] : UploadLineIcon} width={18} style={{ marginBottom: 10 }} />}
                          {<div className='upload-img-text-small'>{(addressObject && addressObject?.[`address1_imageName`]) ? addressObject?.[`address1_imageName`] : 'Upload Image'}</div>}
                          {/* {<img style={{ position: 'absolute', top: 5, right: 5 }} src={cancelBtn} width={18} onClick={()=>handleAddressRemoveFileChange('address1','address_document')}/>} */}
                        </div>
                      </div>
                    </label>
                    {errors?.address1?.address_document && (
                      <div className="invalid-feedback">{errors?.address1?.address_document?.message}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <input type="hidden" {...register('address2.entity_client_id')} value={state.entityClientId} />
                <input type="hidden" {...register('address2.proof_type')} value={'address'} />
                <div className='white-background p-3'>
                  <div className='text-blue mb-3'>
                    <h1 className='fw-normal'>Upload a Proof of your Address 2</h1>
                  </div>
                  <hr className='hide-in-mobile' />
                  <div className="form-group mb-3">
                    <label className="form-label" for="secondaddress_document_type">Select Document</label>
                    <Controller
                      name="address2.address_document_type"
                      control={control}
                      render={({ field }) => (
                        <Select
                          id="secondaddress_document_type"
                          classNamePrefix="react-select"
                          options={addressDropDown}  // Use addressDropDown as options
                          styles={customStyles}
                          onChange={(selectedOption) => {
                            const value = selectedOption ? selectedOption.value : '';
                            field.onChange(value);
                            handleAddressDocumentChange('address2', value);
                          }}
                          value={addressDropDown.find(option => option.value === field.value)}
                          isInvalid={!!errors?.address2?.address_document_type}
                        />
                      )}
                    />
                    {errors?.address2?.address_document_type && (
                      <div className="invalid-feedback">{errors?.address2?.address_document_type?.message}</div>
                    )}
                  </div>
                  <div>
                    <input
                      type="file"
                      className={`${errors?.address2?.address_document ? 'is-invalid' : ''}`}
                      {...register(`address2.address_document`)}
                      id={`fileInput_address2`}
                      style={{ display: 'none' }}
                      onChange={(e) => handleAddressFileChange(e, 'address2', `address_document`)}
                    />
                    <label htmlFor={`fileInput_address2`} className={`upload-box d-flex justify-content-center`} style={{ padding: 16 }}>
                      <div className='d-flex justify-content-center align-items-center'>
                        <div className='text-center'>
                          {<img src={(addressObject && addressObject?.[`address2_imageSrc`]) ? addressObject?.[`address2_imageSrc`] : UploadLineIcon} width={18} style={{ marginBottom: 10 }} />}
                          {<div className='upload-img-text-small'>{(addressObject && addressObject?.[`address2_imageName`]) ? addressObject?.[`address2_imageName`] : 'Upload Image'}</div>}
                          {/* {<img style={{ position: 'absolute', top: 5, right: 5 }} src={cancelBtn} width={18} onClick={()=>handleAddressRemoveFileChange('address2','address_document')} />} */}
                        </div>
                      </div>
                    </label>
                    {errors?.address2?.address_document && (
                      <div className="invalid-feedback">{errors?.address2?.address_document?.message}</div>
                    )}
                  </div>
                </div>
              </div>
            </>
          }
        </div>

        <div className='bottom-button'>
          {!showAddressStep &&
            <button onClick={goBack} className={window.innerWidth<=500?'w-100 btn-secondary':'btn-secondary' } style={{ marginRight: 16 }}>Back</button>
          }
          {showAddressStep &&
            <div className='' style={{ marginRight: 10 }}>
              <button onClick={() => backToIdentity(proofOfAddress)} className={window.innerWidth<=500?'w-100 btn-secondary':'btn-secondary' }>Back</button>
            </div>
          }
            <button type='submit' className={window.innerWidth<=500?'w-100 btn-primary':'btn-primary' }>Continue</button>
        </div>
      </form>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header >
          <h5 className="modal-title text-center text-blue"><b>Accept Photo Proof ID Documents </b></h5>
          <div onClick={handleClose} className='position-absolute top-0 end-0'>
            <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center my-3">
            <img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />
            <p className="text-black mb-0" style={{ fontWeight: '600', fontSize: 16 }}>Passport</p>
          </div>
          <div className="d-flex align-items-center my-3">
            <img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />
            <p className="text-black mb-0" style={{ fontWeight: '600', fontSize: 16 }}>Driving Licence (no provisional licences)</p>
          </div>
          <div className="d-flex align-items-center my-3">
            <img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />
            <p className="text-black mb-0" style={{ fontWeight: '600', fontSize: 16 }}>Military ID</p>
          </div>
          <div className="d-flex align-items-center my-3">
            <img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />
            <p className="text-black mb-0" style={{ fontWeight: '600', fontSize: 16 }}>National ID Card (Govt issued)</p>
          </div>
          <div className="d-flex align-items-center my-3">
            <img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />
            <p className="text-black mb-0" style={{ fontWeight: '600', fontSize: 16 }}>Travel document (Govt issued)</p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show2} onHide={handleClose2} className='cameraModal' centered>
        <Modal.Header >
          <h5 className="modal-title text-center text-blue"><b>GET READY FOR BIOMETRICS</b></h5>
          <div onClick={handleClose2} className='position-absolute top-0 end-0'>
            <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex justify-content-center align-items-center' style={{ marginTop: '-20px', marginBottom: 8 }}>
            <img src={uploadUserPhoto} width={100} style={{ objectFit: 'contain' }} />
          </div>
          <div className='d-flex  mb-2'>
            <div className='d-flex justify-content-start'>
              <img src={greenIcon} width={24} style={{ objectFit: 'contain', marginRight: 8 }} />
              <div>
                <div className=''>Pls keep your documents ready at hand</div>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='d-flex justify-content-start align-items-start'>
              <img src={greenIcon} width={24} style={{ objectFit: 'contain', marginRight: 8 }} />
              <div>
                <div>Align your face to the centre of the frame (as directed).</div>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='d-flex justify-content-start align-items-start'>
              <img src={greenIcon} width={24} style={{ objectFit: 'contain', marginRight: 8 }} />
              <div>
                <div>In the event that the auto-capture is not working please scroll down for the manual capture button</div>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='d-flex justify-content-start align-items-start'>
              <img src={greenIcon} width={24} style={{ objectFit: 'contain', marginRight: 8 }} />
              <div>
                <div>Pls make sure you are in good lighting for the best results</div>
              </div>
            </div>
          </div>
          <button type="button" style={{ width: '100%' }} className='btn-success-light fw-normal mt-3' onClick={() => kyc(identityType)}>
            <div className='d-flex align-items-center justify-content-center'>
              <span className='me-2'>Start Video Session</span>
              <img src={VideoIcon} width={26} />
            </div>
          </button>
        </Modal.Body>
      </Modal>
      <Modal show={show3} onHide={handleCloseAddress} centered size='md'>
        <Modal.Header >
          <h5 className="modal-title text-center text-blue mt-4"><b>Accepted Proof of Address</b></h5>
          <div onClick={handleCloseAddress} className='position-absolute top-0 end-0'>
            <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600',fontSize: 10 }}>Bank, Building Society or Credit Union Statement</li>
  <div style={{fontSize:10}} className='px-2'>(less than 3 months old)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>Council or Housing Association Tenancy Agreement</li>
  <div style={{fontSize:10}} className='px-2'>(Current Year)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>Council Tax Bill</li>
  <div style={{fontSize:10}} className='px-2'>(Current Year)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>Credit Card Statement</li>
  <div style={{fontSize:10}} className='px-2'>(less than 3 months old)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>Driving Licence</li>
  <div style={{fontSize:10}} className='px-2'>(Must not be expired)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>Government Benefits Entitlement letter</li>
  <div style={{fontSize:10}} className='px-2'>(Current Year)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>Government Benefit Book</li>
  <div style={{fontSize:10}} className='px-2'>(Current Year)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}> Motor Insurance Certificate / Renewal Notice / Current Policy</li>
  <div style={{fontSize:10}} className='px-2'>(Current Year)</div>
</div>
<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}> Household Insurance Certificate / Renewal Notice / Current Policy</li>
  <div style={{fontSize:10}} className='px-2'>(Current Year)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>HMRC Tax Notification (for non residents from their tax authority)</li>
  <div style={{fontSize:10}} className='px-2'>(Current Year)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>Letter from Landline Services providers</li>
  <div style={{fontSize:10}} className='px-2'>(Current Year)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>Letter from Local Authority confirming entitlement to Housing Benefits</li>
  <div style={{fontSize:10}} className='px-2'>(Current Year)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>Letter from Local Council re Voters Roll</li>
  <div style={{fontSize:10}} className='px-2'>(Current Year)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>Life Assurance cover documentation</li>
  <div style={{fontSize:10}} className='px-2'>(Current Year)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>Mortgage Statement</li>
  <div style={{fontSize:10}} className='px-2'>(less than 3 months old)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>National ID Card</li>
  <div style={{fontSize:10}} className='px-2'>(Must not be expired)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>National Insurance Card</li>
  <div style={{fontSize:10}} className='px-2'>(Must not be expired)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10}}>Police Issued Shot Gun Licence</li>
  <div style={{fontSize:10}} className='px-2'>(Current Year)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>TV Licence Renewal</li>
  <div style={{fontSize:10}} className='px-2'>(Current Year)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>Utility Bill (Gas, Electricity)</li>
  <div style={{fontSize:10}} className='px-2'>(less than 3 months old)</div>
</div>
<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>Satellite or Cable TV or Land Line Telephone Supplier</li>
  <div style={{fontSize:10}} className='px-2'>(less than 3 months old)</div>
</div>

<div className="d-flex align-items-center">
  {/*<img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />*/}
  <li className="text-black mb-0" style={{ fontWeight: '600', fontSize: 10 }}>Voters Polling Card (Local or National Elections)</li>
  <div style={{fontSize:10}} className='px-2'>(Current Year)</div>
</div>
        </Modal.Body>
      </Modal>
    </div>
    </>
  )
}

export default IndividualKYCVerification;

