import React from 'react'
import OnboardClientBar from '../../../components/OnboardClientBar/OnboardClientBar'
import { useNavigate } from 'react-router-dom';
import ApproveTick from '../../../assets/images/Approve_tick.png'

function CorporateMembersList() {
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/profile/corporate_application`;
        navigate(path);
    }
    const index = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10
    ]

    return (
        <div className='grey-background'>
            <OnboardClientBar
                mainTitle={`Onboard Business Client `}
                title1={`COMPANY DETAILS`}
                title2={`COMPANY INFORMATION`}
                title3={`DIRECTOR LIST`}
                title4={`SHAREHOLDER LIST`}
                backgroundColor1={'#037AD0'}
                backgroundColor2={'#037AD0'}
                backgroundColor3={'#037AD0'}
                backgroundColor4={'#037AD0'}
            />

            <div className='d-flex align-items-center justify-content-between mt-4'>
                <h1 className='text-blue fw-normal'>Verification</h1>
            </div>

            <div className='row my-3'>
                <div className='col-lg-4'>
                    <div className='bg-primary p-2'>
                        <h6 className='text-white fw-bold'>Director Details</h6>
                    </div>
                    <div className='white-background px-3 py-1 mt-3'>
                        {index.map(({ code, name, country_code }) => (
                            <div className='d-flex align-items-center justify-content-between my-4'>
                                <h6 className='text-black fw-normal'>Director 1</h6>
                                <h6 className='text-black fw-normal'>Horem ipsum </h6>
                                <img src={ApproveTick} style={{ width: 25, objectFit: 'contain' }} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className='bg-warning p-2'>
                        <h6 className='text-white fw-bold'>Shareholder Details</h6>
                    </div>
                    <div className='white-background px-3 py-1 mt-3'>
                        {index.map(({ code, name, country_code }) => (
                            <div className='d-flex align-items-center justify-content-between my-4'>
                                <h6 className='text-black fw-normal'>Shareholder 1</h6>
                                <h6 className='text-black fw-normal'>Horem ipsum </h6>
                                <img src={ApproveTick} style={{ width: 25, objectFit: 'contain' }} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className='bg-success p-2'>
                        <h6 className='text-white fw-bold'>Company Details</h6>
                    </div>
                    <div className='white-background px-3 py-1 mt-3'>
                        {index.map(({ code, name, country_code }) => (
                            <div className='d-flex align-items-center justify-content-between my-4'>
                                <h6 className='text-black fw-normal'>Company  Name</h6>
                                <h6 className='text-black fw-normal'>Horem ipsum </h6>
                                <img src={ApproveTick} style={{ width: 25, objectFit: 'contain' }} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end my-4'>
                <div className='d-flex align-items-center'>
                    <button onClick={routeChange} className='btn-primary'>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default CorporateMembersList
