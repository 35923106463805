import React, { useState } from 'react'
import '../Profile.scss';
import OnboardClientBar from '../../../components/OnboardClientBar/OnboardClientBar';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '../../../assets/images/Cancel_icon.png'
import document_1 from '../../../assets/images/document_1.png'
import document_2 from '../../../assets/images/document_2.png'
import CorporateAddress from '../../../assets/images/corpporate_address.png'
import CompanyDetails from '../../../assets/images/Company_Details.png'

function CorporateInformation() {
    const [show, setShow] = useState(false);
    const [showModalTwo, setShowModalTwo] = useState(false);
    const navigate = useNavigate();
    const routeCompanyDetails = () => {
        let path = `/profile/corporate_company_bank`;
        navigate(path);
    }
    const goBack = () => {
        navigate(-1); // This navigates back to the previous page
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseModalTwo = () => setShowModalTwo(false);
    const handleShowModalTwo = () => setShowModalTwo(true);
    return (
        <div className='grey-background'>
            <OnboardClientBar
                mainTitle={`Onboard Business Client `}
                title1={`COMPANY DETAILS`}
                title2={`COMPANY INFORMATION`}
                title3={`DIRECTOR LIST`}
                title4={`SHAREHOLDER LIST`}
                backgroundColor1={'#037AD0'}
                backgroundColor2={'#037AD0'} />

            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background mt-4 p-3'>
                        <h1 className='text-blue px-2 fw-normal'>Date of Incorporation</h1>
                        <hr />
                        <form>
                            <div className='col-lg-12 col-md-12'>
                                <div className="form-group ">
                                    <input type="date" className="form-control" id="exampleInputEmail1" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background  mt-4 p-3'>
                        <h1 className='text-blue px-2 fw-normal'>Tax Number</h1>
                        <hr />
                        <form>
                            <div className='col-lg-12 col-md-12'>
                                <div className="form-group ">
                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder='Tax Number' />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-4'>
                        <div className='d-flex align-items-center'>
                            <img src={CorporateAddress} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                            <h1 className='text-ambed fw-normal'>Address</h1>
                        </div>
                        <hr />
                        <div className='form'>
                            <form>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleFormControlSelect1">Country</label>
                                            <select id="statusDropdown" className="form-control form-select">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Zip</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Zip" />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">State</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">City</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Address</label>
                                            <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Address" rows="5" />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-4 position-relative">
                                            <label className="form-label" for="exampleInputEmail1">Trading Address</label>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className="form-check mt-1">
                                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" style={{ width: 18, height: 18, marginTop: 2 }} />
                                                        <label className="form-check-label" for="defaultCheck1" >
                                                            Same as Above
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className="form-check mt-1">
                                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" style={{ width: 18, height: 18, marginTop: 2 }} />
                                                        <label className="form-check-label" for="defaultCheck1" >
                                                            Different Trading Address
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <textarea type="text" className="form-control mt-2" id="exampleInputEmail1" value={`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`} placeholder="" rows="3" />
                                            <p onClick={handleShow} className='text-blue mb-0 position-absolute bottom-0' style={{ fontSize: 14, cursor: 'pointer', textDecorationLine: 'underline', top: '83%', right: 10 }}>10 more</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-between my-3'>
                                    <p onClick={handleShowModalTwo} className='btn-primary fw-normal mb-0' style={{height:'auto'}}>Add Trading Address</p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-4'>
                        <div className='d-flex align-items-center'>
                            <img src={CompanyDetails} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                            <h1 className='text-success fw-normal'>Company Details</h1>
                        </div>
                        <hr />
                        <div className='form'>
                            <form>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleFormControlSelect1">Company Type</label>
                                            <select id="statusDropdown" className="form-control form-select">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleFormControlSelect1">Category</label>
                                            <select id="statusDropdown" className="form-control form-select">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleFormControlSelect1">Sub-Category</label>
                                            <select id="statusDropdown" className="form-control form-select">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='row align-items-end'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Mobile Number</label>
                                            <div className='row'>
                                                <div className='col-lg-3 col-md-3'>
                                                    <select id="statusDropdown"
                                                        className="form-control form-select">
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                    </select>
                                                </div>
                                                <div className='col-lg-9 col-md-9'>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter number" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row align-items-end'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Landline Number</label>
                                            <div className='row'>
                                                <div className='col-lg-3 col-md-3'>
                                                    <select id="statusDropdown"
                                                        className="form-control form-select">
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                    </select>
                                                </div>
                                                <div className='col-lg-9 col-md-9'>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter number" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Company Email</label>
                                            <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter Company Email" />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Website</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="https://www." />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">VAT No.</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">LEI No.</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-end my-4'>
                    <div className='d-flex align-items-center'>
                        <button onClick={routeCompanyDetails} className='btn-primary'>Save & Next</button>
                    </div>
                </div>
            </div>
            <Modal scrollable={true} show={show} onHide={handleClose} centered >
                <Modal.Body>
                    <h5 className="modal-title text-center text-blue mt-4"><b>Different Trading Address</b></h5>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 6, marginRight: 8, cursor: 'pointer' }} />
                    </div>
                    <div className='d-flex flex-column p-2'>
                        <h6 className="text-black mt-4"><b>Trading Address 1: </b></h6>
                        <h6 className="text-black fw-normal">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and</h6>
                        <hr />
                    </div>
                    <div className='d-flex flex-column p-2'>
                        <h6 className="text-black mt-4"><b>Trading Address 1: </b></h6>
                        <h6 className="text-black fw-normal">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and</h6>
                        <hr />
                    </div>
                    <div className='d-flex flex-column p-2'>
                        <h6 className="text-black mt-4"><b>Trading Address 1: </b></h6>
                        <h6 className="text-black fw-normal">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and</h6>
                        <hr />
                    </div>
                    <div className='d-flex flex-column p-2'>
                        <h6 className="text-black mt-4"><b>Trading Address 1: </b></h6>
                        <h6 className="text-black fw-normal">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and</h6>
                        <hr />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal scrollable={true} show={showModalTwo} onHide={handleCloseModalTwo} centered >
                <Modal.Body>
                    <h5 className="modal-title text-center text-blue mt-4"><b>Trading Address</b></h5>
                    <div onClick={handleCloseModalTwo} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 6, marginRight: 8, cursor: 'pointer' }} />
                    </div>
                    <div className='row mt-3'>
                        <div className='col-1'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            </div>
                        </div>
                        <div className='col-11'>
                            <h6 className="text-black mb-1"><b>Trading Address 1: </b></h6>
                            <h6 className="text-black fw-normal">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and</h6>
                        </div>
                    </div>
                    <hr />
                    <div className='row mt-3'>
                        <div className='col-1'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            </div>
                        </div>
                        <div className='col-11'>
                            <h6 className="text-black mb-1"><b>Trading Address 1: </b></h6>
                            <h6 className="text-black fw-normal">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and</h6>
                        </div>
                    </div>
                    <hr />
                    <div className='row mt-3'>
                        <div className='col-1'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            </div>
                        </div>
                        <div className='col-11'>
                            <h6 className="text-black mb-1"><b>Trading Address 1: </b></h6>
                            <h6 className="text-black fw-normal">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and</h6>
                        </div>
                    </div>
                    <hr />
                    <div className='row mt-3'>
                        <div className='col-1'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            </div>
                        </div>
                        <div className='col-11'>
                            <h6 className="text-black mb-1"><b>Trading Address 1: </b></h6>
                            <h6 className="text-black fw-normal">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and</h6>
                        </div>
                    </div>
                    <hr />
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default CorporateInformation

