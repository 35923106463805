import moment from 'moment'
import React from 'react'
import { Accordion } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
const index = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10
]

function KeyInformation({ globalSearchDetail, personalDetails,saveAmlComments }) {
    const commentSchema = yup.object().shape({
        comment: yup.string().required('Comment is required'),
      });
    
      const { control, handleSubmit, formState: { errors }, reset} = useForm({
        resolver: yupResolver(commentSchema),
      });
      const saveComment =(formData)=>{
          const requestData = { ...formData,global_search_result_id: globalSearchDetail.global_search_results_id , section: "key_data" };  
        saveAmlComments(requestData);
        reset({comment:""});
      }
    return (
        <div>
            <h1 className='text-black mt-4'>Key Information</h1>
            <div className='row mt-4'>
                <div className='col-lg-2'>
                    <h2 className='text-black'>Full Name:</h2>
                </div>
                <div className='col-lg-10'>
                    <h5 className='text-grey'>{globalSearchDetail?.name || ''}</h5>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-lg-2'>
                    <h2 className='text-black'>Entity Type:</h2>
                </div>
                <div className='col-lg-10'>
                    <h5 className='text-grey'>{globalSearchDetail?.search_results?.doc?.entity_type ? globalSearchDetail?.search_results?.doc?.entity_type.charAt(0).toUpperCase() + globalSearchDetail?.search_results?.doc?.entity_type.slice(1) : ''}</h5>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-lg-2'>
                    <h2 className='text-black'>AKA:</h2>
                </div>
                <div className='col-lg-10'>
                    <h5 className='text-grey'>
                        {globalSearchDetail?.search_results?.doc?.aka
                            ? globalSearchDetail.search_results.doc.aka.map(item => item.name).join(', ')
                            : ''}
                    </h5>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-lg-2'>
                    <h2 className='text-black'>Countries:</h2>
                </div>
                <div className='col-lg-10'>
                    <h5 className='text-grey'>{globalSearchDetail?.search_results?.doc?.fields.find(field => field.tag === "country_names")?.value || null}</h5>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-lg-2'>
                    <h2 className='text-black'>Date Of Birth:</h2>
                </div>
                <div className='col-lg-10'>
                    <h5 className='text-grey'>{personalDetails.date_of_birth ? `${personalDetails.date_of_birth} (Age: ${moment().diff(personalDetails.date_of_birth, 'years')})` : ''}</h5>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-lg-2'>
                    <h2 className='text-black'>Associates:</h2>
                </div>
                <div className='col-lg-10'>
                    <h5 className='text-grey'>
                        {(globalSearchDetail?.associates) ? globalSearchDetail?.associates.map(({ name, association }) => `${name} (${association})`).join('') : 'Not Avaliable'}
                    </h5>
                </div>
            </div>

            <hr />

            <h1 className='text-black mt-4'>Listing</h1>
            <Accordion >
                {globalSearchDetail && globalSearchDetail.listing && globalSearchDetail?.listing.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                            {/* <div className='danger-light-background'>
                                <h5 className='text-danger'>Sanction</h5>
                            </div> */}
                            <h1 className='text-black' style={{ marginLeft: '7%' }}>{item?.title}</h1>
                        </Accordion.Header>
                        <Accordion.Body>
                            {item?.fields.map((obj, i) => (
                                <div className='row mt-4' key={i} style={{ marginLeft: '15%' }}>
                                    <div className='col-lg-2'>
                                        <h2 className='text-black'>{obj?.name}:</h2>
                                    </div>
                                    <div className='col-lg-10'>
                                        <h5 className='text-grey'>{obj?.value}</h5>
                                    </div>
                                </div>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            <form onSubmit={handleSubmit(saveComment)}>
                <div className='row align-items-end'>
                    <div className='col-9'>
                        <div className="form-group">
                            <label className="form-label" htmlFor="comment">Add Comment</label>
                            <Controller
                                name="comment"
                                control={control}
                                render={({ field }) => (
                                    <input type="text" className={`form-control ${errors.comment ? 'is-invalid' : ''}`} id="comment" placeholder="Add Comment" {...field} />
                                )}
                            />
                            {errors?.comment && (
                                <div className="invalid-feedback">{errors?.comment?.message}</div>
                            )}
                        </div>
                    </div>
                    <div className='col-3'>
                        <button className='btn-primary w-100' type='submit'>Add Comment</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default KeyInformation
