import React, { useEffect, useState } from 'react'
import './Login.scss'
import Logo from '../../assets/images/logo.png'
import LoginLeftImage from '../../assets/images/login_left_image.png'
import ApproveTick from '../../assets/images/Approve_tick.png'
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '../../assets/images/Cancel_icon.png'
import { useForm } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux'
import { loginWithGoogleAuthenticator, verifyAndSendOtp, verifyLoginOtp } from './loginSlice'
import { toast } from 'react-toastify'
import Loader from '../../components/Loader/Loader';
import { decodeToken } from "react-jwt";

function Login() {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [loginOtp, setLoginOtp] = useState(false);
    const [formData, setFormData] = useState({});
    const { isLoading, userToken } = useSelector(state => state.loginReducer);
    const schema = yup.object().shape({
        email: yup.string().email('Invalid email address').matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email address').required('Email is required'),
        password: !loginOtp ? yup
            .string()
            .required('Password is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/,
                'Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.'
            ) : yup.string(),
        otp: loginOtp ? yup
            .string()
            .required('Otp is required') : yup.string(),
    });
    const { control, register, handleSubmit, formState: { errors }, watch, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const googleAuthenticatorSchema = yup.object().shape({
        email: yup.string().email('Invalid email address').matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email address').required('Email is required'),
        code: yup
            .string()
            .required('Code is required')
    });

    const { control: googleAuthenticatorControl, register: googleAuthenticatorregister, handleSubmit: googleAuthenticatorSubmit, formState: { errors: googleAuthenticatorErrors }, reset: googleAuthenticatorReset } = useForm({
        resolver: yupResolver(googleAuthenticatorSchema),
    });


    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const [authenticateOtp, setAuthenticateOtp] = useState(false);
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/`;
        navigate(path);
    }

    // const navigate = useNavigate();
    // const routeLogin = () => {
    //     let path = `/`;
    //     navigate(path);
    // }
    const routeRegister = () => {
        let path = `/registration`;
        navigate(path);
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            history("/", { replace: true });
        }
    }, []);

    const onSubmit = (data) => {
        setFormData({ email: data.email, password: data.password });
        dispatch(!loginOtp ? verifyAndSendOtp(data) : verifyLoginOtp(data))
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                if (loginOtp) {
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('refresh', res.data.refresh);
                    const decodedToken = decodeToken(res.data.token);
                    localStorage.setItem('activePlan', decodedToken.activePlan);
                    toast.success('Login Successfull');
                    history('/')
                }
                setLoginOtp(!loginOtp);
            }).catch((error) => {
                toast.error(error?.message);
            })
    }

    const resenOTP = () => {
        if (formData) {
            dispatch(verifyAndSendOtp(formData)).unwrap().then((res) => {
                toast.success(res?.message)
            }).catch((error) => {
                toast.error(error?.message);
            })
        }
    }

    const onGoogleAuthenticatorSubmit = (data) => {
        dispatch(loginWithGoogleAuthenticator(data))
            .unwrap()
            .then((res) => {
                toast.success(res?.message)
                googleAuthenticatorReset();
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('refresh', res.data.refresh);
                const decodedToken = decodeToken(res.data.token);
                localStorage.setItem('activePlan', decodedToken?.activePlan);
                history('/')
            }).catch((error) => {
                toast.error(error?.message);
            })
    }

    return (
        <section className="vh-100">
            {isLoading && <Loader />}
            <div className="container-fluid h-100">
                <div className="row d-flex justify-content-between align-items-center h-100 " >
                    <div className="col-md-8 col-lg-7 col-xl-6 h-100 d-flex justify-content-center align-items-center imagecontainer" style={{ backgroundColor: '#E4F1F6', borderTopRightRadius: 50, borderBottomRightRadius: 50 }}>
                        <img src={LoginLeftImage} className='loginImage' />
                    </div>
                    <div className="col-md-7 col-lg-5 col-xl-5 form-login my-2">
                        {!authenticateOtp &&
                            <form className='form' onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <img src={Logo} className="logo mt-3" alt="Sample image" />
                                </div>
                                <h1 className='text-blue text-blue-login my-4'>
                                    Login
                                </h1>
                                <div className="form-outline mb-4">
                                    <label className="form-label" htmlFor="form3Example3">Email</label>
                                    <input
                                        type="text"
                                        className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`}
                                        placeholder="Email"
                                        {...register('email', {
                                            required: `Please Enter Email`,
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: 'Please enter valid Email Id',
                                            },
                                        })}
                                    />
                                    {errors?.email && (
                                        <div className="invalid-feedback">{errors?.email?.message}</div>
                                    )}

                                </div>

                                {!loginOtp && <div className="form-outline mb-3 position-relative">
                                    <label className="form-label" for="form3Example4">Password</label>
                                    <input
                                        type={passwordType}
                                        onChange={handlePasswordChange}
                                        className={`form-control form-control-lg ${errors.password ? 'is-invalid' : ''}`}
                                        placeholder="Enter Password"
                                        {...register('password', {
                                            required: `Enter Password`
                                        })}
                                    />

                                    <div className='eye-icon' onClick={togglePassword}>
                                        {passwordType === "password" ? <FaEyeSlash size={25} color='#037AD0' /> : <FaEye color='#037AD0' size={25} />}
                                    </div>

                                    {errors?.password && (
                                        <div className="invalid-feedback">{errors?.password?.message}</div>
                                    )}
                                </div>
                                }
                                {loginOtp && <div className="form-outline mb-3">
                                    <label className="form-label" for="form3Example4">Enter OTP</label>

                                    <input
                                        type='text'
                                        className={`form-control form-control-lg ${errors.otp ? 'is-invalid' : ''}`}
                                        placeholder="Enter OTP"
                                        {...register('otp', {
                                            required: `Enter OTP`
                                        })}
                                    />

                                    {errors?.otp && (
                                        <div className="invalid-feedback">{errors?.otp?.message}</div>
                                    )}

                                </div>}

                                <div className="d-flex justify-content-between align-items-center">
                                    {/* <a href="#!" className="text-blue forget">Forgot password?</a> */}
                                    <Link to="/forgetpassword" className="text-blue forget" style={{ fontWeight: '500' }}>Forgot password?</Link>
                                    {!loginOtp && <p className="small fw-bold pt-1 mb-0">New to Oomero?<span onClick={routeRegister} className="text-blue" style={{ textDecorationLine: 'none', cursor: 'pointer' }}> Register Here</span></p>}
                                    {loginOtp && <a className="text-blue resend" onClick={resenOTP}>Resend OTP</a>}
                                </div>

                                <div className="text-center text-lg-start mt-4 pt-2 w-100">
                                    {!loginOtp && <button type="submit" className={`${authenticateOtp ? 'btn-grey' : 'btn-primary'} btn-lg btn-block w-100`}>Login</button>}
                                    {loginOtp && <button type="submit" className={`${authenticateOtp ? 'btn-grey' : 'btn-primary'} btn-lg btn-block w-100`}>Login</button>}
                                </div>
                                <div className="divider d-flex align-items-center justify-content-center  my-2">
                                    <p className="text-center fw-bold mx-3 mb-0 text-or">Or</p>
                                </div>
                            </form>
                        }
                        <form className='google-code' onSubmit={googleAuthenticatorSubmit(onGoogleAuthenticatorSubmit)}>
                            {authenticateOtp &&
                                <>
                                    <div>
                                        <img src={Logo} className="logo mt-3" alt="Sample image" />
                                    </div>
                                    <h1 className='text-blue text-blue-login my-4'>
                                        Login with Google Authenticator
                                    </h1>
                                    <div className="form-outline mb-4">
                                        <label className="form-label" htmlFor="form3Example3">Enter Email</label>
                                        <input
                                            type="text"
                                            className={`form-control form-control-lg ${googleAuthenticatorErrors.email ? 'is-invalid' : ''}`}
                                            placeholder="Email"
                                            {...googleAuthenticatorregister('email')}
                                        />
                                        {googleAuthenticatorErrors?.email && (
                                            <div className="invalid-feedback">{googleAuthenticatorErrors?.email?.message}</div>
                                        )}
                                    </div>
                                    <div className="form-outline mb-4">
                                        <label className="form-label" htmlFor="form3Example3">Enter Code</label>
                                        <input
                                            type="text"
                                            className={`form-control form-control-lg ${googleAuthenticatorErrors.code ? 'is-invalid' : ''}`}
                                            placeholder="Enter Code"
                                            {...googleAuthenticatorregister('code')}
                                        />
                                        {googleAuthenticatorErrors?.code && (
                                            <div className="invalid-feedback">{googleAuthenticatorErrors?.code?.message}</div>
                                        )}
                                    </div>
                                </>
                            }
                            <div className="text-center text-lg-start my-2 pt-2 w-100">
                                <button onClick={() => (authenticateOtp) ? '' : setAuthenticateOtp(!authenticateOtp)} type="submit" className="btn btn-success btn-lg btn-block w-100">Use Google Authenticator to Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header className='model-header mt-2 pb-3'>
                    <div className="modal-form">
                        <div className='form-group'>
                            <h3 className="modal-title mb-2 pb-2 text-center">Login Type</h3>
                            <select id="statusDropdown"
                                className="form-control form-select">
                                <option value="Admin">Admin</option>
                                <option value="MLRO">MLRO</option>
                            </select>
                        </div>
                    </div>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 6, marginRight: 8, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Footer >
                    <button onClick={routeChange} type="button" className="btn btn-primary w-50 mb-3">Login</button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}

export default Login
