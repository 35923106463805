import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    isLoading: false,
    error: null,
    taxLists: [],
    taxDetails: {}
}

export const fetchTaxLists = createAsyncThunk("tax/fetchAllTax", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchAllTax + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchTaxDetails = createAsyncThunk("tax/fetchTaxDetails", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchTaxDetails + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const addNewTax = createAsyncThunk("tax/addNewTax", async (requestObject) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.addNewTax,
            data:requestObject
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateTaxData = createAsyncThunk("tax/updateTaxData", async (requestObject) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.updateTaxData,
            data:requestObject
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const deleteTaxData = createAsyncThunk("tax/deleteTaxData", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.delete,
            url: API_ENDPOINTS.deleteTax + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});


const taxSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTaxLists.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.taxLists = payload?.data;
            })
            .addCase(addNewTax.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(fetchTaxDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.taxDetails = payload.data;
            })
            .addCase(updateTaxData.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(deleteTaxData.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addMatcher(isAnyOf(fetchTaxLists.pending, addNewTax.pending,fetchTaxDetails.pending,updateTaxData.pending,deleteTaxData.pending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(fetchTaxLists.rejected, addNewTax.rejected,fetchTaxDetails.rejected,updateTaxData.rejected,deleteTaxData.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again ";

            })
    }
});

const taxSliceReducer = taxSlice.reducer;

export default taxSliceReducer;