import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import ThumbIcon from '../../assets/images/thumb_icon.png'
import { useNavigate, useLocation } from 'react-router-dom';
import { countryList } from '../Registration/registrationSlice'
import { addNewTax, fetchTaxDetails, updateTaxData } from './taxSlice'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import Select from 'react-select';
import BackIcon from '../../assets/images/back-btn-arrow.png';
import { decodeToken } from 'react-jwt';

const Addtax = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [modalMessage, setModalMessage] = useState('Tax added successfully !!');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { countryData } = useSelector(state => state.registerReducer);
    const { isLoading } = useSelector(state => state.taxSliceReducer);
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/tax`;
        navigate(path);
    }
    // Get the query parameters from the URL
    const params = new URLSearchParams(location.search);

    const schema = yup.object().shape({
        taxName: yup.string().required('Tax Name is required'),
        taxRate: yup.string().required('Tax Rate is required'),
        countryId: yup.number().typeError('Invalid Country').required('Country is required'),
    });
    const { control, register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        dispatch(countryList());
    }, []);

    useEffect(() => {
        const taxId = params.get('taxId');
        if (taxId) {
            let queryParams = '?taxId=' + taxId;
            dispatch(fetchTaxDetails(queryParams)).unwrap().then((res) => {
                setValue('taxName', res.data.tax_name);
                setValue('taxRate', res.data.tax_rate);
                setValue('countryId', res.data.country_id);
            }).catch((error) => {
                toast.error(error?.message);
            })
        }
    }, [setValue]);

    useEffect(() => {
        if (!(((decodedToken?.roles?.permissions?.create_tax || decodedToken?.roles?.permissions?.edit_tax) && decodedToken?.isOomero) || decodedToken?.isSuperAdmin)) {
            toast.error("You don't have access for tax");
            navigate('/');
        }
    }, [decodedToken]);

    const onSubmit = (requestObject) => {
        const taxId = params.get('taxId');
        if (taxId) {
            requestObject.taxId = taxId
        }
        dispatch(taxId ? updateTaxData(requestObject) : addNewTax(requestObject)).unwrap().then((res) => {
            if (!taxId) reset();
            setModalMessage(res.message);
            handleShow();
        }).catch((error) => {
            toast.error(error?.message);
        })
    }
    const customStyles = {
        control: (provided) => ({
            ...provided,
            // padding: '5px',
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
            boxShadow: 'none',
            '&:foucs': {
                border: '2px solid #B3B3B3',
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#B3B3B3',
        }),
    };

    return (
        <div className='grey-background'>
            {isLoading && <Loader />}
            <button onClick={routeChange} className='back-button text-blue'><img src={BackIcon} style={{width:8,marginBottom:2,marginRight:4,objectFit:'contain'}}/> Back</button>
            <div className='white-background p-3 my-3'>
                <h1 className='text-blue px-2 fw-normal'>Create Tax</h1>
            </div>
            <form className='form' onSubmit={handleSubmit(onSubmit)}>
                <div className='white-background p-3 mb-3'>
                    {/* <div className="col-lg-12 col-md-12">
                        <div className="form-group mt-3">
                            <label className="form-label" for="countryDropdown">Country</label>
                            <Controller
                                name="countryId"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <Select
                                        id="countryDropdown"
                                        placeholder="Select Country"
                                        className={`${errors.countryId ? 'is-invalid' : ''}`}
                                        options={countryData.map(country => ({
                                            label: country.name,
                                            value: country.country_id
                                        }))}
                                        styles={customStyles}
                                        value={field.value ? {
                                            label: countryData.find(country => country.country_id == field.value)?.name,
                                            value: field.value
                                        } : null}
                                        onChange={(selectedOption) => {
                                            field.onChange(selectedOption?.value);
                                        }}
                                    />
                                )}
                            />
                            {errors?.countryId && (
                                <div className="invalid-feedback">{errors?.countryId?.message}</div>
                            )}
                        </div>
                    </div> */}
                    <div className='row g-3 mb-3'>
                        <div className='col-lg-4 col-md-4'>
                            <div className="form-group mt-3">
                                <label className="form-label" for="countryDropdown">Country</label>
                                <Controller
                                    name="countryId"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            id="countryDropdown"
                                            placeholder="Select Country"
                                            className={`${errors.countryId ? 'is-invalid' : ''}`}
                                            options={countryData.map(country => ({
                                                label: country.name,
                                                value: country.country_id
                                            }))}
                                            styles={customStyles}
                                            value={field.value ? {
                                                label: countryData.find(country => country.country_id == field.value)?.name,
                                                value: field.value
                                            } : null}
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption?.value);
                                            }}
                                        />
                                    )}
                                />
                                {errors?.countryId && (
                                    <div className="invalid-feedback">{errors?.countryId?.message}</div>
                                )}
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            <div className="form-group mt-3">
                                <label className="form-label">Tax Name</label>
                                <input
                                    type="text"
                                    className={`form-control form-control-lg ${errors.taxName ? 'is-invalid' : ''}`}
                                    placeholder="Tax Name"
                                    {...register('taxName', {
                                        required: `Please Enter Tax Name`
                                    })}
                                />
                                {errors?.taxName && (
                                    <div className="invalid-feedback">{errors?.taxName?.message}</div>
                                )}
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            <div className="form-group mt-3">
                                <label className="form-label">Tax Rate <b>%</b> </label>
                                <input
                                    type="text"
                                    className={`form-control form-control-lg ${errors.taxRate ? 'is-invalid' : ''}`}
                                    placeholder="Tax Rate"
                                    {...register('taxRate', {
                                        required: `Please Enter Tax Rate`
                                    })}
                                />
                                {errors?.taxRate && (
                                    <div className="invalid-feedback">{errors?.taxRate?.message}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row-reverse">
                    <button type="submit" className="btn btn-primary mt-3 mb-1">Save</button>
                </div>
            </form>

            <Modal show={show} onHide={handleClose} centered >
                <Modal.Body>
                    <div className='d-flex justify-content-center'>
                        <img src={ThumbIcon} style={{ width: 70, height: 70, objectFit: 'contain' }} />
                    </div>
                    <h3 className='text-blue text-center mt-3 mb-0 fw-normal'>{modalMessage}</h3>
                    <div className="d-flex justify-content-center">
                        <button onClick={routeChange} className="btn-primary w-50 my-3">Ok</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Addtax
