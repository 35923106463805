import React, { useState } from 'react'
import PersonalDetail from '../../assets/images/personalDetails.png'
import AddBlueButton from '../../assets/images/Add_blue_button.png'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ThumbIcon from '../../assets/images/thumb_icon.png'

function ManageAdmin() {
    const navigate = useNavigate();
    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const routeChange = () => {
        // let path = `/manage_clients`;
        let path = `/clients/manage_New_client`;
        navigate(path);
    }
    return (
        <div className='grey-background'>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='white-background p-3'>
                        <div className='d-flex align-items-center'>
                            <img src={PersonalDetail} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                            <h1 className='text-ambed fw-normal'>Admin Details</h1>
                        </div>
                        <hr />
                        <div className='form'>
                            <form>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">First Name</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter First Name" />
                                        </div>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Middle Name</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Middle Name" />
                                        </div>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Last Name</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Last Name" />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Email</label>
                                            <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter Email" />
                                        </div>
                                        <div className='row align-items-end'>
                                            <div className='col-lg-12 col-md-12'>
                                                <div className="form-group mt-3">
                                                    <label className="form-label" for="exampleInputEmail1">Mobile Number</label>
                                                    <div className='row'>
                                                        <div className='col-lg-2 col-md-2'>
                                                            <select id="statusDropdown"
                                                                className="form-control form-select">
                                                                <option>1</option>
                                                                <option>2</option>
                                                                <option>3</option>
                                                                <option>4</option>
                                                                <option>5</option>
                                                            </select>
                                                        </div>
                                                        <div className='col-lg-10 col-md-10'>
                                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter number" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-lg-2 col-md-2'>
                                                <img src={AddBlueButton} style={{ width: 50, objectFit: 'contain' }} />
                                            </div> */}
                                        </div>
                                        <div className='row align-items-end'>
                                            <div className='col-lg-12 col-md-12'>
                                                <div className="form-group mt-3">
                                                    <label className="form-label" for="exampleInputEmail1">Landline Number</label>
                                                    <div className='row'>
                                                        <div className='col-lg-2 col-md-2'>
                                                            <select id="statusDropdown"
                                                                className="form-control form-select">
                                                                <option>1</option>
                                                                <option>2</option>
                                                                <option>3</option>
                                                                <option>4</option>
                                                                <option>5</option>
                                                            </select>
                                                        </div>
                                                        <div className='col-lg-10 col-md-10'>
                                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter number" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-lg-2 col-md-2'>
                                                <img src={AddBlueButton} style={{ width: 50, objectFit: 'contain' }} />
                                            </div> */}
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <div className="form-group">
                            <label className="form-label" for="exampleFormControlSelect1">Designation</label>
                            <select id="statusDropdown" className="form-control form-select">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <div className="form-group">
                            <label className="form-label" for="exampleInputEmail1">Company Name</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <div className="form-group">
                            <label className="form-label" for="exampleInputEmail1">Company Reg. Address</label>
                            <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Company Reg. Address" rows="5" />
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <div className="form-group">
                            <label className="form-label" for="exampleInputEmail1">Company Trading Address</label>
                            <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Company Trading Address" rows="5" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <div className="form-group">
                            <label className="form-label" for="exampleInputEmail1">VAT (Tax) Number</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" />
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <div className="form-group">
                            <label className="form-label" for="exampleInputEmail1">Company Reg Number</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end my-4'>
                <div className='d-flex align-items-center'>
                    <button onClick={routeChange} className='btn-primary'>Save & Next</button>
                </div>
            </div>

            {/* <Modal show={show} onHide={handleClose} centered >
                <Modal.Body>
                    <div className='d-flex justify-content-center'>
                        <img src={ThumbIcon} style={{ width: 70, height: 70, objectFit: 'contain' }} />
                    </div>
                    <h3 className='text-blue text-center mt-3 mb-0 fw-normal'>Changes done successfully !!</h3>
                    <div className="d-flex justify-content-center">
                        <button onClick={routeChange} className="btn-primary w-50 my-3">Ok</button>
                    </div>
                </Modal.Body>
            </Modal> */}
        </div>
    )
}

export default ManageAdmin
