import React, { useEffect, useRef, useState } from 'react'
import ScreenActionIcon from '../../../assets/images/screeing_action.png'
import CommentIcon from '../../../assets/images/comment_icon.png'
import * as  yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { manageScreeningAction, requestDocument, resendToCheckerMaker, sendToCheckerMlro, updateScreeningInfo, uploadDocument } from '../IndividualScreeningSlice';
import Loader from '../../../components/Loader/Loader';
import { fetchEntityClientPersonalDetail } from '../../Profile/profileSlice';
import { useNavigate } from 'react-router-dom';
import { decodeToken, useJwt } from "react-jwt";    
import uploadDoc from '../../../assets/images/doc_img.png'
import { getSignedUrls } from '../../../utils/awsHelper';
import { addScreeningComments } from '../IndividualScreeningSlice'



function IndividualScreenInfo({entityClientId, viewOnly}) {
    const dispatch = useDispatch();
    const { isLoading : screeningLoader } = useSelector(state => state.individualScreeningReducer);
    const fileInputRef = useRef(null);
    const { isLoading , personalDetails} = useSelector(state => state.ProfileReducer);
    const navigate = useNavigate();
    const [riskStatus, setRiskStatus] = useState();
    const [riskLevel, setRiskLevel] = useState();
    const [isBlacklisted, setIsBlacklisted] = useState(false);
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [isWhitelisted, setIsWhitelisted] = useState(false);
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);
    const [uploadedFileInfo, setUploadedFileInfo] = useState(null)
    const requestDocumentSchema = yup.object().shape({
        documentType: yup.string().required('Document Type is required'),
    });
    let screeningStep = {
        maker : "checker",
        checker : "mlro"
    }
    const commentSchema = yup.object().shape({
        comment: yup.string().required('Comment is required').min(30, 'Comment must be at least 30 characters'),
        commentType: yup.string().default("aml"),
    });
    
    const { control: commentControl, register: registerComment, handleSubmit: handleCommentSubmit, formState: { errors: commentErrors}, reset:resetCommentForm, setValue, watch } = useForm({
        resolver: yupResolver(commentSchema),
    });

    const { control: controlRequestDocumentForm, register: registerRequestDocumentForm, handleSubmit: handleSubmitRequestDocumentForm, formState: { errors: errorsRequestDocumentForm }, reset: resetRequestDocumentForm, setValue: setValueRequestDocument } = useForm({
        resolver: yupResolver(requestDocumentSchema),
    });


    const onRequestDocumentFormSubmit = (data) => {
        resetRequestDocumentForm();
        dispatch(requestDocument(`?entityClientId=${data?.entityClientId}&documentType=${data.documentType}`)).unwrap().then((obj) => {
            toast.success(obj?.message || 'Document Requested Successfully');
        }).catch(error => {
            toast.error(error.message || 'Unable to request document');
        });
    }
    const uploadDocumentSchema = yup.object().shape({
        documentType: yup.string().required('Document Type is required'),
        document: yup.string()
        .test('fileSize', 'File size is max 5MB', value => {
            if (!value) return true;
            const fileSizeInBytes = (value.length * 3) / 4 - 2; 
            return fileSizeInBytes <= 5 * 1024 * 1024; 
        })
        .test('fileType', 'Only supported file type of pdf, doc,jpg,png', value => {
            if (!value) return true; 
            const fileTypeMappings = {
                'data:application/pdf': 'pdf',
                'data:application/msword': 'doc',
                'data:image/jpeg': 'jpg',
                'data:image/png': 'png'
            };
            const base64Header = value.substring(0, 30); 
            for (const [typeHeader, fileType] of Object.entries(fileTypeMappings)) {
                if (base64Header.startsWith(typeHeader)) {
                    return true; 
                }
            }
            return false; 
        })
        .required('Document is required'),
        screen: yup.string().required('Screen is required'),
    });

    const { control: controlUploadDocumentForm, register: registerUploadDocumentForm, handleSubmit: handleSubmitUploadDocumentForm, formState: { errors: errorsUploadDocumentForm }, reset: resetUploadDocumentForm, setValue: setValueUploadDocument, trigger } = useForm({
        resolver: yupResolver(uploadDocumentSchema),
    });

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        
        if (file) {
            const fileName = file?.name;
            const uploadDate = new Date().toLocaleString();
            const uploadedBy = decodedToken?.firstName;

            setUploadedFileInfo({
                fileName,
                uploadDate,
                uploadedBy,
            });
            const reader = new FileReader();

            // Set up FileReader onload callback
            reader.onload = () => {
                // FileReader onload callback is triggered when reading is done
                const base64String = reader.result;
                setValueUploadDocument('document', base64String);
                trigger('document');
            };
            setUploadedFileName(file.name);

            // Read the file as a data URL, triggering the onload callback when done
            reader.readAsDataURL(file);
        }
    };
    

    const onUploadDocumentFormSubmit = (data) => {
        const filename = uploadedFileName; // Get the filename from the state
        const payload = { ...data, filename };
        dispatch(uploadDocument(payload)).unwrap().then((obj) => {
            resetUploadDocumentForm({document:null,documentType:null});
            toast.success(obj?.message || 'Document Uploaded Successfully');
        }).catch(error => {
            toast.error(error.message || 'Unable to upload document');
        });
    }
    const resendToCheckerMakerFunc = (step) => {
        const queryParam = `?entity_client_id=${entityClientId}`;
        const payload = {
            step
        }
        dispatch(resendToCheckerMaker({ queryParam, payload })).unwrap().then((obj) => {
            toast.success(obj?.message);
            navigate('/individual_screening');
        }).catch(error => {
            toast.error(error.message || `Unable to send to ${step}`);
        });
    }

    const sendToCheckerMlroFunc = () => {
        const queryParam = `?entity_client_id=${entityClientId}`;
        dispatch(sendToCheckerMlro({ queryParam })).unwrap().then((obj) => {
            toast.success(obj?.message);
            navigate('/individual_screening');
        }).catch(error => {
            toast.error(error.message || `Unable to process`);
        });
    }

    function capitalizeFirstLetter(str) {
        return str.toUpperCase();
    }   

    useEffect(() => {
        if (!entityClientId) {
            navigate(`/individual_screening`);
            return;
        }

        dispatch(fetchEntityClientPersonalDetail({ queryParam: `?entity_client_id=${entityClientId}` })).unwrap().then((res) => {
            setIsBlacklisted(res?.data?.is_blacklisted);
            setIsWhitelisted(res?.data?.is_whitelisted);
            if (decodedToken?.isOomero) {
                setRiskLevel(res?.data?.oomero_risk_level);
                setRiskStatus(res?.data?.oomero_risk_status);
            } else {
                setRiskLevel(res?.data?.risk_level);
                setRiskStatus(res?.data?.risk_status);
            }
        }).catch((error) => {
            toast.error(error?.message);
        });

    }, [entityClientId, dispatch, navigate]);

    const handleRiskStatusChange = (event) => {
        let value = event.target.value;
        setRiskStatus(event.target.value);
        if (value && value != '') {
            dispatch(updateScreeningInfo({payload : {riskStatus : value}, queryParam : `?entity_client_id=${entityClientId}`})).unwrap().then((obj) => {
                toast.success(obj?.message || 'Info Updated Successfully');
            }).catch(error => {
                toast.error(error.message || 'Unable to update info');
            });
        }
    };

    const handleRiskLevelChange = (event) => {
        let value = event.target.value;
        setRiskLevel(value);
        if (value && value != '') {
            dispatch(updateScreeningInfo({payload : {riskLevel : value}, queryParam : `?entity_client_id=${entityClientId}`})).unwrap().then((obj) => {
                toast.success(obj?.message || 'Info Updated Successfully');
            }).catch(error => {
                toast.error(error.message || 'Unable to update info');
            });
        }
    };

    const handleBlacklistedChange = (event) => {
        const { checked } = event.target;
        setIsBlacklisted(checked);
        dispatch(updateScreeningInfo({payload : {isBlacklist : checked}, queryParam : `?entity_client_id=${entityClientId}`})).unwrap().then((obj) => {
            toast.success(obj?.message || 'Info Updated Successfully');
        }).catch(error => {
            toast.error(error.message || 'Unable to update info');
        });
    }

    const handleWhitelistedChange = (event) => {
        const { checked } = event.target;
        setIsWhitelisted(checked);
        dispatch(updateScreeningInfo({payload : {isWhitelist : checked}, queryParam : `?entity_client_id=${entityClientId}`})).unwrap().then((obj) => {
            toast.success(obj?.message || 'Info Updated Successfully');
        }).catch(error => {
            toast.error(error.message || 'Unable to update info');
        });
    }

    const handleClick = (value) => {
        dispatch(manageScreeningAction({ queryParams: `?entity_client_id=${entityClientId}` , payload: { status: value }}))
            .unwrap()
            .then(obj => {
                toast.success(obj?.message || 'Screening Action Updated Successfully');
                navigate('/individual_screening')
            })
            .catch(error => {
                toast.error(error.message || 'Failed to update Screening Action');
            });
    };
    const onCommentFormSubmit = (data) => {
        resetCommentForm();
        dispatch(addScreeningComments({payload:data})).unwrap().then((obj)=>{
            toast.success(obj?.message || 'Comment Added Successfully');
        }).catch(error => {
            toast.error(error.message || 'Unable to add comments');
        });
    }
  return (
    <div>
        {(screeningLoader || isLoading) && <Loader/>}
    <div className='d-flex align-items-center mt-3'>
        <img src={ScreenActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
        <h2 className='text-ambed text-center'>Screening Action</h2>
    </div>
    <hr />
    <div className='row'>
        <div className='col-lg-4'>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" checked={isWhitelisted} onChange={handleWhitelistedChange}  value="" id="flexCheckDefault" disabled={viewOnly}/>
                <label className="form-check-label" for="flexCheckDefault">
                    Whitelist this customer for all future TXNs
                </label>
            </div>
        </div>
        <div className='col-lg-4'>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" checked={isBlacklisted} onChange={handleBlacklistedChange} value="" id="flexCheckDefault" disabled={viewOnly}/>
                <label className="form-check-label" for="flexCheckDefault">
                    Blacklist this customer for all future TXNs
                </label>
            </div>
        </div>
    </div>
    <div className='row'>
        <div className='col-lg-3'>
            <div className="form-group mt-3">
                <label className="form-label" for="exampleFormControlSelect1">Risk Status</label>
                <select id="statusDropdown" value={riskStatus} onChange={handleRiskStatusChange} className="form-control form-select" disabled={viewOnly}>
                    <option value="">Select Risk Status</option>
                    <option value="green">Green</option>
                    <option value="amber">Amber</option>
                    <option value="red">Red</option>
                </select>
            </div>
        </div>
        <div className='col-lg-3'>
            <div className="form-group mt-3">
                <label className="form-label" for="exampleFormControlSelect1">Risk Level</label>
                <select id="statusDropdown" value={riskLevel} disabled={viewOnly} onChange={handleRiskLevelChange} className="form-control form-select">
                    <option value="">Select Risk Level</option>
                    <option value="high">High</option>
                    <option value="medium">Medium</option>
                    <option value="low">Low</option>
                </select>
            </div>
        </div>
    </div>
    {!viewOnly && 
    <div className='row'>
        <div className='col-lg-6'>
            <form onSubmit={handleSubmitUploadDocumentForm(onUploadDocumentFormSubmit)}>
                <div className='row align-items-end'>
                    <div className='col-lg-7'>
                        <div className="form-group mt-3">
                            <label className="form-label" for="exampleFormControlSelect1">Upload Document</label>
                            <select className={`form-select first-select ${errorsUploadDocumentForm?.documentType ? 'is-invalid' : ''}`} aria-label="Default select example" {...registerUploadDocumentForm('documentType')}>
                            <option value=''>Select Document Type</option>
                                            <option value="aadhaar">Aadhaar</option>
                                            <option value="pancard">Pan Card</option>
                                            <option value="driving_license">Driving License</option>
                                            <option value="upload_article">Upload Article</option>
                                            <option value="legal_information"> Legal Information</option>
                                            <option value="proof_of_mitigation"> Proof of Mitigation</option>
                                            <option value="proof_of_bank_letter"> Proof of Bank Letter</option>
                                            <option value="other_document"> Other Document</option>
                            </select>
                            {errorsUploadDocumentForm?.documentType &&
                                <span className="invalid-feedback" >Document Type is required</span>
                            }
                        </div>
                    </div>
                    <div className=''>
                        <input type="file" style={{ display: "none" }} onChange={handleFileChange} ref={fileInputRef} />
                        <input type="hidden" {...registerUploadDocumentForm(`entityClientId`, { value: entityClientId })} />
                        <input
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    onChange={handleFileChange}
                                                    ref={fileInputRef}
                                                    accept=".pdf,.doc,.jpg,.png"
                                                />
                                                <input
                                                    type="hidden"
                                                    {...registerUploadDocumentForm(`entityClientId`, { value: entityClientId })}
                                                />
                                                <input
                                                    type="hidden"
                                                    {...registerUploadDocumentForm(`document`)}
                                                />
                                                <input type="hidden" {...registerUploadDocumentForm(`screen`, { value: 'screeningAction' })} />
                                                <div className={`upload-document-box mt-3 ms-2 ${errorsUploadDocumentForm.document ? 'is-invalid' : ''}`} onClick={handleButtonClick}>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <img src={uploadDoc} style={{ width: 70, objectFit: 'contain' }} alt="Upload" />
                                                        <div className='ms-2'>
                                                            <div className="upload-blue-text">Tap to upload receipt</div>
                                                            <div className="upload-grey-text">PDF, DOC, JPG, PNG (max. 5MB)</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {uploadedFileInfo && (
                                                <p className='subtext text-success' style={{fontSize:12}}>{uploadedFileInfo.fileName} ({uploadedFileInfo.uploadDate}) Uploaded by: {uploadedFileInfo.uploadedBy}</p>)}
                                                {errorsUploadDocumentForm.document && <small className='text-danger'>{errorsUploadDocumentForm.document.message}</small>}
                        <input type="hidden" {...registerUploadDocumentForm(`document`)} />
                    </div>
                </div>
                <button type="submit" className="btn btn-primary mt-3">Submit</button>
            </form>
        </div>
        <div className='col-lg-6'>
            <form className='form' onSubmit={handleSubmitRequestDocumentForm(onRequestDocumentFormSubmit)}>
                <div className='row align-items-end'>
                    <div className='col-lg-7'>
                        <div className="form-group mt-3">
                            <label className="form-label" for="exampleFormControlSelect1">Request Document</label>
                            <select id="statusDropdown" className={`form-control form-select  ${errorsRequestDocumentForm?.documentType ? 'is-invalid' : ''}`} {...registerRequestDocumentForm('documentType')}>
                            <option value=''>Select Document Type</option>
                                            <option value="aadhaar">Aadhaar</option>
                                            <option value="pancard">Pan Card</option>
                                            <option value="driving_license">Driving License</option>
                                            <option value="article">Article</option>
                                            <option value="legal_information">Legal Information</option>
                                            <option value="proof_of_mitigation">Proof of Mitigation</option>
                                            <option value="proof_of_bank_letter">Proof of Bank Letter</option>
                                            <option value="other_document">Other Document</option>
                            </select>
                            {errorsRequestDocumentForm?.documentType &&
                                <span className="invalid-feedback" >Document Type is required</span>
                            }
                        </div>
                        <input type="hidden" {...registerRequestDocumentForm(`entityClientId`, { value: entityClientId })} />
                    </div>
                    <div className='col-lg-5'>
                        <button className='btn-primary fw-normal w-100'>Request Document</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    }
    {!viewOnly && 
              <div className='row'>
                  <div className='col-lg-12'>
                      <div className='row align-items-end'>
                          <form className='form' onSubmit={handleCommentSubmit(onCommentFormSubmit)}>
                              <div className='col-lg-9' style={{ width: '78%' }}>
                                  <div className="form-group mt-3">
                                      <label className="form-label" htmlFor="exampleInputEmail1">
                                          Add Comment (min 30 characters)
                                      </label>
                                      <textarea
                                          type="text"
                                          className={`form-control ${commentErrors?.comment ? 'is-invalid' : ''}`}
                                          id="exampleInputEmail1"
                                          {...registerComment('comment')}
                                          placeholder="Add Comment"
                                          rows="6"
                                      />
                                      {commentErrors?.comment && (
                                          <span className="invalid-feedback">{commentErrors?.comment?.message}</span>
                                      )}
                                  </div>
                                  <input
                                      type="hidden"
                                      {...registerComment('entityClientId', {
                                          value: parseInt(entityClientId, 10),
                                      })}
                                  />
                                  <input
                                      type="hidden"
                                      {...registerComment('commentType', {
                                          value: "aml",
                                      })}
                                  />
                                  <input type="hidden" {...registerComment(`screen`, { value: 'screening_info' })} />
                              </div>
                              <div className='col-lg-3 mt-4' style={{ width: '18%' }}>
                                  <button
                                      type='submit'
                                      className={`btn-primary fw-normal w-100 ${Object.keys(commentErrors).length > 0 ? 'btn-grey' : ''}`}
                                  >
                                      Add Comment
                                  </button>

                              </div>
                          </form>
                      </div>
                  </div>
              </div>
    }

{!viewOnly ? (
    (decodedToken.isEnterpriseAdmin || decodedToken.isSuperAdmin || decodedToken.roles === 'mlro') ? (
        <div className='d-flex align-items-center justify-content-between mt-5'>
            <div>
                <button className='btn-blue-light fw-normal' onClick={()=> resendToCheckerMakerFunc('maker')}>Resend to Maker</button>
                <button className='btn-blue-light fw-normal ms-3' onClick={()=> resendToCheckerMakerFunc('checker')}>Resend to Checker</button>
            </div>
        <div>
            <>
                <button className='btn-primary fw-normal'  disabled={personalDetails.status !== 'pending'} onClick={() => handleClick("approved")}>Approve</button>
                <button className='btn-red fw-normal ms-3' disabled={personalDetails.status !== 'pending'} onClick={() => handleClick("rejected")}>Reject</button>
            </>
            </div>
        </div>
        ) : (
            (decodedToken?.roles?.role_name === 'maker' || decodedToken?.roles?.role_name === 'checker') && (
                <div className='d-flex align-items-center justify-content-center my-4 pb-3'>
                    <button className='btn-primary fw-normal' onClick={() => sendToCheckerMlroFunc()}>
                        Send to {capitalizeFirstLetter(screeningStep[(decodedToken?.roles?.role_name).toLowerCase()])}
                    </button>
                </div>
            )
        )
) : null}

    {/* <hr />
    <div className='comments pb-3'>
        <div className='d-flex align-items-center'>
            <img src={CommentIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
            <h2 className='text-blue text-center'>Comments History</h2>
        </div>
        <hr />
        <div className='white-background mt-2 p-3' style={{ marginBottom: 20 }}>
            <h5 className='text-blue'>21-02-2022  02:26:19</h5>
            <h5 className='text-black'>Moving From Maker 1 to Checker 1 Test Case Amber</h5>
            <h5 className='text-blue text-end'>- maker 1 maker 1</h5>
        </div>
        <div className='white-background mt-2 p-3' style={{ marginBottom: 20 }}>
            <h5 className='text-blue'>21-02-2022  02:26:19</h5>
            <h5 className='text-black'>Moving From Maker 1 to Checker 1 Test Case Amber</h5>
            <h5 className='text-blue text-end'>- maker 1 maker 1</h5>
        </div>
    </div> */}
</div>
  )
}

export default IndividualScreenInfo
