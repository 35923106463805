import React, { useEffect, useState } from 'react'
import { CChart } from '@coreui/react-chartjs';
function AllOnboarding({getStatsByGraphs,graphStats}) {
  const [type, setType] = useState('individual');
  const [userType, setUserType] = useState('');
  const [section, setSection] = useState('screening');
  const [range, setRange] = useState('');

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };

  const handleSectionChange = (event) => {
    setSection(event.target.value);
  };

  const handleRangeChange = (event) => {
    setRange(event.target.value);
  };
  useEffect(() => {
    getStatsByGraphs(userType, range, section, type);
  }, [userType, range, section, type]);


  const containerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 30
    /* Additional styling goes here */
  };
  return (
    <div className='white-background p-3'>
      <div className='d-flex align-items-center justify-content-between mb-3'>
        <h1 className='text-blue mb-3'>All the onboarding</h1>
        <div className='d-flex align-items-center'>
        <div className="form-group px-4">
            <select id="statusDropdown" onChange={handleUserTypeChange} className="form-control form-select" style={{ width: 100, color: '#004AAD', border: '1px solid #004AAD' }}>
              <option value={''}>Select role </option>
              <option value={'mlro'}>MLRO </option>
              <option value={'checker'}>Checker</option>
              <option value={'maker'}>Maker</option>
            </select>
          </div>

          <div className="form-group">
            <select id="statusDropdown" className="form-control form-select" onChange={handleSectionChange}  style={{ width: 125, color: '#004AAD', border: '1px solid #004AAD', marginRight: 20 }}>
              <option value={'screening'}>Screening</option>
              <option value={'onboarded'}>Onboarded</option>
            </select>
          </div>
          <div className="form-group">
            <select id="statusDropdown"  onChange={handleTypeChange} className="form-control form-select" style={{ width: 115, color: '#004AAD', border: '1px solid #004AAD', marginRight: 20 }}>
              <option value={'individual'}>Individual </option>
              <option>Corporate</option>
            </select>
          </div>
          <div className="form-group">
            <select id="statusDropdown" onChange={handleRangeChange} className="form-control form-select" style={{ width: 130, color: '#004AAD', border: '1px solid #004AAD' }}>
              <option value={''}>Select Range</option>
              <option value={'day'}>Day</option>
              <option value={'week'}>Week</option>
              <option value={'month'}>Month</option>
              <option value={'year'}>Year</option>
            </select>
          </div>

        </div>
      </div>
      <div className='row'>
        <div className='col-lg-3'>
          <div className='white-background p-3 d-flex align-items-center flex-column'>
            <div className='white-background position-relative' style={{ width: 150, borderRadius: 80, filter: 'drop-shadow(0px 3px 15px rgba(0, 0, 0, 0.10))' }}>
              <CChart
                type="doughnut"
                data={{
                  labels: ['AngularJs'],
                  datasets: [
                    {
                      backgroundColor: ['#037924'],
                      data: [graphStats?.green],
                      circumference: (ctx) => {
                        return ctx.dataset.data[0] / 70 * 270
                      },
                      borderWidth: 0
                    },
                  ],
                }}
                options={{
                  cutout: '80%',
                  borderRadius: 20,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
              <p className='text-black position-absolute mb-0' style={containerStyle}>{graphStats?.green}</p>
            </div>
            <h5 className='text-success mt-3'>Green</h5>
          </div>
        </div>
        <div className='col-lg-3'>
          <div className='white-background p-3 d-flex align-items-center flex-column'>
            <div className='white-background position-relative' style={{ width: 150, borderRadius: 80, filter: 'drop-shadow(0px 3px 15px rgba(0, 0, 0, 0.10))' }}>
              <CChart
                type="doughnut"
                data={{
                  labels: ['AngularJs'],
                  datasets: [
                    {
                      backgroundColor: ['#9C6802'],
                      data: [graphStats?.amber],
                      circumference: (ctx) => {
                        return ctx.dataset.data[0] / 50 * 270
                      },
                      borderWidth: 0
                    },
                  ],
                }}
                options={{
                  cutout: '80%',
                  borderRadius: 20,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
              <p className='text-black position-absolute mb-0' style={containerStyle}>{graphStats?.amber}</p>
            </div>
            <h5 className='text-ambed mt-3'>Ambed</h5>
          </div>
        </div>
        <div className='col-lg-3'>
          <div className='white-background p-3 d-flex align-items-center flex-column'>
            <div className='white-background position-relative' style={{ width: 150, borderRadius: 80, filter: 'drop-shadow(0px 3px 15px rgba(0, 0, 0, 0.10))' }}>
              <CChart
                type="doughnut"
                data={{
                  labels: ['AngularJs'],
                  datasets: [
                    {
                      backgroundColor: ['#DA0310'],
                      data: [graphStats?.red],
                      circumference: (ctx) => {
                        return ctx.dataset.data[0] / 10 * 270
                      },
                      borderWidth: 0
                    },
                  ],
                }}
                options={{
                  cutout: '80%',
                  borderRadius: 20,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
              <p className='text-black position-absolute mb-0' style={containerStyle}>{graphStats?.red}</p>
            </div>
            <h5 className='text-danger mt-3'>Red</h5>
          </div>
        </div>
        <div className='col-lg-3'>
          <div className='white-background p-3 d-flex align-items-center flex-column'>
            <div className='white-background position-relative' style={{ width: 150, borderRadius: 80, filter: 'drop-shadow(0px 3px 15px rgba(0, 0, 0, 0.10))' }}>
              <CChart
                type="doughnut"
                data={{
                  labels: ['AngularJs'],
                  datasets: [
                    {
                      backgroundColor: ['#949595'],
                      data: [graphStats?.grey],
                      circumference: (ctx) => {
                        return ctx.dataset.data[0] / 70 * 270
                      },
                      borderWidth: 0
                    },
                  ],
                }}
                options={{
                  cutout: '80%',
                  borderRadius: 20,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
              <p className='text-black position-absolute mb-0' style={containerStyle}>{graphStats?.grey}</p>
            </div>
            <h5 className='text-grey mt-3'>Grey</h5>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllOnboarding
