import React, { useRef } from 'react'
import DownloadDocumentIcon from '../../assets/images/download_documnet_icon.png'
import UploadDocumentIcon from '../../assets/images/client_upload_icon.png'
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup'; 
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

function DocumentsAml({globalSearchDetail,saveAmlComments,uploadMediaImage}) {
    const commentSchema = yup.object().shape({
        comment: yup.string().required('Comment is required'),
      });
      const fileInputRef = useRef(null);
    
      const { control, handleSubmit, formState: { errors }, reset} = useForm({
        resolver: yupResolver(commentSchema),
      });
      const saveComment =(formData)=>{
          const requestData = { ...formData,global_search_result_id: globalSearchDetail.global_search_results_id , section: "document" };  
        saveAmlComments(requestData);
        reset({comment:""});
      }

      const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png'];
            if (!allowedTypes.includes(file.type)) {
                toast.error('Invalid file type. Please select a JPEG or PNG file.');
                return;
            }
            // Validate file size (in bytes)
            const maxSize = 5 * 1024 * 1024; // 5MB
            if (file.size > maxSize) {
                toast.error('File size exceeds the limit of 5MB. Please select a smaller file.');
                return;
            }

            const reader = new FileReader();
            
            reader.onloadend = () => {
                uploadMediaImage(globalSearchDetail.global_search_results_id, { mediaImage: reader.result });
            };

            reader.readAsDataURL(file);
        }
    };

    return (
        <div>
            <h1 className='text-black my-5'>Media Documents</h1>
            {/* <div className='row my-3'>
                <div className='col-2'>
                    <h2 style={{ marginRight: 20 }}>Data Source:</h2>
                </div>
                <div className='col-8'>
                    <div className='d-flex align-items-center'>
                        <img src={DownloadDocumentIcon} style={{ width: 50, objectFit: 'contain', marginRight: 10 }} />
                        <h4>star.worldbank.org</h4>
                    </div>
                </div>
            </div>
            <hr /> */}
            <div className='row my-3'>
                <div className='col-2'>
                    <h2 style={{ marginRight: 20 }}>Upload:</h2>
                </div>
                <div className='col-8'>
                    <div className='d-flex align-items-center'>
                        <img src={UploadDocumentIcon} style={{ width: 50, objectFit: 'contain', marginRight: 10 }} />
                        <div>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                accept="image/*"
                            />
                            <h6 onClick={handleButtonClick} className='text-success' style={{ fontWeight: '600', textDecorationLine: 'underline', marginBottom: 7 }}>Upload Image</h6>
                            <h4>JPEG or PNG less than 5MB</h4>
                        </div>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit(saveComment)}>
                <div className='row align-items-end'>
                    <div className='col-9'>
                        <div className="form-group">
                            <label className="form-label" htmlFor="comment">Add Comment</label>
                            <Controller
                                name="comment"
                                control={control}
                                render={({ field }) => (
                                    <input type="text" className={`form-control ${errors.comment ? 'is-invalid' : ''}`} id="comment" placeholder="Add Comment" {...field} />
                                )}
                            />
                            {errors?.comment && (
                                <div className="invalid-feedback">{errors?.comment?.message}</div>
                            )}
                        </div>
                    </div>
                    <div className='col-3'>
                        <button className='btn-primary w-100' type='submit'>Add Comment</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default DocumentsAml
