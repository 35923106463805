import React from 'react'
import './FooterBox.scss'

function FooterBox({footerText}) {
    return (
        <div className='footerbox-css align-content-start '>
            <p className='footer-text'>{footerText}</p>
            <div className='headerline w-100'/>
        </div>
    )
}

export default FooterBox