import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    isLoading: false,
    error: null,
    billingList: { rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200 },
    sendPayBalanceOtpData : null,
    invoiceDetails: {},
    entityDetails: {},
    entityInvoiceList: { rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200 },
}

export const fetchAllEntityBillingList = createAsyncThunk("viewClientBilling/fetchAllEntityBillingList", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchAllEntityBillingList + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const sendPayBalanceOtp = createAsyncThunk("viewClientBilling/sendPayBalanceOtp", async (payload) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.sendPayBalanceOtp,
            data: payload
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const verifyPayBalanceOtp = createAsyncThunk("viewClientBilling/verifyPayBalanceOtp", async (payload) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.verifyPayBalanceOtp,
            data: payload
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchInvoiceDetails = createAsyncThunk("viewClientBilling/fetchInvoiceDetail", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchInvoiceDetail + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const getEntityDetails = createAsyncThunk("entity/getEntityDetails", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getEntityDetails + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const entityPreviousInvoiceList = createAsyncThunk("invoice/entityPreviousInvoiceList", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.entityPreviousInvoiceList + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

const viewClientBillingSlice = createSlice({
    name: "viewClientBilling",
    initialState,
    reducers: {
        resetData(state, action) {
            state.invoiceDetails = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllEntityBillingList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.billingList = payload.data;
            })
            .addCase(sendPayBalanceOtp.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.sendPayBalanceOtpData = payload.data;
            })
            .addCase(verifyPayBalanceOtp.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(fetchInvoiceDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.invoiceDetails = payload?.data;
            })
            .addCase(entityPreviousInvoiceList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.entityInvoiceList = payload?.data;
            })
            .addCase(getEntityDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.entityDetails = payload?.data;
            })
            .addMatcher(isAnyOf(fetchAllEntityBillingList.pending, fetchInvoiceDetails.pending, sendPayBalanceOtp.pending, verifyPayBalanceOtp.pending,entityPreviousInvoiceList.pending,getEntityDetails.pending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(fetchAllEntityBillingList.rejected,sendPayBalanceOtp.rejected, verifyPayBalanceOtp.rejected, fetchInvoiceDetails.rejected,entityPreviousInvoiceList.rejected, getEntityDetails.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again ";
            })
    }
});
const { resetData } = viewClientBillingSlice.actions;
const viewClientBillingReducer = viewClientBillingSlice.reducer;
export { resetData, viewClientBillingReducer };
export default viewClientBillingReducer;