import React, { useEffect, useState } from 'react'
import { Menu, MenuItem, Sidebar, SubMenu, sidebarClasses } from 'react-pro-sidebar';
import { RiHome5Line } from "react-icons/ri";
import { FiArrowLeftCircle, FiArrowRightCircle, FiSettings, FiUser, FiUserCheck, FiUserPlus, FiUsers } from "react-icons/fi";
import { BsCurrencyRupee, BsFileEarmarkCheck, BsJournalCheck } from "react-icons/bs";
import { BrowserRouter, Routes, Route, useLocation, useNavigate, Link } from "react-router-dom";
import { routes } from '../../utils/routes';
import { IoPricetagOutline } from "react-icons/io5";
import './Sidebar.scss'
import { decodeToken, useJwt } from "react-jwt";
import SidebarIcon from '../../assets/images/menu-btn.png'

function NewSidebar(props) {
    const location = useLocation()
    const pathArray = window.location.href.split('/');
    const parentMenu = pathArray[3];
    const [menuCollapse, setMenuCollapse] = useState(false)
    const [showSidebar, setShowSidebar] = useState(true)
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);

    const toggle = () => setMenuCollapse(!menuCollapse);
    
    useEffect(() => {
        if (parentMenu && parentMenu == 'pricing') {
            setShowSidebar(false);
        } else {
            setShowSidebar(true);
        }
    }, [parentMenu]);
    return (
        <>
            {showSidebar &&
                <div className={menuCollapse ? 'col-1 leftapp' : 'col-2 leftapp'}>
                    <Sidebar
                        collapsed={menuCollapse}
                        backgroundColor="#3D73DD"
                        collapsedWidth="4.5rem"
                        breakPoint='md'
                        style={{
                            position: '-webkit-sticky',
                            position: 'sticky',
                            top: 64,
                            padding: '0rem',
                            margin: '0rem',
                            overflow: 'hidden',
                            border: 0,
                            zIndex: 500
                        }} className="app">
                        <div className='d-flex flex-column justify-content-between position-relative'>
                            <div onClick={toggle}>
                                <img src={SidebarIcon} width={34} className={menuCollapse ? 'sidebar-icon-css-closed' : 'sidebar-icon-css'} />
                            </div>
                            <Menu className='sidebar-height' style={{ height: window.innerWidth - 900, overflowY: 'scroll' }} menuItemStyles={{
                                button: ({ level, active }) => {
                                    if (level === 0 || level === 1) {
                                        return {
                                            backgroundColor: active ? '#2D62C9' : '#3D73DD',
                                            color: active ? 'white' : 'white',
                                            "&:hover": {
                                                backgroundColor: '#2D62C9',
                                                color: 'white',
                                            }
                                        }
                                    }
                                },
                            }} >
                                <MenuItem
                                    component={<Link to="/" className="link" />}
                                    icon={<RiHome5Line size={22} />}
                                    active={location.pathname === '/' ? true : false}
                                    style={{ display: menuCollapse ? 'grid' : 'flex', justifyItems: menuCollapse ? 'center' : null }}
                                >
                                    {menuCollapse ? null : " Dashboard"}

                                </MenuItem>
                                { (decodedToken?.roles?.permissions?.view_onboard_profile_menu || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
                                    <SubMenu icon={<FiUserPlus size={22} />} label={menuCollapse ? null : "Onboard Profile "} style={{ display: menuCollapse ? 'grid' : 'flex', justifyItems: menuCollapse ? 'center' : null }} >
                                        <MenuItem component={<Link to="/create_profile" className="link" />} active={location.pathname === '/create_profile' || parentMenu === 'profile' ? true : false}>Create Profile</MenuItem>
                                        {/* <MenuItem >Pending ID Verification</MenuItem>
                                        <MenuItem >Pending KYC Profiles</MenuItem> */}
                                    </SubMenu>
                                }
                                {/* <MenuItem component={<Link to="/aml_check" className="link" />} active={location.pathname === '/aml_check' || parentMenu === 'aml' ? true : false} icon={<BsJournalCheck size={22} />} style={{ display: menuCollapse ? 'grid' : 'flex', justifyItems: menuCollapse ? 'center' : null }} >
                                    {menuCollapse ? null : "AML Check"}
                                </MenuItem> */}


                                {/* <MenuItem component={<Link to="/company_check" className="link" />} active={location.pathname === '/company_check' || parentMenu === 'company' ? true : false} icon={<BsFileEarmarkCheck size={22} />} style={{ display: menuCollapse ? 'grid' : 'flex', justifyItems: menuCollapse ? 'center' : null }} >
                                    {menuCollapse ? null : "Company Check "}
                                </MenuItem> */}

                                {(decodedToken?.roles?.permissions?.view_individual_screening_list || decodedToken?.roles?.permissions?.view_corporate_screening || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) && <SubMenu icon={<FiUserCheck size={22} />} label={menuCollapse ? null : "Screening"} style={{ display: menuCollapse ? 'grid' : 'flex', justifyItems: menuCollapse ? 'center' : null }}>

                                    {(decodedToken?.roles?.permissions?.view_individual_screening_list || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) && <MenuItem
                                        component={<Link to="/individual_screening" className="link" />} active={location.pathname === '/individual_screening' || parentMenu === 'individual_screening' ? true : false}
                                    >Individual Screening</MenuItem>
                                    }

                                    {/* {(decodedToken?.roles?.permissions?.view_corporate_screening || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) && <MenuItem component={<Link to="/corporate_screening" className="link" />} active={location.pathname === '/corporate_screening' || parentMenu === 'corporate_screening' ? true : false}>Screening Corporate</MenuItem>} */}


                                </SubMenu>
                                }
                               {(decodedToken?.isSuperAdmin || (decodedToken?.isOomero && (decodedToken?.roles?.permissions?.fetch_fx_rate || decodedToken?.roles?.permissions?.view_tax))) && <SubMenu icon={<FiSettings size={22} />} label={menuCollapse ? null : "Configuration"} style={{ display: menuCollapse ? 'grid' : 'flex', justifyItems: menuCollapse ? 'center' : null  }} >

                                    {(decodedToken?.isSuperAdmin || (decodedToken?.isOomero && decodedToken?.roles?.permissions?.fetch_fx_rate)) && <>
                                        <MenuItem component={<Link to="/fxrates" className="link" />} active={location.pathname === '/fxrates' || parentMenu === 'rates' ? true : false}>FX Rates</MenuItem>
                                    </>
                                    }
                                    {(decodedToken?.isSuperAdmin || (decodedToken?.isOomero && decodedToken?.roles?.permissions?.view_tax)) && 
                                        <MenuItem component={<Link to="/tax" className="link" />} active={location.pathname === '/tax' || parentMenu === 'tax' ? true : false}>Tax Rates</MenuItem>
                                    }
                                </SubMenu>}
                                {/* <SubMenu icon={<FiUsers size={22} />} label={menuCollapse ? null : "Manage Clients"} style={{ display: menuCollapse ? 'grid' : 'flex', justifyItems: menuCollapse ? 'center' : null }} >
                                    <MenuItem
                                        component={<Link to="/view_screening" className="link" />} active={location.pathname === '/view_screening' || parentMenu === 'view_screening' ? true : false}
                                    >View Details</MenuItem>
                                </SubMenu> */}
                                {(decodedToken?.roles?.permissions?.view_oomero_user || decodedToken?.roles?.permissions?.view_client_user || decodedToken?.roles?.permissions?.view_user_role || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
                                <SubMenu icon={<FiUserPlus size={22} />} label={menuCollapse ? null : "Manage User "} style={{ display: menuCollapse ? 'grid' : 'flex', justifyItems: menuCollapse ? 'center' : null }}>

                                    {(decodedToken?.roles?.permissions?.view_oomero_user || decodedToken?.roles?.permissions?.view_client_user || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
                                    <MenuItem component={<Link to="/manage_users" className="link" />} active={location.pathname === '/manage_users' || parentMenu === 'users' ? true : false}>Manage User</MenuItem>
                                    }

                                    {(decodedToken?.roles?.permissions?.view_user_role || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) && <MenuItem component={<Link to="/user_roles" className="link" />} active={location.pathname === '/user_roles' || parentMenu === 'user' ? true : false}>User Roles</MenuItem>}
                                </SubMenu>
                                }

                                {(decodedToken?.isSuperAdmin || (decodedToken?.isOomero && decodedToken?.roles?.permissions?.view_client_billing_menu)) && <MenuItem component={<Link to="/client_biling" className="link" />} icon={<BsCurrencyRupee size={22} />} active={location.pathname === '/client_biling' || parentMenu === 'billing' ? true : false} style={{ display: menuCollapse ? 'grid' : 'flex', justifyItems: menuCollapse ? 'center' : null }}>{menuCollapse ? null : "View Billing"}</MenuItem>}

                                {(decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.view_onboarded_individual_profiles) && <MenuItem component={<Link to="/view_individual_user" className="link" />} icon={<FiUsers size={22} />} active={location.pathname === '/view_individual_user' || parentMenu === 'view_individual_user' ? true : false} style={{ display: menuCollapse ? 'grid' : 'flex', justifyItems: menuCollapse ? 'center' : null }}>{menuCollapse ? null : "Onboarded Profiles"}</MenuItem>}

                                {(decodedToken?.isSuperAdmin || (decodedToken?.isOomero && decodedToken?.roles?.permissions?.view_package)) &&
                                    <MenuItem icon={<FiUsers size={22} />} component={<Link to="/manage_packages" className="link" />} active={location.pathname === '/manage_packages' || parentMenu === 'manage_packages' ? true : false} i style={{ display: menuCollapse ? 'grid' : 'flex', justifyItems: menuCollapse ? 'center' : null }} >
                                        {menuCollapse ? null : "Manage Packages"}
                                    </MenuItem>

                                /* { decodedToken?.isOomero &&
                                    <MenuItem icon={<FiUsers size={22}/>} component={<Link to="/manage_packages"  className="link" />} active={location.pathname === '/manage_packages' || parentMenu === 'manage_packages' ? true : false} style={{ display: menuCollapse ? 'grid' : 'flex', justifyItems: menuCollapse ? 'center' : null }} >
                                        {menuCollapse ? null : "View Customer Details"}
                                    </MenuItem>
                                } *


                                {/* {(decodedToken?.roles?.permissions?.view_email_master || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) && <MenuItem component={<Link to="/" className="link" />} active={location.pathname === '/' || parentMenu === 'email' ? true : false}>Email Masters</MenuItem>
                                } */}

                                {/* <SubMenu icon={<IoPricetagOutline size={22} />} label={menuCollapse ? null : "Pricing"} style={{ display: menuCollapse ? 'grid' : 'flex', justifyItems: menuCollapse ? 'center' : null }} >
                                    <MenuItem component={<Link to="/pricing" className="link" />} active={location.pathname === '/pricing' || parentMenu === 'pricing' ? true : false}>Pricing</MenuItem>
                                    { decodedToken?.isOomero &&
                                        <MenuItem component={<Link to="/add_pricing" className="link" />} active={location.pathname === '/add_pricing' || parentMenu === 'add_pricing' ? true : false}>Add Pricing</MenuItem>
                                    }
                                </SubMenu> */}
                                <MenuItem icon={<FiUser size={22} />} component={<Link to="/aml-check" className="link" />} active={location.pathname === '/aml-check' ? true : false} i style={{ display: menuCollapse ? 'grid' : 'flex', justifyItems: menuCollapse ? 'center' : null }} >
                                    {menuCollapse ? null : "AML Check"}
                                </MenuItem>
                            </Menu>
                        </div>
                    </Sidebar>
                </div>
            }
            <div className={menuCollapse ? 'col-11 rightapp' : showSidebar ? 'col-10 rightapp' : 'col-12 rightapp'}>
                <section>
                    {props?.component && <props.component />}
                </section>
            </div>
        </>
    )

}

export default NewSidebar
