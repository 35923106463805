import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import { decodeToken } from "react-jwt";
import Modal from 'react-bootstrap/Modal';

import { resetPlanList, getAllPlanList, deactivatePackage, updatePackageData, resetPlanDetails } from './planSlice';
import Loader from '../../components/Loader/Loader';
import TablePagination from '../../components/Pagination';

import SearchIcon from '../../assets/images/search_icon.png'
import EditIcon from '../../assets/images/edit_icon.png'
import PrintIcon from '../../assets/images/Print_Icon.png'
import ViewIcon from '../../assets/images/view_icon.png'
import DollarIcon from '../../assets/images/dollar_icon.png'
import EuroIcon from '../../assets/images/euro_icon.png'
import RupeeIcon from '../../assets/images/rupee_icon.png'
import DeleteIcon from '../../assets/images/delete_icon.png'
import CancelIcon from '../../assets/images/Cancel_icon.png'

function ManagePackage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, packageList } = useSelector(state => state.planSliceReducer);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPge, setPostsPerPage] = useState(10);
    const [search, setSearch] = useState(null);
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);
    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const [showViewButton, setShowViewButton] = useState(false);
    const [showEditButton, setShowEditButton] = useState(false);
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [showPrintButton, setShowPrintButton] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedPackageId, setSelectedPackageId] = useState(null);
    const [packageStatus, setPackageStatus] = useState(null);

    const handleClose = () => {
        setSelectedPackageId(null);
        setShow(false);
        setPackageStatus(null);
    }

    useEffect(() => {
        dispatch(getAllPlanList(`?page=${currentPage}&limit=${postsPerPge}`)).unwrap().catch((error) => {
            toast.error(error?.message);
        });
        dispatch(resetPlanList());
    }, [currentPage]);

    const routeChange = () => {
        dispatch(resetPlanDetails());
        let path = `/manage_packages/add_new_package`;
        navigate(path);
    }

    useEffect(() => {
        if (decodedToken) {
            if (decodedToken.isSuperAdmin) {
                setShowChangeStatus(true);
                setShowEditButton(true);
                 setShowDeleteButton(true);
                setShowPrintButton(true);
                setShowViewButton(true);
            } else if (decodedToken?.isOomero || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.deactivate_package)) {
                setShowChangeStatus(true);
            } else if (decodedToken?.isOomero || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.edit_package)) {
                setShowEditButton(true);
            } else if (decodedToken?.isOomero || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.delete_package)) {
                 setShowDeleteButton(true);
            } else if (decodedToken?.isOomero || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.print_package)) {
                setShowPrintButton(true);
            } else if (decodedToken?.isOomero || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.view_package)) {
                setShowViewButton(true);
            } else {
                setShowChangeStatus(false);
                setShowEditButton(false);
                setShowDeleteButton(false);
                setShowPrintButton(false);
                setShowViewButton(false);
            }
        }
    }, [decodedToken])

    const handlePagination = (event, value) => {
        setCurrentPage(value);
    }
    const handleChangeStatus = (status, package_id) => {
        setSelectedPackageId(package_id);
        setPackageStatus(status);
        setShow(true);
    }
    const handleOnDeactivateConfirmation = () => {
        if (selectedPackageId && packageStatus) {
            setShow(true);
            dispatch(deactivatePackage(selectedPackageId)).unwrap().then((res) => {
                handleClose();
                dispatch(getAllPlanList(`?page=${currentPage}&limit=${postsPerPge}`)).unwrap().catch((error) => {
                    toast.error(error?.message);
                });
                dispatch(resetPlanList());
            }).catch((error) => {
                toast.error(error?.message);
            });
        }
            if (selectedPackageId && !packageStatus) {
                const requestBody = { packageId: selectedPackageId, isActive: !packageStatus };
                dispatch(updatePackageData(requestBody)).unwrap().then((res) => {
                    handleClose();
                    dispatch(getAllPlanList(`?page=${currentPage}&limit=${postsPerPge}`)).unwrap().catch((error) => {
                        toast.error(error?.message);
                    });
                    dispatch(resetPlanList());
                }).catch((error) => {
                    toast.error(error?.message);
                });
            }
    }



    const handleViewClick = (packageid) => {
        let path=`/manage_packages/view_package?${packageid}`
        navigate(path,{state:{packageid, isView:true}})
    };

    const handleEditClick = (packageid) => {
        let path=`/manage_packages/update_package?${packageid}`
        navigate(path,{state:{packageid, isEdit:true}})
    };

    const handlePrintClick = () => {
    };

    useEffect(() => {
        if (search !== null) {
            dispatch(getAllPlanList(`?page=${currentPage}&limit=${postsPerPge}&search=${search}`)).unwrap().catch((error) => {
                toast.error(error?.message);
            });
            dispatch(resetPlanList());
        }
    }, [search])


    return (
        <>
            <div className='grey-background vh-101'>
                {isLoading && <Loader />}
                <div className='white-background p-3 mb-3'>
                    <h1 className='text-blue px-2 fw-normal'>Packages</h1>
                    <hr />
                    <div className='row'>
                        <div className='col-lg-9 col-md-9'>
                            <div className="form-group position-relative">
                                <input type="text" className="form-control" placeholder='Search' onKeyUp={(event) => setSearch(event.target.value)}></input>
                                <div className="position-absolute" style={{ right: 20, top: '20%' }}>
                                    <img src={SearchIcon} style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 justify-content-end'>
                            <button onClick={routeChange} className="btn btn-primary w-100" style={{ fontSize: 14 }}>Add New Package</button>
                        </div>
                    </div>
                </div>
                <div className='col-lg-12 col-md-12 mt-3'>
                    <div className='custom-table'>
                        <div className='custom-table-head'>
                            <div className='row'>
                                <div className='col'>
                                    <h6 className='text-blue'>Country</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue'>Package Name</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue'>Symbol</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue'>Last Updated Date</h6>
                                </div>
                                <div className='col-1'>
                                    <h6 className='text-blue'>Status</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-blue text-center'>Action</h6>
                                </div>
                            </div>
                        </div>
                        {packageList && packageList.rows && packageList.rows.length > 0 &&
                            packageList.rows.map((data, index) => (
                                <div className='custom-table-body' key={'manage' + index}>
                                    <div key={index} className='row align-items-center'>
                                        <div className='col'>
                                            <h6 className='text-black'>{data?.country}</h6>
                                        </div>
                                        <div className='col'>
                                            <h6 className='text-black'>{data?.packageName}</h6>
                                        </div>
                                        <div className='col'>
                                            <div className='d-flex justify-content-start'>
                                                {data?.currency}
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <h6 className='text-black'>{moment(data?.lastUpdated).format("DD/MM/YYYY")}</h6>
                                        </div>
                                        <div className='col-1'>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={data?.status} onChange={() => handleChangeStatus(data?.status, data?.packageid)} />
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                {showViewButton &&
                                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} onClick={()=>handleViewClick(data?.packageid)} />
                                                }
                                                {showEditButton &&
                                                    <img src={EditIcon} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} onClick={()=>handleEditClick(data?.packageid)} />
                                                }
                                                {showPrintButton &&
                                                    <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} onClick={handlePrintClick} />
                                                }
                                                {showDeleteButton &&
                                                    <img
                                                        src={DeleteIcon}
                                                        style={{
                                                            width: 20,
                                                            height: 15,
                                                            objectFit: 'contain',
                                                            cursor: data?.status ? 'pointer' : 'not-allowed', 
                                                            opacity: data?.status ? 1 : 0.5
                                                        }}
                                                        onClick={() => { if (data?.status) {handleChangeStatus(data.status, data.packageid);}}} alt="Delete"
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                {packageList && packageList?.rows?.length > 0 &&
                    <TablePagination totalPages={parseInt(packageList?.totalPages)} currentPage={currentPage} color="primary" handlePagination={handlePagination} />
                }
            </div>
            <Modal className='modal-lg' show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <h5 className="modal-title text-center text-blue mt-3"><b>Confirm {packageStatus ? 'Deactivation' : 'Activation'}</b></h5>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <hr />
                <Modal.Body style={{ paddingLeft: 100, paddingRight: 100 }}>
                    <h6 className='text-blue text-center'>Are you sure, you want to {packageStatus ? 'deactivate the Package? Once, deactivated the package will not be able to use' : 'activate the Package'} </h6>
                    {/* <form>
                        <div className="form-group mt-3 p-2">
                            <div className='manage-user-textarea'>
                                <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Comments" rows="3" onKeyUp={(event)=>setComments(event.target.value)}/>
                            </div>
                        </div>
                    </form> */}
                </Modal.Body>
                <Modal.Footer >
                    <div className='d-flex justify-content-between'>
                        <button onClick={handleClose} type="button" className="btn-secondary mb-3" style={{ marginRight: 20, width: 100 }}>No</button>
                        <button onClick={handleOnDeactivateConfirmation} type="button" className="btn-primary mb-3" style={{ width: 100 }}>Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ManagePackage;