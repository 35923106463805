import React, { useState } from 'react'
import TopResults from '../../../components/Aml/TopResults'
import ResultCard from '../../../components/Aml/ResultCard'
import ScreeningActionIcon from '../../../assets/images/screeing_action.png'
import { useNavigate } from 'react-router-dom';
import ResultsTopCard from '../../../components/Aml/ResultsTopCard';
import ResultsTab from '../../../components/Aml/ResultsTab';
import MasterAmlComments from '../../../components/Aml/MasterAmlComments';

function AmlDetails() {
    const [singleDetails, setSingleDetails] = useState(false)
    const [masterComments, setMasterComments] = useState(false)
    return (
        <div className='mt-3 pb-4'>
            {!singleDetails &&
                <>
                    <TopResults onClickComments={() => { setMasterComments(!masterComments) }} titleofMasterComments={masterComments ? `Go Back` : `Master Comments`} />
                    {!masterComments &&
                        <>
                            <div className='row my-3'>
                                {[
                                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10
                                ].map((item) =>
                                    <div className='col-lg-4'>
                                        <ResultCard onClick={() => setSingleDetails(true)} />
                                    </div>
                                )}
                            </div>
                            <div className='d-flex align-items-center justify-content-end'>
                                <span class="badge bg-primary-badge">1</span>
                                <span class="badge">2</span>
                                <span class="badge">3</span>
                                <span class="badge">4</span>
                                <span class="badge"><i class="fa fa-chevron-right"></i></span>
                            </div>

                            <div className='white-background p-3 mt-4'>
                                <div className='d-flex align-items-center'>
                                    <img src={ScreeningActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                                    <h1 className='text-ambed fw-normal'>Screening Actions</h1>
                                </div>
                                <hr />
                                <div className="d-flex mb-2">
                                    <div style={{ marginRight: 30 }}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <label className="form-check-label" for="flexRadioDefault1">Open</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                            <label className="form-check-label" for="flexRadioDefault1">Ghost</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row align-items-end'>
                                    <div className='col-9'>
                                        <div className="form-group">
                                            <label className="form-label" for="exampleInputEmail1">Add Comment</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Add Comment" />
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <button className='btn-grey w-100'>Add Comment</button>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-lg-4'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1">
                                                Whitelist this customer for all future TXNs
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1">
                                                Blacklist this customer for all future TXNs
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row my-3'>
                                    <div className='col-lg-6'>
                                        <div className='row align-items-end'>
                                            <div className='col-lg-7'>
                                                <div className="form-group">
                                                    <label className="form-label" for="exampleFormControlSelect1">Upload Document</label>
                                                    <select id="statusDropdown" className="form-control form-select">
                                                        <option>PNG</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-5'>
                                                <button className='btn-primary w-100'>Choose a File</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='row align-items-end'>
                                            <div className='col-lg-7'>
                                                <div className="form-group">
                                                    <label className="form-label" for="exampleFormControlSelect1">Request Document</label>
                                                    <select id="statusDropdown" className="form-control form-select">
                                                        <option>PNG</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-5'>
                                                <button className='btn-primary w-100'>Choose a File</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            }
            {
                singleDetails &&
                <>
                    <ResultsTopCard />
                    <div className='mt-4'>
                        <ResultsTab />
                    </div></>
            }
            {
                masterComments &&
                <>
                    <div className='mt-4'>
                        <MasterAmlComments />
                    </div></>
            }
        </div>
    )
}

export default AmlDetails
