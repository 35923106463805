import React from 'react'
import OnboardClientBar from '../../../../components/OnboardClientBar/OnboardClientBar'
import { useNavigate } from 'react-router-dom';


function AddCompanyShareholder() {
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/profile/corporate_company_shareholder_list`;
        navigate(path);
    }
    return (
        <div className='grey-background'>
            <OnboardClientBar
                mainTitle={`Onboard Business Client `}
                title1={`COMPANY DETAILS`}
                title2={`COMPANY INFORMATION`}
                title3={`DIRECTOR LIST`}
                title4={`SHAREHOLDER LIST`}
                backgroundColor1={'#037AD0'}
                backgroundColor2={'#037AD0'}
                backgroundColor3={'#037AD0'}
                backgroundColor4={'#037AD0'}
            />
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='white-background p-3 mt-4'>
                        <div className='d-flex align-items-center'>
                            <h1 className='text-blue fw-normal'>Company Shareholder Representative Details</h1>
                        </div>
                        <hr />
                        <div className='form'>
                            <form>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Name</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" />
                                        </div>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Address</label>
                                            <textarea type="text" className="form-control" id="exampleInputEmail1" rows="5" />
                                        </div>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Mobile Number</label>
                                            <div className='row'>
                                                <div className='col-lg-2 col-md-2'>
                                                    <select id="statusDropdown"
                                                        className="form-control form-select">
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                    </select>
                                                </div>
                                                <div className='col-lg-10 col-md-10'>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter number" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Email</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" />
                                        </div>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleFormControlSelect1">Designation</label>
                                            <select id="statusDropdown" className="form-control form-select">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Shareholding Percentage</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" />
                                        </div>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Landline Number</label>
                                            <div className='row'>
                                                <div className='col-lg-2 col-md-2'>
                                                    <select id="statusDropdown"
                                                        className="form-control form-select">
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                    </select>
                                                </div>
                                                <div className='col-lg-10 col-md-10'>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter number" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Comment</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end my-4'>
                <div className='d-flex align-items-center'>
                    <button onClick={ routeChange } className='btn-primary'>Save & Next</button>
                </div>
            </div>
        </div>
    )
}

export default AddCompanyShareholder
