import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DocumnetIcon from '../../assets/images/documnet_icon.png';
import TablePagination from '../Pagination';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import TableWithPagination from '../TablePagination';

function AdverseMedia({ globalSearchDetail, adverseMedias, saveAdverseMediaComments, fetchAdverseMedia }) {
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPge, setPostsPerPage] = useState(10);

    const [selectedIds, setSelectedIds] = useState(new Set());

    useEffect(() => {
        if (globalSearchDetail?.global_search_results_id) {
            fetchAdverseMedia(globalSearchDetail.global_search_results_id, currentPage + 1, postsPerPge);
        }
    }, [globalSearchDetail?.global_search_results_id, currentPage]);


    const handlePagination = (event, value) => {
        setCurrentPage(value); // Update currentPage when paginating
    };

    const handleChangeRowsPerPage = (event) => {
        setPostsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
      };

    const handleCheckboxChange = (id) => {
        // Toggle selection for individual checkboxes
        const idAsInt = parseInt(id, 10);
        const newSelectedIds = new Set(selectedIds);
        if (newSelectedIds.has(idAsInt)) {
            newSelectedIds.delete(idAsInt);
        } else {
            newSelectedIds.add(idAsInt);
        }
        setSelectedIds(newSelectedIds);
    };

    const commentSchema = yup.object().shape({
        comment: yup.string().required('Comment is required'),
    });

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(commentSchema),
    });

    const saveComment = (formData) => {
        const requestData = {
            global_search_result_id: globalSearchDetail?.global_search_results_id,
            comment: formData.comment,
            adverse_media_ids: Array.from(selectedIds),
            all: "no"
        };

        saveAdverseMediaComments(requestData);

        setSelectedIds(new Set());
        reset({ comment: "" });
    };

    return (
        <div>
            {/* Render adverseMedias rows */}
            {adverseMedias?.rows?.map((item, index) => (
                <div key={index} className='d-flex align-items-start justify-content-between mt-3'>
                    {/* Document Icon */}
                    <img src={DocumnetIcon} style={{ width: 49, objectFit: 'contain' }} alt="Document Icon" />
                    {/* Adverse Media Details */}
                    <div style={{ width: '85%' }}>
                        <ol className='text-blue fw-bold m-0' style={{ paddingLeft: '1rem' }}>
                            <li>
                                <h2 className='text-blue' style={{ cursor: 'pointer' }}>
                                    <a href={item?.url} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
                                        {item.id}. {item?.title}
                                    </a>
                                </h2>
                            </li>
                        </ol>
                        <h5>{item?.description}</h5>
                    </div>
                    {/* Date and Checkbox */}
                    <div className='d-flex flex-column align-items-end justify-content-between'>
                        {/* {item.selected && (
                            <div className='relevant-btn mb-4'>
                                Relevant
                            </div>
                        )} */}
                        <div className="form-check">
                            <input
                                className="form-check-input mt-4"
                                type="checkbox"
                                checked={selectedIds.has(parseInt(item.id, 10)) || item.selected}
                                onChange={() => handleCheckboxChange(item.id)}
                            />
                        </div>
                        <p style={{ fontSize: 12, marginBottom: 0 }}>{moment(item?.date).format('Do MMM YYYY')}</p>
                    </div>
                </div>
            ))}
            {/* Render pagination if adverseMedias and rows exist */}
            {adverseMedias && adverseMedias?.rows?.length > 0 && (
                <TableWithPagination totalPages={parseInt(adverseMedias?.totalPages)} currentPage={currentPage} color="primary" handlePagination={handlePagination} rowsPerPage={postsPerPge} handleChangeRowsPerPage={handleChangeRowsPerPage}/>
            )}
            {/* Form to add comments */}
            <form onSubmit={handleSubmit(saveComment)}>
                <div className='row align-items-end'>
                    <div className='col-9'>
                        <div className="form-group">
                            <label className="form-label" htmlFor="comment">Add Comment</label>
                            <Controller
                                name="comment"
                                control={control}
                                render={({ field }) => (
                                    <input type="text" className={`form-control ${errors.comment ? 'is-invalid' : ''}`} id="comment" placeholder="Add Comment" {...field} />
                                )}
                            />
                            {errors?.comment && (
                                <div className="invalid-feedback">{errors?.comment?.message}</div>
                            )}
                        </div>
                    </div>
                    <div className='col-3'>
                        <button className='btn-primary w-100' type='submit'>Add Comment</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default AdverseMedia;
