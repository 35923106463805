import React from 'react'
import user from '../../assets/images/user.png'
import './Card.scss';

function Card(props) {
  const { image, backgroundColor, title, description, onClick,cardBackgroundColor } = props;
  return (
    <div className='card_container' style={{ backgroundColor:cardBackgroundColor }}  onClick={onClick}>
        <div className='card_img' style={{ backgroundColor }}>
            <img src={image}  
            style={{ width: "70px", height: "70px"}} 
            alt="User" />
        </div>
        <div className='card_details'>
            <h1>{title}</h1>
            <p>{description}</p>
        </div>
    </div>
  )
}

export default Card