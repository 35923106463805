import React, { useEffect } from 'react';
import DollarIcon from '../../assets/images/dollar_icon.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchFxRateHistoryList } from './fxRatesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import moment from 'moment';
import Loader from '../../components/Loader/Loader';
import BackIcon from '../../assets/images/back-btn-arrow.png';

function HistoryRate() {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, fxRateHistoryList } = useSelector(state => state.fxRatesReducer);

    useEffect(() => {
        dispatch(fetchFxRateHistoryList({ baseCurrencyId: state.baseCurrencyId, currencyId: state.currencyId })).unwrap().catch((error) => {
            toast.error(error?.message);
        })
    }, []);

    const navigateToPrevious = () => {
        navigate(-1);
    }

    return (
        <>
            <div className='grey-background'>
                {isLoading && <Loader/>}
                <button onClick={navigateToPrevious} className='back-button text-blue'><img src={BackIcon} style={{width:8,marginBottom:2,marginRight:4,objectFit:'contain'}}/> Back</button>
                <div className='white-background p-3 my-3'>
                    <h1 className='text-blue px-2 fw-normal'>USD History Rate</h1>
                    <hr />
                    <div className='d-flex align-items-center justify-content-start'>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex align-items-center me-5'>
                                <h1 className='text-blue px-2 fw-normal'>Base Country :</h1>
                                <h1 className='text-black px-2 fw-normal'>{state?.baseCurrency?.country_name}</h1>
                            </div>
                            <div className='d-flex align-items-center me-5'>
                                <h1 className='text-blue px-2 fw-normal'>Base Currency (3 digit iso):</h1>
                                <h1 className='text-black px-2 fw-normal'>{state?.baseCurrency?.iso_codes_3_digit}</h1>
                            </div>
                            <div className='d-flex align-items-center me-5'>
                                <h1 className='text-blue px-2 fw-normal'>Base Symbol :</h1>
                                {/* <img
                                    src={DollarIcon}
                                    style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer' }}
                                /> */}
                                {state?.baseCurrency?.currency_symbol}
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-start mt-2'>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex align-items-center me-5'>
                                <h1 className='text-blue px-2 fw-normal'>Country :</h1>
                                <h1 className='text-black px-2 fw-normal'>{state?.currency?.country_name}</h1>
                            </div>
                            <div className='d-flex align-items-center me-5'>
                                <h1 className='text-blue px-2 fw-normal'>Currency (3 digit iso) :</h1>
                                <h1 className='text-black px-2 fw-normal'>{state?.currency?.iso_codes_3_digit}</h1>
                            </div>
                            <div className='d-flex align-items-center me-5'>
                                <h1 className='text-blue px-2 fw-normal'>Symbol :</h1>
                                <h1 className='text-black px-2 fw-normal'>{state?.currency?.currency_symbol}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                        <div className='custom-table'>
                            <div className='custom-table-head'>
                                <div className='row'>
                                    <div className='col'>
                                        <h6 className='text-blue'>Last Updated Date</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-blue'>Exchange Rate</h6>
                                    </div>
                                </div>
                            </div>
                            {fxRateHistoryList.map((rate, index) => (
                                <div className='custom-table-body' key={index}>
                                    <div key={index} className='row align-items-center'>
                                        <div className='col'>
                                            <h6 className='text-black'>{moment(rate.created_at).format('DD/MM/YYYY')}</h6>
                                        </div>
                                        <div className='col'>
                                            <h6 className='text-black'>{rate.new_exchange_rate}</h6>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HistoryRate;
