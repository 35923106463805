import React, { useState } from 'react'
import ViewIcon from '../../assets/images/view_icon.png'
import DownloadIconBlack from '../../assets/images/Download_icon_black.png'
import Modal from 'react-bootstrap/Modal'
import CancelIcon from '../../assets/images/Cancel_icon.png'
import { useNavigate } from 'react-router-dom';

function CompanyCheck() {
    const [show, setShow] = useState(false);
    const [showDirectorSearchResult, setShowDirectorSearchResult] = useState(false);
    const [showCompanyCheckSearchResult, setShowCompanyCheckSearchResult] = useState(false);
    const [selectedOption, setSelectedOption] = useState('companyCheck'); // Default to 'companyCheck'
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/company/previously_reports`;
        navigate(path);
    }
    const routeChange2 = () => {
        let path = `/company/previously_director_reports`;
        navigate(path);
    }
    const handleRadioChange = (option) => {
        setSelectedOption(option);
    };
    return (
        <div className='grey-background'>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <h1 className='text-blue px-2 fw-normal'>Select Check to Download the Report</h1>
                        <hr />
                        <div className="d-flex mb-2">
                            <div className="p-2" style={{ marginRight: 90 }}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="companyCheck"
                                        checked={selectedOption === 'companyCheck'}
                                        onChange={() => handleRadioChange('companyCheck')}
                                    />
                                    <label className="form-check-label" htmlFor="companyCheck">
                                        Company Check
                                    </label>
                                </div>
                            </div>
                            <div className="p-2">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="director"
                                        checked={selectedOption === 'director'}
                                        onChange={() => handleRadioChange('director')}
                                    />
                                    <label className="form-check-label" htmlFor="director">
                                        Director
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <div className="form-group">
                            <h1 className='text-blue px-2 fw-normal'>Select Service Provider</h1>
                            <hr />
                            <div className="d-flex mb-2">
                                <div className="p-2" style={{ marginRight: 90 }}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label className="form-check-label" for="flexRadioDefault1">Companies House (UK)</label>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label className="form-check-label" for="flexRadioDefault1">Credit Report</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='white-background p-4 mb-3 mt-3'>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <div>
                            {selectedOption === 'director' && (
                                <h1 className='text-blue px-2 fw-normal'>Director</h1>
                            )}
                            {selectedOption === 'companyCheck' && (
                                <h1 className='text-blue px-2 fw-normal'>Company Check</h1>
                            )}
                        </div>
                    </div>
                    <div >
                        <div className='d-flex'>
                            <h5 className='text-black px-2 fw-normal'><b>Note: </b></h5>
                            <h5 className='text-grey'>Kindly enter 1 or more fields to search for Company Check</h5>
                        </div>
                    </div>
                </div>
                <hr />
                {selectedOption === 'companyCheck' && (
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className="form-group">
                                <label className="form-label" for="exampleFormControlSelect1">Company Country </label>
                                <select id="statusDropdown" className="form-control form-select">
                                    {/* <option selected disabled value="">Oomero Company Billing</option> */}
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="form-group">
                                <label className="form-label" for="exampleInputEmail1">Company Reg. No</label>
                                <input type="text" className="form-control" placeholder='Enter Company Reg. No'></input>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="form-group">
                                <label className="form-label" for="exampleInputEmail1">Company Name</label>
                                <input type="text" className="form-control" placeholder='Enter Company Name'></input>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="form-group">
                                <label className="form-label" for="exampleInputEmail1">Oomero ID</label>
                                <input type="text" className="form-control"></input>
                            </div>
                        </div>
                        <div className="d-flex flex-row-reverse">
                            <button onClick={() => setShowCompanyCheckSearchResult(!showCompanyCheckSearchResult)} className="btn btn-primary mt-4">Search</button>
                        </div>
                    </div>
                )}
                {selectedOption === 'director' && (
                    <div>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <div className="form-group">
                                    <label className="form-label" for="exampleInputEmail1">Enter Name</label>
                                    <input type="text" className="form-control" placeholder='Enter Name'></input>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className="form-group">
                                    <label className="form-label" for="exampleFormControlSelect1">Country</label>
                                    <select id="statusDropdown" className="form-control form-select">
                                        <option selected disabled value="">Select Country</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row-reverse">
                            <button onClick={() => setShowDirectorSearchResult(!showDirectorSearchResult)} className="btn btn-primary mt-4">Search</button>
                        </div>
                    </div>
                )}

            </div>
            {selectedOption === 'companyCheck' && showCompanyCheckSearchResult && (
                <>
                    <div className='white-background p-3 mb-3'>
                        <h1 className='text-blue px-2 fw-normal'>Search Result</h1>
                    </div>
                    <div className='col-lg-12 col-md-12 mt-3'>
                        <div className='custom-table'>
                            <div className='custom-table-head'>
                                <div className='row'>
                                    <div className='col-1'>
                                        <h6 className='text-blue'>Select</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-blue'>Company Name</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-blue'>Company Reg No.</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-blue'>Company Country</h6>
                                    </div>
                                    <div className='col-2'>
                                        <h6 className='text-blue'>Oomero ID</h6>
                                    </div>
                                    <div className='col-1'>
                                        <h6 className='text-blue text-center' style={{ paddingRight: 10 }}>Report</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row align-items-center'>
                                    <div className='col-1'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>Avenues Payment Pvt Ltd</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>UYRUI70</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>Credit Safe</h6>
                                    </div>
                                    <div className='col-2'>
                                        <h6 className='text-black'>20</h6>
                                    </div>
                                    <div className='col-1'>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                            <img src={DownloadIconBlack} onClick={handleShow} style={{ width: 17, height: 19.75, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row align-items-center'>
                                    <div className='col-1'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>Avenues Pvt Ltd</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>UYRUI70</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>Credit Safe</h6>
                                    </div>
                                    <div className='col-2'>
                                        <h6 className='text-black'>25</h6>
                                    </div>
                                    <div className='col-1'>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                            <img src={DownloadIconBlack} onClick={handleShow} style={{ width: 17, height: 19.75, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row-reverse">
                        <button className="btn btn-primary mt-3 mb-1" onClick={routeChange}>Previous Searched Reports</button>
                    </div>
                </>
            )}
            {selectedOption === 'director' && showDirectorSearchResult && (
                <>
                    <div className='white-background p-3 mb-3'>
                        <h1 className='text-blue px-2 fw-normal'>Search Result</h1>
                    </div>
                    <div className='col-lg-12 col-md-12 mt-3'>
                        <div className='custom-table'>
                            <div className='custom-table-head'>
                                <div className='row'>
                                    <div className='col-1'>
                                        <h6 className='text-blue'>Select</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-blue'>Company Name</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-blue'>Company Reg No.</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-blue'>Company Country</h6>
                                    </div>
                                    <div className='col-2'>
                                        <h6 className='text-blue'>Oomero ID</h6>
                                    </div>
                                    <div className='col-1'>
                                        <h6 className='text-blue text-center' style={{ paddingRight: 10 }}>Report</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row align-items-center'>
                                    <div className='col-1'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>Avenues Payment Pvt Ltd</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>UYRUI70</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>Credit Safe</h6>
                                    </div>
                                    <div className='col-2'>
                                        <h6 className='text-black'>20</h6>
                                    </div>
                                    <div className='col-1'>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                            <img src={DownloadIconBlack} onClick={handleShow} style={{ width: 17, height: 19.75, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row align-items-center'>
                                    <div className='col-1'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>Avenues Pvt Ltd</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>UYRUI70</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>Credit Safe</h6>
                                    </div>
                                    <div className='col-2'>
                                        <h6 className='text-black'>25</h6>
                                    </div>
                                    <div className='col-1'>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                            <img src={DownloadIconBlack} onClick={handleShow} style={{ width: 17, height: 19.75, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row-reverse">
                        <button className="btn btn-primary mt-3 mb-1" onClick={routeChange2}>Previous Searched Reports</button>
                    </div>
                </>
            )}
            <Modal className='modal-xl' show={show} onHide={handleClose} centered>
                <Modal.Header className='p-4'>
                    <div>
                        <h5 className="modal-title text-center text-blue"><b>Confirmation</b></h5>
                    </div>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex text-center">
                        <div className="p-2 flex-fill">
                            <div className='d-flex justify-content-center'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Companies House (No Monitoring)
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 flex-fill">
                            <div className='d-flex justify-content-center' >
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        CreditSafe (No Monitoring)
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 flex-fill">
                            <div className='d-flex justify-content-center'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        CreditSafe (With Monitoring)
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex align-items-center justify-content-end'>
                        <button onClick={handleClose} className='btn-primary mb-2'>Submit</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CompanyCheck
