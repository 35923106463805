import React,{useState} from 'react'
import { Accordion } from 'react-bootstrap'
import EditIcon from '../../assets/images/edit_icon.png'
import SearchIcon from '../../assets/images/search_icon.png'
import PrintIcon from '../../assets/images/Print_Icon.png'
import { useNavigate } from 'react-router-dom'
import CalenderIcon from '../../assets/images/calendar_icon.png'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function SearchAmlCheck() {
    const navigate = useNavigate();

    const routeChange = () => {
        let path = `/aml/search_results`;
        navigate(path);
    }
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div className='grey-background'>
            <div className='white-background p-3 mb-3'>
                <h1 className='text-blue px-2 fw-normal'>Name and details</h1>
                <hr />
                <div className='row'>
                    <div className='col-lg-3 col-md-12'>
                        <div className="form-group mt-3">
                            <label className="form-label" for="exampleInputEmail1">Name</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Name" />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-12'>
                        <div className="form-group mt-3">
                            <label className="form-label" for="exampleInputEmail1">Client Reference (optional)</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Client Reference (optional)" />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-12'>
                        <div className="form-group mt-3">
                            <label className="form-label" for="exampleInputEmail1">Year of Birth  (optional)</label>
                            <div className='reactdatepicker'>
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <img className='calnder-icon-date' src={CalenderIcon} style={{ height: 20, objectFit: 'contain' }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-12'>
                        <div className="form-group mt-3">
                            <label className="form-label" for="exampleFormControlSelect1">Country (optional)</label>
                            <select id="statusDropdown" className="form-control form-select">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='white-background p-3 mt-3'>
                <h1 className='text-blue px-2 fw-normal'>Entity type</h1>
                <hr />
                <form>
                    <div className='row'>
                        <div className='col-lg-3 col-md-12'>
                            <div class="form-check mt-3">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label class="form-check-label" for="flexRadioDefault1">Any</label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>
                            <div class="form-check mt-3">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label class="form-check-label" for="flexRadioDefault1">Person</label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>
                            <div class="form-check mt-3">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label class="form-check-label" for="flexRadioDefault1">Company</label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>
                            <div class="form-check mt-3">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label class="form-check-label" for="flexRadioDefault1">Organization</label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>
                            <div class="form-check mt-3">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label class="form-check-label" for="flexRadioDefault1">Vessel</label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>
                            <div class="form-check mt-3">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label class="form-check-label" for="flexRadioDefault1">Aircraft</label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>
                            <div className="form-group mt-3 d-flex align-items-center">
                                <label className="form-label" for="exampleFormControlSelect1">Fuzziness Interval</label>
                                <select id="statusDropdown" className="form-control form-select">
                                    <option>20%</option>
                                    <option>40%</option>
                                    <option>60%</option>
                                    <option>80%</option>
                                    <option>100%</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className='white-background p-3 mt-3'>
                <div className='d-flex align-items-center justify-content-between'>
                    <h1 className='text-blue fw-normal'>Sources</h1>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                        <label className="form-check-label text-blue" for="defaultCheck1" style={{ fontSize: 16 }}>
                            Select All
                        </label>
                    </div>
                </div>
                <hr />
                <div>
                    <Accordion className='aml-accordion' defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label text-blue" for="defaultCheck1" style={{ fontSize: 16 }}> Sanctions, warning and fitness & Probity </label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="form-check m-3">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}> Sanctions e.g. OFAC SDN List, HM Treasury Sanctions List </label>
                                </div>
                                <div className="form-check m-3">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}> Warning e.g. US immigration and customs wanted </label>
                                </div>
                                <div className="form-check m-3">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}> Fitness & Probity e.g us system for Award Management Exclusion </label>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label text-blue" for="defaultCheck1" style={{ fontSize: 16 }}> PEPs </label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="form-check m-3">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>PEP Class 1 Heads of State, National Parliaments, National Goverments </label>
                                </div>
                                <div className="form-check m-3">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>PEP Class 2 Regional Goverments, Regional Parliaments </label>
                                </div>
                                <div className="form-check m-3">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>PEP Class 3 Senior Management & Boards of SOEs </label>
                                </div>
                                <div className="form-check m-3">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>PEP Class 4 Mayors and Local City Councils </label>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label text-blue" for="defaultCheck1" style={{ fontSize: 16 }}> All Adverse Media V2 </label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className='row'>
                                    <div className='col-4'>
                                        <div className="form-check m-3">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>Property</label>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-check m-3">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>Financial AML/CFT</label>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-check m-3">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>Fraud-linked</label>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-check m-3">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>Narcotics AML/CFT</label>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-check m-3">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>Violence AML/CFT</label>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-check m-3">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>Terrorism</label>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-check m-3">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>Cybercrime</label>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-check m-3">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>General AML/CFT</label>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-check m-3">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>Regulatory</label>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-check m-3">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>Financial difficulty</label>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-check m-3">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>Violence NON-AML/CFT</label>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-check m-3">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>Other Financial</label>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-check m-3">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>Other Serious</label>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-check m-3">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>Other Minor</label>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end my-4'>
                <div className='d-flex align-items-center'>
                    <button className='btn-primary'>Search</button>
                </div>
            </div>
            <div className='white-background p-3 mb-3'>
                <h1 className='text-blue px-2 fw-normal'>Previously Search</h1>
                <hr />
                <div className='row'>
                    <div className='col-lg-10 col-md-10'>
                        <div className="form-group position-relative">
                            <input type="text" className="form-control" placeholder='Search'></input>
                            <div className="position-absolute" style={{ right: 20, top: '20%' }}>
                                <img src={SearchIcon} style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2'>
                        <button className="btn btn-primary w-100" style={{ fontSize: 14 }}>Search</button>
                    </div>
                </div>
            </div>

            <div className='custom-table'>
                <div className='custom-table-head mt-3'>
                    <div className='row'>
                        <div className='col'>
                            <h6 className='text-blue'>Created</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue'>Name</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue'>Oomero ID</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue'>Match Status</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue'>Risk Level</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue'>Assigned To</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue'>Searcher</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue'>Changed</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue'>Monitor</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue'>Action</h6>
                        </div>
                    </div>
                </div>
                <div className='custom-table-body'>
                    <div className='row align-items-center'>
                        <div className='col'>
                            <h6 className='text-black'>12-02-2020</h6>
                        </div>
                        <div className='col'>
                            <h6 onClick={routeChange} className='text-blue' style={{ textDecorationLine: 'underline', cursor: 'pointer' }}>Gulnara Karimova</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>UYRUI70</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Credit Safe</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>20</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Yes</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Yes</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Yes</h6>
                        </div>
                        <div className='col'>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </div>
                        <div className='col'>
                            <div className='d-flex align-items-center'>
                                <img src={EditIcon} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-table-body'>
                    <div className='row align-items-center'>
                        <div className='col'>
                            <h6 className='text-black'>12-02-2020</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue' style={{ textDecorationLine: 'underline', cursor: 'pointer' }}>Fable</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>UYRUI70</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Credit Safe</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>20</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Yes</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Yes</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Yes</h6>
                        </div>
                        <div className='col'>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </div>
                        <div className='col'>
                            <div className='d-flex align-items-center'>
                                <img src={EditIcon} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-table-body'>
                    <div className='row align-items-center'>
                        <div className='col'>
                            <h6 className='text-black'>12-02-2020</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue' style={{ textDecorationLine: 'underline', cursor: 'pointer' }}>Fable</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>UYRUI70</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Credit Safe</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>20</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Yes</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Yes</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Yes</h6>
                        </div>
                        <div className='col'>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </div>
                        <div className='col'>
                            <div className='d-flex align-items-center'>
                                <img src={EditIcon} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-table-body'>
                    <div className='row align-items-center'>
                        <div className='col'>
                            <h6 className='text-black'>12-02-2020</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue' style={{ textDecorationLine: 'underline', cursor: 'pointer' }}>Fable</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>UYRUI70</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Credit Safe</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>20</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Yes</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Yes</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Yes</h6>
                        </div>
                        <div className='col'>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </div>
                        <div className='col'>
                            <div className='d-flex align-items-center'>
                                <img src={EditIcon} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-table-body'>
                    <div className='row align-items-center'>
                        <div className='col'>
                            <h6 className='text-black'>12-02-2020</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue' style={{ textDecorationLine: 'underline', cursor: 'pointer' }}>Fable</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>UYRUI70</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Credit Safe</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>20</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Yes</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Yes</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-black'>Yes</h6>
                        </div>
                        <div className='col'>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </div>
                        <div className='col'>
                            <div className='d-flex align-items-center'>
                                <img src={EditIcon} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end'>
                <span class="badge bg-primary-badge">1</span>
                <span class="badge">2</span>
                <span class="badge">3</span>
                <span class="badge">4</span>
                <span class="badge"><i class="fa fa-chevron-right"></i></span>
            </div>
        </div>
    )
}

export default SearchAmlCheck
