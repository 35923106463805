import React, { useEffect, useState } from 'react'
import ScreenActionIcon from '../../../assets/images/screeing_action.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUploadedDocuments } from '../IndividualScreeningSlice';
import { toast } from 'react-toastify';
import moment from 'moment';
import ViewIcon from '../../../assets/images/view_icon.png'
import DownloadIcon from '../../../assets/images/zip_download_img.png'
import TablePagination from '../../../components/Pagination';
import { getSignedUrls } from '../../../utils/awsHelper';

function IndividualUploadDocuments({ entityClientId }) {
    const { isLoading, fetchDocuments } = useSelector(state => state.individualScreeningReducer);
    
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const handlePagination = (event, value) => {
        setCurrentPage(value);
      }

    useEffect(() => {
        let queryParam = `?page=${currentPage}&entity_client_id=${entityClientId}&screen=screeningAction`;
        dispatch(fetchUploadedDocuments(queryParam)).unwrap().then((obj) => {
            toast.success(obj?.message || 'Document Fetched Successfully');
        }).catch(error => {
            toast.error(error.message || 'Unable to request document');
        });
    }, [dispatch, currentPage]);

    const handleDownload = async (documentUrl) => {
        const signedUrl =await getSignedUrls(documentUrl);
        window.open(signedUrl, '_blank');
    };

    const formatUploadedBy = (str) => {
        if (!str) return '';
        return str
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    return (
        <>
            <div className='d-flex align-items-center mt-3'>
                <img src={ScreenActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} alt="Screen Action Icon" />
                <h2 className='text-ambed text-center'>Uploaded Documents</h2>
            </div>
            <hr />
            <div className='custom-table pb-3'>
                <div className='custom-table-head'>
                    <div className='row'>
                        <div className='col'>
                            <h6 className='text-blue'>Action Date Time</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue'>File Uploaded</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue'>Document Type</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue'>Uploaded By</h6>
                        </div>
                        <div className='col'>
                            <h6 className='text-blue'>Action</h6>
                        </div>
                    </div>
                </div>
                {fetchDocuments?.rows?.map((data) =>
                            <div className='custom-table-body'>
                                <div className='row'>
                                    <div className='col'>
                                        <h6 className='text-black'>
                                            <h4 className='text-black'>{(data?.created_at) ? moment(data?.created_at).format("DD/MM/YYYY") : ''} - {(data?.created_at) ? moment(data?.created_at).format("HH:mm:ss") : ''}</h4>
                                        </h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>{data?.filename}</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>{formatUploadedBy(data?.document_type)}</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>{data?.user_type}</h6>
                                    </div>
                                    <div className='col'>
                                        <img src={DownloadIcon} onClick={() => handleDownload(data.document_url)} style={{ width: 15, objectFit: 'contain', cursor: 'pointer' }} />
                                    </div>
                                </div>
                            </div>
                        )}
                {fetchDocuments && fetchDocuments?.rows?.length > 0 &&
                    <TablePagination totalPages={parseInt(fetchDocuments?.totalPages)} currentPage={currentPage} color="primary" handlePagination={handlePagination} />
                }
            </div>
        </>
    )
}

export default IndividualUploadDocuments