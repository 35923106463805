import React from 'react'
import PrintIcon from '../../assets/images/Print_Icon.png'
import ViewIcon from '../../assets/images/view_icon.png'
import { useNavigate } from 'react-router-dom';
import "./corporateScreening.scss";
import { Tab, Tabs } from 'react-bootstrap'
import FilteredTableData from '../../components/Screening/FilteredTableData'

function CorporateScreening() {
  const tabArray = [
    {tabTitle:'Green', boxColor:'#037924'},
    {tabTitle:'Amber', boxColor:'#9C6802'},
    {tabTitle:'red', boxColor:'#ED4337'},
    {tabTitle:'Grey', boxColor:'#949595'},
    {tabTitle:'White Listed', boxColor:'#FFFFFF'},
    {tabTitle:'Black Listed', boxColor:'#262626'},
  ];
  const navigate = useNavigate();

  const routeChange = () => {
    let path = `/clients/manage_New_client`;
    navigate(path);
  }
  const startScreening = () => {
    let path = `/corporate_screening/screening`;
    navigate(path);
  }

  const renterTabTitle = (title, bgColor) => {
    return (
      <div className='d-flex align-items-center'>
        <div style={{ width: 20, height: 20, backgroundColor: bgColor, marginRight: 5 }} className='tabTitle' /> {title}
      </div>
    )
  }

  return (
    <div className='grey-background corporate-screening-page'>
      <div className='white-background p-3 mb-3'>
        <h1 className='text-blue px-2 fw-normal'>Screening  Corporate</h1>
        <hr />
        <div className='row'>
          <div className='col-lg-9 col-md-9'>
            <div className='row mb-sm'>
              <div className='d-flex' style={{ width: "max-content", alignItems: 'center' }}> Start Date </div>
              <div className='col-md-4 col-sm-4 pb-1'>
                <input type="date" className="form-control" id="exampleInputEmail1" />
              </div>
              <div className='d-flex' style={{ width: "max-content", alignItems: 'center' }}>  End Date </div>
              <div className='col-md-4 col-sm-4 pb-1'>
                <input type="date" className="form-control" id="exampleInputEmail1" />
              </div>
              <button onClick={routeChange} className="btn btn-primary mb-1 p-2 pl-4 pr-4" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10 }}>
                <i className="fa fa-search"></i>
              </button>
            </div>
          </div>
          <div className='col-lg-3 col-md-3 '>
            <div className='row'>
              <div className='col'>
                <input type="text" className="form-control" placeholder='Search'></input>
              </div>
              <button onClick={routeChange} className="btn btn-primary mb-1 p-2 pl-4 pr-4" style={{ width: 40, height: 40, marginLeft: 10 }}>
                <i className="fa fa-search"></i>
              </button>
              <button onClick={routeChange} className="btn btn-secondory mb-1 p-2 pl-4 pr-4" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10 }}>
                <i className="fa fa-rotate-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-12 col-md-12 mt-3'>
        <div className='white-background p-3'>
          <Tabs defaultActiveKey="all" className="mb-3 corporate-tabs" >
            <Tab eventKey="all" title="All">
              <FilteredTableData ViewIcon={ViewIcon} PrintIcon={PrintIcon} />
            </Tab>
            {tabArray.map((item,i)=>{
              return(
                <Tab eventKey={item.tabTitle} title={renterTabTitle(item.tabTitle, item.boxColor)} key={'screen-tab'+i}>
                  <FilteredTableData ViewIcon={ViewIcon} PrintIcon={PrintIcon} startScreening={startScreening} />
                </Tab>
              )
            })}
            {/* <Tab eventKey="Adverse Media" title={renterTabTitle('Green','#037924')}>
            <FilteredTableData ViewIcon={ViewIcon} PrintIcon={PrintIcon} />
            </Tab>
            <Tab eventKey="Documents" title="Documents">
            <FilteredTableData ViewIcon={ViewIcon} PrintIcon={PrintIcon} />
            </Tab> */}
          </Tabs>
        
        </div>
      </div>
    </div>
  )
}

export default CorporateScreening
