import React, { useEffect, useState } from 'react'
import AuditTrailIcon from '../../../assets/images/audit_trail.png'
import { Tab, Tabs } from 'react-bootstrap'
import { getFieldHistory, getScreeningComments } from '../IndividualScreeningSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import { decodeToken} from "react-jwt";
import moment from 'moment';


function IndiviudalAuditTrail({ entityClientId }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [type, setType] = useState('open');
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);
    const { isLoading, screeningComments, fieldHistory } = useSelector(state => state.individualScreeningReducer);

    const handleSelect = (key) => {
        switch (key) {
            case '1':
                setType('open');
                break;
            case '2':
                setType('ghost');
                break;
            case '3':
                setType('aml');
                break;
            case '4':
                dispatch(getFieldHistory(`?entity_client_id=${entityClientId}`)).unwrap().catch((error) => {
                    toast.error(error?.message);
                });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (!entityClientId) {
            navigate(`/individual_screening`);
            return;
        }

        if (entityClientId) {
            dispatch(getScreeningComments(`?entity_client_id=${entityClientId}&type=${type}`)).unwrap().catch((error) => {
                toast.error(error?.message);
            });
        }
    }, [entityClientId, type]);

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    return (
        <div>
            {isLoading && <Loader />}
            <div className='d-flex align-items-center mt-3'>
                <img src={AuditTrailIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                <h2 className='text-success text-center'>Audit Trail</h2>
            </div>
            <hr />
            <div className='oomero-tabs'>
                <Tabs defaultActiveKey="1" className="corporate-tabs" onSelect={handleSelect}>
                    <Tab eventKey="1" title="Open">
                        <div className='custom-table mt-4'>
                            <div className='custom-table-head'>
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <h6 className='text-blue'>Action Date Time</h6>
                                    </div>
                                    {/* <div className='col-lg-2'>
                                        <h6 className='text-blue'>Action Type</h6>
                                    </div> */}
                                    <div className='col-lg-8'>
                                        <h6 className='text-blue'>Action Description</h6>
                                    </div>
                                    <div className='col-lg-2'>
                                        <h6 className='text-blue'>User Details</h6>
                                    </div>
                                </div>
                            </div>
                            {screeningComments['rows'].map((item, i) =>
                                <div className='custom-table-body' key={i}>
                                    <div className='row'>
                                        <div className='col-lg-2'>
                                            <h4 className='text-black'>{(item?.created_at) ? moment(item?.created_at).format("DD/MM/YYYY") : ''} </h4>
                                            <h4 className='text-black'>{(item?.created_at) ? moment(item?.created_at).format("HH:mm:ss") : ''}</h4>
                                        </div>
                                        {/* <div className='col-lg-2'>
                                            <h4 className='text-black'>Document</h4>
                                        </div> */}
                                        <div className='col-lg-8'>
                                            <h4 className='text-black'>{item?.comment}</h4>
                                            {/* <h4 className='text-black'>Maker Uploaded the new passport from user name MAKER </h4>
                                            <h4 className='text-black fw-bold mt-2'>Comment:</h4>
                                            <h4 className='text-black mt-2'>Checker check the document and found photo copy not maching</h4> */}
                                        </div>
                                        <div className='col-lg-2'>
                                            <h4 className='text-black'>{item?.user_type}</h4>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </Tab>
                    {(decodedToken.isEnterpriseAdmin || 
                        decodedToken.isSuperAdmin || 
                        decodedToken.roles?.permissions?.view_ghost_comment)&&
                        
                    <Tab eventKey="2" title="Ghost">
                        <div className='custom-table mt-4'>
                            <div className='custom-table-head'>
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <h6 className='text-blue'>Action Date Time</h6>
                                    </div>
                                    {/* <div className='col-lg-2'>
                                        <h6 className='text-blue'>Action Type</h6>
                                    </div> */}
                                    <div className='col-lg-8'>
                                        <h6 className='text-blue'>Action Description</h6>
                                    </div>
                                    <div className='col-lg-2'>
                                        <h6 className='text-blue'>User Details</h6>
                                    </div>
                                </div>
                            </div>
                            {screeningComments['rows'].map((item, i) =>
                                <div className='custom-table-body' key={i}>
                                    <div className='row'>
                                        <div className='col-lg-2'>
                                            <h4 className='text-black'>{(item?.created_at) ? moment(item?.created_at).format("DD/MM/YYYY") : ''} </h4>
                                            <h4 className='text-black'>{(item?.created_at) ? moment(item?.created_at).format("HH:mm:ss") : ''}</h4>
                                        </div>
                                        {/* <div className='col-lg-2'>
                                            <h4 className='text-black'>Document</h4>
                                        </div> */}
                                        <div className='col-lg-8'>
                                            <h4 className='text-black'>{item?.comment}</h4>
                                            {/* <h4 className='text-black'>Maker Uploaded the new passport from user name MAKER </h4>
                                            <h4 className='text-black fw-bold mt-2'>Comment:</h4>
                                            <h4 className='text-black mt-2'>Checker check the document and found photo copy not maching</h4> */}
                                        </div>
                                        <div className='col-lg-2'>
                                            <h4 className='text-black'>{item?.user_type}</h4>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </Tab>
}
{
    (decodedToken.isEnterpriseAdmin || 
        decodedToken.isSuperAdmin || 
        decodedToken.roles?.permissions?.view_aml_comment_menu)&&
                    <Tab eventKey="3" title="AML">
                        <div className='custom-table mt-4'>
                            <div className='custom-table-head'>
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <h6 className='text-blue'>Action Date Time</h6>
                                    </div>
                                    {/* <div className='col-lg-2'>
                                        <h6 className='text-blue'>Action Type</h6>
                                    </div> */}
                                    <div className='col-lg-8'>
                                        <h6 className='text-blue'>Action Description</h6>
                                    </div>
                                    <div className='col-lg-2'>
                                        <h6 className='text-blue'>User Details</h6>
                                    </div>
                                </div>
                            </div>
                            {screeningComments['rows'].map((item, i) =>
                                <div className='custom-table-body' key={i}>
                                    <div className='row'>
                                        <div className='col-lg-2'>
                                            <h4 className='text-black'>{(item?.created_at) ? moment(item?.created_at).format("DD/MM/YYYY") : ''} </h4>
                                            <h4 className='text-black'>{(item?.created_at) ? moment(item?.created_at).format("HH:mm:ss") : ''}</h4>
                                        </div>
                                        {/* <div className='col-lg-2'>
                                            <h4 className='text-black'>Document</h4>
                                        </div> */}
                                        <div className='col-lg-8'>
                                            <h4 className='text-black'>{item?.comment}</h4>
                                            {/* <h4 className='text-black'>Maker Uploaded the new passport from user name MAKER </h4>
                                            <h4 className='text-black fw-bold mt-2'>Comment:</h4>
                                            <h4 className='text-black mt-2'>Checker check the document and found photo copy not maching</h4> */}
                                        </div>
                                        <div className='col-lg-2'>
                                            <h4 className='text-black'>{item?.user_type}</h4>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                        {/* <div> */}
                        {/* <h1 className='text-black my-3'>Comments</h1> */}
                        {/* <div className='row mt-3'>
                                <div className='col-lg-2' style={{ width: '11.666667%' }}>
                                    <h2>Key Data :</h2>
                                </div>
                                <div className='col-lg-10' style={{ width: '88.333333%' }}>
                                    <h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</h4>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-lg-2' style={{ width: '11.666667%' }}>
                                    <h2>AM :</h2>
                                </div>
                                <div className='col-lg-10' style={{ width: '88.333333%' }}>
                                    <h2>1, 3, 7, 9 :</h2>
                                    <h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</h4>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-lg-2' style={{ width: '11.666667%' }}>
                                    <h2>Docs :</h2>
                                </div>
                                <div className='col-lg-10' style={{ width: '88.333333%' }}>
                                    <h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</h4>
                                </div>
                            </div>
                        </div> */}
                    </Tab>
}
                    <Tab eventKey="4" title="Reasons">
                        <div className='custom-table mt-4'>
                            <div className='custom-table-head'>
                                <div className='row'>
                                    <div className='col-lg-2'>
                                        <h6 className='text-blue'>Action Date Time</h6>
                                    </div>
                                    {/* <div className='col-lg-2'>
                                        <h6 className='text-blue'>Action Type</h6>
                                    </div> */}
                                    <div className='col-lg-8'>
                                        <h6 className='text-blue'>Action Description</h6>
                                    </div>
                                    <div className='col-lg-2'>
                                        <h6 className='text-blue'>User Details</h6>
                                    </div>
                                </div>
                            </div>
                            {fieldHistory['rows'].map((item, i) =>
                                <div className='custom-table-body' key={i}>
                                    <div className='row'>
                                        <div className='col-lg-2'>
                                            <h4 className='text-black'>{(item?.created_at) ? moment(item?.created_at).format("DD/MM/YYYY") : ''} </h4>
                                            <h4 className='text-black'>{(item?.created_at) ? moment(item?.created_at).format("HH:mm:ss") : ''}</h4>
                                        </div>
                                        {/* <div className='col-lg-2'>
                                            <h4 className='text-black'>Document</h4>
                                        </div> */}
                                        <div className='col-lg-8'>
                                            <h4 className='text-black'>{`${capitalizeFirstLetter(item?.user_type)} made changes to ${item?.column_name.includes('_')
                                                ? item?.column_name.split('_').map(word => capitalizeFirstLetter(word)).join(' ')
                                                : item?.column_name.charAt(0).toUpperCase() + item?.column_name.slice(1)
                                                }`}</h4>
                                            <h4 className='text-black fw-bold mt-2'>Comment:</h4>
                                            <h4 className='text-black mt-2'>{item?.reason}</h4>
                                        </div>
                                        <div className='col-lg-2'>
                                            <h4 className='text-black'>{capitalizeFirstLetter(item?.user_type)}</h4>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default IndiviudalAuditTrail
