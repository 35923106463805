import React, { useState } from 'react'
import './Aml.scss'
import TopResults from '../../components/Aml/TopResults'
import ResultCard from '../../components/Aml/ResultCard'
import ScreeningActionIcon from '../../assets/images/screeing_action.png'
import { useNavigate } from 'react-router-dom';
import MasterAmlComments from '../../components/Aml/MasterAmlComments'

function ResultsSearch() {
    const navigate = useNavigate();
    const [masterComments, setMasterComments] = useState(false)

    const routeChange = () => {
        let path = `/aml/search_data`;
        navigate(path);
    }
    const routeComments = () => {
        let path = `/aml/master_comments`;
        navigate(path);
    }
    return (
        <div className='grey-background'>
            <TopResults onClickComments={() => setMasterComments(!masterComments)} titleofMasterComments={masterComments?`Go Back`:`Master Comments`}/>
            { !masterComments &&
                <>
                    <div className='row my-3'>
                        {[
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10
                        ].map((item, i) =>
                            <div className='col-lg-4'>
                                <ResultCard onClick={routeChange} key={i}/>
                            </div>
                        )}
                    </div>
                    <div className='d-flex align-items-center justify-content-end'>
                        <span class="badge bg-primary-badge">1</span>
                        <span class="badge">2</span>
                        <span class="badge">3</span>
                        <span class="badge">4</span>
                        <span class="badge"><i class="fa fa-chevron-right"></i></span>
                    </div>

                    <div className='white-background p-3 mt-4'>
                        <div className='d-flex align-items-center'>
                            <img src={ScreeningActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                            <h1 className='text-ambed fw-normal'>Screening Actions</h1>
                        </div>
                        <hr />
                        <div className='row align-items-end'>
                            <div className='col-9'>
                                <div className="form-group">
                                    <label className="form-label" for="exampleInputEmail1">Add Comment</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Add Comment" />
                                </div>
                            </div>
                            <div className='col-3'>
                                <button className='btn-grey w-100'>Add Comment</button>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-lg-4'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Whitelist this customer for all future TXNs
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Blacklist this customer for all future TXNs
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row my-3'>
                            <div className='col-lg-6'>
                                <div className='row align-items-end'>
                                    <div className='col-lg-7'>
                                        <div className="form-group">
                                            <label className="form-label" for="exampleFormControlSelect1">Upload Document</label>
                                            <select id="statusDropdown" className="form-control form-select">
                                                <option>PNG</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-5'>
                                        <button className='btn-primary w-100'>Choose a File</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='row align-items-end'>
                                    <div className='col-lg-7'>
                                        <div className="form-group">
                                            <label className="form-label" for="exampleFormControlSelect1">Request Document</label>
                                            <select id="statusDropdown" className="form-control form-select">
                                                <option>PNG</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-5'>
                                        <button className='btn-primary w-100'>Choose a File</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

            {masterComments && <MasterAmlComments />}
        </div>
    )
}

export default ResultsSearch
