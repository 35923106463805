import React, { useEffect, useState } from 'react'
import './Pricing.scss';
import CheckCircleIcon from '../../assets/images/check_circle_icon.png'
import WrongCircleIcon from '../../assets/images/wrong_circle_icon.png'
import Modal from 'react-bootstrap/Modal';
import ThumbIcon from '../../assets/images/thumb_icon.png'
import { useDispatch, useSelector } from 'react-redux'
import { fetchActivePlanLists, sendCustomPackageInquiryEmail, initiateOrder, placeOrder, fetchEntityActivePlan } from './planSlice'
import Loader from '../../components/Loader/Loader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../pages/Login/loginSlice';
import LogoutIcon from '../../assets/images/logout_icon.png'

function Pricing() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, planLists, entityActivePlan } = useSelector(state => state.planSliceReducer);

    useEffect(() => {
        dispatch(fetchActivePlanLists())
        dispatch(fetchEntityActivePlan());
        if (!localStorage.getItem('token')) {
            let path = `/login`;
            dispatch(logout());
            navigate(path);
        }
    }, []);

    const sendMailForCustomPackageEnquary = () => {
        dispatch(sendCustomPackageInquiryEmail()).unwrap().then((res) => {
            handleShow();
        }).catch((error) => {
            toast.error(error?.message);
        })
    }

    const onOrderSuccess = (requestObject) => {
        dispatch(placeOrder(requestObject)).unwrap().then((res) => {
            localStorage.setItem('activePlan', true);
            navigate('/dashboard');
        }).catch((error) => {
            localStorage.setItem('activePlan', false);
        })
    }

    const orderInitiate = async (data) => {
        if (data) {
            const [filterd] = planLists.filter((res) => res.package_id == data.package_id);
            const totalPrice = Object.values(filterd.services).reduce((sum, item) => {
                if(item.price){
                    return sum + item.price
                }
                return sum;
            }, 0);

            if (totalPrice) {
                let requestObject = { ...data, amount: totalPrice };
                await dispatch(initiateOrder(requestObject)).unwrap().then((res) => {
                    let obj = {
                        "orderId": res.data.order_id,
                        "packageId": data.package_id,
                        "orderAmount": totalPrice,
                        "paymentStatus": "paid",
                        "transactionId": res.data.transaction_id,
                        "paymentTransactionTd": res.data.transaction_id // this need to change according to payment response id
                    }
                    localStorage.setItem('activePlan', false);
                    onOrderSuccess(obj);
                }).catch((error) => {
                    toast.error(error?.message);
                })
            } else {
                toast.error("Something Went Wrong");
            }
        }
    }
    const routeChangeLogout = () => {
        let path = `/login`;
        dispatch(logout());
        navigate(path);
    }
    if (entityActivePlan && entityActivePlan.amount_used < parseFloat(entityActivePlan.wallet_balance)) {
        navigate('/dashboard');
    } else {
        return (
            <>
                {planLists?.length < 1 ? (
                    <div className='d-flex justify-content-center align-items-center flex-column'>
                        <h1 className="text-center">Oops! No packages found for this country</h1>
                        <p className="text-center">Contact to administrator</p>
                        <a style={{ cursor: 'pointer', textDecoration: 'none' }} className='d-flex align-items-center'>
                            <img src={LogoutIcon} style={{ width: 25, objectFit: 'contain', marginRight: 10 }} />
                            <p className='text-danger text-black-link text-center mb-0' onClick={routeChangeLogout}>Logout</p>
                        </a>

                    </div>
                ) : (
                    <div className='white-background'>
                        {isLoading && <Loader />}
                        <div className="pricing">
                            <div className="container">
                                {planLists && planLists?.length > 0 &&
                                    <div className="pricing-table table-responsive">
                                        <table className="table table align-middle">
                                            <thead>
                                                <tr>
                                                    <th style={{ border: '0px', padding: 0 }}>&nbsp;</th>
                                                    {
                                                        planLists?.map((item, hi) => {
                                                            if (item?.most_popular) {
                                                                return (
                                                                    <th style={{ backgroundColor: '#037AD0', paddingBottom: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, border: 0 }} className="">
                                                                        <h1 className='text-white fw-normal'><b>Most Popular Plan</b></h1>
                                                                    </th>
                                                                )
                                                            } else {
                                                                return <th style={{ padding: 0, border: '0px' }}> </th>
                                                            }

                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th style={{ border: '0px' }}>&nbsp;</th>
                                                    {
                                                        planLists?.map((item, i) => {
                                                            return (
                                                                <th style={{ margin: 10 }} key={'package-header' + i} className={(item?.most_popular) && 'highlight'}>
                                                                    <h1 className='text-black px-2 fw-normal mb-2'><b style={{ fontSize: 30 }}>{item?.package_name}</b></h1>
                                                                    <h6 className='text-grey px-2 fw-normal'>Pay per check solution</h6>
                                                                    <button className={'btn mt-3 mb-1' + ' ' + ((item?.most_popular) && "pricing-btn-blue") + " " + ((item?.is_custom) && 'pricing-btn-red') + " " + ((!(item?.is_custom) && !(item?.most_popular) && 'pricing-btn-green'))} onClick={() => (item?.is_custom) ? sendMailForCustomPackageEnquary() : orderInitiate({ package_id: item.package_id, type: 'plan' })}>{(item?.is_custom) ? 'Custom' : 'Get Started'}</button>
                                                                </th>
                                                            )
                                                        })
                                                    }
                                                    {/* <th style={{ margin: 10 }}>
                                            <h1 className='text-black px-2 fw-normal mb-2'><b style={{ fontSize: 30 }}>PAYG 50</b></h1>
                                            <h6 className='text-grey px-2 fw-normal'>Pay per check solution</h6>
                                            <button className="btn pricing-btn-green mt-3 mb-1" onClick={handleShow}>Get Started</button>
                                        </th>
                                        <th style={{ margin: 10 }}>
                                            <h1 className='text-black px-2 fw-normal mb-2'><b style={{ fontSize: 30 }}>PAYG 50</b></h1>
                                            <h6 className='text-grey px-2 fw-normal'>Pay per check solution</h6>
                                            <button className="btn pricing-btn-yellow mt-3 mb-1" onClick={handleShow}>Get Started</button>
                                        </th>
                                        <th style={{ margin: 10 }} className="highlight">
                                            <h1 className='text-black px-2 fw-normal mb-2'><b style={{ fontSize: 30 }}>PAYG 50</b></h1>
                                            <h6 className='text-grey px-2 fw-normal'>Pay per check solution</h6>
                                            <button className="btn pricing-btn-blue mt-3 mb-1" onClick={handleShow}>Get Started</button>
                                        </th>
                                        <th>
                                            <h1 className='text-black px-2 fw-normal mb-2'><b style={{ fontSize: 30 }}>PAYG 50</b></h1>
                                            <h6 className='text-grey px-2 fw-normal'>Pay per check solution</h6>
                                            <button className="btn pricing-btn-red mt-3 mb-1" onClick={handleShow}>Get Started</button>
                                        </th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h1 className='text-blue px-2 fw-normal'><b>Services Provide:</b></h1></span></td>
                                                    {
                                                        planLists.map((item, i2) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i2}></td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>Maximum no of Individual checks per month</b></h6></span></td>
                                                    {
                                                        planLists.map((item, i1) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i1}>
                                                                    {(item?.is_custom) ? 'Custom' : item?.individual_checks_per_month}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>Maximum no of Individual checks per month</b></h6></span></td>
                                                    {
                                                        planLists.map((item, i3) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i3}>{(item?.is_custom) ? 'Custom' : item?.company_checks_per_month}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>KYC Check - Biometric</b></h6></span></td>

                                                    {
                                                        planLists.map((item, i4) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i4}>{(item?.services?.kyc_biometric?.is_checked) && <img src={(item?.services?.kyc_biometric?.is_checked) ? CheckCircleIcon : WrongCircleIcon} style={{ width: 22, height: 22, objectFit: 'contain' }} />}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>KYC Liveness</b></h6></span></td>

                                                    {
                                                        planLists.map((item, i4) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i4}>{
                                                                    (item?.is_custom) ? 'Custom' : (item?.services?.kyc_liveness) && <img src={(item?.services?.kyc_liveness?.is_checked) ? CheckCircleIcon : WrongCircleIcon} style={{ width: 22, height: 22, objectFit: 'contain' }} />}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>KYC - 1:1 check - checks face across multiple documents</b></h6></span></td>

                                                    {
                                                        planLists.map((item, i5) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i5}>{<img src={(item?.services?.kyc_check_across_multiple_documents?.is_checked) ? CheckCircleIcon : WrongCircleIcon} style={{ width: 22, height: 22, objectFit: 'contain' }} />}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>KYC - 1:N check face across our whole database</b></h6></span></td>
                                                    {
                                                        planLists.map((item, i6) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i6}>{
                                                                    (item?.is_custom) ? 'Custom' : <img src={(item?.services?.kyc_check_across_whole_database?.is_checked) ? CheckCircleIcon : WrongCircleIcon} style={{ width: 22, height: 22, objectFit: 'contain' }} />}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>AML Continuous Monitoring</b></h6></span></td>

                                                    {
                                                        planLists.map((item, i7) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i7}>{<img src={(item?.services?.aml_continuous_monitoring?.is_checked) ? CheckCircleIcon : WrongCircleIcon} style={{ width: 22, height: 22, objectFit: 'contain' }} />}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>Manual Checking</b></h6></span></td>

                                                    {
                                                        planLists.map((item, i8) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i8}>{
                                                                    (item?.services?.manual_checking?.hours) && 'Max ' + item?.services?.manual_checking?.hours + ' hrs'}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>Company basic report (UK)</b></h6></span></td>

                                                    {
                                                        planLists.map((item, i9) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i9}>
                                                                    <img src={(item?.services?.company_basic_report_uk?.is_checked) ? CheckCircleIcon : WrongCircleIcon} style={{ width: 22, height: 22, objectFit: 'contain' }} />
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>Global Company Search</b></h6></span></td>
                                                    {
                                                        planLists.map((item, i10) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i10}>
                                                                    <img src={(item?.services?.global_company_search?.is_checked) ? CheckCircleIcon : WrongCircleIcon} style={{ width: 22, height: 22, objectFit: 'contain' }} />
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>Company Credit Report</b></h6></span></td>
                                                    {
                                                        planLists.map((item, i10) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i10}>
                                                                    <img src={(item?.services?.company_credit_report?.is_checked) ? CheckCircleIcon : WrongCircleIcon} style={{ width: 22, height: 22, objectFit: 'contain' }} />
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>UBO Check</b></h6></span></td>
                                                    {
                                                        planLists.map((item, i10) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i10}>
                                                                    <img src={(item?.services?.ubo_check?.is_checked) ? CheckCircleIcon : WrongCircleIcon} style={{ width: 22, height: 22, objectFit: 'contain' }} />
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>No of Users</b></h6></span></td>
                                                    {
                                                        planLists.map((item, i11) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i11}>
                                                                    {(item?.is_custom) ? 'Custom' :
                                                                        (item?.services?.no_of_users?.user_counts) && item?.services?.no_of_users?.user_counts}
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>API</b></h6></span></td>
                                                    {
                                                        planLists.map((item, i12) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i12}>
                                                                    <img src={(item?.services?.api?.is_checked) ? CheckCircleIcon : WrongCircleIcon} style={{ width: 22, height: 22, objectFit: 'contain' }} />
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>Exportable Company Reports</b></h6></span></td>
                                                    {
                                                        planLists.map((item, i13) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i13}>
                                                                    <img src={(item?.services?.exportable_company_reports?.is_checked) ? CheckCircleIcon : WrongCircleIcon} style={{ width: 22, height: 22, objectFit: 'contain' }} />
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>Custom field selection</b></h6></span></td>
                                                    {
                                                        planLists.map((item, i14) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i14}>
                                                                    <img src={(item?.services?.custom_field_selection?.is_checked) ? CheckCircleIcon : WrongCircleIcon} style={{ width: 22, height: 22, objectFit: 'contain' }} />
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>Enhanced Due Diligence - Individual</b></h6></span></td>
                                                    {
                                                        planLists.map((item, i15) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i15}>
                                                                    {item?.services?.enhanced_due_diligence_individual && 'Custom'}
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>Enhanced Due Diligence - Company</b></h6></span></td>
                                                    {
                                                        planLists.map((item, i16) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i16}>
                                                                    {item?.services?.enhanced_due_diligence_company && 'Custom'}
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ borderLeft: 0 }}><span className="ptable-title"><h6 className='text-black px-2 fw-normal'><b>Monthly minimum top-up cost</b></h6></span></td>
                                                    {
                                                        planLists.map((item, i17) => {
                                                            return (
                                                                <td className={(item?.most_popular) && 'highlight2'} key={'row' + i17}>
                                                                    {item?.is_custom ? 'Custom' :
                                                                        ('£' + (item?.services?.monthly_minimum_top_up_cost) && item?.services?.monthly_minimum_top_up_cost?.price)
                                                                    }
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                        <Modal show={show} onHide={handleClose} centered>
                            <Modal.Body className='px-5'>
                                <div className='d-flex justify-content-center'>
                                    <img src={ThumbIcon} style={{ width: 70, height: 70, objectFit: 'contain' }} />
                                </div>
                                <h3 className='text-blue text-center mt-3 mb-0 fw-normal px-3 pt-1 pb-1'>Thankyou for your enquiry Someone from our team will contact you in the next 24 hours.</h3>
                                <div className="d-flex justify-content-center">
                                    <button onClick={handleClose} className="btn-primary w-50 my-3">Ok</button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>

                )

                }
            </>
        )
    }
}

export default Pricing
