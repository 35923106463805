import React from 'react'
import './SubHeaderBox.scss'

function SubHeaderBox({ showBox1,contentText, showScore, scorelabel, scoreno, frontsidetext, showCenterText, centerText, backsidetext,showBox2,customColor,nameText }) {
    return (
        <div className='subheaderbox-css'>
            {showBox1 && <div className=' box1 d-flex justify-content-between' style={{marginBottom:20}}>
                <p className='text1' style={{color:customColor}}>{contentText}{nameText && <span className='text1 bluesidetext'>{nameText}</span>}</p>
                {showScore && <p className='text1'>{scorelabel}<span className='text1 bluetext'>{scoreno}</span></p>}
            </div>}
            {showBox2 && <div className=' box2 d-flex justify-content-around'>
                <p className='text2'>{frontsidetext}</p>
                {showCenterText && <p className='text2'>{centerText}</p>}
                <p className='text2'>{backsidetext}</p>
            </div>}
        </div>
    )
}

export default SubHeaderBox