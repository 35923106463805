import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    isLoading: false,
    error: null,
    planLists: [],
    entityActivePlan: {},
    packageDetails:{},
    packageList : {rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200},
}

export const fetchActivePlanLists = createAsyncThunk("tax/fetchAllTax", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchActivePlan 
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const sendCustomPackageInquiryEmail = createAsyncThunk("plan/sendCustomPackageInquiryEmail", async () => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.sendCustomPackageInquiryEmail
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const initiateOrder = createAsyncThunk("order/initiateOrder", async (requestObject) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.initiateOrder,
            data: requestObject
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const placeOrder = createAsyncThunk("order/placeOrder", async (requestObject) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.placeOrder,
            data: requestObject
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchEntityActivePlan = createAsyncThunk("package/getEntityActivePackage", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getEntityActivePackage 
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getAllPlanList = createAsyncThunk("package/getAllPackage", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAllPackage + queryParam
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const deactivatePackage = createAsyncThunk("package/deactivatePackage", async (package_id) => {
    try {
        let data = {
            method: METHOD_TYPE.delete,
            url: API_ENDPOINTS.deactivatePackage + '?package_id=' + package_id
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updatePackageData = createAsyncThunk("package/updatePackage", async (requestBody) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updatePackage,
            data: requestBody
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const createNewPackage = createAsyncThunk("package/createNewPackage", async (requestBody) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.createNewPackage,
            data: requestBody
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const  packageUpdate = createAsyncThunk("package/update", async (requestBody) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.packageUpdate,
            data: requestBody
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const  getPackageDetails = createAsyncThunk("package/getPackageDetails", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getPackageDetails+queryParam,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});


const planSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        resetPlanList(state, action) {
            state.packageList = {rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200};
        },
        resetPlanDetails(state, action) {
            state.packageDetails = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchActivePlanLists.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.planLists = payload?.data;
            })
            .addCase(sendCustomPackageInquiryEmail.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(initiateOrder.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(placeOrder.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(fetchEntityActivePlan.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.entityActivePlan = payload.data;
            })
            .addCase(getAllPlanList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.packageList = payload.data;
            })
            .addCase(deactivatePackage.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(updatePackageData.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(createNewPackage.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(packageUpdate.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(getPackageDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.packageDetails=payload.data
            })
            .addMatcher(isAnyOf(fetchActivePlanLists.pending,sendCustomPackageInquiryEmail.pending,initiateOrder.pending,placeOrder.pending,fetchEntityActivePlan.pending,getAllPlanList.pending,deactivatePackage.pending,updatePackageData.pending,createNewPackage.pending,packageUpdate.pending,getPackageDetails.pending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(fetchActivePlanLists.rejected,sendCustomPackageInquiryEmail.rejected,initiateOrder.rejected,placeOrder.rejected, fetchEntityActivePlan.rejected,getAllPlanList.rejected,deactivatePackage.rejected,updatePackageData.rejected,createNewPackage.rejected,packageUpdate.rejected,getPackageDetails.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again ";

            })
    }
});

const { resetPlanList, resetPlanDetails } = planSlice.actions;
const planSliceReducer = planSlice.reducer;

export { resetPlanList, resetPlanDetails, planSliceReducer };
export default planSliceReducer;