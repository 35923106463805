import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    isLoading: false,
    error: null,
    screeningPercentage: {},
    screeningPercentageClients:{},
    graphStats:{},
    keyStatsForGraph : []
}

export const getIndividualScreeningPercentages = createAsyncThunk("/dashboard/getIndividualScreeningPercentage", async (queryParam) => {
    try {
        const data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getIndividualScreeningPercentage + queryParam,
        }
        const response = await api(data);
        return response.data
    } catch (error) {
        throw error?.response?.data
    }
})

export const getKeyStatsForGraph = createAsyncThunk("/dashboard/getKeyStatsForGraph", async (queryParam) => {
    try {
        const data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getKeyStatsForGraph + queryParam,
        }
        const response = await api(data);
        return response.data
    } catch (error) {
        throw error?.response?.data
    }
})
export const getStatsByGraph = createAsyncThunk("/dashboard/getStatsByGraph", async (queryParam) => {
    try {
        const data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getStatsByGraph + queryParam,
        }
        const response = await api(data);
        return response.data
    } catch (error) {
        throw error?.response?.data
    }
})
export const getIndividualScreeningPercentagesClients = createAsyncThunk("/dashboard/getIndividualScreeningPercentages", async () => {
    try {
        const data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getIndividualScreeningPercentage ,
        }
        const response = await api(data);
        return response.data
    } catch (error) {
        throw error?.response?.data
    }
})

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(getIndividualScreeningPercentages.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = null;
            state.screeningPercentage = payload.data;
        })   
        .addCase(getKeyStatsForGraph.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = null;
            state.keyStatsForGraph = payload.data;
        })       
        .addCase(getStatsByGraph.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = null;
            state.graphStats = payload.data;
        })       
        .addCase(getIndividualScreeningPercentagesClients.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = null;
            state.screeningPercentageClients = payload.data;
        })       
        .addMatcher(isAnyOf(getIndividualScreeningPercentages.pending, getKeyStatsForGraph.pending,getStatsByGraph.pending,getIndividualScreeningPercentagesClients.pending), (state) => {
            state.isLoading = true;
        })
        .addMatcher(isAnyOf(getIndividualScreeningPercentages.rejected, getKeyStatsForGraph.rejected,getStatsByGraph.rejected,getIndividualScreeningPercentagesClients.rejected), (state, { error }) => {
            state.isLoading = false;
            state.error = error.message ? error.message : "Request Failed Please Try Again ";
        })
    }
});

const dashboardReducer = dashboardSlice.reducer;

export { dashboardReducer };
export default dashboardReducer;