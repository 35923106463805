import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import '../Profile.scss';
import countryCodes from '../../Registration/countryCode.json';
import professions from './profession.json';
import OnboardClientBar from '../../../components/OnboardClientBar/OnboardClientBar';
import { countryList } from '../../Registration/registrationSlice';
import Logo from "../../../assets/images/logo.png"
import upload from '../../../assets/images/upload.png'
import PersonalDetail from '../../../assets/images/personalDetails.png'
import CancelIcon from '../../../assets/images/Cancel_icon.png'
import BlackInfoIcon from '../../../assets/images/black _Info Circle_.png'
import Loader from '../../../components/Loader/Loader';
import { checkAadhaar, checkEUVatNo, checkPan, checkUKVatNo, fetchEntityClientPersonalDetail, resetRetryCount, saveIndividualPersonalDetails, updateIndividualPersonalDetails, uploadEntityClientDocument } from '../profileSlice';
import { decryptQueryParam } from '../../../utils/EncryptionUtils';
import Select from 'react-select';
import zIndex from '@mui/material/styles/zIndex';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

const titles = [
    {"label": "Mr", "value": "Mr"},
    {"label": "Mrs", "value": "Mrs"},
    {"label": "Miss", "value": "Miss"},
    {"label": "Ms", "value": "Ms"},
    {"label": "Dr (Medical)", "value": "Dr (Medical)"},
    {"label": "Professor (PHD)", "value": "Professor (PHD)"},
    {"label": "Dr (PHD)", "value": "Dr (PHD)"},
    {"label": "Admiral", "value": "Admiral"},
    {"label": "Brigadier", "value": "Brigadier"},
    {"label": "Captain", "value": "Captain"},
    {"label": "Colonel", "value": "Colonel"},
    {"label": "Corporal", "value": "Corporal"},
    {"label": "Field Marshal", "value": "Field Marshal"},
    {"label": "Fleet Admiral", "value": "Fleet Admiral"},
    {"label": "Flight Sergeant", "value": "Flight Sergeant"},
    {"label": "General", "value": "General"},
    {"label": "Hon", "value": "Hon"},
    {"label": "Lady", "value": "Lady"},
    {"label": "Lieutenant - Colonel", "value": "Lieutenant - Colonel"},
    {"label": "Lieutenant - General", "value": "Lieutenant - General"},
    {"label": "Lord", "value": "Lord"},
    {"label": "Major", "value": "Major"},
    {"label": "Major General", "value": "Major General"},
    {"label": "Master", "value": "Master"},
    {"label": "Rear Admiral", "value": "Rear Admiral"},
    {"label": "Reverand", "value": "Reverand"},
    {"label": "Sergeant", "value": "Sergeant"},
    {"label": "Sir", "value": "Sir"},
    {"label": "Sister", "value": "Sister"},
    {"label": "Warrant Officer", "value": "Warrant Officer"},
    {"label": "Vice Admiral", "value": "Vice Admiral"},
    {"label": "Other", "value": "Other"}
];

const gender = [
    {"label": "Male" , "value": "Male"},
    {"label": "Female" , "value": "Female"},
    {"label": "Other" , "value": "Other"},
]

function IndividualPersonalDetails() {
    const [show, setShow] = useState(false);
    const [showModalTwo, setShowModalTwo] = useState(false);
    const [showModalThree, setShowModalThree] = useState(false);
    const [showModal4, setShowModal4] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { isLoading: countryLoading, countryData } = useSelector(state => state.registerReducer);
    const { isLoading } = useSelector(state => state.ProfileReducer);
    const [currency, setCurrency] = useState(false);
    const fileInputRef = useRef(null);
    const [image, setImage] = useState(null);
    const [rerenderFlag, setRerenderFlag] = useState(false);
    const [decryptedValue, setDecryptedValue] = useState(null);
    const [isLegalEntity1, setIsLegalEntity] = useState(false);
    const [isSoleTrader1, setIsSoleTrader] = useState(false);

    const schema = yup.object().shape({
        politicallyExposedPerson: yup.boolean().required('Politically exposed person status is required').default(false),
        politicallyExposedSourceOfWealth: yup.string(),
        politicallyExposedPep: yup.string(),
        highNetWorthIndividual: yup.boolean().required('High net worth individual status is required').default(false),
        highNetValue: yup.string(),
        highNetSourceOfWealth: yup.string(),
        highNetSourceOfIncome: yup.string(),
        personalDetailDocument: yup.string(),
        title: yup.string().required('Title is required'),
        countryOfResidency: yup.number().typeError('Invalid residency country').required('Country of residency is required'),
        countryOfNationality: yup.number().typeError('Invalid nationality country').required('Country of nationality is required'),
        firstName: yup.string().required('First name is required'),
        middleName: yup.string(),
        lastName: yup.string().required('Last name is required'),
        previousName: yup.string(),
        dateOfBirth: yup.string().required('Date of birth is required'),
        email: yup.string().email('Invalid email address').matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email address').required('Email is required'),
        profession: yup.string().required('Profession is required'),
        mobileCode: yup.string().required('Mobile code is required'),
        countryId: yup.number().required("country id is required"),
        mobileNumber: yup.number().typeError('Mobile Number must be a number').required('Mobile No is required').test(
          'len',
          'Mobile No must be at most 15 digits',
          val => val && val.toString().length <= 15
        ),
        landlineCode: yup.string().notRequired(),
        landlineNumber: yup.mixed().transform((originalValue, originalObject) => {
            return originalValue === "" ? undefined : originalValue;
        }).when('landlineCode', {
            is: (obj) => obj && typeof obj != 'undefined',
            then: () => yup.number().typeError('Invalid landline number').required('landline number is required').test(
                'len',
                'Landline No must be at most 15 digits',
                val => val && val.toString().length <= 15
              ),
            otherwise: () => yup.mixed().notRequired(),
        }),
        aadharNumber: (state?.countryId && state?.countryId == 100) ? yup.string().transform((originalValue, originalObject) => {
            return originalValue === "" ? undefined : originalValue;
        }).required('Aadhar No is required').matches(/^\d{12}$/, 'Aadhar number must be exactly 12 digits') : yup.string().notRequired(),
        panNumber: (state?.countryId && state?.countryId == 100) ? yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'PAN number must be in the format: AAAAA9999A').required() : yup.string().notRequired(),
        ssnNo: (state?.countryId && state?.countryId == 234) ? yup.mixed().transform((originalValue, originalObject) => {
            return originalValue === "" ? undefined : originalValue;
        }).required('SSN No is required') : yup.string().notRequired(),
        islegalEntity: yup.boolean().notRequired(),
        legalEntityNumber: yup.number().typeError('Invalid legal entity Number').when('islegalEntity', {
            is: (obj) => obj && typeof obj != 'undefined',
            then: () => yup.number().typeError('Invalid legal entity number').required('Legal entity number is required'),
            otherwise: () => yup.string().notRequired()
        }),
        isSoleTrader: yup.boolean().notRequired(),
        vatNo: yup.string().notRequired(),
        vatDetails : yup.object().notRequired().nullable().transform((value, originalValue) => {
            return originalValue === "" ? null : value;
        }),
        gstNo: (state?.countryId && state?.countryId == 100) ? yup.number().typeError('Invalid GST Number').when('isSoleTrader', {
            is: (obj) => obj && typeof obj != 'undefined',
            then: () => yup.string().typeError('GST number is required').matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, 'GST number must be in the correct format').required('GST number is required'),
            otherwise: () => yup.string().notRequired()
        }) : yup.string().notRequired(),
        tradingAs: yup.string().when('isSoleTrader', {
            is: (obj) => obj && typeof obj != 'undefined',
            then: () => yup.string().required('Trading name is required'),
            otherwise: () => yup.string().notRequired()
        }),
        facebookLink: yup.string().notRequired(),
        instagramLink: yup.string().notRequired(),
        linkedinLink: yup.string().notRequired(),
        twitterLink: yup.string().notRequired(),
        sex: yup.string().required('Sex is required'),
        formConsent: yup.boolean().oneOf([true], 'Form consent is required'),
        biometricConsent: yup.boolean().oneOf([true], 'Biometric consent is required'),
    });

    const handleCheckboxChange = (e) => {
        setIsLegalEntity(e.target.checked);
    };

    const handleCheckboxChange1 = (e) => {
        setIsSoleTrader(e.target.checked);
    };

    const { control, register, handleSubmit, formState: { errors }, reset, setValue, trigger, getValues, watch } = useForm({
        resolver: yupResolver(schema),
    });

    // Get the value from React Hook Form
    const mobileCodeValue = getValues('mobileCode');
    const landLineCodeValue = getValues('landlineCode');
    const highNetSourceOfIncome=watch('highNetSourceOfIncome')
    const highNetSourceOfWealth=watch('highNetSourceOfWealth')
    const highNetValue=watch('highNetValue')
    const isSoleTrader = watch('isSoleTrader');
    const profession = watch('profession');
    const [selectedPep, setSelectedPep] = useState('');
    
    useEffect(() => {
        dispatch(resetRetryCount())
        dispatch(countryList({ requestData: state?.webformToken }));
        if (!state?.countryId) {
            navigate(`/create_profile`);
        }
        setValue('countryId', state?.countryId);
        if (!state?.entityClientId && state?.webformToken) {
            const decryptValue = async () => {
                try {
                    const decrypted = await decryptQueryParam(state?.webformToken);
                    setValue('firstName', decrypted?.firstName);
                    setValue('lastName', decrypted?.lastName);
                    setValue('email', decrypted?.email);
                    setValue('mobileCode', decrypted?.mobileCode);
                    setValue('mobileNumber', decrypted?.mobileNumber);
                    setDecryptedValue(decrypted)
                } catch (error) {
                    console.log("Invalid Token");
                }
            };
            decryptValue();
        }
        if (state?.entityClientId) {
            dispatch(fetchEntityClientPersonalDetail({ queryParam: `?entity_client_id=${state?.entityClientId}`, webformToken: state?.webformToken })).unwrap().then((res) => {
                const { politically_exposed_person, politically_exposed_pep, politically_exposed_source_of_wealth, high_net_worth_individual, high_net_source_of_income, high_net_value, high_net_source_of_wealth, title, country_of_residency, country_of_nationality, first_name, middle_name, last_name, previous_name, date_of_birth, email, profession, mobile_code, mobile_number, landline_code, landline_number, aadhar_number, pan_number, ssn_no, legal_entity_number, is_sole_trader, vat_no, gst_no, trading_as, facebook_link, instagram_link, linkedin_link, twitter_link, personal_detail_document,sex,form_consent , biometric_consent } = res?.data;
                setValue('politicallyExposedPerson', politically_exposed_person);
                setValue('highNetWorthIndividual', high_net_worth_individual);
                setValue('formConsent', form_consent);
                setValue('biometricConsent', biometric_consent);

                if (personal_detail_document) {
                    setImage(personal_detail_document)
                    setValue('personalDetailDocument', personal_detail_document);
                }

                if (high_net_value) {
                    setValue('highNetValue', high_net_value);
                }

                if (high_net_source_of_wealth) {
                    setValue('highNetSourceOfWealth', high_net_source_of_wealth);
                }

                if (high_net_source_of_income) {
                    setValue('highNetSourceOfIncome', high_net_source_of_income);
                }

                if (politically_exposed_source_of_wealth) {
                    setValue('politicallyExposedSourceOfWealth', politically_exposed_source_of_wealth);
                }

                if (politically_exposed_pep) {
                    setValue('politicallyExposedPep', politically_exposed_pep);
                }

                setValue('title', title);
                setValue('countryOfResidency', country_of_residency);
                setValue('countryOfNationality', country_of_nationality);
                setValue('firstName', first_name);
                if (middle_name) {
                    setValue('middleName', middle_name);
                }
                setValue('lastName', last_name);
                if (previous_name) {
                    setValue('previousName', previous_name);
                }
                setValue('dateOfBirth', date_of_birth);
                setValue('email', email);
                setValue('profession', profession);
                setValue('mobileCode', mobile_code);
                setValue('mobileNumber', mobile_number);
                setValue('sex', sex);
                if (landline_code) {
                    setValue('landlineCode', landline_code);
                }

                if (landline_number) {
                    setValue('landlineNumber', landline_number);
                }

                if (aadhar_number) {
                    setValue('aadharNumber', aadhar_number);
                }

                if (pan_number) {
                    setValue('panNumber', pan_number);
                }

                if (ssn_no) {
                    setValue('ssnNo', ssn_no);
                }

                if (legal_entity_number) {
                    setValue('islegalEntity', true);
                    setValue('legalEntityNumber', legal_entity_number);
                }

                if (vat_no) {
                    setValue('vatNo', vat_no);
                }

                if (gst_no) {
                    setValue('gstNo', gst_no);
                }

                if (is_sole_trader) {
                    setValue('isSoleTrader', is_sole_trader);
                    setIsSoleTrader(true);
                }

                if (trading_as) {
                    setValue('tradingAs', trading_as);
                }
                setValue('facebookLink', facebook_link ? facebook_link : '');
                setValue('instagramLink', instagram_link ? instagram_link : '');
                setValue('linkedinLink', linkedin_link ? linkedin_link : '');
                setValue('twitterLink', twitter_link ? twitter_link : '');
                setRerenderFlag(prev => !prev);
            }).catch((error) => {
                toast.error(error?.message);
            })
        }
    }, [])

    const goBack = () => {
        navigate(-1); // This navigates back to the previous page
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseModalTwo = () => setShowModalTwo(false);
    const handleShowModalTwo = () => setShowModalTwo(true);

    const handleCloseModalThree = () => setShowModalThree(false);
    const handleShowModalThree = () => setShowModalThree(true);

    const handleCloseModal4 = () => setShowModal4(false);
    const handleShowModal4 = () => setShowModal4(true);

    const removeEmptyValues = (obj) => {
        let newObj = {};

        for (const key in obj) {
            if (obj[key] !== "") {
                newObj[key] = obj[key];
            }
        }

        return newObj;
    }

    const onSubmit = async (formData) => {
        let requestobject = Object.assign({}, formData);
        if (requestobject.politicallyExposedPerson && (!requestobject.politicallyExposedSourceOfWealth || requestobject.politicallyExposedSourceOfWealth == '' || !requestobject.politicallyExposedPep || requestobject.politicallyExposedPep == '')) {
            handleShowModalTwo();
        } else if (requestobject.highNetWorthIndividual && (!requestobject.highNetValue || requestobject.highNetValue == '' || (!requestobject.highNetSourceOfWealth || requestobject.highNetSourceOfWealth == '') || (!requestobject.highNetSourceOfIncome || requestobject.highNetSourceOfIncome == ''))) {
            handleShow();
        } else {
            delete requestobject.islegalEntity;
            let requestBody = await removeEmptyValues(requestobject);
            let callFunction = (state?.entityClientId) ? updateIndividualPersonalDetails({ requestBody, entityClientId: state?.entityClientId, webformToken: state?.webformToken }) : saveIndividualPersonalDetails({ requestBody, webformToken: state?.webformToken });
            dispatch(callFunction).unwrap().then((res) => {
                toast.success(res?.message);
                let path = (state?.webformToken) ? '/webform/individual_address' : `/profile/individual_address`;
                navigate(path, { state: { entityClientId: (state?.entityClientId) ? state?.entityClientId : res?.data?.entityClientId, countryId: state?.countryId, webformToken: state?.webformToken, entityId: state?.entityId, isOomero: state?.isOomero } });
            }).catch((error) => {
                toast.error(error?.message);
            });
        }
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {

            const allowedTypes = ['image/jpeg', 'image/png'];
            if (!allowedTypes.includes(file.type)) {
                toast.error('Invalid file type. Please select a JPEG or PNG file.');
                return;
            }
            // Validate file size (in bytes)
            const maxSize = 5 * 1024 * 1024; // 5MB
            if (file.size > maxSize) {
                toast.error('File size exceeds the limit of 5MB. Please select a smaller file.');
                return;
            }

            const reader = new FileReader();

            reader.onloadend = () => {
                setImage(reader.result);
            };

            reader.readAsDataURL(file);
            const formData = new FormData();
            formData.append('document', file, file.name);

            dispatch(uploadEntityClientDocument({ formData, webformToken: state?.webformToken })).unwrap().then((res) => {
                setValue('personalDetailDocument', res[0].data);
                trigger(['personalDetailDocument']);
            }).catch((error) => {
                toast.error(error?.message);
            })

        } else {
            setImage('');
        }
    };
    const customStyles = {
        control: (provided) => ({
            ...provided,
            padding: '1px',
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
            boxShadow: 'none',
            '&:hover': {
                border: '2px solid #B3B3B3',
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
            zIndex:'60'
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#B3B3B3',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '14px',
        }),
    };

    const checkVat = (e) => {
        const vatNumber = e.target.value;
        if (!errors?.vatNo?.message) {
            // If UK then check with HMRC else it will be vies
            // 14, 21, 33, 53, 56, 57, 58, 70, 75, 76, 82, 85, 98, 104, 107, 120, 126, 127, 135, 154, 175, 176, 180, 200, 201, 207, 213

            // India and EU --> vies
            // UK --> HRMC

            if (state?.countryId && [233, 100, 14, 21, 33, 53, 56, 57, 58, 70, 75, 76, 82, 85, 98, 104, 107, 120, 126, 127, 135, 154, 175, 176, 180, 200, 201, 207, 213].includes(parseInt(state?.countryId))) {
                const dispatchFunction = (parseInt(state?.countryId) === 233) ? dispatch(checkUKVatNo({queryParam : `?ukVatNo=${vatNumber}`, webformToken: state?.webformToken})) : dispatch(checkEUVatNo({queryParam : `?euVatNo=${vatNumber}`, webformToken: state?.webformToken}));
                dispatchFunction.unwrap().then((res) => {
                    if (res?.status) {
                        setValue("vatDetails", res?.data);
                        toast.success('Vat No Validated Successfully');
                    } else {
                        toast.error('Invalid Vat No');
                        setValue('vatNo', '');
                        setValue("vatDetails", '');
                    }
                }).catch((error) => {
                    toast.error('Invalid Vat No');
                    setValue('vatNo', '');
                    setValue("vatDetails", '');
                })
            }
        } else {
            console.log("errors", errors);
        }
    };
    
    const handlePepClick = (pep) => {
        setSelectedPep(pep);
        setValue('politicallyExposedPep', pep);
    };

    const pepInfo = {
        PEP1: [
            'Heads of state',
            'Members of the national executive',
            'Members of the national legislatures',
            'Senior military and law enforcement officials',
            'Senior officials of national agencies',
            'Board members of central banks',
            'Senior judiciary officials (national level)',
            'Top-ranking officials of political parties',
            'Family members of Foregin/Domestic politicians '
        ],
        PEP2: [
            'Board members of International Organisations (HIOs) &',
            'International Sports Federations',
            'Ambassadors, high commissioners, and other top diplomatic positions',
            'Members of the regional executive',
            'Members of the regional legislatures',
            'Senior judiciary officials (regional level)',
            "PIP (Major Industralist / Family)",
            "PIP (friend / relative / family of political person PEP 1 OR PEP2)",
" Any person who is closely associated with a political party (PIP)",
" Medium Level Military or Law Enforcement Agencies",
" Direct Relatives of a Senior Politician (Father, Mother, Brother, Sister,Daughter, Son etc.)"
        ],
        PEP3: [
            'Senior management and board of directors of state-owned businesses and organisations.',
            "PIP (Major Industralist / Family)",
            "PIP (Relative more than once removed of PEP 1 or PEP 2)",
            "In-Direct Relatives of a Senior Politician (Uncle, Aunt, Nephew, Niece, Brother in Law, Sister in Law etc.)"
        ],
        PEP4: [
            'Mayors and members of local government (sub-regional level).',
            'Family Members of those classified',
            'Close Associates of those classified',
            'National PEPs.',
            '(PIP) Politically Important Person'
        ],
    };

    const pepInformation = [
        'Foreign',
        'Domestic',
        'International Organisations',
        'Family Members of those Foregin/Domestic politicians',
        'Close Associates of those classified',
        'National PEPs.',
    ]
    const aadhaarValidate = (uidNumber) => {
        const requestData = {
            uidNumber: uidNumber,
        }
        dispatch(checkAadhaar(requestData)).unwrap().then((res) => {
            toast.success("Validated successfully");

        }).catch((error) => {
            setValue("aadharNumber", " ")
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    }
    const panValidate = (panNumber) => {
        const requestData = {
            panNumber: panNumber,
            panType: "individual"
        }
        dispatch(checkPan(requestData)).unwrap().then((res) => {
            toast.success("Validated successfully");
        }).catch((error) => {
            setValue("panNumber", "")
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    }

    return (
        <>
            {countryLoading || isLoading && <Loader />}
            {decryptedValue &&
                <div className='white-background py-2 position-fixed w-100' style={{ borderRadius: 0,zIndex:1000 }}>
                    <div className="d-flex justify-content-center">
                        <img src={Logo} style={{ objectFit: 'contain', width: 150 }} />
                    </div>
                </div>
            }
            <div className='grey-background webform-css'>

                {decryptedValue &&
                    <div className=' mt-5 mb-3 p-3 w-100 hide-background hide-in-desktop'>
                        <p className='text-blue fw-normal'>Hi <b>{decryptedValue?.email}</b> ,<br></br> below is the webform to onboard individual client</p>
                    </div>
                }

                <OnboardClientBar
                    mainTitle={`Onboard Individual Client`}
                    title1={`PERSONAL DETAILS`}
                    title2={`ADDRESS`}
                    title3={`BANK DETAILS`}
                    title4={`KYC Verification`}
                    backgroundColor1={'#037AD0'} />

                <div className='hide-in-desktop d-flex align-items-center mt-4'>
                    <img src={PersonalDetail} style={{ width: 35, objectFit: 'contain', marginRight: 10 }} />
                    <h1 className='text-ambed fw-normal'>Personal Details</h1>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" {...register('countryId')} />
                    <div className='row'>
                        <div className='col-lg-7 col-md-12'>
                            <div className='white-background mt-4 p-3'>
                                <h1 className='text-blue px-2 fw-normal'>Please select all that may apply</h1>
                                <hr />
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        {state?.entityClientId && <a href="javascript:void(0)" onClick={() => handleShowModalTwo()}>Edit</a>}
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="politicallyExposedPerson" {...register('politicallyExposedPerson')} onChange={(e) => {
                                                if (!e.target.checked) {
                                                    setValue('politicallyExposedSourceOfWealth', '');
                                                    setValue('politicallyExposedPep', '')
                                                } else {
                                                    handleShowModalTwo();
                                                }
                                            }} />
                                            <label className="form-check-label" for="politicallyExposedPerson">
                                                Politically Exposed Person (PEP)
                                            </label>
                                            <img onClick={handleShowModalThree} src={BlackInfoIcon} style={{ width: 16, objectFit: 'contain', cursor: 'pointer', marginLeft: 10 }} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        {state?.entityClientId && <a href="javascript:void(0)" onClick={() => handleShow()}>Edit</a>}
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="highNetWorthIndividual" {...register('highNetWorthIndividual')} onChange={(e) => {
                                                if (!e.target.checked) {
                                                    setValue('highNetValue', '');
                                                    setValue('highNetSourceOfWealth', '')
                                                    setValue('highNetSourceOfIncome', '')
                                                } else {
                                                    handleShow();
                                                }
                                            }} />
                                            <label className="form-check-label" for="highNetWorthIndividual">
                                                High Net Worth Individual (HNI)
                                            </label>
                                            <img onClick={handleShowModal4} src={BlackInfoIcon} style={{ width: 16, objectFit: 'contain', cursor: 'pointer', marginLeft: 10 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 xol-md-12'>
                        <div className='white-background mt-4 p-3'>
                            <h1 className='text-blue px-2 fw-normal'>Consent</h1>
                            {/* <hr /> */}
                                <div className={`form-check mt-2 ${errors.formConsent ? 'is-invalid' : ''}`}>
                                    <div>
                                        <input className="form-check-input" type="checkbox" id="formConsent" {...register('formConsent')} />
                                        <label className="form-check-label" htmlFor="formConsent">
                                        Form <span style={{ color: 'red' }}> *</span>
                                        </label>
                                    </div>
                                </div>
                                    {errors?.formConsent &&
                                        <div className="invalid-feedback" style={{fontSize: 10}}>{errors?.formConsent?.message}</div>
                                    }
                                <div className={` form-check ${errors.biometricConsent ? 'is-invalid' : ''}`}>
                                    <div>
                                        <input className="form-check-input" type="checkbox" id="biometricConsent" {...register ('biometricConsent')} />
                                        <label className="form-check-label" htmlFor="biometricConsent">
                                        Biometrics <span style={{ color: 'red' }}> *</span>
                                        </label>
                                    </div>
                                </div>
                                    {errors?.biometricConsent &&
                                        <div className="invalid-feedback" style={{fontSize: 10}}>{errors?.biometricConsent?.message}</div>
                                    }
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 hide-upload-box'>
                            <div className='white-background  mt-4 p-3'>
                                <div className='row align-items-center mb-2'>
                                    <div className='col-lg-2 col-mg-2'>
                                        <div className="d-flex align-items-center justify-content-center" style={{ width: '80px', height: '80px', borderRadius: '10px', backgroundColor: '#01C9A51A' }}>
                                            < img src={image ? image : upload} style={{ width: 80, height: 80, objectFit: "cover" }} />
                                        </div>
                                    </div>
                                    <div className='col-lg-10 col-mg-10'>
                                        <div className="ps-5 d-flex flex-column justify-content-center">
                                            <input type="file" hidden accept="image/*" ref={fileInputRef} onChange={handleImageChange}
                                                className={` ${errors.personalDetailDocument ? 'is-invalid' : ''}`} />
                                            <a className='upload' style={{ color: '#037924', cursor: 'pointer' }} onClick={() => fileInputRef.current.click()}>
                                                Upload Image</a>
                                            <p className='resend mb-0'>JPEG or PNG less than 5MB</p>
                                            <input type='hidden'  {...register('personalDetailDocument', {
                                                required: `Please upload personal detail document`
                                            })} />
                                            {errors?.personalDetailDocument &&
                                                <div className="invalid-feedback">{errors?.personalDetailDocument?.message}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='white-background p-3 mt-4'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='hide-in-mobile d-flex align-items-center'>
                                        <img src={PersonalDetail} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                                        <h1 className='text-ambed fw-normal'>Personal Details</h1>
                                    </div>
                                    <div style={{color : 'red',fontSize:14}}>All (*) marked fields are mandatory</div>
                                </div>
                                <hr className='hide-in-mobile' />
                                <div className='form'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group">
                                                <label className="form-label" for="exampleFormControlSelect1">Title <span style={{ color: 'red' }}>*</span></label>
                                                <Controller
                                                    name="title"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            id="title"
                                                            placeholder="Select Title"
                                                            className={`${errors.title ? 'is-invalid' : ''}`}
                                                            options={titles}
                                                            styles={customStyles}
                                                            value={field.value ? { label: titles.find(title => title.value == field.value)?.label, value: field.value } : ''}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption?.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors?.title && (
                                                    <div className="invalid-feedback">{errors?.title?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className={`col-lg-4 col-md-12 hide-in-mobile ${window.innerWidth <= 500 ? 'mt-3' : ''}`}>
                                            <div className="">
                                                <label className="form-label" for="countryOfResidency">Country of Residency<span style={{ color: 'red' }}> *</span></label>
                                                <Controller
                                                    name="countryOfResidency"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            id="countryOfResidency"
                                                            placeholder="Select Country of Residency"
                                                            className={`${errors.countryOfResidency ? 'is-invalid' : ''}`}
                                                            options={countryData.map(country => ({
                                                                label: country.name,
                                                                value: country.country_id
                                                            }))}
                                                            styles={customStyles}
                                                            value={field.value ? { label: countryData.find(country => country.country_id == field.value)?.name, value: field.value } : ''}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption?.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors?.countryOfResidency && (
                                                    <div className="invalid-feedback">{errors?.countryOfResidency?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className={`col-lg-4 col-md-12 hide-in-mobile ${window.innerWidth <= 500 ? 'mt-3' : ''}`}>
                                            <div className="">
                                                <label className="form-label" for="countryOfNationality">Country of Nationality<span style={{ color: 'red' }}> *</span></label>
                                                <Controller
                                                    name="countryOfNationality"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            id="countryOfNationality"
                                                            placeholder="Select Country of Nationality"
                                                            className={`${errors.countryOfNationality ? 'is-invalid' : ''}`}
                                                            options={countryData.map(country => ({
                                                                label: country.name,
                                                                value: country.country_id
                                                            }))}
                                                            styles={customStyles}
                                                            value={field.value ? { label: countryData.find(country => country.country_id == field.value)?.name, value: field.value } : ''}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption?.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors?.countryOfNationality && (
                                                    <div className="invalid-feedback">{errors?.countryOfNationality?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >First Name<span style={{ color: 'red' }}> *</span></label>
                                                <input type="text" className={`form-control form-control-lg ${errors.firstName ? 'is-invalid' : ''}`}
                                                    placeholder="First Name"
                                                    {...register('firstName', {
                                                        required: `Please Enter First Name`
                                                    })}
                                                />
                                                {errors?.firstName && (
                                                    <div className="invalid-feedback">{errors?.firstName?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >Middle Name</label>
                                                <input type="text" className={`form-control form-control-lg ${errors.middleName ? 'is-invalid' : ''}`}
                                                    placeholder="Middle Name"
                                                    {...register('middleName', {
                                                        required: `Please Enter Middle Name`
                                                    })}
                                                />
                                                {errors?.middleName && (
                                                    <div className="invalid-feedback">{errors?.middleName?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >Last Name<span style={{ color: 'red' }}> *</span></label>
                                                <input type="text" className={`form-control form-control-lg ${errors.lastName ? 'is-invalid' : ''}`}
                                                    placeholder="Last Name"
                                                    {...register('lastName', {
                                                        required: `Please Enter Last Name`
                                                    })}
                                                />
                                                {errors?.lastName && (
                                                    <div className="invalid-feedback">{errors?.lastName?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >Previous  Name / AKA</label>
                                                <input type="text" className={`form-control form-control-lg ${errors.previousName ? 'is-invalid' : ''}`}
                                                    placeholder="Enter Previous  Name / AKA"
                                                    {...register('previousName')}
                                                />
                                                {errors?.previousName && (
                                                    <div className="invalid-feedback">{errors?.previousName?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >Date of Birth <span style={{ color: 'red' }}>*</span></label>
                                                {/* <input type="date" className={`form-control form-control-lg ${errors.dateOfBirth ? 'is-invalid' : ''}`}
                                                    placeholder="Date"
                                                    {...register('dateOfBirth', {
                                                        required: `Please Enter Date of Birth`
                                                    })}
                                                /> */}
                                            
                                            <Controller
                                                name="dateOfBirth"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        className={`form-control form-control-lg custom-datepicker ${errors.dateOfBirth ? 'is-invalid' : ''}`}
                                                        onChange={(date) => {
                                                            const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                            field.onChange(formattedDate);
                                                        }}
                                                        value={field.value}
                                                        maxDate={new Date()}
                                                        clearIcon
                                                        format='dd/MM/yyyy'
                                                        yearPlaceholder='yyyy'
                                                        monthPlaceholder='mm'
                                                        dayPlaceholder='dd'
                                                    />
                                                )}
                                            />
                                            {errors?.dateOfBirth && (
                                                <div className="invalid-feedback">{errors?.dateOfBirth?.message}</div>
                                            )}
                                            </div>
                                        </div>
                                        <div className={`col-lg-4 col-md-12 hide-in-desktop ${window.innerWidth <= 500 ? 'mt-3' : ''}`}>
                                            <div className="form-group">
                                                <label className="form-label" for="countryOfResidency">Country of Residency<span style={{ color: 'red' }}> *</span></label>
                                                <Controller
                                                    name="countryOfResidency"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            id="countryOfResidency"
                                                            placeholder="Select Country of Residency"
                                                            className={`${errors.countryOfResidency ? 'is-invalid' : ''}`}
                                                            options={countryData.map(country => ({
                                                                label: country.name,
                                                                value: country.country_id
                                                            }))}
                                                            styles={customStyles}
                                                            value={field.value ? { label: countryData.find(country => country.country_id == field.value)?.name, value: field.value } : ''}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption?.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors?.countryOfResidency && (
                                                    <div className="invalid-feedback">{errors?.countryOfResidency?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className={`col-lg-4 col-md-12 hide-in-desktop ${window.innerWidth <= 500 ? 'mt-3' : ''}`}>
                                            <div className="form-group">
                                                <label className="form-label" for="countryOfNationality">Country of Nationality<span style={{ color: 'red' }}> *</span></label>
                                                <Controller
                                                    name="countryOfNationality"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            id="countryOfNationality"
                                                            placeholder="Select Country of Nationality"
                                                            className={`${errors.countryOfNationality ? 'is-invalid' : ''}`}
                                                            options={countryData.map(country => ({
                                                                label: country.name,
                                                                value: country.country_id
                                                            }))}
                                                            styles={customStyles}
                                                            value={field.value ? { label: countryData.find(country => country.country_id == field.value)?.name, value: field.value } : ''}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption?.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors?.countryOfNationality && (
                                                    <div className="invalid-feedback">{errors?.countryOfNationality?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label">Email<span style={{ color: 'red' }}> *</span></label>
                                                <input type="text" className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`}
                                                    placeholder="abcd@gmail.com"
                                                    {...register('email', {
                                                        required: `Please Enter email`
                                                    })}
                                                />
                                                {errors?.email && (
                                                    <div className="invalid-feedback">{errors?.email?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleFormControlSelect1">Profession<span style={{ color: 'red' }}> *</span></label>
                                                <Controller
                                                    name="profession"
                                                    control={control}
                                                    render={({ onChange, field, value }) => (
                                                        <Select
                                                            id="profession"
                                                            placeholder="Select Profession"
                                                            className={`${errors?.profession ? 'is-invalid' : ''}`}
                                                            options={Object.keys(professions).map((item) => ({
                                                                label: `${professions[item]}`,
                                                                value: professions[item]
                                                            }))}
                                                            styles={customStyles}
                                                            value={profession ? { label: `${profession} `, value: professions[profession] } : ''}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption?.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors?.profession && (
                                                    <div className="invalid-feedback">{errors?.profession?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label">Mobile Number<span style={{ color: 'red' }}> *</span></label>
                                                <div className={window.innerWidth <= 500 ? 'd-flex align-items-center justify-content-between' : 'row'}>
                                                    <div className='col-lg-5 col-sm-5' style={window.innerWidth >= 500 ? { borderRadius: 15 } : { width: '35%' }}>
                                                        <Select
                                                            placeholder="select"
                                                            className={`${errors.mobileCode ? 'is-invalid' : ''}`}
                                                            // options={Object.keys(countryCodes).map((country) => ({
                                                            //     label: `${country} (${countryCodes[country]})`,
                                                            //     value: countryCodes[country]
                                                            // }))}   
                                                            options={countryData.map(country => ({
                                                                label: `${country.name} (${country.country_code})`,
                                                                value: country.country_code
                                                            }))}
                                                            styles={customStyles}
                                                            value={mobileCodeValue ? { label: `${mobileCodeValue} `, value: mobileCodeValue} : ''}
                                                            onChange={(selectedOption) => {
                                                                setValue('mobileCode', selectedOption?.value);
                                                                trigger('mobileCode');
                                                            }}
                                                        />
                                                        {errors?.mobileCode && (
                                                            <div className="invalid-feedback">{errors?.mobileCode?.message}</div>
                                                        )}
                                                    </div>
                                                    <div className='col-lg-7 col-md-6' style={window.innerWidth >= 500 ? {} : { width: '60%' }}>
                                                        <input
                                                            type="text"
                                                            className={`form-control form-control-lg ${errors.mobileNumber ? 'is-invalid' : ''}`}
                                                            placeholder="000 000 0000"
                                                            name="mobileNumber"
                                                            {...register('mobileNumber')}
                                                        />
                                                        {errors?.mobileNumber && (
                                                            <div className="invalid-feedback">{errors?.mobileNumber?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" >Landline Number</label>
                                                <div className={window.innerWidth <= 500 ? 'd-flex align-items-center justify-content-between' : 'row'}>
                                                    <div className='col-lg-5 col-sm-2' style={window.innerWidth >= 500 ? {} : { width: '55%' }}>
                                                        <Controller
                                                            name="landlineCode"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    placeholder="select"
                                                                    className={`${errors.landlineCode ? 'is-invalid' : ''}`}
                                                                    // options={Object.keys(countryCodes).map((country) => ({
                                                                    //     label: `${country} (${countryCodes[country]})`,
                                                                    //     value: countryCodes[country]
                                                                    // }))}
                                                                    options={countryData.map(country => ({
                                                                        label: `${country.name} (${country.country_code})`,
                                                                        value: country.country_code
                                                                    }))}
                                                                    styles={customStyles}
                                                                    value={landLineCodeValue ? { label: `${landLineCodeValue}`, value: landLineCodeValue } : ''}
                                                                    onChange={(selectedOption) => {
                                                                        setValue('landlineCode', selectedOption?.value);
                                                                        trigger('landlineCode');
                                                                    }}
                                                                />
                                                            )}
                                                        />

                                                        {errors?.landlineCode && (
                                                            <div className="invalid-feedback">{errors?.landlineCode?.message}</div>
                                                        )}
                                                    </div>
                                                    <div className='col-lg-7 col-md-6' style={window.innerWidth >= 500 ? {} : { width: '60%' }}>
                                                        <input
                                                            type="text"
                                                            className={`form-control form-control-lg ${errors.landlineNumber ? 'is-invalid' : ''}`}
                                                            placeholder="000 000 0000"
                                                            {...register('landlineNumber', {
                                                                required: `Please select Landline Number`
                                                            })}
                                                        />
                                                        {errors?.landlineNumber && (
                                                            <div className="invalid-feedback">{errors?.landlineNumber?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-12 pe-3 mt-3'>
                                        <div className="form-group">
                                            <label className="form-label" for="exampleFormControlSelect1">Sex <span style={{ color: 'red' }}>*</span></label>
                                            <Controller
                                                name="sex"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        id="sex"
                                                        placeholder="Select sex"
                                                        className={`${errors.sex ? 'is-invalid' : ''}`}
                                                        options={gender}
                                                        styles={customStyles}
                                                        value={field.value ? { label: gender.find(sex => sex.value == field.value)?.label, value: field.value } : ''}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption?.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors?.sex && (
                                                <div className="invalid-feedback">{errors?.sex?.message}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        (state?.countryId && state?.countryId == 100) &&
                        <div className='row'>
                            <div className='col-lg-6 col-md-12'>
                                <div className='white-background mt-4 p-3'>
                                    <div className="form-group">
                                        <label className="form-label" >Aadhar Number<span style={{ color: 'red' }}> *</span></label>
                                        <input
                                            type="text"
                                            className={`form-control form-control-lg ${errors.aadharNumber ? 'is-invalid' : ''}`}
                                            placeholder="111111111111"
                                            {...register('aadharNumber', {
                                                required: `Please Enter Aadhar Number`
                                            })}
                                            onBlur={(e)=>aadhaarValidate(e.target.value)}
                                        />
                                        {errors?.aadharNumber && (
                                            <div className="invalid-feedback">{errors?.aadharNumber?.message}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <div className='white-background  mt-4 p-3'>
                                    <div className="form-group">
                                        <label className="form-label" >Pan Number<span style={{ color: 'red' }}> *</span></label>
                                        <input
                                            type="text"
                                            className={`form-control form-control-lg ${errors.panNumber ? 'is-invalid' : ''}`}
                                            placeholder="Pan Number"
                                            {...register('panNumber', {
                                                required: `Please Enter Pan Number`
                                            })}
                                            onBlur={(e)=>panValidate(e.target.value)}
                                        />
                                        {errors?.panNumber && (
                                            <div className="invalid-feedback">{errors?.panNumber?.message}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='row'>
                        {(state?.countryId && state?.countryId == 234) &&
                            <div className='col-lg-6 col-md-12'>
                                <div className='white-background mt-4 p-3'>
                                    <div className="form-group ">
                                        <label className="form-label" >SSN No.</label>
                                        <input
                                            type="text"
                                            className={`form-control form-control-lg ${errors.ssnNo ? 'is-invalid' : ''}`}
                                            placeholder="SSN No"
                                            {...register('ssnNo', {
                                                required: `Please Enter SSN No`
                                            })}
                                        />
                                        {errors?.ssnNo && (
                                            <div className="invalid-feedback">{errors?.ssnNo?.message}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='col-lg-6 col-md-12'>
                            <div className='white-background  mt-4 p-3'>
                                <div className='row d-flex align-items-center'>
                                    <div className='col-lg-4'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value=""  {...register('islegalEntity')} onChange={handleCheckboxChange} />
                                            <label className="form-check-label" for="defaultCheck1">
                                                LEI Number
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-lg-8'>
                                        <div className="form-group">
                                            <label className="form-label" >
                                                LEI Number
                                                {isLegalEntity1 && <span style={{ color: 'red' }}> *</span>}
                                            </label>
                                            <input type="text" className={`form-control form-control-lg ${errors.legalEntityNumber ? 'is-invalid' : ''}`} placeholder="Enter LEI Number"
                                                {...register('legalEntityNumber', {
                                                    required: `Please Enter LEI Number`
                                                })}
                                            />
                                            {errors?.legalEntityNumber && (
                                                <div className="invalid-feedback">{errors?.legalEntityNumber?.message}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='white-background mt-4 p-3'>
                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox"
                                {...register('isSoleTrader', {
                                    required: `Please Select`
                                })}
                                onChange={handleCheckboxChange1}
                            />
                            {errors?.isSoleTrader && (
                                <div className="invalid-feedback">{errors?.isSoleTrader?.message}</div>
                            )}
                            <label className="form-check-label" for="defaultCheck1">
                                Are you a Sole Trader?
                            </label>
                        </div>
                        <div className='row'>
                            {(state?.countryId && state?.countryId == 100) &&
                                <div className='col-lg-6 col-md-12'>
                                    <div className="form-group ">
                                        <label className="form-label" >GST No</label>
                                        <input type="text" className={`form-control form-control-lg ${errors.gstNo ? 'is-invalid' : ''}`} placeholder="GST Number"
                                            {...register('gstNo', {
                                                required: `Please Enter GST Number`
                                            })}
                                        />
                                        {errors?.gstNo && (
                                            <div className="invalid-feedback">{errors?.gstNo?.message}</div>
                                        )}
                                    </div>
                                </div>
                            }
                            {(state?.countryId && state?.countryId != 1) &&
                                <div className={window.innerWidth <= 500 ? 'col-lg-6 col-md-12 mt-3' : 'col-lg-6 col-md-12'}>
                                    <div className="form-group ">
                                        <label className="form-label" >VAT</label>
                                        <input type="text" className={`form-control form-control-lg ${errors.vatNo ? 'is-invalid' : ''}`} placeholder="VAT Number"
                                            {...register('vatNo')}
                                            onBlur={checkVat}
                                            disabled={!isSoleTrader1}
                                        />
                                        {errors?.vatNo && (
                                            <div className="invalid-feedback">{errors?.vatNo?.message}</div>
                                        )}
                                    </div>
                                </div>

                            }
                            <div className={window.innerWidth <= 500 ? 'col-lg-6 col-md-12 mt-3' : 'col-lg-6 col-md-12'}>
                                <div className="form-group ">
                                    <label className="form-label" >{`${watch('firstName')}  ${watch('middleName') || ''} ${watch('lastName')}`} Trading as {isSoleTrader1 && <span style={{ color: 'red' }}> *</span>}</label>
                                    <input type="text" placeholder="Trading name" className={`form-control form-control-lg ${errors.tradingAs ? 'is-invalid' : ''}`}
                                        {...register('tradingAs', {
                                            required: `Please Enter Trading Name`
                                        })}
                                        disabled={!isSoleTrader1}
                                    />
                                    {errors?.tradingAs && (
                                        <div className="invalid-feedback">{errors?.tradingAs?.message}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='white-background p-3 mt-4'>
                                <p className='form-label mb-2'>Social Media links</p>
                                <div className='form'>
                                    <form>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <div className="form-group mt-3">
                                                    <label className="form-label" >Facebook</label>
                                                    <input type="text" className="form-control" placeholder="Enter Link" {...register('facebookLink')} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <div className="form-group mt-3">
                                                    <label className="form-label" >LinkedIn</label>
                                                    <input type="text" className="form-control" placeholder="Enter Link" {...register('instagramLink')} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <div className="form-group mt-3">
                                                    <label className="form-label" >Instagram</label>
                                                    <input type="text" className="form-control" placeholder="Enter Link" {...register('linkedinLink')} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <div className="form-group mt-3">
                                                    <label className="form-label" >Twitter</label>
                                                    <input type="text" className="form-control" placeholder="Enter Link" {...register('twitterLink')} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bottom-button'>
                        <button onClick={goBack} className={window.innerWidth <= 500 ? 'w-100 btn-secondary hide-in-mobile' : 'btn-secondary'} style={{ marginRight: 16 }}>Previous</button>
                        <button type='submit' className={window.innerWidth <= 500 ? 'w-100 btn-primary' : 'btn-primary'}>Save & Next</button>
                    </div>

                </form>


                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header >
                        <h5 className="modal-title text-center text-blue"><b>High Net worth Individual</b></h5>
                        <div onClick={handleClose} className='position-absolute top-0 end-0'>
                            <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className='form-group p-2'>
                                <select className="form-control form-select" value={getValues('highNetValue')} onChange={(e) => { setValue('highNetValue', e.target.value); setRerenderFlag(prev => !prev); }}>
                                    <option value="">Select High Net Worth</option>
                                    <option value="0-1">High Net Worth $0-1 M</option>
                                    <option value="1-5">High Net Worth $1-5 M</option>
                                    <option value="5-30">High Net Worth $5-30 M</option>
                                    <option value="30+">High Net Worth $30+ M</option>
                                </select>
                            </div>
                            <div className="form-group mt-1 p-2">
                                <textarea
                                    className="form-control"
                                    placeholder="Enter Source of Wealth"
                                    rows="3"
                                    value={getValues('highNetSourceOfWealth')}
                                    onChange={(e) => { setValue('highNetSourceOfWealth', e.target.value); setRerenderFlag(prev => !prev); }}
                                />
                                <div className='d-flex justify-content-end'>
                                    <div style={{fontSize:12}}>50 characters at least (max 1,000)</div>
                                </div>
                            </div>
                            <div className="form-group mt-1 p-2">
                                <textarea type="text" className="form-control" placeholder="Enter Source of Income" rows="3" value={getValues('highNetSourceOfIncome')} onChange={(e) => { setValue('highNetSourceOfIncome', e.target.value); setRerenderFlag(prev => !prev); }} />
                                <div className='d-flex justify-content-end'>
                                    <div style={{fontSize:12}}>50 characters at least (max 1,000)</div>
                                </div>
                            </div>
                        </form>

                    </Modal.Body>
                    <Modal.Footer >
                            <button onClick={handleClose}  disabled={[highNetSourceOfIncome, highNetSourceOfWealth, highNetValue].some(value => value === "") ||
        !(
        (highNetSourceOfIncome?.length >= 50 && highNetSourceOfIncome?.length <= 1000) &&
        (highNetSourceOfWealth?.length >= 50 && highNetSourceOfWealth?.length <= 1000)
        )
    } type="button" className="btn btn-primary w-50 mb-3">Submit</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showModalTwo} onHide={handleCloseModalTwo} centered>
                    <Modal.Header >
                        <h5 className="modal-title text-center text-blue"><b>Politically Exposed Person (PEP)</b></h5>
                        <div onClick={handleCloseModalTwo} className='position-absolute top-0 end-0'>
                            <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="form-group p-2 mb-3">
                                <textarea type="text" className="form-control" value={getValues('politicallyExposedSourceOfWealth')} placeholder="Why do you believe you are a PEP ? " rows="3" onChange={(e) => { setValue('politicallyExposedSourceOfWealth', e.target.value); setRerenderFlag(prev => !prev); }} />
                                <div className='d-flex justify-content-end'>
                                    <div style={{fontSize:12}}>20 characters at least</div>
                                </div>
                            </div>
                        </form>


                        <div className='d-flex justify-content-center align-items-center p-2' style={{ gap: 5, marginTop: -10 }}>
                            <button onMouseMoveCapture={() => handlePepClick('PEP1')} type="button" className={`pepButton w-50 mb-3 ${selectedPep === 'PEP1' ? 'pepButtonActive' : ''}`}>PEP1</button>
                            <button onMouseMoveCapture={() => handlePepClick('PEP2')} type="button" className={`pepButton w-50 mb-3 ${selectedPep === 'PEP2' ? 'pepButtonActive' : ''}`}>PEP2</button>
                            <button onMouseMoveCapture={() => handlePepClick('PEP3')} type="button" className={`pepButton w-50 mb-3 ${selectedPep === 'PEP3' ? 'pepButtonActive' : ''}`} >PEP3</button>
                            <button onMouseMoveCapture={() => handlePepClick('PEP4')} type="button" className={`pepButton w-50 mb-3 ${selectedPep === 'PEP4' ? 'pepButtonActive' : ''}`} >PEP4</button>
                        </div>
                        {selectedPep && (
                            <div className='blueBoxText'>
                                <div className='blueBoxTextstyles'>
                                    <ul style={{ marginBottom: 0 }}>
                                        {pepInfo[selectedPep].map((info, index) => (
                                            <li key={index}>{info}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}
                        <div className='d-flex justify-content-end'>
                            <button type="button" disabled={getValues('politicallyExposedSourceOfWealth')?.length <= 20} className="btn btn-primary mt-3" onClick={handleCloseModalTwo}>Save</button>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={showModalThree} onHide={handleCloseModalThree} centered>
                    <Modal.Header >
                        <h5 className="modal-title text-center text-blue"><b>Politically Exposed Person (PEP)</b></h5>
                        <div onClick={handleCloseModalThree} className='position-absolute top-0 end-0'>
                            <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                        </div>
                    </Modal.Header>
                    <Modal.Body className='pt-0'>
                            <div className="form-group">
                            <ul style={{ marginBottom: 0 }}>
                            
                                <b>A PEP is someone who is, or was, in a position to influence politics or make significant economic or financial decisions.</b><br/>
                                <b>According to FATF, there are six main types of PEPs:</b>
                                {pepInformation.map((info, index) => (
                                    <div className='px-4'>
                                   <li key={index}>
                                   <b >{info}</b>
                               </li>
                                    </div>
                                ))}
                                </ul>
                            </div>
                    </Modal.Body>
                    {/* <Modal.Footer >
                    <button onClick={handleCloseModalThree} type="button" className="btn btn-primary w-50 mb-3">Submit</button>
                </Modal.Footer> */}
                </Modal>
                <Modal show={showModal4} onHide={handleCloseModal4} centered>
                    <Modal.Header >
                        <h5 className="modal-title text-center text-blue"><b>High Net Worth Individual (HNI)</b></h5>
                        <div onClick={handleCloseModal4} className='position-absolute top-0 end-0'>
                            <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                        </div>
                    </Modal.Header>
                    <Modal.Body className='pt-0'>
                            <div className="form-group">
                                <b>A High Net Worth Individual is:</b>
                                <ul style={{ marginBottom: 16 }}>
                                    <li>
                                        A high-net-worth individual (HNWI) is a person with typically at least $1 million in liquid financial assets.
                                    </li>
                                    <li>
                                        North America had a record number of high-net-worth individuals, at 7.9 million people as of 2023.
                                    </li>
                                    <li>An ultra-high-net-worth individual has a net worth of more than $30 million.</li>
                                </ul>
                                <div style={{ marginBottom: 16 }}>
                                <b >The Net worth of someone classed as an HNI is after deducting the equity / value of their primary residence.</b>
                                </div>
                                <b  className='mt-3'>Furthermore the classification of wealth is as follows:</b>
                                <ul style={{ marginBottom: 0 }}>
                                    <li>Millionaires next door, who have $1 million to $5 million in investable wealth</li>
                                    <li>Mid-tier millionaires with $5 million to $30 million to invest</li>
                                    <li>Ultra-HNWIs, who have more than $30 million</li>
                                </ul>
                            </div>
                    </Modal.Body>
                    {/* <Modal.Footer >
                    <button onClick={handleCloseModalThree} type="button" className="btn btn-primary w-50 mb-3">Submit</button>
                </Modal.Footer> */}
                </Modal>
            </div>
        </>

    )
}

export default IndividualPersonalDetails