import React, { useState } from 'react'
import '../../Profile.scss';
import OnboardClientBar from '../../../../components/OnboardClientBar/OnboardClientBar';
import upload from '../../../../assets/images/upload.png'
import PersonalDetail from '../../../../assets/images/personalDetails.png'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '../../../../assets/images/Cancel_icon.png'
import BlackInfoIcon from '../../../../assets/images/black _Info Circle_.png'

function AddDirectorPersonlDetails() {
    const [show, setShow] = useState(false);
    const [showModalTwo, setShowModalTwo] = useState(false);
    const [showModalThree, setShowModalThree] = useState(false);
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/profile/director_address`;
        navigate(path);
    }
    const goBack = () => {
        navigate(-1); // This navigates back to the previous page
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseModalTwo = () => setShowModalTwo(false);
    const handleShowModalTwo = () => setShowModalTwo(true);

    const handleCloseModalThree = () => setShowModalThree(false);
    const handleShowModalThree = () => setShowModalThree(true);
    return (
        <div className='grey-background'>
            <OnboardClientBar
                mainTitle={`Onboard Business Client `}
                title1={`COMPANY DETAILS`}
                title2={`COMPANY INFORMATION`}
                title3={`DIRECTOR LIST`}
                title4={`SHAREHOLDER LIST`}
                backgroundColor1={'#037AD0'}
                backgroundColor2={'#037AD0'}
                backgroundColor3={'#037AD0'} />

            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background mt-4 p-3'>
                        <div className='row align-items-end'>
                            <div className='col-8'>
                                <div className="form-group">
                                    <label className="form-label" for="exampleInputEmail1">Oomero ID</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Oomero ID" />
                                </div>
                            </div>
                            <div className='col-4'>
                                <p className='btn-primary fw-normal mb-0 text-center' style={{ height: 'auto', cursor: 'pointer' }}>Get Details</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background  mt-4 p-3'>
                        <div className='row align-items-center mb-2'>
                            <div className='col-lg-2 col-mg-2'>
                                <div className="d-flex align-items-center justify-content-center" style={{ width: '80px', height: '80px', borderRadius: '10px', backgroundColor: '#01C9A51A' }}>
                                    < img src={upload} style={{ width: '35px', height: '35px' }} />
                                </div>
                            </div>
                            <div className='col-lg-10 col-mg-10'>
                                <div className="ps-4 d-flex flex-column justify-content-center">
                                    <a className='upload' style={{ color: '#037924' }}> Upload File</a>
                                    <p className='resend mb-0'>PDF, JPEG or PNG less than 5MB</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12 col-md-12'>
                    <div className='white-background mt-4 p-3'>
                        <h1 className='text-blue px-2 fw-normal'>Please select all that may apply</h1>
                        <hr />
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Shareholder
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="form-check">
                                    <input onClick={handleShowModalTwo} className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Politically exposed person
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="form-check">
                                    <input onClick={handleShow} className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        High Net worth Individual
                                    </label>
                                    <img onClick={handleShowModalThree} src={BlackInfoIcon} style={{ width: 16, objectFit: 'contain', cursor: 'pointer', marginLeft: 15 }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12 col-md-12'>
                    <div className='white-background mt-4 p-3'>
                        <div className='row align-items-center'>
                            <div className='col-lg-4'>
                                <div className="form-group mt-3">
                                    <label className="form-label" for="exampleInputEmail1">Shareholding Percentage</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Shareholding Percentage" />
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Ultimate Beneficial Owner (UBO)
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Person with Significant Control (PSC)
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='white-background p-3 mt-4'>
                        <div className='d-flex align-items-center'>
                            <img src={PersonalDetail} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                            <h1 className='text-ambed fw-normal'>Personal Details</h1>
                        </div>
                        <hr />
                        <div className='form'>
                            <form>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-12'>
                                        <div className="form-group">
                                            <label className="form-label" for="exampleFormControlSelect1">Title</label>
                                            <select id="statusDropdown" className="form-control form-select">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-12'>
                                        <div className="form-group">
                                            <label className="form-label" for="exampleFormControlSelect1">Country of Residency</label>
                                            <select id="statusDropdown" className="form-control form-select">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-12'>
                                        <div className="form-group">
                                            <label className="form-label" for="exampleFormControlSelect1">Country of Nationality</label>
                                            <select id="statusDropdown" className="form-control form-select">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">First Name</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter First Name" />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Middle Name</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Middle Name" />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Last Name</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Last Name" />
                                        </div>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Previous  Name / AKA</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Previous  Name / AKA" />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Date of Birth</label>
                                            <input type="date" className="form-control" id="exampleInputEmail1" placeholder="Date" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background mt-4 p-3'>
                        <div className="form-group">
                            <label className="form-label" for="exampleInputEmail1">Aadhar Number</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Addhar No." />
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background  mt-4 p-3'>
                        <div className="form-group">
                            <label className="form-label" for="exampleInputEmail1">Pan Number</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Pan Number" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background mt-4 p-3'>
                        <div className="form-group ">
                            <label className="form-label" for="exampleInputEmail1">SSN No.</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="SSN No.   " />
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background  mt-4 p-3'>
                        <div className='row d-flex align-items-center'>
                            <div className='col-lg-4'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Legal Entity
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-8'>
                                <div className="form-group">
                                    <label className="form-label" for="exampleInputEmail1">Legal Entity Number</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Legal Entity Number" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end my-4'>
                <div className='d-flex align-items-center'>
                    <button onClick={goBack} className='btn-secondary' style={{ marginRight: 16 }}>Previous</button>
                    <button onClick={routeChange} className='btn-primary'>Save & Next</button>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header >
                    <h5 className="modal-title text-center text-blue"><b>High Net worth Individual</b></h5>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className='form-group p-2'>
                            <select id="statusDropdown"
                                className="form-control form-select">
                                <option value="Mass Affluent $100-1m">Mass Affluent $100-1m</option>
                                <option value="High Net Worth $1-5m">High Net Worth $1-5m</option>
                                <option value="UHNI US$5m+">UHNI US$5m+</option>
                            </select>
                        </div>
                        <div className="form-group mt-2 p-2">
                            <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Source of Wealth" rows="3" />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer >
                    <button onClick={handleClose} type="button" className="btn btn-primary w-50 mb-3">Submit</button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalTwo} onHide={handleCloseModalTwo} centered>
                <Modal.Header >
                    <h5 className="modal-title text-center text-blue"><b>Politically Exposed Person (PEP)</b></h5>
                    <div onClick={handleCloseModalTwo} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mt-3 p-2">
                            <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Source of Wealth" rows="3" />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer >
                    <button onClick={handleCloseModalTwo} type="button" className="btn btn-primary w-50 mb-3">Submit</button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalThree} onHide={handleCloseModalThree} centered>
                <Modal.Header >
                    <h5 className="modal-title text-center text-blue"><b>Title text</b></h5>
                    <div onClick={handleCloseModalThree} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mt-3 p-2">
                            {/* <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Source of Wealth" rows="3" /> */}
                            <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</h6>
                        </div>
                    </form>
                </Modal.Body>
                {/* <Modal.Footer >
                    <button onClick={handleCloseModalThree} type="button" className="btn btn-primary w-50 mb-3">Submit</button>
                </Modal.Footer> */}
            </Modal>
        </div>
    )
}

export default AddDirectorPersonlDetails
