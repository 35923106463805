import React from 'react'
import '../../Profile/Profile.scss';
import OnboardClientBar from '../../../components/OnboardClientBar/OnboardClientBar';
import LinkCompnay from '../../../assets/images/Link_company.png'
import BankDetails from '../../../assets/images/bank_details.png'
import { useNavigate, useHistory } from 'react-router-dom';
import Logo from '../../../assets/images/logo.png'

function WebformBankDetails() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };
    const routeChange = () => {
        let path = `/individual_onboarding_web_kyc_verification`;
        navigate(path);
    }
    return (
        <div>
            <div className='white-background py-2 position-fixed w-100' style={{ borderRadius: 0 }}>
                <div className="d-flex justify-content-center">
                    <img src={Logo} style={{ objectFit: 'contain', width: 150 }} />
                </div>
            </div>
            <div style={{padding:20}}>
            <div className='mt-5'>

            </div>
                <OnboardClientBar
                    mainTitle={`Onboard Individual Client`}
                    title1={`PERSONAL DETAILS`}
                    title2={`ADDRESS`}
                    title3={`BANK DETAILS`}
                    title4={`KYC Verification`}
                    backgroundColor1={'#037AD0'}
                    backgroundColor2={'#037AD0'}
                    backgroundColor3={'#037AD0'} />

                <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                        <div className='white-background p-3 mt-4'>
                            <div className='d-flex align-items-center'>
                                <img src={BankDetails} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                                <h1 className='text-success fw-normal'>Bank Details</h1>
                            </div>
                            <hr />
                            <div className='form'>
                                <form>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group mt-2">
                                                <label className="form-label" for="exampleFormControlSelect1">Bank Name</label>
                                                <select id="statusDropdown" className="form-control form-select">
                                                    <option selected>HDFC</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleInputEmail1">Account No</label>
                                                <input type="text" className="form-control" placeholder="Account No" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleInputEmail1">Sort Code</label>
                                                <input type="text" className="form-control" placeholder="Sort Code" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleInputEmail1">Swift/BIC Code</label>
                                                <input type="text" className="form-control" placeholder="Swift/BIC Code" rows="5" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleInputEmail1">IBAN</label>
                                                <div className='row'>
                                                    <div className='col-2'>
                                                        <input type="text" className="form-control" placeholder="" />
                                                    </div>
                                                    <div className='col-2'>
                                                        <input type="text" className="form-control" placeholder="" />
                                                    </div>
                                                    <div className='col-2'>
                                                        <input type="text" className="form-control" placeholder="" />
                                                    </div>
                                                    <div className='col-3'>
                                                        <input type="text" className="form-control" placeholder="" />
                                                    </div>
                                                    <div className='col-3'>
                                                        <input type="text" className="form-control" placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleInputEmail1">Address Registered with Bank</label>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <div className=' border-grey-form'>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" style={{ width: 18, height: 18, marginTop: 3, marginLeft: -15 }} />
                                                                <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 14 }}>
                                                                    Home Address
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className=' border-grey-form'>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" style={{ width: 18, height: 18, marginTop: 3, marginLeft: -15 }} />
                                                                <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 14 }}>
                                                                    Sole Trader
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className=' border-grey-form'>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" style={{ width: 18, height: 18, marginTop: 3, marginLeft: -15 }} />
                                                                <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 14 }}>
                                                                    Other
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <textarea type="text" className="form-control mt-4" id="exampleInputEmail1" placeholder="" rows="4" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between my-4'>
                                        <button className='btn-secondary fw-normal' style={{ marginRight: 16 }}>Link Another Account</button>
                                        <button className='btn-primary'>Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className='white-background p-3 mt-4'>
                            <div className='d-flex align-items-center'>
                                <img src={LinkCompnay} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                                <h1 className='text-ambed fw-normal'>Link Company</h1>
                            </div>
                            <hr />
                            <div className='form'>
                                <form>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label" for="flexCheckDefault" style={{ fontSize: 12 }}>
                                            Only add if you are a director / on board or a major shareholder - above 15%
                                        </label>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleFormControlSelect1">Company Country</label>
                                                <select id="statusDropdown" className="form-control form-select">
                                                    <option selected>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleInputEmail1">Company Name</label>
                                                <input type="text" className="form-control" placeholder="Enter company Name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleInputEmail1">Company Reg No.</label>
                                                <input type="text" className="form-control" placeholder="Enter company Reg No." />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleFormControlSelect1">Designation</label>
                                                <select id="statusDropdown" className="form-control form-select">
                                                    <option selected>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleFormControlSelect1">Type of Director</label>
                                                <select id="statusDropdown" className="form-control form-select">
                                                    <option selected>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleInputEmail1">Shareholder</label>
                                                <div className='row mt-3'>
                                                    <div className='col-5'>
                                                        <div className=' border-grey-form'>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" style={{ width: 18, height: 18, marginTop: 3, marginLeft: -15 }} />
                                                                <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 14 }}>
                                                                    Yes
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-5'>
                                                        <div className=' border-grey-form'>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" style={{ width: 18, height: 18, marginTop: 3, marginLeft: -15 }} />
                                                                <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 14 }}>
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-4">
                                                <label className="form-label" for="exampleInputEmail1">Ultimate Beneficial Owner</label>
                                                <div className='row mt-3'>
                                                    <div className='col-5'>
                                                        <div className=' border-grey-form'>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" style={{ width: 18, height: 18, marginTop: 3, marginLeft: -15 }} />
                                                                <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 14 }}>
                                                                    Yes
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-5'>
                                                        <div className=' border-grey-form'>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" style={{ width: 18, height: 18, marginTop: 3, marginLeft: -15 }} />
                                                                <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 14 }}>
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between my-4'>
                                        <button className='btn-secondary fw-normal' style={{ marginRight: 16 }}>Link Another Account</button>
                                        <button className='btn-primary'>Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row '>
                    <div className='col-lg-6 col-md-12 mt-4'>
                        <div className='custom-table'>
                            <div className='custom-table-head'>
                                <div className='row'>
                                    <div className='col-2'>
                                        <h5 className='text-blue'>No</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>Bank Name</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>Account NO.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row'>
                                    <div className='col-2'>
                                        <h5 className='text-blue'>1</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>SBI</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>1234567890</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row'>
                                    <div className='col-2'>
                                        <h5 className='text-blue'>2</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>HDFC</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>1234567890121342</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row'>
                                    <div className='col-2'>
                                        <h5 className='text-blue'>3</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>BOI</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>1234567890121342</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 mt-4'>
                        <div className='custom-table'>
                            <div className='custom-table-head'>
                                <div className='row'>
                                    <div className='col-2'>
                                        <h5 className='text-blue'>No</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>Company Name</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>Company Reg No</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row'>
                                    <div className='col-2'>
                                        <h5 className='text-blue'>1</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>123456</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>1234567890</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row'>
                                    <div className='col-2'>
                                        <h5 className='text-blue'>2</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>123456</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>1234567890121342</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row'>
                                    <div className='col-2'>
                                        <h5 className='text-blue'>3</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>123456</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-blue'>1234567890121342</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-end my-4'>
                    <div className='d-flex align-items-center'>
                        <button onClick={goBack} className='btn-secondary' style={{ marginRight: 16 }}>Previous</button>
                        <button onClick={routeChange} className='btn-primary'>Save & Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WebformBankDetails
