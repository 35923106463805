import React from 'react'
import './HeaderBox.scss'
import LogoIcon from '../../assets/images/LogoIcon.png'

function HeaderBox({ showblueBox,showHeader,headerName, headerText,headerText2 }) {
    return (
        <div className='headerbox-css'>
            {showHeader && <div className='d-flex justify-content-between'>
                <p className='header-name'>{headerName}</p>
                <img src={LogoIcon} className='logoicon' />
            </div>}
            {showblueBox && <div className='headerline w-100'>
                <p className='header-text'>{headerText}{headerText2}</p>
            </div>}
        </div>
    )
}

export default HeaderBox