import React from 'react';
import './ContentBox.scss';

function PersonalDetailsData(props) {
    const { nationalitytext, nationtext, firstname, nametext, previousname, previoustext, professiontext, businesstext, mobiletext, mobileno, emailText, emailidText, aadharhead, aadharText, middletext, residencehead, residenceText, middlehead, datehead, dateText, landlinehead, landline_no, panhead, pan_no, titlehead, titlename, lastnamehead, lastnametext, shouldApplyMargin, shouldApplyMargin2, genderName, genderText, ssn_head, ssn_no, columnLines,columnLines3
    } = props;

    return (
        <>
            <div className='contentbox-css'>
                <div className='d-flex'>
                    <div className='col-4'>
                        <p className='text1'>{nationalitytext}<span className='text2' style={{ fontWeight: 400 }}>{nationtext}</span></p>
                        <p className='text1'>{firstname}<span className='text2' style={{ fontWeight: 400 }}>{nametext}</span></p>
                        <p className='text1'>{previousname}<span className='text2' style={{ fontWeight: 400 }}>{previoustext}</span></p>

                        {columnLines != false &&
                            <>
                                <p className='text1'>{professiontext}<span className='text2' style={{ fontWeight: 400 }}>{businesstext}</span></p>
                                <p className='text1'>{mobiletext}<span className='text2' style={{ fontWeight: 400 }}>{mobileno}</span></p>
                                <p className='text1'>{emailText}<span className='text2' style={{ fontWeight: 400 }}>{emailidText}</span></p>
                                {aadharhead && 
                                    <p className='text1'>{aadharhead}<span className='text2' style={{ fontWeight: 400 }}>{aadharText}</span></p>
                                }
                            </>
                        }
                    </div>
                    <div className='col-4'>
                        <p className='text1'>{residencehead}<span className='text2' style={{ fontWeight: 400 }}>{residenceText}</span></p>
                        <p className='text1'>{middlehead}<span className='text2' style={{ fontWeight: 400 }}>{middletext}</span></p>
                        <p className='text1'
                            style={{ marginTop: shouldApplyMargin ? '23px' : '0px' }}
                        >{datehead}
                            <span className='text2' style={{ fontWeight: 400 }}>{dateText}</span></p>

                        {columnLines != false &&
                            <>
                                <p className='text1' style={{ marginTop: 28 }}>{landlinehead}<span className='text2' style={{ fontWeight: 400 }}>{landline_no}</span></p>
                                {
                                    pan_no && <p className='text1' style={{ marginTop: 28 }}>{panhead}<span className='text2' style={{ fontWeight: 400 }}>{pan_no}</span></p>
                                }
                            </>
                        }
                    </div>
                    <div className='col-4'>
                        <p className='text1'>{titlehead} <span className='text2' style={{ fontWeight: 400 }}>{titlename}</span></p>
                        <p className='text1'>{lastnamehead}<span className='text2' style={{ fontWeight: 400 }}>{lastnametext}</span></p>
                        <p className='text1' style={{ marginTop: shouldApplyMargin2 ? '23px' : '0px' }}>{genderName}<span className='text2' style={{ fontWeight: 400 }}>{genderText}</span></p>
                        {columnLines3 != false &&
                            <>
                                {ssn_no && 
                                    <p className='text1' style={{ marginTop: 76 }}>{ssn_head}<span className='text2' style={{ fontWeight: 400 }}>{ssn_no}</span></p>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default PersonalDetailsData;
