import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { changeUserPassword } from '../ManageUsers/manageUserSlice';
import Loader from '../../components/Loader/Loader';
import '../Login/Login.scss'
import Logo from '../../assets/images/logo.png'
import LoginLeftImage from '../../assets/images/login_left_image.png'
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '../../assets/images/Cancel_icon.png'

function ChangePassword() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState(null); 
    const [passwordType, setPasswordType] = useState("password");
    const [passwordType1, setPasswordType1] = useState("password");
    const [passwordType2, setPasswordType2] = useState("password");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector(state => state.manageUserReducer);

    const schema = yup.object().shape({
        oldPassword: yup.string().required('Old Password is required'),
        password: yup.string().required('Password is required').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/,
            'Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.'
        ).test('not-same-as-old', 'New password must not be the same as old password', function (value) {
            const oldPassword = this.parent.oldPassword;
            return value !== oldPassword;
        }),
        confirm_password: yup.string().required('Confirm Password is required').oneOf([yup.ref('password'), null], 'Passwords must match').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/,
            'Confirm Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.'
        ),
    });

    const { control, register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    const routeChange = () => {
        let path = `/login`;
        navigate(path);
    }

    const onSubmit = (data) => {
        if (data) {
            handleShow();
            setFormData(data);
        } else {
            toast.error("Password is required");
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(onSubmit)();
        }
    };

    const handleConfirm = () => {
        // Continue with form submission
        handleClose();
        if (formData) {
            let requestData = Object.assign({}, formData);
            requestData.password = requestData.confirm_password;
            delete requestData.confirm_password;
            dispatch(changeUserPassword(requestData)).unwrap().then((res) => {
                toast.success(res?.message);
                reset();
                setFormData(null);
            }).catch((error) => {
                toast.error(error?.message);
            });
        }
    };

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const togglePassword1 = () => {
        if (passwordType1 === "password") {
            setPasswordType1("text")
            return;
        }
        setPasswordType1("password")
    }

    const togglePassword2 = () => {
        if (passwordType2 === "password") {
            setPasswordType2("text")
            return;
        }
        setPasswordType2("password")
    }

    return (
        <section className="vh-100">
            {isLoading && <Loader />}
            <div className='grey-background form-registration'>
                <form className='form' onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
                    <div className='white-background p-3'>
                        <h1 className='text-blue fw-normal mb-3'>Change Password</h1>
                        <div className='row mb-3'>
                            <div className='col-lg-4 col-md-12 position-relative'>
                                <div className="form-group">
                                    <label className="form-label" for="exampleInputEmail1">Current Password</label>
                                    <input type={passwordType}
                                        className={`form-control ${errors.oldPassword ? 'is-invalid' : ''}`}
                                        placeholder="Old Password"
                                        {...register('oldPassword', {
                                            required: `Please Enter Old password`
                                        })} />
                                    {errors?.oldPassword && (
                                        <div className="invalid-feedback">{errors?.oldPassword?.message}</div>
                                    )}
                                </div>
                                <div className='eye-icon' onClick={togglePassword}>
                                    {passwordType === "password" ? <FaEyeSlash size={25} color='#037AD0' /> : <FaEye color='#037AD0' size={25} />}
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12 position-relative'>
                                <div className="form-group">
                                    <label className="form-label" for="exampleInputEmail1">New Password</label>
                                    <input type={passwordType1} className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                        placeholder="New Password"
                                        {...register('password', {
                                            required: `Please Enter New Password`
                                        })} />
                                    {errors?.password && (
                                        <div className="invalid-feedback">{errors?.password?.message}</div>
                                    )}
                                </div>
                                <div className='eye-icon' onClick={togglePassword1}>
                                    {passwordType1 === "password" ? <FaEyeSlash size={25} color='#037AD0' /> : <FaEye color='#037AD0' size={25} />}
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12 position-relative'>
                                <div className="form-group">
                                    <label className="form-label" for="exampleInputEmail1">Confirm Password</label>
                                    <input type={passwordType2} className={`form-control ${errors.confirm_password ? 'is-invalid' : ''}`}
                                        placeholder="Confirm Password"
                                        {...register('confirm_password', {
                                            required: `Please Re-Enter New Password`
                                        })} />
                                    {errors?.confirm_password && (
                                        <div className="invalid-feedback">{errors?.confirm_password?.message}</div>
                                    )}
                                </div>
                                <div className='eye-icon' onClick={togglePassword2}>
                                    {passwordType2 === "password" ? <FaEyeSlash size={25} color='#037AD0' /> : <FaEye color='#037AD0' size={25} />}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-end'>
                            <div className='d-flex align-items-center'>
                                <button className='btn-secondary' style={{ marginRight: 16 }} onClick={()=>navigate('/dashboard')}>Cancel</button>
                                <button type="submit" className='btn-primary'>Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <h5 className="text-center text-blue"><b>Change Password</b></h5>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <h6 className='text-blue text-center'>Are you Sure Want to Change the Password</h6>
                </Modal.Body>
                <Modal.Footer >
                    <div className='d-flex justify-content-between'>
                        <button onClick={handleClose} type="button" className="btn-secondary mb-2" style={{ marginRight: 20, width: 100 }}>No</button>
                        <button onClick={handleConfirm} type="button" className="btn-primary mb-2" style={{ width: 100 }}>Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </section>
    )
}

export default ChangePassword
