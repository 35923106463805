const formdata = new FormData();
formdata.append("username", "oomero-tech");
formdata.append("password", "bdm*b3");

const requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};


export async function fetchData() {
    try {
        // Make an HTTP GET request using fetch()
        const response = await fetch('https://bekyc.tech5.tech/api/v4/accounts/token/obtain/', requestOptions); // Replace with your API endpoint

        // Check if the response status is OK (status code 200)
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        // Parse the response data as JSON
        const data = await response.json();

        return data.access; // You can return the data or perform further operations
    } catch (error) {
        console.error('Error:', error);
        throw error; // Handle or rethrow the error as needed
    }
}