import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import AdbIcon from '@mui/icons-material/Adb';
import './Topbar.scss'
import Modal from 'react-bootstrap/Modal';
import Logo from '../../assets/images/logo.png'
import UserIcon from '../../assets/images/user_icon.png'
import PermissionIcon from '../../assets/images/permission_icon.png'
import PasswordChange from '../../assets/images/chnagePasswordIcon.png'
import InvoiceIcon from '../../assets/images/invoive_icon.png'
import QRIcon from '../../assets/images/qr_icon.png'
import LogoutIcon from '../../assets/images/logout_icon.png'
import { FaAngleDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../pages/Login/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import { decodeToken, useJwt } from "react-jwt";

function TopBar() {
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const userToken  = localStorage.getItem('token');
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const routeInvoices = () => {
    let path = `/invoices`;
    navigate(path);
  }
  const routeGoogleAuthenticator = () => {
    let path = `/google_authenticator`;
    navigate(path);
  }
  const decodedToken = decodeToken(userToken);
  const routePermission = () => {
    let path = `/users/add_new_users`;
    navigate(path);
  }
  const routeChangePassword = () => {
    let path = `/change_password`;
    navigate(path);
  }
  const routeChangeLogout = () => {
    let path = `/login`;
    dispatch(logout());
    navigate(path);
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(()=>{
      if(((!localStorage.getItem('activePlan') || localStorage.getItem('activePlan') == null || localStorage.getItem('activePlan') == 'false') && window.location.href.split('/')[3] !=='pricing') && !decodedToken?.isOomero){
          navigate('/pricing',{ replace: true });
      }
    },[localStorage.getItem('activePlan')])

  return (
    <div className='topBar'>
      <AppBar style={{background:'#fff',position:'fixed'}}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img
              src={Logo} style={{ width: 200, objectFit: 'contain' }}
            />

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              LOGO
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleShow} sx={{ p: 0 }}>
                  <Avatar style={{ marginRight: 10 }} sx={{ bgcolor: '#037AD0' }}>{decodedToken?.firstName?.charAt(0)}</Avatar>
                  <p className='text-blue mb-0 fs-6 fw-bold'>{decodedToken?.firstName} {decodedToken?.lastName}</p>
                  <FaAngleDown className='text-black' style={{ marginLeft: 10 }} />
                </IconButton>
              </Tooltip>
              <div className='topbar-modal'>
                <Modal size="sm" style={{ marginRight: 0 }} show={show} onHide={handleClose} dialogClassName='custom-dialog'>
                  <Modal.Body>
                    <a style={{ cursor: 'pointer', textDecoration: 'none' }} aria-disabled className='d-flex align-items-center'>
                      <img src={UserIcon} style={{ width: 25, objectFit: 'contain', marginRight: 10 }} />
                      <p className='text-muted text-center mb-0'>{decodedToken?.firstName} {decodedToken?.lastName}</p>
                    </a>
                    {/* <hr style={{ marginRight: -15, marginLeft: -15, color: 'D9D9D9' }} /> */}
                    {/* <a style={{ cursor: 'pointer', textDecoration: 'none' }} className='d-flex align-items-center'>
                      <img src={PermissionIcon} style={{ width: 25, objectFit: 'contain', marginRight: 10 }} />
                      <p className='text-black-link text-center mb-0' onClick={() => { routePermission(); handleClose() }}>Permissions</p>
                    </a> */}
                    <hr style={{ marginRight: -15, marginLeft: -15, color: 'D9D9D9' }} />
                    <a style={{ cursor: 'pointer', textDecoration: 'none' }} className='d-flex align-items-center'>
                      <img src={PasswordChange} style={{ width: 25, objectFit: 'contain', marginRight: 10 }} />
                      <p className='text-black-link text-center mb-0' onClick={() => { routeChangePassword(); handleClose() }}>Change Password</p>
                    </a>
                    {
                      !decodedToken?.isOomero && <>
                        <hr style={{ marginRight: -15, marginLeft: -15, color: 'D9D9D9' }} />
                        <a style={{ cursor: 'pointer', textDecoration: 'none' }} className='d-flex align-items-center'>
                          <img src={InvoiceIcon} style={{ width: 25, objectFit: 'contain', marginRight: 10 }} />
                          <p className='text-black-link text-center mb-0' onClick={() => { routeInvoices(); handleClose() }}>View Invoices</p>
                        </a>
                      </>
                    }
                    <hr style={{ marginRight: -15, marginLeft: -15, color: 'D9D9D9' }} />
                    <a style={{ cursor: 'pointer', textDecoration: 'none' }} className='d-flex align-items-center'>
                      <img src={QRIcon} style={{ width: 25, objectFit: 'contain', marginRight: 10 }} />
                      <p className='text-black-link text-center mb-0' onClick={() => { routeGoogleAuthenticator(); handleClose() }}>Use Google Authenticator</p>
                    </a>
                    <hr style={{ marginRight: -15, marginLeft: -15, color: 'D9D9D9' }} />
                    <a style={{ cursor: 'pointer', textDecoration: 'none' }} className='d-flex align-items-center'>
                      <img src={LogoutIcon} style={{ width: 25, objectFit: 'contain', marginRight: 10 }} />
                      <p className='text-danger text-black-link text-center mb-0' onClick={routeChangeLogout}>Logout</p>
                    </a>
                  </Modal.Body>
                </Modal>
              </div>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
export default TopBar;
