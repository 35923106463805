import React, { useEffect, useState } from 'react'
import SearchIcon from '../../assets/images/search_icon.png'
import EditIcon from '../../assets/images/edit_icon.png'
import PrintIcon from '../../assets/images/Print_Icon.png'
import ViewIcon from '../../assets/images/view_icon.png'
import DeleteIcon from '../../assets/images/delete_icon.png'
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '../../assets/images/Cancel_icon.png'
import './ManageUsers.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOomeroUserList, fetchClientUserList, deactivateUser, reactivateUser, deleteUser } from './manageUserSlice';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader'
import { decodeToken, useJwt } from "react-jwt";

function ManageUsers() {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [userType, setUserType] = useState(null); //oomero or client
    const userToken  = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);
    const handleClose = () => {
        setSelectedUserId(null); 
        setComments(null);
        setShow(false); 
        setUserType(null);
    }
    const handleShow = () => setShow(true);
    const handleCloseReactive = () => {
        setShow2(false); setSelectedUserId(null);
        setUserType(null);
        setComments(null);
    };
    const handleShowReactive = () => setShow2(true);
    const handleDeleteClose = () => {
        setShowDelete(false);
        setSelectedUserId(null);
        setComments(null);
    }
    const handleDeleteShow = () => setShowDelete(true);
    const navigate = useNavigate();
    const { isLoading, oomeroUserList, clientUserList } = useSelector(state => state.manageUserReducer);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPge, setPostsPerPage] = useState(5);
    const [search, setSearch] = useState('');
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [comments, setComments] = useState(null);
    const [showClientChangeStatus, setShowClientChangeStatus] = useState(false);
    const [showEditClientButton, setShowEditClientButton] = useState(false);
    const [showDeleteClentButton, setShowDeleteClentButton] = useState(false);
    const [showPrintClentButton, setShowPrintClentButton] = useState(false);
    const [viewClientDetailButton, setViewClientDetailButton] = useState(false);
    const [viewClientList, setViewClientList] = useState(false);
    const [showOomeroChangeStatus, setShowOomeroChangeStatus] = useState(false);
    const [showEditOomerotButton, setShowEditOomerotButton] = useState(false);
    const [showDeleteOomeroButton, setShowDeleteOomeroButton] = useState(false);
    const [showPrintOomeroButton, setShowPrintOomeroButton] = useState(false);
    const [viewOomeroDetailButton, setViewOomeroDetailButton] = useState(false);
    const [viewOomeroList, setViewOomeroList] = useState(false);
    
    const routeChange = () => {
        let path = `/users/list_of_oomero_users`;
        navigate(path);
    }
    const routeChange1 = () => {
        let path = `/users/add_oomero_users`;
        navigate(path);
    }
    const routeChange2 = () => {
        let path = `/users/add_client_users`;
        navigate(path);
    }

    useEffect(()=>{
        let queryParams = `page=${currentPage}&limit=${postsPerPge}`;
        if (decodedToken?.isSuperAdmin || viewOomeroList) {
            dispatch(fetchOomeroUserList(queryParams)).unwrap().catch(error => {
                toast.error(error?.message);
            });
        }

        if(decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || viewClientList){
            dispatch(fetchClientUserList(queryParams)).unwrap().catch(error => {
                toast.error(error?.message);
            });        
        }
    },[viewClientList, viewOomeroList])

    const onRadionChange=(type)=>{
        setUserType(type);
    }

    const handleOnSearchClick=()=>{
        if(userType){
           if(userType == 'oomero') navigate(`/users/list_of_oomero_users?search=${search}`);
           if(userType == 'client') navigate(`/users/list_of_client_users?search=${search}`);
        }else{
            toast.error('Please select user type first');
        }
    }

    const handleModalOpen = (type, userId, usertype) => {
        if (type && userId && usertype) {
            setSelectedUserId(userId); 
            setUserType(usertype);
            if(type == 'deactivate'){
                handleShow();
            }
            if(type == 'activate'){
                handleShowReactive();
            }
        }
    }

    const handleOnDeactivateConfirmation = () => {
        if (selectedUserId) {
            let requestBody = {userId:selectedUserId}
            if(comments) requestBody.comments = comments;
            dispatch(deactivateUser(requestBody)).unwrap()
                .then((res) => {
                    toast.success(res?.message);
                    handleClose();
                    let queryParams = `page=${currentPage}&limit=${postsPerPge}`;
                    if (decodedToken?.isSuperAdmin || viewOomeroList) {
                        dispatch(fetchOomeroUserList(queryParams)).unwrap().catch(error => {
                            toast.error(error?.message);
                        });
                    }
            
                    if(decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || viewClientList){
                        dispatch(fetchClientUserList(queryParams)).unwrap().catch(error => {
                            toast.error(error?.message);
                        });        
                    }
                }).catch((error) => {
                    toast.error(error?.message);
                })
        }
    }

    const handleOnReactivateConfirmation = () => {
        if (selectedUserId) {
            let requestBody = {userId:selectedUserId}
            if(comments) requestBody.comments = comments;
            dispatch(reactivateUser(requestBody)).unwrap()
                .then((res) => {
                    toast.success(res?.message);
                    handleCloseReactive();
                    let queryParams = `page=${currentPage}&limit=${postsPerPge}`
                    if (decodedToken?.isSuperAdmin || viewOomeroList) {
                        dispatch(fetchOomeroUserList(queryParams)).unwrap().catch(error => {
                            toast.error(error?.message);
                        });
                    }
            
                    if(decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || viewClientList){
                        dispatch(fetchClientUserList(queryParams)).unwrap().catch(error => {
                            toast.error(error?.message);
                        });        
                    }
                }).catch((error) => {
                    toast.error(error?.message);
                })
        }
    }

    const handleOnConfirmDeleteUser = (userId) => {
        if (userId) {
            let requestBody = { userId, comments }
            dispatch(deleteUser(requestBody)).unwrap()
                .then((res) => {
                    toast.success(res?.message);
                    handleDeleteClose();
                    let queryParams = `page=${currentPage}&limit=${postsPerPge}`
                    if (decodedToken?.isSuperAdmin || viewOomeroList) {
                        dispatch(fetchOomeroUserList(queryParams)).unwrap().catch(error => {
                            toast.error(error?.message);
                        });
                    }
            
                    if(decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || viewClientList){
                        dispatch(fetchClientUserList(queryParams)).unwrap().catch(error => {
                            toast.error(error?.message);
                        });        
                    }
                }).catch((error) => {
                    toast.error(error?.message);
                })
        }
    }

    useEffect(()=>{
        if(decodedToken){
            if(decodedToken.isSuperAdmin){
                setShowClientChangeStatus(true);
                setShowEditClientButton(true);
                setShowDeleteClentButton(true);
                setShowPrintClentButton(true);
                setViewClientDetailButton(true);
                setViewClientList(true);
                
                setShowOomeroChangeStatus(true);
                setShowEditOomerotButton(true);
                setShowDeleteOomeroButton(true);
                setShowPrintOomeroButton(true);
                setViewOomeroDetailButton(true);
                setViewOomeroList(true);

            } else {
                if (decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.deactivate_client_user)) {
                    setShowClientChangeStatus(true);
                }
                
                if (decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.reactivate_client_user)) {
                    setShowClientChangeStatus(true);
                }
                
                if (decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.edit_client_user)) {
                    setShowEditClientButton(true);
                }
                
                if (decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.delete_client_user)) {
                    setShowDeleteClentButton(true);
                }
                
                if (decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.print_client_user)) {
                    setShowPrintClentButton(true);
                }
                
                if (decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.view_client_user_details)) {
                    setViewClientDetailButton(true);
                }

                if (decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.view_client_user)) {
                    setViewClientList(true);
                }
                
                if ((decodedToken.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.deactivate_oomero_user)) {
                    setShowOomeroChangeStatus(true);
                }
                
                if ((decodedToken.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.reactivate_oomero_user)) {
                    setShowOomeroChangeStatus(true);
                }
                
                if ((decodedToken.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.edit_oomero_user)) {
                    setShowEditOomerotButton(true);
                }
                
                if ((decodedToken.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.delete_oomero_user)) {
                    setShowDeleteOomeroButton(true);
                }
                
                if ((decodedToken.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.print_oomero_user)) {
                    setShowPrintOomeroButton(true);
                }
                
                if ((decodedToken.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.view_oomero_user_details)) {
                    setViewOomeroDetailButton(true);
                }

                if ((decodedToken.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.view_oomero_user)) {
                    setViewOomeroList(true);
                }

            }
        }
    },[decodedToken])

    return (
        <div className='grey-background vh-150'>
             {isLoading && <Loader/>}
            <div className='white-background p-3'>
                <h1 className='text-blue px-2 fw-normal'>Manage Users</h1>
                <hr />
                <div className="d-flex mb-2">
                    {decodedToken?.isOomero &&
                    <div className="p-2" style={{ marginRight: 30 }}>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="oomeroUsre" onChange={()=>onRadionChange('oomero')}/>
                            <label className="form-check-label" htmlFor="oomeroUsre">Oomero Users</label>
                        </div>
                    </div>
                    }
                    <div className="p-2">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="clientUser" onChange={()=>onRadionChange('client')}/>
                            <label className="form-check-label" htmlFor="clientUser">Client Users</label>
                        </div>
                    </div>
                </div>
                <div className="form-group position-relative">
                    <input type="text" className="form-control" placeholder='Search' onKeyUp={($event)=>setSearch($event.target.value)}></input>
                    <div className="position-absolute" style={{ right: 20, top: '20%' }}>
                        <img src={SearchIcon} style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} onClick={handleOnSearchClick}/>
                    </div>
                </div>
            </div>

            {decodedToken?.isOomero && <>
                    <div className="d-flex mb-3 mt-4 align-items-center">
                        <div className="me-auto p-2">
                            <h1 className='text-blue px-2 fw-normal'>List of Oomero Users</h1>
                        </div>
                        {(decodedToken?.isSuperAdmin || (decodedToken?.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.add_oomero_user)) &&
                        <div className="p-2">
                            <button className="btn-primary" onClick={routeChange1}>Add New User</button>
                        </div>
                        }
                        {(decodedToken?.isSuperAdmin || (decodedToken?.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.view_all_oomero_user_section)) &&
                        <div className="p-2">
                            <h6 className='dark-blue-small-bg text-blue px-4' onClick={()=>navigate(`/users/list_of_oomero_users${search && `?search=${search}`}`)}>View All</h6>
                        </div>
                    }
                    </div>

                    <div className='col-lg-12 col-md-12 mt-3'>
                        <div className='custom-table'> 
                            <div className='custom-table-head'>
                                <div className='row'>
                                    <div className='col'>
                                        <h6 className='text-blue'>Firstname</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-blue'>Lastname</h6>
                                    </div>
                                    <div className='col-2'>
                                        <h6 className='text-blue'>Email</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-blue'>Role</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-blue'>Mobile</h6>
                                    </div>
                                    <div className='col-1'>
                                        <h6 className='text-blue text-center'>Status</h6>
                                    </div>
                                    { (showEditOomerotButton || showDeleteOomeroButton || showPrintOomeroButton || viewOomeroDetailButton) &&
                                    <div className='col'>
                                        <h6 className='text-blue text-center' style={{ paddingLeft: 25 }}>Action</h6>
                                    </div>
                                    }
                                </div>
                            </div>
                            {oomeroUserList && oomeroUserList?.rows?.length > 0 &&
                                oomeroUserList?.rows?.map((element, index) => (
                                    <div className='custom-table-body' key={'oomero_'+index}>
                                        <div className='row align-items-center'>
                                            <div className='col'>
                                                <h6 className='text-black'>{element?.firstName}</h6>
                                            </div>
                                            <div className='col'>
                                                <h6 className='text-black'>{element?.lastName}</h6>
                                            </div>
                                            <div className='col-2' style={{wordWrap : 'break-word'}}>
                                                <h6 className='text-black'>{element?.email}</h6>
                                            </div>
                                            <div className='col'>
                                                <h6 className='text-black'>{(element?.role) ? element?.role : ((element?.superAdmin && 'Super Admin') || (element?.enterpriseAdmin && 'Enterprise Admin'))}</h6>
                                            </div>
                                            <div className='col'>
                                                <h6 className='text-black'>{element?.mobileCode+' '+ element?.mobile}</h6>
                                            </div>
                                            <div className='col-1'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <div className="form-check form-switch" >
                                                        <input className="form-check-input" type="checkbox" role="switch" checked={element?.isActive} onChange={(e) => 
                                                            { showOomeroChangeStatus &&
                                                                handleModalOpen((element?.isActive) ? 'deactivate' : 'activate', element?.userId, 'oomero')
                                                            }
                                                        } />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    {viewOomeroDetailButton &&
                                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} onClick={()=>navigate(`/user/edit_oomero_user?view=true&userId=${element?.userId}`)} />
                                                    }
                                                    {showEditOomerotButton &&
                                                    <img src={EditIcon} onClick={()=>navigate(`/user/edit_oomero_user?userId=${element?.userId}`)} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                                    }
                                                    {showPrintOomeroButton &&
                                                    <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                                    }
                                                    {showDeleteOomeroButton &&
                                                    <img src={DeleteIcon} onClick={()=>{ setSelectedUserId(element?.userId); handleDeleteShow()}} style={{ width: 20, height: 15, objectFit: 'contain', cursor: 'pointer' }} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {oomeroUserList && oomeroUserList?.rows?.length < 1 &&
                                <div className='text-center'>No Data </div>
                            }
                        </div>
                    </div>
                </>
            }

            <div className="d-flex mb-3 mt-4 align-items-center">
                <div className="me-auto p-2">
                    <h1 className='text-blue px-2 fw-normal'>List of Client Users</h1>
                </div>
                {(decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.add_client_user)) &&
                <div className="p-2">
                    <button className="btn-primary" onClick={routeChange2}>Add New User</button>
                </div>
                }

                {(decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.view_all_client_user)) &&
                    <div className="p-2">
                        <h6 className='dark-blue-small-bg text-blue px-4' onClick={()=>navigate(`/users/list_of_client_users${search && `?search=${search}`}`)}>View All</h6>
                    </div>
                }
            </div>

            <div className='col-lg-12 col-md-12 mt-3'>
                <div className='custom-table'>
                    <div className='custom-table-head'>
                        <div className='row'>
                            <div className='col'>
                                <h6 className='text-blue'>Company</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Firstname</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Lastname</h6>
                            </div>
                            <div className='col-2'>
                                <h6 className='text-blue'>Email</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Role</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Mobile</h6>
                            </div>
                            <div className='col-1'>
                                <h6 className='text-blue text-center'>Status</h6>
                            </div>
                            {/* { (showEditClientButton || showDeleteClentButton || showPrintClentButton || viewClientDetailButton) && */}
                            <div className='col'>
                                <h6 className='text-blue text-center' style={{ paddingLeft: 25 }}>Action</h6>
                            </div>
                            {/* } */}
                        </div>
                    </div>
                    {clientUserList && clientUserList?.rows?.length > 0 &&
                     clientUserList?.rows?.map((client, i) => (
                        <div className='custom-table-body' key={'client'+ i}>
                            <div className='row align-items-center'>
                                <div className='col'>
                                    <h6 className='text-black'>{client?.company}</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-black'>{client?.firstName}</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-black'>{client?.lastName}</h6>
                                </div>
                                <div className='col-2' style={{wordWrap : 'break-word'}}>
                                    <h6 className='text-black'>{client?.email}</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-black'>{(client?.role) ? client?.role : ((client?.superAdmin && 'Super Admin') || (client?.enterpriseAdmin && 'Enterprise Admin'))}</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-black'>{client?.mobileCode+' '+ client?.mobile}</h6>
                                </div>
                                <div className='col-1'>
                                    <div className='d-flex align-items-center justify-content-center'>
                                         <div className="form-check form-switch">
                                             <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={client?.isActive} onChange={(e) =>
                                             {
                                                showClientChangeStatus &&
                                                 handleModalOpen((client?.isActive) ? 'deactivate' : 'activate', client?.userId, 'client')
                                             }
                                             } />
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        {viewClientDetailButton &&
                                        <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} onClick={()=>navigate(`/user/edit_client_user?userId=${client?.userId}&view=true`)}/>
                                        }
                                        {showEditClientButton &&
                                        <img src={EditIcon} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} onClick={()=>navigate(`/user/edit_client_user?userId=${client?.userId}`)}/>
                                        }
                                        {showPrintClentButton &&
                                        <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                        }
                                        {showDeleteClentButton &&
                                        <img src={DeleteIcon} onClick={()=>{setSelectedUserId(client?.userId); handleDeleteShow()}} style={{ width: 20, height: 15, objectFit: 'contain', cursor: 'pointer' }} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                     ))
                    }
                    {clientUserList && clientUserList?.rows?.length < 1 &&
                        <div className='text-center'>No Data </div>
                    }
                </div>
            </div>

            <Modal className='modal-lg' show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <h5 className="modal-title text-center text-blue mt-3"><b>Confirm Deactivation</b></h5>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <hr />
                <Modal.Body style={{ paddingLeft: 100, paddingRight: 100 }}>
                    <h6 className='text-blue text-center'>Are you sure, you want to deactivate the User? Once, deactivated the client will not be able to use Oomero services</h6>
                    <form>
                        <div className="form-group mt-3 p-2">
                            <div className='manage-user-textarea'>
                                <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Comments" rows="3" onKeyUp={(event)=>setComments(event.target.value)}/>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer >
                    <div className='d-flex justify-content-between'>
                        <button onClick={handleClose} type="button" className="btn-secondary mb-3" style={{ marginRight: 20, width: 100 }}>No</button>
                        <button onClick={handleOnDeactivateConfirmation} type="button" className="btn-primary mb-3" style={{ width: 100 }}>Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal className='modal-lg' show={showDelete} onHide={handleDeleteClose} centered>
                <Modal.Header>
                    <h5 className="modal-title text-center text-blue mt-3"><b>Confirm Delete</b></h5>
                    <div onClick={handleDeleteClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <hr />
                <Modal.Body style={{ paddingLeft: 100, paddingRight: 100 }}>
                    <h6 className='text-blue text-center'>Are you sure, you want to delete the user. Once, deleted the user will not be able to use Oomero services</h6>
                    <form>
                        <div className="form-group mt-3 p-2">
                            <div className='manage-user-textarea'>
                                <textarea type="text" className="form-control" placeholder="Comments" rows="3" onKeyUp={(event)=>setComments(event.target.value)}/>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer >
                    <div className='d-flex justify-content-between'>
                        <button onClick={handleDeleteClose} type="button" className="btn-secondary mb-3" style={{ marginRight: 20, width: 100 }}>No</button>
                        <button onClick={()=>handleOnConfirmDeleteUser(selectedUserId)} type="button" className="btn btn-primary mb-3" style={{ width: 100 }}>Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal className='modal-lg' show={show2} onHide={handleCloseReactive} centered>
                <Modal.Header>
                    <h5 className="modal-title text-center text-blue mt-3"><b>Confirm Reactivation</b></h5>
                    <div onClick={handleCloseReactive} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <hr />
                <Modal.Body style={{ paddingLeft: 100, paddingRight: 100 }}>
                    <h6 className='text-blue text-center'>Are you sure, you want to Reactivate the User</h6>
                    <form>
                        <div className="form-group mt-3 p-2">
                            <div className='manage-user-textarea'>
                                <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Comments" rows="3" onKeyUp={(event)=>setComments(event.target.value)}/>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer >
                    <div className='d-flex justify-content-between'>
                        <button onClick={handleCloseReactive} type="button" className="btn-secondary mb-3" style={{ marginRight: 20, width: 100 }}>No</button>
                        <button onClick={handleOnReactivateConfirmation} type="button" className="btn-primary mb-3" style={{ width: 100 }}>Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ManageUsers
