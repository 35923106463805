import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';

import { countryList } from '../Registration/registrationSlice';
import Loader from '../../components/Loader/Loader';

import ThumbIcon from '../../assets/images/thumb_icon.png';
import './Pricing.scss';
import initialFormData from './services.json';
import { createNewPackage, getPackageDetails, updatePackageData } from './planSlice';
import { toast } from 'react-toastify';
import Select from 'react-select';
import BackIcon from '../../assets/images/back-btn-arrow.png';

function AddNewPackage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [initialServices, setInitialServices] = useState(null);
    const [isDisabled, setDisable] = useState(false)
    const [currency, setCurrency] = useState(null);
    const { isLoading: countryLoading, countryData } = useSelector(state => state.registerReducer);
    const { isLoading:packageDetailsLoading, packageDetails } = useSelector(state => state.planSliceReducer);
    const serviceProviderOptions = ["AML Check", "KYC", "AML Only"];
    const navigateToPrevious = () => {
        navigate(-1);
    }
    const schema = yup.object().shape({
        countryId: yup.number().typeError('Invalid Country').required('Country is required'),
        packageName: yup.string().required('Package Name is required'),
        individualChecksPerMonth: yup.number().typeError('Individual Checks Per Month is required').required(),
        companyChecksPerMonth: yup.number().typeError('Company Checks Per Month is required').required(),
        platformFee: yup.number().typeError('Platform Fee is required').required(),
        services: yup.object().shape({
            kyc_biometric: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            kyc_liveness: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            kyc_multiple_document: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            kyc_whole_database: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            kyc_multiple_database: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            kyc_mitigation_with_omc: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            kyc_mitigation_without_omc: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            aml_sanctions: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            aml_peps: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            aml_adverse_media: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            aml_continuous_monitoring: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            aml_continuous_monitoring_after_1: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            aml_global_address_autofill: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            aml_automated_client_due_diligence_refresh: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            aml_lei_number_check: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            misc_aadhar_check: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            misc_pan_check: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            misc_enhanced_due_diligence: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            misc_ssn_check: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                .number()
                .transform((value, originalValue) => {
                    const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                    return trimmedValue === '' ? 0 : value;
                })
                .when('is_checked', {
                    is: true,
                    then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                    otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),

            misc_outsourced_manual_checking: yup.object().shape({
                type: yup.string().required(),
                hours: yup.number().when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.number().typeError('Hours is required').required('Hours is required'),
                    otherwise: () => yup.string()
                }),
                price: yup.string().required().when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().required('Price is required'),
                    otherwise: () => yup.string()
                }),
                is_checked: yup.boolean().default(false),
                service_provider: yup.string().when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('Service Provider is required'),
                    otherwise: () => yup.string()
                }),
            }),
            company_basic_report: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            company_uk_credit_report: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            company_european_credit_report: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            company_usa_canada_credit_report: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            company_world_credit_report: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            integrated_company_onboarding: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            company_hmrc_vat_check: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            company_european_vat_number_check: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            company_india_gst_validation: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            company_lei_number_check: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            company_ubo_check: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            exportable_company_reports: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            company_custom_field_selection: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            company_enhanced_due_diligence: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
                price: yup
                    .number()
                    .transform((value, originalValue) => {
                        const trimmedValue = typeof originalValue === 'string' ? originalValue.trim() : originalValue;
                        return trimmedValue === '' ? 0 : value;
                    })
                    .when('is_checked', {
                        is: true,
                        then: (schema) => schema.required('Price is required').typeError('Price must be a number'),
                        otherwise: (schema) => schema.default(0).typeError('Price must be a number'),
                    }),
                service_provider: yup.string().typeError('Select a valid service provider').when('is_checked', {
                    is: (obj) => obj && typeof obj != 'undefined',
                    then: () => yup.string().typeError('Service Provider is required').required('If yes selected then,service provider need to select'),
                    otherwise: () => yup.string()
                }),
            }),
            no_of_users_check: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
            }),
            oomero_id_created_check: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
            }),
            api_check: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
            }),
            gdpr_managed_check: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
            }),
            oomero_id_accepted_check: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
            }),
            custom_email_template: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
            }),
            consent_management_check: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
            }),
            aiml: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
            }),
            secure_platform_access: yup.object().shape({
                type: yup.string().required(),
                is_checked: yup.boolean().default(false),
            }),
        }).required('Services is required')
    });

    const customStyles = {
        control: (provided) => ({
            ...provided,
            padding: '1px',
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
            boxShadow: 'none',
            '&:hover': {
                border: '2px solid #B3B3B3',
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#B3B3B3',
        }),
    };

    const { control, register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialFormData
    });

    useEffect(() => {
        dispatch(countryList());
        // if(serviceObject){
        //     setInitialServices(serviceObject)
        // }
    }, []);

    const routeChange = () => {
        let path = `/manage_packages`;
        navigate(path);
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSubmit = (requestObject) => {
        if (state?.isEdit == true) {
            dispatch(updatePackageData({
                ...requestObject,
                packageId: state?.packageid,
            })).unwrap().then((res) => {
                handleShow()
                reset();
                // setModalMessage(res.message);
                // handleShow();
            }).catch((error) => {
                toast.error(error?.message);
            })
        }
        else {

            dispatch(createNewPackage(requestObject)).unwrap().then((res) => {
                handleShow()
                reset();
                // setModalMessage(res.message);
                // handleShow();
            }).catch((error) => {
                toast.error(error?.message);
            })
        }
    }


    const setCurrencyData = (id) => {
        if (id) {
            for (let index = 0; index < countryData.length; index++) {
                const element = countryData[index];
                if (element?.country_id == id) setCurrency(element?.currency)
            }
        } else {
            setCurrency(null);
        }
    }
    const setFormValues = (setValue, data, prefix = '') => {
        try {
            Object.entries(data).forEach(([key, value]) => {
                const fieldKey = prefix ? `${prefix}.${key}` : key;
                if (typeof value === 'object' && !Array.isArray(value)) {
                    setFormValues(setValue, value, fieldKey);
                } else if (typeof value === 'boolean') {
                    setValue(fieldKey, String(value));
                }else{
                    setValue(fieldKey, value);
                }
            });
        } catch (error) {
            console.error("An error occurred while setting form values:", error);
            // Optionally, you can show an error message to the user
            // e.g., toast.error("Failed to set form values. Please try again.");
        }
    };

    useEffect(() => {
        if (state) {
            setDisable(state.isView ? true : false);
            dispatch(getPackageDetails(`?package_id=${Number(state.packageid)}`));
        }
    }, [state, dispatch]);

    useEffect(() => {
        if (Object.keys(packageDetails).length > 0) {
            setFormValues(setValue, {services : packageDetails?.services});
            setValue("companyChecksPerMonth", packageDetails?.company_checks_per_month);
            setValue("countryId", packageDetails?.country_id);
            setValue("individualChecksPerMonth", packageDetails?.individual_checks_per_month);
            setValue("packageName", packageDetails?.package_name);
            setValue("platformFee", packageDetails?.platform_fee);
        }
    }, [packageDetails]);

    return (
        <>
            <div className='grey-background'>
                {countryLoading && packageDetailsLoading && <Loader />}
                <button onClick={navigateToPrevious} className='back-button text-blue'><img src={BackIcon} style={{ width: 8, marginBottom: 2, marginRight: 4, objectFit: 'contain' }} /> Back</button>
                <form className='form addForm' onSubmit={handleSubmit(onSubmit)}>
                    <div className='white-background p-3 my-3 '>
                        <div className="row g-3 mb-3">
                            <div className='col-lg-6 col-md-6 pe-3'>
                                <div className="form-group mt-3">
                                    <label className="form-label" for="exampleFormControlSelect1">Select Country</label>
                                    <Controller
                                        name="countryId"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                id="countryId"
                                                placeholder="Select Country"
                                                isDisabled={isDisabled}
                                                className={`${errors.countryId ? 'is-invalid' : ''}`}
                                                options={countryData.map(country => ({
                                                    label: country.name,
                                                    value: country.country_id
                                                }))}
                                                styles={customStyles}
                                                value={field.value ? { label: countryData.find(country => country.country_id == field.value)?.name, value: field.value } : ''}
                                                onChange={(selectedOption) => {
                                                    field.onChange(selectedOption?.value);
                                                }}
                                            />
                                        )}
                                    />
                                    {errors?.countryId && (
                                        <div className="invalid-feedback">{errors?.countryId?.message}</div>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 ps-3'>
                                <div className="form-group mt-3">
                                    <label className="form-label" for="exampleInputEmail1">Enter Package Name</label>
                                    <input disabled={isDisabled} type="text" className={`form-control form-control-lg ${errors.packageName ? 'is-invalid' : ''}`}
                                        placeholder="Package Name"
                                        {...register('packageName', {
                                            required: `Please Enter Package Name`
                                        })}
                                    />
                                    {errors?.packageName && (
                                        <div className="invalid-feedback">{errors?.packageName?.message}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <h1 className='text-blue fw-normal'><b>Services Provider:</b></h1>
                        </div>
                        <div className="row g-3 mb-3">
                            <div className='col-lg-6 col-md-6 pe-3'>
                                <div className="form-group mt-3">
                                    <label className="form-label" for="exampleInputEmail1">Estimated No of Individual Checks per month</label>
                                    <input disabled={isDisabled} type="text" className={`form-control form-control-lg ${errors.individualChecksPerMonth ? 'is-invalid' : ''}`}
                                        placeholder="1"
                                        {...register('individualChecksPerMonth', {
                                            required: `Please Enter Count`
                                        })}
                                    />
                                    {errors?.individualChecksPerMonth && (
                                        <div className="invalid-feedback">{errors?.individualChecksPerMonth?.message}</div>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 ps-3'>
                                <div className="form-group mt-3">
                                    <label className="form-label" for="exampleInputEmail1">Estimated no of company checks per month</label>
                                    <input disabled={isDisabled} type="text" className={`form-control form-control-lg ${errors.companyChecksPerMonth ? 'is-invalid' : ''}`}
                                        placeholder="1"
                                        {...register('companyChecksPerMonth', {
                                            required: `Please Enter Count`
                                        })}
                                    />
                                    {errors?.companyChecksPerMonth && (
                                        <div className="invalid-feedback">{errors?.companyChecksPerMonth?.message}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='mb-5'>
                            <div className='mt-4 mb-3'>
                                <h1 className='text-black fw-normal'><b>KYC:</b></h1>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>KYC Check - Biometric</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.kyc_biometric.type')} defaultValue={'kyc'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.kyc_biometric.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className={`form-check-input ${(errors?.services?.kyc_biometric?.is_checked) ? 'is-invalid' : ''}`}
                                                            type="radio"
                                                            id="is_checked"
                                                            value={'true'}
                                                            checked={field.value == 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="is_checked" >
                                                    Yes
                                                </label>
                                                {errors?.services?.kyc_biometric?.is_checked && (
                                                    <div className="invalid-feedback">{errors?.services?.kyc_biometric?.is_checked?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.kyc_biometric.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            value={'false'}
                                                            checked={field.value == 'false'}
                                                            className={`form-check-input ${(errors?.services?.kyc_biometric?.is_checked) ? 'is-invalid' : ''}`}
                                                            type="radio"
                                                            id="isNotChecked"
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="isNotChecked" >
                                                    No
                                                </label>
                                                {errors?.services?.kyc_biometric?.is_checked && (
                                                    <div className="invalid-feedback">{errors?.services?.kyc_biometric?.is_checked?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="exampleInputEmail1">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.kyc_biometric.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.kyc_biometric?.price) ? 'is-invalid' : ''}`}
                                                                    id="price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.kyc_biometric?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.kyc_biometric?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.kyc_biometric.service_provider"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id="serviceProvider"
                                                            className={`form-control form-select ${(errors?.services?.kyc_biometric?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'Tech 5', value: 'tech5' }].map((res, i) => (
                                                                <option value={res.value}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.kyc_biometric?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.kyc_biometric?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>KYC Check - Liveness</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.kyc_liveness.type')} defaultValue={'kyc'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.kyc_liveness.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="isCheckedLiveness"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="isCheckedLiveness" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.kyc_liveness.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="isNotCheckedLiveness"
                                                            value={'false'}
                                                            checked={field.value === 'false'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="isNotCheckedLiveness" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="exampleInputEmail1">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.kyc_liveness.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.kyc_liveness?.price) ? 'is-invalid' : ''}`}
                                                                    id="price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.kyc_liveness?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.kyc_liveness?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.kyc_liveness.service_provider"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.kyc_liveness.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.kyc_liveness?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'Tech 5', value: 'tech5' }].map((res, i) => (
                                                                <option value={res.value}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.kyc_liveness?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.kyc_liveness?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>KYC - 1:1 check - checks face across multiple documents</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.kyc_multiple_document.type')} defaultValue={'kyc'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.kyc_multiple_document.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="kyc_check_face_across_multiple_documents_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="defaultCheck1" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.kyc_multiple_document.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="kyc_check_face_across_multiple_documents_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="defaultCheck1" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.kyc_multiple_document.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.kyc_multiple_document.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.kyc_multiple_document?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.kyc_multiple_document.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.kyc_multiple_document?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.kyc_multiple_document?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.kyc_multiple_document.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.kyc_multiple_document.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.kyc_multiple_document?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'Tech 5', value: 'tech5' }].map((res, i) => (
                                                                <option value={res.value}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.kyc_multiple_document?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.kyc_multiple_document?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>KYC - 1:N check face across our whole database</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.kyc_whole_database.type')} defaultValue={'kyc'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.kyc_whole_database.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="kyc_check_face_across_our_whole_database_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="kyc_check_face_across_our_whole_database_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.kyc_whole_database.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="kyc_check_face_across_our_whole_database_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="kyc_check_face_across_our_whole_database_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="exampleInputEmail1">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.kyc_whole_database.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.kyc_whole_database?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.kyc_whole_database.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.kyc_whole_database?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.kyc_whole_database?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.kyc_whole_database.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.kyc_whole_database"}
                                                            className={`form-control form-select ${(errors?.services?.kyc_whole_database?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'Tech 5', value: 'tech5' }].map((res, i) => (
                                                                <option value={res.value}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.kyc_whole_database?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.kyc_whole_database?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>KYC - 1:N  Mitigation with OMC</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.kyc_mitigation_with_omc.type')} defaultValue={'kyc'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.kyc_mitigation_with_omc.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="kyc_mitigation_with_omc_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="kyc_mitigation_with_omc_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.kyc_mitigation_with_omc.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="kyc_mitigation_with_omc_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="kyc_mitigation_with_omc_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="exampleInputEmail1">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.kyc_mitigation_with_omc.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.kyc_mitigation_with_omc?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.kyc_mitigation_with_omc.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.kyc_mitigation_with_omc?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.kyc_mitigation_with_omc?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.kyc_mitigation_with_omc.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.kyc_mitigation_with_omc"}
                                                            className={`form-control form-select ${(errors?.services?.kyc_mitigation_with_omc?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'Oomero', value: 'oomero' }].map((res, i) => (
                                                                <option value={res.value}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.kyc_mitigation_with_omc?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.kyc_mitigation_with_omc?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>KYC - 1:N  Mitigation without OMC</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.kyc_mitigation_without_omc.type')} defaultValue={'kyc'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.kyc_mitigation_without_omc.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="kyc_mitigation_without_omc_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="kyc_mitigation_without_omc_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.kyc_mitigation_without_omc.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="kyc_mitigation_without_omc_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="kyc_mitigation_without_omc_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="exampleInputEmail1">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.kyc_mitigation_without_omc.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.kyc_mitigation_without_omc?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.kyc_mitigation_without_omc.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.kyc_mitigation_without_omc?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.kyc_mitigation_without_omc?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.kyc_mitigation_without_omc.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.kyc_mitigation_without_omc"}
                                                            className={`form-control form-select ${(errors?.services?.kyc_mitigation_without_omc?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'Oomero', value: 'oomero' }].map((res, i) => (
                                                                <option value={res.value}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.kyc_mitigation_without_omc?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.kyc_mitigation_without_omc?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>KYC - Multi-database Support</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.kyc_multiple_database.type')} defaultValue={'kyc'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.kyc_multiple_database.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="kyc_check_multi_database_support_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="kyc_check_multi_database_support_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.kyc_multiple_database.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="kyc_check_multi_database_support_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="kyc_check_multi_database_support_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.kyc_multiple_database.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.kyc_multiple_database.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.kyc_multiple_database?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.kyc_multiple_database.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.kyc_multiple_database?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.kyc_multiple_database?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.kyc_multiple_database.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.kyc_multiple_database.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.kyc_multiple_database?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'Tech 5', value: 'tech5' }].map((res, i) => (
                                                                <option value={res.value}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.kyc_multiple_database?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.kyc_multiple_database?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-5'>
                            <div className='mt-4 mb-3'>
                                <h1 className='text-black fw-normal'><b>AML Check:</b></h1>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>Sanctions</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.aml_sanctions.type')} defaultValue={'aml_check'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.aml_sanctions.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="aml_check_sanctions_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="aml_check_sanctions_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.aml_sanctions.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="aml_check_sanctions_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="aml_check_sanctions_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="exampleInputEmail1">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.aml_sanctions.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.aml_sanctions?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.aml_sanctions.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.aml_sanctions?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.aml_sanctions?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.aml_sanctions.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.aml_sanctions.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.aml_sanctions?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'Comply Advantage', value: 'comply_advantage' }].map((res, i) => (
                                                                <option value={res.value}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.aml_sanctions?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.aml_sanctions?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>PEPs</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.aml_peps.type')} defaultValue={'aml_check'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.aml_peps.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="aml_check_peps_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="aml_check_peps_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.aml_peps.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="aml_check_peps_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="aml_check_peps_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="exampleInputEmail1">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.aml_peps.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.aml_peps?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.aml_peps.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.aml_peps?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.aml_peps?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.aml_peps.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.aml_peps.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.aml_peps?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'Comply Advantage', value: 'comply_advantage' }].map((res, i) => (
                                                                <option value={res.value}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.aml_peps?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.aml_peps?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>Adverse Media</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.aml_adverse_media.type')} defaultValue={'aml_check'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.aml_adverse_media.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="aml_check_adevrse_media_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="aml_check_adevrse_media_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.aml_adverse_media.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="aml_check_adevrse_media_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="aml_check_adevrse_media_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="exampleInputEmail1">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.aml_adverse_media.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.aml_adverse_media?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.aml_adverse_media.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.aml_adverse_media?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.aml_adverse_media?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.aml_adverse_media.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.aml_adverse_media.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.aml_adverse_media?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'Comply Advantage', value: 'comply_advantage' }].map((res, i) => (
                                                                <option value={res.value}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.aml_adverse_media?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.aml_adverse_media?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>Continuous Monitoring</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.aml_continuous_monitoring.type')} defaultValue={'aml_check'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.aml_continuous_monitoring.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="aml_check_continuous_monitoring_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="aml_check_continuous_monitoring_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.aml_continuous_monitoring.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="aml_check_continuous_monitoring_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="aml_check_continuous_monitoring_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="exampleInputEmail1">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.aml_continuous_monitoring.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.aml_continuous_monitoring?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.aml_continuous_monitoring.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.aml_continuous_monitoring?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.aml_continuous_monitoring?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.aml_continuous_monitoring.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.aml_continuous_monitoring.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.aml_continuous_monitoring?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'Comply Advantage', value: 'comply_advantage' }].map((res, i) => (
                                                                <option value={res.value}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.aml_continuous_monitoring?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.aml_continuous_monitoring?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>Continuous Monitoring after year 1</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.aml_continuous_monitoring_after_1.type')} defaultValue={'aml_check'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.aml_continuous_monitoring_after_1.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="aml_check_continuous_monitoring_after_year_1_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="aml_check_continuous_monitoring_after_year_1_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.aml_continuous_monitoring_after_1.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="aml_check_continuous_monitoring_after_year_1_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="aml_check_continuous_monitoring_after_year_1_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="exampleInputEmail1">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.aml_continuous_monitoring_after_1.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.aml_continuous_monitoring_after_1?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.aml_continuous_monitoring_after_1.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.aml_continuous_monitoring_after_1?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.aml_continuous_monitoring_after_1?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.aml_continuous_monitoring_after_1.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.aml_continuous_monitoring_after_1.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.aml_continuous_monitoring_after_1?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'Comply Advantage', value: 'comply_advantage' }].map((res, i) => (
                                                                <option value={res.value}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.aml_continuous_monitoring_after_1?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.aml_continuous_monitoring_after_1?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-5'>
                            <div className='mt-4 mb-3'>
                                <h1 className='text-black fw-normal'><b>Misc Services:</b></h1>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>Global Addess Autofill</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.aml_global_address_autofill.type')} defaultValue={'misc_services'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.aml_global_address_autofill.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_services_global_address_autofill_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_services_global_address_autofill_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.aml_global_address_autofill.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_services_global_address_autofill_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_services_global_address_autofill_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="exampleInputEmail1">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.aml_global_address_autofill.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.aml_global_address_autofill?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.aml_global_address_autofill.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.aml_global_address_autofill?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.aml_global_address_autofill?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.aml_global_address_autofill.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.aml_global_address_autofill.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.aml_global_address_autofill?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'Melissa', value: 'melissa' }].map((res, i) => (
                                                                <option value={res.value}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.aml_global_address_autofill?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.aml_global_address_autofill?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>Automated Client Due Diligence refresh</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.aml_automated_client_due_diligence_refresh.type')} defaultValue={'misc_services'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.aml_automated_client_due_diligence_refresh.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_services_automated_client_due_diligence_refresh_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_services_automated_client_due_diligence_refresh_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.aml_automated_client_due_diligence_refresh.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_services_automated_client_due_diligence_refresh_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_services_automated_client_due_diligence_refresh_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-8 col-md-8'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.aml_automated_client_due_diligence_refresh.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.aml_automated_client_due_diligence_refresh.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.aml_automated_client_due_diligence_refresh?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {/* {serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res}>{res}</option>
                                                            ))} */}
                                                                <option value={"oomero platform feature"}>oomero platform feature</option>

                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.aml_automated_client_due_diligence_refresh?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.aml_automated_client_due_diligence_refresh?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>LEI Number check</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.aml_lei_number_check.type')} defaultValue={'misc_services'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.aml_lei_number_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_services_LEI_number_check_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_services_LEI_number_check_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.aml_lei_number_check.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_services_LEI_number_check_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_services_LEI_number_check_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.aml_lei_number_check.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.aml_lei_number_check.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.aml_lei_number_check?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.aml_lei_number_check.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.aml_lei_number_check?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.aml_lei_number_check?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.aml_lei_number_check.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.aml_lei_number_check.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.aml_lei_number_check?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'LEI', value: 'lei' }].map((res, i) => (
                                                                <option value={res.value} key={'aml_lei_number_check' + i}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.aml_lei_number_check?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.aml_lei_number_check?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>Social Security Number (SSN) for USA customers</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.misc_ssn_check.type')} defaultValue={'misc_services'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.misc_ssn_check.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_services_social_security_number_ssn_for_usa_customers_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_services_social_security_number_ssn_for_usa_customers_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.misc_ssn_check.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_services_social_security_number_ssn_for_usa_customers_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_services_social_security_number_ssn_for_usa_customers_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.misc_ssn_check.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.misc_ssn_check.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.misc_ssn_check?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.misc_ssn_check.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.misc_ssn_check?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.misc_ssn_check?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.misc_ssn_check.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.misc_ssn_check.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.misc_ssn_check?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{label : 'Vital4', value : 'vital4'}].map((res, i) => (
                                                                <option value={res.value} key={'misc_ssn_check' + i}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.misc_ssn_check?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.misc_ssn_check?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6'>
                                    <p className='form-label mb-2'>Aadhar Number check for Indian Customers</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.misc_aadhar_check.type')} defaultValue={'aml_check'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.misc_aadhar_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_services_aadhar_number_check_for_indian_customers_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}

                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_services_aadhar_number_check_for_indian_customers_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.misc_aadhar_check.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_services_aadhar_number_check_for_indian_customers_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_services_aadhar_number_check_for_indian_customers_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.misc_aadhar_check.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.misc_aadhar_check.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.misc_aadhar_check?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.misc_aadhar_check.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.misc_aadhar_check?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.misc_aadhar_check?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.misc_aadhar_check.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.misc_aadhar_check.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.misc_aadhar_check?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'Signzy', value: 'signzy' }].map((res, i) => (
                                                                <option value={res.value} key={'misc_aadhar_check' + i}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.misc_aadhar_check?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.misc_aadhar_check?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>Pan Number check for Indian Customers</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.misc_pan_check.type')} defaultValue={'aml_check'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.misc_pan_check.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_pan_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_pan_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.misc_pan_check.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_pan_check"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_pan_check" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.misc_pan_check.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.misc_pan_check.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.misc_pan_check?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.misc_pan_check.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.misc_pan_check?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.misc_pan_check?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.misc_pan_check.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.misc_pan_check.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.misc_pan_check?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'Signzy', value: 'signzy' }].map((res, i) => (
                                                                <option value={res.value} key={'misc_pan_check' + i}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.misc_pan_check?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.misc_pan_check?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>Outsourced Manual Checking</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.misc_outsourced_manual_checking.type')} defaultValue={'misc_services'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-3 col-md-3'>
                                            <div className='d-flex align-items-center'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.misc_outsourced_manual_checking.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_services_outsourced_manual_checking_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_services_outsourced_manual_checking_check" >
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="form-check ms-3">
                                                <Controller
                                                    name="services.misc_outsourced_manual_checking.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_services_outsourced_manual_checking_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_services_outsourced_manual_checking_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-2 px-1'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.misc_outsourced_manual_checking.hours">{currency}</label>
                                                    <div className='w-100'>
                                                        <Controller
                                                            name="services.misc_outsourced_manual_checking.hours"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <select
                                                                    disabled={isDisabled}
                                                                    {...field}
                                                                    id={"services.misc_outsourced_manual_checking.hours"}
                                                                    className={`form-control form-select ${(errors?.services?.misc_outsourced_manual_checking?.hours) ? 'is-invalid' : ''}`}
                                                                >
                                                                    <option value="">Select hours</option>
                                                                    <option value="4">4 hr</option>
                                                                    <option value="24">24 hr</option>
                                                                    <option value="48">48 hr</option>
                                                                    <option value="72">72 hr</option>
                                                                    <option value="96">96 hr</option>
                                                                </select>
                                                            )}
                                                        />
                                                        {errors?.services?.misc_outsourced_manual_checking?.hours && (
                                                            <div className="invalid-feedback">{errors?.services?.misc_outsourced_manual_checking?.hours?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-2 px-1'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    {/* <label className="form-label mb-0 me-2" >{currency}</label> */}
                                                    <div className='w-100'>
                                                       {/* <select className='form-control form-select'>
                                                            <option value="">Select price</option>
                                                            <option value="12">100</option>
                                                            <option value="24">200</option>
                                                            <option value="48">400</option>
                                                            <option value="72">700</option>
                                                        </select> */}
                                                        <Controller
                                                            name="services.misc_outsourced_manual_checking.price"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Controller
                                                                    name="services.misc_outsourced_manual_checking.price"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => (
                                                                        <input disabled={isDisabled}
                                                                            {...field}
                                                                            type="text"
                                                                            className={`form-control ${(errors?.services?.misc_outsourced_manual_checking?.price) ? 'is-invalid' : ''}`}
                                                                            id="services.misc_outsourced_manual_checking.price"
                                                                            placeholder='Enter Price'
                                                                        />
                                                                    )}
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.misc_outsourced_manual_checking?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.misc_outsourced_manual_checking?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-3 px-1'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.misc_outsourced_manual_checking.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.misc_outsourced_manual_checking.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.misc_outsourced_manual_checking?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {[{ label: 'OMC INDIA ', value: 'OMC INDIA ' },{ label: 'OMC POLAND  ', value: 'OMC POLAND ' }].map((res, i) => (
                                                                <option value={res.value} key={'misc_outsourced_manual_checking' + i}>{res.label}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.misc_outsourced_manual_checking?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.misc_outsourced_manual_checking?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>Enhanced Due Diligence - Individuals</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.misc_enhanced_due_diligence.type')} defaultValue={'misc_services'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.misc_enhanced_due_diligence.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_services_enhanced_due_diligence_individuals_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_services_enhanced_due_diligence_individuals_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.misc_enhanced_due_diligence.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="misc_services_enhanced_due_diligence_individuals_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="misc_services_enhanced_due_diligence_individuals_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.misc_enhanced_due_diligence.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.misc_enhanced_due_diligence.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.misc_enhanced_due_diligence?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.misc_enhanced_due_diligence.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.misc_enhanced_due_diligence?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.misc_enhanced_due_diligence?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.misc_enhanced_due_diligence.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.misc_enhanced_due_diligence.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.misc_enhanced_due_diligence?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {/*{serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res} key={'misc_enhanced_due_diligence' + i}>{res}</option>
                                                            ))}*/}
                                                             <option value={'comply_wiser'}>ComplyWiser</option>
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.misc_enhanced_due_diligence?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.misc_enhanced_due_diligence?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-5'>
                            <div className='mt-4 mb-3'>
                                <h1 className='text-black fw-normal'><b>Company Checks:</b></h1>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>Company basic report (UK)</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.company_basic_report.type')} defaultValue={'company_checks'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.company_basic_report.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_company_basic_report_uk_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_company_basic_report_uk_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.company_basic_report.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_company_basic_report_uk_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_company_basic_report_uk_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.company_basic_report.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.company_basic_report.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.company_basic_report?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.company_basic_report.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.company_basic_report?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.company_basic_report?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.company_basic_report.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.company_basic_report.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.company_basic_report?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            <option value="companies_house">Companies House</option>
                                                            {/*{serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res} key={'company_basic_report' + i}>{res}</option>
                                                            ))}*/}

                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.company_basic_report?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.company_basic_report?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>UK Credit Report</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.company_uk_credit_report.type')} defaultValue={'company_checks'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.company_uk_credit_report.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_uk_credit_report_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_uk_credit_report_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.company_uk_credit_report.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_uk_credit_report_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_uk_credit_report_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.company_uk_credit_report.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.company_uk_credit_report.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.company_uk_credit_report?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.company_uk_credit_report.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.company_uk_credit_report?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.company_uk_credit_report?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.company_uk_credit_report.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.company_uk_credit_report.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.company_uk_credit_report?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {/*{serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res} key={'company_uk_credit_report' + i}>{res}</option>
                                                            ))}*/}                                   <option value="red_flag_alert">Red Flag Alert</option>
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.company_uk_credit_report?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.company_uk_credit_report?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>European Credit Report</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.company_european_credit_report.type')} defaultValue={'company_checks'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.company_european_credit_report.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_european_credit_report_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_european_credit_report_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.company_european_credit_report.is_checked"
                                                    control={control}

                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_european_credit_report_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_european_credit_report_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.company_european_credit_report.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.company_european_credit_report.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.company_european_credit_report?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.company_european_credit_report.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.company_european_credit_report?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.company_european_credit_report?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.company_european_credit_report.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.company_european_credit_report.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.company_european_credit_report?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            <option value="red_flag_alert">Red Flag Alert</option>
                                                            {/*{serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res} key={'company_european_credit_report' + i}>{res}</option>
                                                            ))}*/}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.company_european_credit_report?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.company_european_credit_report?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>USA & Cananda Credit Report</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.company_usa_canada_credit_report.type')} defaultValue={'company_checks'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.company_usa_canada_credit_report.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_usa_cananda_credit_report_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_usa_cananda_credit_report_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.company_usa_canada_credit_report.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_usa_cananda_credit_report_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_usa_cananda_credit_report_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.company_usa_canada_credit_report.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.company_usa_canada_credit_report.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.company_usa_canada_credit_report?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.company_usa_canada_credit_report.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.company_usa_canada_credit_report?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.company_usa_canada_credit_report?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.company_usa_canada_credit_report.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.company_usa_canada_credit_report.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.company_usa_canada_credit_report?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {/*{serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res} key={'company_usa_canada_credit_report' + i}>{res}</option>
                                                            ))}*/}
                                                            <option value="red_flag_alert">Red Flag Alert</option>
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.company_usa_canada_credit_report?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.company_usa_canada_credit_report?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>Rest of World Credit Report</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.company_world_credit_report.type')} defaultValue={'company_checks'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.company_world_credit_report.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_rest_of_world_credit_report_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_rest_of_world_credit_report_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.company_world_credit_report.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_rest_of_world_credit_report_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_rest_of_world_credit_report_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.company_world_credit_report.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.company_world_credit_report.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.company_world_credit_report?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.company_world_credit_report.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.company_world_credit_report?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.company_world_credit_report?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.company_world_credit_report.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.company_world_credit_report.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.company_world_credit_report?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {/*{serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res} key={'company_world_credit_report' + i}>{res}</option>
                                                            ))}*/}
                                                            <option value="red_flag_alert">Red Flag Alert</option>
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.company_world_credit_report?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.company_world_credit_report?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>Integrated company onboarding with biometrics for all directors & major shareholders</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.integrated_company_onboarding.type')} defaultValue={'company_checks'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.integrated_company_onboarding.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_integrated_company_onboarding_with_biometrics_for_all_directors_major_shareholders_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_integrated_company_onboarding_with_biometrics_for_all_directors_major_shareholders_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.integrated_company_onboarding.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_integrated_company_onboarding_with_biometrics_for_all_directors_major_shareholders_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_integrated_company_onboarding_with_biometrics_for_all_directors_major_shareholders_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.integrated_company_onboarding.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.integrated_company_onboarding.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.integrated_company_onboarding?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.integrated_company_onboarding.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.integrated_company_onboarding?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.integrated_company_onboarding?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.integrated_company_onboarding.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.integrated_company_onboarding.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.integrated_company_onboarding?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res} key={'integrated_company_onboarding' + i}>{res}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.integrated_company_onboarding?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.integrated_company_onboarding?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>HMRC VAT Check for onboarded companies</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.company_hmrc_vat_check.type')} defaultValue={'company_checks'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.company_hmrc_vat_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_hmrc_vat_check_for_onboarded_companies_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_hmrc_vat_check_for_onboarded_companies_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.company_hmrc_vat_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_hmrc_vat_check_for_onboarded_companies_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_hmrc_vat_check_for_onboarded_companies_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.company_hmrc_vat_check.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.company_hmrc_vat_check.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.company_hmrc_vat_check?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.company_hmrc_vat_check.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.company_hmrc_vat_check?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.company_hmrc_vat_check?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.company_hmrc_vat_check.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.company_hmrc_vat_check.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.company_hmrc_vat_check?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {/*{serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res} key={'company_hmrc_vat_check' + i}>{res}</option>
                                                            ))}*/}
                                                            <option value="hmrc_vat">HMRC VAT</option>
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.company_hmrc_vat_check?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.company_hmrc_vat_check?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>European VAT number check for onboarded companies</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.company_european_vat_number_check.type')} defaultValue={'company_checks'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.company_european_vat_number_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_european_vat_number_check_for_onboarded_companies_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_european_vat_number_check_for_onboarded_companies_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.company_european_vat_number_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_european_vat_number_check_for_onboarded_companies_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_european_vat_number_check_for_onboarded_companies_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.company_european_vat_number_check.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.company_european_vat_number_check.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.company_european_vat_number_check?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.company_european_vat_number_check.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.company_european_vat_number_check?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.company_european_vat_number_check?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.company_european_vat_number_check.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.company_european_vat_number_check.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.company_european_vat_number_check?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            <option value="vies_vat">VIES VAT</option>
                                                            {/*{serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res} key={'company_european_vat_number_check' + i}>{res}</option>
                                                            ))}*/}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.company_european_vat_number_check?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.company_european_vat_number_check?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>India GST validation</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.company_india_gst_validation.type')} defaultValue={'company_checks'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.company_india_gst_validation.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_india_gst_validation_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_india_gst_validation_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.company_india_gst_validation.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_india_gst_validation_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_india_gst_validation_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.company_india_gst_validation.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.company_india_gst_validation.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.company_india_gst_validation?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.company_india_gst_validation.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.company_india_gst_validation?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.company_india_gst_validation?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.company_india_gst_validation.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.company_india_gst_validation.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.company_india_gst_validation?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            <option value="signzy">Signzy</option>
                                                            {/*{serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res} key={'company_india_gst_validation' + i}>{res}</option>
                                                            ))}*/}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.company_india_gst_validation?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.company_india_gst_validation?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>LEI Number check (Company)</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.company_lei_number_check.type')} defaultValue={'company_checks'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.company_lei_number_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_lei_number_check_company_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_lei_number_check_company_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.company_lei_number_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_lei_number_check_company_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_lei_number_check_company_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.company_lei_number_check.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.company_lei_number_check.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.company_lei_number_check?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.company_lei_number_check.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.company_lei_number_check?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.company_lei_number_check?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.company_lei_number_check.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.company_lei_number_check.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.company_lei_number_check?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            <option value="lei">LEI</option>
                                                            {/*{serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res} key={'company_lei_number_check' + i}>{res}</option>
                                                            ))}*/}

                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.company_lei_number_check?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.company_lei_number_check?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>UBO Check* where available</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.company_ubo_check.type')} defaultValue={'company_checks'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.company_ubo_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_ubo_check_all_where_available_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_ubo_check_all_where_available_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.company_ubo_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_ubo_check_all_where_available_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_ubo_check_all_where_available_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.company_ubo_check.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.company_ubo_check.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.company_ubo_check?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {/*{serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res} key={'company_ubo_check' + i}>{res}</option>
                                                            ))}*/}
                                                            <option value="red_flag_alert">Red Flag Alert</option>
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.company_ubo_check?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.company_ubo_check?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>Exportable Company Reports</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.exportable_company_reports.type')} defaultValue={'company_checks'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.exportable_company_reports.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_exportable_company_reports_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_exportable_company_reports_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.exportable_company_reports.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_exportable_company_reports_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_exportable_company_reports_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.exportable_company_reports.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.exportable_company_reports.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.exportable_company_reports?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            {serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res} key={'exportable_company_reports' + i}>{res}</option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.exportable_company_reports?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.exportable_company_reports?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>Company Custom field selection</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.company_custom_field_selection.type')} defaultValue={'company_checks'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.company_custom_field_selection.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_company_custom_field_selection_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_company_custom_field_selection_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.company_custom_field_selection.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_company_custom_field_selection_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_company_custom_field_selection_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.company_custom_field_selection.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.company_custom_field_selection.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.company_custom_field_selection?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.company_custom_field_selection.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.company_custom_field_selection?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.company_custom_field_selection?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.company_custom_field_selection.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.company_custom_field_selection.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.company_custom_field_selection?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            <option value="oomero">Oomero</option>
                                                            {/*{serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res} key={'company_custom_field_selection' + i}>{res}</option>
                                                            ))}*/}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.company_custom_field_selection?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.company_custom_field_selection?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>Enhanced Due Diligence - Company</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.company_enhanced_due_diligence.type')} defaultValue={'company_checks'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.company_enhanced_due_diligence.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_enhanced_due_diligence_company_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_enhanced_due_diligence_company_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.company_enhanced_due_diligence.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="company_checks_enhanced_due_diligence_company_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="company_checks_enhanced_due_diligence_company_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <div className='d-flex justfy-content-center align-items-center'>
                                                    <label className="form-label mb-0 me-2" for="ervices.company_enhanced_due_diligence.price">{currency}</label>
                                                    <div>
                                                        <Controller
                                                            name="services.company_enhanced_due_diligence.price"
                                                            control={control}
                                                            defaultValue=""
                                                            render={({ field }) => (
                                                                <input disabled={isDisabled}
                                                                    {...field}
                                                                    type="text"
                                                                    className={`form-control ${(errors?.services?.company_enhanced_due_diligence?.price) ? 'is-invalid' : ''}`}
                                                                    id="ervices.company_enhanced_due_diligence.price"
                                                                    placeholder='Enter Price'
                                                                />
                                                            )}
                                                        />
                                                        {errors?.services?.company_enhanced_due_diligence?.price && (
                                                            <div className="invalid-feedback">{errors?.services?.company_enhanced_due_diligence?.price?.message}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-group">
                                                <Controller
                                                    name="services.company_enhanced_due_diligence.service_provider"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            disabled={isDisabled}
                                                            id={"services.company_enhanced_due_diligence.service_provider"}
                                                            className={`form-control form-select ${(errors?.services?.company_enhanced_due_diligence?.service_provider) ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Service Provider</option>
                                                            <option value="comply_wiser">ComplyWiser</option>
                                                            {/*{serviceProviderOptions && serviceProviderOptions.length > 0 && serviceProviderOptions.map((res, i) => (
                                                                <option value={res} key={'company_enhanced_due_diligence' + i}>{res}</option>
                                                            ))}*/}
                                                        </select>
                                                    )}
                                                />
                                                {errors?.services?.company_enhanced_due_diligence?.service_provider && (
                                                    <div className="invalid-feedback">{errors?.services?.company_enhanced_due_diligence?.service_provider?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-1'>
                            <div className='mt-4 mb-3'>
                                <h1 className='text-black fw-normal'><b>Additional Check:</b></h1>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>No of users</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.no_of_users_check.type')} defaultValue={'additional_check'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.no_of_users_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_no_of_users_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_no_of_users_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.no_of_users_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_no_of_users_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_no_of_users_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>Oomero ID - created</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.oomero_id_created_check.type')} defaultValue={'additional_check'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.oomero_id_created_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_oomero_id_created_check"
                                                            value={'true'}
                                                            checked={field.value !== 'false' && field.value !== false}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_oomero_id_created_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.oomero_id_created_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_oomero_id_created_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false' || field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_oomero_id_created_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>API</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.api_check.type')} defaultValue={'additional_check'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.api_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_api_check"
                                                            value={true}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_api_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.api_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_api_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_api_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>GDPR managed</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.gdpr_managed_check.type')} defaultValue={'additional_check'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.gdpr_managed_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_gdpr_managed_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_gdpr_managed_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.gdpr_managed_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_gdpr_managed_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_gdpr_managed_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>Oomero ID - accepted</p>
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.oomero_id_accepted_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_oomero_id_accepted_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_oomero_id_accepted_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.oomero_id_accepted_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_oomero_id_accepted_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_oomero_id_accepted_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>Custom email template configuration</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.custom_email_template.type')} defaultValue={'additional_check'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.custom_email_template.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_custom_email_template_configuration_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_custom_email_template_configuration_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.custom_email_template.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_custom_email_template_configuration_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_custom_email_template_configuration_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>Consent management</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.consent_management_check.type')} defaultValue={'additional_check'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.consent_management_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_consent_management_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_consent_management_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.consent_management_check.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_consent_management_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_consent_management_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>AI / ML / NLP</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.aiml.type')} defaultValue={'additional_check'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.aiml.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_ai_ml_nlp_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_ai_ml_nlp_check" >
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        {/* <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.aiml.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="additional_check_ai_ml_nlp_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="additional_check_ai_ml_nlp_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-4">
                                <div className='col-lg-6 col-md-6 pe-3'>
                                    <p className='form-label mb-2'>Secure Platform Access using Google Authenticator</p>
                                    <input disabled={isDisabled} hidden className="form-check-input" {...register('services.secure_platform_access.type')} defaultValue={'additional_check'} />
                                    <div className="row align-items-center">
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check" style={{ marginLeft: '-8px' }}>
                                                <Controller
                                                    name="services.secure_platform_access.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input disabled={isDisabled}
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="secure_platform_access_using_google_authenticator_check"
                                                            value={'true'}
                                                            checked={field.value === 'true'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="secure_platform_access_using_google_authenticator_check" >
                                                    Yes
                                                </label>
                                            </div> 
                                        </div>
                                        <div className='col-lg-2 col-md-2'>
                                            <div className="form-check">
                                                <Controller
                                                    name="services.secure_platform_access.is_checked"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input
                                                            {...field}
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="secure_platform_access_using_google_authenticator_uncheck"
                                                            value={'false'}
                                                            checked={field.value === 'false'}
                                                        />
                                                    )}
                                                />
                                                <label className="form-check-label" for="secure_platform_access_using_google_authenticator_uncheck" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 ps-3'>
                                    <p className='form-label mb-2'>Platform fee</p>
                                    <div className="row align-items-center">
                                        <div className='d-flex align-items-center col-md-6 col-lg-6'>
                                            <label className="form-label mb-0 me-2" for="exampleInputEmail1">{currency}</label>
                                            <div className="form-group">
                                                <input disabled={isDisabled} type="number" className={`form-control form-control-lg ${errors.platformFee ? 'is-invalid' : ''}`}
                                                    placeholder="1"
                                                    {...register('platformFee', {
                                                        required: `Please Enter Platform Free`
                                                    })}
                                                />
                                                {errors?.platformFee && (
                                                    <div className="invalid-feedback">{errors?.platformFee?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row-reverse">
                                {!state?.isView && (
                                    <button type='submit' className="btn btn-primary mt-3 mb-1">
                                        {state?.isEdit ? "Update Package" : "Save Package"}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body className='px-5'>
                    <div className='d-flex justify-content-center'>
                        <img src={ThumbIcon} style={{ width: 70, height: 70, objectFit: 'contain' }} />
                    </div>
                    <h3 className='text-blue text-center mt-3 mb-0 fw-normal px-3 pt-1 pb-1'>Package Added Succesful</h3>
                    <div className="d-flex justify-content-center">
                        <button onClick={routeChange} className="btn-primary w-50 my-3">Ok</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddNewPackage