import React, { useState } from 'react'
import '../../Profile/Profile.scss';
import OnboardClientBar from '../../../components/OnboardClientBar/OnboardClientBar';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../assets/images/logo.png'
import CurrentAddress from '../../../assets/images/current_address.png'
import PreviousAddress from '../../../assets/images/previous_address.png'
import AddBlueButton from '../../../assets/images/Add_blue_button.png'

function CorporateAddShareholdeAddress() {
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/corporate_add_shareholder_bank_details`;
        navigate(path);
    }

    const goBack = () => {
        navigate(-1); // This navigates back to the previous page
    };
    return (
        <div>
            <div className='white-background py-2 position-fixed w-100' style={{ borderRadius: 0 }}>
                <div className="d-flex justify-content-center">
                    <img src={Logo} style={{ objectFit: 'contain', width: 150 }} />
                </div>
            </div>
            <div style={{ padding: 20 }}>
                <div className='mt-5'>

                </div>
                <OnboardClientBar
                    mainTitle={`Onboard Business Client `}
                    title1={`COMPANY DETAILS`}
                    title2={`COMPANY INFORMATION`}
                    title3={`DIRECTOR LIST`}
                    title4={`SHAREHOLDER LIST`}
                    backgroundColor1={'#037AD0'}
                    backgroundColor2={'#037AD0'}
                    backgroundColor3={'#037AD0'}
                    backgroundColor4={'#037AD0'} />

                <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                        <div className='white-background p-3 mt-4'>
                            <div className='d-flex align-items-center'>
                                <img src={CurrentAddress} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                                <h1 className='text-blue fw-normal'>Current Residential Address</h1>
                            </div>
                            <hr />
                            <div className='form'>
                                <form>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleFormControlSelect1">Country</label>
                                                <select id="statusDropdown" className="form-control form-select">
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Zip</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Zip" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Date From</label>
                                                <input type="date" className="form-control" id="exampleInputEmail1" placeholder="" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Date To</label>
                                                <input type="date" className="form-control" id="exampleInputEmail1" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Address</label>
                                                <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Address" rows="5" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">State</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter State" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">City </label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter City " />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className='white-background p-3 mt-4'>
                            <div className='d-flex align-items-center'>
                                <img src={PreviousAddress} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                                <h1 className='text-ambed fw-normal'>Previous Residential Address</h1>
                            </div>
                            <hr />
                            <div className='form'>
                                <form>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleFormControlSelect1">Country</label>
                                                <select id="statusDropdown" className="form-control form-select">
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Zip</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Zip" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Date From</label>
                                                <input type="date" className="form-control" id="exampleInputEmail1" placeholder="" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Date To</label>
                                                <input type="date" className="form-control" id="exampleInputEmail1" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Address</label>
                                                <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Address" rows="5" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">State</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter State" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">City </label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter City " />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                        <div className='white-background p-3 mt-4'>
                            <div className='form'>
                                <form>
                                    <div className='row align-items-end'>
                                        <div className='col-lg-11 col-md-11'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Email</label>
                                                <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter Email" />
                                            </div>
                                        </div>
                                        <div className='col-lg-1 col-md-1' style={{paddingLeft:0}}>
                                            <img src={AddBlueButton} style={{ width: 50, objectFit: 'contain' }} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleFormControlSelect1">Profession</label>
                                            <select id="statusDropdown" className="form-control form-select">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className='white-background p-3 mt-4'>
                            <div className='form'>
                                <form>
                                    <div className='row align-items-end'>
                                        <div className='col-lg-11 col-md-11'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Mobile Number</label>
                                                <div className='row'>
                                                    <div className='col-lg-3 col-md-3'>
                                                        <select id="statusDropdown"
                                                            className="form-control form-select">
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-lg-9 col-md-9'>
                                                        <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter number" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-1 col-md-1' style={{paddingLeft:0}}>
                                            <img src={AddBlueButton} style={{ width: 50, objectFit: 'contain' }} />
                                        </div>
                                    </div>
                                    <div className='row align-items-end'>
                                        <div className='col-lg-11 col-md-11'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Landline Number</label>
                                                <div className='row'>
                                                    <div className='col-lg-3 col-md-3'>
                                                        <select id="statusDropdown" className="form-control form-select">
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-lg-9 col-md-9'>
                                                        <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter number" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-1 col-md-1' style={{paddingLeft:0}}>
                                            <img src={AddBlueButton} style={{ width: 50, objectFit: 'contain' }} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-end my-4'>
                    <div className='d-flex align-items-center'>
                        <button onClick={goBack} className='btn-secondary' style={{ marginRight: 16 }}>Previous</button>
                        <button onClick={routeChange} className='btn-primary'>Save & Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CorporateAddShareholdeAddress
