import React from 'react'
import { Navigate, useNavigate, useLocation } from "react-router-dom";
// import { responseInterceptor } from './api';
// import { store } from '../..';
import NewSidebar from '../components/Sidebar/NewSidebar';
import { responseInterceptor } from './api';
import TopBar from '../components/Topbar/Topbar';

const PrivateRoute = ({
    component: Component,
}) => {
    const history = useNavigate();
    const location = useLocation();
    responseInterceptor(history)
    const isAuthenticated = localStorage.getItem('token');
    if (isAuthenticated) {
        return (        
            <>
                <TopBar />
                <div className='row main-app' style={{ display: "flex", height: "100vh" }}>
                    <NewSidebar component={Component} />
                </div>
            </>  
        )
        
    } else {
        return <Navigate
            to={"/login"}
            replace
            state={{ from: location.pathname }}
        />
    }
}

export default PrivateRoute;
