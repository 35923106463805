import React, { useEffect, useState } from 'react'
import EditIcon from '../../assets/images/edit_icon.png'
import DeleteIcon from '../../assets/images/delete_icon.png'
import SearchIcon from '../../assets/images/search_icon.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { fetchTaxLists, deleteTaxData } from './taxSlice'
import Loader from '../../components/Loader/Loader';
import { toast } from 'react-toastify';
import TablePagination from '../../components/Pagination';
import { Modal } from 'react-bootstrap';
import CancelIcon from '../../assets/images/Cancel_icon.png';
import Logo from '../../assets/images/logo.png';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { decodeToken } from "react-jwt";

function Tax() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPge, SetPostsPerPage] = useState(10);
    const [rerender, setRerender] = useState(false);
    const { isLoading, taxLists } = useSelector(state => state.taxSliceReducer);
    const [searchType, setSearchType] = useState(false);
    const [deleteTaxRateId, setDeleteTaxRateId] = useState(null);
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);
    const [show, setShow] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [search, setSearch] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = (tax_id) => {
        setDeleteTaxRateId(tax_id);
        setShow(true);
    };

    useEffect(() => {
        let queryParams = `?page=${currentPage}&limit=${postsPerPge}`;
        if(search){
            queryParams += `&search=${search}`;
        }

        dispatch(fetchTaxLists(queryParams)).unwrap().catch((error) => {
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    }, [postsPerPge,currentPage, search, rerender]);

    const routeChange = () => {
        let path = `/tax/addtax`;
        navigate(path);
    }

    useEffect(() => {
        if (!((decodedToken?.roles?.permissions?.view_tax && decodedToken?.isOomero) || decodedToken?.isSuperAdmin)) {
            toast.error("You don't have access for tax");
            navigate('/');
        }
    }, [decodedToken]);

    const onSearchFormSubmit = (data) => {
        setSearchType(true);
        let queryParams = `?page=${currentPage}&limit=${postsPerPge}`;
        if(data){
            queryParams += `&search=${data}`;
        }

        setSearch(data);

        dispatch(fetchTaxLists(queryParams)).unwrap().then((data) => {
            setSearchType(false);
        }).catch((error) => {
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    }

    const confirmDeleteTaxRate = () => {
        if (deleteTaxRateId) {
            let queryParams = `?taxId=${deleteTaxRateId}&`;
            dispatch(deleteTaxData(queryParams)).unwrap().then((res) => {
                    dispatch(fetchTaxLists(`?page=${currentPage}&`));
                    toast.success(res.message);
                    setShow(false);
                }).catch((error) => {
                    toast.error(error.message || "Request Failed Please Try Again ");
                });
        }
    }

    const handlePagination = (event, value) => {
        setCurrentPage(value);
    }
    const handleClosePopUp = () => setShowPopUp(false);

    const handleShowPopUp  = () => setShowPopUp(true);
    const generatePDF = () => {
        let queryParams = '';
        
        if (search) {
            queryParams += `?search=${search}`;
        }
        
        dispatch(fetchTaxLists(queryParams)).unwrap().then((data) => {
            // const printButton = document.getElementById('print-button');
            // printButton.style.display = 'none';

            const doc = new jsPDF();

            // const table = document.querySelector('.custom-table');
            // const rows = table.querySelectorAll('.custom-table-body .row');

            // const tableData = [];
            // rows.forEach(row => {
            //     const columns = row.querySelectorAll('.col-3 h6');
            //     const rowData = [];
            //     columns.forEach(col => {
            //         rowData.push(col.innerText || '');
            //     });
            //     tableData.push(rowData);
            // });

            const tableData = data?.data.map(item => [
                item.tax_name,
                item.name,
                `${item.tax_rate}%`
              ]);

            const header = [['Tax Name', 'Country', 'Tax Rate']];

            const logoImg = new Image();
            logoImg.src = Logo;

            logoImg.onload = () => {
                const totalPagesExp = '{total_pages_count_string}';
                const pdfWidth = doc.internal.pageSize.getWidth();
                const pdfHeight = doc.internal.pageSize.getHeight();
                const generationTime = moment().format('DD-MM-YYYY HH:mm:ss');

                const options = {
                    head: header,
                    body: tableData,
                    startY: 30,
                    margin: { top: 20 },
                    didDrawPage: (data) => {
                        doc.addImage(logoImg, 'PNG', 13, 10, 35, 8);
                        doc.setFontSize(14);
                        doc.text(`Generated on: ${generationTime}`, 125, 9); 
                        doc.text('Tax Values', pdfWidth / 2, 15, { align: 'center' });
                        let str = 'Page ' + doc.internal.getNumberOfPages();
                        if (typeof doc.putTotalPages === 'function') {
                            str = str + ' of ' + totalPagesExp;
                        }
                        doc.setFontSize(10);
                        doc.text(str, data.settings.margin.left, pdfHeight - 10);
                    },
                    headStyles: {
                        fillColor: [61, 115, 221],
                        textColor: 255,
                        fontStyle: 'medium',
                    },
                    styles: {
                        overflow: 'linebreak',
                        fontSize: 10,
                        cellPadding: 2,
                        valign: 'middle',
                        halign: 'center',
                        lineColor: [44, 62, 80],
                        lineWidth: 0.1,
                    },
                    bodyStyles: {
                        textColor: [0, 0, 0],
                    },
                    alternateRowStyles: {
                        fillColor: [229, 241, 250],
                    },
                    pageBreak: 'auto'
                };

                doc.autoTable(options);

                if (typeof doc.putTotalPages === 'function') {
                    doc.putTotalPages(totalPagesExp);
                }

                doc.save(`${moment().format('DD-MM-YYYY')} tax report.pdf`);

                // printButton.style.display = 'block';
                setRerender(prev => !prev);
            };
            handleClosePopUp();
        }).catch((error) => {
            setRerender(prev => !prev);
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    };
    
    return (
        <div className='grey-background vh-101'>
            {isLoading && !searchType && <Loader/>}
            <div className='white-background p-3 mb-3'>
                <h1 className='text-blue px-2 fw-normal'>Existing Tax Values</h1>
                <hr />
                <div className='row'>
                    <div className='col-lg-10 col-md-10'>
                        <div className="form-group position-relative">
                            <input type="text" className="form-control" placeholder='Search' onKeyUp={(event)=>onSearchFormSubmit(event.target.value)}></input>
                            <div className="position-absolute" style={{ right: 20, top: '20%' }}>
                                <img src={SearchIcon} style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                    {((decodedToken?.roles?.permissions?.create_tax && decodedToken?.isOomero) || decodedToken?.isSuperAdmin) &&
                        <div className='col-lg-2 col-md-2 justify-content-end'>
                            <button onClick={routeChange} className="btn btn-primary w-100" style={{ fontSize: 14 }}>Add New</button>
                        </div>
                    }
                </div>
            </div>
            <div id="tax-table" className='white-background p-3 mb-3'>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <div className="p-2 w-auto">
                            <h1 className='text-blue px-2 fw-normal'>Tax Values</h1>
                        </div>
                    </div>

                    {((decodedToken?.roles?.permissions?.print_tax && decodedToken?.isOomero) || decodedToken?.isSuperAdmin) &&
                        <div>
                            <h6 className='dark-blue-small-bg' id="print-button" onClick={handleShowPopUp} style={{ cursor: 'pointer' }}>Print Tax Rates</h6>
                        </div>
                    }
                </div>
                <hr />
                <div className='col-lg-12 col-md-12 mt-3'>
                    <div className='custom-table'>
                        <div className='custom-table-head'>
                            <div className='row'>
                                <div className='col-3'>
                                    <h6 className='text-blue'>Tax Name</h6>
                                </div>
                                <div className='col-3'>
                                    <h6 className='text-blue'>Country</h6>
                                </div>
                                <div className='col-3'>
                                    <h6 className='text-blue'>Tax Rate</h6>
                                </div>
                                <div className='col-3'>
                                    <h6 className='text-blue'>Action</h6>
                                </div>
                            </div>
                        </div>
                        {taxLists && taxLists?.rows?.length > 0 &&
                            taxLists?.rows?.map((element, index) => (
                                <div className='custom-table-body' key={'taxLists' + index}>
                                    <div className='row align-items-center'>
                                        <div className='col-3'>
                                            <h6 className='text-black'>{element?.tax_name}</h6>
                                        </div>
                                        <div className='col-3'>
                                            <h6 className='text-black'>{element?.name}</h6>
                                        </div>
                                        <div className='col-3'>
                                            <h6 className='text-black'>{element?.tax_rate}%</h6>
                                        </div>
                                        <div className='col-3'>
                                            <div className='d-flex align-items-center'>
                                                {((decodedToken?.roles?.permissions?.edit_tax && decodedToken?.isOomero) || decodedToken?.isSuperAdmin) &&
                                                    <img src={EditIcon} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer' }} onClick={()=>navigate('/tax/editTax?taxId='+element.tax_id)}/>
                                                }

                                                {((decodedToken?.roles?.permissions?.delete_tax && decodedToken?.isOomero) || decodedToken?.isSuperAdmin) &&
                                                    <img src={DeleteIcon} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer', marginLeft:20 }} onClick={()=> handleShow(element.tax_id)}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            { taxLists && taxLists?.rows?.length > 0 &&
                <TablePagination totalPages={parseInt(taxLists?.totalPages)} currentPage={currentPage} color="primary" handlePagination={handlePagination}/>
            }
            <Modal show={show} onHide={handleClose} size='md' centered>
                <Modal.Header >
                    <div className="modal-title text-center text-blue" style={{fontSize:20}}><b>Delete</b></div>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="d-flex justify-content-center form-group p-2">
                            <h6>Are you sure you want to delete?</h6>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer >
                    <div className='d-flex align-items-center'>
                        <button onClick={handleClose} type="button" className="btn btn-primary me-3 px-5">No</button>
                        <button onClick={confirmDeleteTaxRate} type="button" className="btn btn-primary px-5">Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={showPopUp} onHide={handleClosePopUp} size='md' centered>
                <Modal.Header >
                    <div className="modal-title text-center text-blue" style={{fontSize:20}}><b>Download PDF</b></div>
                    <div onClick={handleClosePopUp} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="d-flex justify-content-center form-group p-2">
                            <h6>You want to download it in PDF </h6>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer >
                    <div className='d-flex align-items-center'>
                        <button onClick={handleClosePopUp} type="button" className="btn btn-primary me-3 px-5">No</button>
                        <button onClick={generatePDF} type="button" className="btn btn-primary px-5">Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Tax;
