import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

function FilteredTableData(props) {
    const { ViewIcon, PrintIcon } = props;
    const navigate = useNavigate();
    const startScreening = () => {
        let path = `/corporate_screening/screening`;
        navigate(path);
    }



    return (
        <div className='custom-table'>
            <div className='custom-table-head'>
                <div className='row'>
                    <div className='col-md-1'>
                        <p className='text-blue'>Company Name</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Oomero ID</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Company Type</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Directors</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Share - holders</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Status</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Date Time</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>KYC Status</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Screening Steps</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Continuous Monitoring</p>
                    </div>
                   <div className='col-md-1'>
                        <p className='text-blue'>Screening</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue text-center'>Action</p>
                    </div>
                </div>
            </div>
            {
                [
                    { status: 'Green', statusColor: '#037924', statusBg: '#A9FFC1' },
                    { status: 'Grey', statusColor: '#949595', statusBg: '#DDDDDD' },
                    { status: 'Green', statusColor: '#037924', statusBg: '#A9FFC1' },
                    { status: 'Red', statusColor: '#ED4337', statusBg: '#FFD0CD' },
                    { status: 'Amber', statusColor: '#9C6802', statusBg: '#FFED8F' },
                ].map((item, i) => {
                    return (
                        <div className='custom-table-body' key={'tabsdata' + i}>
                            <div className='row align-items-center'>
                                <div className='col-md-1'>
                                    <p>Some Company</p>
                                </div>
                                <div className='col-md-1'>
                                    <p>OLP123456111111</p>
                                </div>
                                <div className='col-md-1'>
                                    <p>Ipsum</p>
                                </div>
                                <div className='col-md-1'>
                                    <p>3</p>
                                </div>
                                <div className='col-md-1'>
                                    <p>10</p>
                                </div>
                                <div className='col-md-1'>
                                    <p className='statusBtn' style={{ backgroundColor: item.statusBg, color: item.statusColor }}>{item.status}</p>
                                </div>
                                <div className='col-md-1'>
                                    <p>22/02/2022 16:34:00</p>
                                </div>
                                <div className='col-md-1'>
                                    <p>KYC Completed</p>
                                </div>
                                <div className='col-md-1'>
                                    <p>KYC Completed</p>
                                </div>
                                <div className='col-md-1'>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                    </div>
                                </div>
                               <div className='col-md-1'>
                                    <p style={{ backgroundColor: '#037AD01A', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'fit-content', fontSize: 12, cursor: 'pointer', textAlign: 'center' }} onClick={startScreening}>Start Screening</p>
                                </div>
                                <div className='col-md-1'>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                        <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default FilteredTableData