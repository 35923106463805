import React from 'react'

function PdfResidentialAddress({ datefrom_label, datefromtext, dateto_label, datetotext, addresslabel, addresstext, statelabel, statename, citylabel, cityname, postalcode_label, postalcode, countrylabel, countryname }) {
    
    return (
        <div className='contentbox-css'>
            <div className='row'>
                <div className='col-4'>
                    <p className='text1'>{datefrom_label}<span className='text2' style={{ fontWeight: 400 }}>{datefromtext}</span></p>
                </div>
                <div className='col-4'>
                    <p className='text1'>{dateto_label}<span className='text2' style={{ fontWeight: 400 }}>{datetotext}</span></p>
                </div>
            </div>
            <p className='text1'>{addresslabel}<span className='text2' style={{ fontWeight: 400 }}>{addresstext}</span></p>
            <div className='row'>
                <div className='col-4'>
                    <p className='text1'>{statelabel}<span className='text2' style={{ fontWeight: 400 }}>{statename}</span></p>
                </div>
                <div className='col-4'>
                    <p className='text1'>{citylabel}<span className='text2' style={{ fontWeight: 400 }}>{cityname}</span></p>
                </div>
                <div className='col-4'>
                    <p className='text1'>{postalcode_label}<span className='text2' style={{ fontWeight: 400 }}>{postalcode}</span></p>
                </div>
            </div>
            <div className='col-4'>
                <p className='text1'>{countrylabel}<span className='text2' style={{ fontWeight: 400 }}>{countryname}</span></p>
            </div>
        </div>
    )
}

export default PdfResidentialAddress