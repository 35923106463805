import Dashboard from "../pages/Dashboard/Dashboard";
import ForgetPassword from "../pages/Login/ForgetPassword";
import Login from "../pages/Login/Login";
import ResetPassword from "../pages/Login/ResetPassword";
import Reigistration from "../pages/Registration/Reigistration";
import Screening from "../pages/Screening/Screening";
import CarCreateProfiled from "../pages/Profile/CreateProfile";
import IndividualPersonalDetails from "../pages/Profile/Individual/IndividualPersonalDetails";
import IndividualAddress from "../pages/Profile/Individual/IndividualAddress";
import IndividualBankDetails from "../pages/Profile/Individual/IndividualBankDetails";
import IndividualKYCVerification from "../pages/Profile/Individual/IndividualKYCVerification";
import IndividualAppicationDone from "../pages/Profile/Individual/IndividualAppicationDone";
import IndividualWebWelcome from "../pages/Webform/Individual/WelcomeScreen";
import IndividualWebPersonalDetails from "../pages/Webform/Individual/WebformPersonalDetails";
import IndividualWebAddress from "../pages/Webform/Individual/WebformAddress";
import IndividualWebBankDetail from "../pages/Webform/Individual/WebformBankDetails";
import IndividualWebKYCVerification from "../pages/Webform/Individual/WebformKYCVerification";
import IndividualWebApplication from "../pages/Webform/Individual/WebformAppicationDone";
import CorporatePersonalDetails from "../pages/Profile/Corporate/CorporatePersonalDetails";
import Tax from "../pages/Tax/Tax";
import Addtax from "../pages/Tax/Addtax";
import FxRates from "../pages/FxRates/FxRates";
import AddNewFxRates from "../pages/FxRates/AddNewFxRates";
import CorporateInformation from "../pages/Profile/Corporate/CorporateInformation";
import CorporateBankDetails from "../pages/Profile/Corporate/CorporateBankDetails";
import CorporateDirectorList from "../pages/Profile/Corporate/Directors/CorporateDirectorList";
import UsersRole from "../pages/UsersRoles/UsersRole";
import AddNewUserRoles from "../pages/UsersRoles/AddNewUserRoles";
import AddDirectorPersonlDetails from "../pages/Profile/Corporate/Directors/AddDirectorPersonlDetails";
import AddDirectorAddress from "../pages/Profile/Corporate/Directors/AddDirectorAddress";
import AddDirectorBankDetails from "../pages/Profile/Corporate/Directors/AddDirectorBankDetails";
import CorporateShareholderList from "../pages/Profile/Corporate/ShareHolders/CorporateShareholderList";
import AddShareholdePersonalDetails from "../pages/Profile/Corporate/ShareHolders/AddShareholdePersonalDetails";
import AddShareholdeAddress from "../pages/Profile/Corporate/ShareHolders/AddShareholdeAddress";
import AddShareholderBankDetails from "../pages/Profile/Corporate/ShareHolders/AddShareholderBankDetails";
import CorporateMembersList from "../pages/Profile/Corporate/CorporateMembersList";
import CorporateApplicationDone from "../pages/Profile/Corporate/CorporateApplicationDone";
import ManageClients from "../pages/ManageClients/ManageClients";
import NewClientSetup from "../pages/ManageClients/NewClientSetup";
import ClientServiceSetup from "../pages/ManageClients/ClientServiceSetup";
import ManageAdmin from "../pages/ManageClients/ManageAdmin";
import ClientBiling from "../pages/ViewClientBilling/ClientBiling";
import ViewClientBilling from "../pages/ViewClientBilling/ViewClientBilling";
import PreviousBills from "../pages/ViewClientBilling/PreviousBills";
import EditPricing from "../pages/ViewClientBilling/EditPricing";
import SearchAmlCheck from "../pages/AmlCheck/SearchAmlCheck";
import ResultsSearch from "../pages/AmlCheck/ResultsSearch";
import SingleResultsData from "../pages/AmlCheck/SingleResultsData";
import ManageUsers from "../pages/ManageUsers/ManageUsers";
import ListofOomeroUsers from "../pages/ManageUsers/ListofOomeroUsers";
import AddNewUsers from "../pages/ManageUsers/AddNewUsers";
import CorporateScreening from "../pages/Screening/CorporateScreening";
import StartScreening from "../pages/Screening/StartScreening";
import ScreeningCompanyDetails from "../pages/Screening/ScreeningCompanyDetails";
import CompanyCheck from "../pages/CompanyCheck/CompanyCheck";
import PreviouslyReports from "../pages/CompanyCheck/PreviouslyReports";
import IndividualScreening from "../pages/IndividualScreening/IndividualScreening";
import IndividualScreeningDetails from "../pages/IndividualScreening/IndividualScreeningDetails";
import Pricing from "../pages/Pricing/Pricing";
import IndividualViewedScreening from "../pages/ViewScreeningDetails/individual/individualViewedScreening";
import ViewAllCorporateList from "../pages/ViewScreeningDetails/ViewAllCorporateList";
import ViewAllIndividualList from "../pages/ViewScreeningDetails/ViewAllIndividualList";
import Invoices from "../pages/MyAccount/Invoices";
import GoogleAuthenticator from "../pages/MyAccount/GoogleAuthenticator";
import ChangePassword from "../pages/MyAccount/ChangePassword";
import WelcomeScreen from "../pages/Webform/Individual/WelcomeScreen";
import WebformPersonalDetails from "../pages/Webform/Individual/WebformPersonalDetails";
import WebformAddress from "../pages/Webform/Individual/WebformAddress";
import WebformBankDetails from "../pages/Webform/Individual/WebformBankDetails";
import WebformKYCVerification from "../pages/Webform/Individual/WebformKYCVerification";
import WebformAppicationDone from "../pages/Webform/Individual/WebformAppicationDone";
import CorporateWelcomeScreen from "../pages/Webform/Corporate/CorporateWelcomeScreen";
import CorporateWebformInformation from "../pages/Webform/Corporate/CorporateWebformInformation";
import CorporateWebformBankDetails from "../pages/Webform/Corporate/CorporateWebformBankDetails";
import CorporateWebformDirectorList from "../pages/Webform/Corporate/CorporateWebformDirectorList";
import WebformAddDirectorPersonlDetails from "../pages/Webform/Corporate/WebformAddDirectorPersonlDetails";
import CorporateAddDirectorAddress from "../pages/Webform/Corporate/CorporateAddDirectorAddress";
import CorporateAddDirectorBankDetails from "../pages/Webform/Corporate/CorporateAddDirectorBankDetails";
import CorporateWebformShareholderList from "../pages/Webform/Corporate/CorporateWebformShareholderList";
import CorporateAddShareholdePersonalDetails from "../pages/Webform/Corporate/CorporateAddShareholdePersonalDetails";
import CorporateAddShareholdeAddress from "../pages/Webform/Corporate/CorporateAddShareholdeAddress";
import CorporateAddShareholderBankDetails from "../pages/Webform/Corporate/CorporateAddShareholderBankDetails";
import CorporateWebformMembersList from "../pages/Webform/Corporate/CorporateWebformMembersList";
import CorporateWebformApplicationDone from "../pages/Webform/Corporate/CorporateWebformApplicationDone";
import AddCompanyShareholder from "../pages/Profile/Corporate/ShareHolders/AddCompanyShareholder";
import CorporateAddCompanyShareholder from "../pages/Webform/Corporate/CorporateAddCompanyShareholder";
import ManagePackage from "../pages/Pricing/ManagePackage";
import AddNewPackage from "../pages/Pricing/AddNewPackage";
import PaymentReceived from "../pages/ViewClientBilling/PaymentReceived";
import AddClientUser from "../pages/ManageUsers/AddClientUser";
import AddOomeroUser from "../pages/ManageUsers/AddOomeroUser";
import HistoryRate from "../pages/FxRates/HistoryRate";
import EmailMasters from "../pages/EmailMasters/EmailMasters";
import AddEmailMasters from "../pages/EmailMasters/AddEmailMasters";
import PrivateRoute from "./privateRoute";
import ListofClientUsers from "../pages/ManageUsers/ListofClientUsers";
import UserLists from "../pages/ManageUsers/UserLists";
import PreviouslyDirectorReports from "../pages/CompanyCheck/PreviouslyDirectorReports";
import CreateProfile from "../pages/Profile/CreateProfile";
import WebformThankYouPage from "../pages/Webform/Individual/WebformThankYouPage";
import IndividualPermission from "../pages/Profile/Individual/IndividualPermission";
import ProjectReport from "../pages/ProjectReport/ProjectReport";
import AmlCheck from "../pages/AmlCheck/AmlCheck";

export const loginRoutes = [
  {
    path: "/",
    exact: true,
    element: <PrivateRoute component={Dashboard} />,
  },
  {
    path: "/dashboard",
    exact: true,
    element: <PrivateRoute component={Dashboard} />,
  },
  {
    path: "/login",
    exact: true,
    element: <Login />,
  },
  {
    path: "/forgetpassword",
    exact: true,
    element: <ForgetPassword />,
  },
  {
    path: "/resetpassword",
    exact: true,
    element: <ResetPassword />,
  },
  {
    path: "/registration",
    exact: true,
    element: <Reigistration />,
  },
  {
    path: "/change_password",
    exact: true,
    element: <PrivateRoute component={ChangePassword} />,
  },
  {
    path: "/welcome_screen",
    exact: true,
    element: <WelcomeScreen />,
  },
  {
    path: "/webform_personal_details",
    exact: true,
    element: <WebformPersonalDetails />,
  },
  {
    path: "/webform_address",
    exact: true,
    element: <WebformAddress />,
  },
  {
    path: "/webform_bank_details",
    exact: true,
    element: <WebformBankDetails />,
  },
  {
    path: "/webform_kyc_verification",
    exact: true,
    element: <WebformKYCVerification />,
  },
  {
    path: "/webform_appication_done",
    exact: true,
    element: <WebformAppicationDone />,
  },
  {
    path: "/webform_thank_you",
    exact: true,
    element: <WebformThankYouPage />,
  },
  {
    path: "/Corporate_welcome_screen",
    exact: true,
    element: <CorporateWelcomeScreen />,
  },
  {
    path: "/corporate_webform_information",
    exact: true,
    element: <CorporateWebformInformation />,
  },
  {
    path: "/corporate_webform_bankdetails",
    exact: true,
    element: <CorporateWebformBankDetails />,
  },
  {
    path: "/corporate_webform_directorlist",
    exact: true,
    element: <CorporateWebformDirectorList />,
  },
  {
    path: "/webform_add_director_personl_details",
    exact: true,
    element: <WebformAddDirectorPersonlDetails />,
  },
  {
    path: "/corporate_add_director_address",
    exact: true,
    element: <CorporateAddDirectorAddress />,
  },
  {
    path: "/corporate_add_director_bank_details",
    exact: true,
    element: <CorporateAddDirectorBankDetails />,
  },
  {
    path: "/corporate_webform_shareholder_list",
    exact: true,
    element: <CorporateWebformShareholderList />,
  },
  {
    path: "/corporate_add_shareholde_personal_details",
    exact: true,
    element: <CorporateAddShareholdePersonalDetails />,
  },
  {
    path: "/corporate_add_shareholde_address",
    exact: true,
    element: <CorporateAddShareholdeAddress />,
  },
  {
    path: "/corporate_add_shareholder_bank_details",
    exact: true,
    element: <CorporateAddShareholderBankDetails />,
  },
  {
    path: "/corporate_webform_members_list",
    exact: true,
    element: <CorporateWebformMembersList />,
  },
  {
    path: "/corporate_webform_application_done",
    exact: true,
    element: <CorporateWebformApplicationDone />,
  },
  {
    path: "/corporate_add_company_shareholder",
    exact: true,
    element: <CorporateAddCompanyShareholder />,
  },
];
export const routes = [

  {
    path: "/projectreport",
    exact: true,
    element: <ProjectReport />,
  },

  {
    path: "/screening",
    exact: true,
    element: <Screening />,
  },
  {
    path: "/corporate_screening",
    exact: true,
    element: <CorporateScreening />,
  },
  {
    path: "/corporate_screening/screening",
    exact: true,
    element: <StartScreening />,
  },
  {
    path: "/create_profile",
    exact: true,
    element:<PrivateRoute component={CreateProfile} />
  },  
  {
    path: "/webform/individual_personal_details",
    exact: true,
    element: <IndividualPersonalDetails />,
  },
  {
    path: "/webform/individual_address",
    exact: false,
    element: <IndividualAddress />,
  },
  {
    path: "/webform/individual_bank_details",
    exact: false,
    element: <IndividualBankDetails />,
  },
  {
    path: "/webform/individual_kyc_verification",
    exact: false,
    element: <IndividualKYCVerification />,
  },
  {
    path: "/webform/individual_application_done",
    exact: true,
    element: <IndividualAppicationDone />,
  },
  {
    path: "/profile/individual_personal_details",
    exact: true,
    element: <PrivateRoute component={IndividualPersonalDetails} />,
  },
  {
    path: "/profile/individual_address",
    exact: true,
    element: <PrivateRoute component={IndividualAddress} />,
  },
  {
    path: "/profile/individual_bank_details",
    exact: true,
    element: <PrivateRoute component={IndividualBankDetails} />,
  },
  {
    path: "/profile/individual_kyc_verification",
    exact: true,
    element: <PrivateRoute component={IndividualKYCVerification} />,
  },
  {
    path: "/profile/individual_application_done",
    exact: true,
    element: <PrivateRoute component={IndividualAppicationDone} />,
  },
  {
    path: "/individual_onboarding_web_welcome",
    exact: true,
    element: <IndividualWebWelcome />,
  },
  {
    path: "/individual_onboarding_web_permission",
    exact: true,
    element: <IndividualPermission />,
  },
  {
    path: "/individual_onboarding_web_personal_detail",
    exact: true,
    element: <IndividualWebPersonalDetails />,
  },
  {
    path: "/individual_onboarding_web_address",
    exact: true,
    element: <IndividualWebAddress />,
  },
  {
    path: "/individual_onboarding_web_bank_detail",
    exact: true,
    element: <IndividualWebBankDetail />,
  },
  {
    path: "/individual_onboarding_web_kyc_verification",
    exact: true,
    element: <IndividualWebKYCVerification />,
  },   
  {
    path: "/individual_onboarding_web_application_done",
    exact: true,
    element: <IndividualWebApplication />,
  },
  {
    path: "/profile/corporate_company_details",
    exact: true,
    element: <PrivateRoute component={CorporatePersonalDetails} />,
  },
  {
    path: "/profile/corporate_company_information",
    exact: true,
    element:<PrivateRoute component={CorporateInformation} />,
  },
  {
    path: "/profile/corporate_company_bank",
    exact: true,
    element: <PrivateRoute component={CorporateBankDetails} />,
  },
  {
    path: "/profile/corporate_company_director_list",
    exact: true,
    element: <PrivateRoute component={CorporateDirectorList} />,
  },
  {
    path: "/profile/director_personal_details",
    exact: true,
    element: <AddDirectorPersonlDetails />,
  },
  {
    path: "/profile/director_address",
    exact: true,
    element: <AddDirectorAddress />,
  },
  {
    path: "/profile/director_bank_details",
    exact: true,
    element: <AddDirectorBankDetails />,
  },
  {
    path: "/profile/corporate_company_shareholder_list",
    exact: true,
    element: <PrivateRoute component={CorporateShareholderList} />,
  },
  {
    path: "/profile/shareholder_personal_details",
    exact: true,
    element: <AddShareholdePersonalDetails />,
  },
  {
    path: "/profile/shareholder_address",
    exact: true,
    element: <AddShareholdeAddress />,
  },
  {
    path: "/profile/shareholder_bank_details",
    exact: true,
    element: <AddShareholderBankDetails />,
  },
  {
    path: "/profile/add_company_shareholder",
    exact: true,
    element: <AddCompanyShareholder />,
  },
  {
    path: "/profile/corporate_members_list",
    exact: true,
    element: <CorporateMembersList />,
  },
  {
    path: "/profile/corporate_application",
    exact: true,
    element: <CorporateApplicationDone />,
  },
  {
    path: "/manage_clients",
    exact: true,
    element: <PrivateRoute component={ManageClients} />,
  },
  {
    path: "/clients/manage_New_client",
    exact: true,
    element: <NewClientSetup />,
  },
  {
    path: "/clients/manage_New_client_services",
    exact: true,
    element: <ClientServiceSetup />,
  },
  {
    path: "/clients/manage_admin",
    exact: true,
    element: <ManageAdmin />,
  },
  {
    path: "/aml_check",
    exact: true,
    element: <SearchAmlCheck />,
  },
  {
    path: "/aml/search_results",
    exact: true,
    element: <ResultsSearch />,
  },
  {
    path: "/aml/search_data",
    exact: true,
    element: <SingleResultsData />,
  },
  {
    path: "/user_roles",
    exact: true,
    element: <PrivateRoute component={UsersRole}/>,
  },
  {
    path: "/user/add_user_role",
    exact: true,
    element: <PrivateRoute component={AddNewUserRoles} />,
  },
  {
    path: "/tax",
    exact: true,
    element: <PrivateRoute component={Tax} />,
  },
  {
    path: "/tax/addtax",
    exact: true,
    element: <PrivateRoute component={Addtax} />,
  },
  {
    path: "/tax/editTax",
    exact: true,
    element: <PrivateRoute component={Addtax} />,
  },
  {
    path: "/fxrates",
    exact: true,
    element: <PrivateRoute component={FxRates} />,
  },
  {
    path: "/email_masters",
    exact: true,
    element: <EmailMasters />,
  },
  {
    path: "/email_masters/add_email_masters",
    exact: true,
    element: <AddEmailMasters />,
  },
  {
    path: "/rates/manage_fxrates",
    exact: true,
    element: <PrivateRoute component={AddNewFxRates} />,
  },
  {
    path: "/rates/history_rates",
    exact: true,
    element: <PrivateRoute component={HistoryRate} />,
  },
  {
    path: "/client_biling",
    exact: true,
    element: <PrivateRoute component={ClientBiling} />,
  },
  {
    path: "/billing/view_client_biling",
    exact: true,
    element: <PrivateRoute component={ViewClientBilling} />,
  },
  {
    path: "/billing/previous_bills",
    exact: true,
    element: <PrivateRoute component={PreviousBills} />,
  },
  {
    path: "/billing/edit_pricing",
    exact: true,
    element: <EditPricing />,
  },
  {
    path: "/billing/payment_received",
    exact: true,
    element: <PaymentReceived />,
  },
  {
    path: "/manage_users",
    exact: true,
    element: <PrivateRoute component={ManageUsers} />,
  },
  {
    path: "users/list_of_oomero_users",
    exact: true,
    element: <PrivateRoute component={UserLists} />,
  },
  {
    path: "users/list_of_client_users",
    exact: true,
    element: <PrivateRoute component={UserLists} />,
  },
  {
    path: "users/add_new_users",
    exact: true,
    element: <AddNewUsers />,
  },
  {
    path: "/users/add_client_users",
    exact: true,
    element: <PrivateRoute component={AddClientUser} />,
  },
  {
    path: "/user/edit_client_user",
    exact: true,
    element: <PrivateRoute component={AddClientUser} />,
  },
  {
    path: "/users/add_oomero_users",
    exact: true,
    element: <PrivateRoute component={AddOomeroUser} />,
  },
  {
    path: "/user/edit_oomero_user",
    exact: true,
    element: <PrivateRoute component={AddOomeroUser} />,
  },
  {
    path: "/company_check",
    exact: true,
    element: <CompanyCheck />,
  },
  {
    path: "/company/previously_reports",
    exact: true,
    element: <PreviouslyReports />,
  },
  {
    path: "/company/previously_director_reports",
    exact: true,
    element: <PreviouslyDirectorReports />,
  },
  {
    path: "/corporate_screening/corporate_screening_details",
    exact: true,
    element: <ScreeningCompanyDetails />,
  },
  {
    path: "/individual_screening",
    exact: true,
    element: <PrivateRoute component={IndividualScreening} />,
  },
  {
    path: "/individual_screening/individual_screening_details",
    exact: true,
    element: <PrivateRoute component={IndividualScreeningDetails} />,
  },
  {
    path: "/view_individual_user",
    exact: true,
    element: <PrivateRoute component={IndividualViewedScreening} />,
  },
  {
    path: "/view_individual_user/individual_screening_details",
    exact: true,
    element: <PrivateRoute component={IndividualScreeningDetails} />,
  },
  {
    path: "/view_screening/corporate_list",
    exact: true,
    element: <ViewAllCorporateList />,
  },
  {
    path: "/view_screening/individual_list",
    exact: true,
    element: <ViewAllIndividualList />,
  },
  {
    path: "/pricing",
    exact: true,
    element: <Pricing />,
  },
  {
    path: "/manage_packages",
    exact: true,
    element: <PrivateRoute component={ManagePackage} />,
  },
  {
    path: "/manage_packages/add_new_package",
    exact: true,
    element: <PrivateRoute component={AddNewPackage} />,
  },
  {
    path: "/manage_packages/update_package",
    exact: true,
    element: <PrivateRoute component={AddNewPackage} />,
  },
  {
    path: "/manage_packages/view_package",
    exact: true,
    element: <PrivateRoute component={AddNewPackage} />,
  },
  {
    path: "/invoices",
    exact: true,
    element: <Invoices />,
  },
  {
    path: "/google_authenticator",
    exact: true,
    element: <PrivateRoute component={GoogleAuthenticator} />,
  },
  {
    path: "/aml-check",
    exact: true,
    element: <PrivateRoute component={AmlCheck} />,
  },
];

