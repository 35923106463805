import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import ThumbIcon from '../../assets/images/thumb_icon.png'
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from 'react-router-dom';


function EditPricing() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/client_biling`;
        navigate(path);
    }
    return (
        <div className='grey-background'>
            <div className='white-background p-3 mb-3'>
                <h1 className='text-blue px-2 fw-normal'>Configure Client Pricing</h1>
                <hr />
                <h1 className='text-blue px-2 fw-normal'>Please confgure pricing for the services selected</h1>
            </div>
            <div className='white-background p-3 mb-3'>
                <div className="row ">
                    <div className="col">
                        <h4 className='text-black px-2 fw-normal'>Client Name : <b>Oomero</b></h4>
                    </div>
                    <div className="col">
                        <h4 className='text-black px-2 fw-normal'>Currency : <b>GBP</b></h4>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <h4 className='text-black px-2 fw-normal'>Client Type : <b>Business</b></h4>
                    </div>
                    <div className="col">
                        <h4 className='text-black px-2 fw-normal'>Billing Cycle : <b>10th of every month</b></h4>
                    </div>
                </div>
            </div>
            <div className='white-background p-3 mb-3'>
                <div className='white-background p-3 mb-3'>
                    <h1 className='text-blue px-2 fw-normal'>Service</h1>

                </div>
                <Accordion className='client-billing-accordion client-services'>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div className="d-flex" style={{ width: '99%' }}>
                                <div>
                                    <h2 className='text-black fw-normal'>Comply Advantage</h2>
                                </div>
                                <div>
                                    <i className="fa fa-chevron-down ms-3"></i>
                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div style={{ marginLeft: 40 }}>
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <h2 className='text-black fw-normal'><b>Reports</b></h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'><b>Reports Price</b></h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'><b>Minimum Reports</b></h2>
                                    </div>
                                    <div className="col-2">
                                        <h2 className='text-black fw-normal'><b>Reports Used</b></h2>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <h2 className='text-black fw-normal'>UK Reports: 50</h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'>£ 5.50</h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'>100</h2>
                                    </div>
                                    <div className="col-2">
                                        <h2 className='text-black fw-normal'>50</h2>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <h2 className='text-black fw-normal'>Europe Reports: 100</h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'>£ 25.50</h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'>200</h2>
                                    </div>
                                    <div className="col-2">
                                        <h2 className='text-black fw-normal'>100</h2>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <h2 className='text-black fw-normal'>India Report: 50</h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'>£ 145.50</h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'>350</h2>
                                    </div>
                                    <div className="col-2">
                                        <h2 className='text-black fw-normal'>200</h2>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <div className="d-flex" style={{ width: '99%' }}>
                                <div>
                                    <h2 className='text-black fw-normal'>Credit Safe</h2>
                                </div>
                                <div>
                                    <i className="fa fa-chevron-down ms-3"></i>
                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div style={{ marginLeft: 40 }}>
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <h2 className='text-black fw-normal'><b>Reports</b></h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'><b>Reports Price</b></h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'><b>Minimum Reports</b></h2>
                                    </div>
                                    <div className="col-2">
                                        <h2 className='text-black fw-normal'><b>Reports Used</b></h2>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <h2 className='text-black fw-normal'>UK Reports: 50</h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'>£ 5.50</h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'>100</h2>
                                    </div>
                                    <div className="col-2">
                                        <h2 className='text-black fw-normal'>50</h2>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <h2 className='text-black fw-normal'>Europe Reports: 100</h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'>£ 25.50</h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'>200</h2>
                                    </div>
                                    <div className="col-2">
                                        <h2 className='text-black fw-normal'>100</h2>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <h2 className='text-black fw-normal'>India Report: 50</h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'>£ 145.50</h2>
                                    </div>
                                    <div className="col-3">
                                        <h2 className='text-black fw-normal'>350</h2>
                                    </div>
                                    <div className="col-2">
                                        <h2 className='text-black fw-normal'>200</h2>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="d-flex flex-row-reverse">
                <button onClick={handleShow} className="btn btn-primary mt-3 mb-1">Save</button>
            </div>
            <Modal show={show} onHide={handleClose} centered >
                <Modal.Body>
                    <div className='d-flex justify-content-center'>
                        <img src={ThumbIcon} style={{ width: 70, height: 70, objectFit: 'contain' }} />
                    </div>
                    <h3 className='text-blue text-center mt-3 mb-0 fw-normal'>Changes done successfully !!</h3>
                    <div className="d-flex justify-content-center">
                        <button onClick={routeChange} className="btn-primary w-50 my-3">Ok</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditPricing
