import React, { useEffect, useRef, useState } from 'react'
import PrintIcon from '../../../assets/images/Print_Icon.png'
import ViewIcon from '../../../assets/images/view_icon.png'
import CalenderIcon from '../../../assets/images/calendar_icon.png'
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap'
import CorporateDataTable from '../../../components/ViewScreening/CorporateDataTable';
import IndividualDataTables from '../../../components/ViewScreening/IndividualDataTables';
import DatePicker from 'react-date-picker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchIndividualViewedScreeningList, getOnboardedClientReport, resendToScreening, resetIndividualViewedScreeningList , resetReportData, updateIndividualViewedScreeningList} from './individualViewedScreeningSlice';
import Loader from '../../../components/Loader/Loader';
import IndividualTableData from '../../../components/Screening/IndividualTableData';
import { downloadClientDocZipFiles, manageContinuousMonitoring, manageEntityClientStatus } from '../../IndividualScreening/IndividualScreeningSlice';
import { Modal } from 'react-bootstrap';
import CancelIcon from '../../../assets/images/Cancel_icon.png';
import { decodeToken } from 'react-jwt';
import moment from 'moment';
import ProjectReport, { generatePdf } from '../../../pages/ProjectReport/ProjectReport';

function IndividualViewedScreening(){
    const tabArray = [
        { tabTitle: 'Green(P)', boxColor: '#037924', value: 'greenp' },
        { tabTitle: 'Green (RC)', boxColor: '#037924', value: 'green',  },
        { tabTitle: 'Amber (RC)', boxColor: '#9C6802', value: 'amber' },
        { tabTitle: 'Red (RC)', boxColor: '#ED4337', value: 'red' },
        { tabTitle: 'White Listed', boxColor: '#FFFFFF', value: 'whitelisted' },
        { tabTitle: 'Black Listed', boxColor: '#262626', value: 'blacklisted' },
        { tabTitle: 'Grey', boxColor: '#949595', value: 'grey' },
        { tabTitle: 'Rejected', boxColor: '#ED4337', value : 'rejected' },
    ];
    const navigate = useNavigate();
    const { isLoading, individualViewedScreeningList, getOnboardedClientReportData } = useSelector(state => state.individualViewedScreeningReducer);
    const { isLoading : screeningLoader } = useSelector(state => state.individualScreeningReducer);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPge, SetPostsPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const inputRef = useRef(null);
    const [activeTab, setActiveTab] = useState('');
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [entityClientId, setEntityClientId] = useState(null);
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);

    const startEndFilter = yup.object().shape({
        startDate: yup.string().required('Start date is required'),
        endDate: yup.string().required('End date is required').test('is-greater', 'End date must be greater than start date', function (value) {
            const { startDate } = this.parent;
            // Convert dates to JavaScript Date objects for comparison
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(value);

            // Compare the dates
            return endDateObj >= startDateObj;
        })
    });

    const { control, register, handleSubmit, formState: { errors }, reset, setValue, trigger } = useForm({
        resolver: yupResolver(startEndFilter),
    });

    useEffect(() => {
        let queryParam = `?limit=${postsPerPge}&page=${currentPage}&onboarded=true`;
        if (search) {
            queryParam += `&search=${search}`;
        }

        if (startDate != '' & endDate != '') {
            queryParam += `&startDate=${startDate}&endDate=${endDate}`;
        }

        if (activeTab != '') {
            if(activeTab === 'rejected'){
                queryParam += `&status=${activeTab}`;
            }else{
                queryParam += `&status=approved&flag=${activeTab}`;
            }
        }else{
            queryParam += `&status=all`;
        }

        dispatch(fetchIndividualViewedScreeningList(queryParam)).unwrap().catch((error) => {
            toast.error(error?.message);
        });

        dispatch(resetIndividualViewedScreeningList());
    }, [search, activeTab, currentPage, startDate, endDate]);

    const resetDateFilter = () => {
        setStartDate('');
        setEndDate('')
        reset();
    }

    const handleTabSelect = (tabKey) => {
        setCurrentPage(1);
        setActiveTab((tabKey));
      };

    const handleOnSearch = () => {
        if (inputRef.current) {
            setCurrentPage(1);
            setSearch(inputRef.current.value);
        }
    }

    const onSubmit = (data) => {
        setCurrentPage(1);
        setStartDate(data.startDate);
        setEndDate(data.endDate);
    }

    const routeChange = () => {
        let path = `/clients/manage_New_client`;
        navigate(path);
    }

    const startScreening = () => {
        let path = `/corporate_screening/screening`;
        navigate(path);
    }

    const handlePagination = (event, value) => {
        setCurrentPage(value);
    }
    const [isDataReady, setIsDataReady] = useState(false);

    const downloadZip = (entityClientId) => {
        dispatch(downloadClientDocZipFiles(`?entity_client_id=${entityClientId}&onboarded=true`))
            .unwrap()
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `client_${entityClientId}_documents.zip`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => {
                toast.error(error.message || 'Something Went Wrong');
            });
    };

    const handleDownloadReport = (entityClientId) => {
        dispatch(resetReportData());
        dispatch(getOnboardedClientReport(`?entity_client_id=${entityClientId}`))
            .unwrap()
            .then(async (res) => {
                setIsDataReady(true);
            //     const element = document.getElementById('content-to-pdf');

            //     if (!element) {
            //         console.error("Element with ID 'content-to-pdf' not found.");
            //         return;
            //     }
               
            // //    Ensure all images are loaded
            //     const images = element.querySelectorAll('img');
            //     await Promise.all(Array.from(images).map(img => {
            //         return new Promise((resolve, reject) => {
            //             if (img.complete) {
            //                 resolve(); // Image already loaded
            //             } else {
            //                 img.onload = resolve;
            //                 img.onerror = () => {
            //                     console.error('Error loading image:', img.src);
            //                     resolve(); // Continue even if an image fails to load
            //                 };
            //             }
            //         });
            //     }));
                
            //     setTimeout(generatePdf(),30000);
            //     setIsDataReady(false);
            })
            .catch((error) => {
                toast.error(error.message || 'Something Went Wrong');
            });
    }

    // // Watch for changes in getOnboardedClientReportData and trigger PDF generation
    useEffect(() => {
        const generatePdfIfReady = async () => {
            if (isDataReady && getOnboardedClientReportData) {
                const element = document.getElementById('content-to-pdf');
    
                if (!element) {
                    console.error("Element with ID 'content-to-pdf' not found.");
                    setIsDataReady(false);
                    return;
                }
    
                // Ensure all images are loaded before generating the PDF
                const images = element.querySelectorAll('img');
                try {
                    console.log("starting promise");
                    await Promise.all(Array.from(images).map(img => {
                        console.log("images", img);
                        return new Promise((resolve) => {
                            if (img.complete && img.naturalHeight !== 0) {
                                resolve(); // Image already loaded and valid
                            } else {
                                img.onload = resolve;
                                img.onerror = () => {
                                    console.error('Error loading image:', img.src);
                                    resolve(); // Continue even if an image fails to load
                                };
                            }
                        });
                    }));
                    console.log("now generating pdf");
                    // Generate PDF after images are loaded
                    generatePdf();  // Call the PDF generation function
                } catch (error) {
                    console.error("Error during PDF generation:", error);
                } finally {
                    // Reset data readiness state
                    setIsDataReady(false);
                }
            }
        };
    
        generatePdfIfReady();
    }, [isDataReady, getOnboardedClientReportData]);

    const manageEntityClient = (data) => {
        const isSuperAdmin = decodedToken?.isSuperAdmin;
        const isEnterpriseAdmin = decodedToken?.isEnterpriseAdmin;
        const hasActivatePermission = decodedToken?.roles?.permissions?.reactivate_oomero_id_onboarded;
        const hasDeActivatePermission = decodedToken?.roles?.permissions?.deactivate_oomero_id_onboarded;

        if (data?.status && !(isSuperAdmin || isEnterpriseAdmin || hasActivatePermission)) {
            toast.error('You are not authorized for activating the record');
            return;
        }

        if (!data?.status && !(isSuperAdmin || isEnterpriseAdmin || hasDeActivatePermission)) {
            toast.error('You are not authorized for deactivating the record');
            return;
        }

        dispatch(manageEntityClientStatus({ queryParams: `?entity_client_id=${data?.entityClientId}&onboarded=true`, payload: { status: data?.status } })).unwrap().then((obj) => {
            let object = individualViewedScreeningList;

            const updatedRows = object?.rows.map(row => {
                if (row.entity_client_id === data?.entityClientId) {
                    return {
                        ...row,
                        is_active: data?.status
                    };
                }
                return row;
            });

            // Create a new object with the updated rows
            const updatedObject = {
                ...object,
                rows: updatedRows
            };

            dispatch(updateIndividualViewedScreeningList(updatedObject));

            toast.success(obj?.message || 'Successfully done');
        }).catch(error => {
            toast.error(error.message || 'Something Went Wrong');
        });
    }

    const manageContinuousMonitoringFunc = (data) => {
        dispatch(manageContinuousMonitoring({ queryParams: `?entity_client_id=${data?.entityClientId}&onboarded=true`, payload: { continous_monitoring: data?.continous_monitoring } })).unwrap().then((obj) => {
            let object = individualViewedScreeningList;

            const updatedRows = object?.rows.map(row => {
                if (row.entity_client_id === data?.entityClientId) {
                    return {
                        ...row,
                        continous_monitoring: data?.continous_monitoring
                    };
                }
                return row;
            });

            // Create a new object with the updated rows
            const updatedObject = {
                ...object,
                rows: updatedRows
            };

            dispatch(updateIndividualViewedScreeningList(updatedObject));

            toast.success(obj?.message || 'Successfully done');
        }).catch(error => {
            toast.error(error.message || 'Something Went Wrong');
        });
    }

    const reScreening = (entity_client_id) => {
        setEntityClientId(entity_client_id)
        setShow(true);
    };

    const confirmRescreen = () => {
        if (entityClientId) {
            let queryParam = `?entity_client_id=${entityClientId}`;
            dispatch(resendToScreening({queryParam})).unwrap().then((res) => {
                let object = individualViewedScreeningList;
                const updatedRows = object?.rows.filter(row => {
                    return (row.entity_client_id != entityClientId);
                });
                
                // Create a new object with the updated rows
                const updatedObject = {
                    ...object,
                    rows: updatedRows
                };
                
                dispatch(updateIndividualViewedScreeningList(updatedObject));
                
                
                setEntityClientId('');
                toast.success(res.message);
                setShow(false);
            }).catch((error) => {
                setEntityClientId('');
                setShow(false);
                toast.error(error.message || "Request Failed Please Try Again ");
            });
        }
    }

    const renterTabTitle = (title, bgColor) => {
        return (
            <div className='d-flex align-items-center'>
                <div style={{ width: 12, height: 12, backgroundColor: bgColor, marginRight: 5 }} className='tabTitle' /> {title}
            </div>
        )
    }

    return (
        <div className='grey-background corporate-screening-page'>
            <div id="pdf-loader" style={{ display: 'none' }}>
                <Loader />
            </div>
            {(isLoading || screeningLoader) && <Loader />}
            <div className='white-background p-3 mb-3'>
                <h1 className='text-blue px-2 fw-normal'>Individual Screening</h1>
                <hr />
                {isDataReady && 
                    <div className='display-none'>
                        <ProjectReport getOnboardedClientReportData={getOnboardedClientReportData}/>
                    </div>
                }
                <div className='row'>
                    <div className='col-lg-9 col-md-9'>
                        <form className='form' onSubmit={handleSubmit(onSubmit)}>
                            <div className='row mb-sm'>
                                <div className='d-flex' style={{ width: "max-content", alignItems: 'center' }}> Start Date </div>
                                <div className='col-md-3 col-sm-4 pb-1'>
                                    {/* <input type="date" {...register('startDate')} className={`form-control ${errors?.startDate ? 'is-invalid' : ''}`} id="exampleInputEmail1" /> */}
                                    <Controller
                                        name="startDate"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                className={`form-control form-control-lg custom-datepicker ${errors.startDate ? 'is-invalid' : ''}`}
                                                onChange={(date) => {
                                                  const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                  field.onChange(formattedDate);
                                                }}
                                                value={field.value}
                                                maxDate={new Date()}
                                                clearIcon
                                                format='dd-MM-yyyy'
                                                yearPlaceholder='yyyy'
                                                monthPlaceholder='mm'
                                                dayPlaceholder='dd'
                                            />
                                        )}
                                    />
                                    {errors?.startDate && (
                                        <div className="invalid-feedback">{errors?.startDate?.message}</div>
                                    )}
                                </div>
                                <div className='d-flex' style={{ width: "max-content", alignItems: 'center' }}>  End Date </div>
                                <div className='col-md-3 col-sm-3 pb-1'>
                                    {/* <input type="date" {...register('endDate')} className={`form-control ${errors?.endDate ? 'is-invalid' : ''}`} id="exampleInputEmail1" /> */}
                                    <Controller
                                        name="endDate"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                className={`form-control form-control-lg custom-datepicker ${errors.endDate ? 'is-invalid' : ''}`}
                                                onChange={(date) => {
                                                  const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                  field.onChange(formattedDate);
                                                }}
                                                value={field.value}
                                                maxDate={new Date()}
                                                clearIcon
                                                format='dd-MM-yyyy'
                                                yearPlaceholder='yyyy'
                                                monthPlaceholder='mm'
                                                dayPlaceholder='dd'
                                            />
                                        )}
                                    />
                                    {errors?.endDate && (
                                        <div className="invalid-feedback">{errors?.endDate?.message}</div>
                                    )}
                                </div>
                                <button type="submit" className="btn btn-primary mb-1 p-2 pl-4 pr-4" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10 }}>
                                    <i className="fa fa-search"></i>
                                </button>
                                <button type='button' className="btn btn-secondory mb-1 p-2 pl-4 pr-4" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10 }} onClick={() => resetDateFilter()}>
                                    <i className="fa fa-rotate-right"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className='col-lg-3 col-md-3 '>
                        <div className='row'>
                            <div className='col'>
                                <input type="text" className="form-control" placeholder='Search' ref={inputRef}></input>
                            </div>
                            <button className="btn btn-primary mb-1 p-2 pl-4 pr-4" style={{ width: 40, height: 40, marginLeft: 10 }} onClick={handleOnSearch}>
                                <i className="fa fa-search"></i>
                            </button>
                            <button className="btn btn-secondory mb-1 p-2 pl-4 pr-4" style={{ width: 40, height: 40, marginLeft: 10, marginRight: 10 }} onClick={() => { setSearch(''); inputRef.current.value = ''; }}>
                                <i className="fa fa-rotate-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-12 col-md-12 mt-3'>
                <div className='white-background p-3'>
                    <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="mb-3 corporate-tabs" >
                        <Tab eventKey="" title="All">
                            <IndividualTableData onboarded={true} ViewIcon={ViewIcon} manageEntityClient={manageEntityClient} manageContinuousMonitoring={manageContinuousMonitoringFunc} PrintIcon={PrintIcon} listData={individualViewedScreeningList} handlePagination={handlePagination} currentPage={currentPage}  downloadZip={downloadZip} reScreening={reScreening} decodedToken={decodedToken} handleDownloadReport={handleDownloadReport}/>
                            {/* <CorporateDataTable ViewIcon={ViewIcon} PrintIcon={PrintIcon} /> */}
                        </Tab>
                        {tabArray.map((item, i) => {
                            return (
                                <Tab eventKey={item.value} title={renterTabTitle(item.tabTitle, item.boxColor)} key={'screen-tab' + i}>
                                    {/* <IndividualDataTables ViewIcon={ViewIcon} PrintIcon={PrintIcon} startScreening={startScreening} /> */}

                                    <IndividualTableData onboarded={true} ViewIcon={ViewIcon} manageEntityClient={manageEntityClient} manageContinuousMonitoring={manageContinuousMonitoringFunc} PrintIcon={PrintIcon} listData={individualViewedScreeningList} handlePagination={handlePagination} currentPage={currentPage}  downloadZip={downloadZip} reScreening={reScreening} decodedToken={decodedToken} handleDownloadReport={handleDownloadReport}/>

                                    {/* <CorporateDataTable ViewIcon={ViewIcon} PrintIcon={PrintIcon} startScreening={startScreening} /> */}
                                </Tab>
                            )
                        })}
                        {/* <Tab eventKey="Adverse Media" title={renterTabTitle('Green','#037924')}>
            <FilteredTableData ViewIcon={ViewIcon} PrintIcon={PrintIcon} />
            </Tab>
            <Tab eventKey="Documents" title="Documents">
            <FilteredTableData ViewIcon={ViewIcon} PrintIcon={PrintIcon} />
            </Tab> */}
                    </Tabs>

                </div>
            </div>
            <Modal show={show} onHide={handleClose} size='md' centered>
                <Modal.Header >
                    <div className="modal-title text-center text-blue" style={{fontSize:20}}><b>Send to Rescreen</b></div>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="d-flex justify-content-center form-group p-2">
                            <h6>Are you sure you want to send to rescreen?</h6>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer >
                    <div className='d-flex align-items-center'>
                        <button onClick={handleClose} type="button" className="btn btn-primary me-3 px-5">No</button>
                        <button onClick={confirmRescreen} type="button" className="btn btn-primary px-5">Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default IndividualViewedScreening;
