import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    isLoading: false,
    error: null,
    roleLists: [],
    roleDetails: {},
    activeEntityRoleLists: []
}

export const fetchAllRoles = createAsyncThunk("role/fetchAllRoles", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchAllRoles + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const fetchAllActiveRolesByEntity = createAsyncThunk("role/fetchActiveRoles", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchActiveRoles + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const createRole = createAsyncThunk("role/createRole", async (payload) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.createRole,
            data : payload
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const deleteRole = createAsyncThunk("role/deleteRole", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.delete,
            url: API_ENDPOINTS.deleteRole +queryParams,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchRoleById = createAsyncThunk("role/fetchRoleById", async (id) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchRoleById + `/${parseInt(id)}`
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateRole = createAsyncThunk("role/updateRole", async ({id, payload}) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateRole + `/${parseInt(id)}`,
            data : payload
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

const roleSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        resetData(state, action) {
            state.roleDetails = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllRoles.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.roleLists = payload?.data;
            })
            .addCase(createRole.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(fetchAllActiveRolesByEntity.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.activeEntityRoleLists = payload?.data;
            })
            .addCase(deleteRole.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(fetchRoleById.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.roleDetails = payload?.data;
            })
            .addCase(updateRole.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addMatcher(isAnyOf(fetchAllRoles.pending, fetchAllActiveRolesByEntity.pending, createRole.pending, deleteRole.pending, fetchRoleById.pending, updateRole.pending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(fetchAllRoles.rejected,fetchAllActiveRolesByEntity.rejected, createRole.rejected, deleteRole.rejected, fetchRoleById.rejected, updateRole.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again ";

            })
    }
});

const { resetData } = roleSlice.actions;
const roleSliceReducer = roleSlice.reducer;

export { resetData, roleSliceReducer };
export default roleSliceReducer;