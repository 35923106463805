import React, { useEffect, useState } from 'react'
import DownloadIcon from '../../assets/images/download_icon.png'
import PrintBlueIcon from '../../assets/images/print_blue_icon.png'
import Accordion from 'react-bootstrap/Accordion';
import { useLocation, useNavigate } from 'react-router-dom';
import './ClientBilling.scss';
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '../../assets/images/Cancel_icon.png'
import { sendPayBalanceOtp, verifyPayBalanceOtp , fetchInvoiceDetails } from './viewClientBillingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import BackIcon from '../../assets/images/back-btn-arrow.png';
import { decodeToken } from 'react-jwt';

function ViewClientBilling() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { isLoading, invoiceDetails } = useSelector(state => state.viewClientBillingReducer);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigateToPreviousBills = (id) => {
        let path = `/billing/previous_bills`;
        navigate(path,{ state: { entityId: id } });
    }

    const routeChange2 = () => {
        let path = `/billing/edit_pricing`;
        navigate(path);
    }

    const routeChange3 = () => {
        let path = `/billing/payment_received`;
        navigate(path);
    }

    const schema = yup.object().shape({
        otp: yup.number().typeError("Only number are allowed").required('OTP is required')
    });

    const { control, register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    const sendPayBalanceOtpFunction = () => {
        reset();
        dispatch(sendPayBalanceOtp({ invoiceId: 3 })).unwrap().then((res) => {
            handleShow();
            toast.success(res?.message)
        }).catch((error) => {
            toast.error(error?.message);
        });
    };

    const onSubmit = (data) => {
        data.invoiceId = 3;
        dispatch(verifyPayBalanceOtp(data)).unwrap().then((res) => {
            toast.success(res?.message)
            handleClose();
        }).catch((error) => {
            toast.error(error?.message);
        });
    }

    useEffect(() => {
        if (state?.invoiceId) {
            dispatch(fetchInvoiceDetails('?invoiceId=' + state?.invoiceId)).unwrap()
                .then((res) => {
                    // setInvoiceData(res?.data);
                }).catch((error) => {
                    toast.error(error?.message);
                })
        }
    }, []);

    const formattedString =(originalString)=> originalString.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)) .join(' ');

    const calculateTotalAmount = (data) => {
        // Calculate subtotals
        const subtotals = data.map(item => item.price * item.total_used_count);
        // Calculate total
        const total = subtotals.reduce((acc, subtotal) => acc + subtotal, 0);
        
        return total;
    }
    const navigateToPrevious = () => {
        navigate(-1);
    }
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);
    return (
        <>
            <div className='grey-background vh-150'>
                {isLoading && <Loader />}
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <button onClick={navigateToPrevious} className='back-button text-blue'><img src={BackIcon} style={{width:8,marginBottom:2,marginRight:4,objectFit:'contain'}}/> Back</button>
                    <h6 onClick={()=>navigateToPreviousBills(invoiceDetails?.entity_id)} className='dark-blue-small-bg text-blue p-3' style={{ marginRight: 16, borderRadius: 50, height: 50 }}><b>View Past Bills</b></h6>
                </div>
                <div className='white-background p-3 mb-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h1 className='text-blue px-2 fw-normal'>Client Details</h1>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className="d-flex align-items-center">
                            {((decodedToken?.roles?.permissions?.download_invoice && decodedToken?.isOomero) || decodedToken?.isSuperAdmin) &&
                                <div className='me-3'>
                                    <img src={DownloadIcon} style={{ height: 28, objectFit: 'contain', cursor: 'pointer' }} />
                                </div>
                            }
                             {((decodedToken?.roles?.permissions?.print_client_invoice && decodedToken?.isOomero) || decodedToken?.isSuperAdmin) &&
                                <div className=''>
                                    <img src={PrintBlueIcon} style={{ height: 28, objectFit: 'contain', cursor: 'pointer' }} />
                                </div>
}
                            </div>
                            {invoiceDetails && invoiceDetails?.package_type && invoiceDetails?.package_type ==='postpaid' &&
                            <button onClick={sendPayBalanceOtpFunction} className='btn-secondary-small-outline ' style={{ marginLeft: 16 }}>Pay Balance</button>
                            }
                            <h6 className='text-blue dark-blue-small-bg' onClick={routeChange3} style={{ marginLeft: 16 }}>Payment Received</h6>
                        </div>
                    </div>
                    <hr />
                    <div className="row mt-3">
                        <div className="col">
                            <h4 className='text-black px-2 fw-normal'>Company Name : <b>{invoiceDetails?.entity_name}</b></h4>
                        </div>
                        <div className="col">
                            <h4 className='text-black px-2 fw-normal'>Entity Type : <b>{invoiceDetails?.business_type}</b></h4>
                        </div>
                        <div className="col">
                            <h4 className='text-black px-2 fw-normal'>Bill Cycle : <b>{(new Date(invoiceDetails?.startdate)).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })} to {(new Date(invoiceDetails?.enddate)).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}</b></h4>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <h4 className='text-black px-2 fw-normal'>Current Invoice Value: <b>{invoiceDetails?.currency_code} {invoiceDetails?.due_amount}</b></h4>
                        </div>
                        <div className="col">
                            <h4 className='text-black px-2 fw-normal'>Current Balance : <b>{invoiceDetails?.currency_code} {invoiceDetails?.wallet_balance}</b></h4>
                        </div>
                        <div className="col">
                            <h4 className='text-black px-2 fw-normal'>Credit Limit : <b>{invoiceDetails?.max_credit_limit &&invoiceDetails?.currency_code} {invoiceDetails?.max_credit_limit}</b></h4>
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-end'>
                    <div className='d-flex align-items-center'>
                        {/* <h6 onClick={routeChange2} className='dark-blue-small-bg text-blue p-3' style={{ borderRadius: 50, height: 50 }}><b>Edit Billing</b></h6> */}
                    </div>
                </div>
                <div className='white-background p-3 mb-3'>
                    <div className='dark-blue-small-bg p-3 mb-3'>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <div>
                                    <h2 className='text-blue px-2 fw-normal'>Summary</h2>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div>
                                    <h2 className='text-blue px-2 fw-normal'>Amount</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Accordion className='client-billing-accordion client-services'>
                    { invoiceDetails && invoiceDetails?.package_service &&
                        Object.keys(invoiceDetails?.package_service).map((category, i) => (
                            <Accordion.Item eventKey={i} key={category}>
                                <Accordion.Header>
                                    <div className="d-flex" style={{ width: '99%' }}>
                                        <div>
                                            <h2 className='text-black fw-normal'>{formattedString(category)}</h2>
                                        </div>
                                        <div>
                                            <i className="fa fa-chevron-down ms-3"></i>
                                        </div>
                                        <div className="ms-auto">
                                            <h2 className='text-black fw-normal' style={{ fontSize: 15 }}><b>{invoiceDetails?.currency_code} {calculateTotalAmount(invoiceDetails?.package_service[category])}</b></h2>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div style={{ marginLeft: 40 }} >
                                        <div className="row mt-2">
                                            <div className="col-4">
                                                <h2 className='text-black fw-normal'><b>Reports</b></h2>
                                            </div>
                                            <div className="col-4">
                                                <h2 className='text-black fw-normal'><b>Reports Price</b></h2>
                                            </div>
                                            <div className="col-3">
                                                <h2 className='text-black fw-normal'><b>Sub-Total</b></h2>
                                            </div>
                                            <div className="col-1">
                                                <h2 className='text-black fw-normal'><b>{invoiceDetails?.currency_code} {calculateTotalAmount(invoiceDetails?.package_service[category])}</b></h2>
                                            </div>
                                        </div>
                                    
                                        {invoiceDetails?.package_service[category].map((service, i) => (
                                            <div className="row mt-2" key={service.type}>
                                                <div className="col-4">
                                                    <h2 className='text-black fw-normal'>{formattedString(service?.service_name)}: {service?.total_used_count}</h2>
                                                </div>
                                                <div className="col-4">
                                                    <h2 className='text-black fw-normal'>{service?.price}</h2>
                                                </div>
                                                <div className="col-3">
                                                    <h2 className='text-black fw-normal'>Sub-Total</h2>
                                                </div>
                                                <div className="col-1">
                                                    <h2 className='text-black fw-normal'></h2>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                          ))
                        }
                    </Accordion>

                    <div className='white-background p-3 mb-3'>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <div>
                                    <h2 className='text-blue px-2 fw-normal'><b>Total</b></h2>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div>
                                    <h2 className='text-blue px-2 fw-normal'><b>{invoiceDetails?.currency_code} {invoiceDetails?.due_amount}</b></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='white-background p-3 mb-3'>
                    <div className="d-flex flex-row">
                        <div className='mt-3'>
                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                        </div>
                        <div className="p-2 mt-1">
                            <h2 className='text-blue px-2 fw-normal'><b>Receive PDF Invoice By Email</b></h2>
                            <h6 className='text-black px-2 fw-normal'>Turn on this feature to receive a PDF version of your invoice by email. Invoices are available within first three days of the month.</h6>
                        </div>
                    </div>
                </div> */}
            </div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header >
                    <h5 className="modal-title text-center text-blue mt-2"><b>Pay Balance OTP Verification</b></h5>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <hr />
                <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                        <div className="form-group">
                            <input
                                type='text'
                                className={`form-control form-control-lg ${errors.otp ? 'is-invalid' : ''}`}
                                placeholder="Enter OTP"
                                {...register('otp', {
                                    required: `Enter OTP`
                                })}
                            />

                            {errors?.otp && (
                                <div className="invalid-feedback">{errors?.otp?.message}</div>
                            )} 
                        </div>

                </Modal.Body>
                <Modal.Footer >
                <div className='d-flex justify-content-between'>
                        <button onClick={handleClose} type="button" className="btn-secondary mb-3" style={{ marginRight: 20, width: 100 }}>No</button>
                        <button type="submit" className="btn-primary mb-3" style={{ width: 100 }}>Yes</button>
                    </div>
                </Modal.Footer>
                    </form>
            </Modal>
        </>
    )
}

export default ViewClientBilling
