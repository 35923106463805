import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import ThumbIcon from '../../assets/images/thumb_icon.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { countryList } from '../Registration/registrationSlice';
import { createFxRate, fetchFxRateDetailById, getCurrencyList, resetFxList, updateFxRate } from './fxRatesSlice';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify'
import BackIcon from '../../assets/images/back-btn-arrow.png';
import Select from 'react-select';
import { decodeToken } from 'react-jwt';

const AddNewFxRates = () => {
    const [show, setShow] = useState(false);
    const [currencyIsoCode, setCurrencyIsoCode] = useState('');
    const [selectedCountryId, setSelectedCountryId] = useState('');
    const { countryData } = useSelector(state => state.registerReducer);
    const { isLoading, currencyList } = useSelector(state => state.fxRatesReducer);
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { state } = useLocation();
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/fxrates`;
        navigate(path);
    }

    const navigateToPrevious = () => {
        navigate(-1);
    }

    const schema = yup.object().shape({
        baseCurrency: yup.number().typeError('Base Currency').required('Base Currency is required'),
        currencyId: yup.number().typeError('Invalid Currency Id').required('Currency is required').test('notEqual', 'Base Currency must not be equal to Currency Id', function (value) {
            const currencyId = this.parent.baseCurrency;
            return value !== currencyId;
          }),
        currencyIsoCode: yup.string(),
        exchangeRate: yup.number().typeError('Invalid Exchange Rate').required('Exchange Rate is required')
    });

    const { control, register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        dispatch(countryList());
        dispatch(getCurrencyList());
    }, []);

    useEffect(() => {        
        const fetchBaseCountry = async () => {
            if (state?.baseCurrencyId) {
    
                // If countryData or some part of this involves async, wrap it in await
                const baseCountry = await countryData.find(country => country.country_currency_id === state.baseCurrencyId);
                if (baseCountry) {
                    setSelectedCountryId(baseCountry?.country_id);
                    setValue('baseCurrency', state?.baseCurrencyId);
                }
            }
        };
    
        fetchBaseCountry(); // Call the async function inside useEffect
    }, [state?.baseCurrencyId]);

    useEffect(() => {
        if (state?.fxratesId) {
            const queryParam = `?fxRateId=${state.fxratesId}`;
            dispatch(fetchFxRateDetailById(queryParam)).unwrap()
                .then((res) => {
                    setValue('baseCurrency', res?.base_currency);
                    setValue('currencyId', res?.currency_id);
                    setValue('exchangeRate', res?.exchange_rate);

                    setSelectedCountryId(res?.base_country_id);
                    setCurrencyIsoCode(res?.currency_code);
                }).catch((error) => {
                    toast.error(error?.message);
                })
        }
    }, [state?.fxratesId]);

    const onSubmit = (data) => {
        const apiFunction = (state?.fxratesId) ? updateFxRate({payload : data, queryParam : `?fxrateId=${state?.fxratesId}`}) : createFxRate(data);

        dispatch(apiFunction).unwrap()
            .then((res) => {
                toast.success(res?.message);
                reset();
                navigate(`/fxrates`);
            }).catch((error) => {
                toast.error(error?.message);
            })
    }
    
    const handleBaseCurrencyChange = (selectedValue) => {
        setValue('baseCurrency', selectedValue?.country_currency_id);
        setSelectedCountryId(selectedValue?.value);
    }

    const handleCurrencyChange = (selectedValue) => {
        setCurrencyIsoCode(selectedValue);
    }

    useEffect(() => {
        if (!(((decodedToken?.roles?.permissions?.create_fx_rate || decodedToken?.roles?.permissions?.edit_fx_rate) && decodedToken?.isOomero) || decodedToken?.isSuperAdmin)) {
            toast.error("You don't have access for fx rates");
            navigate('/');
        }
    }, [decodedToken]);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            padding: '1px',
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
            boxShadow: 'none',
            '&:hover': {
                border: '2px solid #B3B3B3',
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#B3B3B3',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '14px',
        }),
    };

    return (
        <div className='grey-background'>
            <button onClick={navigateToPrevious} className='back-button text-blue'><img src={BackIcon} style={{width:8,marginBottom:2,marginRight:4,objectFit:'contain'}}/> Back</button>
            <form className='form' onSubmit={handleSubmit(onSubmit)}>
            <div className='white-background p-3 my-3'>
                    <h1 className='text-blue px-2 fw-normal'>FX Rates Setup</h1>
                    <hr />
                    <div className='row g-3 mb-3'>
                        <div className='col-lg-6 col-md-6'>
                            <div className="form-group">
                                <label className="form-label" for="exampleFormControlSelect1">Base Country</label>
                                <Select
                                    placeholder="Select Country"
                                    options={countryData.map((country) => {
                                        return { label: country.name, value: country.country_id, country_currency_id: country.country_currency_id }
                                    })}
                                    styles={customStyles}
                                    value={selectedCountryId ? { label: countryData.find(country => country.country_id == selectedCountryId)?.name, value: selectedCountryId } : ''}
                                    onChange={(selectedOption) => {
                                        handleBaseCurrencyChange(selectedOption);
                                    }}  
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <div className="form-group">
                                <label className="form-label" for="exampleInputEmail1">Base Currency</label>
                                <Controller
                                    name="baseCurrency"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <select id="statusDropdown" className={`form-control form-select ${errors.baseCurrency ? 'is-invalid' : ''}`} {...field} disabled>
                                            <option>Select Currency</option>
                                            {currencyList.map((currency) => (
                                                <option key={currency.country_currency_id} value={currency.country_currency_id}>
                                                    {currency.currency}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                />
                                {errors?.baseCurrency && (
                                    <div className="invalid-feedback">{errors?.baseCurrency?.message}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='white-background p-3 mb-3'>
                    <h1 className='text-blue px-2 fw-normal'>{(state?.fxratesId)? 'Edit' : 'Add' } New Currency</h1>
                    <hr />
                    <div className='row g-3 mb-3'>
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                <label className="form-label" for="exampleFormControlSelect1">Currency Name</label>
                                <Controller
                                    name="currencyId"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            id="currencyId"
                                            placeholder="Select Currency"
                                            className={`${errors.currencyId ? 'is-invalid' : ''}`}
                                            options={currencyList.map((currency) => {
                                                return { label: `${currency.country_name} (${currency.currency} ${currency.currency_symbol})` , value: currency.country_currency_id }
                                            })}
                                            styles={customStyles}
                                            value={field.value ? (() => {
                                                const labelValue = currencyList.find(obj => obj.country_currency_id == field.value);
                                                return { label: labelValue ? `${labelValue.country_name} (${labelValue.currency} ${labelValue.currency_symbol})` : '', value: field.value };
                                            })() : ''}
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption?.value);
                                                handleCurrencyChange(currencyList.find(obj => obj.country_currency_id == selectedOption?.value)?.currency_code)
                                            }}
                                        />
                                    )}
                                />
                                    
                                {errors?.currencyId && (
                                    <div className="invalid-feedback">{errors?.currencyId?.message}</div>
                                )}
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className="form-group">
                                <label className="form-label" for="exampleInputEmail1">Currency ISO Code</label>
                                <input type="text" value={currencyIsoCode} className="form-control" disabled></input>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className="form-group mb-3">
                                <label className="form-label" for="exampleInputEmail1">FX Rates</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.exchangeRate ? 'is-invalid' : ''}`}
                                    placeholder=""
                                    name="exchangeRate"
                                    {...register('exchangeRate')}
                                />
                                {errors?.exchangeRate && (
                                    <div className="invalid-feedback">{errors?.exchangeRate?.message}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row-reverse">
                    <button type="submit" className="btn btn-primary mt-3 mb-1">Save</button>
                </div>
            </form>
            <Modal show={show} onHide={handleClose} centered >
                <Modal.Body>
                    <div className='d-flex justify-content-center'>
                        <img src={ThumbIcon} style={{ width: 70, height: 70, objectFit: 'contain' }} />
                    </div>
                    <h3 className='text-blue text-center mt-3 mb-0 fw-normal'>Changes done successfully !!</h3>
                    <div className="d-flex justify-content-center">
                        <button onClick={routeChange} className="btn-primary w-50 my-3">Ok</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddNewFxRates
