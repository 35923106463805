import React, { useState } from 'react'
import ScreenActionIcon from '../../../assets/images/screeing_action.png'
import CommentIcon from '../../../assets/images/comment_icon.png'
import Accordion from 'react-bootstrap/Accordion';
import ViewIcon from '../../../assets/images/view_icon.png'
import PassportImage from '../../../assets/images/passport_img.png'

function OtherInfo() {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };
    return (
        <div>
            <div className='d-flex align-items-center mt-3'>
                <img src={ScreenActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                <h2 className='text-ambed text-center'>Old Data</h2>
            </div>
            <hr />
            <Accordion className='client-billing-accordion client-services'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div className="d-flex" style={{ width: '99%' }}>
                            <div>
                                <h2 className='text-black fw-normal'>Basic Info</h2>
                            </div>

                            <div className="ms-auto">
                                <i className="fa fa-chevron-down ms-3"></i>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div style={{ marginLeft: 40 }}>
                            <div className="row mt-2">
                                <div className="col-4">
                                    <h2 className='text-black fw-normal'><b>First Name : </b>Sam</h2>
                                </div>
                                <div className="col-4">
                                    <h2 className='text-black fw-normal'><b>Middle Name : </b>
                                        JJ</h2>
                                </div>
                                <div className="col-4">
                                    <h2 className='text-black fw-normal'><b>Last Name : </b>
                                        Tarly</h2>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-4">
                                    <h2 className='text-black fw-normal'><b>Date of birth : </b>31/12/2020</h2>
                                </div>
                                <div className="col-4">
                                    <h2 className='text-black fw-normal'><b>Mobile : </b>
                                        +44-12345623</h2>
                                </div>
                                <div className="col-4">
                                    <h2 className='text-black fw-normal'><b>Email : </b>
                                        sample@test.com</h2>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-4">
                                    <h2 className='text-black fw-normal'><b>Aadhar No : </b>123456789011</h2>
                                </div>
                                <div className="col-4">
                                    <h2 className='text-black fw-normal'><b>Pan No : </b>
                                        ABCDE1234D</h2>
                                </div>
                                <div className="col-4">
                                    <h2 className='text-black fw-normal'><b>SSN No : </b>
                                        123456789011</h2>
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <div className="d-flex" style={{ width: '99%' }}>
                            <div>
                                <h2 className='text-black fw-normal'>Bank Details</h2>
                            </div>

                            <div className="ms-auto">
                                <i className="fa fa-chevron-down ms-3"></i>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div style={{ marginLeft: 40 }}>

                            <div className='custom-table-body'>
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <h6 className='text-black'>Kotak</h6>
                                    </div>
                                    <div className='col-lg-3'>
                                        <h6 className='text-black'>12345676678</h6>
                                    </div>
                                    <div className='col-lg-3'>
                                        <h6 className='text-black'>123333</h6>
                                    </div>
                                    <div className='col-lg-2'>
                                        <h6 className='text-black'>123333</h6>
                                    </div>
                                    <div className='col-lg-1'>
                                        <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <div className="d-flex" style={{ width: '99%' }}>
                            <div>
                                <h2 className='text-black fw-normal'>Documents</h2>
                            </div>

                            <div className="ms-auto">
                                <i className="fa fa-chevron-down ms-3"></i>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <Accordion className='aml-accordion documnets-tab-accordion' defaultActiveKey={0} flush>
                                {[
                                    { key: 1, status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', title: 'Passport' },

                                ].map((item) =>
                                    <Accordion.Item eventKey={item.key} style={{ paddingLeft: 30, paddingRight: 30 }}>
                                        <Accordion.Header>
                                            {/* <div className="d-flex align-items-center">
                                                <h2 className='text-blue'>{item.title}</h2>
                                                <p className='statusBtn_small' style={{ backgroundColor: item.statusBg, color: item.statusColor, fontSize: 12, marginLeft: 10 }}>{item.status}</p>
                                            </div> */}
                                            <div className="d-flex" style={{ width: '99%' }}>
                                                <div>
                                                    <div className="d-flex align-items-center">
                                                        <h2 className='text-blue'>{item.title}</h2>
                                                        <p className='statusBtn_small' style={{ backgroundColor: item.statusBg, color: item.statusColor, fontSize: 12, marginLeft: 10 }}>{item.status}</p>
                                                    </div>
                                                </div>
                                                <div className="ms-auto">
                                                    <i className="fa fa-chevron-down ms-3"></i>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className='custom-table'>
                                                <div className='custom-table-head'>
                                                    <div className='row'>
                                                        <div className='col-lg-3'>
                                                            <h6 className='text-blue'>Checks Done</h6>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6 className='text-blue'>View Documents</h6>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6 className='text-blue'>Provider</h6>
                                                        </div>
                                                        <div className='col-lg-2'>
                                                            <h6 className='text-blue'>Result</h6>
                                                        </div>
                                                        <div className='col-lg-3'>
                                                            <h6 className='text-blue'>Action</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Accordion className='client-billing-accordion client-services'>
                                                    {[
                                                        { key: 1, status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', check_done: 'ID Document', action: 'Request or Upload Additional Document' },
                                                        { key: 2, status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', check_done: 'Liveness', action: 'Re-KYC' },
                                                        { key: 3, status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', check_done: 'ID Document - Face', action: 'Re-KYC' },
                                                        { key: 4, status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', check_done: 'OCR Content', action: 'Request or Upload Additional Document' },
                                                    ].map((item) =>
                                                        <Accordion.Item eventKey={item.key}>
                                                            <Accordion.Header onClick={toggleAccordion}>
                                                                <div className='row align-items-center w-100'>
                                                                    <div className='col-lg-3'>
                                                                        <h6 className='text-black'>{item.check_done}<span>
                                                                            <i className={`fa fa-chevron-down ms-3 ${isAccordionOpen ? 'rotate-chevron' : ''}`}></i>
                                                                        </span></h6>
                                                                    </div>
                                                                    <div className='col-lg-2'>
                                                                        <img src={PassportImage} style={{ width: 55, objectFit: 'contain' }} />
                                                                    </div>
                                                                    <div className='col-lg-2'>
                                                                        <h6 className='text-black'>Yoti</h6>
                                                                    </div>
                                                                    <div className='col-lg-2'>
                                                                        <p className='statusBtn' style={{ backgroundColor: item.statusBg, color: item.statusColor }}>{item.status}</p>
                                                                    </div>
                                                                    <div className='col-lg-3'>
                                                                        <p style={{ backgroundColor: '#E6F2FB', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'fit-content', fontSize: 14, }} className='text-blue mb-0'>{item.action}</p>
                                                                    </div>
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className='row'>
                                                                    <div className='col-lg-4'>
                                                                        <div className='d-flex align-items-center mt-3'>
                                                                            <h6 className='text-black fw-bold'>Country:</h6>
                                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>United Kingdom</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-4'>
                                                                        <div className='d-flex align-items-center mt-3'>
                                                                            <h6 className='text-black fw-bold'>Type:</h6>
                                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>P</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-4'>
                                                                        <div className='d-flex align-items-center mt-3'>
                                                                            <h6 className='text-black fw-bold'>Passport No:</h6>
                                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>125035493</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-4'>
                                                                        <div className='d-flex align-items-center mt-3'>
                                                                            <h6 className='text-black fw-bold'>Surname:</h6>
                                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>Golechha</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-4'>
                                                                        <div className='d-flex align-items-center mt-3'>
                                                                            <h6 className='text-black fw-bold'>Given Names:</h6>
                                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>Sandeep</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-4'>
                                                                        <div className='d-flex align-items-center mt-3'>
                                                                            <h6 className='text-black fw-bold'>Code:</h6>
                                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>GBR</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-4'>
                                                                        <div className='d-flex align-items-center mt-3'>
                                                                            <h6 className='text-black fw-bold'>Nationality:</h6>
                                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>British Citizen</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-4'>
                                                                        <div className='d-flex align-items-center mt-3'>
                                                                            <h6 className='text-black fw-bold'>Sex:</h6>
                                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>M</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-4'>
                                                                        <div className='d-flex align-items-center mt-3'>
                                                                            <h6 className='text-black fw-bold'>Date of Birth:</h6>
                                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>22/Dec/1971</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-4'>
                                                                        <div className='d-flex align-items-center mt-3'>
                                                                            <h6 className='text-black fw-bold'>Place of Birth:</h6>
                                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>Chennai</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-4'>
                                                                        <div className='d-flex align-items-center mt-3'>
                                                                            <h6 className='text-black fw-bold'>Date of Issue:</h6>
                                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>02/Jun/2021</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-4'>
                                                                        <div className='d-flex align-items-center mt-3'>
                                                                            <h6 className='text-black fw-bold'>Date of Expiry:</h6>
                                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>02/Jun/2031</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-12'>
                                                                        <div className='d-flex align-items-center mt-3'>
                                                                            <h6 className='text-black fw-bold'>MRZ No:</h6>
                                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>asgsfdsgfgfghjgrhtjytregfngmhgkyrgbfngjyedbfgjyebnjfegjyuytegjytyegjgjgesjthgeseghthf</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    )}
                                                </Accordion>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                            </Accordion>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </div>
    )
}

export default OtherInfo
