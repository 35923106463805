import React from 'react'
import { Accordion } from 'react-bootstrap'
import SearchIcon from '../../assets/images/search_icon.png'
import EditIcon from '../../assets/images/edit_icon.png'
import PrintIcon from '../../assets/images/Print_Icon.png'

function AmlCheck() {
    const tableData = [
        {
            created: '12-02-2020',
            name: 'Fable',
            oomeroID: 'UYRUI70',
            matchStatus: 'Credit Safe',
            riskLevel: 20,
            assignedTo: 'Yes',
            searcher: 'Yes',
            changed: 'Yes',
            monitor: false
        },
        {
            created: '12-02-2020',
            name: 'Fable',
            oomeroID: 'UYRUI70',
            matchStatus: 'Credit Safe',
            riskLevel: 20,
            assignedTo: 'Yes',
            searcher: 'Yes',
            changed: 'Yes',
            monitor: true
        },
        {
            created: '12-02-2020',
            name: 'Fable',
            oomeroID: 'UYRUI70',
            matchStatus: 'Credit Safe',
            riskLevel: 20,
            assignedTo: 'Yes',
            searcher: 'Yes',
            changed: 'Yes',
            monitor: false
        },
        {
            created: '12-02-2020',
            name: 'Fable',
            oomeroID: 'UYRUI70',
            matchStatus: 'Credit Safe',
            riskLevel: 20,
            assignedTo: 'Yes',
            searcher: 'Yes',
            changed: 'Yes',
            monitor: false
        },
        {
            created: '12-02-2020',
            name: 'Fable',
            oomeroID: 'UYRUI70',
            matchStatus: 'Credit Safe',
            riskLevel: 20,
            assignedTo: 'Yes',
            searcher: 'Yes',
            changed: 'Yes',
            monitor: true
        },
        {
            created: '12-02-2020',
            name: 'Fable',
            oomeroID: 'UYRUI70',
            matchStatus: 'Credit Safe',
            riskLevel: 20,
            assignedTo: 'Yes',
            searcher: 'Yes',
            changed: 'Yes',
            monitor: false
        }
    ];

    return (
        <div className='grey-background vh-101'>
            <div className='white-background p-3 mb-3'>
                <h1 className='text-blue px-2 fw-normal'>Name and details</h1>
                <hr />
                <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                        <div className="form-group">
                            <label className="form-label" >Name</label>
                            <input type="text" className="form-control" placeholder='' />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <label className="form-label" >Client Reference (optional)</label>
                        <input type="text" className="form-control" placeholder='' />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-lg-6 col-md-6'>
                        <div className="form-group">
                            <label className="form-label" >Year of Birth  (optional)</label>
                            <input type="text" className="form-control" placeholder='' />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <label className="form-label" >Country (optional)</label>
                        <input type="text" className="form-control" placeholder='' />
                    </div>
                </div>
            </div>
            <div className='white-background p-3 mb-3'>
                <h1 className='text-blue px-2 fw-normal'>Entity Type</h1>
                <hr />
                <div className='row p-2'>
                    <div className='col-lg-3'>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="any" />
                            <label className="form-check-label" htmlFor="any">Any</label>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="person" />
                            <label className="form-check-label" htmlFor="person">Person</label>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="company" />
                            <label className="form-check-label" htmlFor="company">Company</label>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="organization" />
                            <label className="form-check-label" htmlFor="organization">Organization</label>
                        </div>
                    </div>
                </div>
                <div className='row p-2'>
                    <div className='col-lg-3'>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="vessel" />
                            <label className="form-check-label" htmlFor="vessel">Vessel</label>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="aircraft" />
                            <label className="form-check-label" htmlFor="aircraft">Aircraft</label>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className="form-group d-flex align-items-center">
                            <label className="form-label me-3 mb-0" for="exampleFormControlSelect1">Fuzziness Interval</label>
                            <select id="statusDropdown" className="form-control form-select" style={{ width: 100 }}>
                                <option>20%</option>
                                <option>40%</option>
                                <option>60%</option>
                                <option>80%</option>
                                <option>100%</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='white-background p-3 mb-3'>
                <div className='d-flex justify-content-between align-items-center'>
                    <h1 className='text-blue px-2 fw-normal'>Sources</h1>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" />
                        <label className="checkbox-blue-text" >
                            Select All
                        </label>
                    </div>
                </div>
                <hr className='mb-0' />
                <Accordion>
                    <Accordion.Item className='id-documents-css accordion-button::after'>
                        <Accordion.Header className='ps-0'>
                            <div className="form-check mt-1">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="checkbox-blue-text" for="defaultCheck1">
                                    Sanctions, Warning and Fitness & Probity
                                </label>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className='p-5 py-2'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Sanctions e.g. OFAC SDN List, HM Treasury Sanctions List
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Warning e.g. US Immigration and Customs Wanted
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-check py-2">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Fitness & Probity e.g US System for Award Management Exclusion
                                </label>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion>
                    <Accordion.Item className='id-documents-css accordion-button::after'>
                        <Accordion.Header className='ps-0'>
                            <div className="form-check mt-1">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="checkbox-blue-text" for="defaultCheck1">
                                    PEPs
                                </label>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className='p-5 py-2'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Heads of State, National Parliaments, National Goverments
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Regional Goverments, Regional Parliaments
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Senior Management & Boards of SOEs
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Mayors and Local City Councils
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion>
                    <Accordion.Item className='id-documents-css accordion-button::after'>
                        <Accordion.Header className='ps-0'>
                            <div className="form-check mt-1">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="checkbox-blue-text" for="defaultCheck1">
                                    All Adverse Media V2
                                </label>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className='p-5 py-2'>
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Property
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Financial AML/CFT
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Fraud-linked
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Narcotics AML/CFT
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Violence AML/CFT
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Terrorism
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Cybercrime
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            General AML/CFT
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Regulatory
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Financial difficulty
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Violence NON-AML/CFT
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Other Financial
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Other Serious
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="form-check py-2">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Other Minor
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className='d-flex justify-content-end my-4'>
                <button className='btn-primary px-5'>Search</button>
            </div>
            <div className='white-background p-3 mb-3'>
                <h1 className='text-blue px-2 fw-normal'>Previously Search</h1>
                <hr />
                <div className='row'>
                    <div className='col-lg-10 col-md-10'>
                        <div className="form-group position-relative">
                            <input type="text" className="form-control" placeholder='Search' ></input>
                            <div className="position-absolute" style={{ right: 20, top: '20%' }}>
                                <img src={SearchIcon} style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 justify-content-end'>
                        <button className="btn btn-primary w-100" style={{ fontSize: 14 }}>Search</button>
                    </div>
                </div>
            </div>
            <div className='custom-table'>
                <div className='custom-table-head'>
                    <div className='row'>
                        <div className='col-md-1'>
                            <p className='text-blue'>Created</p>
                        </div>
                        <div className='col-md-1'>
                            <p className='text-blue'>Name</p>
                        </div>
                        <div className='col'>
                            <p className='text-blue'>Oomero ID</p>
                        </div>
                        <div className='col'>
                            <p className='text-blue'>Match Status</p>
                        </div>
                        <div className='col'>
                            <p className='text-blue'>Risk Level</p>
                        </div>
                        <div className='col'>
                            <p className='text-blue'>Assigned To</p>
                        </div>
                        <div className='col'>
                            <p className='text-blue'>Searcher</p>
                        </div>
                        <div className='col'>
                            <p className='text-blue'>Changed</p>
                        </div>
                        <div className='col'>
                            <p className='text-blue'>Monitor</p>
                        </div>
                        <div className='col'>
                            <p className='text-blue'>Action</p>
                        </div>
                    </div>
                </div>
                {tableData.map((item, index) => (
                    <div className='custom-table-body'>
                        <div className='row' key={index}>
                            <div className='col-md-1 pe-0'>
                                {item.created}
                            </div>
                            <div className='col name-link'>
                                {item.name}
                            </div>
                            <div className='col ps-0'>
                                {item.oomeroID}
                            </div>
                            <div className='col ps-0'>
                                {item.matchStatus}
                            </div>
                            <div className='col'>
                                {item.riskLevel}
                            </div>
                            <div className='col'>
                                {item.assignedTo}
                            </div>
                            <div className='col'>
                                {item.searcher}
                            </div>
                            <div className='col'>
                                {item.changed}
                            </div>
                            <div className='col'>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id={`flexSwitchCheckDefault-${index}`} checked={item.monitor} readOnly />
                                </div>
                            </div>
                            <div className='col'>
                                <img src={EditIcon} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} alt="Edit" />
                                <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} alt="Print" />
                            </div>
                        </div>
                    </div>
                ))}
                {/* {listData && listData?.rows?.length > 0 &&
                    <TablePagination totalPages={parseInt(listData?.totalPages)} currentPage={currentPage} color="primary" handlePagination={handlePagination} />
                } */}
            </div>
        </div>
    )
}

export default AmlCheck