import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../../utils/apiUrls";
import api from "../../../utils/api";

const initialState = {
    isLoading: false,
    error: null,
    individualViewedScreeningList: { rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200 },
    pendingKYCindividualScreeningList: { rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200 },
    entityClientBanks: [],
    entityClientBankDetails: {},
    entityClientCompany: [],
    entityClientCompanyDetails: {},
    fetchDocuments: { rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200 },
    kycDocuments: [],
    adverseMedias:[],
    screeningComments: { rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200 },
    fieldHistory: { rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200 },
    globalSearchDetail: null,
    globalSearches: { rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200 },
    oldData: [],
    amlComments:{ rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200 },
    getOnboardedClientReportData : null
}

export const getOnboardedClientReport = createAsyncThunk("individual_screening/getOnboardedClientReport", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getOnboardedClientReport + queryParam
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchIndividualViewedScreeningList = createAsyncThunk("individual_screening/getIndividualScreeninglist", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getIndividualScreeninglist + queryParam
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const listPendingKYCIndividualScreening = createAsyncThunk("individual_screening/listPendingKYCIndividualScreening", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.listPendingKYCIndividualScreening + queryParam
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateIndividualPersonalDetailsWithReason = createAsyncThunk("individual_screening/updateIndividualPersonalDetailsWithReason", async ({ payload, queryParam }) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.updateIndividualPersonalDetailsWithReason + queryParam,
            data: payload
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const addScreeningComments = createAsyncThunk("individual_screening/addScreeningComments", async ({ payload }) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.addScreeningComments,
            data: payload
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getEntityClientBank = createAsyncThunk("individual_screening/getEntityClientBank", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getEntityClientBank + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getEntityClientBankDetails = createAsyncThunk("individual_screening/getEntityClientBankDetails", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getEntityClientBankDetails + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getEntityClientCompanyDetails = createAsyncThunk("individual_screening/getEntityClientCompanyDetails", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getEntityClientCompanyDetails + queryParams
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const listKycDocuments = createAsyncThunk("individual_screening/listKycDocuments", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.listKycDocuments + queryParams
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const requestDocument = createAsyncThunk("individual_screening/requestDocument", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.requestDocument + queryParams
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const uploadDocument = createAsyncThunk("individual_screening/uploadDocument", async (payload) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.uploadDocument,
            data: payload
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchUploadedDocuments = createAsyncThunk("individual_screening/fetchUploadedDocuments", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchUploadedDocuments + queryParam
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateScreeningInfo = createAsyncThunk("individual_screening/updateScreeningInfo", async ({ payload, queryParam }) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.updateScreeningInfo + queryParam,
            data: payload
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const getScreeningComments = createAsyncThunk("individual_screening/getScreeningComments", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getScreeningComments + queryParams
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const getFieldHistory = createAsyncThunk("individual_screening/getFieldHistory", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getFieldHistory + queryParams
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const getGlobalSearchById = createAsyncThunk("individual_screening/getGlobalSearchById", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getGlobalSearchById + queryParams
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const getAllGlobalSearchById = createAsyncThunk("individual_screening/getAllGlobalSearchById", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAllGlobalSearchById + queryParams
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});
export const getIndividualScreeningClientHistory = createAsyncThunk("individual_screening/getIndividualScreeningClientHistory", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getIndividualScreeningClientHistory + queryParams
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});
export const saveRelevantResult = createAsyncThunk("individual_screening/saveRelevantResult", async ({queryParams, payload}) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.saveRelevantResult + queryParams,
            data: payload
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const removeRelevantResult = createAsyncThunk("individual_screening/removeRelevantResult", async ({queryParams, payload}) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.removeRelevantResult + queryParams,
            data: payload
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const getSearchCertificate = createAsyncThunk("individual_screening/getSearchCertificate", async ({queryParams, payload}) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.getSearchCertificate + queryParams,
            data: payload,
            responseType: 'arraybuffer'
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});
export const getAdverseMedia = createAsyncThunk("individual_screening/getAdverseMedia", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAdverseMedia + queryParams
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const saveExcludedAdverseMedia=createAsyncThunk("individual_screening/saveExcludedAdverseMedia",async(payload) =>{
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.saveExcludedAdverseMedia,
            data: payload
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
})
export const manageScreeningAction = createAsyncThunk("individual_screening/manageScreeningAction", async ({queryParams, payload}) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.manageScreeningAction + queryParams,
            data: payload
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
})

export const saveAmlInfoComment = createAsyncThunk("individual_screening/saveAmlInfoComment", async ( payload) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.saveAmlInfoComment ,
            data: payload
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
})
export const saveAdverseMediaComment = createAsyncThunk("individual_screening/saveAdverseMediaComment", async ( payload) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.saveAdverseMediaComment ,
            data: payload
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
})

export const resendToScreening = createAsyncThunk("individual_screening/resendToScreening", async ({payload, queryParam}) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.resendToScreening + queryParam,
            data: payload
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
})
export const fetchAmlComments = createAsyncThunk("individual_screening/fetchAmlComments", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchAmlComments + queryParam,
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
})

const fetchIndividualScreeningSlice = createSlice({
    name: "individualScreening",
    initialState,
    reducers: {
        resetIndividualViewedScreeningList(state, action) {
            state.individualViewedScreeningList = { rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200 };
        },
        // resetListPendingKYCIndividualScreening(state, action) {
        //     state.pendingKYCindividualScreeningList = { rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200 };
        // },
        // resetGlobalSearchDetails(state, action) {
        //     state.globalSearchDetail = null;
        // },
        resetReportData(state, action) {
            state.getOnboardedClientReportData = null;
        },
        updateIndividualViewedScreeningList(state, action) {
            state.individualViewedScreeningList = action.payload;
        },
        // updateListPendingKYCIndividualScreening(state, action) {
        //     state.pendingKYCindividualScreeningList = action.payload;
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchIndividualViewedScreeningList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.individualViewedScreeningList = payload.data;
            })
            .addCase(getOnboardedClientReport.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.getOnboardedClientReportData = payload.data;
            })
            .addCase(listPendingKYCIndividualScreening.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.pendingKYCindividualScreeningList = payload.data;
            })
            .addCase(updateIndividualPersonalDetailsWithReason.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(addScreeningComments.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(getEntityClientBank.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.entityClientBanks = payload.data
            })
            .addCase(getEntityClientBankDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.entityClientBankDetails = payload.data
            })
            .addCase(getEntityClientCompanyDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.entityClientCompanyDetails = payload.data
            })
            .addCase(listKycDocuments.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.kycDocuments = payload.data;
            })
            .addCase(requestDocument.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(uploadDocument.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(fetchUploadedDocuments.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.fetchDocuments = payload.data
            })
            .addCase(updateScreeningInfo.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(getScreeningComments.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.screeningComments = payload.data;
            })
            .addCase(getFieldHistory.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.fieldHistory = payload.data;
            })
            .addCase(getGlobalSearchById.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.globalSearchDetail = payload.data;
            })
            .addCase(getAllGlobalSearchById.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.globalSearches = payload.data;
            })
            .addCase(getIndividualScreeningClientHistory.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.oldData = payload.data;
            })
            .addCase(saveRelevantResult.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(removeRelevantResult.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(getSearchCertificate.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(getAdverseMedia.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.adverseMedias=payload.data
                state.error = null;
            })
            .addCase(manageScreeningAction.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(saveExcludedAdverseMedia.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(saveAmlInfoComment.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(saveAdverseMediaComment.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(resendToScreening.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
             .addCase(fetchAmlComments.fulfilled, (state, {payload})=>{
                state.isLoading = false;
                state.error = null;
                state.amlComments=payload.data
            })
            .addMatcher(isAnyOf(fetchIndividualViewedScreeningList.pending, listPendingKYCIndividualScreening.pending, updateIndividualPersonalDetailsWithReason.pending, addScreeningComments.pending, getEntityClientBank.pending, getEntityClientBankDetails.pending, getEntityClientCompanyDetails.pending, listKycDocuments.pending, requestDocument.pending, uploadDocument.pending,fetchUploadedDocuments.pending, updateScreeningInfo.pending, getScreeningComments.pending, getFieldHistory.pending, getGlobalSearchById.pending, getAllGlobalSearchById.pending, getIndividualScreeningClientHistory.pending, saveRelevantResult.pending, removeRelevantResult.pending, getSearchCertificate.pending, getAdverseMedia.pending,manageScreeningAction.pending,saveExcludedAdverseMedia.pending, saveAmlInfoComment.pending,saveAdverseMediaComment.pending, resendToScreening.pending,fetchAmlComments.pending, getOnboardedClientReport.pending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(fetchIndividualViewedScreeningList.rejected, listPendingKYCIndividualScreening.rejected, updateIndividualPersonalDetailsWithReason.rejected, addScreeningComments.rejected, getEntityClientBank.rejected, getEntityClientBankDetails.rejected, getEntityClientCompanyDetails.rejected, listKycDocuments.rejected, requestDocument.rejected, uploadDocument.rejected,fetchUploadedDocuments.rejected, updateScreeningInfo.rejected, getScreeningComments.rejected, getFieldHistory.rejected, getGlobalSearchById.rejected, getAllGlobalSearchById.rejected, getIndividualScreeningClientHistory.rejected, saveRelevantResult.rejected, removeRelevantResult.rejected, getSearchCertificate.rejected, getAdverseMedia.rejected,saveExcludedAdverseMedia.rejected,manageScreeningAction.rejected, saveAmlInfoComment.rejected,saveAdverseMediaComment.rejected, resendToScreening.rejected,fetchAmlComments.rejected, getOnboardedClientReport.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = (error) ? error.message : "Request Failed Please Try Again ";
            })
    }
});

const { resetIndividualViewedScreeningList, resetGlobalSearchDetails, resetListPendingKYCIndividualScreening, updateListPendingKYCIndividualScreening, updateIndividualViewedScreeningList, resetReportData } = fetchIndividualScreeningSlice.actions;
const individualScreeningReducer = fetchIndividualScreeningSlice.reducer;

export { resetIndividualViewedScreeningList, resetGlobalSearchDetails, resetListPendingKYCIndividualScreening, updateListPendingKYCIndividualScreening, individualScreeningReducer, updateIndividualViewedScreeningList, resetReportData };
export default individualScreeningReducer;