import React, { useEffect } from 'react'
import SearchIcon from '../../assets/images/search_icon.png'
import ViewIcon from '../../assets/images/view_icon.png'
import { useDispatch, useSelector } from 'react-redux'

import { GetInvoiceData } from './invoiceSlice'

function Invoices() {
    const dispatch = useDispatch()
    const { error, invoice } = useSelector((state) => state.MyInvoicesSliceReducer)
    useEffect(() => {
        dispatch(GetInvoiceData)
    })
    return (
        <div className='grey-background'>
            <div className='white-background p-3 mb-3'>
                <h1 className='text-blue px-2 fw-normal'>View Invoices</h1>
                <hr />
                <div className='row'>
                    <div className='col-lg-10 col-md-12'>
                        <div className="form-group position-relative">
                            <input type="text" className="form-control" placeholder='Search'></input>
                            <div className="position-absolute" style={{ right: 20, top: '20%' }}>
                                <img src={SearchIcon} style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-12'>
                        <div className="form-group">
                            <select id="statusDropdown" className="form-control form-select">
                                <option selected disabled value="">View Invoices</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-12 col-md-12 mt-3'>
                <div className='custom-table'>
                    <div className='custom-table-head'>
                        <div className='row'>
                            <div className='col'>
                                <h6 className='text-blue'>Client Name</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Current Balance</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Current Invoice Amt</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Date of Last Invoice</h6>
                            </div>
                            <div className='col-1'>
                                <h6 className='text-blue' style={{ paddingLeft: 15 }} >Action</h6>
                            </div>
                        </div>
                    </div>
                    <div className='custom-table-body'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='text-black'>Gorem ipsum</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>$100</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>$100</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>11/04/2023</h6>
                            </div>
                            <div className='col-1'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='custom-table-body'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='text-black'>Gorem ipsum</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>$100</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>$100</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>11/04/2023</h6>
                            </div>
                            <div className='col-1'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='custom-table-body'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='text-black'>Gorem ipsum</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>$100</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>$100</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>11/04/2023</h6>
                            </div>
                            <div className='col-1'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='custom-table-body'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='text-black'>Gorem ipsum</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>$100</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>$100</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>11/04/2023</h6>
                            </div>
                            <div className='col-1'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='custom-table-body'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='text-black'>Gorem ipsum</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>$100</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>$100</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>11/04/2023</h6>
                            </div>
                            <div className='col-1'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='custom-table-body'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h6 className='text-black'>Gorem ipsum</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>$100</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>$100</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-black'>11/04/2023</h6>
                            </div>
                            <div className='col-1'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end'>
                <span className="badge bg-primary-badge">1</span>
                <span className="badge">2</span>
                <span className="badge">3</span>
                <span className="badge">4</span>
                <span className="badge"><i className="fa fa-chevron-right"></i></span>
            </div>
        </div>
    )
}

export default Invoices
