import React from 'react'
import CompanyLogo from '../../../assets/images/company_logo.png'
import CompanyInfoIcon from '../../../assets/images/personalDetails.png'
import AddressIcon from '../../../assets/images/current_address.png'
import EditIcon from '../../../assets/images/edit_icon.png'
import ScreenActionIcon from '../../../assets/images/screeing_action.png'

function CompnayDetails() {
    return (
        <>
            <div className='row'>
                <div className='col-lg-2' style={{ borderRight: '1px solid #D9D9D9' }}>
                    <div className='d-flex align-items-center justify-content-between flex-column h-100'>
                        <div className='mt-3'>
                            <img src={CompanyLogo} style={{ width: 158, objectFit: 'contain' }} />
                            <h2 className='text-blue text-center'>Company Name</h2>
                        </div>
                        <div>
                            <h4 className='text-black text-center'>Oomero ID:</h4>
                            <h2 className='text-blue text-center'>OLP1234567890123</h2>
                        </div>
                        <div>
                            <h4 className='text-black text-center'>Tax Number:</h4>
                            <h2 className='text-blue text-center'>07AAGFF2194N1Z1</h2>
                        </div>
                        <div className='mb-3'>
                            <h4 className='text-black text-center'>VAT Number:</h4>
                            <h2 className='text-blue text-center'>07AAGFF2194N1Z1</h2>
                        </div>
                    </div>
                </div>
                <div className='col-lg-10 px-4 py-4'>
                    <div className='d-flex align-items-center justify-content-end '>
                        <img src={EditIcon} style={{ width: 15, objectFit: 'contain', cursor: 'pointer' }} />
                    </div>
                    <div className='d-flex align-items-center '>
                        <img src={CompanyInfoIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                        <h2 className='text-ambed text-center'>Company Info</h2>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className='text-black fw-bold'>Company Type: </h6>
                                <h4 className='text-black' style={{ marginLeft: 5 }}>Tech</h4>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className='text-black fw-bold'>VAT No:</h6>
                                <h4 className='text-black' style={{ marginLeft: 5 }}>321324GT23</h4>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className='text-black fw-bold'>Category:</h6>
                                <h4 className='text-black' style={{ marginLeft: 5 }}>Sam</h4>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className='text-black fw-bold'>Sub-Category:</h6>
                                <h4 className='text-black' style={{ marginLeft: 5 }}>JJ</h4>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className='text-black fw-bold'>Mobile No:</h6>
                                <h4 className='text-black' style={{ marginLeft: 5 }}>+44-12345623</h4>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className='text-black fw-bold'>Landline No:</h6>
                                <h4 className='text-black' style={{ marginLeft: 5 }}>+44-12345623</h4>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className='text-black fw-bold'>Company Email:</h6>
                                <h4 className='text-black' style={{ marginLeft: 5 }}>sample@test.com</h4>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='d-flex align-items-center '>
                        <img src={AddressIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                        <h2 className='text-blue text-center'>Address</h2>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className='text-black fw-bold'>Country:</h6>
                                <h4 className='text-black' style={{ marginLeft: 5 }}>United Kingdom</h4>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className='text-black fw-bold'>City:</h6>
                                <h4 className='text-black' style={{ marginLeft: 5 }}>London</h4>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className='text-black fw-bold'>Postal Code:</h6>
                                <h4 className='text-black' style={{ marginLeft: 5 }}>Tarly</h4>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className='text-black fw-bold'>State/Region:</h6>
                                <h4 className='text-black' style={{ marginLeft: 5 }}>London</h4>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className='text-black fw-bold'>Date From:</h6>
                                <h4 className='text-black' style={{ marginLeft: 5 }}>31/12/2000</h4>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className='text-black fw-bold'>Date To:</h6>
                                <h4 className='text-black' style={{ marginLeft: 5 }}>31/12/2020 </h4>
                            </div>
                        </div>
                        <div className='col-lg-8'>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className='text-black fw-bold'>Address:</h6>
                                <h4 className='text-black' style={{ marginLeft: 5 }}>201, Daimler street, Greater Birmingham, England</h4>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='d-flex align-items-start flex-column mt-3 position-relative'>
                                <h6 className='text-black fw-bold'>Trading Address:</h6>
                                <h4 className='text-black mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</h4>
                                <p className='text-blue mb-0 position-absolute bottom-0' style={{ fontSize: 14, cursor: 'pointer', textDecorationLine: 'underline', top: '83%', right: 10 }}>10 more</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='white-background p-3 my-3'>
                <div className='d-flex align-items-center'>
                    <img src={ScreenActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                    <h2 className='text-ambed text-center'>Add Comments</h2>
                </div>
                <hr />
                <div className="d-flex mb-2">
                    <div style={{ marginRight: 30 }}>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label" for="flexRadioDefault1">Open</label>
                        </div>
                    </div>
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label" for="flexRadioDefault1">Ghost</label>
                        </div>
                    </div>
                </div>  
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='row align-items-end'>
                            <div className='col-lg-9' style={{ width: '80%' }}>
                                <div className="form-group mt-3">
                                    <label className="form-label" for="exampleInputEmail1">Add Comment</label>
                                    <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Add Comment" rows="2" />
                                </div>
                            </div>
                            <div className='col-lg-3' style={{ width: '20%' }}>
                                <button className='btn-primary fw-normal'>Add Comment</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompnayDetails
