import React from 'react';
import './ContentBox.scss';

function MatchContentData({ matchfoundText, match_head1, match_text1, match_text2, match_head2, match_text3, match_head3, match_text4, match_head4, match_text5, match_head5, matchno, listname, imagesource1, imagesource2, imagesource3, imagesource4, imagesource5 }) {

    return (
        <div className='contentbox-css'>
            <p className='text1'>{match_head1}<span className='text2' style={{ fontWeight: 400 }}>{match_text1}</span></p>
            <div className='d-flex'>
                <img src={imagesource1} className='imgicon' />
                <p className='text1'>{match_head2}<span className='text2' style={{ fontWeight: 400 }}>{match_text2}</span></p>
            </div>
            <div className='d-flex'>
                <img src={imagesource2} className='imgicon' />
                <p className='text1'>{match_head3}<span className='text2' style={{ fontWeight: 400 }}>{match_text3}</span></p>
            </div>
            <div className='d-flex'>
                <img src={imagesource3} className='imgicon' />
                <p className='text1'>{match_head4} <span className='text2' style={{ fontWeight: 400 }}>{match_text4}</span></p>
            </div>
            <div className='d-flex'>
                <img src={imagesource4} className='imgicon' />
                <p className='text1'>{match_head5}<span className='text2' style={{ fontWeight: 400 }}>{match_text5}</span></p>
            </div>

            <div className='blue-line'></div>

            <div className='d-flex align-items-center' style={{ marginTop: 5 }}>
                <img src={imagesource5} className='imgicon' />
                <p className='text2' style={{ marginTop: 5 }}>{matchfoundText}<span className='text2 bluetext' style={{ marginLeft: 3 }}>{matchno}</span> {listname}</p>
            </div>
        </div>
    )
}

export default MatchContentData