import React from 'react'
import './ContentBox.scss'

function ContentData({ label1, value1, shoudlAppyMargin1, label2, value2, label3, value3, shoudlAppyMargin2, Text3, SummaryBox, summarylabel, implicatedlabel, summarytext, implicatedtext, QuestionData, CommentBox, commentheading, comment_text,shouldRemoveClass }) {

    return (
        <div className={!shouldRemoveClass ? 'contentbox-css' : 'commentbox-css'}>
            {QuestionData &&
                <>
                    <p className='text1'>{label1}<span className='text2' style={{ fontWeight: 400 }}>{value1}</span></p>
                    <p className='text1' style={{ marginTop: shoudlAppyMargin1 ? '22px' : '0px' }}>{label2}<span className='text2' style={{ fontWeight: 400 }}>{value2}</span></p>
                    {Text3 && <p className='text1' style={{ marginTop: shoudlAppyMargin2 ? '22px' : '0px' }}>{label3}<span className='text2' style={{ fontWeight: 400 }}>{value3}</span></p>}
                </>
            }

            {SummaryBox && <div className='d-flex'>
                <div>
                    <p className='text1'>{summarylabel}</p>
                    <p className='text1' style={{ width: '75%' }}>{implicatedlabel}</p>
                </div>
                <div>
                    <p className='text2' style={{ fontWeight: 400 }}>{summarytext}</p>
                    <p className='text2' style={{ fontWeight: 400 }}>{implicatedtext}</p>
                </div>
            </div>}

            {CommentBox &&
                <>
                    <p className='text1'>{commentheading}</p>
                    <p className='text2' style={{ marginLeft: 0 }}>
                        {comment_text}
                    </p>
                </>
            }

        </div>
    )
}

export default ContentData