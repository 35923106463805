import React, { useEffect, useState } from 'react'
import '../Profile.scss';
import OnboardClientBar from '../../../components/OnboardClientBar/OnboardClientBar';
import LinkCompnay from '../../../assets/images/Link_company.png'
import * as  yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import BankDetails from '../../../assets/images/bank_details.png'
import { useNavigate, useHistory, useLocation } from 'react-router-dom';
import { Controller, useForm, } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { countryList } from "../../../pages/Registration/registrationSlice"
import { useDispatch, useSelector } from 'react-redux/es';
import { saveEntityClientBank, saveEntityClientCompany, fetchDataEntityClientBank, fetchDataEntityClientCompany, fetchEntityData } from '../profileSlice'
import Loader from '../../../components/Loader/Loader';
import { decodeToken } from "react-jwt";
import { decryptQueryParam } from '../../../utils/EncryptionUtils';
import Logo from "../../../assets/images/logo.png"
import  Select  from 'react-select';
import BlackInfoIcon from '../../../assets/images/black _Info Circle_.png'

const designations = [
    { "label": "Director", "value": "Director" },
    { "label": "Managing Director", "value": "Managing Director" },
    { "label": "Non-Executive Director", "value": "Non-Executive Director" },
    { "label": "Chief Executive Officer (CEO)", "value": "Chief Executive Officer (CEO)" },
    { "label": "Chief Financial Officer (CFO)", "value": "Chief Financial Officer (CFO)" },
    { "label": "Chief Commercial Officer (CCO)", "value": "Chief Commercial Officer (CCO)" },
    { "label": "Chief Operating Officer (COO)", "value": "Chief Operating Officer (COO)" },
    { "label": "Chief Technical Officer (CTO)", "value": "Chief Technical Officer (CTO)" },
    { "label": "Chief Marketing Officer (CMO)", "value": "Chief Marketing Officer (CMO)" },
    { "label": "Chief Investment Officer (CIO)", "value": "Chief Investment Officer (CIO)" },
    { "label": "C-Suite Job (CXO) - Other", "value": "C-Suite Job (CXO) - Other" },
    { "label": "Managing Partner", "value": "Managing Partner" },
    { "label": "Partner", "value": "Partner" },
];

function IndividualBankDetails() {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { countryData } = useSelector(state => state.registerReducer);
    const [entityClientId, setEntityClientId] = useState();
    const { isLoading,  entityClientCompany, entityClientBank } = useSelector((state) => state.ProfileReducer);
    const userToken = localStorage.getItem('token');
    const decodedToken = userToken?decodeToken(userToken):null;
    const [bankBetails, setBankBetails] = useState(false);
    const [decryptedValue, setDecryptedValue] = useState(null);

    useEffect(() => {
        dispatch(countryList());
        if (state?.entityClientId) {
            setEntityClientId(state?.entityClientId);
            fetchDataForBank(state?.entityClientId);
            fetchDataForCompany(state?.entityClientId);
        }
    }, []);

    const navigate = useNavigate();

    const bankSchema = yup.object().shape({
        bank_name: yup.string().required('Bank name is required'),
        account_no: yup.string().required('Account No is required').typeError('Invalid Account No'),
       
        sort_code: (state?.countryId != 100) ? yup.string().required("Sort code is required") : yup.string().notRequired(),
        ifsc_code: (state?.countryId == 100) ? yup.string() .matches(/^[A-Za-z]{4}\d{7}$/, 'Invalid IFSC Code format').required("IFSC code is required") : yup.string().notRequired(),
        iban: yup.string().typeError('Invalid IBAN number').optional(),
        bic_code: yup.string()
            .optional(),
        address_type: yup.string().required('Address type is required'),
        address: yup.string().required('Address is required'),
    });

    const companySchema = yup.object().shape({
        country_id: yup.number().required('Country name is required').typeError("Select Country name"),
        company_name: yup.string().required('Compnay is required'),
        company_reg_no: yup.string().required('Register number is required'),
        designation: yup.string().optional(),
        type_of_director: yup.string().optional(),
        shareholder: yup.string().required(' Shareholder type is require'),
        ultimate_beneficial_owner: yup.string().required(' Ultimate beneficial owner is required'),
    });

    const { register: bankRegister, handleSubmit: handleBankSubmit,control:bankControl, formState: { errors }, reset: resetBank, watch : watchBank,setValue : setValueBank } = useForm({
        resolver: yupResolver(bankSchema),
    });

    const { register: companyRegister, handleSubmit: handleCompanySubmit, formState: { errors: errors1 }, reset: resetCompany , trigger,control} = useForm({
        resolver: yupResolver(companySchema),
    });

    const addressType = watchBank('address_type');

    const fetchDataForBank = async (entityClientId) => {
        if (entityClientId) {
            try {
                const queryParam = `?entity_client_id=${entityClientId}`;
                dispatch(fetchDataEntityClientBank({queryParam, webformToken:state?.webformToken})).unwrap().catch(err => {
                    toast.error(err.message || 'Error in fetching Bank')
                });
            } catch (error) {
                toast.error(error?.message || "An error occurred");
            }
        }
    };

    const fetchDataForCompany = async (entityClientId) => {
        if (entityClientId) {
            try {
                const queryParam = `?entity_client_id=${entityClientId}`;
                dispatch(fetchDataEntityClientCompany({queryParam, webformToken:state?.webformToken})).unwrap().catch(err => {
                    toast.error(err.message || 'Error in fetching Company')
                });
            } catch (error) {
                toast.error(error?.message || "An error occurred");
            }
        }
    };

    const onBankSubmit = async (data) => {
        const requestData = { ...data, countryId: state?.countryId };
        const queryParam = `?entity_client_id=${entityClientId}`;
        dispatch(saveEntityClientBank({queryParam, requestData, webformToken: state?.webformToken }))
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                fetchDataForBank(entityClientId);
                resetBank();
            })
            .catch((error) => {
                toast.error(error.message || "Request Failed Please Try Again");
            });
    };
    
    
    const onCompanySubmit = async (data) => {
        try {
            const queryParam = `?entity_client_id=${entityClientId}`;
            dispatch(saveEntityClientCompany({ queryParam, requestData: data, webformToken:state?.webformToken })).unwrap().then((res) => {
                toast.success(res?.message);
                fetchDataForCompany(entityClientId);
                resetCompany();
            }).catch((error) => {
                toast.error(error.message || "Request Failed Please Try Again ");
            });
        } catch (error) {
            toast.error(error?.message || "An error occurred while saving company data");
        }
    };

    const goBack = () => {
        let path = (state?.webformToken)?'/webform/individual_address':`/profile/individual_address`;
        navigate(path,{ state: { entityClientId: state?.entityClientId, countryId: state?.countryId, webformToken:state?.webformToken, entityId : state?.entityId, isOomero : state?.isOomero  } });
    };

    const routeChange = () => {
            let path = (state?.webformToken)?'/webform/individual_kyc_verification':`/profile/individual_kyc_verification`;
            navigate(path, { state: { entityClientId: state?.entityClientId, countryId: state?.countryId , webformToken:state?.webformToken, entityId : state?.entityId, address: state?.address, isOomero : state?.isOomero } }); 
    }
    
    useEffect(() => {
        let entityId = (decodedToken?.entityId)?decodedToken.entityId:null;
        const pathArray = window.location.href.split('/');
        const parentMenu = pathArray[3].split('?')[0];
        const childMenu = pathArray[4].split('?')[0];
        if(parentMenu === 'webform' && childMenu === 'individual_bank_details' && state?.webformToken){
            entityId = state?.entityId;          
        }
        if (entityId) {
          dispatch(fetchEntityData({queryParams:`?entityId=${entityId}`,webformToken:state?.webformToken})).unwrap().then((rep) => {
            setBankBetails(rep.data.bank_details);
          }).catch((error) => {
            toast.error(error?.message);
          });
        }
        dispatch(countryList());

        if (state?.webformToken) {
            const decryptValue = async () => {
                try {
                    const decrypted = await decryptQueryParam(state.webformToken);
                    setDecryptedValue(decrypted);
                } catch (error) {
                    console.log("Invalid Token");
                }
            };
            decryptValue();
        }
      }, []);
      const customStyles = {
        control: (provided) => ({
            ...provided,
            padding: '1px',
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
            boxShadow: 'none',
            '&:hover': {
                border: '2px solid #B3B3B3',
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#B3B3B3',
        }),
    };

    const handleAddressTypeChange = (event) => {
        setValueBank('address_type', event.target.value);
        if(event.target.value === 'home'){
            setValueBank('address', state?.address);
        }else{
            setValueBank('address', '');
        }
    };
    console.log('errors', errors)

    return (
        <>
        {decryptedValue &&
            <div className='white-background py-2 position-fixed w-100' style={{ borderRadius: 0,zIndex:1000 }}>
                <div className="d-flex justify-content-center">
                    <img src={Logo} style={{ objectFit: 'contain', width: 150 }} />
                </div>
            </div>
        }
        <div className='grey-background webform-css'>
            <div className=' mt-5 mb-3 p-3 w-100 hide-background hide-in-desktop'>
                    <p className='text-blue fw-normal'>Hi <b>{decryptedValue?.email}</b> ,<br></br> below is the webform to onboard individual client</p>
                </div>
            <div className={window.innerWidth <= 500 ? 'mt-4' : 'mt-1'}></div>
            {isLoading && <Loader />}
            <OnboardClientBar
                mainTitle={`Onboard Individual Client`}
                title1={`PERSONAL DETAILS`}
                title2={`ADDRESS`}
                title3={`BANK DETAILS`}
                title4={`KYC Verification`}
                backgroundColor1={'#037AD0'}
                backgroundColor2={'#037AD0'}
                backgroundColor3={'#037AD0'} />
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-4'>
                        <div className='d-flex align-items-center hide-in-mobile'>
                            <img src={BankDetails} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                            <h1 className='text-success fw-normal'>Bank Details</h1>
                        </div>
                        <hr className='hide-in-mobile' />
                        <div className='form '>
                            <form onSubmit={handleBankSubmit(onBankSubmit)}>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-2">
                                            <label className="form-label" htmlFor="bank_name">Bank Name</label>
                                            <input type="text" className={`form-control ${errors.bank_name ? 'is-invalid' : ''}`} placeholder="Bank name" {...bankRegister("bank_name")} />
                                            {errors?.bank_name && (
                                                <div className="invalid-feedback">{errors?.bank_name?.message}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-group mt-4">
                                            <label className="form-label" htmlFor="account_no">Account No</label>
                                            <input type="text" className={`form-control ${errors.account_no ? 'is-invalid' : ''}`} placeholder="Account No" {...bankRegister("account_no")} />
                                            {errors?.account_no && (
                                                <div className="invalid-feedback">{errors?.account_no?.message}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
    {state?.countryId== 100 ? (
        <div className="form-group mt-4">
            <label className="form-label" htmlFor="ifsc_code">IFSC Code</label>
            <input
                id="ifsc_code"
                type="text"
                className={`form-control ${errors.ifsc_code ? 'is-invalid' : ''}`}
                placeholder="IFSC Code"
                {...bankRegister("ifsc_code")}
            />
            {errors?.ifsc_code && (
                <div className="invalid-feedback">{errors.ifsc_code.message}</div>
            )}
        </div>
    ) : (
        <div className="form-group mt-4">
            <label className="form-label" htmlFor="sort_code">Sort Code</label>
            <input
                id="sort_code"
                type="text"
                className={`form-control ${errors.sort_code ? 'is-invalid' : ''}`}
                placeholder="Sort Code"
                {...bankRegister("sort_code")}
            />
            {errors?.sort_code && (
                <div className="invalid-feedback">{errors.sort_code.message}</div>
            )}
        </div>
    )}
</div>



                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-4">
                                            <label className="form-label" htmlFor="bic_code">Swift/BIC Code</label>
                                            <input type="text" className={`form-control ${errors.bic_code ? 'is-invalid' : ''}`} placeholder="Swift/BIC Code" {...bankRegister("bic_code")} />
                                            {errors?.bic_code && (
                                                <div className="invalid-feedback">{errors?.bic_code?.message}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
    <div className='col-lg-12 col-md-12'>
        {state?.countryId != 100 && (
            <div className="form-group mt-4">
                <label className="form-label" htmlFor="iban">IBAN</label>
                <Controller
                    name="iban"
                    control={bankControl}
                    defaultValue=""
                    render={({ field }) => {
                        const handleChange = (index, e) => {
                            const value = e.target.value;
                            const ibanArray = field.value.split('').concat(['', '', '', '', '', '']).slice(0, 22);
                            
                            if (index < 5) {
                                ibanArray.splice(index * 4, 4, ...value.split('').slice(0, 4));
                            } else {
                                ibanArray.splice(20, 2, ...value.split('').slice(0, 2));
                            }

                            field.onChange(ibanArray.join(''));
                            if (value.length === e.target.maxLength && index < 5) {
                                document.getElementById(`iban-segment-${index + 1}`).focus();
                            }
                            if (value.length === 0 && index > 0) {
                                document.getElementById(`iban-segment-${index - 1}`).focus();
                            }
                        };

                        return (
                            <div className="d-flex">
                                {[0, 1, 2, 3, 4].map((index) => (
                                    <input
                                        key={index}
                                        id={`iban-segment-${index}`}
                                        type="text"
                                        className={`form-control mx-1 ${errors.iban ? 'is-invalid' : ''}`}
                                        style={{ width: 65 }}
                                        maxLength="4"
                                        placeholder="1111"
                                        value={field.value.slice(index * 4, index * 4 + 4)}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                ))}
                                <input
                                    id="iban-segment-5"
                                    type="text"
                                    className={`form-control mx-1 ${errors.iban ? 'is-invalid' : ''}`}
                                    maxLength="2"
                                    placeholder="11"
                                    style={{ width: 65 }}
                                    value={field.value.slice(20, 22)}
                                    onChange={(e) => handleChange(5, e)}
                                />
                            </div>
                        );
                    }}
                />
                {errors.iban && (
                    <div className="invalid-feedback">{errors.iban.message}</div>
                )}
            </div>
        )}
    </div>
</div>

                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-4">
                                            <label className="form-label">Address Registered with Bank</label>
                                            <div className={`mt-4 ${errors?.address_type ? 'is-invalid' : ''}`}>
                                                <div className="row">
                                                    <div className='col-4'>
                                                    <div className={`${window.innerWidth<=500?"mb-4":"border-grey-form"}`}>
                                                            <div className="form-check">
                                                                <input
                                                                    className='form-check-input'
                                                                    type="radio"
                                                                    value="home"
                                                                    id="home"
                                                                    {...bankRegister("address_type")}
                                                                    onChange={handleAddressTypeChange}
                                                                />
                                                                <label className="form-check-label d-flex" htmlFor="home">Home Address</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                    <div className={`${window.innerWidth<=500?"mb-4":"border-grey-form"}`}>
                                                            <div className="form-check">
                                                                <input
                                                                    className='form-check-input'
                                                                    type="radio"
                                                                    value="office"
                                                                    id="office"
                                                                    {...bankRegister("address_type")}
                                                                    onChange={handleAddressTypeChange}
                                                                />
                                                                <label className="form-check-label d-flex" htmlFor="office">Office</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                    <div className={`${window.innerWidth<=500?"mb-4":"border-grey-form"}`}>
                                                            <div className="form-check">
                                                                <input
                                                                    className='form-check-input'
                                                                    type="radio"
                                                                    value="other"
                                                                    id="other"
                                                                    {...bankRegister("address_type")}
                                                                    onChange={handleAddressTypeChange}
                                                                />
                                                                <label className="form-check-label " htmlFor="other" >Other</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {errors?.address_type && (
                                                <div className="invalid-feedback">{errors?.address_type?.message}</div>
                                            )}
    
                                            <textarea className={`form-control mt-4 ${errors.address ? 'is-invalid' : ''}`} placeholder="Address" {...bankRegister("address")} rows="4" disabled={addressType === 'home'}></textarea>
                                            {errors?.address && (
                                                <div className="invalid-feedback">{errors?.address?.message}</div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                <div className='d-flex align-items-center justify-content-between my-4 py-1'>
                                <button className={window.innerWidth<=500?"btn-secondary fw-normal  w-100":"btn-secondary fw-normal"} style={{ marginRight: 16 }}>{window.innerWidth<=500?"Add New":"Link Another Account"} </button>
                                    <button type="submit" className={window.innerWidth<=500?"btn-primary fw-normal w-100":"btn-primary fw-normal "}> {window.innerWidth<=500?"Save Bank":"Save"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='row hide-in-desktop pe-0'>
                <div className='col-lg-6 col-md-12 mt-4 pe-0'>
                    <div className='custom-table white-background p-3'>
                        <div className='custom-table-head'>
                            <div className='row'>
                                <div className='col-2'>
                                    <h5 className='text-blue'>No</h5>
                                </div>
                                <div className='col-5'>
                                    <h5 className='text-blue'>Bank Name</h5>
                                </div>
                                <div className='col-5'>
                                    <h5 className='text-blue'>Account NO.</h5>
                                </div>
                            </div>
                        </div>
                        {entityClientBank.map((data, i) =>
                            <div className='custom-table-body' key={'entityClientBank'+i}>
                                <div className='row'>
                                    <div className='col-2'>
                                        <h6 className='text-black'>{i + 1}</h6>
                                    </div>
                                    <div className='col-5'>
                                        <h6 className='text-black'>{data.bank_name}</h6>
                                    </div>
                                    <div className='col-5'>
                                        <h6 className='text-black'>{data.account_no}</h6>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12 '>
                <div className='d-flex align-items-center hide-in-desktop mt-4'>
                            <img src={LinkCompnay} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                            <h1 className='text-ambed fw-normal'>Link Company</h1>
                        </div>
                    <div className='white-background p-3 mt-4 '>
                        <div className='d-flex align-items-center hide-in-mobile'>
                            <img src={LinkCompnay} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                            <h1 className='text-ambed fw-normal'>Link Company</h1>
                        </div>
                        <hr className='hide-in-mobile' />
                        <div className='form '>
                            <form onSubmit={handleCompanySubmit(onCompanySubmit)}>

                                <div className="form-check hide-in-mobile p-0">
                                    <label className="form-check-label" htmlFor="flexCheckDefault" style={{ fontSize: 13, fontWeight: 600 }}>
                                        <img src={BlackInfoIcon} style={{ width: 20, objectFit: 'contain', marginRight: 5 }} />
                                        Only add if you are a director / board member or a major shareholder - above 15%
                                    </label>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-3">
                                            <label className={`form-label ${errors1?.country_id ? 'is-invalid' : ' '}`} htmlFor="country_id">Company Country</label>
                                            <Controller
                                                    name="country_id"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            id="country_id"
                                                            placeholder="Select Country"
                                                            className={`${errors1.country_id ? 'is-invalid' : ''}`}
                                                            options={countryData.map(country => ({
                                                                label: country.name,
                                                                value: country.country_id
                                                            }))}
                                                            styles={customStyles}
                                                            value={field.value ? { label: countryData.find(country => country.country_id == field.value)?.name, value: field.value } : ''}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption?.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            {errors1?.country_id && (
                                                <div className="invalid-feedback">{errors1?.country_id?.message}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-4">
                                            <label className="form-label" htmlFor="company_name">Company Name</label>
                                            <input type="text" className={`form-control ${errors1?.company_name ? 'is-invalid' : ''}`} placeholder="Enter company Name" {...companyRegister("company_name")} />
                                            {errors1?.company_name &&
                                                <span className="invalid-feedback" >Company name is required</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-4">
                                            <label className="form-label" htmlFor="company_reg_no">Company Reg No.</label>
                                            <input type="text" className={`form-control ${errors1?.company_reg_no ? 'is-invalid' : ''}`} placeholder="Enter company Reg No." {...companyRegister("company_reg_no", { required: true })} />
                                            {errors1?.company_reg_no && <span className="invalid-feedback" >Company Reg No. is required</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-4">
                                            <label className="form-label" htmlFor="designation">Designation</label>
                                            <Controller
                                                name="designation"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        id="designationDropdown"
                                                        placeholder="Select Designation"
                                                        className={`${errors.designation ? 'is-invalid' : ''}`}
                                                        options={designations}
                                                        styles={customStyles}
                                                        value={field.value ? designations.find(designation => designation.value === field.value) :  ''}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption?.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors1?.designation &&
                                                <span className="invalid-feedback" >Designation is required</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="form-group mt-4">
                                            <label className="form-label" htmlFor="type_of_director">Type of Director</label>
                                            <select id="statusDropdown" className={`form-control ${errors1?.type_of_director ? 'is-invalid' : ''}`} {...companyRegister("type_of_director")}>
                                                <option value="">Select Director Type</option>
                                                <option value="Board Member">Board Member</option>
                                                <option value="non-executive director">Non-executive Director</option>
                                            </select>
                                            {errors1?.type_of_director &&
                                                <span className="invalid-feedback" >Type of Director is required</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className={` mt-4 ${errors1?.shareholder ? 'is-invalid' : ''}`}>
                                            <label>Shareholder</label>
                                            <div className='row mt-1'>
                                                <div className='col-5'>
                                                <div className={`${window.innerWidth<=500?"mb-4":"border-grey-form"}`}>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"

                                                                id="shareholderYes"

                                                                value={true} // Set value to true
                                                                {...companyRegister('shareholder')}
                                                            />
                                                            <label className="form-check-label" htmlFor="shareholderYes">Yes</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-5'>
                                                <div className={`${window.innerWidth<=500?"mb-4":"border-grey-form"}`}>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id="shareholderNo"
                                                                value={false} // Set value to false
                                                                {...companyRegister('shareholder')}
                                                            />
                                                            <label className="form-check-label" htmlFor="shareholderNo">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {errors1?.shareholder &&
                                            <span className="invalid-feedback" >Shareholder is required</span>
                                        }
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className={`mt-4  ${errors1?.ultimate_beneficial_owner ? 'is-invalid' : ''}`}>
                                            <label>Ultimate Beneficial Owner</label>
                                            <div className='row mt-1'>
                                                <div className='col-5'>
                                                    <div className={`${window.innerWidth<=500?"mb-4":"border-grey-form"}`}>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id="beneficialOwnerYes"
                                                                value={true} // Set value to true
                                                                {...companyRegister('ultimate_beneficial_owner')}
                                                            />
                                                            <label className="form-check-label" htmlFor="beneficialOwnerYes">Yes</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-5'>
                                                <div className={`${window.innerWidth<=500?"mb-4":"border-grey-form"}`}>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id="beneficialOwnerNo"
                                                                value={false} // Set value to false
                                                                {...companyRegister('ultimate_beneficial_owner')}
                                                            />
                                                            <label className="form-check-label" htmlFor="beneficialOwnerNo">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {errors1?.ultimate_beneficial_owner &&
                                            <span className="invalid-feedback" >{errors1?.ultimate_beneficial_owner?.message}</span>
                                        }
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-between my-4 py-3'>
                                    <button className={window.innerWidth<=500?"btn-secondary fw-normal  w-100":"btn-secondary fw-normal"} style={{ marginRight: 16 }}>{window.innerWidth<=500?"Add New":"Link Another Account"} </button>
                                    <button type="submit" className={window.innerWidth<=500?"btn-primary fw-normal w-100":"btn-primary fw-normal "}> {window.innerWidth<=500?"Save Company":"Save"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='row hide-in-desktop pe-0'>
                <div className='col-lg-6 col-md-12 mt-4 pe-0'>
                    <div className='custom-table white-background p-3'>
                        <div className='custom-table-head'>
                            <div className='row'>
                                <div className='col-2'>
                                    <h5 className='text-blue'>No</h5>
                                </div>
                                <div className='col-5'>
                                    <h5 className='text-blue'>Company Name</h5>
                                </div>
                                <div className='col-5'>
                                    <h5 className='text-blue'>Company Reg No</h5>
                                </div>
                            </div>
                        </div>
                        {entityClientCompany.map((data,i) =>
                            <div className='custom-table-body' key={'company_'+i}>
                                <div className='row'>
                                    <div className='col-2'>
                                        <h5 className='text-black'>{i + 1}</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-black'>{data.company_name}</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-black'>{data.company_reg_no
                                        }</h5>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>  
                </div>
                </div>

            </div>
            <div className='row '>
                <div className='col-lg-6 col-md-12 mt-4 hide-in-mobile'>
                    <div className='custom-table'>
                        <div className='custom-table-head'>
                            <div className='row'>
                                <div className='col-2'>
                                    <h5 className='text-blue'>No</h5>
                                </div>
                                <div className='col-5'>
                                    <h5 className='text-blue'>Bank Name</h5>
                                </div>
                                <div className='col-5'>
                                    <h5 className='text-blue'>Account NO.</h5>
                                </div>
                            </div>
                        </div>
                        {entityClientBank.map((data, i) =>
                            <div className='custom-table-body' key={'entityClientBank'+i}>
                                <div className='row'>
                                    <div className='col-2'>
                                        <h6 className='text-black'>{i + 1}</h6>
                                    </div>
                                    <div className='col-5'>
                                        <h6 className='text-black'>{data.bank_name}</h6>
                                    </div>
                                    <div className='col-5'>
                                        <h6 className='text-black'>{data.account_no}</h6>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='col-lg-6 col-md-12 mt-4 hide-in-mobile'>
                    <div className='custom-table'>
                        <div className='custom-table-head'>
                            <div className='row'>
                                <div className='col-2'>
                                    <h5 className='text-blue'>No</h5>
                                </div>
                                <div className='col-5'>
                                    <h5 className='text-blue'>Company Name</h5>
                                </div>
                                <div className='col-5'>
                                    <h5 className='text-blue'>Company Reg No</h5>
                                </div>
                            </div>
                        </div>
                        {entityClientCompany.map((data,i) =>
                            <div className='custom-table-body' key={'company_'+i}>
                                <div className='row'>
                                    <div className='col-2'>
                                        <h5 className='text-black'>{i + 1}</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-black'>{data.company_name}</h5>
                                    </div>
                                    <div className='col-5'>
                                        <h5 className='text-black'>{data.company_reg_no
                                        }</h5>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>  
                </div>
            </div>
            <div className='bottom-button'>
                    <button onClick={goBack} className={window.innerWidth<=500?'btn-secondary w-100':'btn-secondary  ' } style={{ marginRight: 16 }}>Previous</button>
                    <button onClick={routeChange} className={window.innerWidth<=500?' btn-primary w-100':'btn-primary ' }>Save & Next</button>
                </div>
        </div>
        </>
    )
}

export default IndividualBankDetails