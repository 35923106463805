import { createAsyncThunk,createSlice,isAnyOf } from "@reduxjs/toolkit";
import { METHOD_TYPE,API_ENDPOINTS } from "../../utils/apiUrls";
import api from "../../utils/api";
const initialState={
    isLoading:false,
    error:null,
    invoice:[]

}


export const GetInvoiceData=createAsyncThunk("invoice/getMyInvoices",async (requestData)=>{
    try {
        let data={
            method:METHOD_TYPE.get,
        url:`${API_ENDPOINTS.getInvoiceData}?limit=10&page=0&year=2024&search=100`,
        };
        const respone=await api(data);
        return respone.data

    } catch (error) {
        throw error ?.respone?.data;
    }
})

const MyInvoicesSlice=createSlice({
    name:"invoice",
    initialState,

    reducers:{

    },
    extraReducers:(builder)=>{
        builder
        .addCase(GetInvoiceData.fulfilled,(state,{payload})=>{
            state.isLoading=false;
            state.error=null;
            state.invoice=payload.data
        }).addMatcher(isAnyOf(GetInvoiceData.pending,(state)=>{
            state.isLoading=true;
        }))
        .addMatcher(isAnyOf(GetInvoiceData.rejected),(state,{error})=>{
            state.isLoading=false;
state.error=(error)?error.message:"Rquest Failed"
        })
    },
})
const MyInvoicesSliceReducer=MyInvoicesSlice.reducer;
export default MyInvoicesSliceReducer;