import React, { useState } from 'react'
import '../../Profile/Profile.scss';
import OnboardClientBar from '../../../components/OnboardClientBar/OnboardClientBar';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../assets/images/logo.png'

function CorporateWebformShareholderList() {
    const navigate = useNavigate();
    const routeIndividualShareHolder = () => {
        let path = `/corporate_add_shareholde_personal_details`;
        navigate(path);
    }
    const routeCompanyShareHolder = () => {
        let path = `/corporate_add_company_shareholder`;
        navigate(path);
    }
    return (
        <div>
            <div className='white-background py-2 position-fixed w-100' style={{ borderRadius: 0 }}>
                <div className="d-flex justify-content-center">
                    <img src={Logo} style={{ objectFit: 'contain', width: 150 }} />
                </div>
            </div>
            <div style={{ padding: 20 }}>
                <div className='mt-5'>

                </div>
                <OnboardClientBar
                    mainTitle={`Onboard Business Client `}
                    title1={`COMPANY DETAILS`}
                    title2={`COMPANY INFORMATION`}
                    title3={`DIRECTOR LIST`}
                    title4={`SHAREHOLDER LIST`}
                    backgroundColor1={'#037AD0'}
                    backgroundColor2={'#037AD0'}
                    backgroundColor3={'#037AD0'}
                    backgroundColor4={'#037AD0'} />

                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='white-background p-2 px-4 mt-4' style={{ boxShadow: "none" }}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <h1 className='text-blue fw-normal'>Individual Shareholder List</h1>
                                <button onClick={routeIndividualShareHolder} className='btn-primary'>Add Individual Shareholder</button>
                            </div>
                        </div>
                        <div className='custom-table'>
                            <div className='custom-table-head mt-3'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <h6 className='text-blue'>Sr.</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-blue'>First Name</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-blue'>Last Name</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row align-items-center'>
                                    <div className='col-3'>
                                        <h6 className='text-black'>1</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Corem</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Country</h6>
                                    </div>
                                    <div className='col-3'>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            <h6 className='text-black green-small-bg'>View Report</h6>
                                            <h6 className='text-blue dark-blue-small-bg'>Fill Details</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row align-items-center'>
                                    <div className='col-3'>
                                        <h6 className='text-black'>1</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Corem</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Country</h6>
                                    </div>
                                    <div className='col-3'>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            <h6 className='text-black green-small-bg'>View Report</h6>
                                            <h6 className='text-blue dark-blue-small-bg'>Fill Details</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row align-items-center'>
                                    <div className='col-3'>
                                        <h6 className='text-black'>1</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Corem</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Country</h6>
                                    </div>
                                    <div className='col-3'>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            <h6 className='text-black green-small-bg'>View Report</h6>
                                            <h6 className='text-blue dark-blue-small-bg'>Fill Details</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row align-items-center'>
                                    <div className='col-3'>
                                        <h6 className='text-black'>1</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Corem</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Country</h6>
                                    </div>
                                    <div className='col-3'>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            <h6 className='text-black green-small-bg'>View Report</h6>
                                            <h6 className='text-blue dark-blue-small-bg'>Fill Details</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-end'>
                            <span class="badge bg-primary-badge">1</span>
                            <span class="badge">2</span>
                            <span class="badge">3</span>
                            <span class="badge">4</span>
                            <span class="badge"><i class="fa fa-chevron-right"></i></span>
                        </div>
                    </div>
                    <div className='col-lg-12  mt-4'>
                        <div className='white-background p-2 px-4 mt-4' style={{ boxShadow: "none" }}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <h1 className='text-blue fw-normal'>Company Shareholder List</h1>
                                <button onClick={routeCompanyShareHolder} className='btn-primary'>Add Company Shareholder</button>
                            </div>
                        </div>
                        <div className='custom-table'>
                            <div className='custom-table-head mt-3'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <h6 className='text-blue'>Sr.</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-blue'>First Name</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-blue'>Last Name</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row align-items-center'>
                                    <div className='col-3'>
                                        <h6 className='text-black'>1</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Corem</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Country</h6>
                                    </div>
                                    <div className='col-3'>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            <h6 className='text-black green-small-bg'>View Report</h6>
                                            <h6 className='text-blue dark-blue-small-bg'>Fill Details</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row align-items-center'>
                                    <div className='col-3'>
                                        <h6 className='text-black'>1</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Corem</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Country</h6>
                                    </div>
                                    <div className='col-3'>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            <h6 className='text-black green-small-bg'>View Report</h6>
                                            <h6 className='text-blue dark-blue-small-bg'>Fill Details</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row align-items-center'>
                                    <div className='col-3'>
                                        <h6 className='text-black'>1</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Corem</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Country</h6>
                                    </div>
                                    <div className='col-3'>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            <h6 className='text-black green-small-bg'>View Report</h6>
                                            <h6 className='text-blue dark-blue-small-bg'>Fill Details</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='custom-table-body'>
                                <div className='row align-items-center'>
                                    <div className='col-3'>
                                        <h6 className='text-black'>1</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Corem</h6>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='text-black'>Country</h6>
                                    </div>
                                    <div className='col-3'>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            <h6 className='text-black green-small-bg'>View Report</h6>
                                            <h6 className='text-blue dark-blue-small-bg'>Fill Details</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-end'>
                            <span class="badge bg-primary-badge">1</span>
                            <span class="badge">2</span>
                            <span class="badge">3</span>
                            <span class="badge">4</span>
                            <span class="badge"><i class="fa fa-chevron-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CorporateWebformShareholderList
