import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

function CorporateDataTable(props) {
    const { ViewIcon, PrintIcon } = props;
    const location = useLocation()
    const navigate = useNavigate();
    const [locationData, setLocationData] = useState('');

    useEffect(() => {
        setLocationData(location.pathname === 'view_screening/corporate_list' );
    }, [location.pathname]);

    const viewAll = () => {
        let path = `/view_screening/corporate_list`;
        navigate(path);
    }

    const viewIconClick = () => {
        let path = `/corporate_screening/corporate_screening_details`;
        navigate(path);
    }

    return (
        <div className='custom-table'>
            {!locationData &&
                <div className='d-flex mt-5 mb-3 align-items-center justify-content-between'>
                    <h1 className='text-blue px-2 fw-normal'>List of Screening Corporate</h1>
                    <h6 onClick={viewAll} className='dark-blue-small-bg text-blue px-4' >View All</h6>
                </div>
            }
            <div className='custom-table-head'>
                <div className='row'>
                    <div className='col-md-2'>
                        <p className='text-blue'>Company Name</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Oomero ID</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Company Type</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Directors</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Share - holders</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Status</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Date Time</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>KYC Status</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Screening Steps</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue'>Continuous Monitoring</p>
                    </div>
                    <div className='col-md-1'>
                        <p className='text-blue text-center'>Action</p>
                    </div>
                </div>
            </div>
            {
                [
                    { status: 'Green', statusColor: '#037924', statusBg: '#A9FFC1' },
                    { status: 'Grey', statusColor: '#949595', statusBg: '#DDDDDD' },
                    { status: 'Green', statusColor: '#037924', statusBg: '#A9FFC1' },
                    { status: 'Red', statusColor: '#ED4337', statusBg: '#FFD0CD' },
                    { status: 'Amber', statusColor: '#9C6802', statusBg: '#FFED8F' },
                ].map((item, i) => {
                    return (
                        <div className='custom-table-body' key={'tabsdata' + i}>
                            <div className='row align-items-center'>
                                <div className='col-md-2'>
                                    <p>Some Company</p>
                                </div>
                                <div className='col-md-1'>
                                    <p>OLP123456111111</p>
                                </div>
                                <div className='col-md-1'>
                                    <p>Ipsum</p>
                                </div>
                                <div className='col-md-1'>
                                    <p>3</p>
                                </div>
                                <div className='col-md-1'>
                                    <p>10</p>
                                </div>
                                <div className='col-md-1'>
                                    <p className='statusBtn' style={{ backgroundColor: item.statusBg, color: item.statusColor }}>{item.status}</p>
                                </div>
                                <div className='col-md-1'>
                                    <p>22/02/2022 16:34:00</p>
                                </div>
                                <div className='col-md-1'>
                                    <p>KYC Completed</p>
                                </div>
                                <div className='col-md-1'>
                                    <p>KYC Completed</p>
                                </div>
                                <div className='col-md-1'>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                    </div>
                                </div>
                                <div className='col-md-1'>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <img onClick={viewIconClick} src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                        <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default CorporateDataTable