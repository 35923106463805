import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

import Loader from '../../components/Loader/Loader';
import { fetchOomeroUserList, fetchClientUserList, deactivateUser, reactivateUser, deleteUser } from './manageUserSlice';

import EditIcon from '../../assets/images/edit_icon.png'
import PrintIcon from '../../assets/images/Print_Icon.png'
import ViewIcon from '../../assets/images/view_icon.png'
import DeleteIcon from '../../assets/images/delete_icon.png'
import TablePagination from '../../components/Pagination'
import CancelIcon from '../../assets/images/Cancel_icon.png'
import BackIcon from '../../assets/images/back-btn-arrow.png';
import { decodeToken, useJwt } from "react-jwt";
function ManageUserList() {
    const pathArray = window.location.href.split('/');
    const parentMenu = pathArray[4].split('?')[0];
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, oomeroUserList, clientUserList } = useSelector(state => state.manageUserReducer);
    const [showDelete, setShowDelete] = useState(false);
    const [userType, setUserType] = useState(null); //oomero or client
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [comments, setComments] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPge, setPostsPerPage] = useState(10);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const userToken  = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);
    const [showClientChangeStatus, setShowClientChangeStatus] = useState(false);
    const [showEditClientButton, setShowEditClientButton] = useState(false);
    const [showDeleteClentButton, setShowDeleteClentButton] = useState(false);
    const [showPrintClentButton, setShowPrintClentButton] = useState(false);
    const [viewClientDetailButton, setViewClientDetailButton] = useState(false);
    const [viewClientList, setViewClientList] = useState(false);
    const [showOomeroChangeStatus, setShowOomeroChangeStatus] = useState(false);
    const [showEditOomerotButton, setShowEditOomerotButton] = useState(false);
    const [showDeleteOomeroButton, setShowDeleteOomeroButton] = useState(false);
    const [showPrintOomeroButton, setShowPrintOomeroButton] = useState(false);
    const [viewOomeroDetailButton, setViewOomeroDetailButton] = useState(false);
    const [viewOomeroList, setViewOomeroList] = useState(false);
    const handleClose = () => {
        setSelectedUserId(null);
        setComments(null);
        setShow(false);
    }
    const handleShow = () => setShow(true);
    const handleCloseReactive = () => {
        setShow2(false); setSelectedUserId(null);
        setComments(null);
    };
    const handleShowReactive = () => setShow2(true);
    const handleDeleteClose = () => {
        setShowDelete(false);
        setSelectedUserId(null);
        setComments(null);
    }
    const handleDeleteShow = () => setShowDelete(true);

    const params = new URLSearchParams(location.search);

    useEffect(() => {
        const search = params.get('search');
        let queryParams = `page=${currentPage}&limit=${postsPerPge}`;
        if (search) queryParams += `&search=${search}`
        if (parentMenu == 'list_of_client_users') {
            setUserType('client');
            dispatch(fetchClientUserList(queryParams)).unwrap().catch(error => {
                toast.error(error?.message);
            });
        }
        if (parentMenu == 'list_of_oomero_users') {
            setUserType('oomero');
            dispatch(fetchOomeroUserList(queryParams)).unwrap().catch(error => {
                toast.error(error?.message);
            });
        }

    }, [])

    const handlePagination = (event, value) => {
        setCurrentPage(value);
    }

    useEffect(() => {
        const search = params.get('search');
        let queryParams = `page=${currentPage}&limit=${postsPerPge}`
        if (search) queryParams += `&search=${search}`
        if (userType == 'client') {
            dispatch(fetchClientUserList(queryParams)).unwrap().catch(error => {
                toast.error(error?.message);
            });
        }
        if (userType == 'oomero') {
            dispatch(fetchOomeroUserList(queryParams)).unwrap().catch(error => {
                toast.error(error?.message);
            });
        }
    }, [currentPage]);

    const handleModalOpen = (type, userId, usertype) => {
        if (type && userId && usertype) {
            setSelectedUserId(userId);
            if (type == 'deactivate') {
                handleShow();
            }
            if (type == 'activate') {
                handleShowReactive();
            }
        }
    }

    const handleOnDeactivateConfirmation = () => {
        if (selectedUserId) {
            let requestBody = { userId: selectedUserId }
            if (comments) requestBody.comments = comments;
            dispatch(deactivateUser(requestBody)).unwrap()
                .then((res) => {
                    toast.success(res?.message);
                    let queryParams = `page=${currentPage}&limit=${postsPerPge}`
                    if (userType == 'oomero') {
                        dispatch(fetchOomeroUserList(queryParams)).unwrap().catch(error => {
                            toast.error(error?.message);
                        });
                    }

                    if (userType == 'client') {
                        dispatch(fetchClientUserList(queryParams)).unwrap().catch(error => {
                            toast.error(error?.message);
                        });
                    }

                    handleClose();
                }).catch((error) => {
                    toast.error(error?.message);
                })
        }
    }

    const handleOnReactivateConfirmation = () => {
        if (selectedUserId) {
            let requestBody = { userId: selectedUserId }
            if (comments) requestBody.comments = comments;
            dispatch(reactivateUser(requestBody)).unwrap()
                .then((res) => {
                    toast.success(res?.message);
                    let queryParams = `page=${currentPage}&limit=${postsPerPge}`
                    if(userType == 'oomero') dispatch(fetchOomeroUserList(queryParams));
                    if(userType == 'client') dispatch(fetchClientUserList(queryParams));
                    handleCloseReactive();
                }).catch((error) => {
                    toast.error(error?.message);
                })
        }
    }

    const handleOnConfirmDeleteUser = (userId) => {
        if (userId) {
            let requestBody = { userId, comments }
            dispatch(deleteUser(requestBody)).unwrap()
                .then((res) => {
                    toast.success(res?.message);
                    let queryParams = `page=${currentPage}&limit=${postsPerPge}`
                    if(userType == 'oomero') dispatch(fetchOomeroUserList(queryParams));
                    if(userType == 'client') dispatch(fetchClientUserList(queryParams));
                    handleDeleteClose();
                }).catch((error) => {
                    toast.error(error?.message);
                })
        }
    }

    useEffect(()=>{
        if(decodedToken){
            if(decodedToken.isSuperAdmin){
                setShowClientChangeStatus(true);
                setShowEditClientButton(true);
                setShowDeleteClentButton(true);
                setShowPrintClentButton(true);
                setViewClientDetailButton(true);
                setViewClientList(true);

                setShowOomeroChangeStatus(true);
                setShowEditOomerotButton(true);
                setShowDeleteOomeroButton(true);
                setShowPrintOomeroButton(true);
                setViewOomeroDetailButton(true);
                setViewOomeroList(true);

            } else {
                if (decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.deactivate_client_user)) {
                    setShowClientChangeStatus(true);
                }
                
                if (decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.reactivate_client_user)) {
                    setShowClientChangeStatus(true);
                }
                
                if (decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.edit_client_user)) {
                    setShowEditClientButton(true);
                }
                
                if (decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.delete_client_user)) {
                    setShowDeleteClentButton(true);
                }
                
                if (decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.print_client_user)) {
                    setShowPrintClentButton(true);
                }
                
                if (decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.view_client_user_details)) {
                    setViewClientDetailButton(true);
                }

                if (decodedToken?.isEnterpriseAdmin || (decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.view_client_user)) {
                    setViewClientList(true);
                }
                
                if ((decodedToken.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.deactivate_oomero_user)) {
                    setShowOomeroChangeStatus(true);
                }
                
                if ((decodedToken.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.reactivate_oomero_user)) {
                    setShowOomeroChangeStatus(true);
                }
                
                if ((decodedToken.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.edit_oomero_user)) {
                    setShowEditOomerotButton(true);
                }
                
                if ((decodedToken.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.delete_oomero_user)) {
                    setShowDeleteOomeroButton(true);
                }
                
                if ((decodedToken.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.print_oomero_user)) {
                    setShowPrintOomeroButton(true);
                }
                
                if ((decodedToken.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.view_oomero_user_details)) {
                    setViewOomeroDetailButton(true);
                }

                if ((decodedToken.isOomero && decodedToken?.roles?.permissions && decodedToken?.roles?.permissions?.view_oomero_user)) {
                    setViewOomeroList(true);
                }
            }
        }
    },[decodedToken])

    const renderBodyData = (data) => {

        return (
            <>
                {
                    data && data?.rows?.length > 0 && (viewClientList || viewOomeroList) && 
                    data?.rows?.map((element, index) => (
                        <div className='custom-table-body' key={'oomero' + index}>
                            <div className='row align-items-center'>
                                {userType == 'client' &&
                                    <div className='col'>
                                        <h6 className='text-black'>{element?.company}</h6>
                                    </div>
                                }
                                <div className='col'>
                                    <h6 className='text-black'>{element?.firstName}</h6>
                                </div>
                                <div className='col p-0'>
                                    <h6 className='text-black'>{element?.lastName}</h6>
                                </div>
                                <div className='col-3'>
                                    <h6 className='text-black '>{element?.email}</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-black'>{(element?.role) ? element?.role : ((element?.superAdmin && 'Super Admin') || (element?.enterpriseAdmin && 'Enterprise Admin'))}</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-black'>{element?.mobileCode + ' ' + element?.mobile}</h6>
                                </div>
                                <div className='col-1'>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" role="switch" checked={element?.isActive} onChange={() => handleModalOpen((element?.isActive) ? 'deactivate' : 'activate', element?.userId, userType)
                                             }/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        {(viewClientDetailButton || viewOomeroDetailButton) &&
                                        <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} onClick={()=>
                                            navigate(`/user/${userType == 'client'?'edit_client_user':'edit_oomero_user'}?userId=${element?.userId}&view=true`)
                                        }/>
                                        }

                                        {(showEditClientButton || showEditOomerotButton) &&
                                        <img src={EditIcon} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} onClick={()=>navigate(`/user/${userType == 'client'?'edit_client_user':'edit_oomero_user'}?userId=${element?.userId}`)}/>
                                        }
                                        {(showPrintClentButton || showPrintOomeroButton) &&
                                        <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                        }
                                        {(showDeleteClentButton || showDeleteOomeroButton) &&
                                        <img src={DeleteIcon} style={{ width: 20, height: 15, objectFit: 'contain', cursor: 'pointer' }} onClick={()=>{ setSelectedUserId(element?.userId); handleDeleteShow()}}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                {
                    data && data?.rows?.length < 1 &&
                    <div className='text-center'>No Data </div>
                }
            </>)
    }

    const navigateToPrevious = () => {
        navigate(-1);
    }

    return (
        <div className='grey-background vh-150'>
            <button onClick={navigateToPrevious} className='back-button text-blue mb-3'><img src={BackIcon} style={{width:8,marginBottom:2,marginRight:4,objectFit:'contain'}}/> Back</button>
            {isLoading && <Loader />}
            <div className='white-background p-3'>
                <h1 className='text-blue px-2 fw-normal'>List of {parentMenu == 'list_of_oomero_users' && 'Oomero'} {parentMenu == 'list_of_client_users' && 'Client'} Users</h1>
            </div>
            <div className='col-lg-12 col-md-12 mt-3'>
                <div className='custom-table'>
                    <div className='custom-table-head'>
                        <div className='row'>
                            {parentMenu == 'list_of_client_users' &&
                                <div className='col'>
                                    <h6 className='text-blue'>Company</h6>
                                </div>
                            }
                            <div className='col'>
                                <h6 className='text-blue'>Firstname</h6>
                            </div>
                            <div className='col p-0'>
                                <h6 className='text-blue'>Lastname</h6>
                            </div>
                            <div className='col-3'>
                                <h6 className='text-blue'>Email</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>{userType == 'client' ? 'Type' : 'Role'}</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Mobile</h6>
                            </div>
                            <div className='col-1'>
                                <h6 className='text-blue text-center'>Status</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue text-center' style={{ paddingLeft: 25 }}>Action</h6>
                            </div>
                        </div>
                    </div>
                    {
                        parentMenu == 'list_of_client_users' && clientUserList && clientUserList?.rows?.length > 0 && renderBodyData(clientUserList)
                    }
                    {
                        parentMenu == 'list_of_oomero_users' && oomeroUserList && oomeroUserList?.rows?.length > 0 && renderBodyData(oomeroUserList)
                    }
                </div>
            </div>
            {parentMenu == 'list_of_oomero_users' && oomeroUserList && oomeroUserList?.rows?.length > 0 &&
                <TablePagination totalPages={parseInt(oomeroUserList?.totalPages)} currentPage={currentPage} color="primary" handlePagination={handlePagination} />
            }
            {parentMenu == 'list_of_client_users' && clientUserList && clientUserList?.rows?.length > 0 &&
                <TablePagination totalPages={parseInt(clientUserList?.totalPages)} currentPage={currentPage} color="primary" handlePagination={handlePagination} />
            }

            <Modal className='modal-lg' show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <h5 className="modal-title text-center text-blue mt-3"><b>Confirm Deactivation</b></h5>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <hr />
                <Modal.Body style={{ paddingLeft: 100, paddingRight: 100 }}>
                    <h6 className='text-blue text-center'>Are you sure, you want to deactivate the User? Once, deactivated the client will not be able to use Oomero services</h6>
                    <form>
                        <div className="form-group mt-3 p-2">
                            <div className='manage-user-textarea'>
                                <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Comments" rows="3" onKeyUp={(event) => setComments(event.target.value)} />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer >
                    <div className='d-flex justify-content-between'>
                        <button onClick={handleClose} type="button" className="btn-secondary mb-3" style={{ marginRight: 20, width: 100 }}>No</button>
                        <button onClick={handleOnDeactivateConfirmation} type="button" className="btn-primary mb-3" style={{ width: 100 }}>Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal className='modal-lg' show={showDelete} onHide={handleDeleteClose} centered>
                <Modal.Header>
                    <h5 className="modal-title text-center text-blue mt-3"><b>Confirm Delete</b></h5>
                    <div onClick={handleDeleteClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <hr />
                <Modal.Body style={{ paddingLeft: 100, paddingRight: 100 }}>
                    <h6 className='text-blue text-center'>Are you sure, you want to delete the user. Once, deleted the user will not be able to use Oomero services</h6>
                    <form>
                        <div className="form-group mt-3 p-2">
                            <div className='manage-user-textarea'>
                                <textarea type="text" className="form-control" placeholder="Comments" rows="3" onKeyUp={(event) => setComments(event.target.value)} />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer >
                    <div className='d-flex justify-content-between'>
                        <button onClick={handleDeleteClose} type="button" className="btn-secondary mb-3" style={{ marginRight: 20, width: 100 }}>No</button>
                        <button onClick={() => handleOnConfirmDeleteUser(selectedUserId)} type="button" className="btn btn-primary mb-3" style={{ width: 100 }}>Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal className='modal-lg' show={show2} onHide={handleCloseReactive} centered>
                <Modal.Header>
                    <h5 className="modal-title text-center text-blue mt-3"><b>Confirm Reactivation</b></h5>
                    <div onClick={handleCloseReactive} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <hr />
                <Modal.Body style={{ paddingLeft: 100, paddingRight: 100 }}>
                    <h6 className='text-blue text-center'>Are you sure, you want to Reactivate the User</h6>
                    <form>
                        <div className="form-group mt-3 p-2">
                            <div className='manage-user-textarea'>
                                <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Comments" rows="3" onKeyUp={(event) => setComments(event.target.value)} />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer >
                    <div className='d-flex justify-content-between'>
                        <button onClick={handleCloseReactive} type="button" className="btn-secondary mb-3" style={{ marginRight: 20, width: 100 }}>No</button>
                        <button onClick={handleOnReactivateConfirmation} type="button" className="btn-primary mb-3" style={{ width: 100 }}>Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default ManageUserList;
