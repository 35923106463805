import React, { useEffect, useState } from 'react'
import EditIcon from '../../assets/images/edit_icon.png'
import SearchIcon from '../../assets/images/search_icon.png'
import PrintIcon from '../../assets/images/Print_Icon.png'
import ViewIcon from '../../assets/images/view_icon.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import { fetchClientList, changeEntityStatus } from './manageClientSlice';
import Loader from '../../components/Loader/Loader'
import TablePagination from '../../components/Pagination'
import { toast } from 'react-toastify'

function ManageClients() {
    const location = useLocation();
    const dispatch = useDispatch();
    const { isLoading, clientList } = useSelector(state => state.manageClientReducer);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPge, setPostsPerPage] = useState(10);
    const params = new URLSearchParams(location.search);
    const [search, setSearch] = useState('');

    useEffect(() => {
        let queryParams = `?page=${currentPage}&limit=${postsPerPge}`;
        if(search) queryParams+=`&search=${search}`
        dispatch(fetchClientList(queryParams));
    }, [currentPage, search])

    const routeChange = () => {
        // let path = `/clients/manage_New_client`;
        let path = `/clients/manage_admin`
        navigate(path);
    }

    const camelCase = (str)=>{
        return str.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
    }

    const handlePagination = (event, value) => {
        setCurrentPage(value);
    }

    const handleOnChangeEntityStatus = (entityId, status) =>{
        if(entityId && status !==''){
            let requestData = { userEntityId: entityId, status}
            dispatch(changeEntityStatus(requestData)).unwrap().then((res) => {
                let queryParams = `?page=${currentPage}&limit=${postsPerPge}`;
                if(search) queryParams+=`&search=${search}`
                dispatch(fetchClientList(queryParams));
                toast.success(res?.message);
            }).catch((error) => {
                toast.error(error?.message);
            })
        }
    }

    return (
        <div className='grey-background'>
            { isLoading && !search && <Loader />}
            <div className='white-background p-3 mb-3'>
                <h1 className='text-blue px-2 fw-normal'>Manage Clients</h1>
                <hr />
                <div className='row'>
                    <div className='col-lg-10 col-md-12'>
                        <div className="form-group position-relative">
                            <input type="text" className="form-control" placeholder='Search'  onKeyUp={($event)=>setSearch($event.target.value)}></input>
                            <div className="position-absolute" style={{ right: 20, top: '20%' }}>
                                <img src={SearchIcon} style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-12'>
                        <button onClick={routeChange} className="btn btn-primary mb-1 pl-4 pr-4" style={{ fontSize: 14, paddingLeft: 15, paddingRight: 15 }}>Setup New Client</button>
                    </div>
                </div>
            </div>
            <div className='col-lg-12 col-md-12 mt-3'>
                <div className='custom-table'>
                    <div className='custom-table-head'>
                        <div className='row'>
                            <div className='col'>
                                <h6 className='text-blue'>Company Name</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Company NO</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Services</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Status</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>A/C Type</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Package</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Payment Cycle</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue text-center'>Action</h6>
                            </div>
                        </div>
                    </div>
                    {clientList && clientList.rows && clientList.rows.length > 0 &&
                        clientList.rows.map((client, index) => (
                            <div className='custom-table-body' key={index}>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h6 className='text-blue'>{client.entity_name}</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>{client.entity_registration_no}</h6>
                                    </div>
                                    <div className='col text-center'>
                                           {Object.keys(client.package_service).map((key, i) =>
                                        (
                                            <h6 className={` ${i === 0 ? 'text-success' : i === 1 ? 'text-ambed' : 'text-blue'}`} key={i}>
                                                {camelCase(key) }  {i < Object.keys(client.package_service).length - 1 && ','}
                                            </h6>
                                        )
                                        )}
                                    </div>
                                    <div className='col'>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" checked={client.is_active} onChange={()=>handleOnChangeEntityStatus(client?.entity_id, !client.is_active)}/>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>{client.account_type}</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>{client.package_name}</h6>
                                    </div>
                                    <div className='col'>
                                        <h6 className='text-black'>{client.package_type}</h6>
                                    </div>
                                    <div className='col'>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                            <img src={EditIcon} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                            <img src={PrintIcon} style={{ width: 20, objectFit: 'contain', cursor: 'pointer' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                {clientList && clientList?.rows?.length > 0 &&
                    <TablePagination totalPages={parseInt(clientList?.totalPages)} currentPage={currentPage} color="primary" handlePagination={handlePagination} />
                }
            </div>
        </div>
    )
}

export default ManageClients
