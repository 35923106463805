import { useNavigate } from "react-router-dom";
import "./startScreeningPage.scss";

const StartScreening = (params) => {
    const navigate = useNavigate();

    const CorporateScreeingDetails = () => {
      let path = `/corporate_screening/corporate_screening_details`;
      navigate(path);
    }
    const individualScreeningDetails = () => {
        // let path = `/individual_screening/individual_screening_details`;
        // navigate(path);
    }
    return (
        <div className='start-screening-page'>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <hr style={{ width: '80%' }} />
                <p className="breadCrumbTitle">7 Screening Needs to Resolve</p>
            </div>
            <div className='white-background p-3 mb-3'>
                <div className='cardTitle px-2 fw-normal'>Screening  Corporate</div>
            </div>
            <div className='custom-table'>
                <div className='custom-table-head'>
                    <div className='row'>
                        <div className='col'>
                            <div className='text-blue'>Company Name</div>
                        </div>
                        <div className='col'>
                            <div className='text-blue'>Oomero ID</div>
                        </div>
                        <div className='col'>
                            <div className='text-blue'>Email</div>
                        </div>
                        <div className='col'>
                            <div className='text-blue'>Status</div>
                        </div>
                        <div className='col'>
                            <div className='text-blue'>Action</div>
                        </div>
                    </div>
                </div>
                <div className='custom-table-body'>
                    <div className='row align-items-center'>
                        <div className='col'>
                            Some Company
                        </div>
                        <div className='col'>
                            OLP123456111111
                        </div>
                        <div className='col'>
                            email@email.com
                        </div>
                        <div className='col'>
                            <div className='statusBtn' style={{ backgroundColor: '#A9FFC1', color: '#037924' }}>Green</div>
                        </div>
                        <div className='col'>
                            <div onClick={CorporateScreeingDetails} style={{ backgroundColor: '#037AD01A', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'max-content', fontSize: 12, cursor: 'pointer' }} >Start Screening</div>
                        </div>
                    </div>
                </div>
            </div>
            <section style={{ marginTop: 50 }}>
                <div className='white-background p-3 mb-3'>
                    <div className='cardTitle px-2 fw-normal'>List of Directors</div>
                </div>
                <div className='custom-table'>
                    <div className='custom-table-head'>
                        <div className='row'>
                            <div className='col'>
                                <div className='text-blue'>Company Name</div>
                            </div>
                            <div className='col'>
                                <div className='text-blue'>Oomero ID</div>
                            </div>
                            <div className='col'>
                                <div className='text-blue'>Email</div>
                            </div>
                            <div className='col'>
                                <div className='text-blue'>Status</div>
                            </div>
                            <div className='col'>
                                <div className='text-blue'>Action</div>
                            </div>
                        </div>
                    </div>
                    {
                        [1, 2, 3, 4].map((item, i) => {
                            return (
                                <div className='custom-table-body' key={'ListofDirectors' + i}>
                                    <div className='row align-items-center' >
                                        <div className='col'>
                                            Some Company
                                        </div>
                                        <div className='col'>
                                            OLP123456111111
                                        </div>
                                        <div className='col'>
                                            email@email.com
                                        </div>
                                        <div className='col'>
                                            <div className='statusBtn' style={{ backgroundColor: '#A9FFC1', color: '#037924' }}>Green</div>
                                        </div>
                                        <div className='col'>
                                            <div onClick={individualScreeningDetails} style={{ backgroundColor: '#037AD01A', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'max-content', fontSize: 12, cursor: 'pointer' }} >Start Screening</div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </section>
            <section style={{ marginTop: 50 }}>
                <div className='white-background p-3 mb-3'>
                    <div className='cardTitle px-2 fw-normal'>List of Shareholders</div>
                </div>
                <div className='custom-table'>
                    <div className='custom-table-head'>
                        <div className='row'>
                            <div className='col'>
                                <div className='text-blue'>Company Name</div>
                            </div>
                            <div className='col'>
                                <div className='text-blue'>Oomero ID</div>
                            </div>
                            <div className='col'>
                                <div className='text-blue'>Email</div>
                            </div>
                            <div className='col'>
                                <div className='text-blue'>Status</div>
                            </div>
                            <div className='col'>
                                <div className='text-blue'>Action</div>
                            </div>
                        </div>
                    </div>
                    {
                        [
                            {status:'Green',statusColor:'#037924', statusBg:'#A9FFC1'},
                            {status:'Grey', statusColor:'#949595', statusBg:'#DDDDDD'},
                            {status:'Green',statusColor:'#037924', statusBg:'#A9FFC1'},
                            {status:'Red',statusColor:'#ED4337', statusBg:'#FFD0CD'},
                            {status:'Amber',statusColor:'#9C6802', statusBg:'#FFED8F'},
                        ].map((item, i) => {
                            return (
                                <div className='custom-table-body' key={'ListofShareholders' + i}>
                                    <div className='row align-items-center' >
                                        <div className='col'>
                                            Some Company
                                        </div>
                                        <div className='col'>
                                            OLP123456111111
                                        </div>
                                        <div className='col'>
                                            email@email.com
                                        </div>
                                        <div className='col'>
                                            <div className='statusBtn' style={{ backgroundColor: item.statusBg, color: item.statusColor }}>{item.status}</div>
                                        </div>
                                        <div className='col'>
                                            <div onClick={individualScreeningDetails} style={{ backgroundColor: '#037AD01A', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'max-content', fontSize: 12, cursor: 'pointer' }} >Start Screening</div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </section>
        </div>
    )
}

export default StartScreening
