import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    isLoading: false,
    error: null,
    oomeroUserList: [],
    clientUserList: [],
    oomeroEntityList: [],
    clientEntityList: [],
    userDetails: {},
}

export const fetchOomeroUserList = createAsyncThunk("user/fetchOomeroUserList", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchUserList+'?userType=oomero&' + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchClientUserList = createAsyncThunk("user/fetchClientUserList", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchUserList+'?userType=client&' + queryParams
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const deactivateUser = createAsyncThunk("user/deactivateUser", async (requestBody) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.deactivateUser,
            data: requestBody
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const reactivateUser = createAsyncThunk("user/reactivateUser", async (requestBody) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.reactivateUser,
            data: requestBody
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const deleteUser = createAsyncThunk("user/deleteUser", async (requestBody) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.deleteUser,
            data: requestBody
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchListOfOomeroEntity = createAsyncThunk("user/fetchListOfOomeroEntity", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchOomeroEntity
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchListOfClientEntity = createAsyncThunk("user/fetchClientEntity", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchClientEntity
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const addNewUser = createAsyncThunk("user/addNewUser", async (requestBody) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.addNewUser,
            data: requestBody
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const setUserPassword = createAsyncThunk("user/setInviteUserPassword", async (requestBody) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.setUserPassword,
            data: requestBody
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const changeUserPassword = createAsyncThunk("user/changePassword", async (requestBody) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.changePassword,
            data: requestBody
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchUserDetails = createAsyncThunk("user/fetchUserDetails", async (userId) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchUserDetails+'?userId=' + userId
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateUserDetail = createAsyncThunk("user/updateUserData", async (requestBody) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateUserDetails,
            data: requestBody
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

const manageUserSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOomeroUserList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.oomeroUserList = payload?.data;
            })
            .addCase(fetchClientUserList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.clientUserList = payload?.data;
            })
            .addCase(deactivateUser.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(reactivateUser.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(deleteUser.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(fetchListOfOomeroEntity.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.oomeroEntityList =  payload.data;
            })
            .addCase(addNewUser.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(fetchListOfClientEntity.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.clientEntityList = payload.data;
            })
            .addCase(fetchUserDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.userDetails = payload.data;
            })
            .addCase(updateUserDetail.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(setUserPassword.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(changeUserPassword.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addMatcher(isAnyOf(fetchOomeroUserList.pending, fetchClientUserList.pending,deactivateUser.pending,reactivateUser.pending,deleteUser.pending, fetchListOfOomeroEntity.pending, addNewUser.pending, fetchListOfClientEntity.pending,fetchUserDetails.pending,updateUserDetail.pending, setUserPassword.pending, changeUserPassword.pending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(fetchOomeroUserList.rejected,fetchClientUserList.rejected,deactivateUser.rejected,reactivateUser.rejected,deleteUser.rejected, fetchListOfOomeroEntity.rejected, addNewUser.rejected,fetchListOfClientEntity.rejected,fetchUserDetails.rejected,updateUserDetail.rejected,setUserPassword.rejected, changeUserPassword.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again ";

            })
    }
});

const manageUserReducer = manageUserSlice.reducer;

export default manageUserReducer;