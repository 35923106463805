import CryptoJS from 'crypto-js';

// Encryption function for objects
export const encryptQueryParam = (object, secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY) => {
  try {
    const jsonString = JSON.stringify(object);
    const ciphertext = CryptoJS.AES.encrypt(jsonString, secretKey).toString();
    return ciphertext;
  } catch (error) {
    console.error('Error encrypting object:', error);
    return ''; // or handle the error appropriately
  }
};

// Decryption function for objects
export const decryptQueryParam = (encryptedValue, secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY) => {
  return new Promise((resolve, reject) => {
    try {
      const bytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedValue), secretKey);
      const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
      resolve(JSON.parse(decryptedValue));
    } catch (error) {
      // Handle decryption errors
      console.error('Decryption error:', error);
      reject(error);
    }
  });
};