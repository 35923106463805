import React, { useState } from 'react'
import '../../Profile/Profile.scss';
import OnboardClientBar from '../../../components/OnboardClientBar/OnboardClientBar';
import BlackInfoIcon from '../../../assets/images/black _Info Circle_.png'
import CircleBlueTick from '../../../assets/images/circle_blue_tick.png'
import ApproveTick from '../../../assets/images/Approve_tick.png'
import TickBlackIcon from '../../../assets/images/tick_black_icon.png'
import { useNavigate } from 'react-router-dom';
import Logo from '../../../assets/images/logo.png'
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '../../../assets/images/Cancel_icon.png'

function WebformAppicationDone() {
    // let navigate = useNavigate();
    // const routeChange = () => {
    //     let path = `/create_profile`;
    //     navigate(path);
    // }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div>
            <div className='white-background py-2 position-fixed w-100' style={{ borderRadius: 0 }}>
                <div className="d-flex justify-content-center">
                    <img src={Logo} style={{ objectFit: 'contain', width: 150 }} />
                </div>
            </div>
            <div style={{padding:20}}>
            <div className='mt-5'>

            </div>
                <OnboardClientBar
                    mainTitle={`Onboard Individual Client`}
                    title1={`PERSONAL DETAILS`}
                    title2={`ADDRESS`}
                    title3={`BANK DETAILS`}
                    title4={`KYC Verification`}
                    backgroundColor1={'#037AD0'}
                    backgroundColor2={'#037AD0'}
                    backgroundColor3={'#037AD0'}
                    backgroundColor4={'#037AD0'}
                />
                <div className='row mt-4'>

                    <div className='col-lg-4'>
                        <div className='white-background p-3'>
                            <div className='text-blue mb-3'>
                                <h1 className='fw-normal'>KYC Verification</h1>
                            </div>
                            <hr />
                            <div className='row'>
                                <div className='col-1'>
                                    <img src={CircleBlueTick} style={{ width: 20, objectFit: 'contain' }} />
                                </div>
                                <div className='col-11'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h2 className='text-black'>Photo Proof</h2>
                                        <img onClick={handleShow} src={BlackInfoIcon} style={{ width: 16, objectFit: 'contain', cursor: 'pointer' }} />
                                    </div>
                                    <h4 className='text-black fw-normal' style={{marginRight:25}}>Borem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-1'>
                                    <img src={CircleBlueTick} style={{ width: 20, objectFit: 'contain' }} />
                                </div>
                                <div className='col-11'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h2 className='text-black'>Proof Of  Address</h2>
                                        <img onClick={handleShow} src={BlackInfoIcon} style={{ width: 16, objectFit: 'contain', cursor: 'pointer'}} />
                                    </div>
                                    <h4 className='text-black fw-normal' style={{marginRight:25}}>Borem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-1'>
                                    <img src={CircleBlueTick} style={{ width: 20, objectFit: 'contain' }} />
                                </div>
                                <div className='col-11'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h2 className='text-black'>Application Done</h2>
                                        <img onClick={handleShow} src={BlackInfoIcon} style={{ width: 16, objectFit: 'contain', cursor: 'pointer' }} />
                                    </div>
                                    <h4 className='text-black fw-normal' style={{marginRight:25}}>Borem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-8'>
                        <div className='white-background p-3'>
                            <div className='text-blue mb-3'>
                                <h1 className='fw-normal'>Application Done</h1>
                            </div>
                            <hr />
                            <div className='d-flex align-items-center flex-column'>
                                <img src={ApproveTick} style={{ width: 90, objectFit: 'contain', marginTop: 20 }} />
                                <h1 className='text-blue text-center fw-normal mt-4'>Application has been successfully
                                    <br />submitted for processing</h1>
                                <p className='text-black text-center fw-bold mt-4 border-dash' style={{ fontSize: 36 }}>
                                    oomero ID<br />
                                    0000000000
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-end my-4'>
                    <div className='d-flex align-items-center'>
                        <button className='btn-primary'>Continue</button>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header >
                        <h5 className="modal-title text-center text-blue"><b>Accept Photo Proof ID Documents </b></h5>
                        <div onClick={handleClose} className='position-absolute top-0 end-0'>
                            <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex align-items-center my-3">
                            <img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />
                            <p className="text-black mb-0" style={{ fontWeight: '600', fontSize: 16 }}>Photo Proof 1</p>
                        </div>
                        <div className="d-flex align-items-center my-3">
                            <img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />
                            <p className="text-black mb-0" style={{ fontWeight: '600', fontSize: 16 }}>Photo Proof 1</p>
                        </div>
                        <div className="d-flex align-items-center my-3">
                            <img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />
                            <p className="text-black mb-0" style={{ fontWeight: '600', fontSize: 16 }}>Photo Proof 1</p>
                        </div>
                        <div className="d-flex align-items-center my-3">
                            <img src={TickBlackIcon} style={{ width: 20, objectFit: 'contain', marginRight: 10 }} />
                            <p className="text-black mb-0" style={{ fontWeight: '600', fontSize: 16 }}>Photo Proof 1</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default WebformAppicationDone
