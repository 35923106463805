import React, { useEffect, useRef, useState } from 'react'
import DocumnetVerificationIcon from '../../../assets/images/documnet_verification.png'
import PassportImage from '../../../assets/images/passport_img.png'
import EditIcon from '../../../assets/images/edit_icon.png'
import { Accordion, Modal } from 'react-bootstrap'
import ScreenActionIcon from '../../../assets/images/screeing_action.png'
import * as  yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { decodeToken } from 'react-jwt'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'
import { addScreeningComments, listKycDocuments, requestDocument } from '../IndividualScreeningSlice'
import Loader from '../../../components/Loader/Loader'
import { getSignedUrls } from '../../../utils/awsHelper'
import { TransformWrapper,TransformComponent } from "react-zoom-pan-pinch";
import CancelIcon from '../../../assets/images/Cancel_icon.png'

function IndividualDocuments({ entityClientId, viewOnly }) {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [fullScreenImageSrc, setFullScreenImageSrc] = useState(null);
    const { isLoading: screeningLoader, kycDocuments } = useSelector(state => state.individualScreeningReducer);
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const [docUrl,setDocUrl]=useState()

    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const commentSchema = yup.object().shape({
        commentType: yup.string().required('Comment Type is required'),
        comment: yup.string().required('Comment is required'),
    });

    const { control: controlCommentForm, register: registerCommentForm, handleSubmit: handleSubmitCommentForm, formState: { errors: errorsCommentForm }, reset: resetCommentForm, setValue: setValueComment } = useForm({
        resolver: yupResolver(commentSchema),
    });

    const requestDocumentSchema = yup.object().shape({
        documentType: yup.string().required('Document Type is required'),
    });

    const { control: controlRequestDocumentForm, register: registerRequestDocumentForm, handleSubmit: handleSubmitRequestDocumentForm, formState: { errors: errorsRequestDocumentForm }, reset: resetRequestDocumentForm, setValue: setValueRequestDocument } = useForm({
        resolver: yupResolver(requestDocumentSchema),
    });

    useEffect(() => {
        if (!entityClientId) {
            navigate(`/individual_screening`);
            return;
        }

        dispatch(listKycDocuments(`?entityClientId=${entityClientId}`)).unwrap().catch((error) => {
            toast.error(error?.message);
        });

    }, [entityClientId]);

    const onCommentFormSubmit = (data) => {
        resetCommentForm();
        dispatch(addScreeningComments({ payload: data })).unwrap().then((obj) => {
            toast.success(obj?.message || 'Comment Added Successfully');
        }).catch(error => {
            toast.error(error.message || 'Unable to add comments');
        });
    }

    const onRequestDocumentFormSubmit = (data) => {
        resetRequestDocumentForm();
        dispatch(requestDocument(`?entityClientId=${data?.entityClientId}&documentType=${data.documentType}`)).unwrap().then((obj) => {
            toast.success(obj?.message || 'Document Requested Successfully');
        }).catch(error => {
            toast.error(error.message || 'Unable to request document');
        });
    }
    const imageRef = useRef(null);
    const getImgUrl = async (url) => {
        const baseUrl = url.split('?')[0];
        const signedUrl = await getSignedUrls(baseUrl);
        return signedUrl;
    }

    useEffect(() => {
        const fetchImageSrc = async () => {
            try {
                if (imageRef.current) {
                    imageRef.current.innerHTML = '';
                } 
                const valid = [
                    { key: 'identity_document_backid_url', label: 'Back ID' },
                    { key: 'identity_document_frontid_url', label: 'Front ID' },
                    { key: 'identity_document_passport_url', label: 'Passport' },
                    { key: 'identity_document_selfie_url', label: 'Selfie' }
                ];
    
                for (let i = 0; i < kycDocuments.length; i++) {
                    for (let j = 0; j < valid.length; j++) {
                        const { key, label } = valid[j];
                        const doc = kycDocuments[i]?.[key];
                        if (doc) {
                            const imageUrl = await getImgUrl(doc);
                            if (imageRef.current) {
                                const mainDiv = document.createElement('div');
                                mainDiv.style.display = 'inline-block';
                                mainDiv.style.margin = '10px 5px';
                                mainDiv.style.textAlign = 'center';
    
                                const newImg = document.createElement('img');
                                newImg.src =  imageUrl;
                                newImg.style.width = '55px';
                                newImg.style.margin = '5px';
                                newImg.style.objectFit = 'contain';
                                newImg.style.cursor = 'pointer';
                                newImg.onclick = () => handleImageClick(imageUrl);
    
                                const imgLabel = document.createElement('div');
                                imgLabel.textContent = label;
                                imgLabel.style.fontSize = '12px';
                                imgLabel.style.color = '#555';
    
                                mainDiv.appendChild(newImg);
                                mainDiv.appendChild(imgLabel);
    
                                await imageRef.current.appendChild(mainDiv);
                            }
                        } else {
                            console.error('No document found in kycDocuments');
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };
    
        fetchImageSrc();
    }, [kycDocuments]);

    const handleImageClick = (imageUrl) => {
        if (typeof imageUrl === 'string') {
            setFullScreenImageSrc(imageUrl);
            handleShow(true)
        } else {
            console.error('Invalid image URL:', imageUrl);
        }
    };
    const handleImageClickDoc = (docUrl) => {
        if (typeof docUrl === 'string') {
            setFullScreenImageSrc(docUrl);
            handleShow(true)
        } else {
            console.error('Invalid image URL:', docUrl);
        }
    };

    const formatLabel = (key) => {
        return key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };
    const documentOptions = [
        { value: 'aadhaar', label: 'Aadhaar' },
        { value: 'pancard', label: 'Pan Card' },
        { value: 'driving_license', label: 'Driving License' },
        { value: 'national_id_card', label: 'National ID Card' },
        { value: 'tax_id_card', label: 'Tax ID Card' },
        { value: 'voter_id_card', label: 'Voter ID Card' },
        { value: 'other', label: 'Other Proof of Identity' },
      ];
    return (
        <div>
            {screeningLoader && <Loader />}
            <div className='d-flex align-items-center justify-content-end mt-3'>
                {/* <img src={EditIcon} style={{ width: 15, objectFit: 'contain', cursor: 'pointer' }} /> */}
            </div>
            <div className='d-flex align-items-center'>
                <img src={DocumnetVerificationIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                <h2 className='text-success text-center'>Document Verification Results</h2>
            </div>
            <hr />
            <Accordion className='aml-accordion documnets-tab-accordion' defaultActiveKey={1} flush>
                {kycDocuments.map((item,index) =>
                    <Accordion.Item eventKey={index.toString()} className='id-documents-css accordion-button::after'>
                        <Accordion.Header onClick={() => toggleAccordion(index.toString())}>
                            <div className="d-flex align-items-center">
                                <h2 className='text-blue capitalize'>{item.identity_document_type}</h2>
                                {/* <p className='statusBtn_small' style={{ backgroundColor: item.statusBg, color: item.statusColor, fontSize: 12, marginLeft: 10 }}>{item.status}</p> */}
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='custom-table'>
                                <div className='custom-table-head'>
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <h6 className='text-blue'>Checks Done</h6>
                                        </div>
                                        <div className='col-lg-4'>
                                            <h6 className='text-blue'>View Documents</h6>
                                        </div>
                                        <div className='col-lg-4'>
                                            <h6 className='text-blue'>Provider</h6>
                                        </div>
                                        {/* <div className='col-lg-2'>
                                            <h6 className='text-blue'>Result</h6>
                                        </div>
                                        <div className='col-lg-3'>
                                            <h6 className='text-blue'>Action</h6>
                                        </div> */}
                                    </div>
                                </div>
                                {/* {[
                                { status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', check_done: 'ID Document', action: 'Request or Upload Additional Document' },
                                { status: 'Pending', statusColor: '#9C6802', statusBg: '#FFED8F', check_done: 'Liveness', action: 'Re-KYC' },
                                { status: 'Pending', statusColor: '#9C6802', statusBg: '#FFED8F', check_done: 'ID Document - Face', action: 'Re-KYC' },
                            ].map((item) =>
                                <div className='custom-table-body'>
                                    <div className='row align-items-center'>
                                        <div className='col-lg-3'>
                                            <h6 className='text-black'>{item.check_done}</h6>
                                        </div>
                                        <div className='col-lg-2'>
                                            <img src={PassportImage} style={{ width: 55, objectFit: 'contain' }} />
                                        </div>
                                        <div className='col-lg-2'>
                                            <h6 className='text-black'>Yoti</h6>
                                        </div>
                                        <div className='col-lg-2'>
                                            <p className='statusBtn' style={{ backgroundColor: item.statusBg, color: item.statusColor }}>{item.status}</p>
                                        </div>
                                        <div className='col-lg-3'>
                                            <p style={{ backgroundColor: '#E6F2FB', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'fit-content', fontSize: 14, }} className='text-blue'>{item.action}</p>
                                        </div>
                                    </div>
                                </div>
                            )} */}
                                <Accordion className='client-billing-accordion client-services'>

                                    <Accordion.Item eventKey={item.key}>
                                        <Accordion.Header onClick={toggleAccordion}>
                                            <div className='row align-items-center w-100'>
                                                <div className='col-lg-4'>
                                                    <h6 className='text-black'>Details<span>
                                                        <i className={`fa fa-chevron-down ms-3 ${isAccordionOpen ? 'rotate-chevron' : ''}`}></i>
                                                    </span></h6>
                                                </div>
                                                <div className="col-lg-4" onClick={(e) => e.stopPropagation()}>
                                                    <div className='row d-flex'>

                                                    <div ref={imageRef} onClick={handleImageClick}></div>
                                                        {
                                                            item?.provider_details?.document_portrait_url && (
                                                                <div className='col-lg-2'>
                                                                    <div>
                                                                        <img
                                                                            src={item?.provider_details?.document_portrait_url}
                                                                            width={55}
                                                                            onClick={() => handleImageClickDoc(item?.provider_details?.document_portrait_url)}
                                                                            alt="Document"
                                                                        />
                                                                        <br />
                                                                        <p className='text-black' style={{ fontSize: 12 }}>document portrait url</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                            </div>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <h6 className='text-black'>Tech5</h6>
                                                </div>
                                                {/* <div className='col-lg-2'>
                                                        <p className='statusBtn' style={{ backgroundColor: item.statusBg, color: item.statusColor }}>{item.status}</p>
                                                    </div>
                                                    <div className='col-lg-3'>
                                                        <p style={{ backgroundColor: '#E6F2FB', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'fit-content', fontSize: 14, }} className='text-blue mb-0'>{item.action}</p>
                                                    </div> */}
                                            </div>

                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className='row'>
                                                {
                                                    Object.entries(item?.provider_details || {})
                                                        .filter(([key]) => key !== 'document_portrait' && key !== 'document_portrait_url') 
                                                        .map(([key, value]) => (
                                                            value && (
                                                                <div className='col-lg-4' key={key}>
                                                                    <div className='d-flex align-items-center mt-3'>
                                                                        <h6 className='text-black fw-bold'>{formatLabel(key)}:</h6>
                                                                        <h4 className='text-black' style={{ marginLeft: 5 }}>{value}</h4>
                                                                    </div>
                                                                </div>
                                                            )
                                                        ))
                                                }
                                                {/* <div className='col-lg-4'>
                                                    <div className='d-flex align-items-center mt-3'>
                                                        <h6 className='text-black fw-bold'>Country:</h6>
                                                        <h4 className='text-black' style={{ marginLeft: 5 }}>United Kingdom</h4>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <div className='d-flex align-items-center mt-3'>
                                                        <h6 className='text-black fw-bold'>Type:</h6>
                                                        <h4 className='text-black' style={{ marginLeft: 5 }}>P</h4>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <div className='d-flex align-items-center mt-3'>
                                                        <h6 className='text-black fw-bold'>Passport No:</h6>
                                                        <h4 className='text-black' style={{ marginLeft: 5 }}>125035493</h4>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <div className='d-flex align-items-center mt-3'>
                                                        <h6 className='text-black fw-bold'>Surname:</h6>
                                                        <h4 className='text-black' style={{ marginLeft: 5 }}>Golechha</h4>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <div className='d-flex align-items-center mt-3'>
                                                        <h6 className='text-black fw-bold'>Given Names:</h6>
                                                        <h4 className='text-black' style={{ marginLeft: 5 }}>Sandeep</h4>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <div className='d-flex align-items-center mt-3'>
                                                        <h6 className='text-black fw-bold'>Code:</h6>
                                                        <h4 className='text-black' style={{ marginLeft: 5 }}>GBR</h4>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <div className='d-flex align-items-center mt-3'>
                                                        <h6 className='text-black fw-bold'>Nationality:</h6>
                                                        <h4 className='text-black' style={{ marginLeft: 5 }}>British Citizen</h4>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <div className='d-flex align-items-center mt-3'>
                                                        <h6 className='text-black fw-bold'>Sex:</h6>
                                                        <h4 className='text-black' style={{ marginLeft: 5 }}>M</h4>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <div className='d-flex align-items-center mt-3'>
                                                        <h6 className='text-black fw-bold'>Date of Birth:</h6>
                                                        <h4 className='text-black' style={{ marginLeft: 5 }}>22/Dec/1971</h4>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <div className='d-flex align-items-center mt-3'>
                                                        <h6 className='text-black fw-bold'>Place of Birth:</h6>
                                                        <h4 className='text-black' style={{ marginLeft: 5 }}>Chennai</h4>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <div className='d-flex align-items-center mt-3'>
                                                        <h6 className='text-black fw-bold'>Date of Issue:</h6>
                                                        <h4 className='text-black' style={{ marginLeft: 5 }}>02/Jun/2021</h4>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <div className='d-flex align-items-center mt-3'>
                                                        <h6 className='text-black fw-bold'>Date of Expiry:</h6>
                                                        <h4 className='text-black' style={{ marginLeft: 5 }}>02/Jun/2031</h4>
                                                    </div>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <div className='d-flex align-items-center mt-3'>
                                                        <h6 className='text-black fw-bold'>MRZ No:</h6>
                                                        <h4 className='text-black' style={{ marginLeft: 5 }}>asgsfdsgfgfghjgrhtjytregfngmhgkyrgbfngjyedbfgjyebnjfegjyuytegjytyegjgjgesjthgeseghthf</h4>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                </Accordion>

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                )}
            </Accordion>
        {!viewOnly &&
            <div className='white-background p-3 my-3'>
                <form className='form' onSubmit={handleSubmitCommentForm(onCommentFormSubmit)}>
                    <div className='d-flex align-items-center'>
                        <img src={ScreenActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                        <h2 className='text-ambed text-center'>Add Comments</h2>
                    </div>
                    <hr />
                    <div className={`d-flex mb-2 ${errorsCommentForm?.commentType ? 'is-invalid' : ''}`}>
                        { (decodedToken?.roles?.permissions?.add_general_comment || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
                            <div style={{ marginRight: 30 }}>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" {...registerCommentForm('commentType')} id="flexRadioDefault2" value="open" />
                                    <label className="form-check-label" for="flexRadioDefault1">Open</label>
                                </div>
                            </div>
                        }
                        {(decodedToken?.roles?.permissions?.add_aml_comments_screening_dashboard || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
                            <div style={{ marginRight: 30 }}>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" {...registerCommentForm('commentType')} id="flexRadioDefault1" value="aml" />
                                    <label className="form-check-label" for="flexRadioDefault1">AML</label>
                                </div>
                            </div>
                        }
                        {
                            (decodedToken?.roles?.permissions?.add_ghost_comment || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
                            <div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" {...registerCommentForm('commentType')} id="flexRadioDefault3" value="ghost" />
                                    <label className="form-check-label" for="flexRadioDefault1">Ghost</label>
                                </div>
                            </div>
                        }
                    </div>

                    <input type="hidden" {...registerCommentForm(`entityClientId`, { value: entityClientId })} />
                    <input type="hidden" {...registerCommentForm(`screen`, { value: 'documents' })} />

                    {errorsCommentForm?.commentType &&
                        <span className="invalid-feedback" >Comment Type is required</span>
                    }

                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row align-items-end'>
                                <div className='col-lg-9' style={{ width: '75%' }}>
                                    <div className="form-group mt-3">
                                        <label className="form-label" for="exampleInputEmail1">Add Comment</label>
                                        <textarea type="text" {...registerCommentForm('comment')} className={`form-control ${errorsCommentForm?.comment ? 'is-invalid' : ''}`} id="exampleInputEmail1" placeholder="Add Comment" rows="2" />
                                        {errorsCommentForm?.comment &&
                                            <span className="invalid-feedback" >Comment is required</span>
                                        }
                                    </div>
                                </div>
                                <div className='col-lg-3' style={{ width: '25%' }}>
                                    <button className='btn-primary fw-normal w-100'>Add Comment</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <form className='form' onSubmit={handleSubmitRequestDocumentForm(onRequestDocumentFormSubmit)}>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className="row align-items-end">
                                <div className='col-lg-9' style={{ width: '75%' }}>
                                    <div className="form-group mt-3">
                                        <label className="form-label" for="exampleInputEmail1">Request Documents</label>
                                        <select className={`form-select first-select ${errorsRequestDocumentForm?.documentType ? 'is-invalid' : ''}`} aria-label="Default select example" {...registerRequestDocumentForm('documentType')} >
                                            <option value=''>Select Document Type</option>
                                            <option value="aadhaar">Aadhaar</option>
                                            <option value="pancard">Pan Card</option>
                                            <option value="driving_license">Driving License</option>
                                            <option value="national_id_card">National ID Card</option>
                                            <option value="tax_id_card">Tax ID Card</option>
                                            <option value="voter_id_card">Voter ID Card</option>
                                            <option value="other">Other Proof of Identity</option>
                                        </select>
                                        {errorsRequestDocumentForm?.documentType &&
                                            <span className="invalid-feedback" >Document Type is required</span>
                                        }

                                        <input type="hidden" {...registerRequestDocumentForm(`entityClientId`, { value: entityClientId })} />
                                    </div>
                                </div>
                                <div className='col-lg-3' style={{ width: '25%' }}>
                                    <button className='btn-primary fw-normal w-100'>Request Documents</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        }
        <Modal show={show} onHide={handleClose} centered scrollable size='md'>
                <Modal.Header className='model-header mt-2 pb-3'>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginRight: 8, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="fullscreen-overlay active">
                            <TransformWrapper
                                initialScale={1}
                                initialPositionX={0}
                                initialPositionY={0}
                                centerOnInit={true}
                            >
                                <TransformComponent wrapperStyle={{ width: '100%' }}>
                                    <img
                                        src={fullScreenImageSrc}
                                        alt="Full-Screen View"
                                        style={{ width: '100%', objectFit: 'contain', cursor: 'pointer', height: 400}}
                                    />
                                </TransformComponent>
                            </TransformWrapper>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default IndividualDocuments

