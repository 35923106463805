import React from 'react'
import SearchIcon from '../../assets/images/search_icon.png'
import UploadIcon from '../../assets/images/client_upload_icon.png'
import { useNavigate } from 'react-router-dom';

function NewClientSetup() {
    const navigate = useNavigate();

    const routeChange = () => {
        let path = `/clients/manage_New_client_services`;
        navigate(path);
    }
    return (
        <div className='grey-background'>
            <div className='white-background p-3'>
                <h2 className='text-blue'>New Client Setup </h2>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    {/* <div className='white-background p-3 mt-3'>
                        <div className="form-group position-relative">
                            <label className="form-label" for="exampleInputEmail1">Client Name</label>
                            <input type="text" className="form-control" placeholder='Search'></input>
                            <div className="position-absolute" style={{ right: 20, top: '53%' }}>
                                <img src={SearchIcon} style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div> */}
                    <div className='white-background p-3 mt-3'>
                        <label className="form-label" for="exampleInputEmail1">Client Account</label>
                        <div className='row mt-3'>
                            <div className='col-lg-6 col-md-12'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        WEB
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        SAAS
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <div className="form-group">
                            <label className="form-label" for="exampleFormControlSelect1">Client Industry</label>
                            <select id="statusDropdown" className="form-control form-select">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <div className="form-group">
                            <label className="form-label" for="exampleFormControlSelect1">Client Business Type</label>
                            <select id="statusDropdown" className="form-control form-select">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12'>
                                <div className="form-group">
                                    <label className="form-label" for="exampleFormControlSelect1">Assign to Oomero Company</label>
                                    <select id="statusDropdown" className="form-control form-select">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <div className="form-group">
                                    <label className="form-label" for="exampleFormControlSelect1">Assign to Oomero Employee</label>
                                    <select id="statusDropdown" className="form-control form-select">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <div className="form-group">
                            <label className="form-label" for="exampleFormControlSelect1">Client Business Type</label>
                            <select id="statusDropdown" className="form-control form-select">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <div className="form-group">
                            <label className="form-label" for="exampleInputEmail1">Client Email</label>
                            <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Client Email" />
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <label className="form-label mb-3" for="exampleInputEmail1">Client Refresh Rates</label>
                        <div className='row align-items-center'>
                            <div className='col-lg-6'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Green 3 Years
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='row align-items-center'>
                                    <div className='col-6'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1">
                                                Custom
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='d-flex align-items-center'>
                                            <div className="form-group">
                                                <input type="text" className="form-control p-2 text-center" id="exampleInputEmail1" placeholder="MM" />
                                            </div>
                                            <label className="form-check-label" for="defaultCheck1" style={{ marginLeft: 10 }}>
                                                Months
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row align-items-center mt-3'>
                            <div className='col-lg-6'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Amber 2 Years
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='row align-items-center'>
                                    <div className='col-6'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1">
                                                Custom
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='d-flex align-items-center'>
                                            <div className="form-group">
                                                <input type="text" className="form-control p-2 text-center" id="exampleInputEmail1" placeholder="MM" />
                                            </div>
                                            <label className="form-check-label" for="defaultCheck1" style={{ marginLeft: 10 }}>
                                                Months
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row align-items-center mt-3'>
                            <div className='col-lg-6'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Red 1 Year
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='row align-items-center'>
                                    <div className='col-6'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1">
                                                Custom
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='d-flex align-items-center'>
                                            <div className="form-group">
                                                <input type="text" className="form-control p-2 text-center" id="exampleInputEmail1" placeholder="MM" />
                                            </div>
                                            <label className="form-check-label" for="defaultCheck1" style={{ marginLeft: 10 }}>
                                                Months
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='white-background p-3 mt-3'>
                                <label className="form-label" for="exampleInputEmail1">Compliance manual</label>
                                <div className='row align-items-center'>
                                    <div className='col-lg-5 col-md-12'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1">
                                                Standard
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-lg-5 col-md-12'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1">
                                                Customer Provided
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-md-12'>
                                        <img src={UploadIcon} style={{ width: 40, objectFit: 'contain', cursor: 'pointer' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='white-background p-3 mt-3'>
                                <label className="form-label" for="exampleInputEmail1">Payment Cycle</label>
                                <div className='row mt-3 align-items-center'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1">
                                                Prepaid
                                            </label>
                                        </div>
                                    </div>
                                    {/* <div className='col-lg-6 col-md-12'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1">
                                                Monthly Billing
                                            </label>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <label className="form-label mb-3" for="exampleInputEmail1">Manual Checking Time -Individual</label>
                        <div className='row align-items-center'>
                            <div className='col-lg-4'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Yes
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row align-items-center'>
                            <div className='col-lg-3 mt-2'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        2 Hrs
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-3 mt-2'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        4 Hrs
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-3 mt-2'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        8 Hrs
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-3 mt-2'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        24 Hrs
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-3 mt-2'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        48 Hrs
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-3 mt-2'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        72 Hrs
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-3 mt-2'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Custom
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-3 mt-2'>
                                <div className="form-group">
                                    <input type="text" className="form-control p-2 text-center" id="exampleInputEmail1" placeholder="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <label className="form-label mb-3" for="exampleInputEmail1">Manual Checking Time - Corporates</label>
                        <div className='row align-items-center'>
                            <div className='col-lg-4'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Yes
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row align-items-center'>
                            <div className='col-lg-4 mt-2'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        24 Hrs
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-4 mt-2'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        48 Hrs
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-4 mt-2'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        72 Hrs
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-4 mt-2'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Custom
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-4 mt-2'>
                                <div className="form-group">
                                    <input type="text" className="form-control p-2 text-center" id="exampleInputEmail1" placeholder="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <label className="form-label mb-3" for="exampleInputEmail1">Proof of Identity</label>
                        <div className='row align-items-center'>
                            <div className='col-lg-3'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        0
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        1
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <label className="form-label mb-3" for="exampleInputEmail1">Proof of Address</label>
                        <div className='row align-items-center'>
                            <div className='col-lg-3'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        0
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        1
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        2
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <label className="form-label" for="exampleInputEmail1">Bank Details (Mandatory)</label>
                        <div className='row mt-3'>
                            <div className='col-lg-6 col-md-12'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        Yes
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                    <label className="form-check-label" for="defaultCheck1">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <label className="form-label" for="exampleInputEmail1">Corporate onboarding</label>
                        <div className='row'>
                            <div className='col-lg-5 col-md-12'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <div className="form-group">
                                        <input type="text" className="form-control p-2 text-center" id="exampleInputEmail1" placeholder="" />
                                    </div>
                                    <label className="form-label px-3" for="exampleInputEmail1">%</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <label className="form-label mb-3" for="exampleInputEmail1">Billing</label>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12'>
                                <label className="form-label mb-3" for="exampleInputEmail1">Invoice by Email</label>
                                <div className='row align-items-center'>
                                    <div className='col-lg-4'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <div className="form-group">
                                    <label className="form-label" for="exampleFormControlSelect1">Currency</label>
                                    <select id="statusDropdown" className="form-control form-select">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12'>
                                <div className="form-group">
                                    <label className="form-label" for="exampleFormControlSelect1">Tax code </label>
                                    <select id="statusDropdown" className="form-control form-select">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                    <div className='white-background p-3 mt-3'>
                        <div className="form-group mb-4 mt-1">
                            <label className="form-label" for="exampleInputEmail1">Max Credit limit</label>
                            <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Max Credit limit" />
                        </div>
                        <div className="form-group mb-2">
                            <label className="form-label" for="exampleInputEmail1">Platform Fee</label>
                            <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Platform Fee" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end my-4'>
                <div className='d-flex align-items-center'>
                    <button onClick={routeChange} className='btn-primary'>Save & Next</button>
                </div>
            </div>
        </div>
    )
}

export default NewClientSetup
