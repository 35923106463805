import React from 'react';
import './PdfTable.scss';

function PdfTable({ data, headers, showStatusColumn, showActions, actionsHeaderText, showTableImg, ImgHeaderText, showActions2, actionsHeaderText2, expandedRow, showtablecss, showImage, tableImg, showOrderedList, showRelevant, showNormalText }) {
    return (
        <div className={`${showtablecss ? 'custom-table-css' : 'another-table-css'}`}>
            <div style={{ overflowX: 'auto', padding: '0 0', width: window.innerWidth < 900 ? window.innerWidth - 70 : '' }}>
                <table style={{ width: '100%', borderCollapse: 'separate' }} className={window.innerWidth < 900 ? 'mt-4' : 'custom-table'} cellSpacing="0" cellPadding="10">
                    <thead>
                        <tr className='table-head'>
                            {headers.map((header, index) => (
                                <th className='table-header' key={index}>{header}</th>
                            ))}
                            {showStatusColumn && <th>Status</th>}
                            {showActions && <th className='table-header'>{actionsHeaderText || ''}</th>}
                            {showActions2 && <th className='table-header'>{actionsHeaderText2 || ''}</th>}
                            {showTableImg && <th className='table-header'>{ImgHeaderText || ''}</th>}
                        </tr>
                    </thead>
                    <tbody className='table-body'>
                        {data.map((rowData, rowIndex) => (
                            <tr key={rowIndex} className='table-body-tr' style={{ position: 'relative' }}>
                                {Object.entries(rowData).map(([key, value], colIndex) => {
                                    const classNames = {
                                        'Conservative': 'table-dark-green-class',
                                        'Moderate': 'table-yellow-class',
                                        'Aggressive': 'table-red-class',
                                    };

                                    let className = '';

                                    if (headers[colIndex] === 'View Documents') {
                                        return (
                                            <td className='table-row' key={colIndex}>
                                                <img src={tableImg} alt='Document' style={{ width: 40 }} />
                                            </td>
                                        );
                                    }

                                    if (headers[colIndex] === 'TITLE' || headers[colIndex] === 'ACTION DESCRIPTION') {
                                        if (Array.isArray(value)) {
                                            const commentItem = value.find(item => item.includes('COMMENT'));
                                            const items = value.filter(item => !item.includes('COMMENT')).slice(0, 3);

                                            return (
                                                <td className='table-row' key={colIndex} style={{ textAlign: 'left' }}>
                                                    {showOrderedList && items.length > 0 && (
                                                        <ol style={{ color: '#0094FF' }}>
                                                            {items.map((item, idx) => (
                                                                <li key={idx}>{item}</li>
                                                            ))}
                                                        </ol>
                                                    )}
                                                    {showNormalText && (
                                                        <div>
                                                            {items.map((data, item) => (
                                                                <div key={item}>{data}</div>
                                                            ))}
                                                        </div>
                                                    )}
                                                    {commentItem && (
                                                        <div>
                                                            <strong>{commentItem.split('COMMENT:')[0]} COMMENT:</strong>
                                                            <br />
                                                            <span className=''>{commentItem.split('COMMENT:')[1]}</span>
                                                        </div>
                                                    )}
                                                </td>
                                            );
                                        }

                                        if (typeof value === 'string') {
                                            const parts = value.split('Comment:');
                                            return (
                                                <td className='table-row' key={colIndex} style={{ textAlign: 'left' }}>
                                                    {parts[1] && (
                                                        <div>
                                                            <strong>Comment</strong>
                                                            <br />
                                                            <span>{parts[1]}</span>
                                                        </div>
                                                    )}
                                                </td>
                                            );
                                        }
                                        return <td className='table-row' key={colIndex}>{value}</td>;
                                    }

                                    if (headers[colIndex] === 'PUBLISHED' || headers[colIndex] === 'ACTION DATE TIME' || headers[colIndex] === 'DATE TIME') {
                                        return (
                                            <td className='table-row' key={colIndex}>
                                                {typeof value === 'string' && value.split(' ').map((data, i) => (
                                                    <div key={i}>
                                                        {data}
                                                        <br />
                                                    </div>
                                                ))}
                                            </td>
                                        );
                                    }

                                    return (
                                        <td className={`table-row ${className}`} key={colIndex}>
                                            {value}
                                        </td>
                                    );
                                })}
                                {showActions && (
                                    <td
                                        className='table-row'
                                        style={{ display: expandedRow === rowIndex ? 'flex' : '',alignItems: expandedRow === rowIndex ? 'start' : ''}}
                                    >
                                        {showRelevant &&
                                            <div className='table-green-class'>RELEVANT</div>
                                        }
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default PdfTable;
