import React from 'react';
import './ContentBox.scss';

function BankDetailsData({ bankname_label,bankname,accountno_label,accountno,code_label,codeno,sortcode_label,sortcode_no,iban_label,iban_no,addresslabel,addresstext,imagesource1,homeaddress_label,lane_no,streetname,name,cityhead, cityname, statehead, statename, postalcodehead, postalcode }) {
    return (
        <div className='contentbox-css'>

            <div className='d-flex'>
                <div className='col-7'>
                    <p className='text1'>{bankname_label}<span className='text2' style={{ fontWeight: 400 }}>{bankname}</span></p>
                    <p className='text1'>{accountno_label}<span className='text2' style={{ fontWeight: 400 }}>{accountno}</span></p>
                    <p className='text1'>{code_label}<span className='text2' style={{ fontWeight: 400 }}>{codeno}</span></p>
                </div>
                <div className='col-5'>
                    <p className='text1'>{sortcode_label}<span className='text2' style={{ fontWeight: 400 }}>{sortcode_no}</span></p>
                    <p className='text1'>{iban_label}<span className='text2' style={{ fontWeight: 400 }}>{iban_no}</span></p>
                </div>
            </div>
            <p className='text1' style={{ marginTop: '22px' }}>
                {addresslabel}</p>
            <div style={{ marginTop: -10 }}>
                <img src={imagesource1} className='tick-circle' style={{ marginLeft: 0 }} />
                <span className='text2'>{addresstext}</span>
            </div>

            <div className='d-flex'>
                <p className='text1' style={{ marginTop: 5 }}>
                    {homeaddress_label}
                </p>
                <div className='d-flex' style={{ marginTop: 5 }}>
                    {/* <img src={Tickcircle} className='tick-circle' /> */}
                    <p className='text2'>
                        {lane_no}
                        {/* <br />
                        {streetname}
                        <br />
                        {name} */}
                    </p>
                </div>
            </div>
{/* 
            <div className='d-flex justify-content-between'>
               {cityname && <p className='text1'>
                    {cityhead}
                    <span className='text2' style={{ fontWeight: 400 }}>{cityname}</span>
                </p>}
                {statename && <p className='text1'>
                    {statehead}
                    <span className='text2' style={{ fontWeight: 400 }}>{statename}</span>
                </p>}
                {postalcode && <p className='text1'>
                    {postalcodehead}
                    <span className='text2' style={{ fontWeight: 400 }}>{postalcode}</span>
                </p>}
            </div> */}

        </div>
    )
}

export default BankDetailsData