import React from 'react';
import './ContentBox.scss'

function DocumentDetails({ data }) {

    const formatLabel = (key) => {
        return key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };

    return (
        <div className='contentbox-css'>
            <div className="row">
                {Object.entries(data || {}).map(([key, value]) => (
                    <div className="col-xl-4">
                        <p key={key} className='text1'>
                            {formatLabel(key)}:<span className='text2' style={{ fontWeight: 400 }}>{value}</span>
                        </p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DocumentDetails