import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { fetchListOfOomeroEntity, addNewUser, fetchUserDetails, updateUserDetail } from './manageUserSlice';
import { fetchAllActiveRolesByEntity } from './../UsersRoles/roleSlice';
import Loader from '../../components/Loader/Loader';
import OomeroUtils from './../../utils/OomeroUtils';
import Select from "react-select"
import countryCodes from '../Registration/countryCode.json';
import BackBtnImg from '../../assets/images/back-btn-arrow.png'

function AddOomeroUser() {
    const navigate = useNavigate();
    const location = useLocation();
    const { isLoading, oomeroEntityList, userDetails } = useSelector(state => state.manageUserReducer);
    const { isLoading: roleLoader, activeEntityRoleLists } = useSelector(state => state.roleReducer);
    const dispatch = useDispatch();
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [originalEmail, setOriginalEmail] = useState(null);
    const [readOnly, setReadOnly] = useState(false);
    const [readOnlyAll, setReadOnlyAll] = useState(false);
    const pathArray = window.location.href.split('/');
    const parentMenu = pathArray[4].split('?')[0];
    const params = new URLSearchParams(location.search);
    const view = params.get('view'); // Get the view parameter

    const backBtn = () => {
        let path = `/manage_users`;
        navigate(path);
    }

    const schema = yup.object().shape({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        roleId: yup.string().required('Role is required'),
        entityId: yup.string().required('Company Name is required'),
        email: yup.string().email('Invalid email address').matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email address').required('Email is required'),
        mobile: yup
            .string()
            .required('Mobile Number is Invalid')
            .min(10, "Mobile number must be 10 digits")
            .matches(/^\d{10}$/, 'Mobile number must be 10 digits')
            .matches(/^[^a-zA-Z]*$/, 'Alphabets are not allowed'),
        mobileCode: yup.string().typeError('Invalid Mobile Code').required('Mobile Code is required'),
    });
    const { control, register, handleSubmit, formState: { errors }, reset, setValue ,getValues,trigger} = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        dispatch(fetchListOfOomeroEntity());
    }, [])
    useEffect(() => {
        if (selectedEntity) dispatch(fetchAllActiveRolesByEntity(`?entityId=${selectedEntity}`));
    }, [selectedEntity])

    const onFormSubmit = (data) => {
        if (selectedUserId) {
            delete data.email;
            let requestBody = OomeroUtils.getUnmatchedKeyValuePairs({ ...data }, { ...userDetails });
            if (Object.keys(requestBody).length === 0) {
                toast.info('No field value changed.');
            } else {
                requestBody.userId = selectedUserId;
                dispatch(updateUserDetail(requestBody)).unwrap()
                    .then((res) => {
                        toast.success(res?.message)
                    }).catch((error) => {
                        toast.error(error?.message);
                    })
            }
        } else {
            dispatch(addNewUser(data)).unwrap()
                .then((res) => {
                    toast.success(res?.message)
                    reset();
                    navigate(`/manage_users`);
                }).catch((error) => {
                    toast.error(error?.message);
                })
        }
    }

    useEffect(() => {
        const userId = params.get('userId');
        const view = params.get('view');
        if (parentMenu && parentMenu == 'edit_oomero_user' && userId) {
            setSelectedUserId(userId);
            dispatch(fetchUserDetails(userId)).unwrap()
                .then((res) => {
                    dispatch(fetchAllActiveRolesByEntity(`?entityId=${res?.data?.entityId}`));
                    setValue('firstName', res?.data?.firstName);
                    setValue('lastName', res?.data?.lastName);
                    setValue('roleId', res?.data?.roleId);
                    setValue('email', res?.data?.email);
                    setValue('mobile', res?.data?.mobile);
                    setValue('mobileCode', res?.data?.mobileCode);
                    setValue('entityId', res?.data?.entityId);
                    setOriginalEmail(res?.data?.email);
                    setReadOnly(res?.data?.email && true);
                    if (view) setReadOnlyAll(true);
                }).catch((error) => {
                    toast.error(error?.message);
                })
        }
    },[parentMenu])
    // getting Value
    const mobileCodeValue=getValues("mobileCode")

    const customStyles = {
        control: (provided) => ({
            ...provided,
            padding: '1px',
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
            boxShadow: 'none',
            '&:hover': {
                border: '2px solid #B3B3B3',
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#B3B3B3',
        }),
    };
    return (
        <>
            <div className='grey-background'>
                {(isLoading || roleLoader) && <Loader />}
                <form className='form' onSubmit={handleSubmit(onFormSubmit)} >
                    <button onClick={backBtn} className='back-button text-blue'><img src={BackBtnImg} style={{width:8,marginBottom:2,marginRight:4,objectFit:'contain'}}/> Back</button>
                    <div className='white-background p-3 my-3'>
                        <h2 className='text-blue fw-normal pt-2'>
                            {view ? 'View' : selectedUserId ? 'Edit' : 'Add'} Oomero User
                        </h2>
                        <hr />
                        <div className="row g-3 mb-3">
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">First Name</label>
                                    <input
                                        type="text"
                                        className={`form-control form-control-lg ${errors.firstName ? 'is-invalid' : ''}`}
                                        placeholder="First Name"
                                        {...register('firstName', {
                                            required: `Please Enter First Name`
                                        })}
                                        disabled={readOnlyAll && true}
                                    />
                                    {errors?.firstName && (
                                        <div className="invalid-feedback">{errors?.firstName?.message}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <label className="form-label" >Last Name</label>
                                    <input
                                        type="text"
                                        className={`form-control form-control-lg ${errors.lastName ? 'is-invalid' : ''}`}
                                        placeholder="Last Name"
                                        {...register('lastName', {
                                            required: `Please Enter Last Name`
                                        })}
                                        disabled={readOnlyAll && true}
                                    />
                                    {errors?.lastName && (
                                        <div className="invalid-feedback">{errors?.lastName?.message}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <label className="form-label" >Email</label>
                                    <input
                                        type="text"
                                        className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`}
                                        placeholder="Enter a valid email address"
                                        {...register('email', {
                                            required: `Enter a valid email address`,
                                            validate: value => {
                                                // Add additional validation if needed
                                                return !readOnly || (readOnly && value === originalEmail);
                                            },
                                        })}
                                        disabled={readOnly || readOnlyAll} // Set readOnly based on your condition
                                        value={originalEmail}
                                    />
                                    {errors?.email && (
                                        <div className="invalid-feedback">{errors?.email?.message}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group ">
                                    <label className="form-label" >Mobile</label>
                                    <div className='row'>
                                        <div className='col-lg-3 col-md-3'>
                                            <Controller
                                                name="mobileCode"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                    placeholder="select"
                                                    isOptionDisabled={(option) => option.isdisabled}
                                                    isDisabled={readOnly}
                                                    className={`${errors.mobileCode ? 'is-invalid' : ''}`}
                                                    options={Object.keys(countryCodes).map((country) => ({
                                                        label: `${country} (${countryCodes[country]})`,
                                                        value: countryCodes[country]
                                                    }))}
                                                    styles={customStyles}
                                                    value={mobileCodeValue ? { label: `${mobileCodeValue} `, value: countryCodes[mobileCodeValue] } : ''}
                                                    onChange={(selectedOption) => {
                                                        setValue('mobileCode', selectedOption?.value);
                                                        trigger('mobileCode');
                                                    }}
                                                />
                                                )}
                                            />
                                            {errors?.mobileCode && (
                                                <div className="invalid-feedback">{errors?.mobileCode?.message}</div>
                                            )}
                                        </div>
                                        <div className='col-lg-9 col-md-9'>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className={`form-control form-control-lg ${errors.mobile ? 'is-invalid' : ''}`}
                                                    placeholder="000 000 0000"
                                                    name="mobile"
                                                    {...register('mobile')}
                                                    disabled={readOnlyAll}
                                                />
                                                {errors?.mobile && (
                                                    <div className="invalid-feedback">{errors?.mobile?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group ">
                                    <label className="form-label" >Assign to Oomero Company</label>
                                    <Controller
                                        name="entityId"
                                        control={control}
                                        render={({ field }) => (
                                            <select
                                                id="entityDropdown"
                                                className={`form-control form-select ${errors.entityId ? 'is-invalid' : ''}`}
                                                {...field}
                                                onChange={(e) => {
                                                    setSelectedEntity(e.target.value);
                                                    field.onChange(e);
                                                }}
                                                disabled={readOnlyAll}
                                            >
                                                <option value={''}>Select Company Name</option>
                                                {oomeroEntityList && oomeroEntityList.length > 0 &&
                                                    oomeroEntityList.map((res, i) => (
                                                        <option key={'entityId' + i} value={res?.entity_id}>
                                                            {res?.entity_name}
                                                        </option>
                                                    ))}
                                            </select>
                                        )}
                                    />
                                    {errors?.entityId && (
                                        <div className="invalid-feedback">{errors?.entityId?.message}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group ">
                                    <label className="form-label" >Select Role</label>
                                    <Controller
                                        name="roleId"
                                        control={control}
                                        render={({ field }) => (
                                            <select
                                                id="roleDropdown"
                                                className={`form-control form-select ${errors.roleId ? 'is-invalid' : ''}`}
                                                {...field}
                                                disabled={readOnlyAll}
                                            >
                                                <option >Select Role</option>
                                                {activeEntityRoleLists && activeEntityRoleLists.length > 0 &&
                                                    activeEntityRoleLists.map((res, i) => (
                                                        <option key={'roleId' + i} value={res?.role_id}>
                                                            {res?.role_name}
                                                        </option>
                                                    ))}
                                            </select>
                                        )}
                                    />
                                    {errors?.roleId && (
                                        <div className="invalid-feedback">{errors?.roleId?.message}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {!params.get('view') &&
                        <div className="d-flex flex-row-reverse">
                            <button type="submit" className="btn btn-primary mt-2 mb-1">Save User</button>
                        </div>
                    }
                </form>
            </div>
        </>
    )
}

export default AddOomeroUser