import React from 'react';
import './pagination.scss';
import { Pagination } from '@mui/material';

const TablePagination = ({ totalPages, handlePagination, currentPage }) => {
    
    
    return (
        <div className='d-flex justify-content-end pt-2 pb-4'>
            <Pagination count={parseInt(totalPages)} page={currentPage} color="primary" onChange={handlePagination} showFirstButton showLastButton/>
        </div>
    )
}
export default TablePagination;