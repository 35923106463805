import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import BlueCommentIcon from '../../assets/images/blue_comment_icon.png'
import KeyInformation from './KeyInformation'
import AdverseMedia from './AdverseMedia'
import DocumentsAml from './DocumentsAml'
import InnerComments from './InnerComments'


function ResultsTab({globalSearchDetail, personalDetails,adverseMedias,saveAmlComments, saveAdverseMediaComments,amlComments,fetchAllAmlComments,uploadMediaImage,fetchAdverseMedia}) {
    return (
        <div className='p-3 oomero-tabs'>
            <div className='position-relative'>
                <div className='position-absolute ' style={{right:'0%'}}>
                    <div className='d-flex align-items-center'>
                    {/*<h4 className='text-black'>7 Comments Out of 100</h4>*/}
                    {/*<img src={BlueCommentIcon} style={{ width: 38, objectFit: 'contain', cursor: 'pointer',marginLeft:10 }} />*/}
                    </div>
                </div>
            </div>
            <Tabs defaultActiveKey="Key Data" id="uncontrolled-tab-example" className="mb-3" >
                <Tab eventKey="Key Data" title="Key Data">
                <KeyInformation globalSearchDetail={globalSearchDetail} personalDetails={personalDetails} saveAmlComments={saveAmlComments}/>
                </Tab>
                <Tab eventKey="Adverse Media" title="Adverse Media">
                <AdverseMedia globalSearchDetail={globalSearchDetail} adverseMedias={adverseMedias} saveAdverseMediaComments={saveAdverseMediaComments} fetchAdverseMedia={fetchAdverseMedia} />
                </Tab>
                <Tab eventKey="Documents" title="Documents">
                <DocumentsAml globalSearchDetail={globalSearchDetail} uploadMediaImage={uploadMediaImage} saveAmlComments={saveAmlComments}/>
                </Tab>
                <Tab eventKey="Comments" title="AML Comments">
                <InnerComments globalSearchDetail={globalSearchDetail} amlComments={amlComments} fetchAllAmlComments={fetchAllAmlComments}   />
                </Tab>
            </Tabs>
        </div>
    )
}

export default ResultsTab
