import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    isLoading: false,
    error: null,
    countryData : []
}

export const registerEnterpriseAdmin = createAsyncThunk("user/registerEnterpriseAdmin", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.register,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const sendSignupOtp = createAsyncThunk("user/sendSignupOtp", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.sendSignupOtp,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const countryList = createAsyncThunk("user/getCountryList", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getCountryList
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

const registerSlice = createSlice({
    name: "register",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerEnterpriseAdmin.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(sendSignupOtp.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(countryList.fulfilled, (state, { payload }) => {
                state.countryData = payload.data;
                state.isLoading = false;
                state.error = null;
            })
            .addMatcher(isAnyOf(registerEnterpriseAdmin.pending, countryList.pending,sendSignupOtp.pending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(registerEnterpriseAdmin.rejected, countryList.rejected,sendSignupOtp.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again ";
            })
    }
});

const registerReducer = registerSlice.reducer;

export default registerReducer;