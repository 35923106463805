import React from 'react';
import './tablepagination.scss';
import { TablePagination } from '@mui/material';

const TableWithPagination = (props) => {
    const { totalPages, handlePagination, currentPage, rowsPerPage, handleChangeRowsPerPage } = props;
    
    return (
        <div className='d-flex justify-content-end pt-2 pb-4'>
            <TablePagination
                component="div"
                count={parseInt(totalPages)}
                page={currentPage}
                onPageChange={handlePagination}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    )
}
export default TableWithPagination;