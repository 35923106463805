import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    isLoading: false,
    error: null,
    forgotData: {},
    userToken: localStorage.getItem("token") ? localStorage.getItem("token") : null,
}

export const verifyAndSendOtp = createAsyncThunk("user/verifyCredentialsAndSendOtp", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.verifyCredentialsAndSendOtp,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const verifyLoginOtp = createAsyncThunk("user/verifyLoginOtp", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.verifyLoginOtp,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const loginWithGoogleAuthenticator = createAsyncThunk("user/loginWithGoogleAuthenticator", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.loginWithGoogleAuthenticator,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const verifyResetPasswordOtp = createAsyncThunk(
    'user/verifyForgotPasswordCode',
    async ({ email, otp, }) => {
      try {
        const data = {
          method: METHOD_TYPE.post,
          url: API_ENDPOINTS.resetPasswordOtpVerify,
          data: {
            email,
            otp,
          },
        };
  
        const response = await api(data);
        return response.data;
      } catch (error) {
        throw error?.response?.data;
      }
    }
  );


  export const changeForgetPassword=createAsyncThunk("user/setNewPassword", async  ({ email, otp,newPassword }) => {
    try {
      const data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.changeForgetPassword,
        data: {
          email,
          otp,
          newPassword,
        },
      };

      const response = await api(data);
      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
  })

  export const sendForgetOpt=createAsyncThunk("/user/sendForgotPasswordCode",async({email})=>{
    try {
        const data={
            method:METHOD_TYPE.post,
            url:API_ENDPOINTS.sendForgotPasswordCode,
            data:{
                email
            }
        }
        const response=await api(data);
        return response.data
    } catch (error) {
        throw error?.response?.data
    }
  })

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        logout(state, action) {
            localStorage.clear();
            state.userToken = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(verifyAndSendOtp.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(loginWithGoogleAuthenticator.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addMatcher(isAnyOf(verifyLoginOtp.fulfilled, changeForgetPassword.fulfilled,
                sendForgetOpt.fulfilled, verifyResetPasswordOtp.fulfilled),(state) => {
                state.isLoading = false;
                state.error = null;
            })            
            .addMatcher(isAnyOf(verifyAndSendOtp.pending, verifyLoginOtp.pending, loginWithGoogleAuthenticator.pending, changeForgetPassword.pending,
                sendForgetOpt.pending, verifyResetPasswordOtp.pending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(verifyAndSendOtp.rejected, verifyLoginOtp.rejected, loginWithGoogleAuthenticator.rejected, changeForgetPassword.rejected,
                sendForgetOpt.rejected, verifyResetPasswordOtp.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again ";

            })
    }
});

const { logout } = loginSlice.actions;
const loginReducer = loginSlice.reducer;

export { logout, loginReducer };
export default loginReducer;