import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    isLoading: false,
    error: null,
    currencyList : [],
    fxRatesList : {rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200},
    fxRateDetail : {}, 
    fxRateHistoryList : [] 
}

export const getCurrencyList = createAsyncThunk("user/getCurrencyList", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getCurrencyList
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getFxRateBasedByBaseCurrency = createAsyncThunk("user/getFxRateBasedByBaseCurrency", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getFxRateBasedByBaseCurrency + queryParam
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const createFxRate = createAsyncThunk("user/createFxRate", async (payload) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.createFxRate,
            data: payload
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const deleteFxRate = createAsyncThunk("user/deleteFxRate", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.delete,
            url: API_ENDPOINTS.deleteFxRate + queryParam
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchFxRateDetailById = createAsyncThunk("user/fetchFxRateDetailById", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchFxRateDetailById + queryParam
        };
        const response = await api(data);
        return (response.data) ? response?.data?.data : {};

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateFxRate = createAsyncThunk("user/updateFxRate", async ({payload, queryParam}) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateFxRate + queryParam,
            data: payload
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchFxRateHistoryList = createAsyncThunk("user/fetchFxRateHistoryList", async (payload) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.fetchFxRateHistoryList,
            data: payload
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

const fxRatesSlice = createSlice({
    name: "fxRates",
    initialState,
    reducers: {
        resetFxList(state, action) {
            state.fxRatesList = {rows: [], count: 0, totalPages: 0, currentPage: 1, type: 200};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCurrencyList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.currencyList = payload.data;
            })
            .addCase(getFxRateBasedByBaseCurrency.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.fxRatesList = payload.data;
            })
            .addCase(createFxRate.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(fetchFxRateDetailById.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.fxRateDetail = payload.data;
            })
            .addCase(updateFxRate.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(fetchFxRateHistoryList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.fxRateHistoryList = payload.data;
            })
            .addMatcher(isAnyOf(getCurrencyList.pending, getFxRateBasedByBaseCurrency.pending, createFxRate.pending, fetchFxRateDetailById.pending, updateFxRate.pending, fetchFxRateHistoryList.pending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(getCurrencyList.rejected, getFxRateBasedByBaseCurrency.rejected, createFxRate.rejected, fetchFxRateDetailById.rejected, updateFxRate.rejected, fetchFxRateHistoryList.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = (error) ? error.message : "Request Failed Please Try Again ";
            })
    }
});

const { resetFxList } = fxRatesSlice.actions;
const fxRatesReducer = fxRatesSlice.reducer;

export { resetFxList, fxRatesReducer };
export default fxRatesReducer;