import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '../../assets/images/Cancel_icon.png'
import './ManageClient.scss'
import ThumbIcon from '../../assets/images/thumb_icon.png'

function ClientServiceSetup() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


    const routeChange = () => {
        let path = `/clients/manage_admin`;
        navigate(path);
    }
    return (
        <div className='grey-background client-services'>
            <div className='white-background p-3'>
                <h2 className='text-blue'>ID&V</h2>
            </div>
            <div className='mt-3'>
                <Accordion >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>
                                    Credit Safe
                                </label>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='row'>
                                <div className='col-3 mt-3'>
                                    <div onClick={handleShow} className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" checked />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                    <div className='d-flex align-items-center mt-2'>
                                        <div className="form-group" style={{ width: '15%' }}>
                                            <input type="text" className="form-control p-2 text-center" id="exampleInputEmail1" placeholder="" style={{ height: 25, borderRadius: 5 }} />
                                        </div>
                                        <label className="form-check-label" for="defaultCheck1" style={{ marginLeft: 10 }}>
                                            No.of Yearly Report
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Select All
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>
                                    Companies House (UK)
                                </label>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='row'>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" checked />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                    <div className='d-flex align-items-center mt-2'>
                                        <div className="form-group" style={{ width: '15%' }}>
                                            <input type="text" className="form-control p-2 text-center" id="exampleInputEmail1" placeholder="" style={{ height: 25, borderRadius: 5 }} />
                                        </div>
                                        <label className="form-check-label" for="defaultCheck1" style={{ marginLeft: 10 }}>
                                            No.of Yearly Report
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Select All
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>
                                    Mira Inform
                                </label>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='row'>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" checked />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                    <div className='d-flex align-items-center mt-2'>
                                        <div className="form-group" style={{ width: '15%' }}>
                                            <input type="text" className="form-control p-2 text-center" id="exampleInputEmail1" placeholder="" style={{ height: 25, borderRadius: 5 }} />
                                        </div>
                                        <label className="form-check-label" for="defaultCheck1" style={{ marginLeft: 10 }}>
                                            No.of Yearly Report
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Select All
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className='white-background p-3'>
                <h2 className='text-blue'>AML</h2>
            </div>
            <div className='mt-3'>
                <Accordion >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>
                                    Credit Safe
                                </label>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='row'>
                                <div className='col-3 mt-3'>
                                    <div onClick={handleShow} className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" checked />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                    <div className='d-flex align-items-center mt-2'>
                                        <div className="form-group" style={{ width: '15%' }}>
                                            <input type="text" className="form-control p-2 text-center" id="exampleInputEmail1" placeholder="" style={{ height: 25, borderRadius: 5 }} />
                                        </div>
                                        <label className="form-check-label" for="defaultCheck1" style={{ marginLeft: 10 }}>
                                            No.of Yearly Report
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Select All
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>
                                    Companies House (UK)
                                </label>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='row'>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" checked />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                    <div className='d-flex align-items-center mt-2'>
                                        <div className="form-group" style={{ width: '15%' }}>
                                            <input type="text" className="form-control p-2 text-center" id="exampleInputEmail1" placeholder="" style={{ height: 25, borderRadius: 5 }} />
                                        </div>
                                        <label className="form-check-label" for="defaultCheck1" style={{ marginLeft: 10 }}>
                                            No.of Yearly Report
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Select All
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1" style={{ fontSize: 16 }}>
                                    Mira Inform
                                </label>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='row'>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" checked />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                    <div className='d-flex align-items-center mt-2'>
                                        <div className="form-group" style={{ width: '15%' }}>
                                            <input type="text" className="form-control p-2 text-center" id="exampleInputEmail1" placeholder="" style={{ height: 25, borderRadius: 5 }} />
                                        </div>
                                        <label className="form-check-label" for="defaultCheck1" style={{ marginLeft: 10 }}>
                                            No.of Yearly Report
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Band A
                                        </label>
                                    </div>
                                </div>
                                <div className='col-3 mt-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Select All
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className='d-flex align-items-center justify-content-end my-4'>
                <div className='d-flex align-items-center'>
                    <button className='btn-primary'>Save & Next</button>
                </div>
            </div>
            <Modal className='modal-lg' show={show} onHide={handleClose} centered>
                <Modal.Header style={{ borderBottom: '1px solid #D9D9D9 ' }}>
                    <div>
                        <h5 className="modal-title text-center text-blue"><b>Band A</b></h5>
                        <h5 className="modal-title text-center text-blue mt-2"><b>Select Countries from Band A</b></h5>
                    </div>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label className="form-check-label" for="defaultCheck1">
                                Select All
                            </label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ borderTop: '1px solid #D9D9D9' }}>
                    <div className='row'>
                        <div className='col-3 mt-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Belgium
                                </label>
                            </div>
                        </div>
                        <div className='col-3 mt-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Denmark
                                </label>
                            </div>
                        </div>
                        <div className='col-3 mt-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    France
                                </label>
                            </div>
                        </div>
                        <div className='col-3 mt-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Germany
                                </label>
                            </div>
                        </div>
                        <div className='col-3 mt-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Ireland
                                </label>
                            </div>
                        </div>
                        <div className='col-3 mt-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Italy
                                </label>
                            </div>
                        </div>
                        <div className='col-3 mt-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Luxembourg
                                </label>
                            </div>
                        </div>
                        <div className='col-3 mt-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Japan
                                </label>
                            </div>
                        </div>
                        <div className='col-3 mt-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Netherland
                                </label>
                            </div>
                        </div>
                        <div className='col-3 mt-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Norway
                                </label>
                            </div>
                        </div>
                        <div className='col-3 mt-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    United Kingdom
                                </label>
                            </div>
                        </div>
                        <div className='col-3 mt-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Sweden
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end my-4'>
                        <div className='d-flex align-items-center'>
                            <button onClick={handleClose} className='btn-secondary' style={{ marginRight: 16 }}>Cancel</button>
                            <button onClick={() => { handleClose(); handleShow2(); }} className='btn-primary'>Save</button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={show2} onHide={handleClose2} centered >
                <Modal.Body>
                    <div className='d-flex justify-content-center'>
                        <img src={ThumbIcon} style={{ width: 70, height: 70, objectFit: 'contain' }} />
                    </div>
                    <h3 className='text-blue text-center mt-3 mb-0 fw-normal'>Changes done successfully !!</h3>
                    <div className="d-flex justify-content-center">
                        <button onClick={handleClose2} className="btn-primary w-50 my-3">Ok</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ClientServiceSetup
