import React from 'react';
import './TableComponent.scss';
import CautionIcon from '../../assets/images/caution-red-icon.png';
import InfoIcon from '../../assets/images/carbon_information-filled.png';

function TableComponent({ text1, text2, showBoxes, customColor, shouldApplyUnderline, text3, text4, text5, text6, text7, text8, text9, insertText, boxtext1, boxtext2, boxtext3, boxtext4, boxtext5, boxtext6, boxtext7, boxtext8, boxsubtext1, boxsubtext2, boxsubtext3, boxsubtext4, boxsubtext5, boxsubtext6, boxsubtext7, boxsubtext8, boxsubtext9, boxsubtext10, boxsubtext11, boxsubtext12, boxtext9
}) {

    const shouldShowBoxes = showBoxes && (text1 === "case management" || text1 === "key information");

    return (
        <div className='tablecomp-css'>
            <div className='d-flex'>
                <div className='col-4 columnbox'>
                    <p className='text1'>{text1}</p>
                </div>
                {text1 === 'filters' ? (
                    <>
                        <div className='col-3 columnbox'>
                            <div className='d-flex'>
                                <img src={CautionIcon} className='icon-image' alt="Caution" />
                                <p className='text1' style={{ fontWeight: 500 }} >{text1}</p>
                            </div>
                            <p className='text2'>{boxtext1}</p>
                            <p className='text2'>{boxtext2}</p>
                            <p className='text2'>{boxtext3}</p>
                            <p className='text2'>{boxtext4}</p>
                            <p className='text2'>{boxtext5}</p>
                            <p className='text2'>{boxtext6}</p>
                            <p className='text2'>{boxtext7}</p>
                            <p className='text2'>{boxtext8}</p>
                            <div className='d-flex'>
                                <img src={InfoIcon} className='icon-image' alt="Info" />
                                <p className='text3'>{boxtext9}</p>
                            </div>
                        </div>
                        <div className='col-5 columnbox'>
                            <p className='text2 text3'>{boxsubtext1}</p>
                            <p className='text2 text3'>{boxsubtext2}</p>
                            <p className='text2 text3'>{boxsubtext3}</p>
                            <p className='text2 text3'>{boxsubtext4}</p>
                            <p className='text2 text3'>{boxsubtext5}</p>
                            <p className='text2 text3'>{boxsubtext6}</p>
                            <p className='text2 text3'>{boxsubtext7}</p>
                            <p className='text2 text3'>{boxsubtext8}</p>
                            <p className='text2 text3'>{boxsubtext9}</p>
                            <p className='text2 text3'>{boxsubtext10}</p>
                            <p className='text2 text3'>{boxsubtext11}</p>
                            <p className='text2 text3'>{boxsubtext12}</p>
                        </div>
                    </>
                ) : (
                    <div className='col-8 columnbox'>
                        {shouldShowBoxes ? (
                            <div className='d-flex'>
                                <div className='col-6 borderbox'>
                                    <p className='text2'>{text3}</p>
                                    <p className='text2'>{text4}</p>
                                    <p className='text2'>{text5}</p>
                                    {text9 && <p className='text2'>{text9}</p>}
                                </div>
                                <div className='col-6'>
                                    <p className='text2 text4'>{text6}</p>
                                    <p className='text2 text4'>{text7}</p>
                                    <p className='text2 text4'>{text8}</p>
                                    {insertText && <p className='text2 text4'>{insertText}</p>}
                                </div>
                            </div>
                        ) : (
                            <p className='text2' style={{ color: customColor, textDecorationLine: shouldApplyUnderline ? 'underline' : 'none' }}>
                                {text2}
                            </p>
                        )}
                    </div>
                )}
            </div>
            
        </div>
    );
}

export default TableComponent;
