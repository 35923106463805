import React, { useEffect, useState } from 'react'
import Chart from 'chart.js/auto';

function Graph({keyStatsForGraph, keyStatsForGraphType, changeGraphType}) {
    let xAxisValue = keyStatsForGraph ? keyStatsForGraph.map(obj => obj.date.split('-')[2]  ) : [];
    let yAxisValue = keyStatsForGraph ? keyStatsForGraph.map(obj => obj.count  ) : [];
    console.log("xAxisValue", xAxisValue)
    console.log("yAxisValue", yAxisValue)
    useEffect(() => {
        // Access the canvas element using a ref after the component has mounted.
        const ctx = document.getElementById('myChart').getContext('2d');

        // Check if a chart instance already exists
        const existingChart = Chart.getChart(ctx);

        // Define gradient colors
        const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
        gradientStroke.addColorStop(0, 'rgba(21, 112, 239, 1)');
        gradientStroke.addColorStop(1, 'rgba(21, 112, 239, 1)');

        const gradientFill = ctx.createLinearGradient(0, 0, 0, 200);
        gradientFill.addColorStop(0, 'rgba(21, 112, 239, 0.5)');
        gradientFill.addColorStop(1, 'rgba(21, 112, 239, 0.05)');

        // Create the chart
        if (existingChart) {
            existingChart.destroy();
        }

        new Chart(ctx, {
            type: 'line',
            data: {
                labels: xAxisValue,
                datasets: [
                    {
                        borderColor: gradientStroke,
                        pointBorderColor: gradientStroke,
                        pointBackgroundColor: '#fff',
                        pointHoverBackgroundColor: gradientStroke,
                        pointHoverBorderColor: gradientStroke,
                        pointBorderWidth: 2,
                        pointHoverRadius: 1,
                        pointHoverBorderWidth: 1,
                        pointRadius: 5,
                        fill: true,
                        backgroundColor: gradientFill,
                        borderWidth: 2,
                        data: yAxisValue,
                        tension: 0.2
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    y: {
                        ticks: {
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                            beginAtZero: true,
                            maxTicksLimit: 6,
                            padding: 0,
                        },
                        grid: {
                            zeroLineColor: 'transparent',
                        },
                        title: {
                            display: true,
                            text: `Count`,
                            padding: 0,
                        }
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            padding: 10,
                            fontColor: 'rgba(0,0,0,0.5)',
                            fontStyle: 'bold',
                        },
                        title: {
                            display: true,
                            text: `Days`,
                            color: 'rgba(21, 112, 239, 1)',
                            padding: 0,
                        }
                    },
                },
            },
        });
    }, [keyStatsForGraph]);

    return (
        <div className='white-background p-3'>
            <div className='d-flex align-items-center justify-content-between'>
                <h1 className='text-blue mb-3'>Total No Of Individuals</h1>
                <div className='d-flex align-items-center justify-content-between'>
                    <div class="form-check" style={{marginRight:20}}>
                        <input class="form-check-input" type="radio" checked={keyStatsForGraphType === 'individual'} name="flexRadioDefault" id="flexRadioDefault1" onChange={() => changeGraphType('individual')}/>
                        <label class="form-check-label" for="flexRadioDefault1">
                            Individual
                        </label>
                    </div>
                    <div class="form-check" style={{marginRight:20}}>
                        <input class="form-check-input" type="radio" checked={keyStatsForGraphType === 'client'} name="flexRadioDefault" id="flexRadioDefault1" onChange={() => changeGraphType('client')}/>
                        <label class="form-check-label" for="flexRadioDefault1">
                            Client
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" checked={keyStatsForGraphType === 'individual_rejected'} name="flexRadioDefault" id="flexRadioDefault1" onChange={() => changeGraphType('individual_rejected')}/>
                        <label class="form-check-label" for="flexRadioDefault1">
                            Individual Rejected
                        </label>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end">
            </div>
            <div className='d-flex justify-content-center'>
                <canvas id="myChart"></canvas>
            </div></div>
    )
}

export default Graph
