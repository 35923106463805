import React from 'react';
import './ImageComponent.scss';

function ImageComponent({ imagesource, imageurl, width, height, style }) {
    return (
        <div className='imagecomponent-css'>
            <div className='imagediv'>
                <img
                    ref={imagesource || null}
                    src={imageurl || ''} //"https://kinsta.com/wp-content/uploads/2021/02/what-is-a-url.jpg"
                    width={width}
                    height={height}
                    style={style}
                    alt="Image"
                    // onError={(e) => {
                    //     if (!e.target.dataset.errorHandled) {
                    //         e.target.dataset.errorHandled = true; // Mark as error handled to prevent looping

                    //         // Hide the broken image
                    //         e.target.src = ''; // Hide the image source
                    //         e.target.style.display = 'none'; // Optionally hide the image element

                    //         // Check if an error message is already added
                    //         const errorMessage = document.createElement('div');
                    //         errorMessage.innerText = 'Image not avaliable';
                    //         e.target.parentNode.insertBefore(errorMessage, e.target.nextSibling); // Insert the error message
                    //     }
                    // }}
                />
            </div>
        </div>
    )
}

export default ImageComponent