import React from 'react'
import Logo from "../../../assets/images/logo.png"
import WelcomeLogo from '../../../assets/images/welcome_logo.png'

function WebformThankYouPage() {
  return (
    <>
        <div className='white-background py-2 position-fixed w-100' style={{ borderRadius: 0 }}>
            <div className="d-flex justify-content-center">
                <img src={Logo} style={{ objectFit: 'contain', width: 150 }} />
            </div>
        </div>
        <div className='grey-background webform-css'>
            <div className="d-flex justify-content-center mt-5">
                <img src={WelcomeLogo} className='welcome-img' />
            </div>
            <div className="d-flex justify-content-center mt-4">
                <div className='text-blue-header'>Thank You!</div>
            </div>
            <div className="d-flex justify-content-center mt-3">
                <div className='text-blue-description'>Your Oomero ID is been Successfully created</div>
            </div>
        </div>
    </>
  )
}

export default WebformThankYouPage