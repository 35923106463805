import React from 'react';
import './ContentBox.scss';

function EntityData({ searchterm, searchtext, createdhead, date_and_time, intervalhead, interval_percent, search_ref_label, search_ref_no, entity_label, entity_no, matched }) {
    return (
        <div className='contentbox-css'>
            <div className='d-flex'>
                <div className='col-6'>
                    <p className='text1'>{searchterm}<span className='text2' style={{ fontWeight: 400 }}>{searchtext}</span></p>
                    <p className='text1'>{createdhead}<span className='text2' style={{ fontWeight: 400 }}>{date_and_time}</span></p>
                    <p className='text1'>{intervalhead} <span className='text2' style={{ fontWeight: 400 }}>{interval_percent}</span></p>

                </div>
                <div className='col-6'>
                    <p className='text1'>{search_ref_label}<span className='text2' style={{ fontWeight: 400 }}>{search_ref_no}</span></p>
                    <p className='text1'>{entity_label}<span className='text2' style={{ fontWeight: 400 }}>{entity_no}</span></p>
                </div>
            </div>
            <div className='col-9' style={{marginLeft:10}}>
                <div className='row'>
                    {matched && matched.map((item) => (
                        <div className='personbox redbox'>
                            <p className='boxtext redtext'>{item}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default EntityData