import React, { useEffect, useState } from 'react'
import SearchIcon from '../../assets/images/search_icon.png'
import VectorIcon from '../../assets/images/vector_icon.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllEntityBillingList } from './viewClientBillingSlice';
import { toast } from 'react-toastify';
import moment from 'moment';
import TablePagination from '../../components/Pagination';
import Loader from '../../components/Loader/Loader';
import { resetData } from './viewClientBillingSlice';

function ClientBiling() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPge, SetPostsPerPage] = useState(10);
    const { isLoading, billingList } = useSelector(state => state.viewClientBillingReducer);
    const [searchType, setSearchType] = useState(false);
    const routeChange = () => {
        let path = `/billing/view_client_biling`;
        navigate(path);
    }
    useEffect(() => {
        dispatch(fetchAllEntityBillingList(`?page=${currentPage}&limit=${postsPerPge}`)).unwrap().catch((error) => {
            toast.error(error?.message);
        });
    }, [currentPage]);

    const onSearchFormSubmit = (data) => {
        setSearchType(true);
        let queryParams = `?page=${currentPage}&limit=${postsPerPge}`;
        if (data) {
            queryParams += `&search=${data}`;
        }
        dispatch(fetchAllEntityBillingList(queryParams)).unwrap().then((data) => {
            setSearchType(false);
        }).catch((error) => {
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    }

    const handlePagination = (event, value) => {
        setCurrentPage(value);
    }

    const handleView = (id) => {
        let path = `/billing/view_client_biling`;
        dispatch(resetData());
        navigate(path, { state: { invoiceId: id, viewOnly : true } });
    }

    return (
        <div className='grey-background'>
            {isLoading && <Loader/>}
            <div className='white-background p-3 mb-3'>
                <h1 className='text-blue px-2 fw-normal'>Client Billing</h1>
                <hr />
                <div className='row'>
                    <div className='col-lg-12 col-md-12'>
                        <div className="form-group position-relative">
                            <input type="text" className="form-control" placeholder='Search' onKeyUp={(event)=>onSearchFormSubmit(event.target.value)}></input>
                            <div className="position-absolute" style={{ right: 20, top: '20%' }}>
                                <img src={SearchIcon} style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-12 col-md-12 mt-3'>
                <div className='custom-table'>
                    <div className='custom-table-head'>
                        <div className='row'>
                            <div className='col'>
                                <h6 className='text-blue'>Client Name</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Account Type</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Current Balance</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Current Invoice</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Date of Last payment</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Date of Last Invoice</h6>
                            </div>
                            <div className='col-1'>
                                <h6 className='text-blue'></h6>
                            </div>
                        </div>
                    </div>
                    {billingList?.rows.map((obj, index) => (
                        <div className='custom-table-body'>
                            <div className='row align-items-center'> 
                                <div className='col'>
                                    <h6 className='text-black'>{obj?.entity_name}</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-black'>{obj?.package_type}</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-black'>
                                        {obj?.package_type === 'prepaid' ? obj?.prepaid_currency : obj?.package_type === 'postpaid' ?obj?.postpaid_currency:''} {obj?.current_balance}
                                    </h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-black'>
                                    {obj?.package_type === 'prepaid' ? obj?.prepaid_currency : obj?.package_type === 'postpaid' ?obj?.postpaid_currency:''} {obj?.current_invoice}
                                    </h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-black'>{(obj?.date_of_last_payment)?moment(obj?.date_of_last_payment).format("DD/MM/YYYY"):''}</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-black'>{(obj?.date_of_last_invoice)?moment(obj?.date_of_last_invoice).format("DD/MM/YYYY"): ''}</h6>
                                </div>
                                <div className='col-1'>
                                    <div className='d-flex align-items-center'>
                                        <img src={VectorIcon} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer' }} onClick={()=>handleView(obj?.invoice_id)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* <div className='d-flex align-items-center justify-content-end'>
                <span className="badge bg-primary-badge">1</span>
                <span className="badge">2</span>
                <span className="badge">3</span>
                <span className="badge">4</span>
                <span className="badge"><i className="fa fa-chevron-right"></i></span>
            </div> */}
            { billingList && billingList?.rows?.length > 0 &&
                <TablePagination totalPages={parseInt(billingList?.totalPages)} currentPage={currentPage} color="primary" handlePagination={handlePagination}/>
            }
        </div>
    )
}

export default ClientBiling
