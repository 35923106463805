import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import TablePagination from '../Pagination';
import moment from 'moment';
import riskStatusColors from './../../pages/IndividualScreening/riskStatusColors.json'
import DownloadImg from './../../assets/images/zip_download_img.png'

function IndividualTableData(props) {
    const { ViewIcon, PrintIcon, listData, handlePagination , currentPage, manageContinuousMonitoring, manageEntityClient,downloadZip, onboarded, reScreening, decodedToken, handleDownloadReport} = props;
    const navigate = useNavigate(); 

    const startScreening = (entityClientId) => {
        let path = onboarded ? `/view_individual_user/individual_screening_details`: `/individual_screening/individual_screening_details`;
        navigate(path, { state: { entityClientId, viewOnly : onboarded } });
    }
    const handleDownload = (entityClientId) => {
        downloadZip(entityClientId);
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div className='custom-table'>
            <div className='custom-table-head'>
                <div className='row'>
                    <div className='col-md-1'>
                        <p className='text-blue'>Full Name</p>
                    </div>
                    <div className='col'>
                        <p className='text-blue'>Oomero ID</p>
                    </div>
                    <div className='col'>
                        <p className='text-blue'>Oomero Risk Status</p>
                    </div>
                    <div className='col'>
                        <p className='text-blue'>Risk Status</p>
                    </div>
                    <div className='col'>
                        <p className='text-blue'>Date Time</p>
                    </div>
                    {
                        !onboarded &&
                        <div className='col'>
                            <p className='text-blue'>Screening Steps</p>
                        </div>
                    }
                    <div className='col'>
                        <p className='text-blue'>Continuous Monitoring</p>
                    </div>
                    {(decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.activate_individual_screening || decodedToken?.roles?.permissions?.deactivate_individual_screening) &&
                    <div className='col'>
                        <p className='text-blue'>Activate/Deactive</p>
                    </div>
                    }
                    {
                        onboarded && <div className='col'>
                            <p className='text-blue'>Status</p>
                        </div>
                    }
                    <div className='col-md-1'>
                        {!onboarded && (decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.edit_individual_screening) &&
                            <p className='text-blue'>Screening</p>

                        }
                        {
                            onboarded &&
                            <p className='text-blue'>View Details</p>
                        }
                    </div>
                    {
                        onboarded && (decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.send_to_rescreening_onboarded) && <div className='col'>
                            <p className='text-blue'>Rescreen</p>
                        </div>
                    }
                    <div className='col'>
                        <p className='text-blue text-center'>Action</p>
                    </div>
                </div>
            </div>
            {/* [
                    { status: 'Green', statusColor: '#037924', statusBg: '#A9FFC1' },
                    { status: 'Grey', statusColor: '#949595', statusBg: '#DDDDDD' },
                    { status: 'Green', statusColor: '#037924', statusBg: '#A9FFC1' },
                    { status: 'Red', statusColor: '#ED4337', statusBg: '#FFD0CD' },
                    { status: 'Amber', statusColor: '#9C6802', statusBg: '#FFED8F' },
                ] */}
            {listData && listData?.rows && listData?.rows.map((item, i) => {
            
                    const riskStatusStyle = riskStatusColors[item.oomero_risk_status?item.oomero_risk_status:'grey'] || riskStatusColors['grey'];
                    
                    return (
                        <div className='custom-table-body' key={'tabsdata' + i}>
                            <div className='row align-items-center'>
                                <div className='col-md-1'>
                                    <p>{item.first_name} {item.last_name}</p>
                                </div>
                                <div className='col'>
                                    <p>{item.oomero_id}</p>
                                </div>
                                <div className='col'>
                                    <p className='statusBtn' style={{ backgroundColor:riskStatusStyle.backgroundColor, color:riskStatusStyle.color, textTransform:'capitalize' }}>{item.oomero_risk_status? item.oomero_risk_status :'Grey'}</p>
                                </div>
                                <div className='col'>
                                    <p className='statusBtn' style={{ backgroundColor:riskStatusColors[item.risk_status?item.risk_status:'grey'].backgroundColor, color:riskStatusColors[item.risk_status?item.risk_status:'grey'].color, textTransform:'capitalize' }}>{item.risk_status? item.risk_status :'Grey'}</p>
                                </div>
                                <div className='col'>
                                    <p>{moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</p>
                                </div>
                                {
                                    !onboarded &&
                                        <div className='col'>
                                            <p style={{textTransform:'capitalize'}}>{item.screening_step}</p>
                                        </div>
                                }
                                <div className='col'>
                                    <div className="form-check form-switch">
                                        {!onboarded && (decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.continuous_monitoring_individual_screening) &&
                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={item?.continous_monitoring} onChange={(e) => manageContinuousMonitoring({ continous_monitoring: e.target.checked, entityClientId: item?.entity_client_id })} />
                                        }
                                        {onboarded && (decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.continuous_monitoring_profiles_onboarded) &&
                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={item?.continous_monitoring} onChange={(e) => manageContinuousMonitoring({ continous_monitoring: e.target.checked, entityClientId: item?.entity_client_id })} />
                                        }
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className="form-check form-switch">
                                        {!onboarded && (decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.activate_individual_screening || decodedToken?.roles?.permissions?.deactivate_individual_screening) &&
                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={item?.is_active} onChange={(e) => manageEntityClient({ status: e.target.checked, entityClientId: item?.entity_client_id })} />

                                        }
                                        {onboarded && (decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.reactivate_oomero_id_onboarded || decodedToken?.roles?.permissions?.deactivate_oomero_id_onboarded) &&

                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={item?.is_active} onChange={(e) => manageEntityClient({ status: e.target.checked, entityClientId: item?.entity_client_id })} />
                                        }
                                    </div>
                                </div>
                                {
                                    onboarded && <div className='col-md-1 me-3'>
                                        <p>{capitalizeFirstLetter(item?.status)}</p>
                                    </div>
                                }   
                                <div className='col-md-1 me-3'>
                                    {!onboarded && (decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.edit_individual_screening) &&
                                        <p style={{ backgroundColor: '#037AD01A', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'fit-content', fontSize: 12, cursor: 'pointer', textAlign: 'center' }} onClick={() => startScreening(item?.entity_client_id)}>{'Start Screening'}</p>

                                    }
                                    {
                                        onboarded &&
                                        <p style={{ backgroundColor: '#037AD01A', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'fit-content', fontSize: 12, cursor: 'pointer', textAlign: 'center' }} onClick={() => startScreening(item?.entity_client_id)}>{'View Details'}</p>
                                    }
                                </div>
                                {
                                    onboarded && (decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.send_to_rescreening_onboarded) &&  
                                    <div className='col-md-1 me-3'>
                                        <p style={{ backgroundColor: '#037AD01A', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'fit-content', fontSize: 12, cursor: 'pointer', textAlign: 'center' }} onClick={() => reScreening(item?.entity_client_id)}> Send to Rescreening</p>
                                    </div>
                                }
                                <div className='col'>
                                    {
                                        item?.hasfiles && !onboarded && (decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.download_individual_screening) &&
                                        <div className='d-flex align-items-center justify-content-center' style={{cursor: 'pointer'}} onClick={() => handleDownload(item.entity_client_id)}>
                                            <img src={DownloadImg} style={{ width: 16, objectFit: 'contain' }}  />
                                            <div style={{ fontSize: 12, marginLeft: 5 }}>ZIP</div>
                                        </div>
                                    }
                                    {
                                        item?.hasfiles && onboarded && (decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.download_screening_onboarded) &&
                                        <div className='d-flex align-items-center justify-content-center' style={{cursor: 'pointer'}} onClick={() => handleDownloadReport(item.entity_client_id)}>
                                            <img src={DownloadImg} style={{ width: 16, objectFit: 'contain' }}  />
                                            <div style={{ fontSize: 12, marginLeft: 5 }}>ZIP</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            {listData && listData?.rows?.length > 0 &&
                <TablePagination totalPages={parseInt(listData?.totalPages)} currentPage={currentPage} color="primary" handlePagination={handlePagination} />
            }
        </div>
    )
}

export default IndividualTableData