import React, { useEffect, useState } from 'react';
import AllOnboarding from '../../components/Dashboard/AllOnboarding';
import { CChart } from '@coreui/react-chartjs';
import { LiaUserCircle } from "react-icons/lia";
import Graph from '../../components/Dashboard/Graph';
import BarGraph from '../../components/Dashboard/BarGraph';
import { getIndividualScreeningPercentages, getIndividualScreeningPercentagesClients, getKeyStatsForGraph, getStatsByGraph } from './DashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import { decodeToken } from 'react-jwt';

function Dashboard() {
  const { isLoading, screeningPercentage, keyStatsForGraph,graphStats,screeningPercentageClients } = useSelector((state) => state.dashboardReducer);
  const [keyStatsForGraphType, setKeyStatsForGraphType] = useState("individual");
  const dispatch = useDispatch();
  const userToken = localStorage.getItem('token');
  const decodedToken = decodeToken(userToken);

  const changeGraphType = (type) => {
    setKeyStatsForGraphType(type);
  }

  useEffect(() => {

    const fetchScreeningPercentages = async () => {
      try {
        await dispatch(getIndividualScreeningPercentages());
      } catch (error) {
        toast.error(error.message || 'Unable to fetch Clients Percentage');
      }
    };

    const fetchScreeningPercentagesClients = async () => {
      try {
        await dispatch(getIndividualScreeningPercentagesClients('?screen=onboarded'));
      } catch (error) {
        toast.error(error.message || 'Unable to fetch Onboard Clients Percentage');
      }
    };
    
    if ((decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.view_dashboard)) {
      fetchScreeningPercentages();
      fetchScreeningPercentagesClients();
    }
  }, [dispatch]);

  useEffect(() => {
    if ((decodedToken?.isSuperAdmin || (decodedToken?.isOomero && decodedToken?.roles?.permissions?.view_dashboard))) {
      getKeyStatsForGraphFunc();
    }
  }, [keyStatsForGraphType])

  const getKeyStatsForGraphFunc = async () => {
    await dispatch(getKeyStatsForGraph(`?type=${keyStatsForGraphType}`)).unwrap().catch(error => {
      toast.error(error.message || 'Unable to fetch Key Stats data');
    })
  }
  
const getStatsByGraphs=async (userType,range,section,type)=>{
  if ((decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.view_dashboard)) {
    await dispatch(getStatsByGraph(`?userType=${userType}&range=${range}&section=${section}&type=${type}`)).unwrap().catch(error => {
      toast.error(error.message || 'Unable to fetch Key Stats data');
    });
  }
}

  const containerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 50,
    fontWeight: '700'
  };

  const items = [
    { title: 'Green', percentage: `${screeningPercentage?.greenp || '0.00'}%`, backgroundColor: '#037924' },
    { title: 'Ambed', percentage: `${screeningPercentage?.rejected || '0.00'}%`, backgroundColor: '#9C6802' },
    { title: 'Red', percentage: `${screeningPercentage?.redrc || '0.00'}%`, backgroundColor: '#DA0310' },
    { title: 'White Listed', percentage: `${screeningPercentageClients?.whitelist || '0.00'}%`, backgroundColor: '#80BCE7' },
    { title: 'Green (RC)', percentage:`${screeningPercentageClients?.green || '0.00'}%`, backgroundColor: '#28DC18' },
    { title: 'Ambed (RC)', percentage: `${screeningPercentageClients?.amber || '0.00'}%`, backgroundColor: '#FCB01A' },
    { title: 'Red (RC)', percentage: `${screeningPercentageClients?.red || '0.00'}%`, backgroundColor: '#FF626B' },
    { title: 'Black Listed', percentage: `${screeningPercentageClients?.blacklist || '0.00'}%`, backgroundColor: '#262626' },
  ];

  return (
    <div className='grey-background'>
        {isLoading && <Loader />}

        {
          !decodedToken?.isEnterpriseAdmin && !decodedToken?.isSuperAdmin && !decodedToken?.roles?.permissions?.view_dashboard &&
          <p>Welcome to Oomero</p>
        }

      {(decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.view_dashboard) &&
      <div className='row g-4'>
        {
          items.map((item, index) => (
            <React.Fragment key={index}>
              {index === 0 && <div className='col-12 mb-0'><h1 className='text-blue'>Screening Status Buckets</h1></div>}
              {index === 4 && <div className='col-12 mb-0'><h1 className='text-blue '>Onboard Clients</h1></div>}
              <div className='col-lg-3'>
                <div className='white-background p-3 mb-2 position-relative'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div>
                      <h6 className='text-grey'>{item?.title}</h6>
                      <p className='text-success mb-0' style={{ fontSize: 24, fontWeight: '700', color: item?.backgroundColor }}>{item?.percentage}</p>
                    </div>
                    <LiaUserCircle size={45} style={{ marginRight: 30 }} color={item?.backgroundColor} />
                  </div>
                  <div className='position-absolute' style={{ backgroundColor: item?.backgroundColor, height: '100%', width: 18, top: 0, right: 0, borderTopRightRadius: 10, borderBottomRightRadius: 10 }} />
                </div>
              </div>
            </React.Fragment>
          ))
        }
      </div>
      }

      <div className='row g-4'>
      {(decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.view_dashboard) && <>
        <div className='col-lg-9'>
          <AllOnboarding  getStatsByGraphs={getStatsByGraphs} graphStats={graphStats}  />
        </div>

        <div className='col-lg-3'>
          <div className='white-background p-3'>
            <h1 className='text-blue mb-3'>Alerts</h1>
            <div>
              <h2 className='fw-bold mb-2'>Company A</h2>
              <h6>Corem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
              <hr />
            </div>
            <div>
              <h2 className='fw-bold mb-2'>Company A</h2>
              <h6>Corem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
            </div>
            <div className='d-flex justify-content-center mt-2'>
              <button className='btn-secondary'>See All</button>
            </div>
          </div>
        </div>

        <div className='col-4'>
          <div className='white-background p-3'>
            <h1 className='text-blue mb-3'>Pending Verifications</h1>
            <div className='d-flex justify-content-center mb-5' style={{ marginTop: '3.7rem' }}>
              <div className='white-background position-relative' style={{ width: 250, borderRadius: 150, filter: 'drop-shadow(0px 3px 15px rgba(0, 0, 0, 0.10))' }}>
                <CChart
                  type="doughnut"
                  data={{
                    labels: ['AngularJs'],
                    datasets: [
                      {
                        backgroundColor: ['#FCB01A'],
                        data: [70],
                        circumference: (ctx) => {
                          return ctx.dataset.data[0] / 70 * 270
                        },
                        borderWidth: 0
                      },
                    ],
                  }}
                  options={{
                    cutout: '85%',
                    borderRadius: 20,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
                <p className='text-black position-absolute mb-0' style={containerStyle}>10<span style={{ fontSize: 30, fontWeight: '300' }}>/50</span></p>
              </div>
            </div>
          </div>
        </div>
        </>
        }

        {(decodedToken?.isSuperAdmin || (decodedToken?.isOomero && decodedToken?.roles?.permissions?.view_dashboard)) &&
          <div className='col-8'>
            <Graph keyStatsForGraph={keyStatsForGraph} keyStatsForGraphType={keyStatsForGraphType} changeGraphType={changeGraphType}/>
          </div>
        }

        {(decodedToken?.isSuperAdmin || (decodedToken?.isOomero && decodedToken?.roles?.permissions?.view_dashboard)) &&
          <div className='col-6'>
            <div className='white-background p-3 pb-2'>
              <div className='d-flex justify-content-between align-items-center2'>
                <h1 className='text-blue mb-3'>Manage Clients</h1>
                <button className='btn-secondary' style={{ padding: '0px 15px', fontSize: 12, height: 30, borderRadius: 10 }}>See All</button>
              </div>
              <div className='d-flex align-items-center'>
                <div class="form-check" style={{ marginRight: 30 }}>
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  <label class="form-check-label" for="flexRadioDefault1">
                    Top Web clients
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  <label class="form-check-label" for="flexRadioDefault1">
                    Top SASS /API
                  </label>
                </div>
              </div>
              <div className='custom-table mt-2 mb-2'>
                {
                  [1, 2, 3].map(() => {
                    return (
                      <div className='custom-table-body' style={{ padding: '10px' }}>
                        <div className='row'>
                          <div className='col-2'>
                            <h5 className='text-blue'>1</h5>
                          </div>
                          <div className='col-5'>
                            <h5 className='text-blue'>SBI</h5>
                          </div>
                          <div className='col-5'>
                            <h5 className='dark-blue-small-bg text-blue' style={{ width: 'fit-content' }}>View Details</h5>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        }

        {(decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.view_dashboard) &&
          <div className='col-6'>
            <div className='white-background p-3'>
              <h1 className='text-blue mb-3'>Client Onboarding Status</h1>
              <BarGraph />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default Dashboard
