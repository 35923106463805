import React from 'react'
import BankDetailsIcon from '../../../assets/images/bank_details.png'
import ViewIcon from '../../../assets/images/view_icon.png'
import EditIcon from '../../../assets/images/edit_icon.png'
import ScreenActionIcon from '../../../assets/images/screeing_action.png'


function BankDetails() {
    return (
        <div>
            <div className='d-flex align-items-center justify-content-end mt-3'>
                <img src={EditIcon} style={{ width: 15, objectFit: 'contain', cursor: 'pointer' }} />
            </div>
            <div className='d-flex align-items-center'>
                <img src={BankDetailsIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                <h2 className='text-success text-center'>Bank Details</h2>
            </div>
            <hr />
            <div className='custom-table'>
                <div className='custom-table-head'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <h6 className='text-blue'>Bank Name</h6>
                        </div>
                        <div className='col-lg-3'>
                            <h6 className='text-blue'>Account No:</h6>
                        </div>
                        <div className='col-lg-3'>
                            <h6 className='text-blue'>Sort Code</h6>
                        </div>
                        <div className='col-lg-2'>
                            <h6 className='text-blue'>Swift/BIC Code</h6>
                        </div>
                        <div className='col-lg-1'>
                            <h6 className='text-blue'>Action</h6>
                        </div>
                    </div>
                </div>
                {[1, 2, 3, 4, 5, 6, 7, 8].map(() =>
                    <div className='custom-table-body'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <h6 className='text-black'>Kotak</h6>
                            </div>
                            <div className='col-lg-3'>
                                <h6 className='text-black'>12345676678</h6>
                            </div>
                            <div className='col-lg-3'>
                                <h6 className='text-black'>123333</h6>
                            </div>
                            <div className='col-lg-2'>
                                <h6 className='text-black'>123333</h6>
                            </div>
                            <div className='col-lg-1'>
                                <img src={ViewIcon} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                            </div>
                        </div>
                    </div>
                )}

            </div>
            <div className='d-flex align-items-center justify-content-end py-3'>
                <span class="badge bg-primary-badge">1</span>
                <span class="badge">2</span>
                <span class="badge">3</span>
                <span class="badge">4</span>
                <span class="badge"><i class="fa fa-chevron-right"></i></span>
            </div>

            <div className='white-background p-3 mb-3'>
                <div className='d-flex align-items-center'>
                    <img src={ScreenActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                    <h2 className='text-ambed text-center'>Add Comments</h2>
                </div>
                <hr />
                <div className="d-flex mb-2">
                    <div style={{ marginRight: 30 }}>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label" for="flexRadioDefault1">Open</label>
                        </div>
                    </div>
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label" for="flexRadioDefault1">Ghost</label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='row align-items-end'>
                            <div className='col-lg-9' style={{ width: '80%' }}>
                                <div className="form-group mt-3">
                                    <label className="form-label" for="exampleInputEmail1">Add Comment</label>
                                    <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Add Comment" rows="2" />
                                </div>
                            </div>
                            <div className='col-lg-3' style={{ width: '20%' }}>
                                <button className='btn-primary fw-normal'>Add Comment</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BankDetails
