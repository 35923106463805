import React, { useEffect, useState } from 'react'
import BankDetailsIcon from '../../../assets/images/bank_details.png'
import LinkCompanyIcon from '../../../assets/images/Link_company.png'
import ViewIcon from '../../../assets/images/view_icon.png'
import EditIcon from '../../../assets/images/edit_icon.png'
import ScreenActionIcon from '../../../assets/images/screeing_action.png'
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '../../../assets/images/Cancel_icon.png'
import BankDetails from '../../../assets/images/bank_details.png'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { addScreeningComments, getEntityClientBank, getEntityClientBankDetails, updateIndividualPersonalDetailsWithReason } from '../IndividualScreeningSlice'
import Loader from '../../../components/Loader/Loader'
import { useForm } from 'react-hook-form'
import * as  yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { decodeToken, useJwt } from "react-jwt";

function IndividualBankDetails({entityClientId, viewOnly}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    const { isLoading, entityClientBanks, entityClientBankDetails } = useSelector(state => state.individualScreeningReducer);
    const [currentValues, setCurrentValues] = useState(entityClientBankDetails);
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);

    const bankSchema = yup.object().shape({
        bank_name: yup.string().required('Bank name is required'),
        account_no: yup.string().required('Account No is required').typeError('Invalid Account No'),
        sort_code: yup.string().required('Sort Code  is required'),
        iban: yup.string().typeError('Invalid Country').required('IBAN is required'),
        bic_code: yup.string().required('Bic code is required'),
        address_type: yup.string().required('Address type is required'),
        address: yup.string().required('Address is required'),
        reason: yup.string().required('Reason is required'),
    });

    const { control, register, handleSubmit, formState: { errors }, setValue, watch, reset } = useForm({
        resolver: yupResolver(bankSchema),
    });

    const commentSchema = yup.object().shape({
        commentType: yup.string().required('Comment Type is required'),
        comment: yup.string().required('Comment is required'),
    });

    const { control: controlCommentForm, register: registerCommentForm, handleSubmit: handleSubmitCommentForm, formState: { errors: errorsCommentForm }, reset: resetCommentForm, setValue: setValueComment } = useForm({
        resolver: yupResolver(commentSchema),
    });

    useEffect(() => {
        setCurrentValues(entityClientBankDetails);
    }, [entityClientBankDetails]);

    useEffect(() => {
        if (currentValues?.bank_name) setValue('bank_name', currentValues.bank_name);
        if (currentValues?.account_no) setValue('account_no', currentValues.account_no);
        if (currentValues?.iban) setValue('iban', currentValues.iban);
        if (currentValues?.sort_code) setValue('sort_code', currentValues.sort_code);
        if (currentValues?.address_type) setValue('address_type', currentValues.address_type);
        if (currentValues?.bic_code) setValue('bic_code', currentValues.bic_code);
        if (currentValues?.address) setValue('address', currentValues.address);
    }, [currentValues]);

    useEffect(() => {
        if (entityClientId) {
            dispatch(getEntityClientBank(`?entity_client_id=${entityClientId}`)).unwrap().catch((error) => {
                toast.error(error?.message);
            });
        }
    }, [entityClientId]);

    const handleChange = (field, value, displayName) => {
        setCurrentValues({
            ...currentValues,
            [field]: (displayName) ? `${displayName}@|${value}` : value
        });
    };

    const onEditClick = (id) => {
        reset();
        if (id) {
            dispatch(getEntityClientBankDetails(`?entity_client_id=${entityClientId}&entity_bank_id=${id}`)).unwrap().then((res) => {
                setValue('id', id);
                setCurrentValues(res?.data);
                handleShow();
            }).catch((error) => {
                toast.error(error?.message);
            });
        }
    }

    const onFormSubmit = (data) => {
        const changes = Object.entries(currentValues).filter(([field, value]) => {
            const valueAsString = String(value);
            const actualValue = valueAsString.includes('@|') ? value.split('@|')[1] : valueAsString;
            return actualValue !== String(entityClientBankDetails[field]);
        }).map(([field, value]) => ({ 
            column_name : field,
            old_value : entityClientBankDetails[field],
            new_value : String(value).includes('@|') ? String(value).split('@|')[1] : value,
            screen : "bank_details",
            reason : data?.reason
        }));

        if (changes.length > 0) {
            dispatch(updateIndividualPersonalDetailsWithReason({ payload: {
                entity_client_banks : changes
            }, queryParam: `?entityClientId=${entityClientId}&entityClientBankId=${data?.id}` })).unwrap().then((obj) => {
                toast.success(obj?.message);
                handleClose();
                reset();
                dispatch(getEntityClientBank(`?entity_client_id=${entityClientId}`)).unwrap().catch((error) => {
                    toast.error(error?.message);
                });
            }).catch(error => {
                toast.error(error?.message || 'Unable to update the record');
            });
        } else {
            toast.info("No Fields were updated");
        }
    }

    const onCommentFormSubmit = (data) => {
        resetCommentForm();
        dispatch(addScreeningComments({payload:data})).unwrap().then((obj)=>{
            toast.success(obj?.message || 'Comment Added Successfully');
        }).catch(error => {
            toast.error(error.message || 'Unable to add comments');
        });
    }

    return (
        <>
            {isLoading && <Loader/>}
            <div>
                <div className='d-flex align-items-center justify-content-end mt-3'>
                    {/* <img src={EditIcon} style={{ width: 15, objectFit: 'contain', cursor: 'pointer' }} /> */}
                </div>
                <div className='d-flex align-items-center'>
                    <img src={BankDetailsIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                    <h2 className='text-success text-center'>Bank Details</h2>
                </div>
                <hr />
                <div className='custom-table pb-3'>
                    <div className='custom-table-head'>
                        <div className='row'>
                            <div className='col-lg-3 col-sm-2'>
                                <h6 className='text-blue'>Bank Name</h6>
                            </div>
                            <div className='col-lg-2 col-sm-2'>
                                <h6 className='text-blue'>Account No:</h6>
                            </div>
                            <div className='col-lg-2 col-sm-2'>
                                <h6 className='text-blue'>Sort Code</h6>
                            </div>
                            <div className='col-lg-2 col-sm-2'>
                                <h6 className='text-blue'>Swift/BIC Code</h6>
                            </div>
                            <div className='col-lg-2 col-sm-2'>
                                <h6 className='text-blue'>IBAN</h6>
                            </div>
                            {
                                !viewOnly && <div className='col-lg-1 col-sm-2'>
                                <h6 className='text-blue'>Action</h6>
                            </div>
                            }                            
                        </div>
                    </div>
                    {entityClientBanks.map((obj) =>
                        <div className='custom-table-body' key={obj.id}>
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <h6 className='text-black'>{obj?.bank_name}</h6>
                                </div>
                                <div className='col-lg-2'>
                                    <h6 className='text-black'>{obj?.account_no}</h6>
                                </div>
                                <div className='col-lg-2'>
                                    <h6 className='text-black'>{obj?.sort_code}</h6>
                                </div>
                                <div className='col-lg-2'>
                                    <h6 className='text-black'>{obj?.bic_code}</h6>
                                </div>
                                <div className='col-lg-2'>
                                    <h6 className='text-black'>{obj?.iban}</h6>
                                </div>
                                {
                                    !viewOnly && <div className='col-lg-1'>
                                        <img src={EditIcon} onClick={() => onEditClick(obj?.id)} style={{ width: 15, objectFit: 'contain', cursor: 'pointer' }} />
                                    </div>
                                }                                
                            </div>
                        </div>
                    )}

                </div>

                { !viewOnly &&
                <div className='white-background p-3 mb-3'>
                    <form className='form' onSubmit={handleSubmitCommentForm(onCommentFormSubmit)}>
                        <div className='d-flex align-items-center'>
                            <img src={ScreenActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                            <h2 className='text-ambed text-center'>Add Comments</h2>
                        </div>
                        <hr />
                        <div className={`d-flex mb-2 ${errorsCommentForm?.commentType ? 'is-invalid' : ''}`}>
                            { (decodedToken?.roles?.permissions?.add_general_comment || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
                                <div style={{ marginRight: 30 }}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" {...registerCommentForm('commentType')} id="flexRadioDefault2" value="open"/>
                                        <label className="form-check-label" for="flexRadioDefault1">Open</label>
                                    </div>
                                </div>
                            }
                            { (decodedToken?.roles?.permissions?.add_aml_comment || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
                                <div style={{ marginRight: 30 }}>
                                    <div className="form-check">
                                    <input className="form-check-input" type="radio" {...registerCommentForm('commentType')} id="flexRadioDefault1" value="aml" />
                                        <label className="form-check-label" for="flexRadioDefault1">AML</label>
                                    </div>
                                </div>
                            }
                            {
                            (decodedToken?.roles?.permissions?.add_ghost_comment || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
                                <div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" {...registerCommentForm('commentType')} id="flexRadioDefault3" value="ghost"/>
                                        <label className="form-check-label" for="flexRadioDefault1">Ghost</label>
                                    </div>
                                </div>
                            }
                        </div>

                        <input type="hidden" {...registerCommentForm(`entityClientId`, { value: entityClientId })} />
                        <input type="hidden" {...registerCommentForm(`screen`, { value: 'bank_details' })} />

                        {errorsCommentForm?.commentType &&
                            <span className="invalid-feedback" >Comment Type is required</span>
                        }

                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='row align-items-end'>
                                    <div className='col-lg-9' style={{ width: '80%' }}>
                                        <div className="form-group mt-3">
                                            <label className="form-label" for="exampleInputEmail1">Add Comment</label>
                                            <textarea type="text" {...registerCommentForm('comment')} className={`form-control ${errorsCommentForm?.comment ? 'is-invalid' : ''}`} id="exampleInputEmail1" placeholder="Add Comment" rows="2" />
                                            {errorsCommentForm?.comment &&
                                                <span className="invalid-feedback" >Comment is required</span>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-lg-3' style={{ width: '20%' }}>
                                        <button className='btn-primary fw-normal w-100'>Add Comment</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                }
            </div>
            <Modal show={show} size='md' onHide={handleClose} centered scrollable style={{ maxHeight: '90%', marginTop: 50 }}>
                <Modal.Body className=' mt-2 pb-3 p-4'>
                    {/* <div className="modal-form">
                        <div className='form-group'>
                            <h3 className="modal-title mb-2 pb-2">Add Comment !</h3>
                            <h6 className='text-black'>Are you sure you want to add this comment</h6>
                        </div>
                    </div> */}
                    {/* <div className='d-flex align-items-center'>
                        <img src={BankDetails} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                        <h1 className='text-success fw-normal'>Bank Details</h1>
                    </div> */}
                    <div className='form'>
                        <form className='form' onSubmit={handleSubmit(onFormSubmit)}>
                            <div className='d-flex align-items-center'>
                                <img src={BankDetails} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                                <h1 className='text-success fw-normal'>Bank Details</h1>
                            </div>
                            <input type="hidden" {...register("id")} />
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <div className="form-group mt-2">
                                        <label className="form-label" htmlFor="bank_name">Bank Name</label>
                                        {/* <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" /> */}
                                        <input type="text" className={`form-control ${errors.bank_name ? 'is-invalid' : ''}`} placeholder="Bank name" {...register("bank_name")} onChange={(e) => {
                                            handleChange('bank_name', e.target.value); // Your custom onChange handler
                                        }} />
                                        {errors?.bank_name && (
                                            <div className="invalid-feedback">{errors?.bank_name?.message}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12'>
                                    <div className="form-group mt-3">
                                        <label className="form-label" htmlFor="account_no">Account No</label>
                                        {/* <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" /> */}
                                        <input type="text" className={`form-control ${errors.account_no ? 'is-invalid' : ''}`} placeholder="Account No" {...register("account_no")} onChange={(e) => {
                                            handleChange('account_no', e.target.value); // Your custom onChange handler
                                        }}/>
                                        {errors?.account_no && (
                                            <div className="invalid-feedback">{errors?.account_no?.message}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <div className="form-group mt-3">
                                        <label className="form-label" htmlFor="sort_code">Sort Code</label>
                                        {/* <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" /> */}
                                        <input type="text" className={`form-control ${errors.sort_code ? 'is-invalid' : ''}`} placeholder="Sort Code" {...register("sort_code")} onChange={(e) => {
                                            handleChange('sort_code', e.target.value); // Your custom onChange handler
                                        }}/>
                                        {errors?.sort_code && (
                                            <div className="invalid-feedback">{errors?.sort_code?.message}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <div className="form-group mt-3">
                                        <label className="form-label" htmlFor="bic_code">Swift/BIC Code</label>
                                        {/* <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" /> */}
                                        <input type="text" className={`form-control ${errors.bic_code ? 'is-invalid' : ''}`} placeholder="Swift/BIC Code" {...register("bic_code")} onChange={(e) => {
                                            handleChange('bic_code', e.target.value); // Your custom onChange handler
                                        }}/>
                                        {errors?.bic_code && (
                                            <div className="invalid-feedback">{errors?.bic_code?.message}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <div className="form-group mt-3">
                                        <label className="form-label" htmlFor="iban">IBAN</label>
                                        {/* <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" /> */}
                                        <input type="text" className={`form-control ${errors.iban ? 'is-invalid' : ''}`} placeholder="IBAN" {...register("iban")} onChange={(e) => {
                                            handleChange('iban', e.target.value); // Your custom onChange handler
                                        }}/>
                                        {errors?.iban && (
                                            <div className="invalid-feedback">{errors?.iban?.message}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <div className="form-group mt-3">
                                        <label className="form-label">Address Registered with Bank</label>
                                        {/* <div className={`col-12 form-control ${errors?.address_type ? 'is-invalid' : ''}`}> */}
                                        <div className={`col-12 form-control ${errors?.address_type ? 'is-invalid' : ''}`}>
                                            <div className="row">
                                                <div className='col-4'>
                                                    <div className='border-grey-form'>
                                                        <div className="form-check">
                                                            <div className='row'>
                                                                <div className='col-2 pe-0'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        type="radio"
                                                                        value="home"
                                                                        id="home"
                                                                        style={{ marginRight: 0 }}
                                                                        {...register("address_type")}
                                                                        onChange={(e) => {
                                                                            handleChange('address_type', 'home'); // Your custom onChange handler
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='col-10'>
                                                                    <label className="form-check-label" htmlFor="home">Home Address</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='border-grey-form'>
                                                        <div className="form-check">
                                                            <div className='row'>
                                                                <div className='col-2 pe-0'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        type="radio"
                                                                        value="sole"
                                                                        id="sole"
                                                                        style={{ marginRight: 0 }}
                                                                        {...register("address_type")}
                                                                        onChange={(e) => {
                                                                            handleChange('address_type', 'sole'); // Your custom onChange handler
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='col-10'>
                                                                    <label className="form-check-label" htmlFor="sole">Sole Trader</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='border-grey-form'>
                                                        <div className="form-check">
                                                            <div className='row'>
                                                                <div className='col-2 pe-0'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        type="radio"
                                                                        value="other"
                                                                        id="other"
                                                                        style={{ marginRight: 0 }}
                                                                        {...register("address_type")}
                                                                        onChange={(e) => {
                                                                            handleChange('address_type', 'other'); // Your custom onChange handler
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='col-10'>
                                                                    <label className="form-check-label " htmlFor="other" >Other</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {errors?.address_type && (
                                            <div className="invalid-feedback">{errors?.address_type?.message}</div>
                                        )}

                                        <textarea className={`form-control mt-4 ${errors.address ? 'is-invalid' : ''}`} placeholder="Address" {...register("address")} onChange={(e) => {
                                            handleChange('address', e.target.value);
                                        }} rows="4"></textarea>
                                        {/* <textarea type="text" className="form-control mt-3" id="exampleInputEmail1" placeholder="" rows="2" /> */}
                                        {errors?.address && (
                                            <div className="invalid-feedback">{errors?.address?.message}</div>
                                        )}
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <div className="form-group mt-3">
                                                    <label className="form-label" htmlFor="reason">Reason</label>
                                                    {/* <input type="text" className="form-control" id="exampleInputEmail1" placeholder="" /> */}
                                                    <input type="text" className={`form-control ${errors.reason ? 'is-invalid' : ''}`} placeholder="Enter Reason" {...register("reason")} />
                                                    {errors?.reason && (
                                                        <div className="invalid-feedback">{errors?.reason?.message}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='d-flex align-items-center justify-content-between mt-3'>
                                {/* <button className='btn-secondary fw-normal' style={{ marginRight: 16 }}>Link Another Account</button> */}
                                <button type="submit" className='btn-primary'>Save</button>
                            </div>
                        </form>
                    </div>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 6, marginRight: 8, cursor: 'pointer' }} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default IndividualBankDetails
