import loginReducer from "../pages/Login/loginSlice";
import taxSliceReducer from "../pages/Tax/taxSlice";
import planSliceReducer from "../pages/Pricing/planSlice";
import registerReducer from "../pages/Registration/registrationSlice";
import roleReducer from "../pages/UsersRoles/roleSlice";
import fxRatesReducer from "../pages/FxRates/fxRatesSlice";
import manageUserReducer from "../pages/ManageUsers/manageUserSlice";
import myAccountReducer from "../pages/MyAccount/myAccountSlice";
import manageClientReducer from "../pages/ManageClients/manageClientSlice";
import MyInvoicesSliceReducer from "../pages/MyAccount/invoiceSlice"
import ProfileReducer from "../pages/Profile/profileSlice"
import viewClientBillingReducer from "../pages/ViewClientBilling/viewClientBillingSlice";
import individualScreeningReducer from "../pages/IndividualScreening/IndividualScreeningSlice";
import individualViewedScreeningReducer from "../pages/ViewScreeningDetails/individual/individualViewedScreeningSlice";
import dashboardReducer from "../pages/Dashboard/DashboardSlice";
import corporateOnboardingSlice from "../pages/Profile/Corporate/corporateSlice"

export const reducers = {
    loginReducer,
    registerReducer,
    taxSliceReducer,
    planSliceReducer,
    fxRatesReducer,
    myAccountReducer,
    roleReducer,
    manageUserReducer,
    manageClientReducer,
    MyInvoicesSliceReducer,
    ProfileReducer,
    viewClientBillingReducer,
    individualScreeningReducer,
    individualViewedScreeningReducer,
    dashboardReducer,
    corporateOnboardingSlice
}