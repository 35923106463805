import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { decodeToken } from "react-jwt";

import SingleCard from "../../components/Card/Card";
import user from '../../assets/images/user.png'
import user_2 from '../../assets/images/user_2.png'
import building_1 from '../../assets/images/building_1.png'
import building_2 from '../../assets/images/building_2.png'
import document_1 from '../../assets/images/document_1.png'
import document_2 from '../../assets/images/document_2.png'
import ApproveTick from '../../assets/images/Approve_tick.png'
import CancelIcon from '../../assets/images/Cancel_icon.png'
import './Profile.scss';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Loader from '../../components/Loader/Loader';
import { countryList } from '../Registration/registrationSlice';
import { toast } from 'react-toastify';
import countryCodes from '../Registration/countryCode.json';
import { encryptQueryParam } from '../../utils/EncryptionUtils';
import { generateOnboardingInviteLink } from './profileSlice';
import Select from 'react-select';

function CreateProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { isLoading: countryLoading, countryData } = useSelector(state => state.registerReducer);
  const { isLoading } = useSelector(state => state.ProfileReducer);
  const [show, setShow] = useState(false);

  const handleClose = () => { setShow(false); setSelectedCountry(null) };
  const handleShow = () => setShow(true);
  const handleCloseModal = () => { setShowModal(false); setValue("countryId", null); };
  const handleShowModal = () => {
    if (selectedCountry) {
      setShowModal(true);
      handleClose(false);
      reset();
      setValue("countryId", selectedCountry);
    } else {
      setValue("countryId", null);
      toast.error("Please select country first");
    }
  }
  const userToken = localStorage.getItem('token');
  const decodedToken = decodeToken(userToken);

  const handleUpdate = (formData) => {
    setShow(false);
  };

  const handleOptionChange = (e) => {
    setSelectedCountry(e.target.value);

  };

  const routeIndividualPersonalDetails = () => {
    if (selectedCountry) {
      let path = `/profile/individual_personal_details`;
      navigate(path, { state: { countryId: selectedCountry } });
    } else {
      toast.error("Please select country first");
    }
  }

  const routeCompanyDetails = () => {
    let path = `/profile/corporate_company_details`;
    navigate(path);
  }

  useEffect(() => {
    dispatch(countryList());
  }, []);

  const schemaIndividualInviteLink = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Invalid email address').matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email address').required('Email is required'),
    mobileCode: yup.string().required('Mobile code is required'),
    mobileNumber: yup
        .number()
        .typeError('Mobile Number must be a number')
        .required('Mobile No is required')
        .test(
          'len',
          'Mobile No must be at most 15 digits',
          val => val && val.toString().length <= 15
        ),
    countryId: yup.number().typeError('Invalid Country ID').required('Country id is required'),
  });

  const { control, register, handleSubmit, formState: { errors }, reset, setValue, trigger, getValues } = useForm({
    resolver: yupResolver(schemaIndividualInviteLink),
  });
  const mobileCodeValue = getValues('mobileCode');
  const sendIndividualInviteLink = async (formData) => {
    const requestData = await { ...formData, userId: decodedToken?.userId };
    dispatch(generateOnboardingInviteLink(requestData)).unwrap().then(async (res) => {
      toast.success(res?.message);
      handleCloseModal();
    }).catch((error) => {
      toast.error(error?.message);
    })
  }
  const customStyles = {
    control: (provided) => ({
        ...provided,
        borderRadius: '10px',
        border: '2px solid #D9D9D9',
        boxShadow: 'none',
        '&:hover': {
            border: '2px solid #B3B3B3',
        },
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: '10px',
        border: '2px solid #D9D9D9',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#B3B3B3',
    }),
};
  return (
    <div className='cards_container grey-background'>
  {(isLoading || countryLoading) && <Loader />}
      <div className='title'>
        <p>Choose from the following to continue</p>
      </div>
      <hr />
      <div className='row g-4'>
          { (decodedToken?.roles?.permissions?.create_individual_onboarding || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
        <div className='col-xl-6 col-lg-6 col-md-12'>
          <SingleCard
            image={user}
            cardBackgroundColor='#FFFFFF'
            backgroundColor='#E6F8F2'
            title='CREATE INDIVIDUAL'
            onClick={handleShow}
          />
        </div>
          }

      { (decodedToken?.roles?.permissions?.create_business_onboarding || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
        <div className='col-xl-6 col-lg-6 col-md-12'>
          <SingleCard
            image={building_1}
            cardBackgroundColor='#FFFFFF'
            backgroundColor='#E6F2FB'
            title='ONBOARDING COMPANY'
            // description='Coming soon'
          onClick={routeCompanyDetails}
          />
        </div>
      }
      { (decodedToken?.roles?.permissions?.create_asset_onboarding || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
        <div className='col-xl-6 col-lg-6 col-md-12'>
          <SingleCard
            image={user_2}
            cardBackgroundColor='#ccc9c9'
            backgroundColor='#E6F2FB'
            title='CREATE ASSET'
            description='Coming Soon'
          />
        </div>
      }
      { (decodedToken?.roles?.permissions?.onboard_using_oomero_id || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
        <div className='col-xl-6 col-lg-6 col-md-12'>
          <SingleCard
            image={building_2}
            cardBackgroundColor='#FFFFFF'
            backgroundColor='#FFF6E9'
            title='ONBOARD USING OOMERO ID'
          />
        </div>
        }
      </div>

      <Modal show={show} onHide={handleClose} centered >
        <Modal.Header className='model-header mt-2 pb-3'>
          <div className="modal-form">
            <div className='form-group'>
              <h3 className="modal-title mb-2 pb-2 text-center">Select Country</h3>
              <Select
                placeholder="Select Country"
                className={`${errors.countryId ? 'is-invalid' : ''}`}
                options={countryData.map(country => ({
                  label: country.name,
                  value: country.country_id
                }))}
                styles={customStyles}
                value={selectedCountry ? { label: countryData.find(country => country.country_id === selectedCountry)?.name, value: selectedCountry } : null}
                onChange={(selectedOption) => {
                  setSelectedCountry(selectedOption ? selectedOption.value : null);
                }}
              />
            </div>
          </div>
          <div onClick={handleClose} className='position-absolute top-0 end-0'>
            <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 6, marginRight: 8, cursor: 'pointer' }} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <h3 className='text-blue text-center mt-2 mb-0'>How would you like to onboard a client ?</h3>
        </Modal.Body>
        <Modal.Footer >
          <div className='footer-btn d-flex align-items-center justify-content-between'>
            <div type="button" className="coustom-btn" onClick={routeIndividualPersonalDetails} >
              <div className='manual-btn d-flex align-items-center justify-content-center'>
                <div className='btn_img' style={{ backgroundColor: "#FFF6E9" }}>
                  <img src={document_1}
                    style={{ width: "20px", height: "20px" }}
                    alt="document_1" />
                </div>
                <div>
                  <p className='btn-title mb-0'>Manual</p>
                </div>
              </div>
            </div>
            <div type="button" className="coustom-btn" onClick={handleShowModal} >
              <div className='manual-btn d-flex align-items-center justify-content-center'>
                <div className='btn_img' style={{ backgroundColor: "#E6F8F2" }}>
                  <img src={document_2}
                    style={{ width: "20px", height: "20px" }}
                    alt="document_2" />
                </div>
                <div>
                  <p className='btn-title mb-0'>Webform</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <form onSubmit={handleSubmit(sendIndividualInviteLink)}>
          <Modal.Header >
            <div className='modal-form form-group'>
              <h5 className="modal-title text-center"><b>Enter Details</b></h5>
            </div>

            <div onClick={handleCloseModal} className='position-absolute top-0 end-0'>
              <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className='col-md-6 mb-3'>
                <input type="text" className={`form-control form-control-lg ${errors.firstName ? 'is-invalid' : ''}`}
                  placeholder="First Name"
                  {...register('firstName', {
                    required: `Please Enter First Name`
                  })}
                />
                {errors?.firstName && (
                  <div className="invalid-feedback">{errors?.firstName?.message}</div>
                )}
              </div>
              <div className='col-md-6 mb-3'>
                <input type="text" className={`form-control form-control-lg ${errors.lastName ? 'is-invalid' : ''}`}
                  placeholder="Last Name"
                  {...register('lastName', {
                    required: `Please Enter Last Name`
                  })}
                />
                {errors?.lastName && (
                  <div className="invalid-feedback">{errors?.lastName?.message}</div>
                )}
              </div>
              <div className="form-group mb-3">
                <input type="text" className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`}
                  placeholder="abcd@gmail.com"
                  {...register('email', {
                    required: `Please Enter email`
                  })}
                />
                {errors?.email && (
                  <div className="invalid-feedback">{errors?.email?.message}</div>
                )}
              </div>
              <div className='row pe-0'>
                <div className='col-lg-4 col-md-4'>
                  <Controller
                    name="mobileCode"
                    control={control}
                    render={({ field }) => (
                      <Select
                        className={` ${errors.mobileCode ? 'is-invalid' : ''}`}
                        {...field}
                        options={countryData.map(country => ({
                          label: `${country.name} (${country.country_code})`,
                          value: country.country_code
                        }))}
                        value={mobileCodeValue ? { label: `${mobileCodeValue} `, value: mobileCodeValue} : ''}
                        onChange={(selectedOption) => {
                          setValue('mobileCode', selectedOption?.value);
                          trigger('mobileCode');
                      }}
                      />
                    )}
                  />
                  {errors?.mobileCode && (
                    <div className="invalid-feedback">{errors?.mobileCode?.message}</div>
                  )}
                </div>
                <div className='col-lg-8 col-md-8 pe-0'>
                  <input
                    type="text"
                    className={`form-control form-control-lg ${errors.mobileNumber ? 'is-invalid' : ''}`}
                    placeholder="000 000 0000"
                    name="mobileNumber"
                    {...register('mobileNumber')}
                  />
                  {errors?.mobileNumber && (
                    <div className="invalid-feedback">{errors?.mobileNumber?.message}</div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer >
            <button type="submit" className="btn btn-primary w-50 mb-3">Submit</button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default CreateProfile