import React from 'react'
import DirectorListIcon from '../../../assets/images/documnet_verification.png'
import { useNavigate } from 'react-router-dom';

function DirectorAndShareholderList() {
    const navigate = useNavigate();
    const individualScreeningDetails = () => {
        // let path = `/individual_screening/individual_screening_details`;
        // navigate(path);
    }
    return (
        <div>
            <div className='d-flex align-items-center mt-3'>
                <img src={DirectorListIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                <h2 className='text-success text-center'>List of Directors</h2>
            </div>
            <hr />
            <div className='custom-table mb-5'>
                <div className='custom-table-head'>
                    <div className='row'>
                        <div className='col-lg-2'>
                            <h6 className='text-blue'>Oomero ID</h6>
                        </div>
                        <div className='col-lg-2'>
                            <h6 className='text-blue'>Name</h6>
                        </div>
                        <div className='col-lg-2'>
                            <h6 className='text-blue'>Email</h6>
                        </div>
                        <div className='col-lg-2'>
                            <h6 className='text-blue'>Status</h6>
                        </div>
                        <div className='col-lg-2'>
                            <h6 className='text-blue'>Screening</h6>
                        </div>
                        <div className='col-lg-2'>
                            <h6 className='text-blue'>Action</h6>
                        </div>
                    </div>
                </div>
                {[
                    { status: 'Green', statusColor: '#037924', statusBg: '#A9FFC1' },
                    { status: 'Red', statusColor: '#ED4337', statusBg: '#FFD0CD' },
                    { status: 'Green', statusColor: '#037924', statusBg: '#A9FFC1' },
                    { status: 'Red', statusColor: '#ED4337', statusBg: '#FFD0CD' },
                    { status: 'Amber', statusColor: '#9C6802', statusBg: '#FFED8F' },
                ].map((item, i) =>
                    <div className='custom-table-body'>
                        <div className='row'>
                            <div className='col-lg-2'>
                                <h4 className='text-black'>OLP123456111111</h4>
                            </div>
                            <div className='col-lg-2'>
                                <h4 className='text-black'>Zaheer Shaikh</h4>
                            </div>
                            <div className='col-lg-2'>
                                <h4 className='text-black'>Corem@gmail.com</h4>
                            </div>
                            <div className='col-lg-2'>
                                <p className='statusBtn' style={{ backgroundColor: item.statusBg, color: item.statusColor }}>{item.status}</p>
                            </div>
                            <div className='col-lg-2'>
                                <p  onClick={individualScreeningDetails} style={{ backgroundColor: '#E6F2FB', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'fit-content', fontSize: 14, cursor:'pointer' }} className='text-blue mb-0'>Start Screening</p>
                            </div>
                            <div className='col-lg-2'>
                                <p style={{ backgroundColor: '#E6F2FB', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'fit-content', fontSize: 14, }} className='text-blue mb-0'>Re-KYC</p>
                            </div>
                        </div>
                    </div>
                )}

            </div>

            <div className='d-flex align-items-center mt-5'>
                <img src={DirectorListIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                <h2 className='text-success text-center'>List of Shareholders</h2>
            </div>
            <hr />
            <div className='custom-table'>
                <div className='custom-table-head'>
                    <div className='row'>
                        <div className='col-lg-2'>
                            <h6 className='text-blue'>Oomero ID</h6>
                        </div>
                        <div className='col-lg-2'>
                            <h6 className='text-blue'>Name</h6>
                        </div>
                        <div className='col-lg-2'>
                            <h6 className='text-blue'>Email</h6>
                        </div>
                        <div className='col-lg-2'>
                            <h6 className='text-blue'>Status</h6>
                        </div>
                        <div className='col-lg-2'>
                            <h6 className='text-blue'>Screening</h6>
                        </div>
                        <div className='col-lg-2'>
                            <h6 className='text-blue'>Action</h6>
                        </div>
                    </div>
                </div>
                {[
                    { status: 'Green', statusColor: '#037924', statusBg: '#A9FFC1' },
                    { status: 'Red', statusColor: '#ED4337', statusBg: '#FFD0CD' },
                    { status: 'Green', statusColor: '#037924', statusBg: '#A9FFC1' },
                    { status: 'Red', statusColor: '#ED4337', statusBg: '#FFD0CD' },
                    { status: 'Amber', statusColor: '#9C6802', statusBg: '#FFED8F' },
                ].map((item, i) =>
                    <div className='custom-table-body'>
                        <div className='row'>
                            <div className='col-lg-2'>
                                <h4 className='text-black'>OLP123456111111</h4>
                            </div>
                            <div className='col-lg-2'>
                                <h4 className='text-black'>Zaheer Shaikh</h4>
                            </div>
                            <div className='col-lg-2'>
                                <h4 className='text-black'>Corem@gmail.com</h4>
                            </div>
                            <div className='col-lg-2'>
                                <p className='statusBtn' style={{ backgroundColor: item.statusBg, color: item.statusColor }}>{item.status}</p>
                            </div>
                            <div className='col-lg-2'>
                                <p  onClick={individualScreeningDetails} style={{ backgroundColor: '#E6F2FB', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'fit-content', fontSize: 14, cursor:'pointer' }} className='text-blue mb-0'>Start Screening</p>
                            </div>
                            <div className='col-lg-2'>
                                <p style={{ backgroundColor: '#E6F2FB', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'fit-content', fontSize: 14, }} className='text-blue mb-0'>Re-KYC</p>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}

export default DirectorAndShareholderList
