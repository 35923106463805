import logo from './logo.svg';
import './App.scss';
import { BrowserRouter, Routes, Route, useLocation, useNavigate, Link } from "react-router-dom";
import { loginRoutes, routes } from './utils/routes';
import NewSidebar from './components/Sidebar/NewSidebar';
import TopBar from './components/Topbar/Topbar';
import OomeroUtils from './utils/OomeroUtils';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const location = useLocation();
  return (
    <>
    <OomeroUtils.ScrollToTop/>
      {/* {
        !location.pathname.includes('/login') && !location.pathname.includes('/forgetpassword') &&
        !location.pathname.includes('/resetpassword') && !location.pathname.includes('/registration') &&
        !location.pathname.includes('/change_password') &&
        !location.pathname.includes('/welcome_screen') &&
        !location.pathname.includes('/webform_personal_details') &&
        !location.pathname.includes('/webform_address') &&
        !location.pathname.includes('/webform_bank_details') &&
        !location.pathname.includes('/webform_kyc_verification') &&
        !location.pathname.includes('/webform_appication_done') &&
        !location.pathname.includes('/Corporate_welcome_screen') &&
        !location.pathname.includes('/corporate_webform_information') &&
        !location.pathname.includes('/corporate_webform_bankdetails') &&
        !location.pathname.includes('/corporate_webform_directorlist') &&
        !location.pathname.includes('/webform_add_director_personl_details') &&
        !location.pathname.includes('/corporate_add_director_address') &&
        !location.pathname.includes('/corporate_add_director_bank_details') &&
        !location.pathname.includes('/corporate_webform_shareholder_list') &&
        !location.pathname.includes('/corporate_add_shareholde_personal_details') &&
        !location.pathname.includes('/corporate_add_shareholde_address') &&
        !location.pathname.includes('/corporate_add_shareholder_bank_details') &&
        !location.pathname.includes('/corporate_webform_members_list') &&
        !location.pathname.includes('/corporate_webform_application_done') &&
        !location.pathname.includes('/corporate_add_company_shareholder') &&
        <>
          <TopBar />
          <div className='row main-app'style={{ display: "flex", height: "100vh" }}>
            <NewSidebar />
          </div>
        </>
      } */}
      <Routes>
        {
          loginRoutes.map((route, key) => {
            return <Route key={key} {...route} />
          })
        }
        {
          routes.map((route, key) => {
            return <Route key={key} {...route} />
          })
        }
      </Routes>
      <ToastContainer/>
    </>
  );
}

export default App;
