import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { setUserPassword } from '../ManageUsers/manageUserSlice';
import './Login.scss'
import Logo from '../../assets/images/logo.png'
import ApproveTick from '../../assets/images/Approve_tick.png'
import LoginLeftImage from '../../assets/images/login_left_image.png'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { useForm } from 'react-hook-form';

function ResetPassword() {
    const [show, setShow] = useState(false);
    const [passwordVisibility, setPasswordVisibility] = useState({
        new_password: "password",
        confirm_password: "password"
    });
    
    const [passwordInputs, setPasswordInputs] = useState({
        new_password: "",
        confirm_password: ""
    });
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const INVITELINK = params.get('INVITE_LINK');
    const { isLoading } = useSelector(state => state.manageUserReducer);
    const [inviteCode, setInviteCode] = useState(null);

    const schema = yup.object().shape({
        new_password: yup.string().required('Password is required').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/,
            'Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.'
        ),
        confirm_password: yup.string().required('Password is required').oneOf([yup.ref('new_password'), null], 'Passwords must match').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/,
            'Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.'
        ),
    });

    const { control, register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    const routeChange = () => {
        localStorage.clear();
        let path = `/login`;
        navigate(path);
    }

    useEffect(() => {
        if (INVITELINK) {
            setInviteCode(INVITELINK)
        } else {
            localStorage.clear();
            navigate(`/login`);
        }
    }, [INVITELINK]);

    const onSubmit = (data) => {
        if (data.new_password && data.confirm_password && data.confirm_password == data.new_password) {
            let requestData = {};
            requestData.inviteCode = inviteCode;
            requestData.password = data.confirm_password;
            dispatch(setUserPassword(requestData)).unwrap().then((res) => {
                if (res.status) {
                    handleShow();
                } else {
                    toast.error(res?.message);
                }
            }).catch((error) => {
                toast.error(error?.message);
            })
        } else {
            toast.error("Password is required");
        }
    }
    const handleTogglePassword = (type) => {
        setPasswordVisibility((prevState) => ({
            ...prevState,
            [type]: prevState[type] === "password" ? "text" : "password"
        }));
    }
    
    const handlePasswordChange = (evnt, type) => {
        setPasswordInputs((prevState) => ({
            ...prevState,
            [type]: evnt.target.value
        }));
    }
    return (
        <section className="vh-100">
            {isLoading && <Loader />}
            <div className="container-fluid h-100">
                <div className="row d-flex justify-content-between align-items-center h-100 " >
                    <div className="col-md-8 col-lg-7 col-xl-6 h-100 d-flex justify-content-center align-items-center imagecontainer" style={{ backgroundColor: '#E4F1F6', borderTopRightRadius: 50, borderBottomRightRadius: 50 }}>
                        <img src={LoginLeftImage} className='loginImage' />
                    </div>
                    <div className="col-md-7 col-lg-5 col-xl-5 form-login my-2">
                        <form className='form' onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <img src={Logo} className="logo mt-3" alt="Sample image" />
                            </div>
                            <h1 className="text-blue text-blue-login my-4">
                                {inviteCode ? 'Set New' : 'Reset'} Password
                            </h1>
                            <small>Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.</small>
                            <div className="form-outline mb-3 position-relative mt-3">
                                <label className="form-label" htmlFor="newPassword">New Password</label>
                                <input
                                    id="newPassword"
                                    type={passwordVisibility.new_password}
                                    onChange={(evnt) => handlePasswordChange(evnt, 'new_password')}
                                    className={`form-control form-control-lg ${errors.new_password ? 'is-invalid' : ''}`}
                                    placeholder="New Password"
                                    {...register('new_password', {
                                        required: `Please Enter New password`
                                    })}
                                />
                                <div className='eye-icon' onClick={() => handleTogglePassword('new_password')}>
                                    {passwordVisibility.new_password === "password" ? <FaEyeSlash size={25} color='#037AD0' /> : <FaEye color='#037AD0' size={25} />}
                                </div>
                                {errors?.new_password && (
                                    <div className="invalid-feedback">{errors?.new_password?.message}</div>
                                )}
                            </div>
                            <div className="form-outline mb-3 position-relative">
                                <label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
                                <input
                                    id="confirmPassword"
                                    type={passwordVisibility.confirm_password}
                                    onChange={(evnt) => handlePasswordChange(evnt, 'confirm_password')}
                                    className={`form-control form-control-lg ${errors.confirm_password ? 'is-invalid' : ''}`}
                                    placeholder="Confirm Password"
                                    {...register('confirm_password', {
                                        required: `Please Enter Confirm password`
                                    })}
                                />
                                <div className='eye-icon' onClick={() => handleTogglePassword('confirm_password')}>
                                    {passwordVisibility.confirm_password === "password" ? <FaEyeSlash size={25} color='#037AD0' /> : <FaEye color='#037AD0' size={25} />}
                                </div>
                                {errors?.confirm_password && (
                                    <div className="invalid-feedback">{errors?.confirm_password?.message}</div>
                                )}
                            </div>
                            <div className="text-center text-lg-start mt-4 pt-2 w-100">
                                <button type="submit" className="btn btn-primary btn-lg btn-block w-100">{inviteCode ? 'Set New' : 'Reset'} Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header >
                    <img src={ApproveTick} style={{ width: 96, objectFit: 'contain' }} />
                </Modal.Header>
                <Modal.Body>
                    <p className='text-blue text-center'>Password Changed Successfully !!</p>
                </Modal.Body>
                <Modal.Footer >
                    <button onClick={routeChange} type="button" className="btn btn-primary w-50 mb-3">Back to Login</button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}

export default ResetPassword
