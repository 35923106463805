import React, { useState } from 'react'
import DocumnetVerificationIcon from '../../../assets/images/documnet_verification.png'
import PassportImage from '../../../assets/images/passport_img.png'
import EditIcon from '../../../assets/images/edit_icon.png'
import { Accordion } from 'react-bootstrap'
import ScreenActionIcon from '../../../assets/images/screeing_action.png'

function DocumentsDetails() {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };
    return (
        <div>
            <div className='d-flex align-items-center justify-content-end mt-3'>
                <img src={EditIcon} style={{ width: 15, objectFit: 'contain', cursor: 'pointer' }} />
            </div>
            <div className='d-flex align-items-center'>
                <img src={DocumnetVerificationIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                <h2 className='text-success text-center'>Document Verification Results</h2>
            </div>
            <hr />
            <Accordion className='aml-accordion documnets-tab-accordion' defaultActiveKey={1} flush>
                {[
                    { key: 1, status: 'Pending', statusColor: '#9C6802', statusBg: '#FFED8F', title: 'Passport' },
                    { key: 2, status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', title: 'Driving License' },
                    { key: 3, status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', title: 'Voter ID' },
                ].map((item) =>
                    <Accordion.Item eventKey={item.key}>
                        <Accordion.Header>
                            <div className="d-flex align-items-center">
                                <h2 className='text-blue'>{item.title}</h2>
                                <p className='statusBtn_small' style={{ backgroundColor: item.statusBg, color: item.statusColor, fontSize: 12, marginLeft: 10 }}>{item.status}</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='custom-table'>
                                <div className='custom-table-head'>
                                    <div className='row'>
                                        <div className='col-lg-3'>
                                            <h6 className='text-blue'>Checks Done</h6>
                                        </div>
                                        <div className='col-lg-2'>
                                            <h6 className='text-blue'>View Documents</h6>
                                        </div>
                                        <div className='col-lg-2'>
                                            <h6 className='text-blue'>Provider</h6>
                                        </div>
                                        <div className='col-lg-2'>
                                            <h6 className='text-blue'>Result</h6>
                                        </div>
                                        <div className='col-lg-3'>
                                            <h6 className='text-blue'>Action</h6>
                                        </div>
                                    </div>
                                </div>
                                {/* {[
                                { status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', check_done: 'ID Document', action: 'Request or Upload Additional Document' },
                                { status: 'Pending', statusColor: '#9C6802', statusBg: '#FFED8F', check_done: 'Liveness', action: 'Re-KYC' },
                                { status: 'Pending', statusColor: '#9C6802', statusBg: '#FFED8F', check_done: 'ID Document - Face', action: 'Re-KYC' },
                            ].map((item) =>
                                <div className='custom-table-body'>
                                    <div className='row align-items-center'>
                                        <div className='col-lg-3'>
                                            <h6 className='text-black'>{item.check_done}</h6>
                                        </div>
                                        <div className='col-lg-2'>
                                            <img src={PassportImage} style={{ width: 55, objectFit: 'contain' }} />
                                        </div>
                                        <div className='col-lg-2'>
                                            <h6 className='text-black'>Yoti</h6>
                                        </div>
                                        <div className='col-lg-2'>
                                            <p className='statusBtn' style={{ backgroundColor: item.statusBg, color: item.statusColor }}>{item.status}</p>
                                        </div>
                                        <div className='col-lg-3'>
                                            <p style={{ backgroundColor: '#E6F2FB', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'fit-content', fontSize: 14, }} className='text-blue'>{item.action}</p>
                                        </div>
                                    </div>
                                </div>
                            )} */}
                                <Accordion className='client-billing-accordion client-services'>
                                    {[
                                        { key: 1, status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', check_done: 'ID Document', action: 'Request or Upload Additional Document' },
                                        { key: 2, status: 'Pending', statusColor: '#9C6802', statusBg: '#FFED8F', check_done: 'Liveness', action: 'Re-KYC' },
                                        { key: 3, status: 'Pending', statusColor: '#9C6802', statusBg: '#FFED8F', check_done: 'ID Document - Face', action: 'Re-KYC' },
                                        { key: 4, status: 'Approved', statusColor: '#037924', statusBg: '#A9FFC1', check_done: 'OCR Content', action: 'Request or Upload Additional Document' },
                                    ].map((item) =>
                                        <Accordion.Item eventKey={item.key}>
                                            <Accordion.Header onClick={toggleAccordion}>
                                                <div className='row align-items-center w-100'>
                                                    <div className='col-lg-3'>
                                                        <h6 className='text-black'>{item.check_done}<span>
                                                            <i className={`fa fa-chevron-down ms-3 ${isAccordionOpen ? 'rotate-chevron' : ''}`}></i>
                                                        </span></h6>
                                                    </div>
                                                    <div className='col-lg-2'>
                                                        <img src={PassportImage} style={{ width: 55, objectFit: 'contain' }} />
                                                    </div>
                                                    <div className='col-lg-2'>
                                                        <h6 className='text-black'>Yoti</h6>
                                                    </div>
                                                    <div className='col-lg-2'>
                                                        <p className='statusBtn' style={{ backgroundColor: item.statusBg, color: item.statusColor }}>{item.status}</p>
                                                    </div>
                                                    <div className='col-lg-3'>
                                                        <p style={{ backgroundColor: '#E6F2FB', color: '#004AAD', padding: '5px 10px', borderRadius: 10, width: 'fit-content', fontSize: 14, }} className='text-blue mb-0'>{item.action}</p>
                                                    </div>
                                                </div>

                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className='row'>
                                                    <div className='col-lg-4'>
                                                        <div className='d-flex align-items-center mt-3'>
                                                            <h6 className='text-black fw-bold'>Country:</h6>
                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>United Kingdom</h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <div className='d-flex align-items-center mt-3'>
                                                            <h6 className='text-black fw-bold'>Type:</h6>
                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>P</h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <div className='d-flex align-items-center mt-3'>
                                                            <h6 className='text-black fw-bold'>Passport No:</h6>
                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>125035493</h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <div className='d-flex align-items-center mt-3'>
                                                            <h6 className='text-black fw-bold'>Surname:</h6>
                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>Golechha</h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <div className='d-flex align-items-center mt-3'>
                                                            <h6 className='text-black fw-bold'>Given Names:</h6>
                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>Sandeep</h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <div className='d-flex align-items-center mt-3'>
                                                            <h6 className='text-black fw-bold'>Code:</h6>
                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>GBR</h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <div className='d-flex align-items-center mt-3'>
                                                            <h6 className='text-black fw-bold'>Nationality:</h6>
                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>British Citizen</h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <div className='d-flex align-items-center mt-3'>
                                                            <h6 className='text-black fw-bold'>Sex:</h6>
                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>M</h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <div className='d-flex align-items-center mt-3'>
                                                            <h6 className='text-black fw-bold'>Date of Birth:</h6>
                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>22/Dec/1971</h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <div className='d-flex align-items-center mt-3'>
                                                            <h6 className='text-black fw-bold'>Place of Birth:</h6>
                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>Chennai</h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <div className='d-flex align-items-center mt-3'>
                                                            <h6 className='text-black fw-bold'>Date of Issue:</h6>
                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>02/Jun/2021</h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <div className='d-flex align-items-center mt-3'>
                                                            <h6 className='text-black fw-bold'>Date of Expiry:</h6>
                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>02/Jun/2031</h4>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-12'>
                                                        <div className='d-flex align-items-center mt-3'>
                                                            <h6 className='text-black fw-bold'>MRZ No:</h6>
                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>asgsfdsgfgfghjgrhtjytregfngmhgkyrgbfngjyedbfgjyebnjfegjyuytegjytyegjgjgesjthgeseghthf</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )}
                                </Accordion>

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                )}
            </Accordion>

            <div className='white-background p-3 my-3'>
                <div className='d-flex align-items-center'>
                    <img src={ScreenActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                    <h2 className='text-ambed text-center'>Add Comments</h2>
                </div>
                <hr />
                <div className="d-flex mb-2">
                    <div style={{ marginRight: 30 }}>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label" for="flexRadioDefault1">Open</label>
                        </div>
                    </div>
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label" for="flexRadioDefault1">Ghost</label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='row align-items-end'>
                            <div className='col-lg-9' style={{ width: '75%' }}>
                                <div className="form-group mt-3">
                                    <label className="form-label" for="exampleInputEmail1">Add Comment</label>
                                    <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Add Comment" rows="2" />
                                </div>
                            </div>
                            <div className='col-lg-3' style={{ width: '25%' }}>
                                <button className='btn-primary fw-normal w-100'>Add Comment</button>
                            </div>
                        </div>
                        <div className='row align-items-end'>
                            <div className='col-lg-9' style={{ width: '75%' }}>
                                <div className="form-group mt-3">
                                    <label className="form-label" for="exampleInputEmail1">Request Documents</label>
                                    <select class="form-select first-select" aria-label="Default select example">
                                        <option selected>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-lg-3' style={{ width: '25%' }}>
                                <button className='btn-primary fw-normal w-100'>Request Documents</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentsDetails
