import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    isLoading: false,
    error: null,
    entityClientCompany: [],
    entityClientBank: [],
    personalDetails : {},
    firstIdentityRetryAttempt : 3,
    secondIdentityRetryAttempt : 3,
    autoSearchAddressData : [],
    autoPrevSearchAddressData : []
}

export const saveIndividualPersonalDetails = createAsyncThunk("individual/saveIndividualPersonalDetails", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.saveIndividualPersonalDetails,
            data:requestData.requestBody,
            headers: {}
        };
        if(requestData?.webformToken){
          data['headers']['publictoken'] = requestData?.webformToken
        }
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const uploadEntityClientDocument = createAsyncThunk("individual/uploadEntityClientDocument", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.uploadEntityClientDocument,
            data:requestData.formData,
            headers: {
                'Content-Type': 'multipart/form-data', // Set the appropriate content type for file uploads
            },
        };
        if(requestData?.webformToken){
          data.headers['publictoken'] = requestData?.webformToken
        }
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateIndividualPersonalDetails = createAsyncThunk("individual/updateIndividualPersonalDetails", async (request) => {
  try {
    let data = {
      method: METHOD_TYPE.put,
      url: API_ENDPOINTS.updateIndividualPersonalDetails + '?entity_client_id=' + request.entityClientId,
      data: request.requestBody,
      headers: {},
    };
    if(request?.webformToken){
      data.headers['publictoken'] = request.webformToken
    }
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});

export const saveIndividualAddress = createAsyncThunk("individual/saveIndividualAddress", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.saveIndividualAddress+'?entity_client_id='+ requestData.entityClientId,
            data:requestData.requestBody,
            headers: {}
        };
        if(requestData?.webformToken){
          data.headers['publictoken'] = requestData.webformToken;
        }
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const saveEntityClientKycDetail = createAsyncThunk("individual/saveEntityClientKycDetail", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.saveEntityClientKycDetail + '?entity_client_id=' + requestData.entityClientId,
            data: requestData.requestData,
            headers: {}
        };
        if(requestData?.webformToken){
          data.headers['publictoken'] = requestData.webformToken;
        }
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchDataEntityClientCompany = createAsyncThunk("individual_onboarding/getEntityClientCompany", async (requestData) => {
  try {
    const data = {
      method: METHOD_TYPE.get,
      url: `${API_ENDPOINTS.getEntityClientCompany}${requestData.queryParam}`,
      headers:{},
    };
    if(requestData?.webformToken){
      data.headers['publictoken'] = requestData.webformToken;
    }
    const response = await api(data);
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
});

export const fetchDataEntityClientBank = createAsyncThunk("individual_onboarding/getEntityClientBank", async (requestData) => {
  try {
    const data = {
      method: METHOD_TYPE.get,
      url: `${API_ENDPOINTS.getEntityClientBank}${requestData.queryParam}`,
      headers:{},
    };
    if(requestData?.webformToken){
      data.headers['publictoken'] = requestData.webformToken;
    }
    const response = await api(data);
    return response.data;
  } catch (error) {

    throw error?.response?.data;
  }
});

export const saveEntityClientCompany = createAsyncThunk("individual_onboarding/postEntityClientCompany", async ({ queryParam, requestData, webformToken }) => {
  try {
    const data = {
      method: METHOD_TYPE.post,
      url: `${API_ENDPOINTS.postEntityClientCompany}${queryParam}`,
      data: requestData,
      headers: {}
    };
    if(webformToken){
      data['headers']['publictoken'] = webformToken
    }
    const response = await api(data);
    return response.data;
  } catch (error) {

    throw error?.response?.data;
  }
});

export const saveEntityClientBank = createAsyncThunk("individual_onboarding/createEntityClientBanks", async ({ queryParam, requestData,webformToken }) => {
  try {
    const data = {
      method: METHOD_TYPE.post,
      url: `${API_ENDPOINTS.postEntityClientBank}${queryParam}`,
      data: requestData,
      headers: {}
    };
    if(webformToken){
      data['headers']['publictoken'] = webformToken
    }
    const response = await api(data);
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
});

export const generateOnboardingInviteLink = createAsyncThunk("individual_onboarding/generateOnboardingInviteLink", async (requestData) => {
  try {
    const data = {
      method: METHOD_TYPE.post,
      url: `${API_ENDPOINTS.generateOnboardingInviteLink}`,
      data: requestData,
    };
    const response = await api(data);
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
});

export const fetchEntityClientPersonalDetail = createAsyncThunk("individual_onboarding/fetchEntityClientPersonalDetail", async (requestData) => {
  try {
    const data = {
      method: METHOD_TYPE.get,
      url: `${API_ENDPOINTS.fetchEntityClientPersonalDetail}${requestData.queryParam}`,
      headers: {}
    };
    if(requestData?.webformToken){
      data['headers']['publictoken'] = requestData?.webformToken
    }
    const response = await api(data);
    return response.data;
  } catch (error) {

    throw error?.response?.data;
  }
});

export const fetchEntityClientAddress = createAsyncThunk("individual_onboarding/fetchEntityClientAddress", async (requestData) => {
  try {
    const data = {
      method: METHOD_TYPE.get,
      url: `${API_ENDPOINTS.fetchEntityClientAddress}${requestData.queryParam}`,
      headers: {}
    };
    if(requestData?.webformToken){
      data['headers']['publictoken'] = requestData?.webformToken
    }
    const response = await api(data);
    return response.data;
  } catch (error) {

    throw error?.response?.data;
  }
});

export const fetchEntityData = createAsyncThunk("entity/fetchEntityData", async (requestData) => {
  try {
      let data = {
          method: METHOD_TYPE.get,
          url: API_ENDPOINTS.getEntityDetails + requestData.queryParams,
          headers: {}
      };
      if(requestData?.webformToken){
        data['headers']['publictoken'] = requestData?.webformToken
      }
      const response = await api(data);
      return response.data;

  } catch (error) {
      throw error?.response?.data;
  }
});

export const deductKYCLivelynessCharge = createAsyncThunk("deductKYCLivelynessCharge", async ({requestData, webformToken}) => {
  try {
      let data = {
          method: METHOD_TYPE.post,
          url: API_ENDPOINTS.deductKYCLivelynessCharge,
          headers: {},
          data: requestData
      };
      if(webformToken){
        data['headers']['publictoken'] = webformToken
      }
      const response = await api(data);
      return response.data;

  } catch (error) {
      throw error?.response?.data;
  }
});

export const checkUKVatNo = createAsyncThunk("checkUKVatNo", async ({queryParam, webformToken}) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.checkUKVatNo + queryParam,
      headers: {}
    };
    if (webformToken) {
      data['headers']['publictoken'] = webformToken
    }
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});

export const autoSearchAddress = createAsyncThunk("autoSearchAddress", async (requestData) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.autoSearchAddress + requestData?.queryParam,
      headers: {}
    };
    if (requestData?.webformToken) {
      data['headers']['publictoken'] = requestData?.webformToken
    }
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});

export const autoPrevSearchAddress = createAsyncThunk("autoPrevSearchAddress", async (requestData) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.autoSearchAddress + requestData?.queryParam,
      headers: {}
    };
    if (requestData?.webformToken) {
      data['headers']['publictoken'] = requestData?.webformToken
    }
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});

export const checkEUVatNo = createAsyncThunk("checkEUVatNo", async ({queryParam, webformToken}) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.checkEUVatNo + queryParam,
      headers: {}
    };
    if (webformToken) {
      data['headers']['publictoken'] = webformToken
    }
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});
export const checkAadhaar = createAsyncThunk("checkAadhaar", async (requestData) => {
  try {
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.checkAadhaarUrl,
      data: requestData

    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});
export const checkPan = createAsyncThunk("checkPan", async (requestData) => {
  try {
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.checkPanUrl,
      data: requestData
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
      adjustRetryCount(state, action) {
        const type = action.payload;
        if (type === 'firstIdentity') {
          if (state.firstIdentityRetryAttempt > 0) {
            state.firstIdentityRetryAttempt -= 1;
          }
        } else if (type === 'secondIdentity') {
          if (state.secondIdentityRetryAttempt > 0) {
            state.secondIdentityRetryAttempt -= 1;
          }
        }
      },
      resetRetryCount(state) {
        state.firstIdentityRetryAttempt = initialState.firstIdentityRetryAttempt;
        state.secondIdentityRetryAttempt = initialState.secondIdentityRetryAttempt;
      },
      resetAutoSearchAddress(state) {
        state.autoSearchAddressData = initialState.autoSearchAddressData;
      },
      resetPrevAutoSearchAddress(state) {
        state.autoPrevSearchAddressData = initialState.autoPrevSearchAddressData;
      }
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveIndividualPersonalDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(saveIndividualAddress.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(uploadEntityClientDocument.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(saveEntityClientKycDetail.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(saveEntityClientCompany.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.error = null;
            })
            .addCase(fetchDataEntityClientBank.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.error = null;
              state.entityClientBank = payload.data;
            })
            .addCase(fetchDataEntityClientCompany.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.error = null;
              state.entityClientCompany = payload.data;
            })
            .addCase(fetchEntityClientPersonalDetail.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.error = null;
              state.personalDetails = payload.data;
            })
            .addCase(fetchEntityClientAddress.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.error = null;
            })
            .addCase(updateIndividualPersonalDetails.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.error = null;
            })
            .addCase(fetchEntityData.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.error = null;
            })
            .addCase(deductKYCLivelynessCharge.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.error = null;
            })
            .addCase(checkUKVatNo.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.error = null;
            }).addCase(checkEUVatNo.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.error = null;
            })
            .addCase(generateOnboardingInviteLink.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.error = null;
            })
            .addCase(autoSearchAddress.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.autoSearchAddressData = payload.data;
              state.error = null;
            })
            .addCase(autoPrevSearchAddress.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.autoPrevSearchAddressData = payload.data;
              state.error = null;
            })
            .addCase(checkAadhaar.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.error = null;
            })
            .addCase(checkPan.fulfilled, (state, { payload }) => {
              state.isLoading = false;
              state.error = null;
            })
            .addMatcher(isAnyOf(saveIndividualPersonalDetails.pending,saveIndividualAddress.pending,uploadEntityClientDocument.pending,saveEntityClientBank.pending, fetchDataEntityClientBank.pending, fetchDataEntityClientBank.pending, saveEntityClientCompany.pending,saveEntityClientKycDetail.pending, fetchEntityClientPersonalDetail.pending,fetchEntityClientAddress.pending,updateIndividualPersonalDetails.pending,generateOnboardingInviteLink.pending,fetchEntityData.pending, deductKYCLivelynessCharge.pending, checkUKVatNo.pending, checkEUVatNo.pending,checkPan.pending,checkAadhaar.pending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(saveIndividualPersonalDetails.rejected,saveIndividualAddress.rejected,uploadEntityClientDocument.rejected,fetchDataEntityClientBank.rejected,fetchDataEntityClientCompany.rejected,saveEntityClientBank.rejected,saveEntityClientCompany.rejected,saveEntityClientKycDetail.rejected, fetchEntityClientPersonalDetail.rejected,fetchEntityClientAddress.rejected,updateIndividualPersonalDetails.rejected,generateOnboardingInviteLink.rejected,fetchEntityData.rejected,deductKYCLivelynessCharge.rejected, checkUKVatNo.rejected, checkEUVatNo.rejected,checkPan.rejected,checkAadhaar.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = (error) ? error.message : "Request Failed Please Try Again ";
            })
    }
});

const { adjustRetryCount, resetRetryCount, resetAutoSearchAddress, resetPrevAutoSearchAddress } = profileSlice.actions;
const profileReducer = profileSlice.reducer;

export { adjustRetryCount, resetRetryCount, profileReducer, resetAutoSearchAddress, resetPrevAutoSearchAddress };
export default profileReducer;