import React, { useEffect, useRef, useState } from 'react'
import IndividualImage from '../../../assets/images/Individual_image.png'
import CompanyInfoIcon from '../../../assets/images/personalDetails.png'
import AddressIcon from '../../../assets/images/current_address.png'
import EditIcon from '../../../assets/images/edit_icon.png'
import ScreenActionIcon from '../../../assets/images/screeing_action.png'
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '../../../assets/images/Cancel_icon.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { autoPrevSearchAddress, fetchEntityClientPersonalDetail, resetPrevAutoSearchAddress } from '../../Profile/profileSlice'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { countryList } from '../../Registration/registrationSlice'
import * as yup from 'yup'
import countryCodes from '../../Registration/countryCode.json';
import PreviousAddress from '../../../assets/images/previous_address.png'
import soloTrader from '../../../assets/images/soloTrader.png'
import pepFlag from '../../../assets/images/pepFlag.png'
import hmiw from '../../../assets/images/hniw.png'
import socialIcons from '../../../assets/images/socialIcons.png'
import check from '../../../assets/images/check.png'
import Loader from '../../../components/Loader/Loader'
import { addScreeningComments, updateIndividualPersonalDetailsWithReason } from '../IndividualScreeningSlice'
import { decodeToken, useJwt } from "react-jwt";
import { getSignedUrls } from '../../../utils/awsHelper'
import userImg from "../../../assets/images/defaultUser.jpg"
import Select from 'react-select';
import DatePicker from 'react-date-picker'

const titles = [
    { "label": "Mr", "value": "Mr" },
    { "label": "Mrs", "value": "Mrs" },
    { "label": "Miss", "value": "Miss" },
    { "label": "Ms", "value": "Ms" },
    { "label": "Dr (Medical)", "value": "Dr (Medical)" },
    { "label": "Professor (PHD)", "value": "Professor (PHD)" },
    { "label": "Dr (PHD)", "value": "Dr (PHD)" },
    { "label": "Admiral", "value": "Admiral" },
    { "label": "Brigadier", "value": "Brigadier" },
    { "label": "Captain", "value": "Captain" },
    { "label": "Colonel", "value": "Colonel" },
    { "label": "Corporal", "value": "Corporal" },
    { "label": "Field Marshal", "value": "Field Marshal" },
    { "label": "Fleet Admiral", "value": "Fleet Admiral" },
    { "label": "Flight Sergeant", "value": "Flight Sergeant" },
    { "label": "General", "value": "General" },
    { "label": "Hon", "value": "Hon" },
    { "label": "Lady", "value": "Lady" },
    { "label": "Lieutenant - Colonel", "value": "Lieutenant - Colonel" },
    { "label": "Lieutenant - General", "value": "Lieutenant - General" },
    { "label": "Lord", "value": "Lord" },
    { "label": "Major", "value": "Major" },
    { "label": "Major General", "value": "Major General" },
    { "label": "Master", "value": "Master" },
    { "label": "Rear Admiral", "value": "Rear Admiral" },
    { "label": "Reverand", "value": "Reverand" },
    { "label": "Sergeant", "value": "Sergeant" },
    { "label": "Sir", "value": "Sir" },
    { "label": "Sister", "value": "Sister" },
    { "label": "Warrant Officer", "value": "Warrant Officer" },
    { "label": "Vice Admiral", "value": "Vice Admiral" },
    { "label": "Other", "value": "Other" }
];
const gender = [
    { "label": "Male", "value": "Male" },
    { "label": "Female", "value": "Female" },
    { "label": "Other", "value": "Other" },
]
function IndividualDetails({ entityClientId, viewOnly }) {
    const { control, register, handleSubmit, formState: { errors }, watch, reset, trigger } = useForm();
    const { autoPrevSearchAddressData } = useSelector(state => state.ProfileReducer);
    const containerRef = useRef(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show2, setShow2] = useState(false);
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);
    let entityClientIndex = 0;
    let entityClientAddressIndex = 0;
    const handleClose2 = () => {
        entityClientIndex = 0
        entityClientAddressIndex = 0;
        setCurrentValues(personalDetails);
        setChangedFields([]);
        reset();
        setShow2(false);
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            padding: '1px',
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
            boxShadow: 'none',
            '&:hover': {
                border: '2px solid #B3B3B3',
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#B3B3B3',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '12px',
        }),
    };

    const optionalCountryCodes = [
        14, 21, 33, 53, 56, 57, 58, 70, 75, 76, 82, 85, 98, 104, 107, 120, 126, 127,
        135, 154, 175, 176, 180, 200, 201, 207, 213
      ];
      const isOptionalCountry = (countryId) => optionalCountryCodes.includes(countryId)
    const handleShow2 = () => setShow2(true);
    const [isEditing, setIsEditing] = useState(false);
    const { isLoading, personalDetails } = useSelector(state => state.ProfileReducer);
    const { isLoading: countryLoading, countryData } = useSelector(state => state.registerReducer);
    const { isLoading: screeningLoading } = useSelector(state => state.individualScreeningReducer);
    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentValues, setCurrentValues] = useState(personalDetails);
    const [changedFields, setChangedFields] = useState([]);
    const schema = yup.object().shape({
        title: yup.string().required('Title is required'),
        country_of_residency: yup.number().typeError('Invalid residency country').required('Country of residency is required'),
        country_of_nationality: yup.number().typeError('Invalid nationality country').required('Country of nationality is required'),
        first_name: yup.string().required('First name is required'),
        middle_name: yup.string(),
        previous_name: yup.string(),
        sex: yup.string(),
        profession: yup.string().required('Profession is required'),
        trading_as: yup.string(),
        last_name: yup.string().required('Last name is required'),
        landline_code: yup.string().notRequired(),
        vat_no: yup.string().notRequired(),
        landline_number: yup.mixed().transform((originalValue, originalObject) => {
            return originalValue === "" ? undefined : originalValue;
        }).when('landline_code', {
            is: (obj) => obj && typeof obj != 'undefined',
            then: () => yup.number().typeError('Invalid landline number').required('landline number is required').test(
                'len',
                'Landline No must be at most 15 digits',
                val => val && val.toString().length <= 15
              ),
            otherwise: () => yup.mixed().notRequired(),
        }),
        date_of_birth: yup.string().required('Date of birth is required'),
        email: yup.string().email('Invalid email address').matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email address').required('Email is required'),
        facebook_link: yup.string().default(''),
        instagram_link: yup.string().default(''),
        linkedin_link: yup.string().default(''),
        twitter_link: yup.string().default(''),

        mobile_code: yup.string().required('Mobile code is required'),
        mobile_number: yup
        .number()
        .typeError('Mobile Number must be a number')
        .required('Mobile No is required')
        .test(
          'len',
          'Mobile No must be at most 15 digits',
          val => val && val.toString().length <= 15
        ),

        aadhar_number: (personalDetails?.aadhar_number) ? yup.string().transform((originalValue, originalObject) => {
            return originalValue === "" ? undefined : originalValue;
        }).required('Aadhar No is required').matches(/^\d{12}$/, 'Aadhar number must be exactly 12 digits') : yup.string().notRequired(),

        pan_number: (personalDetails?.pan_number) ? yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'PAN number must be in the format: AAAAA9999A').required() : yup.string().notRequired(),

        ssn_no: (personalDetails?.ssn_no) ? yup.mixed().transform((originalValue, originalObject) => {
            return originalValue === "" ? undefined : originalValue;
        }).required('SSN No is required') : yup.string().notRequired(),

        country: yup.number().typeError('Invalid country').required('Country is required'),
        city: yup.string().required('City is required'),
        zip: yup.string().required('Zip Code is required'),
        state:(isOptionalCountry(currentValues?.countryId))? yup.string().required('State is required'):yup.string().notRequired(),
        date_from: yup.string().required('Date from is required'),
        date_to: yup.string().required('Date to is required').test('is-greater', 'Date to must be greater than Date from', function (value) {
            const { date_from } = this.parent;
            // Convert dates to JavaScript Date objects for comparison
            const startDateObj = new Date(date_from);
            const endDateObj = new Date(value);

            // Compare the dates
            return endDateObj >= startDateObj;
        }),
        address: yup.string().required('Address is required'),
        previous_country: yup.number().test(
            'oneOfRequired',
            'Country is Required',
            (item,values)=>{
                const { previous_country, previous_city, previous_zip, previous_state, previous_date_from, previous_date_to, previous_address } = values.parent;
                let flag = true;

                if(!personalDetails?.previous_id){

                    /// Check if any of the fields (except previous_country) are filled and not empty
                    const anyFieldFilledExceptCountry = (
                        (typeof previous_city !== 'undefined' && previous_city !== '') ||
                        (typeof previous_zip !== 'undefined' && previous_zip !== '') ||
                        (typeof previous_state !== 'undefined' && previous_state !== '') ||
                        (typeof previous_date_from !== 'undefined' && previous_date_from !== '') ||
                        (typeof previous_date_to !== 'undefined' && previous_date_to !== '') ||
                        (typeof previous_address !== 'undefined' && previous_address !== '')
                    );

                    // Require previous_country if any other field (except itself) is filled and not empty
                    if (anyFieldFilledExceptCountry) {
                        return typeof previous_country !== 'undefined' && previous_country !== '';
                    }
                }
                return flag;
                // false means validation active true means ok
            }
          ),
        previous_city: yup.string().test(
            'oneOfRequired',
            'City is Required',
            (item,values)=>{
                const { previous_country, previous_city, previous_zip, previous_state, previous_date_from, previous_date_to, previous_address } = values.parent;
                let flag = true;

                if(!personalDetails?.previous_id){
                    /// Check if any of the fields (except previous_country) are filled and not empty
                    const anyFieldFilledExceptCountry = (
                        (typeof previous_country !== 'undefined' && previous_country !== '') ||
                        (typeof previous_zip !== 'undefined' && previous_zip !== '') ||
                        (typeof previous_state !== 'undefined' && previous_state !== '') ||
                        (typeof previous_date_from !== 'undefined' && previous_date_from !== '') ||
                        (typeof previous_date_to !== 'undefined' && previous_date_to !== '') ||
                        (typeof previous_address !== 'undefined' && previous_address !== '')
                    );

                    // Require previous_country if any other field (except itself) is filled and not empty
                    if (anyFieldFilledExceptCountry) {
                        return typeof previous_city !== 'undefined' && previous_city !== '';
                    }
                }
                return flag;
                // false means validation active true means ok
            }
          ),
        previous_zip: yup.number().typeError('Invalid Zip Code').test(
            'oneOfRequired',
            'Zip is Required',
            (item,values)=>{
                const { previous_country, previous_city, previous_zip, previous_state, previous_date_from, previous_date_to, previous_address } = values.parent;
                let flag = true;

                if(!personalDetails?.previous_id){
                    /// Check if any of the fields (except previous_country) are filled and not empty
                    const anyFieldFilledExceptCountry = (
                        (typeof previous_country !== 'undefined' && previous_country !== '') ||
                        (typeof previous_city !== 'undefined' && previous_city !== '') ||
                        (typeof previous_state !== 'undefined' && previous_state !== '') ||
                        (typeof previous_date_from !== 'undefined' && previous_date_from !== '') ||
                        (typeof previous_date_to !== 'undefined' && previous_date_to !== '') ||
                        (typeof previous_address !== 'undefined' && previous_address !== '')
                    );

                    // Require previous_country if any other field (except itself) is filled and not empty
                    if (anyFieldFilledExceptCountry) {
                        return typeof previous_zip !== 'undefined' && previous_zip !== '';
                    }
                }
                return flag;
                // false means validation active true means ok
            }
          ),
        previous_state: yup.string().test(
            'oneOfRequired',
            'State is Required',
            (item,values)=>{
                const { previous_country, previous_city, previous_zip, previous_state, previous_date_from, previous_date_to, previous_address } = values.parent;
                let flag = true;

                if(!personalDetails?.previous_id){
                    /// Check if any of the fields (except previous_country) are filled and not empty
                    const anyFieldFilledExceptCountry = (
                        (typeof previous_country !== 'undefined' && previous_country !== '') ||
                        (typeof previous_city !== 'undefined' && previous_city !== '') ||
                        (typeof previous_zip !== 'undefined' && previous_zip !== '') ||
                        (typeof previous_date_from !== 'undefined' && previous_date_from !== '') ||
                        (typeof previous_date_to !== 'undefined' && previous_date_to !== '') ||
                        (typeof previous_address !== 'undefined' && previous_address !== '')
                    );

                    // Require previous_country if any other field (except itself) is filled and not empty
                    if (anyFieldFilledExceptCountry) {
                        return typeof previous_state !== 'undefined' && previous_state !== '';
                    }
                }
                return flag;
                // false means validation active true means ok
            }
          ),
        previous_date_from: yup.string().test(
            'oneOfRequired',
            'Date From is Required',
            (item,values)=>{
                const { previous_country, previous_city, previous_zip, previous_state, previous_date_from, previous_date_to, previous_address } = values.parent;
                let flag = true;

                if(!personalDetails?.previous_id){
                    /// Check if any of the fields (except previous_country) are filled and not empty
                    const anyFieldFilledExceptCountry = (
                        (typeof previous_country !== 'undefined' && previous_country !== '') ||
                        (typeof previous_city !== 'undefined' && previous_city !== '') ||
                        (typeof previous_zip !== 'undefined' && previous_zip !== '') ||
                        (typeof previous_state !== 'undefined' && previous_state !== '') ||
                        (typeof previous_date_to !== 'undefined' && previous_date_to !== '') ||
                        (typeof previous_address !== 'undefined' && previous_address !== '')
                    );

                    // Require previous_country if any other field (except itself) is filled and not empty
                    if (anyFieldFilledExceptCountry) {
                        return typeof previous_date_from !== 'undefined' && previous_date_from !== '';
                    }
                }
                return flag;
                // false means validation active true means ok
            }
          ),
        previous_date_to: yup.string().test(
            'oneOfRequired',
            'Date To is Required',
            (item,values)=>{
                const { previous_country, previous_city, previous_zip, previous_state, previous_date_from, previous_date_to, previous_address } = values.parent;
                let flag = true;

                if(!personalDetails?.previous_id){
                    /// Check if any of the fields (except previous_country) are filled and not empty
                    const anyFieldFilledExceptCountry = (
                        (typeof previous_country !== 'undefined' && previous_country !== '') ||
                        (typeof previous_city !== 'undefined' && previous_city !== '') ||
                        (typeof previous_zip !== 'undefined' && previous_zip !== '') ||
                        (typeof previous_state !== 'undefined' && previous_state !== '') ||
                        (typeof previous_date_from !== 'undefined' && previous_date_from !== '') ||
                        (typeof previous_address !== 'undefined' && previous_address !== '')
                    );

                    // Require previous_country if any other field (except itself) is filled and not empty
                    if (anyFieldFilledExceptCountry) {
                        return typeof previous_date_to !== 'undefined' && previous_date_to !== '';
                    }
                }
                return flag;
                // false means validation active true means ok
            }
          ),
        previous_address: yup.string().test(
            'oneOfRequired',
            'Address is Required',
            (item,values)=>{
                const { previous_country, previous_city, previous_zip, previous_state, previous_date_from, previous_date_to, previous_address } = values.parent;
                let flag = true;

                if(!personalDetails?.previous_id){
                    /// Check if any of the fields (except previous_country) are filled and not empty
                    const anyFieldFilledExceptCountry = (
                        (typeof previous_country !== 'undefined' && previous_country !== '') ||
                        (typeof previous_city !== 'undefined' && previous_city !== '') ||
                        (typeof previous_zip !== 'undefined' && previous_zip !== '') ||
                        (typeof previous_state !== 'undefined' && previous_state !== '') ||
                        (typeof previous_date_from !== 'undefined' && previous_date_from !== '') ||
                        (typeof previous_date_to !== 'undefined' && previous_date_to !== '')
                    );

                    // Require previous_country if any other field (except itself) is filled and not empty
                    if (anyFieldFilledExceptCountry) {
                        return typeof previous_address !== 'undefined' && previous_address !== '';
                    }
                }
                return flag;
                // false means validation active true means ok
            }
          ),
        politically_exposed_source_of_wealth: (personalDetails?.politically_exposed_person) ? yup.string().required('This Field is requied') : yup.string().notRequired(),
        politically_exposed_pep: (personalDetails?.politically_exposed_person) ? yup.string().required('This Field is requied') : yup.string().notRequired(),

        high_net_value: (personalDetails?.high_net_worth_individual) ? yup.string().required('This Field is requied') : yup.string().notRequired(),
        high_net_source_of_wealth: (personalDetails?.high_net_worth_individual) ? yup.string().required('This Field is requied') : yup.string().notRequired(),
        high_net_source_of_income: (personalDetails?.high_net_worth_individual) ? yup.string().required('This Field is requied') : yup.string().notRequired(),
    });

    const { control: controlForm, register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, reset: resetForm, setValue, watch : watchForm } = useForm({
        resolver: yupResolver(schema),
    });

    const prevAddress = watchForm('previous_address');
    const prevCountry = watchForm('previous_country');

    const handlePreviousKeyDown = () => {
        dispatch(resetPrevAutoSearchAddress());
        if(prevAddress && prevCountry){
            debugger;
            const countrycode = countryData.find((obj)=> obj.country_id == prevCountry).iso_codes_2_digit;
            dispatch(autoPrevSearchAddress({queryParam : `?countryCode=${countrycode}&searchText=${prevAddress}`})).unwrap().catch((error) => {
                toast.error(error?.message);
            });
        }
    }   

    const selectedPrevAddress = (address) => {
        console.log("fff", address);
        setValue('previous_address', address);
        trigger('previous_address');
        dispatch(resetPrevAutoSearchAddress());
    }

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            dispatch(resetPrevAutoSearchAddress());
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleBlur = (e) => {
        console.log("onblur trigger")
        if (containerRef.current && containerRef.current.contains(e.relatedTarget)) {
            // Clicked inside the suggestions box, don't trigger the blur logic
            return;
        }
        dispatch(resetPrevAutoSearchAddress());
    };

    const commentSchema = yup.object().shape({
        commentType: yup.string().required('Comment Type is required'),
        comment: yup.string().required('Comment is required'),
    });

    const { control: controlCommentForm, register: registerCommentForm, handleSubmit: handleSubmitCommentForm, formState: { errors: errorsCommentForm }, reset: resetCommentForm, setValue: setValueComment } = useForm({
        resolver: yupResolver(commentSchema),
    });

    useEffect(() => {
        setCurrentValues(personalDetails);
    }, [personalDetails]);
    useEffect(() => {
        resetForm();
        if (currentValues?.title) setValue('title', currentValues.title);
        if (currentValues?.country_of_nationality) setValue('country_of_nationality', ((currentValues.country_of_nationality).toString().split("@|").length > 1) ? currentValues.country_of_nationality.split("@|")[1] : currentValues.country_of_nationality);
        if (currentValues?.country_of_residency) setValue('country_of_residency', ((currentValues.country_of_residency).toString().split("@|").length > 1) ? currentValues.country_of_residency.split("@|")[1] : currentValues.country_of_residency);
        if (currentValues?.first_name) setValue('first_name', currentValues.first_name);
        if (currentValues?.middle_name) setValue('middle_name', currentValues.middle_name);
        if (currentValues?.last_name) setValue('last_name', currentValues.last_name);
        if (currentValues?.previous_name) setValue('previous_name', currentValues.previous_name);
        if (currentValues?.sex) setValue('sex', currentValues.sex);
        if (currentValues?.profession) setValue('profession', currentValues.profession);
        if (currentValues?.date_of_birth) setValue('date_of_birth', currentValues.date_of_birth);
        if (currentValues?.mobile_code) setValue('mobile_code', currentValues.mobile_code);
        if (currentValues?.mobile_number) setValue('mobile_number', currentValues.mobile_number);
        if (currentValues?.landline_number) setValue('landline_number', currentValues.landline_number);
        if (currentValues?.landline_code) setValue('landline_code', currentValues.landline_code);
        if (currentValues?.email) setValue('email', currentValues.email);
        if (currentValues?.country) setValue('country', ((currentValues.country).toString().split("@|").length > 1) ? currentValues.country.split("@|")[1] : currentValues.country);
        if (currentValues?.city) setValue('city', currentValues.city);
        if (currentValues?.zip) setValue('zip', currentValues.zip);
        if (currentValues?.state) setValue('state', currentValues.state);
        if (currentValues?.date_from) setValue('date_from', currentValues.date_from);
        if (currentValues?.date_to) setValue('date_to', currentValues.date_to);
        if (currentValues?.address) setValue('address', currentValues.address);
        if (currentValues?.previous_country) setValue('previous_country', ((currentValues.previous_country).toString().split("@|").length > 1) ? currentValues.previous_country.split("@|")[1] : currentValues.previous_country);
    if (currentValues?.previous_city) setValue('previous_city', currentValues.previous_city);
    if (currentValues?.previous_zip) setValue('previous_zip', currentValues.previous_zip);
    if (currentValues?.previous_state) setValue('previous_state', currentValues.previous_state);
    if (currentValues?.previous_date_from) setValue('previous_date_from', currentValues.previous_date_from);
    if (currentValues?.previous_date_to) setValue('previous_date_to', currentValues.previous_date_to);
    if (currentValues?.previous_address) setValue('previous_address', currentValues.previous_address);
        if (currentValues?.politically_exposed_source_of_wealth) setValue('politically_exposed_source_of_wealth', currentValues.politically_exposed_source_of_wealth);
        if (currentValues?.politically_exposed_pep) setValue('politically_exposed_pep', currentValues.politically_exposed_pep);
        if (currentValues?.high_net_value) setValue('high_net_value', currentValues.high_net_value);
        if (currentValues?.high_net_source_of_wealth) setValue('high_net_source_of_wealth', currentValues.high_net_source_of_wealth);
        if (currentValues?.high_net_source_of_income) setValue('high_net_source_of_income', currentValues.high_net_source_of_income);
        if (currentValues?.aadhar_number) setValue('aadhar_number', currentValues.aadhar_number);
        if (currentValues?.pan_number) setValue('pan_number', currentValues.pan_number);
        if (currentValues?.vat_no) setValue('vat_no', currentValues.vat_no);
        if (currentValues?.trading_as) setValue('trading_as', currentValues.trading_as);
        if (currentValues?.linkedin_link) setValue('linkedin_link', currentValues.linkedin_link);
        if (currentValues?.instagram_link) setValue('instagram_link', currentValues.instagram_link);
        if (currentValues?.facebook_link) setValue('facebook_link', currentValues.facebook_link);
        if (currentValues?.twitter_link) setValue('twitter_link', currentValues.twitter_link);
    }, [currentValues])

    const handleChange = (field, value, displayName) => {
        // const keys = field.split('.');
        // setCurrentValues(prevValues => {
        //     let updatedValues = { ...prevValues };
        //     let nestedObj = updatedValues;
        //     for (let i = 0; i < keys.length - 1; i++) {
        //         const key = keys[i];
        //         nestedObj = nestedObj[key] = { ...(nestedObj[key] || {}) }; // Ensure nested objects exist
        //     }
        //     nestedObj[keys[keys.length - 1]] = value; // Update the value of the last nested field
        //     return updatedValues;
        // });
        setCurrentValues({
            ...currentValues,
            [field]: (displayName) ? `${displayName}@|${value}` : value
        });
    };

    useEffect(() => {
        if (!entityClientId) {
            navigate(`/individual_screening`);
            return;
        }

        dispatch(fetchEntityClientPersonalDetail({ queryParam: `?entity_client_id=${entityClientId}` })).unwrap().catch((error) => {
            toast.error(error?.message);
        });

        dispatch(countryList());

    }, [entityClientId, dispatch, navigate, setValue]);

    const onSubmit = (data) => {
        dispatch(updateIndividualPersonalDetailsWithReason({ payload: data, queryParam: `?entityClientId=${entityClientId}` })).unwrap().then((obj) => {
            entityClientIndex = 0;
            entityClientAddressIndex = 0;
            toast.success(obj?.message);
            setChangedFields([]);
            setShow2(false);
            reset();
            dispatch(fetchEntityClientPersonalDetail({ queryParam: `?entity_client_id=${entityClientId}` })).unwrap().catch((error) => {
                toast.error(error?.message);
            });
        }).catch(error => {
            toast.error(error?.message || 'Unable to update the record');
        });
    }

    
    
    const onFormSubmit = (data) => {
        handleEditClick();
        setChangedFields([]);
        entityClientIndex = 0;
        entityClientAddressIndex = 0;
        reset();
    
        const previousFields = ["previous_country", 'previous_city', 'previous_zip', 'previous_state', 'previous_date_from', 'previous_date_to', 'previous_address'];
    
        const changes = Object.entries(currentValues).filter(([field, value]) => {
            const valueAsString = (value != null)?String(value):'';
            const actualValue = valueAsString.includes('@|') ? value.split('@|')[1] : valueAsString;    
            return actualValue !== ((personalDetails[field] != null)?String(personalDetails[field]):'');
        }).map(([field, value]) => {
            const isPreviousField = previousFields.includes(field);
            const splitValue = value.includes('@|') ? value.split('@|') : [value, value];
            const columnName = isPreviousField ? field.replace('previous_', '') : field;

            const payload = {
                column_name: columnName,
                old_value: personalDetails[field] ?? "",
                new_value: splitValue[1],
                value_text: splitValue[0],
                table_name: (['country', 'city', 'zip', 'state', 'date_from', 'date_to', 'address'].includes(columnName)) ? 'entity_client_address' : 'entity_client'
            };

            if(payload.table_name === 'entity_client_address'){
                payload.type = isPreviousField ? 'previous' : 'current'
            }
    
            return payload;
        });
    
        if (changes.length > 0) {
            setChangedFields(changes);
            handleShow2();
        } else {
            toast.info("No Fields were updated");
        }
    };

    // const onFormSubmit = (data) => {
    //     handleEditClick();
    //     setChangedFields([]);
    //     entityClientIndex = 0;
    //     entityClientAddressIndex = 0;
    //     reset();
    //     const changes = Object.entries(currentValues).filter(([field, value]) => {
    //         const valueAsString = String(value);
    //         const actualValue = valueAsString.includes('@|') ? value.split('@|')[1] : valueAsString;
    //         return actualValue !== String(personalDetails[field]);
    //     }).map(([field, value]) => ({
    //         column_name: field,
    //         old_value: personalDetails[field] ?? "",
    //         new_value: (value).includes('@|') ? value.split('@|')[1] : value,
    //         value_text: (value).includes('@|') ? value.split('@|')[0] : value,
    //         table_name: (['country', 'city', 'zip', 'state', 'date_from', 'date_to', 'address'].includes(field)) ? 'entity_client_address' : 'entity_client'
    //     }));
    //     if (changes.length > 0) {
    //         setChangedFields(changes);
    //         handleShow2();
    //     } else {
    //         toast.info("No Fields were updated");
    //     }
    // }


    
    const onCommentFormSubmit = (data) => {
        dispatch(addScreeningComments({ payload: data })).unwrap().then((obj) => {
            toast.success(obj?.message || 'Comment Added Successfully');
            resetCommentForm();
        }).catch(error => {
            toast.error(error.message || 'Unable to add comments');
        });
    }

    const imageRef = useRef(null);
    const getImgUrl = async (url) => {
        const baseUrl = url ? url.split('?')[0] : '';
        if(!baseUrl){
            return '';
        }
        const signedUrl = await getSignedUrls(baseUrl);
        return signedUrl;
    }
    useEffect(() => {
        const fetchImageSrc = async () => {
            try {
                if (personalDetails?.personal_detail_document) {
                    const imageUrl = await getImgUrl(personalDetails.personal_detail_document);
                    if (imageUrl && imageRef.current) {
                        imageRef.current.src = imageUrl;
                    } else if (imageRef.current) {
                        imageRef.current.src = userImg; // Fallback if imageUrl is invalid
                    }
                }
            } catch (error) {
                console.error('Error fetching image:', error);
                if (imageRef.current) {
                    imageRef.current.src = userImg; // Fallback on error
                }
            }
        };
    
        if (imageRef.current) {
            imageRef.current.src = userImg; // Initial fallback
        }
        
        fetchImageSrc();
    }, [personalDetails?.personal_detail_document, getImgUrl]);

//    const fetchKeyData = async () => {
//        try {
//            await dispatch(listKycDocuments(`?entityClientId=${entityClientId}`)).unwrap();
//        } catch (error) {
//            toast.error(error?.message);
//        }
//    };
//    useEffect(() => {
//        const fetchImageSrc = async() => {
//            if (kycDocuments && kycDocuments[0]?.identity_document_selfie_url) {
//                const imageUrl = await getImgUrl(kycDocuments[0].identity_document_selfie_url);
//                if (imageUrl && imageRef.current) {
//                    imageRef.current.src = imageUrl;
//                } else if (imageRef.current) {
//                    imageRef.current.src = userImg; // Fallback if imageUrl is invalid
//                }
//            } else if (imageRef.current) {
//                imageRef.current.src = userImg; // Fallback if no documents are available
//            }
//        };
//
//        // Set initial fallback
//        if (imageRef.current) {
//            imageRef.current.src = userImg;
//        }
//
//        fetchImageSrc();
//    }, [kycDocuments]);
//    useEffect(() => {
//        fetchKeyData();
//    }, [entityClientId]);

    return (
        <>
            {(isLoading || screeningLoading) && <Loader />}
            <form className='form' onSubmit={handleSubmitForm(onFormSubmit)}>
                <div className='row'>
                    <div className='col-lg-2' style={{ borderRight: '1px solid #D9D9D9' }}>
                        <div className='d-flex align-items-center justify-content-start flex-column h-100'>
                            <div className='my-3'>
                                <img ref={imageRef} style={{ width: 158, objectFit: 'contain' }} alt="Individual Image" />
                                <h2 className='text-blue text-center'>{personalDetails?.first_name} {personalDetails?.middle_name} {personalDetails?.last_name}</h2>
                            </div>
                            <div className='mb-3'>
                                <h4 className='text-black text-center'>Oomero ID:</h4>
                                <h2 className='text-blue text-center'>{personalDetails?.oomero_id}</h2>
                            </div>
                            {personalDetails?.vat_no &&
                                <div className='mb-3'>
                                    <h4 className='text-black text-center'>VAT No:</h4>
                                    <h2 className='text-blue text-center'>{personalDetails?.vat_no}</h2>
                                </div>
                            }
                            {personalDetails?.trading_as &&
                                <div className='mb-3'>
                                    <h4 className='text-black text-center'>Trading Name:</h4>
                                    <h2 className='text-blue text-center'>{personalDetails?.trading_as}</h2>
                                </div>
                            }
                            {
                                (personalDetails?.high_net_worth_individual || personalDetails?.politically_exposed_person) && <div className='mb-3'>
                                    <h2 className='text-blue text-center'>Type of Individual:</h2>
                                    <div className='d-flex align-items-center justify-content-center' style={{ gap: 10 }}>

                                        {personalDetails?.politically_exposed_person && <h2 className='text-white text-center' style={{ padding: '0px 16px', backgroundColor: '#9C6802', borderRadius: 5 }}>PEP</h2>}

                                        {personalDetails?.high_net_worth_individual && <h2 className='text-white text-center' style={{ padding: '0px 16px', backgroundColor: '#037AD0', borderRadius: 5 }}>HNI</h2>}
                                    </div>
                                </div>
                            }
                            {
                                (personalDetails?.biometric_consent || personalDetails?.form_consent) &&
                                <div className='mb-3'>
                                    <h4 className=' text-center'> Consent:</h4>
                                    <div className='d-flex align-items-center justify-content-center col-12' style={{ gap: 10 }}>
                                        <div className='row'>

                                            {personalDetails?.form_consent &&

                                                <h2 className='text-blue text-center col-10' style={{ padding: '0px 16px', borderRadius: 5 }}> <img src={check} style={{ width: 15, objectFit: 'contain' }} />  Form</h2>
                                            }

                                            {personalDetails?.biometric_consent &&
                                                <h2 className='text-blue text-center col-10' style={{ padding: '0px 16px', borderRadius: 5 }}> <img src={check} style={{ width: 15, objectFit: 'contain' }} />   BIOMETRICS</h2>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    <div className='col-lg-10 px-4 py-4'>
                        {
                                !viewOnly && <div className='d-flex align-items-center justify-content-end'>
                            <img src={EditIcon} onClick={handleEditClick} style={{ width: 15, objectFit: 'contain', cursor: 'pointer', marginRight: 5 }} />
                                <div>Edit</div>
                            
                        </div>}
                        <div className='d-flex align-items-center '>
                            <img src={CompanyInfoIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                            <h2 className='text-ambed text-center'>Basic Info</h2>
                        </div>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Title: </h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.title ? currentValues?.title.charAt(0).toUpperCase() + currentValues?.title.slice(1) : ''}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="title"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <Select
                                                    id="title"
                                                    placeholder="Select Title"
                                                    className={`${errorsForm?.title ? 'is-invalid' : ''}`}
                                                    options={titles}
                                                    styles={customStyles}
                                                    value={titles.find(option => option.value === field.value) || titles.find(option => option.value === currentValues?.title)}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption?.value);
                                                        handleChange('title', selectedOption?.value);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.title && (
                                            <div className="invalid-feedback">{errorsForm?.title?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Nationality:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.country_of_nationality_name}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="country_of_nationality"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <select
                                                    {...field}
                                                    className={`form-control form-select ${errorsForm.country_of_nationality ? 'is-invalid' : ''}`}
                                                    onChange={(e) => {
                                                        const countryId = e.target.value;
                                                        // Use a custom attribute to get the country name
                                                        const countryName = e.target.options[e.target.selectedIndex].getAttribute('data-country-name');


                                                        handleChange('country_of_nationality', countryId, countryName);
                                                        field.onChange(e);
                                                    }}
                                                >
                                                    <option value=''>Select Country of Nationality</option>
                                                    {countryData && countryData.map((country) => (
                                                        <option key={'nationality_country_' + country.country_id} data-country-name={country.name} value={country.country_id}>
                                                            {country.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        />
                                        {errorsForm?.country_of_nationality && (
                                            <div className="invalid-feedback">{errorsForm?.country_of_nationality?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Country of Residence:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.country_of_residency_name}</h4>)}
                                </div>
                                {isEditing && (
                                    <><Controller
                                        name="country_of_residency"
                                        control={controlForm}
                                        render={({ field }) => (
                                            <select
                                                {...field}
                                                className={`form-control form-select ${errorsForm.country_of_residency ? 'is-invalid' : ''}`}
                                                onChange={(e) => {
                                                    const countryId = e.target.value;
                                                    // Use a custom attribute to get the country name
                                                    const countryName = e.target.options[e.target.selectedIndex].getAttribute('data-country-name');

                                                    handleChange('country_of_residency', countryId, countryName);
                                                    field.onChange(e);
                                                }}
                                            >
                                                <option value=''>Select Country of Residency</option>
                                                {countryData && countryData.map((country) => (
                                                    <option key={'residency_country_' + country.country_id} data-country-name={country.name} value={country.country_id}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    />
                                        {errorsForm?.country_of_residency && (
                                            <div className="invalid-feedback">{errorsForm?.country_of_residency?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>First Name:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.first_name}</h4>)}
                                </div>
                                {isEditing && (
                                    // <input type="text" value={currentValues['first_name']}
                                    // onChange={(e) => handleChange('first_name', e.target.value)} className="form-control mt-1" placeholder=''></input>
                                    <><Controller
                                        name="first_name"
                                        control={controlForm}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                className={`form-control form-control-lg ${errorsForm.first_name ? 'is-invalid' : ''}`}
                                                placeholder="First Name"
                                                onChange={(e) => {
                                                    field.onChange(e); // Trigger React Hook Form's onChange handler
                                                    handleChange('first_name', e.target.value); // Your custom onChange handler
                                                }}
                                            />
                                        )}
                                    />
                                        {errorsForm?.first_name && (
                                            <div className="invalid-feedback">{errorsForm?.first_name?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Middle Name:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.middle_name}</h4>)}
                                </div>
                                {isEditing && (
                                    <><Controller
                                        name="middle_name"
                                        control={controlForm}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                className={`form-control mt-1 ${errorsForm.middle_name ? 'is-invalid' : ''}`}
                                                placeholder=""
                                                onChange={(e) => {
                                                    handleChange('middle_name', e.target.value);
                                                    field.onChange(e);
                                                }}
                                            />
                                        )}
                                    />
                                        {errorsForm?.middle_name && (
                                            <div className="invalid-feedback">{errorsForm?.middle_name?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Last Name:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.last_name}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="last_name"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    placeholder=""
                                                    className={`form-control mt-1 ${errorsForm.last_name ? 'is-invalid' : ''}`}
                                                    onChange={(e) => {
                                                        handleChange('last_name', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.last_name && (
                                            <div className="invalid-feedback">{errorsForm?.last_name?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Previous Name:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.previous_name}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="previous_name"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    placeholder=""
                                                    className={`form-control mt-1 ${errorsForm.previous_name ? 'is-invalid' : ''}`}
                                                    onChange={(e) => {
                                                        handleChange('previous_name', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.previous_name && (
                                            <div className="invalid-feedback">{errorsForm?.previous_name?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4 col-md-12 pe-3 mt-1'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Sex:</h6>
                                    {!isEditing && (
                                        <h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.sex}</h4>
                                    )}
                                </div>
                                {isEditing && (
                                    <div className="form-group">
                                        <Controller
                                            name="sex"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <Select
                                                    id="sex"
                                                    placeholder="Select sex"
                                                    className={`${errorsForm?.sex ? 'is-invalid' : ''}`}
                                                    options={gender}
                                                    styles={customStyles}
                                                    value={gender.find(option => option.value === field.value) || gender.find(option => option.value === currentValues?.sex)}
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption?.value);
                                                        handleChange('sex', selectedOption?.value);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.sex && (
                                            <div className="invalid-feedback">{errorsForm?.sex?.message}</div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Profession:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.profession}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="profession"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    placeholder=""
                                                    className={`form-control mt-1 ${errorsForm.profession ? 'is-invalid' : ''}`}
                                                    onChange={(e) => {
                                                        handleChange('profession', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.profession && (
                                            <div className="invalid-feedback">{errorsForm?.profession?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Date of birth:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.date_of_birth ? moment(personalDetails?.date_of_birth).format("DD/MM/YYYY") : ''}</h4>)}
                                </div>
                                {isEditing && (
                                    <><Controller
                                        name="date_of_birth"
                                        control={controlForm}
                                        render={({ field }) => (
                                            // <input
                                            //     {...field}
                                            //     type="date"
                                            //     className={`form-control mt-1 ${errorsForm.date_of_birth ? 'is-invalid' : ''}`}
                                            //     placeholder=""
                                            //     onChange={(e) => {
                                            //         handleChange('date_of_birth', e.target.value);
                                            //         field.onChange(e);
                                            //     }}
                                            // />
                                            <DatePicker
                                                {...field}
                                                className={`form-control form-control-lg custom-datepicker ${errorsForm.date_of_birth ? 'is-invalid' : ''}`}
                                                onChange={(date) => {
                                                    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                    handleChange('date_of_birth', formattedDate);
                                                    field.onChange(formattedDate);
                                                }}
                                                value={field.value}
                                                clearIcon
                                                maxDate={new Date()} 
                                                format='dd-MM-yyyy'
                                                yearPlaceholder='yyyy'
                                                monthPlaceholder='mm'
                                                dayPlaceholder='dd'
                                            />
                                        )}
                                    />

                                        {errorsForm?.date_of_birth && (
                                            <div className="invalid-feedback">{errorsForm?.date_of_birth?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Mobile:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.mobile_code + '-' + personalDetails?.mobile_number}</h4>)}
                                </div>
                                {isEditing && (
                                    <>

                                        <div className='row'>
                                            <div className='col-lg-4 col-md-4 mt-1'>
                                                <Controller
                                                    name="mobile_code"
                                                    control={controlForm}
                                                    render={({ field }) => (
                                                        <select
                                                            id="mobile_codeDropdown"
                                                            className={`form-control form-select ${errorsForm.mobile_code ? 'is-invalid' : ''}`}
                                                            {...field}
                                                            onChange={(e) => {
                                                                handleChange('mobile_code', e.target.value);
                                                                field.onChange(e);
                                                            }}
                                                        >
                                                            <option value="" >Select Mobile Code</option>
                                                            {Object.keys(countryCodes).map((code, i) => (
                                                                <option key={'countryCodes' + i} value={countryCodes[code]}>
                                                                    {countryCodes[code]}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errorsForm?.mobile_code && (
                                                    <div className="invalid-feedback">{errorsForm?.mobile_code?.message}</div>
                                                )}

                                            </div>
                                            <div className='col-lg-8 col-md-8'>
                                                <div className="form-group">
                                                    <Controller
                                                        name="mobile_number"
                                                        control={controlForm}
                                                        render={({ field }) => (
                                                            <input
                                                                {...field}
                                                                type="text"
                                                                className={`form-control mt-1 ${errorsForm.mobile_number ? 'is-invalid' : ''}`}
                                                                placeholder=""
                                                                onChange={(e) => {
                                                                    handleChange('mobile_number', e.target.value);
                                                                    field.onChange(e);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errorsForm?.mobile_number && (
                                                        <div className="invalid-feedback">{errorsForm?.mobile_number?.message}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='d-flex align-items-center mt-4'>
                                <h6 className='text-black fw-bold'>Landline Number:</h6>
                                {!isEditing && (
                                    <h4 className='text-black' style={{ marginLeft: 5 }}>
                                        {currentValues.landline_code}-{currentValues.landline_number}
                                    </h4>
                                )}
                            </div>
                            {isEditing && (

                                <div className='col-lg-6 col-md-8'>
                                    <div className="form-group mt-3">
                                        <div className={window.innerWidth <= 500 ? 'd-flex align-items-center justify-content-between' : 'row'}>
                                            <div className='col-lg-6 col-sm-2' style={window.innerWidth >= 500 ? {} : { width: '55%' }}>
                                                <Controller
                                                    name="landline_code"
                                                    control={controlForm}
                                                    render={({ field }) => (
                                                        <select
                                                            id="landline_code"
                                                            className={`form-control form-select mt-1 ${errorsForm.landline_code ? 'is-invalid' : ''}`}
                                                            {...field}
                                                            onChange={(e) => {
                                                                handleChange('landline_code', e.target.value);
                                                                field.onChange(e);
                                                            }}
                                                        >
                                                            <option value="" >Select landline Code</option>
                                                            {Object.keys(countryCodes).map((code, i) => (
                                                                <option key={'countryCodes' + i} value={countryCodes[code]}>
                                                                    {countryCodes[code]}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                />
                                                {errorsForm?.landline_code && (
                                                    <div className="invalid-feedback">{errorsForm?.landline_code?.message}</div>
                                                )}
                                            </div>
                                            <div className="form-group col-6 ">
                                                <Controller
                                                    name="landline_number"
                                                    control={controlForm}
                                                    render={({ field }) => (
                                                        <input
                                                            {...field}
                                                            type="text"
                                                            className={`form-control mt-1 ${errorsForm.landline_number ? 'is-invalid' : ''}`}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                handleChange('landline_number', e.target.value);
                                                                field.onChange(e);
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errorsForm?.landline_number && (
                                                    <div className="invalid-feedback">{errorsForm?.landline_number?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )}

                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center '>
                                    <h6 className='text-black fw-bold'>Email:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.email}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="email"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control  ${errorsForm.email ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        handleChange('email', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.email && (
                                            <div className="invalid-feedback">{errorsForm?.email?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            {personalDetails?.aadhar_number &&
                                <div className='col-lg-4'>
                                    <div className='d-flex align-items-center mt-4'>
                                        <h6 className='text-black fw-bold'>Aadhar No:</h6>
                                        {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.aadhar_number}</h4>)}
                                    </div>
                                    {isEditing && (
                                        <><Controller
                                            name="aadhar_number"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.aadhar_number ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        handleChange('aadhar_number', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                            {errorsForm?.aadhar_number && (
                                                <div className="invalid-feedback">{errorsForm?.aadhar_number?.message}</div>
                                            )}
                                        </>
                                    )}
                                </div>
                            }
                            {personalDetails?.pan_number &&
                                <div className='col-lg-4'>
                                    <div className='d-flex align-items-center mt-4'>
                                        <h6 className='text-black fw-bold'>Pan No:</h6>
                                        {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.pan_number}</h4>)}
                                    </div>
                                    {isEditing && (
                                        <><Controller
                                            name="pan_number"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.pan_number ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        handleChange('pan_number', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                            {errorsForm?.pan_number && (
                                                <div className="invalid-feedback">{errorsForm?.pan_number?.message}</div>
                                            )}
                                        </>
                                    )}
                                </div>
                            }
                            {personalDetails?.ssn_no &&
                                <div className='col-lg-4'>
                                    <div className='d-flex align-items-center mt-4'>
                                        <h6 className='text-black fw-bold'>SSN No:</h6>
                                        {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.ssn_no}</h4>)}
                                    </div>
                                    {isEditing && (
                                        <>
                                            <Controller
                                                name="ssn_no"
                                                control={controlForm}
                                                render={({ field }) => (
                                                    <input
                                                        {...field}
                                                        type="text"
                                                        className={`form-control mt-1 ${errorsForm.ssn_no ? 'is-invalid' : ''}`}
                                                        placeholder=""
                                                        onChange={(e) => {
                                                            handleChange('ssn_no', e.target.value);
                                                            field.onChange(e);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errorsForm?.ssn_no && (
                                                <div className="invalid-feedback">{errorsForm?.ssn_no?.message}</div>
                                            )}
                                        </>
                                    )}
                                </div>
                            }
                        </div>
                        <hr />
                        <div className='d-flex align-items-center '>
                            <img src={AddressIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                            <h2 className='text-blue text-center'>Residential Address</h2>
                        </div>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Country:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}> {currentValues?.current_country_name}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="country"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <select
                                                    {...field}
                                                    id="statusDropdown"
                                                    className={`form-control form-select ${errorsForm.country ? 'is-invalid' : ''}`}
                                                    onChange={(e) => {
                                                        const countryId = e.target.value;
                                                        // Use a custom attribute to get the country name
                                                        const countryName = e.target.options[e.target.selectedIndex].getAttribute('data-country-name');

                                                        handleChange('country', e.target.value, countryName);
                                                        field.onChange(e);
                                                    }}
                                                >
                                                    <option value=''>Select Country</option>
                                                    {countryData && countryData.map((country) => (
                                                        <option key={'country' + country.country_id} data-country-name={country.name} value={country.country_id}>
                                                            {country.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        />
                                        {errorsForm?.country && (
                                            <div className="invalid-feedback">{errorsForm?.country?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>City:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.city}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="city"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.city ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        handleChange('city', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.city && (
                                            <div className="invalid-feedback">{errorsForm?.city?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Postal Code:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.zip}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="zip"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.zip ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        handleChange('zip', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.zip && (
                                            <div className="invalid-feedback">{errorsForm?.zip?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>State/Region:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.state}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="state"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.state ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        handleChange('state', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.state && (
                                            <div className="invalid-feedback">{errorsForm?.state?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Date From:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{(currentValues?.date_from) ? moment(currentValues?.date_from).format("DD/MM/YYYY") : ''}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="date_from"
                                            control={controlForm}
                                            render={({ field }) => (
                                                // <input
                                                //     {...field}
                                                //     type="date"
                                                //     className={`form-control mt-1 ${errorsForm.date_from ? 'is-invalid' : ''}`}
                                                //     placeholder=""
                                                //     onChange={(e) => {
                                                //         handleChange('date_from', e.target.value);
                                                //         field.onChange(e);
                                                //     }}
                                                // />
                                                <DatePicker
                                                    {...field}
                                                    className={`form-control form-control-lg custom-datepicker mt-1 ${errorsForm.date_from ? 'is-invalid' : ''}`}
                                                    onChange={(date) => {
                                                      const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                      handleChange('date_from', formattedDate);
                                                      field.onChange(formattedDate);
                                                    }}
                                                    value={field.value}
                                                    clearIcon
                                                    maxDate={new Date()}
                                                    format='dd-MM-yyyy'
                                                    yearPlaceholder='yyyy'
                                                    monthPlaceholder='mm'
                                                    dayPlaceholder='dd'
                                                />
                                            )}
                                        />
                                        {errorsForm?.date_from && (
                                            <div className="invalid-feedback">{errorsForm?.date_from?.message}</div>
                                        )}
                                    </>

                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Date To:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{(currentValues?.date_to) ? moment(currentValues?.date_to).format("DD/MM/YYYY") : ''} </h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="date_to"
                                            control={controlForm}
                                            render={({ field }) => (
                                                // <input
                                                //     {...field}
                                                //     type="date"
                                                //     className={`form-control mt-1 ${errorsForm.date_to ? 'is-invalid' : ''}`}
                                                //     placeholder=""
                                                //     onChange={(e) => {
                                                //         handleChange('date_to', e.target.value);
                                                //         field.onChange(e);
                                                //     }}
                                                // />
                                                <DatePicker
                                                    {...field}
                                                    className={`form-control form-control-lg custom-datepicker mt-1 ${errorsForm.date_to ? 'is-invalid' : ''}`}
                                                    onChange={(date) => {
                                                      const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                      handleChange('date_to', formattedDate);
                                                      field.onChange(formattedDate);
                                                    }}
                                                    value={field.value}
                                                    clearIcon
                                                    maxDate={new Date()}
                                                    format='dd-MM-yyyy'
                                                    yearPlaceholder='yyyy'
                                                    monthPlaceholder='mm'
                                                    dayPlaceholder='dd'
                                                />
                                            )}
                                        />
                                        {errorsForm?.date_to && (
                                            <div className="invalid-feedback">{errorsForm?.date_to?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-12'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Address:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.address}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="address"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.address ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        handleChange('address', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.address && (
                                            <div className="invalid-feedback">{errorsForm?.address?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <hr />
                        <div className='d-flex align-items-center  '>
                            <img src={PreviousAddress} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                            <h2 className='text-ambed text-center'>Previous Residential Address</h2>
                        </div>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Country:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.previous_country_name}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                       <Controller
                                            name="previous_country"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <select
                                                    {...field}
                                                    id="statusDropdown"
                                                    className={`form-control form-select ${errorsForm.previous_country ? 'is-invalid' : ''}`}
                                                    onChange={(e) => {
                                                        const countryId = e.target.value;
                                                        // Use a custom attribute to get the country name
                                                        const countryName = e.target.options[e.target.selectedIndex].getAttribute('data-previouscountry-name');

                                                        handleChange('previous_country', e.target.value, countryName);
                                                        field.onChange(e);
                                                    }}
                                                >
                                                    <option value=''>Select Country</option>
                                                    {countryData && countryData.map((country) => (
                                                        <option key={'previous_country' + country.country_id} data-previouscountry-name={country.name} value={country.country_id}>
                                                            {country.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        />
                                        {errorsForm?.previous_country && (
                                            <div className="invalid-feedback">{errorsForm?.previous_country?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>City:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.previous_city}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="previous_city"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.previous_city ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        handleChange('previous_city', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.previous_city && (
                                            <div className="invalid-feedback">{errorsForm?.previous_city?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Postal Code:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.previous_zip}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="previous_zip"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.previous_zip ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        handleChange('previous_zip', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.previous_zip && (
                                            <div className="invalid-feedback">{errorsForm?.previous_zip?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>State/Region:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.previous_state}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="previous_state"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.previous_state ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        handleChange('previous_state', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.previous_state && (
                                            <div className="invalid-feedback">{errorsForm?.previous_state?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Date From:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{(currentValues?.previous_date_from) ? moment(currentValues?.previous_date_from).format("DD/MM/YYYY") : ''}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="previous_date_from"
                                            control={controlForm}
                                            render={({ field }) => (
                                                // <input
                                                //     {...field}
                                                //     type="date"
                                                //     className={`form-control mt-1 ${errorsForm.previous_date_from ? 'is-invalid' : ''}`}
                                                //     placeholder=""
                                                //     onChange={(e) => {
                                                //         handleChange('previous_date_from', e.target.value);
                                                //         field.onChange(e);
                                                //     }}
                                                // />
                                                <DatePicker
                                                    {...field}
                                                    className={`form-control form-control-lg custom-datepicker mt-1 ${errorsForm.previous_date_from ? 'is-invalid' : ''}`}
                                                    onChange={(date) => {
                                                      const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                      handleChange('previous_date_from', formattedDate);
                                                      field.onChange(formattedDate);
                                                    }}
                                                    value={field.value}
                                                    maxDate={new Date()}
                                                    clearIcon
                                                    format='dd-MM-yyyy'
                                                    yearPlaceholder='yyyy'
                                                    monthPlaceholder='mm'
                                                    dayPlaceholder='dd'
                                                />
                                            )}
                                        />
                                        {errorsForm?.previous_date_from && (
                                            <div className="invalid-feedback">{errorsForm?.previous_date_from?.message}</div>
                                        )}
                                    </>

                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Date To:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{(currentValues?.previous_date_to) ? moment(currentValues?.previous_date_to).format("DD/MM/YYYY") : ''} </h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="previous_date_to"
                                            control={controlForm}
                                            render={({ field }) => (
                                                // <input
                                                //     {...field}
                                                //     type="date"
                                                //     className={`form-control mt-1 ${errorsForm.previous_date_to ? 'is-invalid' : ''}`}
                                                //     placeholder=""
                                                //     onChange={(e) => {
                                                //         handleChange('previous_date_to', e.target.value);
                                                //         field.onChange(e);
                                                //     }}
                                                // />
                                                <DatePicker
                                                    {...field}
                                                    className={`form-control form-control-lg custom-datepicker mt-1 ${errorsForm.previous_date_to ? 'is-invalid' : ''}`}
                                                    onChange={(date) => {
                                                      const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                      handleChange('previous_date_to', formattedDate);
                                                      field.onChange(formattedDate);
                                                    }}
                                                    value={field.value}
                                                    clearIcon
                                                    format='dd-MM-yyyy'
                                                    maxDate={new Date()}
                                                    yearPlaceholder='yyyy'
                                                    monthPlaceholder='mm'
                                                    dayPlaceholder='dd'
                                                />
                                            )}
                                        />
                                        {errorsForm?.previous_date_to && (
                                            <div className="invalid-feedback">{errorsForm?.previous_date_to?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-12' style={{position:'relative'}}>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Address:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.previous_address}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="previous_address"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    onKeyDown={handlePreviousKeyDown} 
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.previous_address ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        handleChange('previous_address', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.previous_address && (
                                            <div className="invalid-feedback">{errorsForm?.previous_address?.message}</div>
                                        )}
                                        {autoPrevSearchAddressData.length > 0 &&
                                            <div className='suggestions-box' ref={containerRef}>
                                                {
                                                    autoPrevSearchAddressData.map((obj) => (
                                                        <div className='text-box' onClick={() => { selectedPrevAddress(obj?.Address.Address) }}>
                                                            <div className='suggestions-text'>{obj.Address.Address}</div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </>
                                )}
                            </div>

                        </div>
                        <hr />
                        <div className='d-flex align-items-center w-25 '>
                            <img src={soloTrader} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                            <h2 className='text-blue text-center'>Sole Trader</h2>
                        </div>
                        <div className='row'>
                            <div className='col-6'>

                                <div className='col-lg-4'>
                                    <div className='mb-3'>
                                        <h4 className='text-black text-center fw-bold'>VAT No:</h4>
                                        {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.vat_no}</h4>)}
                                    </div>
                                    {isEditing && (
                                        <>
                                            <Controller
                                                name="vat_no"
                                                control={controlForm}
                                                render={({ field }) => (
                                                    <input
                                                        {...field}
                                                        type="text"
                                                        className={`form-control mt-1 ${errorsForm.vat_no ? 'is-invalid' : ''}`}
                                                        placeholder=""
                                                        onChange={(e) => {
                                                            handleChange('vat_no', e.target.value);
                                                            field.onChange(e);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errorsForm?.vat_no && (
                                                <div className="invalid-feedback">{errorsForm?.vat_no?.message}</div>
                                            )}
                                        </>
                                    )}
                                </div>

                                <div className='col-lg-2'>
                                    <div className='d-flex align-items-center mt-4'>
                                        <h6 className='text-black fw-bold'>Trading As:</h6>
                                        {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.trading_as}</h4>)}
                                    </div>
                                    {isEditing && (
                                        <>
                                            <Controller
                                                name="trading_as"
                                                control={controlForm}
                                                render={({ field }) => (
                                                    <input
                                                        {...field}
                                                        type="text"
                                                        className={`form-control mt-1 ${errorsForm.trading_as ? 'is-invalid' : ''}`}
                                                        placeholder=""
                                                        onChange={(e) => {
                                                            handleChange('trading_as', e.target.value);
                                                            field.onChange(e);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errorsForm?.trading_as && (
                                                <div className="invalid-feedback">{errorsForm?.trading_as?.message}</div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>


                            <div className='col-6'>
                                <div className='d-flex '>
                                    <h2 className='text-blue text-center'>Responses</h2>
                                </div>
                                {personalDetails?.vat_details ? (
                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <div className='d-flex align-items-center mt-4'>
                                                    <h6 className='text-black fw-bold'>First Name:</h6>
                                                    <h4 className='text-black' style={{ marginLeft: 5 }}>{personalDetails?.vat_details?.name || personalDetails?.vat_details?.traderName}</h4>
                                                </div>
                                            </div>
                                            {
                                                personalDetails?.vat_details?.address?.postcode &&
                                                    <div className='col-lg-5'>
                                                        <div className='d-flex align-items-center mt-4'>
                                                            <h6 className='text-black fw-bold'>Postal Code:</h6>
                                                            <h4 className='text-black' style={{ marginLeft: 5 }}>{personalDetails?.vat_details?.address?.postcode}</h4>
                                                        </div>
                                                    </div>
                                            }
                                            <div className='col-lg-7'>
                                                <div className='d-flex align-items-center mt-4'>
                                                    <h6 className='text-black fw-bold'>Address:</h6>
                                                    <h4 className='text-black' style={{ marginLeft: 5 }}>{ (personalDetails.vat_details?.traderAddress) ? personalDetails.vat_details?.traderAddress : `${personalDetails?.vat_details?.address?.line1}, ${personalDetails?.vat_details?.address?.line2}, ${personalDetails?.vat_details?.address?.line3}, ${personalDetails?.vat_details?.address?.line4}`}</h4>
                                                </div>
                                            </div>
                                            <div className='col-lg-4'>
                                                <div className='mb-3'>
                                                    <h4 className='text-black text-center'>VAT No:</h4>
                                                    <h4 className='text-black' style={{ marginLeft: 5 }}>{personalDetails?.vat_details?.vatNumber}</h4>
                                                </div>
                                            </div>
                                            <div className='col-lg-4'>
                                                <div className='d-flex align-items-center mt-4'>
                                                    <h6 className='text-black fw-bold'>Country:</h6>
                                                    <h4 className='text-black' style={{ marginLeft: 5 }}>{personalDetails?.vat_details?.address?.countryCode || personalDetails?.vat_details?.countryCode}</h4>
                                                </div>
                                            </div>
                                        </div>
                                ) : (
                                    <div className='text-center'>
                                        <p>No VAT details available.</p>
                                    </div>
                                )}

                            </div>
                        </div>

                        <hr />
                        <div className='d-flex align-items-center '>
                            <img src={pepFlag} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                            <h2 className='text-blue text-center'>Politically Exposed Person (PEP)</h2>
                        </div>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Why you are a PEP:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.politically_exposed_source_of_wealth}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="politically_exposed_source_of_wealth"
                                            control={controlForm}
                                            defaultValue={currentValues['politically_exposed_source_of_wealth']}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.politically_exposed_source_of_wealth ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        field.onChange(e); // Important: This is necessary to update the form value
                                                        handleChange('politically_exposed_source_of_wealth', e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.politically_exposed_source_of_wealth && (
                                            <div className="invalid-feedback">{errorsForm?.politically_exposed_source_of_wealth?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>PEP Level:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.politically_exposed_pep}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="politically_exposed_pep"
                                            control={controlForm}
                                            defaultValue={currentValues['politically_exposed_pep']}
                                            render={({ field }) => (
                                                <select
                                                    {...field}
                                                    id="statusDropdown"
                                                    className={`form-control form-select ${errorsForm.politically_exposed_pep ? 'is-invalid' : ''}`}
                                                    onChange={(e) => {
                                                        field.onChange(e); // Important: This is necessary to update the form value
                                                        handleChange('politically_exposed_pep', e.target.value);
                                                    }}
                                                >
                                                    <option value=''>Please Select PEP Level</option>
                                                    <option value={'PEP1'}>PEP1</option>
                                                    <option value={'PEP2'}>PEP2</option>
                                                    <option value={'PEP3'}>PEP3</option>
                                                    <option value={'PEP4'}>PEP4</option>
                                                </select>
                                            )}
                                        />
                                        {errorsForm?.politically_exposed_pep && (
                                            <div className="invalid-feedback">{errorsForm?.politically_exposed_pep?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>

                        </div>
                        <hr />
                        <div className='d-flex align-items-center '>
                            <img src={hmiw} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                            <h2 className='text-blue text-center'>High Net worth Individual</h2>
                        </div>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Net worth:</h6>
                                    {!isEditing && (<h4 className='text-black' style={{ marginLeft: 5 }}>{currentValues?.high_net_value}</h4>)}
                                </div>
                                {isEditing && (
                                    <><Controller
                                        name="high_net_value"
                                        control={controlForm}
                                        render={({ field }) => (
                                            <select
                                                {...field}
                                                id="statusDropdown"
                                                className={`form-control form-select ${errorsForm.high_net_value ? 'is-invalid' : ''}`}
                                                onChange={(e) => {
                                                    field.onChange(e); // Important: This is necessary to update the form value
                                                    handleChange('high_net_value', e.target.value);
                                                }}
                                            >
                                                <option value="">Select High Net Worth</option>
                                                <option value="0-1">High Net Worth 0-1</option>
                                                <option value="1-5">High Net Worth 1-5</option>
                                                <option value="5-10">High Net Worth 5-10</option>
                                                <option value="10-15">High Net Worth 10-15</option>
                                                <option value="15-20">High Net Worth 15-20</option>
                                                <option value="20+">High Net Worth 20+</option>
                                            </select>
                                        )}
                                    />
                                        {errorsForm?.high_net_value && (
                                            <div className="invalid-feedback">{errorsForm?.high_net_value?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='mt-4'>
                                    <h6 className='text-black fw-bold'>Source of Wealth:</h6>
                                    {!isEditing && (<h4 className='text-black mt-1'>{currentValues?.high_net_source_of_wealth}</h4>)}
                                </div>
                                {isEditing && (
                                    <><Controller
                                        name="high_net_source_of_wealth"
                                        control={controlForm}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                className={`form-control mt-1 ${errorsForm.high_net_source_of_wealth ? 'is-invalid' : ''}`}
                                                placeholder=""
                                                onChange={(e) => {
                                                    field.onChange(e); // Important: This is necessary to update the form value
                                                    handleChange('high_net_source_of_wealth', e.target.value);
                                                }}
                                            />
                                        )}
                                    />
                                        {errorsForm?.high_net_source_of_wealth && (
                                            <div className="invalid-feedback">{errorsForm?.high_net_source_of_wealth?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='mt-4'>
                                    <h6 className='text-black fw-bold'>Source of Income:</h6>
                                    {!isEditing && (<h4 className='text-black mt-1'>{currentValues?.high_net_source_of_income}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="high_net_source_of_income"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.high_net_source_of_income ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        field.onChange(e); // Important: This is necessary to update the form value
                                                        handleChange('high_net_source_of_income', e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.high_net_source_of_income && (
                                            <div className="invalid-feedback">{errorsForm?.high_net_source_of_income?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        {/* {isEditing && (<button style={{ marginTop: 20 }} onClick={() => {
                        handleEditClick();
                        handleSaveChanges();
                    }} className='btn-primary fw-normal'>Save</button>)} */}

                        <hr />
                        <div className='d-flex align-items-center'>
                            <img src={socialIcons} style={{ width: 25, objectFit: 'contain', marginRight: 15 }} />
                            <h2 className='text-blue text-center'>social media links</h2>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Facebook:</h6>
                                    {!isEditing && (<h4 className='text-black mt-1 ' style={{ marginLeft: 6 }} >{currentValues?.facebook_link}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="facebook_link"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.facebook_link ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        handleChange('facebook_link', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.facebook_link && (
                                            <div className="invalid-feedback">{errorsForm?.facebook_link?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-6'>
                                <div className=' d-flex align-items-center mt-4'>
                                    <h6 className='text-black fw-bold'>Linkdin:</h6>
                                    {!isEditing && (<h4 className='text-black mt-1' style={{ marginLeft: 6 }}>{currentValues?.linkedin_link}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="linkedin_link"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.linkedin_link ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        handleChange('linkedin_link', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.linkedin_link && (
                                            <div className="invalid-feedback">{errorsForm?.linkedin_link?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-6'>
                                <div className='mt-4 d-flex align-items-center'>
                                    <h6 className='text-black fw-bold'>Instagram:</h6>
                                    {!isEditing && (<h4 className='text-black mt-1' style={{ marginLeft: 6 }}>{currentValues?.instagram_link}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="instagram_link"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.instagram_link ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        handleChange('instagram_link', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.instagram_link && (
                                            <div className="invalid-feedback">{errorsForm?.instagram_link?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='col-lg-6'>
                                <div className='mt-4 d-flex align-items-center'>
                                    <h6 className='text-black fw-bold'>Twitter:</h6>
                                    {!isEditing && (<h4 className='text-black mt-1' style={{ marginLeft: 6 }}>{currentValues?.twitter_link}</h4>)}
                                </div>
                                {isEditing && (
                                    <>
                                        <Controller
                                            name="twitter_link"
                                            control={controlForm}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className={`form-control mt-1 ${errorsForm.twitter_link ? 'is-invalid' : ''}`}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        handleChange('twitter_link', e.target.value);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errorsForm?.twitter_link && (
                                            <div className="invalid-feedback">{errorsForm?.twitter_link?.message}</div>
                                        )}
                                    </>
                                )}
                            </div>

                        </div>
                        {isEditing && (<button style={{ marginTop: 20 }} type="submit" className='btn-primary fw-normal'>Save</button>)}
                    </div>
                </div>
            </form>
            {
                !viewOnly &&             
            <div className='white-background p-3 my-3'>
                <form className='form' onSubmit={handleSubmitCommentForm(onCommentFormSubmit)}>
                    <div className='d-flex align-items-center'>
                        <img src={ScreenActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15 }} />
                        <h2 className='text-ambed text-center'>Add Comments</h2>
                    </div>
                    <hr />
                    <div className={`d-flex mb-2 ${errorsCommentForm?.commentType ? 'is-invalid' : ''}`}>
                        { (decodedToken?.roles?.permissions?.add_general_comment || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
                            <div style={{ marginRight: 30 }}>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" {...registerCommentForm('commentType')} id="flexRadioDefault2" value="open" />
                                    <label className="form-check-label" for="flexRadioDefault1">Open</label>
                                </div>
                            </div>
                        }
                        {
                            (decodedToken?.roles?.permissions?.add_aml_comment || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) && <div style={{ marginRight: 30 }} >
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" {...registerCommentForm('commentType')} id="flexRadioDefault1" value="aml" />
                                    <label className="form-check-label" for="flexRadioDefault1">AML</label>
                                </div>
                            </div>
                        }
                        {
                            (decodedToken?.roles?.permissions?.add_ghost_comment || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) && <div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" {...registerCommentForm('commentType')} id="flexRadioDefault3" value="ghost" />
                                    <label className="form-check-label" for="flexRadioDefault1">Ghost</label>
                                </div>
                            </div>
                        }
                        {errorsForm?.commentType && (
                            <div className="invalid-feedback">{errorsForm?.commentType?.message}</div>
                        )}
                    </div>

                    <input type="hidden" {...registerCommentForm(`entityClientId`, { value: entityClientId })} />
                    <input type="hidden" {...registerCommentForm(`screen`, { value: 'personal_details' })} />

                    {errorsCommentForm?.commentType &&
                        <span className="invalid-feedback" >Comment Type is required</span>
                    }
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row align-items-end'>
                                <div className='col-lg-9' style={{ width: '80%' }}>
                                    <div className="form-group mt-3">
                                        <label className="form-label" for="exampleInputEmail1">Add Comment</label>
                                        <textarea type="text" {...registerCommentForm('comment')} className={`form-control ${errorsCommentForm?.comment ? 'is-invalid' : ''}`} id="exampleInputEmail1" placeholder="Add Comment" rows="2" />
                                        {errorsCommentForm?.commentType &&
                                            <span className="invalid-feedback" >Comment Type is required</span>
                                        }
                                    </div>
                                </div>
                                <div className='col-lg-3' style={{ width: '20%' }}>
                                    <button type="submit" className='btn-primary fw-normal w-100'>Add Comment</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            }
            <Modal show={show} size='sm' onHide={handleClose} centered>
                <Modal.Header className=' mt-2 pb-3'>
                    <div className="modal-form">
                        <div className='form-group'>
                            <h3 className="modal-title mb-2 pb-2">Add Comment !</h3>
                            <h6 className='text-black'>Are you sure you want to add this comment</h6>
                        </div>
                    </div>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 6, marginRight: 8, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Footer >
                    <button type="button" onClick={handleClose} className="btn btn-primary w-50 mb-3">Confirm</button>
                </Modal.Footer>
            </Modal>

            {changedFields.length > 0 &&
                <Modal show={show2} size='md' onHide={handleClose2} centered>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Modal.Header className=' mt-2 pb-3'>
                            <div className="modal-form">
                                <div className='form-group' style={{ marginBottom: 35 }}>
                                    <h3 className="modal-title mb-2 pb-2">Following changes has been made</h3>
                                    <h6 className='text-black'>Are you sure yo want to make the changes that are mentioned below!</h6>
                                </div>
                                {
                                    changedFields.map((obj, index) => {
                                        const fieldName = obj.column_name;
                                        const fieldValue = obj.new_value;
                                        const uniqueKey = `${obj.table_name}_${fieldName}_${index}`;
                                        let currentIndex;

                                        if (obj.table_name === 'entity_client') {
                                            currentIndex = entityClientIndex++;
                                        } else if (obj.table_name === 'entity_client_address') {
                                            currentIndex = entityClientAddressIndex++;
                                        }

                                        return (
                                            <div key={uniqueKey} className='row align-items-center mb-2'>
                                                <div className='col-lg-6'>
                                                    <div className='d-flex align-items-center'>
                                                        <h6 className='text-black fw-bold'>{fieldName.split('_')
                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                            .join(' ')} : </h6>
                                                        <h4 className='text-black' style={{ marginLeft: 5 }}>{obj.value_text}</h4>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <Controller
                                                        control={control}
                                                        name={`[${obj.table_name}][${currentIndex}].reason`}
                                                        rules={{ required: 'Comment is required' }}
                                                        render={({ field, fieldState: { error } }) => (
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    className={`form-control mt-1 ${error ? 'is-invalid' : ''}`}
                                                                    placeholder='Comment'
                                                                    {...field}
                                                                />
                                                                {error && (
                                                                    <div className="invalid-feedback">{error.message}</div>
                                                                )}
                                                            </>
                                                        )}
                                                    />

                                                    <input
                                                        type="hidden"
                                                        {...register(`[${obj.table_name}][${currentIndex}].new_value`, { value: fieldValue })} // Set the initial value using the "value" prop
                                                    />

                                                    <input
                                                        type="hidden"
                                                        {...register(`[${obj.table_name}][${currentIndex}].old_value`, { value: obj.old_value })} // Set the initial value using the "value" prop
                                                    />

                                                    <input
                                                        type="hidden"
                                                        {...register(`[${obj.table_name}][${currentIndex}].column_name`, { value: fieldName })} // Set the initial value using the "value" prop
                                                    />

                                                    <input
                                                        type="hidden"
                                                        {...register(`[${obj.table_name}][${currentIndex}].screen`, { value: 'basic' })} // Set the initial value using the "value" prop
                                                    />

                                                    {(obj?.type && obj.table_name === 'entity_client_address') &&
                                                      <input
                                                      type="hidden"
                                                      {...register(`[${obj.table_name}][${currentIndex}].type`, { value: obj?.type })} // Set the initial value using the "value" prop
                                                  />  
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div onClick={handleClose2} className='position-absolute top-0 end-0'>
                                <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 6, marginRight: 8, cursor: 'pointer' }} />
                            </div>
                        </Modal.Header>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-primary w-50 mb-3">Save and Continue</button>
                        </Modal.Footer>
                    </form>
                </Modal>
            }
        </>
    )
}

export default IndividualDetails
