import React from 'react';
import ReportHeader from '../../assets/images/report_header.png'
import './IndividualReport.scss';
import LogoIcon from '../../assets/images/LogoIcon.png'

function IndividualReport({ reportcustomerlabel, customername, onboardedlabel, onboardedtime, idnolabel, idno, text1, text2, text3, text4, text5 }) {
    return (
        <div className='individualreport-css'>
            <img src={LogoIcon} className='logoicon' />
            <img src={ReportHeader} className='reportheaderimg' />
            <div style={{ marginTop: 32 }}>
                <p className='text2'>{reportcustomerlabel}</p>
                <div className='blueline w-100'></div>
                <p className='text1'>{customername}</p>
            </div>
            <div style={{ marginTop: 22 }}>
                <p className='text2'>{onboardedlabel}</p>
                <div className='blueline w-100'></div>
                <p className='text1'>{onboardedtime}</p>
            </div>
            <div style={{ marginTop: 22 }}>
                <p className='text2'>{idnolabel}</p>
                <div className='blueline w-100'></div>
                <p className='text1'>{idno}</p>
            </div>
            <div className='greyline w-100'></div>
            <p className='text3' style={{ marginTop: 30 }}>{text1} </p>
            <p className='text3' style={{ marginTop: 14, color: '#F50100' }}>{text2}
            </p>
            <p className='text3' style={{ marginTop: 14 }}>{text3}
            </p>
            <p className='text3' style={{ marginTop: 14 }}>{text4} <span className='boldbluetext'>{text5}</span>
            </p>
        </div>
    )
}

export default IndividualReport