import React, { useEffect, useState } from 'react'
import Logo from '../../assets/images/logo.png'
import LoginLeftImage from '../../assets/images/login_left_image.png'
import ApproveTick from '../../assets/images/Approve_tick.png'
import CancelIcon from '../../assets/images/Cancel_icon.png'
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import './Registration.scss'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Loader from '../../components/Loader/Loader';
import { countryList, registerEnterpriseAdmin, sendSignupOtp } from './registrationSlice';
import countryCodes from './countryCode.json';
import { FaEye, FaEyeSlash } from "react-icons/fa";
// import industries from './industries.json';
import { decodeToken, useJwt } from "react-jwt";
import Select from 'react-select';

const industries = [
    {"label": "Accountants", "value": "Accountants"},
    {"label": "Antique Dealer", "value": "Antique Dealer"},
    {"label": "Art Dealer / Gallery", "value": "Art Dealer / Gallery"},
    {"label": "Auction House", "value": "Auction House"},
    {"label": "Bank / Building Society", "value": "Bank / Building Society"},
    {"label": "Bullion dealer", "value": "Bullion dealer"},
    {"label": "Car Dealership", "value": "Car Dealership"},
    {"label": "Casino - online & offline", "value": "Casino - online & offline"},
    {"label": "Cheque Cashing Business", "value": "Cheque Cashing Business"},
    {"label": "Company / Trust Formation Company", "value": "Company / Trust Formation Company"},
    {"label": "Credit Unions", "value": "Credit Unions"},
    {"label": "Credit / Debit / Prepaid Card Provider", "value": "Credit / Debit / Prepaid Card Provider"},
    {"label": "Crowd Funding Platform", "value": "Crowd Funding Platform"},
    {"label": "Crypto Exchange / Broker", "value": "Crypto Exchange / Broker"},
    {"label": "Crypto / Custodial Wallet company", "value": "Crypto / Custodial Wallet company"},
    {"label": "Currency Exchange", "value": "Currency Exchange"},
    {"label": "Debt Collection Agency", "value": "Debt Collection Agency"},
    {"label": "Diamond / Precious Stones intermediaries", "value": "Diamond / Precious Stones intermediaries"},
    {"label": "Financial Services Industry", "value": "Financial Services Industry"},
    {"label": "FX Company", "value": "FX Company"},
    {"label": "Gambling / Gaming Company", "value": "Gambling / Gaming Company"},
    {"label": "High Value Dealer", "value": "High Value Dealer"},
    {"label": "Insurance Industry", "value": "Insurance Industry"},
    {"label": "Investment Firms", "value": "Investment Firms"},
    {"label": "Jeweller / Watch Dealer", "value": "Jeweller / Watch Dealer"},
    {"label": "Lawyers / Solicitors / Barrister", "value": "Lawyers / Solicitors / Barrister"},
    {"label": "Money Services Business (MSB)", "value": "Money Services Business (MSB)"},
    {"label": "Money Transmitter / Remittance Company", "value": "Money Transmitter / Remittance Company"},
    {"label": "Multi National / Transa National Company", "value": "Multi National / Transa National Company"},
    {"label": "Notary", "value": "Notary"},
    {"label": "Pawn Shop", "value": "Pawn Shop"},
    {"label": "Payments Business", "value": "Payments Business"},
    {"label": "Property - Real Estate Agent / Broker", "value": "Property - Real Estate Agent / Broker"},
    {"label": "Property - Developer", "value": "Property - Developer"},
    {"label": "Remittance Company", "value": "Remittance Company"},
    {"label": "Retailer (High Value Goods)", "value": "Retailer (High Value Goods)"},
    {"label": "Venture Capital Firm", "value": "Venture Capital Firm"},
    {"label": "Yacht Broker", "value": "Yacht Broker"},
    {"label": "Other", "value": "Other"}
];

const designations = [
    { "label": "C-Suite Job (CXO) - Other", "value": "C-Suite Job (CXO) - Other" },
    { "label": "Chief Commercial Officer (CCO)", "value": "Chief Commercial Officer (CCO)" },
    { "label": "Chief Executive Officer (CEO)", "value": "Chief Executive Officer (CEO)" },
    { "label": "Chief Financial Officer (CFO)", "value": "Chief Financial Officer (CFO)" },
    { "label": "Chief Investment Officer (CIO)", "value": "Chief Investment Officer (CIO)" },
    { "label": "Chief Marketing Officer (CMO)", "value": "Chief Marketing Officer (CMO)" },
    { "label": "Chief Operating Officer (COO)", "value": "Chief Operating Officer (COO)" },
    { "label": "Chief Technical Officer (CTO)", "value": "Chief Technical Officer (CTO)" },
    { "label": "Director", "value": "Director" },
    {"label": "Entrepreneur", "value":"Entrepreneur"},
    { "label": "Managing Director", "value": "Managing Director" },
    { "label": "Managing Partner", "value": "Managing Partner" },
    { "label": "Non-Executive Director", "value": "Non-Executive Director" },
    { "label": "Partner", "value": "Partner" },
];

function Reigistration() {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [otpSent  , setOtpSent] = useState(false);
    const [formData, setFormData] = useState({});
    const [otpValue  , setOtpValue] = useState(null);
    const [passwordType, setPasswordType] = useState("password");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const { userToken } = useSelector(state => state.loginReducer);
    const history = useNavigate();
    const { isLoading, countryData } = useSelector(state => state.registerReducer);
    const schema = yup.object().shape({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        designation: yup.string().required('Designation is required'),
        country: yup.number().typeError('Invalid Country').required('Country is required'),
        companyName: yup.string().required('Company Name is required'),
        companyRegistrationNo: yup.string().required('Company Registration No is required').max(23, 'Company No must be at most 23 digits'),
        email: yup.string().email('Invalid email address').matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email address').required('Email is required'),
        address: yup.string().required('Address is required'),
        mobileNumber: yup
        .number()
        .typeError('Mobile Number must be a number')
        .required('Mobile No is required')
        .test(
          'len',
          'Mobile No must be at most 15 digits',
          val => val && val.toString().length <= 15
        ),
        industry: yup.string().required('Industry is required'),
        password: yup.string().required('Password is required').matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/,
            'Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.'
        ),
        mobileCode: yup.string().typeError('Invalid Mobile Code').required('Mobile Code is required'),
        landlineCode: yup.string(),
        landlineNumber: yup.string().typeError('Invalid Landline Number').when('landlineCode', {
            is: (val) => typeof val === 'string' && val.trim() !== '',
            then: () => yup.string().required('Landline Number is required').max(15, 'Landline Number must be at most 15 digits')
        })
    });
    const { control, register, handleSubmit, formState: { errors }, reset, setValue, getValues, trigger } = useForm({
        resolver: yupResolver(schema),
    });

    const mobileCodeValue = getValues('mobileCode');
    const landLineCodeValue = getValues('landlineCode');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/login`;
        navigate(path);
    }

    useEffect(()=>{
        dispatch(countryList());
    },[])

    useEffect(() => {
        if (userToken) {
            history("/", { replace: true });
        }
    }, []);
    
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            padding: '0.4rem 0.50rem',
            borderRadius: '10px',
            border: '1px solid #bcdae6',
            lineHeight: 1.5,
            color: '#037AD0',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            '&:hover': {
                border: '2px solid #B3B3B3',
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '10px',
            border: '2px solid #D9D9D9',
            color: '#037AD0',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#037AD0',
        }),
    };

    const onSubmit = (data) => {
        if(!otpValue){
           const company_registration_no = data.companyRegistrationNo
            let requestData = { "type": "email", "email": data.email,company_registration_no, "mobile": data.mobileNumber, mobileCode : data.mobileCode};
            dispatch(sendSignupOtp(requestData)).unwrap().then((res) => {
                if(res.status){
                    setOtpSent(true);
                    setFormData(data);
                }else{
                    toast.error(res?.message);
                }
            }).catch((error) => {
                toast.error(error?.message);
            })
        }else{
            data.otp = otpValue;
            dispatch(registerEnterpriseAdmin(data))
                .unwrap()
                .then((res) => {
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('refresh', res.data.refresh);
                    const decodedToken = decodeToken(res.data.token);
                    localStorage.setItem('activePlan', decodedToken.activePlan);
                    toast.success(res?.message)
                    reset();
                    history('/pricing');
                }).catch((error) => {
                    toast.error(error?.message);
                })
        }
    }

    const resendOTP = () => {
        if (formData) {
            const company_registration_no = formData.companyRegistrationNo;
            let requestData = { "type": "email", "email": formData.email, company_registration_no, "mobile": formData.mobileNumber, mobileCode : formData.mobileCode}
            dispatch(sendSignupOtp(requestData)).unwrap().then((res) => {
                toast.success(res?.message)
            }).catch((error) => {
                toast.error(error?.message);
            })
        }
    }

    return (
        <section className="vh-100 registration" >
            {isLoading && <Loader/>}
            <div className="container-fluid h-100 ">
                <div className="row d-flex justify-content-between align-items-center " style={{ height: '130%' }}>
                    <div className="col-md-8 col-lg-7 col-xl-6 h-100 d-flex justify-content-center align-items-center imagecontainer" style={{ backgroundColor: '#E4F1F6', borderTopRightRadius: 50, borderBottomRightRadius: 50 }}>
                        <img src={LoginLeftImage} className='loginImage' />
                    </div>
                    <div className="col-md-7 col-lg-5 col-xl-5 form-registration my-2">
                        <form className='form' onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <img src={Logo} className="logo mt-3" alt="Sample image" />
                            </div>
                            <h1 className='text-blue text-blue-login my-4'>
                                Register Now
                            </h1>
                            <div className="row" style={{display:otpSent?'none':''}}>
                                <div className="form-group col-md-6 mb-4">
                                    <label className="form-label" for="form3Example3">First Name</label>
                                    {/* <input type="text" id="form3Example3" className="form-control form-control-lg"
                                        placeholder="First Name" /> */}

                                    <input
                                        type="text"
                                        className={`form-control form-control-lg ${errors.firstName ? 'is-invalid' : ''}`}
                                        placeholder="First Name"
                                        {...register('firstName', {
                                            required: `Please Enter First Name`
                                        })}
                                    />
                                    {errors?.firstName && (
                                        <div className="invalid-feedback">{errors?.firstName?.message}</div>
                                    )}
                                </div>
                                <div className="form-group col-md-6 mb-4">
                                    <label className="form-label" for="form3Example3">Last Name</label>
                                    {/* <input type="text" id="form3Example3" className="form-control form-control-lg"
                                        placeholder="Last Name" /> */}

                                    <input
                                        type="text"
                                        className={`form-control form-control-lg ${errors.lastName ? 'is-invalid' : ''}`}
                                        placeholder="Last Name"
                                        {...register('lastName', {
                                            required: `Please Enter Last Name`
                                        })}
                                    />
                                    {errors?.lastName && (
                                        <div className="invalid-feedback">{errors?.lastName?.message}</div>
                                    )}

                                </div>
                            </div>
                            <div className="row" style={{display:otpSent?'none':''}}>
                                <div className="form-group col-md-6 mb-4">
                                    <label className="form-label" for="form3Example3">Designation</label>
                                    <Controller
                                        name="designation"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                id="designationDropdown"
                                                placeholder="Select Designation"
                                                className={`${errors.designation ? 'is-invalid' : ''}`}
                                                options={designations}
                                                styles={customStyles}
                                                value={field.value ? designations.find(designation => designation.value === field.value) :  ''}
                                                onChange={(selectedOption) => {
                                                    field.onChange(selectedOption?.value);
                                                }}
                                            />
                                        )}
                                    />
                                    {errors?.designation && (
                                        <div className="invalid-feedback">{errors?.designation?.message}</div>
                                    )}
                                </div>
                                <div className="form-group col-md-6 mb-4">
                                    <label className="form-label" for="form3Example3">Country</label>
                                    {/* <select id="statusDropdown" className="form-control form-select">
                                        <option selected>Select Country</option>
                                        {countryData.map((country) => (
                                            <option key={country.country_id} value={country.country_id}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </select> */}

                                    <Controller
                                        name="country"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                id="country_id"
                                                placeholder="Select Country"
                                                className={`${errors.country ? 'is-invalid' : ''}`}
                                                options={countryData.map(country => ({
                                                    label: country.name,
                                                    value: country.country_id
                                                }))}
                                                styles={customStyles}
                                                value={field.value ? { label: countryData.find(country => country.country_id == field.value)?.name, value: field.value } : ''}
                                                onChange={(selectedOption) => {
                                                    field.onChange(selectedOption?.value);
                                                }}
                                            />
                                        )}
                                    />
                                    {errors?.country && (
                                        <div className="invalid-feedback">{errors?.country?.message}</div>
                                    )}
                                </div>
                            </div>
                            <div className="row" style={{display:otpSent?'none':''}}>
                                <div className="form-group col-md-6 mb-4">
                                    <label className="form-label" for="form3Example3">Company Name</label>
                                    {/* <input type="text" id="form3Example3" className="form-control form-control-lg"
                                        placeholder="Company Name" /> */}
                                    <input
                                        type="text"
                                        className={`form-control form-control-lg ${errors.companyName ? 'is-invalid' : ''}`}
                                        placeholder="Company Name"
                                        {...register('companyName', {
                                            required: `Please Enter Company Name`
                                        })}
                                    />
                                    {errors?.companyName && (
                                        <div className="invalid-feedback">{errors?.companyName?.message}</div>
                                    )}
                                </div>
                                <div className="form-group col-md-6 mb-4">
                                    <label className="form-label" for="form3Example3">Company Registration Number</label>
                                    {/* <input type="text" id="form3Example3" className="form-control form-control-lg"
                                        placeholder="Company Reg No *" /> */}
                                    <input
                                        type="text"
                                        className={`form-control form-control-lg ${errors.companyRegistrationNo ? 'is-invalid' : ''}`}
                                        placeholder="Company Reg No"
                                        {...register('companyRegistrationNo', {
                                            required: `Please Enter Company Registration No`
                                        })}
                                    />
                                    {errors?.companyRegistrationNo && (
                                        <div className="invalid-feedback">{errors?.companyRegistrationNo?.message}</div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className={`form-group ${otpSent?'col-md-12 ':'col-md-6 '} mb-4`}>
                                    <div className="form-outline">
                                        <label className="form-label" for="form3Example3">Email</label>

                                        <input
                                            type="text"
                                            className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`}
                                            placeholder="Enter a valid email address"
                                            {...register('email', {
                                                required: `Enter a valid email address`
                                            })}
                                        />
                                        {errors?.email && (
                                            <div className="invalid-feedback">{errors?.email?.message}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group col-md-6 mb-4" style={{display:otpSent?'none':''}}>
                                    <label className="form-label" for="form3Example3">Industry</label>
                                    <Controller
                                        name="industry"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                id="industry"
                                                placeholder="Select Industry"
                                                className={`${errors.industry ? 'is-invalid' : ''}`}
                                                options={industries}
                                                styles={customStyles}
                                                value={field.value ? { label: industries.find(industry => industry.value == field.value)?.label, value: field.value } : ''}
                                                onChange={(selectedOption) => {
                                                    field.onChange(selectedOption?.value);
                                                }}
                                            />
                                        )}
                                    />

                                    {errors?.industry && (
                                        <div className="invalid-feedback">{errors?.industry?.message}</div>
                                    )}
                                </div>
                            </div>

                            <div className="form-outline mb-4" style={{display:otpSent?'none':''}} >
                                <label className="form-label" for="form3Example3">Address</label>
                                {/* <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Enter Address'></textarea> */}

                                <Controller
                                    name="address"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className={`form-control form-control-lg ${errors.address ? 'is-invalid' : ''}`}
                                            rows="3"
                                            placeholder='Enter Address'
                                            {...field}
                                        />
                                    )}
                                />
                                {errors?.address && (
                                    <div className="invalid-feedback">{errors?.address?.message}</div>
                                )}
                            </div>

                            <div className="row" style={{display:otpSent?'none':''}}>
                                <div className="form-group col-md-6 mb-4">
                                    <label className="form-label" for="form3Example3">Mobile Number *</label>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            {/* <input type="text" id="form3Example3" className="form-control form-control-lg"
                                                placeholder="+91" /> */}

                                            <Controller
                                                name="mobileCode"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        className={` ${errors.mobileCode ? 'is-invalid' : ''}`}
                                                        {...field}
                                                        options={countryData.map(country => ({
                                                            label: `${country.name} (${country.country_code})`,
                                                            value: country.country_code
                                                        }))}
                                                        styles={customStyles}
                                                        value={mobileCodeValue ? { label: `${mobileCodeValue} `, value: mobileCodeValue } : ''}
                                                        onChange={(selectedOption) => {
                                                            setValue('mobileCode', selectedOption?.value);
                                                            trigger('mobileCode');
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors?.mobileCode && (
                                                <div className="invalid-feedback">{errors?.mobileCode?.message}</div>
                                            )}
                                        </div>
                                        <div className='col-md-6'>
                                            {/* <input type="text" id="form3Example3" className="form-control form-control-lg"
                                                placeholder="000 000 0000" /> */}
                                            <input
                                                type="text"
                                                className={`form-control form-control-lg ${errors.mobileNumber ? 'is-invalid' : ''}`}
                                                placeholder="000 000 0000"
                                                name="mobileNumber"
                                                {...register('mobileNumber')}
                                            />
                                            {errors?.mobileNumber && (
                                                <div className="invalid-feedback">{errors?.mobileNumber?.message}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-6 mb-4">
                                    <label className="form-label" for="form3Example3">Landline Number</label>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            {/* <input type="text" id="form3Example3" className="form-control form-control-lg"
                                                placeholder="+91" /> */}
                                            {/* <input
                                                type="text"
                                                className={`form-control form-control-lg ${errors.landlineCode ? 'is-invalid' : ''}`}
                                                placeholder="+91"
                                                {...register('landlineCode', {
                                                    required: `Please select Landline Code`
                                                })}
                                            /> */}

                                            <Controller
                                                name="landlineCode"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        placeholder="select"
                                                        className={`${errors.landlineCode ? 'is-invalid' : ''}`}
                                                        // options={Object.keys(countryCodes).map((country) => ({
                                                        //     label: `${country} (${countryCodes[country]})`,
                                                        //     value: countryCodes[country]
                                                        // }))}
                                                        options={countryData.map(country => ({
                                                            label: `${country.name} (${country.country_code})`,
                                                            value: country.country_code
                                                        }))}
                                                        styles={customStyles}
                                                        value={landLineCodeValue ? { label: `${landLineCodeValue}`, value: landLineCodeValue } : ''}
                                                        onChange={(selectedOption) => {
                                                            setValue('landlineCode', selectedOption?.value);
                                                            trigger('landlineCode');
                                                        }}
                                                    />
                                                )}
                                            />


                                            {errors?.landlineCode && (
                                                <div className="invalid-feedback">{errors?.landlineCode?.message}</div>
                                            )}
                                        </div>
                                        <div className='col-md-6'>
                                            {/* <input type="text" id="form3Example3" className="form-control form-control-lg"
                                                placeholder="000 000 0000" /> */}
                                            <input
                                                type="text"
                                                className={`form-control form-control-lg ${errors.landlineNumber ? 'is-invalid' : ''}`}
                                                placeholder="000 000 0000"
                                                {...register('landlineNumber', {
                                                    required: `Please select Landline Number`
                                                })}
                                            />
                                            {errors?.landlineNumber && (
                                                <div className="invalid-feedback">{errors?.landlineNumber?.message}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12 mb-4 position-relative">
                                    <label className="form-label" for="form3Example3">Password</label>

                                    <div className='col-md-12'>
                                        <input
                                            type={passwordType}
                                            className={`form-control form-control-lg ${errors.password ? 'is-invalid' : ''}`}
                                            placeholder=""
                                            {...register('password', {
                                                required: `Enter Password`
                                            })}
                                        />
                                        {errors?.password && (
                                            <div className="invalid-feedback">{errors?.password?.message}</div>
                                        )}
                                    </div>
                                    <div className='eye-icon' onClick={togglePassword}>
                                        {passwordType === "password" ? <FaEyeSlash size={25} color='#037AD0' /> : <FaEye color='#037AD0' size={25} />}
                                    </div>

                                </div>
                            </div>
                            {otpSent &&
                                <div className="form-group col-md-12 mb-4">
                                    <div className="form-outline">
                                        <label className="form-label">Verification Code</label>
                                        <input
                                            type="text"
                                            className={`form-control form-control-lg}`}
                                            placeholder='Enter Verification Code'
                                            onKeyUp={(event)=>setOtpValue(event.target.value)}
                                        />
                                        {(!otpValue || otpValue == null) && (
                                            <div className="invalid-feedback" style={{display:'block'}}>{'Enter a valid OTP'}</div>
                                        )}
                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                            <a className="text-blue resend" style={{ cursor: "pointer", marginTop: 12 }} onClick={resendOTP} >Resend OTP</a>
                                        </div>

                                    </div>
                                </div>
                            }
                            {/* onClick={handleShow} */}

                            <div className="text-center text-lg-start mt-4 mb-4 pt-2 w-100">
                                <button type="submit" className="btn btn-primary btn-lg btn-block w-100">{otpSent?'Create Account':'Continue to Verify Email'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header >
                    <img src={ApproveTick} style={{ width: 96, objectFit: 'contain' }} />
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-blue text-center'><b>Registration Successful.</b><br />
                        Please check you Email for login details</p>
                </Modal.Body>
                <Modal.Footer >
                    <button onClick={routeChange} type="button" className="btn btn-primary w-50 mb-3">Go to Login </button>
                </Modal.Footer>
            </Modal>
        </section>
    )
}

export default Reigistration
