import React from 'react';
import './DetailsInfo.scss';

function DetailsInfo({ mainimage, namelabel, nametext, idlabel, idtext, tradingnamelabel, tradingname, vatlabel, vatno, typelabels, typename1, typename2, consentlabel, imagesource1, formtext, imagesource2, biometrictext, imagesource3, amltext }) {
    return (
        <>
            <div className='detailsinfo-css'>
                <div className='detailsbox d-flex'>
                    <div className='col-2'>
                        <img ref={mainimage} className='profile-icon' />
                    </div>
                    <div className='col-10'>
                        <p className='text1'>{namelabel}<span className='text2' style={{ fontWeight: 400 }}>{nametext}</span></p>
                        <div className='blueline w-100'></div>
                        <p className='text1' style={{ marginTop: 5 }}>{idlabel}<span className='text2' style={{ fontWeight: 400 }}>{idtext}</span></p>
                        <div className='d-flex justify-content-between'>
                            <p className='text1'>{tradingnamelabel}<span className='text2' style={{ fontWeight: 400 }}>{tradingname}</span></p>
                            <p className='text1'>{vatlabel}<span className='text2' style={{ fontWeight: 400 }}>{vatno}</span></p>
                        </div>
                        <div className='blueline w-100'></div>
                        <div className='d-flex'>
                            <p className='text1' style={{ marginTop: 5 }}>{typelabels}</p>
                            <div className='d-flex' style={{ marginLeft: 5 }}>
                                {typename1 && (
                                    <div className='boxes'>
                                        <p className='textvalue'>{typename1}</p>
                                    </div>
                                )}

                                {typename2 && (
                                    <div className='boxes' style={{ backgroundColor: '#037AD0' }}>
                                        <p className='textvalue'>{typename2}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='d-flex'>
                            <p className='text1' style={{ marginTop: 5 }}>{consentlabel}</p>
                            <div className='d-flex' style={{ marginLeft: 5 }}>
                                <img src={imagesource1} className='tick-circle' />
                                <p className='text2'>{formtext}</p>
                            </div>
                            <div className='d-flex' style={{ marginLeft: 5 }}>
                                <img src={imagesource2} className='tick-circle' />
                                <p className='text2'>{biometrictext}</p>
                            </div>
                        </div>
                        <div className='d-flex' style={{ marginLeft: -5 }}>
                            <img src={imagesource3} className='tick-circle' />
                            <p className='text1' style={{ marginLeft: 4, marginTop: 1 }}>{amltext}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailsInfo