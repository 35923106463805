import React from 'react'
import OnboardClientBar from '../../../components/OnboardClientBar/OnboardClientBar'
import ApproveTick from '../../../assets/images/Approve_tick.png'
import { useNavigate } from 'react-router-dom';

function CorporateApplicationDone() {
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/create_profile`;
        navigate(path);
    }
    return (
        <div className='grey-background vh-100'>
            <OnboardClientBar
                mainTitle={`Onboard Business Client `}
                title1={`COMPANY DETAILS`}
                title2={`COMPANY INFORMATION`}
                title3={`DIRECTOR LIST`}
                title4={`SHAREHOLDER LIST`}
                backgroundColor1={'#037AD0'}
                backgroundColor2={'#037AD0'}
                backgroundColor3={'#037AD0'}
                backgroundColor4={'#037AD0'}
            />
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='white-background p-3 mt-4'>
                        <div className='d-flex align-items-center'>
                            <h1 className='text-blue fw-normal'>Application Done</h1>
                        </div>
                        <hr />
                        <div className='d-flex flex-column align-items-center my-5'>
                            <img src={ApproveTick} style={{ width: 90, objectFit: 'contain', marginTop: 20 }} />
                            <h1 className='text-blue text-center fw-normal mt-4'>Application has been successfully
                                <br />submitted for processing</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end my-4'>
                <div className='d-flex align-items-center'>
                    <button onClick={routeChange} className='btn-primary'>Save</button>
                </div>
            </div>
        </div>
    )
}

export default CorporateApplicationDone
