import React from 'react';
import './PdfTable.scss';
import InfoIcon from '../../assets/images/info-icon.png'
import FlagIcon from '../../assets/images/uk_flag_icon.png';

function DoubleTable({ header1, header2, type, text, subHeading, headers, SmallBox, Subheading, useCol6, blueText, ImageBox,showFlag,tableHeader }) {

    const isURL = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    return (
        <div className='doubleTableCSS'>
            {tableHeader!=false &&<div className='row table-header' style={{marginRight:0}}>
                <div className='col-xl-2'>
                    <div className='heading-text'>{header1}</div>
                </div>
                <div className='col-xl-10'>
                    <div className='heading-text'>{header2}</div>
                </div>
            </div>}
            <div className='row table-rows no-gutters' style={{marginRight:0}}>
                <div className='col-xl-2 p-0' style={{ border: '0.5px solid #E5E5E5' }}>
                    {SmallBox !== false && (
                        <div className='table-row'>
                            <div className={type === 'RELEVANT' ? 'table-green-class' : 'table-grey-class'}>{type}</div>
                        </div>
                    )}
                </div>
                <div className='col-xl-10 p-0'>
                    <div className='table-padding small-text-table'>{text}</div>
                    <div className='d-flex' style={{marginLeft:20}} >
                        {showFlag &&<img src={FlagIcon} className='flag-icon' />}
                        <p className='blue-text'>{blueText}</p>
                    </div>
                    {ImageBox &&
                        <div className='d-flex' style={{ paddingLeft: 20 }} >
                            <img src={InfoIcon} className='icon-img' />
                            <p className='medium-text-table' style={{ marginBottom: 4 }} >listed 17 december 2019-present</p>
                        </div>
                    }
                    {Subheading && <div className='small-header heading-text'>{subHeading}</div>}
                    <div className='row no-gutters' >
                        {headers.map((data, index) => (
                            <React.Fragment key={index}>
                                <div className={`${useCol6 ? 'col-xl-5' : 'col-xl-2'} table-rows no-gutters`}>
                                    <div className=' table-padding heading-text'>{data?.name}</div>
                                </div>
                                <div className={`${useCol6 ? 'col-xl-7' : 'col-xl-10'} table-rows no-gutters`}>
                                    <div className={`table-padding  ${isURL(data?.value) ? 'blue-text blue-text1' : 'medium-text-table'}`}>{data?.value}</div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DoubleTable;
