import React from 'react';
import { Bar } from 'react-chartjs-2';

function BarGraph() {
    const gradientFill1 = 'rgba(21, 112, 239, 1)';
    const gradientFill2 = 'rgba(255, 98, 107, 1)';
    const gradientFill3 = 'rgba(3, 121, 36, 1)';
    const gradientFill4 = 'rgba(217, 217, 217, 1)';

    const shadowPlugin = [
        {
            id: 'shadow',
            beforeDraw: (chart) => {
                const { ctx } = chart;
                const _fill = ctx.fill;
                ctx.fill = function () {
                    ctx.save();
                    ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
                    ctx.shadowBlur = 10;
                    ctx.shadowOffsetX = 2;
                    ctx.shadowOffsetY = 3;
                    _fill.apply(this, arguments);
                    ctx.restore();
                };
            },
        },
    ];

    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                ticks: {
                    display: false,
                    borderWidth: 0,
                },
                grid: {
                    display: false,
                    borderWidth: 0,
                },
                offset: {
                    display: false,
                },
            },
            x: {
                grid: {
                    display: false,
                    borderWidth: 0,
                },
                ticks: {
                    padding: 10,
                    fontColor: 'rgba(0,0,0,0.5)',
                    fontStyle: 'bold',
                },
            },
        },
    };

    const data = {
        labels: ['Registered', 'Onboarded', 'Mitigated', 'Pending'],
        datasets: [
            {
                label: 'SLP',
                data: [35000, 46000, 40000, 41000],
                backgroundColor: [gradientFill1, gradientFill2, gradientFill3, gradientFill4],
                borderRadius: 15,
                barPercentage: 0.5,
            },
        ],
    };

    return (
        <Bar
            options={options}
            data={data}
            plugins={shadowPlugin}
        />
    );
}

export default BarGraph;
