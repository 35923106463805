import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import ThumbIcon from '../../assets/images/thumb_icon.png'
import CancelIcon from '../../assets/images/Cancel_icon.png'
import UploadDocumentIcon from '../../assets/images/client_upload_icon.png'
import BackIcon from '../../assets/images/back-btn-arrow.png';
import { useNavigate } from 'react-router-dom';

function PaymentReceived() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const clients = [
        {
            name: '12/12/2023',
            type: 'Inv 123456789',
            currentBalance: '- $600',
            currentInvoice: '+ $600',
        },
        {
            name: '12/12/2023',
            type: 'Payment Received',
            currentBalance: '+ $600',
            currentInvoice: '- $600',
        },
        {
            name: '12/12/2023',
            type: 'Inv 123456789',
            currentBalance: '- $600',
            currentInvoice: '+ $600',
        },
        {
            name: '12/12/2023',
            type: 'Payment Received',
            currentBalance: '+ $600',
            currentInvoice: '- $600',
        },
    ];
    const navigateToPrevious = () => {
        navigate(-1);
    }
    return (
        <>
            <div className='grey-background'>
            <button onClick={navigateToPrevious} className='back-button text-blue'><img src={BackIcon} style={{width:8,marginBottom:2,marginRight:4,objectFit:'contain'}}/> Back</button>
                <div className='white-background p-2 my-3'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <h1 className='text-blue px-2 fw-normal'>Payment Received</h1>
                        <button className="btn btn-primary" onClick={handleShow}>Adjustments</button>
                    </div>
                </div>
                <div className='white-background p-3 mb-3'>
                    <div className='row align-items-end'>
                        <div className='col-lg-10 col-md-10'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <div className="form-group">
                                        <label className="form-label" for="exampleInputEmail1">Amount</label>
                                        <input type="text" className="form-control"></input>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <div className="form-group">
                                        <label className="form-label" for="exampleInputEmail1">Date received</label>
                                        <input type="text" className="form-control"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-2 justify-content-end'>
                            <button className="btn btn-primary w-100" style={{ fontSize: 14 }}>Add</button>
                        </div>
                    </div>
                </div>

                <div className='custom-table'>
                    <div className='custom-table-head'>
                        <div className='row'>
                            <div className='col'>
                                <h6 className='text-blue'>Date</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Invoice/Payment</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Amount</h6>
                            </div>
                            <div className='col'>
                                <h6 className='text-blue'>Balance</h6>
                            </div>
                        </div>
                    </div>
                    {clients.map((client, index) => (
                        <div className='custom-table-body' key={index}>
                            <div className='row align-items-center'>
                                <div className='col'>
                                    <h6 className='text-black'>{client.name}</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-black'>{client.type}</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-black'>{client.currentBalance}</h6>
                                </div>
                                <div className='col'>
                                    <h6 className='text-black'>{client.currentInvoice}</h6>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='d-flex align-items-center justify-content-end'>
                    <span className="badge bg-primary-badge">1</span>
                    <span className="badge">2</span>
                    <span className="badge">3</span>
                    <span className="badge">4</span>
                    <span className="badge"><i className="fa fa-chevron-right"></i></span>
                </div>
            </div>
            {/* <Modal show={show} onHide={handleClose} centered >
                <Modal.Header>
                    <h3 className='text-blue text-center mt-3 mb-0 fw-normal'>Changes done successfully !!</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center'>

                    </div>

                    <div className="d-flex justify-content-center">
                        <button className="btn-primary w-50 my-3">Ok</button>
                    </div>
                </Modal.Body>
            </Modal> */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header className=' mt-2 pb-3'>
                    <div className="modal-form">
                        <div className='form-group'>
                            <h3 className="modal-title mb-2 pb-2 text-center">Adjustments</h3>
                            <div className='d-flex align-items-center justify-content-start mb-3'>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label class="form-check-label" for="flexRadioDefault1">Debit Note</label>
                                </div>
                                <div class="form-check mx-3">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label class="form-check-label" for="flexRadioDefault1">Credit Note</label>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <input type="text" className="form-control" placeholder='Amount'></input>
                            </div>
                            <div className="form-group mb-3">
                                <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Reason" rows="2" />
                            </div>
                            <div className='white-background p-4'>
                                <div className='d-flex align-items-center'>
                                    <img src={UploadDocumentIcon} style={{ width: 50, objectFit: 'contain', marginRight: 10 }} />
                                    <div>
                                        <h6 className='text-success' style={{ fontWeight: '600', textDecorationLine: 'underline', marginBottom: 7 }}>Upload File</h6>
                                        <h4>PDF, JPEG or PNG less than 5MB</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={handleClose} className='position-absolute top-0 end-0'>
                        <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 6, marginRight: 8, cursor: 'pointer' }} />
                    </div>
                </Modal.Header>
                <Modal.Footer >
                    <button type="button" onClick={handleClose} className="btn btn-primary w-50 mb-3">Submit</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PaymentReceived