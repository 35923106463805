import React, { useEffect, useRef, useState } from 'react'
import TopResults from '../../../components/Aml/TopResults'
import ResultCard from '../../../components/Aml/ResultCard'
import ScreeningActionIcon from '../../../assets/images/screeing_action.png'
import { useNavigate } from 'react-router-dom';
import ResultsTopCard from '../../../components/Aml/ResultsTopCard';
import ResultsTab from '../../../components/Aml/ResultsTab';
import MasterAmlComments from '../../../components/Aml/MasterAmlComments';
import { useForm } from 'react-hook-form';
import * as  yup from 'yup';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { decodeToken } from 'react-jwt';
import { useDispatch, useSelector } from 'react-redux';
import { addScreeningComments, getAdverseMedia, getAllGlobalSearchById, getGlobalSearchById, getSearchCertificate, removeRelevantResult, requestDocument, resetGlobalSearchDetails, saveAdverseMediaComment, saveAmlInfoComment, saveExcludedAdverseMedia, saveRelevantResult, uploadDocument, uploadMediaImage,fetchAmlComments, fetchUploadedDocuments, getEntityCertificate} from '../IndividualScreeningSlice';
import Loader from '../../../components/Loader/Loader';
import { fetchEntityClientPersonalDetail } from '../../Profile/profileSlice';
import TablePagination from '../../../components/Pagination';
import uploadDoc from '../../../assets/images/doc_img.png';
import HeaderBox from '../../../components/headerbox/HeaderBox';
import TableComponent from '../../../components/TableComponent/TableComponent';
import FooterBox from '../../../components/footerBox/FooterBox';
import SubHeaderBox from '../../../components/SubheaderBox/SubHeaderBox';
import MatchContentData from '../../../components/contentBox/MatchContentData';
import CautionImg from '../../../assets/images/caution-image.png';
import InfoImg from '../../../assets/images/info-icon.png';
import PersonIcon from '../../../assets/images/person-icon.png';
import CommentIcon from '../../../assets/images/comment-icon.png';
import DocumentIcon from '../../../assets/images/document-icon.png';
import moment from 'moment';
import html2pdf from 'html2pdf.js';
import DoubleTable from '../../../components/PdfTable.js/DoubleTable';

function IndividualAmlDetails({ entityClientId, viewOnly }) {
    const { isLoading: screeningLoader, globalSearchDetail, globalSearches, adverseMedias,amlComments, searchCertificateDetails, entityCertificateDetails } = useSelector(state => state.individualScreeningReducer);
    const { isLoading, personalDetails } = useSelector(state => state.ProfileReducer);
    const [singleDetails, setSingleDetails] = useState(false)
    const [masterComments, setMasterComments] = useState(false)
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPge, setPostsPerPage] = useState(1);
    const [uploadedFileInfo, setUploadedFileInfo] = useState(null)
    const [uploadedFileName, setUploadedFileName] = useState('');
    const tabledata7 = [
        {
            text1: 'Search Term(s):',
            text2: searchCertificateDetails?.create_search_details?.search_term || '',
        },
        {
            text1: 'User Reference:',
            text2: searchCertificateDetails?.create_search_details?.ref || '',
        },
        {
            text1: 'Search ID:',
            text2: searchCertificateDetails?.search_id || '',
        },
        {
            text1: 'Performed on:',
            text2: moment(searchCertificateDetails?.create_search_details?.created_at).format("DD:MM:YYYY HH:MM:SS"), //'[Insert Date (DD:MM:YYYY) and time (HH:MM:SS]',
        },
        {
            text1: 'Performed by:',
            text2: searchCertificateDetails?.action_performed_on || '',
        },
        {
            text1: 'Match status:',
            text2: searchCertificateDetails?.create_search_details?.match_status,
        },
        {
            text1: 'Continuous Monitoring:',
            text2: searchCertificateDetails?.continous_monitoring ? 'Yes' : 'No',
        },
        {
            text1: 'Searching:',
            text2: 'Sanctions',
        },
        {
            text1: 'filters',
            boxtext1: 'Types:',
            boxtext2: 'Sanctions',
            boxtext3: 'Warning',
            boxtext4: 'Fitness-probity',
            boxtext5: 'PEP-class-1',
            boxtext6: 'PEP-class-2',
            boxtext7: 'PEP-class-3',
            boxtext8: 'PEP-class-4',
            boxtext9: 'Fuzziness Interval: (80%)',
            boxsubtext1: 'Adverse-media-V2-cybercrime',
            boxsubtext2: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext3: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext4: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext5: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext6: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext7: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext8: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext9: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext10: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext11: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext12: 'Adverse-media-V2-violence-non-aml-cft',
        }
    ]

    const tabledata8 = [
        {
            text1: 'Search created:',
            text2: personalDetails?.create_search_details?.created_at,  //'[Insert Date (DD:MM:YYYY) and time (HH:MM:SS]',
        },
        {
            text1: 'Search Term(s):',
            text2: personalDetails?.create_search_details?.search_term,
        },
        {
            text1: 'Client Reference:',
            text2: personalDetails?.oomero_id,
        },
        {
            text1: 'Search ref:',
            text2: personalDetails?.create_search_details?.ref,
        },
        {
            text1: 'Entity ID:',
            text2: personalDetails?.search_id,
        },
        {
            text1: 'Fuzziness interval:',
            text2: (personalDetails?.create_search_details?.filters?.fuzziness ?? 0) * 100 + '%',
        },
    ]

    const handlePagination = (event, value) => {
        setCurrentPage(value);
    }

    const setPages = (value) => {
        setCurrentPage(value)
    }

    const commentSchema = yup.object().shape({
        commentType: yup.string().required('Comment Type is required'),
        comment: yup.string().required('Comment is required'),
    });

    const { control: controlCommentForm, register: registerCommentForm, handleSubmit: handleSubmitCommentForm, formState: { errors: errorsCommentForm }, reset: resetCommentForm, setValue: setValueComment } = useForm({
        resolver: yupResolver(commentSchema),
    });

    useEffect(() => {
        dispatch(getAllGlobalSearchById(`?page=${currentPage}&limit=10&entity_client_id=${entityClientId}`)).unwrap().catch((error) => {
            toast.error(error?.message);
        });    
    }, [currentPage]);

    const fetchSearchDetails = (unique_id, globalSearchResultId) => {
        setSingleDetails(true);

        dispatch(resetGlobalSearchDetails());

        dispatch(getGlobalSearchById(`?entity_client_id=${entityClientId}&unique_id=${unique_id}`)).unwrap().then((obj) => {
            toast.success(obj?.message || 'Data Fetched Successfully');
        }).catch(error => {
            toast.error(error.message || 'Unable to fetch data');
        });
        
        dispatch(fetchEntityClientPersonalDetail({ queryParam: `?entity_client_id=${entityClientId}` })).unwrap().catch((error) => {
            toast.error(error?.message);
        });
    }

    const onCommentFormSubmit = (data) => {
        resetCommentForm();
        dispatch(addScreeningComments({ payload: data })).unwrap().then((obj) => {
            toast.success(obj?.message || 'Comment Added Successfully');
        }).catch(error => {
            toast.error(error.message || 'Unable to add comments');
        });
    }

    const requestDocumentSchema = yup.object().shape({
        documentType: yup.string().required('Document Type is required'),
    });

    const { control: controlRequestDocumentForm, register: registerRequestDocumentForm, handleSubmit: handleSubmitRequestDocumentForm, formState: { errors: errorsRequestDocumentForm }, reset: resetRequestDocumentForm, setValue: setValueRequestDocument } = useForm({
        resolver: yupResolver(requestDocumentSchema),
    });

    const onRequestDocumentFormSubmit = (data) => {
        resetRequestDocumentForm();
        dispatch(requestDocument(`?entityClientId=${data?.entityClientId}&documentType=${data.documentType}`)).unwrap().then((obj) => {
            toast.success(obj?.message || 'Document Requested Successfully');
        }).catch(error => {
            toast.error(error.message || 'Unable to request document');
        });
    }

    const uploadDocumentSchema = yup.object().shape({
        documentType: yup.string().required('Document Type is required'),
        document: yup.string()
        .test('fileSize', 'File size is max 5MB', value => {
            if (!value) return true;
            const fileSizeInBytes = (value.length * 3) / 4 - 2; 
            return fileSizeInBytes <= 5 * 1024 * 1024; 
        })
        .test('fileType', 'Only supported file type of pdf, doc,jpg,png', value => {
            if (!value) return true; 
            const fileTypeMappings = {
                'data:application/pdf': 'pdf',
                'data:application/msword': 'doc',
                'data:image/jpeg': 'jpg',
                'data:image/png': 'png'
            };
            const base64Header = value.substring(0, 30); 
            for (const [typeHeader, fileType] of Object.entries(fileTypeMappings)) {
                if (base64Header.startsWith(typeHeader)) {
                    return true; 
                }
            }
            return false; 
        })
        .required('Document is required'),
        screen: yup.string().required('Screen is required'),
    });

    const { control: controlUploadDocumentForm, register: registerUploadDocumentForm, handleSubmit: handleSubmitUploadDocumentForm, formState: { errors: errorsUploadDocumentForm }, reset: resetUploadDocumentForm, setValue: setValueUploadDocument, trigger } = useForm({
        resolver: yupResolver(uploadDocumentSchema),
    });

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();

            // Set up FileReader onload callback
            reader.onload = () => {
                // FileReader onload callback is triggered when reading is done
                const base64String = reader.result;
                setValueUploadDocument('document', base64String);
                trigger('document');
            };
            setUploadedFileName(file.name);
            // Read the file as a data URL, triggering the onload callback when done
            reader.readAsDataURL(file);
        }
    };

    const onUploadDocumentFormSubmit = (data) => {
        const filename = uploadedFileName;
        const payload = { ...data, filename };
        dispatch(uploadDocument(payload)).unwrap().then((obj) => {     
            resetUploadDocumentForm({document:null,documentType:null})
            const fileName = uploadedFileName;
            const uploadDate = new Date().toLocaleString();
            const uploadedBy = decodedToken?.firstName;

            setUploadedFileInfo({
                fileName,
                uploadDate,
                uploadedBy,
            });
            toast.success(obj?.message || 'Document Uploaded Successfully');
        }).catch(error => {
            toast.error(error.message || 'Unable to upload document');
        });
    }

    const saveRelevantResultFunction = (globalSearchResultId) => {
        dispatch(saveRelevantResult({ queryParams: `?entity_client_id=${entityClientId}`, payload: { global_search_result_id: globalSearchResultId } })).unwrap().then((obj) => {
            toast.success(obj?.message || 'Successfully done');

            dispatch(getAllGlobalSearchById(`?page=${currentPage}&limit=10&entity_client_id=${entityClientId}`)).unwrap().catch((error) => {
                toast.error(error?.message);
            }); 
        }).catch(error => {
            toast.error(error.message || 'Something Went Wrong');
        });
    }

    const removeRelevantResultFunction = (globalSearchResultId) => {
        dispatch(removeRelevantResult({ queryParams: `?entity_client_id=${entityClientId}`, payload: { global_search_result_id: globalSearchResultId } })).unwrap().then((obj) => {
            toast.success(obj?.message || 'Successfully done');
            dispatch(getAllGlobalSearchById(`?page=${currentPage}&limit=10&entity_client_id=${entityClientId}`)).unwrap().catch((error) => {
                toast.error(error?.message);
            }); 
        }).catch(error => {
            toast.error(error.message || 'Something Went Wrong');
        });
    }

    const uploadMediaImageFunction = (globalSearchResultId, payload) => {
        dispatch(uploadMediaImage({ queryParams: `?global_search_result_id=${globalSearchResultId}`, payload })).unwrap().then((obj) => {
            toast.success(obj?.message || 'Successfully done');
        }).catch(error => {
            toast.error(error.message || 'Something Went Wrong');
        });
    }

    const getSearchCertificateFunc = (entity_client_id) => {
        if (!entity_client_id) {
            toast.error('Something Went Wrong');
            return;
        }

        dispatch(getSearchCertificate({ queryParams: `?entity_client_id=${entity_client_id}` })).unwrap().then(async (response) => {
            const element = document.getElementById('content-to-pdf-search-certificate');
            const loader = document.getElementById('pdf-loader');
            if (loader) loader.style.display = 'block';

            if (!element) {
                console.error("Element with ID 'content-to-pdf-search-certificate' not found.");
                if (loader) loader.style.display = 'none';
                return;
            }

            const customHeight = 297;
            let calculatedWidth = 250;

            const options = {
                margin: [9, 9, 9, 9],
                filename: 'Search Certificate.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 1.7, useCORS: true },
                jsPDF: { unit: 'mm', format: [calculatedWidth, customHeight], orientation: 'portrait' }
            };

            html2pdf().from(element).set(options).save().then(() => {
                toast.success('PDF downloaded successfully.');
            }).catch(error => {
                console.error('Error generating PDF:', error);
                toast.error(error.message || "Error generating PDF ");
            }).finally(() => {
                // Hide the loader
                if (loader) loader.style.display = 'none';
            });
        }).catch(error => {
            toast.error(error.message || 'Something Went Wrong');
        });
    }

    const getEntityCertificateFunc = (entity_client_id) => {
        if (!entity_client_id) {
            toast.error('Something Went Wrong');
            return;
        }

        // dispatch(getEntityCertificate({ queryParams: `?entity_client_id=${entity_client_id}` })).unwrap().then(async (response) => {
            const element = document.getElementById('content-to-pdf-entity-certificate');
            const loader = document.getElementById('pdf-loader');
            if (loader) loader.style.display = 'block';

            if (!element) {
                console.error("Element with ID 'content-to-pdf-entity-certificate' not found.");
                if (loader) loader.style.display = 'none';
                return;
            }

            const customHeight = 297;
            let calculatedWidth = 250;

            const options = {
                margin: [9, 9, 9, 9],
                filename: 'Entity Certificate.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 1.7, useCORS: true },
                jsPDF: { unit: 'mm', format: [calculatedWidth, customHeight], orientation: 'portrait' }
            };

            html2pdf().from(element).set(options).save().then(() => {
                toast.success('PDF downloaded successfully.');
            }).catch(error => {
                console.error('Error generating PDF:', error);
                toast.error(error.message || "Error generating PDF ");
            }).finally(() => {
                // Hide the loader
                if (loader) loader.style.display = 'none';
            });
        // }).catch(error => {
        //     toast.error(error.message || 'Something Went Wrong');
        // });
    }

    const saveAmlComments=(data)=>{
        dispatch(saveAmlInfoComment(data)).unwrap().then((obj) => {
            toast.success(obj?.message || 'Successfully done');
        }).catch(error => {
            toast.error(error.message || 'Something Went Wrong');
        });
    }
    const saveAdverseMediaComments=(data)=>{
        dispatch(saveAdverseMediaComment(data)).unwrap().then((obj) => {
            toast.success(obj?.message || 'Successfully done');
        }).catch(error => {
            toast.error(error.message || 'Something Went Wrong');
        });
    }
    const fetchAllAmlComments=(globalSearchResultId,section,currentPage,postsPerPge)=>{
        dispatch (fetchAmlComments(`?global_search_results_id=${globalSearchResultId}&limit=${postsPerPge}&page=${currentPage}&section=${section}`)).unwrap().catch((error) => {
            toast.error(error?.message);
        });
    }
    const fetchAdverseMedia=(globalSearchResultId,currentPage,postsPerPge)=>{
        dispatch(getAdverseMedia(`?global_search_result_id=${globalSearchResultId}&entity_client_id=${entityClientId}&limit=${postsPerPge}&page=${currentPage}`)).unwrap().catch((error) => {
            toast.error(error?.message);
        });
    }
    const getAmlDocuments=(entityClientId,currentPage,postsPerPge)=>{
        let queryParam = `?page=${currentPage}&entity_client_id=${entityClientId}&screen=amlInfo&limit=${postsPerPge}`;
        dispatch(fetchUploadedDocuments(queryParam)).unwrap().then((obj) => {
        }).catch(error => {
            toast.error(error.message || 'Unable to request document');
        });
    }

    const updateSearch = (data) => {
        let query = `?page=${currentPage}&limit=10&entity_client_id=${entityClientId}`;

        data.search = true;

        // Convert object to query parameters
        const queryParams = new URLSearchParams(data);

        // Append the parameters to the existing query string
        query += `&${queryParams.toString()}`;

        dispatch(getAllGlobalSearchById(query)).unwrap().catch((error) => {
            toast.error(error?.message);
        });
    }
    return (
        <>
        <div className='mt-3 pb-4'>
            <div id="pdf-loader" style={{ display: 'none' }}>
                <Loader />
            </div>
            {(screeningLoader || isLoading) && <Loader />}
            {!singleDetails &&
                <>
                    <TopResults getSearchCertificate={getSearchCertificateFunc} updateSearch={updateSearch} personalDetails={personalDetails} onClickComments={() => { setMasterComments(!masterComments) }} titleofMasterComments={masterComments ? `Go Back` : `Master Comments`} getAmlDocuments={getAmlDocuments} />
                    {!masterComments &&
                        <>
                            <div className='row my-3 d-flex'>
                                {
                                    globalSearches.rows.map((item, i) => (
                                        <div className='col-lg-4 d-flex' key={i}>
                                            {item && (
                                                <ResultCard
                                                    data={item}
                                                    removeRelevantResult={removeRelevantResultFunction}
                                                    saveRelevantResult={saveRelevantResultFunction}
                                                    onClick={() => fetchSearchDetails(item?.unique_id, item?.global_search_results_id
                                                    )}
                                                />
                                            )}
                                        </div>
                                    ))}

                            </div>
                            <div className='d-flex align-items-center justify-content-end'>
                                {globalSearches && globalSearches?.rows?.length > 0 &&
                                    <TablePagination totalPages={parseInt(globalSearches?.totalPages)} currentPage={currentPage} color="primary" handlePagination={handlePagination} />
                                }
                                {/* <span class="badge bg-primary-badge">1</span>
                                <span class="badge">2</span>
                                <span class="badge">3</span>
                                <span class="badge">4</span>
                                <span class="badge"><i class="fa fa-chevron-right"></i></span> */}
                            </div>
                            {!viewOnly &&
                            <div className='white-background p-3 mt-4'>
                                <form className='form' onSubmit={handleSubmitCommentForm(onCommentFormSubmit)}>
                                    <div className='d-flex align-items-center'>
                                        <img src={ScreeningActionIcon} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                                        <h1 className='text-ambed fw-normal'>Screening Actions</h1>
                                    </div>
                                    <hr />
                                    <div className={`d-flex mb-2 ${errorsCommentForm?.commentType ? 'is-invalid' : ''}`}>
                                        {/* <div style={{ marginRight: 30 }}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" {...registerCommentForm('commentType')} id="flexRadioDefault2" value="open" />
                                                <label className="form-check-label" for="flexRadioDefault1">Open</label>
                                            </div>
                                        </div> */}
                                        {(decodedToken?.roles?.permissions?.add_aml_comment || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
                                            <div style={{ marginRight: 30 }}>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" {...registerCommentForm('commentType')} id="flexRadioDefault1" value="aml" />
                                                    <label className="form-check-label" for="flexRadioDefault1">AML</label>
                                                </div>
                                            </div>
                                        }
                                        {
                                            (decodedToken?.roles?.permissions?.add_ghost_comment || decodedToken?.isEnterpriseAdmin || decodedToken?.isSuperAdmin) &&
                                            <div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" {...registerCommentForm('commentType')} id="flexRadioDefault3" value="ghost" />
                                                    <label className="form-check-label" for="flexRadioDefault1">Ghost</label>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <input type="hidden" {...registerCommentForm(`entityClientId`, { value: entityClientId })} />
                                    <input type="hidden" {...registerCommentForm(`screen`, { value: 'aml' })} />

                                    {errorsCommentForm?.commentType &&
                                        <span className="invalid-feedback" >Comment Type is required</span>
                                    }

                                    <div className='row align-items-end'>
                                        <div className='col-9'>
                                            <div className="form-group">
                                                <label className="form-label" for="exampleInputEmail1">Add Comment</label>
                                                <input type="text" {...registerCommentForm('comment')} className={`form-control ${errorsCommentForm?.comment ? 'is-invalid' : ''}`} id="exampleInputEmail1" placeholder="Add Comment" />
                                                {errorsCommentForm?.comment &&
                                                    <span className="invalid-feedback" >Comment is required</span>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <button className='btn-primary w-100'>Add Comment</button>
                                        </div>
                                    </div>
                                </form>
                                {/* <div className='row mt-3'>
                                    <div className='col-lg-4'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1">
                                                Whitelist this customer for all future TXNs
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                            <label className="form-check-label" for="defaultCheck1">
                                                Blacklist this customer for all future TXNs
                                            </label>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='row my-3'>
                                    <div className='col-lg-6'>
                                        <form onSubmit={handleSubmitUploadDocumentForm(onUploadDocumentFormSubmit)}>
                                            <div className='row align-items-end'>
                                                <div className='col-lg-7'>
                                                    <div className="form-group">
                                                        <label className="form-label" for="exampleFormControlSelect1">Upload Document</label>
                                                        <select className={`form-select first-select ${errorsUploadDocumentForm?.documentType ? 'is-invalid' : ''}`} aria-label="Default select example" {...registerUploadDocumentForm('documentType')}>
                                                            <option value=''>Select Document Type</option>
                                                            <option value="aadhaar">Aadhaar</option>
                                                            <option value="pancard">Pan Card</option>
                                                            <option value="driving_license">Driving License</option>
                                                        <option value="article">Article</option>
                                                        <option value="legal_information">Legal Information</option>
                                                        <option value="proof_of_mitigation">Proof of Mitigation</option>
                                                        <option value="proof_of_bank_letter">Proof of Bank Letter</option>
                                                        <option value="other_document">Other Document</option>
                                                        </select>
                                                        {errorsUploadDocumentForm?.documentType &&
                                                            <span className="invalid-feedback" >Document Type is required</span>
                                                        }
                                                    </div>
                                                </div>
                                                <input
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    onChange={handleFileChange}
                                                    ref={fileInputRef}
                                                    accept=".pdf,.doc,.jpg,.png"
                                                />
                                                <input
                                                    type="hidden"
                                                    {...registerUploadDocumentForm(`entityClientId`, { value: entityClientId })}
                                                />
                                                <input
                                                    type="hidden"
                                                    {...registerUploadDocumentForm(`document`)}
                                                />
                                                <input type="hidden" {...registerUploadDocumentForm(`screen`, { value: 'amlInfo' })} />

                                                <div className={`upload-document-box mt-3 ms-2 ${errorsUploadDocumentForm.document ? 'is-invalid' : ''}`} onClick={handleButtonClick}>
                                                    <div className="d-flex align-items-center">
                                                        <img src={uploadDoc} style={{ width: 32, objectFit: 'contain' }} alt="Upload" />
                                                        <div className='ms-2'>
                                                            <div className="upload-blue-text">Tap to upload receipt</div>
                                                            <div className="upload-grey-text">PDF, DOC, JPG, PNG (max. 5MB)</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {errorsUploadDocumentForm.document && <small className='text-danger'>{errorsUploadDocumentForm.document.message}</small>}
                                                {/*<div className='upload-document-box mt-3 ms-2'>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <img src={uploadDoc} style={{ width: 70, objectFit: 'contain' }} />
                                                        <div className='ms-2'>
                                                            <div className="upload-blue-text">Tap to upload receipt</div>
                                                            <div className="upload-grey-text">SVG, PNG, JPG or GIF (max. 800x400px)</div>
                                                        </div>
                                                    </div>
                                                </div>*/}
                                                {uploadedFileInfo && (
                                                <p className='subtext text-success' style={{fontSize:12}}>{uploadedFileInfo.fileName} ({uploadedFileInfo.uploadDate}) Uploaded by: {uploadedFileInfo.uploadedBy}</p>)}
                                                <button type="submit" className="btn btn-primary w-50 mt-3">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='col-lg-6'>
                                        <form onSubmit={handleSubmitRequestDocumentForm(onRequestDocumentFormSubmit)}>
                                            <div className='row align-items-end justify-content-end'>
                                                <div className='col-lg-7'>
                                                    <div className="form-group">
                                                        <label className="form-label" for="exampleFormControlSelect1">Request Document</label>
                                                        <select className={`form-select first-select ${errorsRequestDocumentForm?.documentType ? 'is-invalid' : ''}`} aria-label="Default select example" {...registerRequestDocumentForm('documentType')} >
                                                            <option value=''>Select Document Type</option>
                                                            <option value="aadhaar">Aadhaar</option>
                                                            <option value="pancard">Pan Card</option>
                                                            <option value="driving_license">Driving License</option>
                                                            <option value="article">Article</option>
                                                        <option value="legal_information">Legal Information</option>
                                                        <option value="proof_of_mitigation">Proof of Mitigation</option>
                                                        <option value="proof_of_bank_letter">Proof of Bank Letter</option>
                                                        <option value="other_document">Other Document</option>
                                                        </select>
                                                        {errorsRequestDocumentForm?.documentType &&
                                                            <span className="invalid-feedback" >Document Type is required</span>
                                                        }
                                                        <input type="hidden" {...registerRequestDocumentForm(`entityClientId`, { value: entityClientId })} />
                                                    </div>
                                                </div>
                                                <div className='col-5'>
                                                    <button className='btn-primary w-100'>Request Documents</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            }
                        </>
                    }
                </>
            }
            {
                singleDetails &&
                <>
                    <ResultsTopCard globalSearchDetail={globalSearchDetail} personalDetails={personalDetails} getEntityCertificate={getEntityCertificateFunc}/>
                    <div className='mt-4'>
                        <ResultsTab globalSearchDetail={globalSearchDetail} personalDetails={personalDetails} adverseMedias={adverseMedias} uploadMediaImage={uploadMediaImageFunction} saveAmlComments={saveAmlComments} saveAdverseMediaComments={saveAdverseMediaComments} fetchAllAmlComments={fetchAllAmlComments} amlComments={amlComments} fetchAdverseMedia={fetchAdverseMedia} />
                    </div></>
            }
            {
                masterComments &&
                <>
                    <div className='mt-4'>
                        <MasterAmlComments />
                    </div></>
            }
        </div>
        <div className='display-none'>
            <div className='projectreport-css'>
                <div id='content-to-pdf-search-certificate'>
                        <div className='page-break'>
                            <HeaderBox
                                showHeader={true}
                                headerName='Search Certificate'
                                showblueBox={true}
                                headerText='Search Details'
                            />
                            <div style={{ marginTop: 15 }}>
                                {tabledata7.map((data, index) => (
                                    <TableComponent
                                        {...data}
                                    />
                                ))}

                                <SubHeaderBox
                                    showBox1
                                    contentText={`${searchCertificateDetails?.amlInformation?.length || 0} Result(s) found for ${searchCertificateDetails?.create_search_details?.search_term}`}
                                />

                            </div>

                            <div style={{ marginTop: 15 }}>
                                {
                                    searchCertificateDetails?.amlInformation && searchCertificateDetails?.amlInformation.map((obj, index) => {
                                        return (<MatchContentData
                                            match_head1='Matched:'
                                            match_text1={obj?.name}
                                            imagesource1={CautionImg}
                                            match_head2='Matched: '
                                            match_text2={obj?.name}
                                            imagesource2={InfoImg}
                                            match_head3='Relevance:'
                                            match_text3={obj?.search_results?.match_types?.map(type => type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())).join(', ') || ''}
                                            imagesource3={PersonIcon}
                                            match_head4='Entity Type: '
                                            match_text4={(obj?.search_results?.doc?.entity_type || '').replace(/\b\w/g, char => char.toUpperCase())}
                                            matched={obj?.search_results?.doc?.types?.map(type => type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()))}
                                            imagesource4={CommentIcon}
                                            match_head5='Comments:'
                                            match_text5=''
                                            imagesource5={DocumentIcon}
                                            matchfoundText='Match found on'
                                            matchno={`${searchCertificateDetails?.amlInformation.length}`}
                                            listname='list'
                                        />)
                                    })
                                }
                            </div>

                            <FooterBox
                                footerText='Private and Confidential** '
                            />

                        </div>
                </div>
                <div id='content-to-pdf-entity-certificate'>
                    <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Entity Certificate'
                            showblueBox={true}
                            headerText='Search Information'
                        />

                                
                                    <div style={{ marginTop: 15 }}>
                                        <HeaderBox
                                            showHeader={true}
                                            showblueBox={true}
                                            headerText='Search Information'
                                        />
                                    </div>
                                    <div style={{ marginTop: 15 }}>
                                        {tabledata8.map((data, index) => (
                                            <TableComponent
                                                showBoxes={true}
                                                countriesText={true}
                                                insertText={true}
                                                {...data}
                                            />
                                        ))}
                                        {[{
                                            text1: 'case management',
                                            text3: 'Is Whitelisted:',
                                            text4: 'Match Status:',
                                            text5: 'Risk Level:',
                                            text6: (entityCertificateDetails?.is_whitelisted) ? 'yes' : 'no',
                                            text7: entityCertificateDetails?.create_search_details?.match_status,
                                            text8: entityCertificateDetails?.create_search_details?.risk_level,
                                        }, {
                                            text1: 'key information',
                                            text3: 'Full name:',
                                            text4: 'Entity type:',
                                            text5: 'Also Known As (AKA):',
                                            text9: 'Countries:',
                                            text6: globalSearchDetail?.name,
                                            text7: (globalSearchDetail?.search_results?.doc?.entity_type || '').replace(/\b\w/g, char => char.toUpperCase()),
                                            text8: globalSearchDetail?.search_results?.doc?.aka
                                                ? globalSearchDetail.search_results.doc.aka.map(item => item.name).join(', ')
                                                : '',
                                            text10: globalSearchDetail?.search_results?.doc?.fields.find(field => field.tag === "country_names")?.value || 'None'
                                        }].map((data, index) => (
                                            <TableComponent
                                                showBoxes={true}
                                                countriesText={true}
                                                insertText={true}
                                                {...data}
                                            />
                                        ))}
                                    </div>

                                    <div style={{ marginTop: 15 }}>
                                        <HeaderBox
                                            showHeader={true}
                                            // headerName='' //Bank Account Details
                                            showblueBox={true}
                                            headerText='LISTING'
                                        />
                                    </div>

                                    {globalSearchDetail && globalSearchDetail.listing && globalSearchDetail?.listing.map((item, index) => (
                                        <div>
                                            <DoubleTable
                                                header1={item?.title}
                                                header2="Currently on"
                                                blueText=""
                                                SmallBox={false}
                                                smallTableData={true}
                                                useCol6={true}
                                                headers={item?.fields}
                                            />
                                        </div>
                                    ))}
                                
                            


                        <FooterBox
                            footerText='Private and Confidential** '
                        />

                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default IndividualAmlDetails

