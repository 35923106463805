import React from 'react'
import TopResults from './TopResults'
import { Accordion } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

function MasterAmlComments() {
    return (
        <div>
            <div>
                <h1 className='text-black py-4'>Master Comments</h1>
                <Accordion className='client-services' defaultActiveKey={1}>
                    {
                        [1, 2, 3, 4, 5, 6, 7,8,9,10].map((index) => {
                            return (
                                <Accordion.Item eventKey={index} active>
                                    <Accordion.Header>
                                        <div className='d-flex align-items-center'>
                                            <h2 className='text-black' style={{ marginRight: 30 }}>Card:</h2>
                                            <h2 className='text-black'>1, 3, 7, 9 :</h2>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <h6 style={{ marginLeft: 75 }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</h6>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })
                    }
                </Accordion>
            </div>
            <div className='d-flex align-items-center justify-content-end'>
                <span class="badge bg-primary-badge">1</span>
                <span class="badge">2</span>
                <span class="badge">3</span>
                <span class="badge">4</span>
                <span class="badge"><i class="fa fa-chevron-right"></i></span>
            </div>
        </div>
    )
}

export default MasterAmlComments
