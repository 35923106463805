import React, { useEffect, useRef, useState } from 'react'
import PersonalDetailsData from '../../components/contentBox/PersonalDetailsData'
import MatchContentData from '../../components/contentBox/MatchContentData';
import CautionImg from '../../assets/images/caution-image.png';
import InfoImg from '../../assets/images/info-icon.png';
import PersonIcon from '../../assets/images/person-icon.png';
import CommentIcon from '../../assets/images/comment-icon.png';
import DocumentIcon from '../../assets/images/document-icon.png';
import EntityData from '../../components/contentBox/EntityData';
import BasicInfo from '../../components/contentBox/BasicInfo';
import BankDetailsData from '../../components/contentBox/BankDetailsData';
import TickCircle from '../../assets/images/tick-circle.png'
import ContentData from '../../components/contentBox/ContentData';
import DocumentDetails from '../../components/contentBox/DocumentDetails';
import PdfResidentialAddress from '../../components/contentBox/PdfResidentialAddress';
import BankInfoDetails from '../../components/contentBox/BankInfoDetails';
import HeaderBox from '../../components/headerbox/HeaderBox';
import TableComponent from '../../components/TableComponent/TableComponent';
import FooterBox from '../../components/footerBox/FooterBox';
import html2pdf from 'html2pdf.js';
import SubHeaderBox from '../../components/SubheaderBox/SubHeaderBox';
import DoubleTable from '../../components/PdfTable.js/DoubleTable';
import PdfTable from '../../components/PdfTable.js/PdfTable';
import TableImg from '../../assets/images/table_img.png';
import ProfilePhoto from '../../assets/images/profile_photo.png';
import './ProjectReport.scss'
import DetailsInfo from '../../components/DetailsInfo/DetailsInfo';
import IndividualReport from '../../components/individualReport/IndividualReport';
import InformationBox from '../../components/informationbox/InformationBox'
import FrontSideDoc from '../../assets/images/frontside_document.png';
import BackSideDoc from '../../assets/images/backside_document.png';
import PassportDoc from '../../assets/images/passport-document.png';
import SelfieDoc from '../../assets/images/selfie-document.png';
import ReferenceDoc from '../../assets/images/reference-document.png';
import ImageComponent from '../../components/ImageComponent/ImageComponent';
import TableComponent2 from '../../components/TableComponent/TableComponent2';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import moment from 'moment'
import { getSignedUrls } from '../../utils/awsHelper';
import userImg from "../../assets/images/defaultUser.jpg";

export const generatePdf = () => {
    const element = document.getElementById('content-to-pdf');
    const loader = document.getElementById('pdf-loader');
    if (loader) loader.style.display = 'block';

    if (!element) {
        console.error("Element with ID 'content-to-pdf' not found.");
        if (loader) loader.style.display = 'none';
        return;
    }

    const customHeight = 297;
    let calculatedWidth = 250;

    const options = {
        margin: [9, 9, 0, 9],
        filename: 'Report.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 1.7, useCORS: true },
        jsPDF: { unit: 'mm', format: [calculatedWidth, customHeight], orientation: 'portrait' }
    };

    html2pdf().from(element).set(options).save().then(() => {
        toast.success('PDF downloaded successfully.');
    }).catch(error => {
        console.error('Error generating PDF:', error);
        toast.error(error.message || "Error generating PDF ");
    }).finally(() => {
        // Hide the loader
        if (loader) loader.style.display = 'none';
    });
};

function ProjectReport({ getOnboardedClientReportData }) {
    // const personalImageRef = useRef(null);
    const getImgUrl = async (url) => {
        const baseUrl = url ? url.split('?')[0] : '';
        if (!baseUrl) {
            return '';
        }
        
        try {
            const signedUrl = await getSignedUrls(baseUrl);
            const response = await fetch(signedUrl);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob); // Convert blob to Base64
            });
        } catch (error) {
            console.error('Error converting image to Base64:', error);
            return '';
        }
    }

    // useEffect(() => {
    //     const fetchImageSrc = async () => {
    //         try {
    //             if (getOnboardedClientReportData?.personal_detail_document) {
    //                 const imageUrl = await getImgUrl(getOnboardedClientReportData?.personal_detail_document);
    //                 if (imageUrl && personalImageRef.current) {
    //                     console.log("success");
    //                     personalImageRef.current.src = imageUrl;
    //                 } else if (personalImageRef.current) {
    //                     console.log("other");
    //                     personalImageRef.current.src = userImg; // Fallback if imageUrl is invalid
    //                 }
    //             }
    //         } catch (error) {
    //             console.error('Error fetching image:', error);
    //             if (personalImageRef.current) {
    //                 personalImageRef.current.src = userImg; // Fallback on error
    //             }
    //         }
    //     };

    //     if (personalImageRef.current) {
    //         console.log("other fallback");
    //         personalImageRef.current.src = userImg; // Initial fallback
    //     }

    //     fetchImageSrc();
    // }, [getOnboardedClientReportData?.personal_detail_document, getImgUrl]);

    const tabledata1 = [
        {
            text1: 'MLRO / Admin Name',
            text2: getOnboardedClientReportData?.mlroComments?.first_name,
        },
        {
            text1: 'Role (Admin or MLRO)',
            text2: getOnboardedClientReportData?.mlroComments?.is_superadmin ? 'Superadmin' : ((getOnboardedClientReportData?.mlroComments?.is_enterprise_admin) ? 'Enterprise Admin' : getOnboardedClientReportData?.mlroComments?.role_name),
        },
        {
            text1: 'MLRO / Admin Email address',
            text2: getOnboardedClientReportData?.mlroComments?.email,
        },
        {
            text1: 'MLRO / admin Mobile No.',
            text2: getOnboardedClientReportData?.mlroComments?.mobile_code + getOnboardedClientReportData?.mlroComments?.mobile,
        },
        {
            text1: 'Company Name',
            text2: getOnboardedClientReportData?.mlroComments?.entity_name || '',
        },
        {
            text1: 'Company Address',
            text2: getOnboardedClientReportData?.mlroComments?.company_reg_address || '',
        },
        {
            text1: 'Company Registration No.',
            text2: getOnboardedClientReportData?.mlroComments?.entity_registration_no || '',
        },
        {
            text1: 'Company VAT no. (if any)',
            text2: '00000000000',
        },
        {
            text1: 'Director name ',
            text2: (getOnboardedClientReportData?.mlroComments?.directors_details?.first_name || '') +
                (getOnboardedClientReportData?.mlroComments?.directors_details?.last_name ? ' ' + getOnboardedClientReportData?.mlroComments?.directors_details?.last_name : '')
        },
        {
            text1: 'Director email Address',
            text2: getOnboardedClientReportData?.mlroComments?.directors_details?.email,
        },
        {
            text1: 'Director Mobile No.',
            text2: getOnboardedClientReportData?.mlroComments?.directors_details?.mobile_code + getOnboardedClientReportData?.mlroComments?.directors_details?.mobile,
        },
        {
            text1: 'Company landline',
            text2: '0000000000',
        },
        {
            text1: 'Company website',
            text2: 'Loremipsum@abc.com',
        }
    ]

    const tabledata2 = [
        {
            text1: 'Admin / MLRO approved risk status',
            text2: getOnboardedClientReportData?.performed_by_details?.is_oomero ? getOnboardedClientReportData?.oomero_risk_status : getOnboardedClientReportData?.risk_status,
        },
        {
            text1: 'Admin / MLRO approved risk level',
            text2: getOnboardedClientReportData?.performed_by_details?.is_oomero ? getOnboardedClientReportData?.oomero_risk_level : getOnboardedClientReportData?.risk_level,
        },
        {
            text1: 'Oomero Default Risk status',
            text2: getOnboardedClientReportData?.oomero_risk_status || '',
        },
        {
            text1: 'Enterprise Risk Status',
            text2: getOnboardedClientReportData?.risk_status || '',
        },
        {
            text1: 'Oomero Default Risk Level',
            text2: getOnboardedClientReportData?.oomero_risk_level || '',
        },
        {
            text1: 'Customer is Whitelisted ',
            text2: (getOnboardedClientReportData?.is_whitelisted) ? 'yes' : 'no',
        },
        {
            text1: 'Customer is Blacklisted',
            text2: (getOnboardedClientReportData?.is_blacklisted) ? 'yes' : 'no',
        },
        {
            text1: 'Performed on:',
            text2: getOnboardedClientReportData?.action_performed_on,
        },
    ]

    const tabledata3 = [
        {
            text1: 'Facebook:',
            text2: getOnboardedClientReportData?.facebook_link || '',
            customColor: '#0094FF'
        },
        {
            text1: 'Linkedin:',
            text2: getOnboardedClientReportData?.linkedin_link || '',
            customColor: '#0094FF'
        },
        {
            text1: 'Instagram:',
            text2: getOnboardedClientReportData?.instagram_link || '',
            customColor: '#0094FF'
        },
        {
            text1: 'Twitter:',
            text2: getOnboardedClientReportData?.twitter_link || '',
            customColor: '#0094FF'
        },
    ]

    const tabledata4 = [
        {
            text1: 'Company Name:',
            text2: 'Kotak',
        },
        {
            text1: 'country:',
            text2: 'India',
        },
        {
            text1: 'Company  Reg No:',
            text2: '123333',
        },
        {
            text1: 'Designation:',
            text2: 'Director',
        },
        {
            text1: 'Director Type:',
            text2: 'board member',
        },
        {
            text1: 'Shareholder:',
            text2: '45%',
        },
        {
            text1: 'UBO:',
            text2: 'Yes',
        },
    ]

    const tabledata5 = [
        {
            text1: 'Company Name:',
            text2: 'Kotak',
        },
        {
            text1: 'country:',
            text2: 'India',
        },
        {
            text1: 'Company  Reg No:',
            text2: '123333',
        },
        {
            text1: 'Designation:',
            text2: 'ceo',
        },
        {
            text1: 'Director Type:',
            text2: 'non-executive director',
        },
        {
            text1: 'Shareholder:',
            text2: '45%',
        },
        {
            text1: 'UBO:',
            text2: 'Yes',
        },
    ]

    const tabledata6 = [
        {
            text1: 'Company Name:',
            text2: 'Kotak',
        },
        {
            text1: 'country:',
            text2: 'India',
        },
        {
            text1: 'Company  Reg No:',
            text2: '123333',
        },
        {
            text1: 'Designation:',
            text2: 'cto',
        },
        {
            text1: 'Director Type:',
            text2: 'board member',
        },
        {
            text1: 'Shareholder:',
            text2: '45%',
        },
        {
            text1: 'UBO:',
            text2: 'Yes',
        },
    ]

    const tabledata7 = [
        {
            text1: 'Search Term(s):',
            text2: getOnboardedClientReportData?.create_search_details?.search_term || '',
        },
        {
            text1: 'User Reference:',
            text2: getOnboardedClientReportData?.create_search_details?.ref || '',
        },
        {
            text1: 'Search ID:',
            text2: getOnboardedClientReportData?.search_id || '',
        },
        {
            text1: 'Performed on:',
            text2: moment(getOnboardedClientReportData?.create_search_details?.created_at).format("DD:MM:YYYY HH:MM:SS"), //'[Insert Date (DD:MM:YYYY) and time (HH:MM:SS]',
        },
        {
            text1: 'Performed by:',
            text2: getOnboardedClientReportData?.action_performed_on || '',
        },
        {
            text1: 'Match status:',
            text2: getOnboardedClientReportData?.create_search_details?.match_status,
        },
        {
            text1: 'Continuous Monitoring:',
            text2: getOnboardedClientReportData?.continous_monitoring ? 'Yes' : 'No',
        },
        {
            text1: 'Searching:',
            text2: 'Sanctions',
        },
        {
            text1: 'filters',
            boxtext1: 'Types:',
            boxtext2: 'Sanctions',
            boxtext3: 'Warning',
            boxtext4: 'Fitness-probity',
            boxtext5: 'PEP-class-1',
            boxtext6: 'PEP-class-2',
            boxtext7: 'PEP-class-3',
            boxtext8: 'PEP-class-4',
            boxtext9: 'Fuzziness Interval: (80%)',
            boxsubtext1: 'Adverse-media-V2-cybercrime',
            boxsubtext2: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext3: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext4: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext5: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext6: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext7: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext8: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext9: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext10: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext11: 'Adverse-media-V2-violence-non-aml-cft',
            boxsubtext12: 'Adverse-media-V2-violence-non-aml-cft',
        }
    ]

    const tabledata8 = [
        {
            text1: 'Search created:',
            text2: getOnboardedClientReportData?.create_search_details?.created_at,  //'[Insert Date (DD:MM:YYYY) and time (HH:MM:SS]',
        },
        {
            text1: 'Search Term(s):',
            text2: getOnboardedClientReportData?.create_search_details?.search_term,
        },
        {
            text1: 'Client Reference:',
            text2: getOnboardedClientReportData?.oomero_id,
        },
        {
            text1: 'Search ref:',
            text2: getOnboardedClientReportData?.create_search_details?.ref,
        },
        {
            text1: 'Entity ID:',
            text2: getOnboardedClientReportData?.search_id,
        },
        {
            text1: 'Fuzziness interval:',
            text2: (getOnboardedClientReportData?.create_search_details?.filters?.fuzziness ?? 0) * 100 + '%',
        },
    ]

    const tabledata9 = [
        {
            text1: 'Full Name:',
            text2: 'Name matched exactly',
        },
        {
            text1: 'Entity Type:',
            text2: 'Person',
        },
        {
            text1: 'AKA:',
            text2: 'Gülnara KerimovaGulnara I. KarimovaGoulnora Islamovna KarimovaGulnora KarimovováGulnara \n* Lola KarimovGulnora KarimovaGulnara KarimovaГульнара Исламовна КаримоваGyulnara KarimovaGoulnara KarimovaGoogooshaگلناره کریمواKarimova GulnaraGulnara Cult Karimova굴노라 카리모바古爾諾拉·卡麗莫娃Gulnara Karimovجلنار كريموفاD. Gulnara KarimovaGulnara KarimowaG. KarimovaKarimovaGulanara Karimovaグリナラ・カリモヴァ',
        },
        {
            text1: 'Countries:',
            text2: 'Armenia, Austria, Azerbaijan, Cameroon, Canada, Czech Republic, Germany, Luxembourg, Russian Federation, Switzerland, United Kingdom, United States, Uzbekistan, Viet Nam',
        },
        {
            text1: 'Date Of Birth:',
            text2: '1972-07-08 (Age: 51)',
        },
        {
            text1: 'Associates:',
            text2: 'Iman Karimova (child) Islam Jr. (child) Islam Karimov (parent) Islam Karimov (relative) Islam Karimov Jr (child) Mansur Maqsudi (former spouse) Mansur Maqsudi (spouse) Tatyana Karimova (parent) Tatyana Karimova (relative)',
        },
    ]

    const tabledata10 = [
        {
            keyname: 'valid uk vat number',
            valuename: getOnboardedClientReportData?.vat_no || '',
        },
        {
            keyname: 'registered business name',
            valuename: getOnboardedClientReportData?.vat_details?.name || getOnboardedClientReportData?.vat_details?.traderName,
        },
        {
            keyname: 'registered business address',
            address: (getOnboardedClientReportData?.vat_details?.traderAddress) ? getOnboardedClientReportData?.vat_details?.traderAddress : (getOnboardedClientReportData?.vat_details ? `${getOnboardedClientReportData?.vat_details?.address?.line1}, ${getOnboardedClientReportData?.vat_details?.address?.line2}, ${getOnboardedClientReportData?.vat_details?.address?.line3}, ${getOnboardedClientReportData?.vat_details?.address?.line4}` : '')
        },
    ]

    const headers1 = [
        { 'Activation date:': '11/2/2016' },
        { 'enforcement agency:': 'Ministry of corporate affairs india' },
        { 'enforcement Type:': 'Disqualified' },
        { 'Identification number:': 'Din:3208006' },
        { 'Other information:': 'the entity is disqualified to be director by the ministry of corporate affairs india' },
        { 'termination date:': '31/10/21' },
    ];

    const headers2 = [
        { 'COUNTRY:': 'UNITED KINGDOM' },
        { 'ORIGINAL COUNTRY TEXT:': 'UNITED KINGDOM' },
    ];

    const headers3 = [
        { 'NATIONALITY:': 'UZBEKISTAN' },
        { 'cOUNTRY:': 'UZBEKISTAN' },
        { 'oRIGINAL COUNTRY TEXT:': 'UZBEKISTAN' },
        { 'PLACE OF BIRTH:': 'UZBEKISTAN' },
        { 'oRIGINAL PLACE OF BIRTH TEXT:': 'FERGANA,UZBEKISTAN' },
        { 'DATE OF BIRTH:': '1972-07-08' },
        { 'GENDER:': 'FEMALE' },
        { 'PASSPORT:': 'PASSPORT: dA0006735, ISSUING COUNTRY:UZBEKISTAN' },
        { 'aDDRESS:': 'tASHKENT,UZBEKISTAN' },
        { 'DESIGNATION DATE:': '2017-10-12' },
        { 'LEGAL BASIS:': 'EXECUTIVE ORDER 13818(GLOBAL MAGNITSKY' },
        { 'LIST NAME:': 'sdN LIST' },
        { 'LISTING ID:': 'ofac-23307' },
        { 'PROGRAM:': 'GLOMAG' },
        { 'SANCTION TYPE:': 'BLOCK' },
        { 'LOW QUALITY ALIAS': 'GOOGOOSHA' },
    ];

    const headers4 = [
        { 'Country:': 'Switzerland' },
        { 'Original Country Text:': 'Switzerland' },
        { 'Asset Recovery Chain Placement:': 'Investigation/Asset Tracking/Asset Restraint' },
        { 'Disposition of Criminal Case:': 'According to a press statement by the Swiss public ministry, the money laundering investigation against Ms. Karimova was initiated in 2013.' },
        { 'Jurisdiction of Origin:': 'Uzbekistan' },
        { 'Jurisdiction of Asset Recovery Description:': 'Undertaking Recovery Effort; Location of Recovery Effort; Asset Location/Alleged Asset Location' },
        { 'Legal Basis for Recovery:': 'Criminal Prosecution and Forfeiture' },
        { 'Money Laundering Implicated:': 'Yes' },
        { 'Recovery End:': 'Ongoing' },
    ];

    const headers5 = [
        { 'activation date:': '2018-10-03 00:00:00' },
        { 'enforcement agency:': 'united states department of justice' },
        { 'enforcement type:': 'indictment' },
        { 'locationurl': 'HTTPS://WWW.SFO.GOV.UK/CASES/GULNARA-KARIMOVA-RUSTAM-MADUMAROV/' },
        { 'other info': 'court docket no: 19-cr-00165-kmw; district: southern district if new york; filed: march 7, 2019' },
        { 'relatedurl': 'HTTPS://WWW.SFO.GOV.UK/CASES/GULNARA-KARIMOVA-RUSTAM-MADUMAROV/' },
    ];

    const tableHeaders = ['ACTION DATE TIME', 'File Uploaded', 'Document Type', 'Uploaded By'];

    const userData = [
        {
            id: '26/07/2020 12:22:00',
            fullName: 'Passport.png',
            email: 'Passport',
            phone: 'John Doe',
        },
        {
            id: '26/07/2020 12:22:00',
            fullName: 'Passport.png',
            email: 'Passport',
            phone: 'John Doe',
        },
        {
            id: '26/07/2020 12:22:00',
            fullName: 'Passport.png',
            email: 'Passport',
            phone: 'John Doe',

        },
        {
            id: '26/07/2020 12:22:00',
            fullName: 'Passport.png',
            email: 'Passport',
            phone: 'John Doe',
        },
        {
            id: '26/07/2020 12:22:00',
            fullName: 'Passport.png',
            email: 'Passport',
            phone: 'John Doe',
        }
    ]


    const headers6 = ['PUBLISHED', 'TITLE'];
    const data10 = [
        { published: '26/07/2020 12:22:00', title: ['"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', '"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', '"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', 'COMMENT: Checker check the document and found photo copy not maching'] },
        { published: '26/07/2020 12:22:00', title: ['"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', '"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', 'COMMENT: Checker check the document and found photo copy not maching'] },
        { published: '26/07/2020 12:22:00', title: ['"Usbekische Prinzessin kaufte weltweit Immobilien – mit Bestechungsgeld', 'COMMENT: Checker check the document and found photo copy not maching'] },
        { published: '26/07/2020 12:22:00', title: ['"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', 'COMMENT: Checker check the document and found photo copy not maching'] },
        { published: '26/07/2020 12:22:00', title: ['COMMENT: Checker check the document and found photo copy not maching'] },
    ];

    const headers7 = [{ 'Key data:': 'Durch Korruption soll Gulnara Karimowa ein riesiges Vermögen angehäuft haben. 240 Millionen US-Dollar hat sie für Immobilien von London bis Hongkong ausgegeben, so ein Bericht.' }, { 'AM:': '1, 3, 7, 9 Durch Korruption soll Gulnara Karimowa ein riesiges Vermögen angehäuft haben. 240 Millionen US-Dollar hat sie für Immobilien von London bis Hongkong ausgegeben, so ein Bericht.' }, { 'Docs:': 'Durch Korruption soll Gulnara Karimowa ein riesiges Vermögen angehäuft haben. 240 Millionen US-Dollar hat sie für Immobilien von London bis Hongkong ausgegeben, so ein Bericht.' }];

    const headers8 = ['ACTION DATE TIME', 'ACTION TYPE', 'ACTION DESCRIPTION', 'USER DETAILS',];
    const data11 = [
        { published: '26/07/2020 12:22:00', actionType: 'DOCUMENT', title: ['Maker Uploaded the new passport from user name MAKER ', 'Maker Uploaded the new passport from user name MAKER ', 'COMMENT: Maker Uploaded the new passport from user name MAKER '], userDetails: 'MAKER' },
        { published: '26/07/2020 12:22:00', actionType: 'DOCUMENT', title: ['Maker Uploaded the new passport from user name MAKER ', 'COMMENT: Maker Uploaded the new passport from user name MAKER '], userDetails: 'MAKER' },
        { published: '26/07/2020 12:22:00', actionType: 'DOCUMENT', title: ['Maker Uploaded the new passport from user name MAKER ', 'COMMENT: Maker Uploaded the new passport from user name MAKER'], userDetails: 'MAKER' },
        { published: '26/07/2020 12:22:00', actionType: 'DOCUMENT', title: ['Maker Uploaded the new passport from user name MAKER ', 'COMMENT: Maker Uploaded the new passport from user name MAKER'], userDetails: 'MAKER' },
        { published: '26/07/2020 12:22:00', actionType: 'DOCUMENT', title: ['Maker Uploaded the new passport from user name MAKER ', 'COMMENT: Maker Uploaded the new passport from user name MAKER'], userDetails: 'MAKER' },
        { published: '26/07/2020 12:22:00', actionType: 'DOCUMENT', title: ['Maker Uploaded the new passport from user name MAKER ', 'COMMENT: Maker Uploaded the new passport from user name MAKER'], userDetails: 'MAKER' },
        { published: '26/07/2020 12:22:00', actionType: 'DOCUMENT', title: ['COMMENT: Maker Uploaded the new passport from user name MAKER'], userDetails: 'MAKER' },
    ];

    const headers9 = ['DATE TIME', 'TYPE', 'TITLE', 'USER'];
    const data12 = [
        { published: '26/07/2020 12:22:00', type: 'ADVERSE MEDIA', title: ['"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', '"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', '"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', 'COMMENT: Checker check the document and found photo copy not maching'], user: 'AMIT' },
        { published: '26/07/2020 12:22:00', type: 'ADVERSE MEDIA', title: ['"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', '"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', '"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', 'COMMENT: Checker check the document and found photo copy not maching'], user: 'AMIT' },
        { published: '26/07/2020 12:22:00', type: 'ADVERSE MEDIA', title: ['"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', '"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', '"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', 'COMMENT: Checker check the document and found photo copy not maching'], user: 'AMIT' },
        { published: '26/07/2020 12:22:00', type: 'ADVERSE MEDIA', title: ['"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', '"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', '"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', 'COMMENT: Checker check the document and found photo copy not maching'], user: 'AMIT' },
        { published: '26/07/2020 12:22:00', type: 'ADVERSE MEDIA', title: ['"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', '"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', '"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', 'COMMENT: Checker check the document and found photo copy not maching'], user: 'AMIT' },
    ];

    const headers10 = ['DATE TIME', 'TYPE', 'TITLE', 'USER'];
    const data13 = [
        { published: '26/07/2020 12:22:00', type: 'KEY DATA', title: ['COMMENT: Checker check the document and found photo copy not maching'], user: 'AMIT' },
        { published: '26/07/2020 12:22:00', type: 'KEY DATA', title: ['COMMENT: Checker check the document and found photo copy not maching'], user: 'AMIT' },
        { published: '26/07/2020 12:22:00', type: 'KEY DATA', title: ['COMMENT: Checker check the document and found photo copy not maching'], user: 'AMIT' },
        { published: '26/07/2020 12:22:00', type: 'KEY DATA', title: ['COMMENT: Checker check the document and found photo copy not maching'], user: 'AMIT' },
        { published: '26/07/2020 12:22:00', type: 'KEY DATA', title: ['COMMENT: Checker check the document and found photo copy not maching'], user: 'AMIT' },
    ];

    const headers11 = ['ACTION DATE TIME', 'ACTION TYPE', 'ACTION DESCRIPTION', 'USER DETAILS',];
    const data14 = [
        { published: '26/07/2020 12:22:00', actionType: 'DOCUMENT', title: ['Maker Uploaded the new passport from user name MAKER ', 'Maker Uploaded the new passport from user name MAKER ', 'COMMENT: Maker Uploaded the new passport from user name MAKER '], userDetails: 'MAKER' },
        { published: '26/07/2020 12:22:00', actionType: 'DOCUMENT', title: ['Maker Uploaded the new passport from user name MAKER ', 'COMMENT: Maker Uploaded the new passport from user name MAKER '], userDetails: 'MAKER' },
        { published: '26/07/2020 12:22:00', actionType: 'DOCUMENT', title: ['Maker Uploaded the new passport from user name MAKER ', 'COMMENT: Maker Uploaded the new passport from user name MAKER'], userDetails: 'MAKER' },
        { published: '26/07/2020 12:22:00', actionType: 'DOCUMENT', title: ['Maker Uploaded the new passport from user name MAKER ', 'COMMENT: Maker Uploaded the new passport from user name MAKER'], userDetails: 'MAKER' },
        { published: '26/07/2020 12:22:00', actionType: 'DOCUMENT', title: ['Maker Uploaded the new passport from user name MAKER ', 'COMMENT: Maker Uploaded the new passport from user name MAKER'], userDetails: 'MAKER' },
        { published: '26/07/2020 12:22:00', actionType: 'DOCUMENT', title: ['Maker Uploaded the new passport from user name MAKER ', 'COMMENT: Maker Uploaded the new passport from user name MAKER'], userDetails: 'MAKER' },
        { published: '26/07/2020 12:22:00', actionType: 'DOCUMENT', title: ['COMMENT: Maker Uploaded the new passport from user name MAKER'], userDetails: 'MAKER' },
    ];


    const tableHeaders2 = ['ACTION DATE TIME', 'File Uploaded', 'Document Type', 'Uploaded By', 'Document View'];

    const userData2 = [
        {
            id: '26/07/2020 12:22:00',
            fullName: 'Passport.png',
            email: 'Passport',
            phone: 'John Doe',
            tableImg: TableImg,
        },
        {
            id: '26/07/2020 12:22:00',
            fullName: 'Passport.png',
            email: 'Passport',
            phone: 'John Doe',
            tableImg: TableImg,
        },

    ]

    const commentData = [
        {
            comment_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        {
            comment_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        {
            comment_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        }
    ]

    const sampleData = [
        { label: 'Given Name', value: 'SANDEEP' },
        { label: 'Document Number', value: '125035493' },
    ];


    // const generatePdf = () => {
    //     const element = document.getElementById('content-to-pdf');
    //     const customHeight = 297;
    //     let calculatedWidth = 250;

    //     const options = {
    //         margin: [9, 9,0,9],
    //         filename: 'Report.pdf',
    //         image: { type: 'jpeg', quality: 0.98 },
    //         html2canvas: { scale: 1.7 },
    //         jsPDF: { unit: 'mm', format: [calculatedWidth, customHeight], orientation: 'portrait' }
    //     };

    //     html2pdf().from(element).set(options).save();
    // };

    return (
        <>
            {/* <button className='m-5' onClick={generatePdf}>Download</button> */}
            <div className='projectreport-css'>
                <div id='content-to-pdf'>

                    <div className='page-break'>
                        <div style={{ marginTop: 15 }}>
                            <IndividualReport
                                reportcustomerlabel='This is a report on customer:'
                                customername={`${getOnboardedClientReportData?.first_name} ${(getOnboardedClientReportData?.middle_name) ? getOnboardedClientReportData?.middle_name : ''} ${(getOnboardedClientReportData?.last_name) ? getOnboardedClientReportData?.last_name : ''}`}
                                onboardedlabel='Onboarded on: '
                                onboardedtime={getOnboardedClientReportData?.action_performed_on ? getOnboardedClientReportData?.action_performed_on : ''}  //'26/07/2020 - 12:22:00'
                                idnolabel='Having Oomero ID No:'
                                idno={getOnboardedClientReportData?.oomero_id || 'No Oomero Id'}
                                text1='Please also ensure that this document is not given to any customer or any person not authorised to look at this information.'
                                text2='It can be considered as “Tipping-off” any such person or organisation by providing this report and is regarded as a criminal offence. Please read the tipping-off section on the Oomero Platform FAQ section before disclosing anything.'
                                text3='This document is normally required for an investigation by a law enforcement agency or regulator but can be also requested by relevant 3rd parties. '
                                text4='If in doubt please contact a compliance professional at Oomero Ltd by reaching out on'
                                text5='mlrohelp@oomero.com.'
                            />
                        </div>


                        <div>
                            <FooterBox
                                footerText='Private and Confidential** '
                            />
                        </div>

                    </div>

                    {/* {Page -2-----} */}
                    <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Key Details'
                            showblueBox={true}
                        />

                        <div style={{ marginTop: 15 }}>
                            <DetailsInfo
                                mainimage={async (el) => {
                                    if (el) {
                                      try {
                                        const imageUrl = await getImgUrl(getOnboardedClientReportData?.personal_detail_document);
                                        el.src = imageUrl || userImg; // Set the image URL or fallback
                                      } catch (error) {
                                        console.error('Error fetching image:', error);
                                        el.src = userImg; // Fallback on error
                                      }
                                    }
                                  }}
                                namelabel='Name:'
                                nametext={`${getOnboardedClientReportData?.first_name} ${(getOnboardedClientReportData?.middle_name) ? getOnboardedClientReportData?.middle_name : ''} ${(getOnboardedClientReportData?.last_name) ? getOnboardedClientReportData?.last_name : ''}`}
                                idlabel='Oomero ID:'
                                idtext={getOnboardedClientReportData?.oomero_id || 'No Oomero Id'}
                                tradingnamelabel='Trading Name:'
                                tradingname={getOnboardedClientReportData?.trading_as || ''}
                                vatlabel='VAT No:'
                                vatno={getOnboardedClientReportData?.vat_no || ''}
                                typelabels='Type of Individual:'
                                typename1={(getOnboardedClientReportData?.politically_exposed_person) ? 'PEP' : ''}
                                typename2={(getOnboardedClientReportData?.high_net_worth_individual) ? 'HNI' : ''}
                                consentlabel='Consent:'
                                imagesource1={TickCircle}
                                formtext={(getOnboardedClientReportData?.form_consent) ? 'FORM' : ''}
                                imagesource2={TickCircle}
                                biometrictext={(getOnboardedClientReportData?.biometric_consent) ? 'BIOMETRICS' : ''}
                                imagesource3={TickCircle}
                                amltext='AML Done'
                            />
                        </div>

                        <div style={{ marginTop: 15 }}>
                            <HeaderBox
                                showHeader={false}
                                showblueBox={true}
                                headerText='Basic Information'
                            />

                        </div>

                        <div style={{ marginTop: 15 }}>
                            <PersonalDetailsData
                                nationalitytext='Nationality :'
                                nationtext={getOnboardedClientReportData?.country_of_nationality_name || ''}
                                firstname='First Name :'
                                nametext={getOnboardedClientReportData?.first_name || ''}
                                previousname='Previous Name :'
                                previoustext={getOnboardedClientReportData?.previous_name || ''}
                                professiontext='Profession :'
                                businesstext={getOnboardedClientReportData?.profession || ''}
                                mobiletext='Mobile :'
                                mobileno={(getOnboardedClientReportData?.mobile_code) ? `${getOnboardedClientReportData?.mobile_code} ${getOnboardedClientReportData?.mobile_number}` : ''}
                                emailText='Email :'
                                emailidText={getOnboardedClientReportData?.email || ''}
                                aadharhead='Aadhar : '
                                aadharText={getOnboardedClientReportData?.aadhar_number || ''}
                                residencehead='Country of Residence :'
                                residenceText={getOnboardedClientReportData?.country_of_residency_name || ''}
                                middlehead='Middle Name : '
                                middletext={getOnboardedClientReportData?.middle_name || ''}
                                datehead='Date of Birth :'
                                dateText={getOnboardedClientReportData?.date_of_birth || ''}
                                landlinehead='Landline :'
                                landline_no={(getOnboardedClientReportData?.landline_code) ? `${getOnboardedClientReportData?.landline_code} ${getOnboardedClientReportData?.landline_number}` : ''}
                                panhead='PAN :'
                                pan_no={getOnboardedClientReportData?.pan_number || ''}
                                titlehead='Title :'
                                titlename={getOnboardedClientReportData?.title || ''}
                                lastnamehead='Last Name :'
                                lastnametext={getOnboardedClientReportData?.last_name || ''}
                                genderName='Sex :'
                                genderText={getOnboardedClientReportData?.sex || ''}
                                ssn_head='SSN NO :'
                                ssn_no={(getOnboardedClientReportData?.ssn_no) ? getOnboardedClientReportData?.ssn_no : ''}
                            />
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />
                    </div>

                    {/* {Page -3-----} */}
                    <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='MLRO Comments'
                            showblueBox={true}
                        />

                        <div style={{ marginTop: 15 }}>
                            {tabledata1.map((data) => (
                                <TableComponent {...data} />
                            ))}
                        </div>


                        <div style={{ marginTop: 15 }}>
                            <SubHeaderBox
                                showBox1
                                contentText='Customer name:'
                                nameText={getOnboardedClientReportData?.first_name + ' ' + getOnboardedClientReportData?.last_name}
                            />
                        </div>

                        <ContentData
                            CommentBox={true}
                            commentheading='Comment:'
                            shouldRemoveClass={true}
                        />
                        {getOnboardedClientReportData?.mlroComments && getOnboardedClientReportData?.mlroComments['comments'].split(",").map((comment_text) => (
                            <ContentData
                                CommentBox={true}
                                comment_text={comment_text}
                                shouldRemoveClass={true}
                            />
                        ))}

                        <FooterBox
                            footerText='Private and Confidential** '
                        />

                    </div>

                    {/* {Page -4-----} */}
                    <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='MLRO Comments'
                            showblueBox={true}
                            headerText='Risk details'
                        />
                        <div style={{ marginTop: 15 }}>
                            {tabledata2.map((data) => (
                                <TableComponent {...data} />
                            ))}
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />

                    </div>

                    {/* {Page -5-----} */}
                    <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Residential Address'
                            showblueBox={true}
                            headerText='Residential Address'
                        />
                        <div style={{ marginTop: 15, marginBottom: 15 }}>
                            <PdfResidentialAddress
                                datefrom_label='Date From:'
                                datefromtext={getOnboardedClientReportData?.date_from || ''}
                                dateto_label='Date To:'
                                datetotext={getOnboardedClientReportData?.date_to || ''}
                                addresslabel='Address:'
                                addresstext={getOnboardedClientReportData?.address || ''}
                                statelabel='State/Region:'
                                statename={getOnboardedClientReportData?.state || ''}
                                citylabel='City:'
                                cityname={getOnboardedClientReportData?.city || ''}
                                postalcode_label='Postal Code:'
                                postalcode={getOnboardedClientReportData?.zip || ''}
                                countrylabel='Country:'
                                countryname={getOnboardedClientReportData?.current_country_name || ''}
                            />
                        </div>

                        <HeaderBox
                            showHeader={false}
                            showblueBox={true}
                            headerText='Previous Residential Address'
                        />

                        <div style={{ marginTop: 15, marginBottom: 15 }}>
                            <PdfResidentialAddress
                                datefrom_label='Date From:'
                                datefromtext={getOnboardedClientReportData?.previous_date_from || ''}
                                dateto_label='Date To:'
                                datetotext={getOnboardedClientReportData?.previous_date_to || ''}
                                addresslabel='Address:'
                                addresstext={getOnboardedClientReportData?.previous_address || ''}
                                statelabel='State/Region:'
                                statename={getOnboardedClientReportData?.previous_state || ''}
                                citylabel='City:'
                                cityname={getOnboardedClientReportData?.previous_city || ''}
                                postalcode_label='Postal Code:'
                                postalcode={getOnboardedClientReportData?.previous_zip || ''}
                                countrylabel='Country:'
                                countryname={getOnboardedClientReportData?.previous_country_name || ''}
                            />
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />

                    </div>

                    {/* {Page -6-----} */}
                    <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Key Questions (HNI & PEP)'
                            showblueBox={true}
                            headerText='Politically Exposed Person (PEP)'
                        />
                        <div style={{ marginTop: 15, marginBottom: 15 }}>
                            <ContentData
                                label1='PEP Level (if any):'
                                value1={getOnboardedClientReportData?.politically_exposed_pep || ''}
                                label2='Why you are a PEP:'
                                value2={getOnboardedClientReportData?.politically_exposed_source_of_wealth || ''}
                                QuestionData={true}
                                shoudlAppyMargin1={true}
                            />
                        </div>

                        <HeaderBox
                            showHeader={false}
                            showblueBox={true}
                            headerText='High Net worth Individual(HNI)'
                        />

                        <div style={{ marginTop: 15 }}>
                            <ContentData
                                label1='Net worth:'
                                value1={getOnboardedClientReportData?.high_net_value || ''}
                                label2='Source of Wealth:'
                                value2={getOnboardedClientReportData?.high_net_source_of_wealth || ''}
                                Text3={true}
                                label3='Source of Income:'
                                value3={getOnboardedClientReportData?.high_net_source_of_income || ''}
                                QuestionData={true}
                                shoudlAppyMargin1={true}
                                shoudlAppyMargin2={true}
                            />
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />

                    </div>

                    {/* {Page -7-----} */}
                    <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Other Data & Social Media'
                            showblueBox={true}
                            headerText='Sole Trader'
                        />
                        <div style={{ marginTop: 15, marginBottom: 15 }}>
                            <ContentData
                                label1='Trading Name:'
                                value1={`${getOnboardedClientReportData?.first_name} ${getOnboardedClientReportData?.middle_name || ''} ${getOnboardedClientReportData?.last_name} trading as ${getOnboardedClientReportData?.trading_as || ''} `}
                                label2='VAT (if any):'
                                value2={getOnboardedClientReportData?.vat_no || ''}
                                Text3={true}
                                // label3='VAT number checked:'
                                // value3='' //[Insert Date (DD:MM:YYYY) and time (HH:MM:SS]
                                QuestionData={true}
                                shoudlAppyMargin1={true}
                                shoudlAppyMargin2={false}
                            />
                        </div>

                        <div style={{ marginTop: 15 }}>
                            <SubHeaderBox
                                showBox1
                                contentText='response from hmrc/vies/gst number:'
                            />
                        </div>

                        <div>
                            {tabledata10.map((data) => (
                                <TableComponent2
                                    {...data}
                                />
                            ))}
                        </div>
                        {/* <TableComponent2/> */}

                        <div style={{ marginTop: 15 }}>
                            <HeaderBox
                                showHeader={false}
                                showblueBox={true}
                                headerText='social media links'
                            />
                        </div>

                        <div style={{ marginTop: 15 }}>
                            {tabledata3.map((data) => (
                                <TableComponent
                                    firstBox={true}
                                    shouldApplyUnderline={true}
                                    showBoxes={true}
                                    {...data}
                                />
                            ))}
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />

                    </div>

                    {/* {Page -8-----} */}
                    <div className='page-break'>

                        {
                            getOnboardedClientReportData?.bankRecords && getOnboardedClientReportData?.bankRecords.map((obj, index) => (
                                <>
                                    {index == 0 ? <HeaderBox
                                        showHeader={true}
                                        headerName='Bank Account Details'
                                        showblueBox={true}
                                        headerText={`Bank ${index + 1} `}
                                    /> : <HeaderBox
                                        showHeader={false}
                                        showblueBox={true}
                                        headerText={`Bank ${index + 1} `}
                                    />}
                                    <div style={{ marginTop: 15, marginBottom: 15 }}>
                                        <BankDetailsData
                                            bankname_label='Bank Name:'
                                            bankname={obj?.bank_name}
                                            accountno_label='Account No:'
                                            accountno={obj?.account_no}
                                            code_label='Swift/BIC Code:'
                                            codeno={obj?.bic_code}
                                            sortcode_label='Sort Code:'
                                            sortcode_no={obj?.bic_code}
                                            iban_label='Iban:'
                                            iban_no={obj?.iban}
                                            addresslabel='Address Registered with Bank:'
                                            imagesource1={TickCircle}
                                            addresstext={obj?.address_type}
                                            homeaddress_label='Home address:'
                                            lane_no={obj?.address}
                                        />
                                    </div>
                                </>
                            ))
                        }

                        <FooterBox
                            footerText='Private and Confidential** '
                        />

                    </div>

                    {/* {Page -9-----} */}
                    <div className='page-break'>
                        {
                            getOnboardedClientReportData?.companyRecords && getOnboardedClientReportData?.companyRecords.map((obj, index) => (
                                <>
                                    {index == 0 ? <HeaderBox
                                        showHeader={true}
                                        headerName='Linked Companies'
                                        showblueBox={true}
                                        headerText={`Company ${index + 1}`}
                                    /> : <HeaderBox
                                        showHeader={false}
                                        showblueBox={true}
                                        headerText={`Company ${index + 1}`}
                                    />}

                                    <div style={{ marginTop: 15, marginBottom: 15 }}>
                                        <TableComponent text1='Company Name:' text2={obj?.company_name} />
                                        <TableComponent text1='Country:' text2={obj?.country_name} />
                                        <TableComponent text1='Company Reg No:' text2={obj?.company_reg_no} />
                                        <TableComponent text1='Designation:' text2={obj?.designation} />
                                        <TableComponent text1='Director Type:' text2={obj?.type_of_director} />
                                        <TableComponent text1='Shareholder:' text2={(obj?.shareholder) ? 'Yes' : 'No'} />
                                        <TableComponent text1='UBO:' text2={(obj?.ultimate_beneficial_owner) ? 'Yes' : 'No'} />
                                    </div>
                                </>
                            ))
                        }
                        <FooterBox
                            footerText='Private and Confidential** '
                        />
                    </div>

                    {
                        getOnboardedClientReportData?.idvDocuments && getOnboardedClientReportData?.idvDocuments.map((obj, index) => {
                            {/* {Page -10-----} */ }
                            // const { document_portrait_url, ...tech5data } = obj?.provider_details || {};
                            // Create a new object excluding the 'document_portrait_url' key
                            const tech5data = Object.keys(obj?.provider_details || {}).reduce((acc, key) => {
                                if (key !== 'document_portrait_url') {
                                    acc[key] = obj?.provider_details[key];
                                }
                                return acc;
                            }, {});

                            return (<>
                            {obj?.identity_document_type === 'passport' &&
                            <div className='page-break'>
                                <HeaderBox
                                    showHeader={true}
                                    headerName='IDV Documents'
                                    showblueBox={true}
                                    headerText='idv document - Passport'
                                />
                                <div style={{ marginTop: 15 }}>
                                    <SubHeaderBox
                                        contentText='passport image'
                                        showScore
                                        scorelabel='Score'
                                        scoreno='90'
                                    />
                                </div>

                                <div>
                                    <ImageComponent
                                        imagesource={async (el) => {
                                            if (el) {
                                              try {
                                                const imageUrl = await getImgUrl(obj?.identity_document_passport_url);
                                                el.src = imageUrl || userImg; // Set the image URL or fallback
                                              } catch (error) {
                                                console.error('Error fetching image:', error);
                                                el.src = userImg; // Fallback on error
                                              }
                                            }
                                          }}
                                        width={333}
                                        height={250}
                                    />
                                </div>

                                <div style={{ marginTop: 15 }}>
                                    <SubHeaderBox
                                        contentText='Ocr content'
                                    />
                                </div>

                                <div style={{ marginTop: 15 }}>
                                    <DocumentDetails data={tech5data || {}} />
                                </div>

                                <FooterBox
                                    footerText='Private and Confidential** '
                                />

                            </div>
                            }
                                {/* {Page -11-----} */}
                                {/* {obj?.provider_details && obj?.provider_details?.document_portrait_url && */}
                                <div className='page-break'>
                                    <HeaderBox
                                        showHeader={true}
                                        headerName='IDV Documents'
                                        showblueBox={true}
                                        headerText='Face Comparison'
                                    />
                                    <div style={{ marginTop: 15 }}>
                                        <SubHeaderBox
                                            showBox1
                                            contentText='Face Comparison'
                                            showScore
                                            scorelabel='Score'
                                            scoreno={obj?.score_percent || 0}
                                            showBox2
                                            showCenterText
                                            centerText='Selfie'
                                        />
                                    </div>

                                    <div>
                                        <ImageComponent
                                            imagesource={async (el) => {
                                                if (el) {
                                                  try {
                                                    const imageUrl = await getImgUrl(obj?.identity_document_selfie_url);
                                                    el.src = imageUrl || userImg; // Set the image URL or fallback
                                                  } catch (error) {
                                                    console.error('Error fetching image:', error);
                                                    el.src = userImg; // Fallback on error
                                                  }
                                                }
                                              }}
                                            width={190}
                                            height={250}
                                        />
                                    </div>


                                    <div style={{ marginTop: 15 }}>
                                        <SubHeaderBox
                                            showBox2
                                            showCenterText
                                            centerText='Reference'
                                        />
                                    </div>

                                    <div>
                                        <ImageComponent
                                            imageurl={obj?.provider_details?.document_portrait_url}
                                            width={190}
                                            height={250}
                                        />
                                        {/* obj?.provider_details?.document_portrait_url */}
                                    </div>

                                    <FooterBox
                                        footerText='Private and Confidential** '
                                    />

                                </div>
                        {/* } */}

                                {/* {Page -12-----} */}
                                <div className='page-break'>
                                    <HeaderBox
                                        showHeader={true}
                                        headerName='IDV Documents'
                                        showblueBox={true}
                                        headerText='Liveness Detection'
                                    />
                                    <div style={{ marginTop: 15 }}>
                                        <SubHeaderBox
                                            showBox1
                                            contentText='Liveness Detection'
                                            showScore
                                            scorelabel='Score'
                                            scoreno={obj?.liveness_uscore || 0}
                                            showBox2
                                            showCenterText
                                            centerText='Selfie'
                                        />
                                    </div>

                                    <div>
                                        <ImageComponent
                                            imagesource={async (el) => {
                                                if (el) {
                                                  try {
                                                    const imageUrl = await getImgUrl(obj?.identity_document_selfie_url);
                                                    el.src = imageUrl || userImg; // Set the image URL or fallback
                                                  } catch (error) {
                                                    console.error('Error fetching image:', error);
                                                    el.src = userImg; // Fallback on error
                                                  }
                                                }
                                              }}
                                            width={190}
                                            height={250}
                                        />
                                    </div>

                                    <FooterBox
                                        footerText='Private and Confidential** '
                                    />

                                </div>

                                {/* {Page -13-----} */}
                                {obj?.identity_document_type === 'card' &&
                                    <div className='page-break'>
                                        <HeaderBox
                                            showHeader={true}
                                            headerName='IDV Documents'
                                            showblueBox={true}
                                            headerText='Document Verification Results'
                                        />
                                        <div style={{ marginTop: 15 }}>
                                            <SubHeaderBox
                                                showBox1
                                                contentText='ID Document'
                                                showScore
                                                scorelabel='Score'
                                                scoreno={obj?.document_uscore || 0}
                                                showBox2
                                                frontsidetext='Front Side'
                                                backsidetext='Back Side'
                                            />
                                        </div>

                                        <div className='d-flex justify-content-around'>
                                            <div style={{ marginRight: 20 }}>
                                                <ImageComponent
                                                    imagesource={async (el) => {
                                                        if (el) {
                                                          try {
                                                            const imageUrl = await getImgUrl(obj?.identity_document_frontid_url);
                                                            el.src = imageUrl || userImg; // Set the image URL or fallback
                                                          } catch (error) {
                                                            console.error('Error fetching image:', error);
                                                            el.src = userImg; // Fallback on error
                                                          }
                                                        }
                                                      }}
                                                    width={260}
                                                    height={260}
                                                />
                                            </div>

                                            <div>
                                                <ImageComponent
                                                    imagesource={async (el) => {
                                                        if (el) {
                                                          try {
                                                            const imageUrl = await getImgUrl(obj?.identity_document_backid_url);
                                                            el.src = imageUrl || userImg; // Set the image URL or fallback
                                                          } catch (error) {
                                                            console.error('Error fetching image:', error);
                                                            el.src = userImg; // Fallback on error
                                                          }
                                                        }
                                                      }}
                                                    width={260}
                                                    height={260}
                                                />
                                            </div>

                                        </div>

                                        <div style={{ marginTop: 15 }}>
                                            <SubHeaderBox
                                                showBox1
                                                contentText='Ocr content'
                                            />
                                        </div>

                                        <div style={{ marginTop: 15 }}>
                                            <DocumentDetails
                                                data={tech5data || {}}
                                            />
                                        </div>

                                        <FooterBox
                                            footerText='Private and Confidential** '
                                        />

                                    </div>
                                }
                            </>)
                        }
                        )}

                    {/* {Page -14-----} */}
                    <div className='page-break'>
                        <div className='row' style={{ marginTop: 15 }}>
                            {
                                getOnboardedClientReportData?.amlInformation && getOnboardedClientReportData?.amlInformation.map((obj, index) => {
                                    const dobFields = obj?.search_results?.doc?.fields
                                        .filter(field => field.tag === "date_of_birth")
                                        .map(field => field.value);

                                    const limitedDOBFields = dobFields;

                                    return (<>
                                        {
                                            index === 0 && <HeaderBox
                                                showHeader={true}
                                                headerName='AML Information'
                                                showblueBox={true}
                                            />
                                        }

                                        <div className='col-xl-4'>
                                            <InformationBox
                                                customBg='#A9FFC1'
                                                showBox1={obj?.checked}
                                                FromText
                                                boxtext={obj?.checked ? 'relevant' : ''}
                                                fromlabel='From: '
                                                fromname={obj?.oomero_relevant ? 'Oomero' : 'Enterprise'}
                                                personlabel={(obj?.search_results?.doc?.entity_type || '').replace(/\b\w/g, char => char.toUpperCase())}
                                                person_name={obj?.name}
                                                matched_label='Matched:'
                                                matched={obj?.search_results?.doc?.types?.map(type => type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()))}
                                                relevancelabel='Relevance:'
                                                relevancetext={obj?.search_results?.match_types?.map(type => type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())).join(', ') || ''}
                                                countrieslabel='Countries:'
                                                countriesname={obj?.search_results?.doc?.fields.find(field => field.tag === "country_names")?.value || null}
                                                doblabel='DOB:'
                                                dob_date={limitedDOBFields.join(", ")}
                                            />
                                        </div>

                                    </>)
                                }
                                )}

                        </div>
                        {/* <div className='row' style={{ marginTop: 15 }}>
                            <div className='col-xl-4'>
                                <InformationBox
                                    customBg='#A9FFC1'
                                    showBox1={true}
                                    FromText
                                    boxtext='relevant'
                                    fromlabel='From: '
                                    fromname='Oomero'
                                    personlabel='Person'
                                    person_name='D. Gulnara Karimova'
                                    matched_label='Matched:'
                                    sanctiontext='Sanction'
                                    warningtext='Warning'
                                    peptext='Pep'
                                    adversemedia_text='Adverse Media'
                                    relevancelabel='Relevance:'
                                    relevancetext='Name matched exactly'
                                    countrieslabel='Countries:'
                                    countriesname='Russian Federation'
                                    doblabel='DOB:'
                                    dob_date='1990-04-29 (Age: 33 years old)'
                                />
                            </div>
                            <div className='col-xl-4'>
                                <InformationBox
                                    customBg='#A9FFC1'
                                    showBox1={true}
                                    FromText
                                    boxtext='relevant'
                                    fromlabel='From: '
                                    fromname='Oomero'
                                    personlabel='Person'
                                    person_name='D. Gulnara Karimova'
                                    matched_label='Matched:'
                                    sanctiontext='Sanction'
                                    warningtext='Warning'
                                    peptext='Pep'
                                    adversemedia_text='Adverse Media'
                                    relevancelabel='Relevance:'
                                    relevancetext='Name matched exactly'
                                    countrieslabel='Countries:'
                                    countriesname='Russian Federation'
                                    doblabel='DOB:'
                                    dob_date='1990-04-29 (Age: 33 years old)'
                                />
                            </div>
                            <div className='col-xl-4'>
                                <InformationBox
                                    customBg='#A9FFC1'
                                    showBox1={true}
                                    FromText
                                    boxtext='relevant'
                                    fromlabel='From: '
                                    fromname='Oomero'
                                    personlabel='Person'
                                    person_name='D. Gulnara Karimova'
                                    matched_label='Matched:'
                                    sanctiontext='Sanction'
                                    warningtext='Warning'
                                    peptext='Pep'
                                    adversemedia_text='Adverse Media'
                                    relevancelabel='Relevance:'
                                    relevancetext='Name matched exactly'
                                    countrieslabel='Countries:'
                                    countriesname='Russian Federation'
                                    doblabel='DOB:'
                                    dob_date='1990-04-29 (Age: 33 years old)'
                                />
                            </div>
                        </div>

                        <div className='row' style={{ marginTop: 15 }}>
                            <div className='col-xl-4'>
                                <InformationBox
                                    boxColor='#E3E3E3'
                                    customBg='#C0C0C0'
                                    showBox1={true}
                                    boxtext='Not Relevant'
                                    personlabel='Person'
                                    person_name='D. Gulnara Karimova'
                                    matched_label='Matched:'
                                    sanctiontext='Sanction'
                                    warningtext='Warning'
                                    peptext='Pep'
                                    adversemedia_text='Adverse Media'
                                    relevancelabel='Relevance:'
                                    relevancetext='Name matched exactly'
                                    countrieslabel='Countries:'
                                    countriesname='Russian Federation'
                                    doblabel='DOB:'
                                    dob_date='1990-04-29 (Age: 33 years old)'
                                />
                            </div>
                            <div className='col-xl-4'>
                                <InformationBox
                                    boxColor='#E3E3E3'
                                    customBg='#C0C0C0'
                                    showBox1={true}
                                    boxtext='Not Relevant'
                                    personlabel='Person'
                                    person_name='D. Gulnara Karimova'
                                    matched_label='Matched:'
                                    sanctiontext='Sanction'
                                    warningtext='Warning'
                                    peptext='Pep'
                                    adversemedia_text='Adverse Media'
                                    relevancelabel='Relevance:'
                                    relevancetext='Name matched exactly'
                                    countrieslabel='Countries:'
                                    countriesname='Russian Federation'
                                    doblabel='DOB:'
                                    dob_date='1990-04-29 (Age: 33 years old)'
                                />
                            </div>
                            <div className='col-xl-4'>
                                <InformationBox
                                    boxColor='#E3E3E3'
                                    customBg='#C0C0C0'
                                    showBox1={true}
                                    boxtext='Not Relevant'
                                    personlabel='Person'
                                    person_name='D. Gulnara Karimova'
                                    matched_label='Matched:'
                                    sanctiontext='Sanction'
                                    warningtext='Warning'
                                    peptext='Pep'
                                    adversemedia_text='Adverse Media'
                                    relevancelabel='Relevance:'
                                    relevancetext='Name matched exactly'
                                    countrieslabel='Countries:'
                                    countriesname='Russian Federation'
                                    doblabel='DOB:'
                                    dob_date='1990-04-29 (Age: 33 years old)'
                                />
                            </div>
                        </div> */}



                        <FooterBox
                            footerText='Private and Confidential** '
                        />

                    </div>

                    {/* {Page -14-----} */}
                    {/* <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='AML Information'
                            showblueBox={true}
                        />

                        <div className='row' style={{ marginTop: 15 }}>
                            <div className='col-xl-4'>
                                <InformationBox
                                    customBg='#A9FFC1'
                                    personlabel='Person'
                                    person_name='D. Gulnara Karimova'
                                    matched_label='Matched:'
                                    sanctiontext='Sanction'
                                    warningtext='Warning'
                                    peptext='Pep'
                                    adversemedia_text='Adverse Media'
                                    relevancelabel='Relevance:'
                                    relevancetext='Name matched exactly'
                                    countrieslabel='Countries:'
                                    countriesname='Russian Federation'
                                    doblabel='DOB:'
                                    dob_date='1990-04-29 (Age: 33 years old)'
                                />
                            </div>
                            <div className='col-xl-4'>
                                <InformationBox
                                    customBg='#A9FFC1'
                                    personlabel='Person'
                                    person_name='D. Gulnara Karimova'
                                    matched_label='Matched:'
                                    sanctiontext='Sanction'
                                    warningtext='Warning'
                                    peptext='Pep'
                                    adversemedia_text='Adverse Media'
                                    relevancelabel='Relevance:'
                                    relevancetext='Name matched exactly'
                                    countrieslabel='Countries:'
                                    countriesname='Russian Federation'
                                    doblabel='DOB:'
                                    dob_date='1990-04-29 (Age: 33 years old)'
                                />
                            </div>
                            <div className='col-xl-4'>
                                <InformationBox
                                    customBg='#A9FFC1'
                                    personlabel='Person'
                                    person_name='D. Gulnara Karimova'
                                    matched_label='Matched:'
                                    sanctiontext='Sanction'
                                    warningtext='Warning'
                                    peptext='Pep'
                                    adversemedia_text='Adverse Media'
                                    relevancelabel='Relevance:'
                                    relevancetext='Name matched exactly'
                                    countrieslabel='Countries:'
                                    countriesname='Russian Federation'
                                    doblabel='DOB:'
                                    dob_date='1990-04-29 (Age: 33 years old)'
                                />
                            </div>
                        </div>

                        <div className='row' style={{ marginTop: 15 }}>
                            <div className='col-xl-4'>
                                <InformationBox
                                    customBg='#A9FFC1'
                                    personlabel='Person'
                                    person_name='D. Gulnara Karimova'
                                    matched_label='Matched:'
                                    sanctiontext='Sanction'
                                    warningtext='Warning'
                                    peptext='Pep'
                                    adversemedia_text='Adverse Media'
                                    relevancelabel='Relevance:'
                                    relevancetext='Name matched exactly'
                                    countrieslabel='Countries:'
                                    countriesname='Russian Federation'
                                    doblabel='DOB:'
                                    dob_date='1990-04-29 (Age: 33 years old)'
                                />
                            </div>
                            <div className='col-xl-4'>
                                <InformationBox
                                    customBg='#A9FFC1'
                                    personlabel='Person'
                                    person_name='D. Gulnara Karimova'
                                    matched_label='Matched:'
                                    sanctiontext='Sanction'
                                    warningtext='Warning'
                                    peptext='Pep'
                                    adversemedia_text='Adverse Media'
                                    relevancelabel='Relevance:'
                                    relevancetext='Name matched exactly'
                                    countrieslabel='Countries:'
                                    countriesname='Russian Federation'
                                    doblabel='DOB:'
                                    dob_date='1990-04-29 (Age: 33 years old)'
                                />
                            </div>
                            <div className='col-xl-4'>
                                <InformationBox
                                    customBg='#A9FFC1'
                                    personlabel='Person'
                                    person_name='D. Gulnara Karimova'
                                    matched_label='Matched:'
                                    sanctiontext='Sanction'
                                    warningtext='Warning'
                                    peptext='Pep'
                                    adversemedia_text='Adverse Media'
                                    relevancelabel='Relevance:'
                                    relevancetext='Name matched exactly'
                                    countrieslabel='Countries:'
                                    countriesname='Russian Federation'
                                    doblabel='DOB:'
                                    dob_date='1990-04-29 (Age: 33 years old)'
                                />
                            </div>
                        </div>

                        <div className='row' style={{ marginTop: 15 }}>
                            <div className='col-xl-4'>
                                <InformationBox
                                    customBg='#A9FFC1'
                                    personlabel='Person'
                                    person_name='D. Gulnara Karimova'
                                    matched_label='Matched:'
                                    sanctiontext='Sanction'
                                    warningtext='Warning'
                                    peptext='Pep'
                                    adversemedia_text='Adverse Media'
                                    relevancelabel='Relevance:'
                                    relevancetext='Name matched exactly'
                                    countrieslabel='Countries:'
                                    countriesname='Russian Federation'
                                    doblabel='DOB:'
                                    dob_date='1990-04-29 (Age: 33 years old)'
                                />
                            </div>
                            <div className='col-xl-4'>
                                <InformationBox
                                    customBg='#A9FFC1'
                                    personlabel='Person'
                                    person_name='D. Gulnara Karimova'
                                    matched_label='Matched:'
                                    sanctiontext='Sanction'
                                    warningtext='Warning'
                                    peptext='Pep'
                                    adversemedia_text='Adverse Media'
                                    relevancelabel='Relevance:'
                                    relevancetext='Name matched exactly'
                                    countrieslabel='Countries:'
                                    countriesname='Russian Federation'
                                    doblabel='DOB:'
                                    dob_date='1990-04-29 (Age: 33 years old)'
                                />
                            </div>
                            <div className='col-xl-4'>
                                <InformationBox
                                    customBg='#A9FFC1'
                                    personlabel='Person'
                                    person_name='D. Gulnara Karimova'
                                    matched_label='Matched:'
                                    sanctiontext='Sanction'
                                    warningtext='Warning'
                                    peptext='Pep'
                                    adversemedia_text='Adverse Media'
                                    relevancelabel='Relevance:'
                                    relevancetext='Name matched exactly'
                                    countrieslabel='Countries:'
                                    countriesname='Russian Federation'
                                    doblabel='DOB:'
                                    dob_date='1990-04-29 (Age: 33 years old)'
                                />
                            </div>
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />

                    </div> */}

                    {/* {Page -14-----} */}
                    <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Search Certificate'
                            showblueBox={true}
                            headerText='Search Details'
                        />
                        <div style={{ marginTop: 15 }}>
                            {tabledata7.map((data, index) => (
                                <TableComponent
                                    {...data}
                                />
                            ))}

                            <SubHeaderBox
                                showBox1
                                contentText={`${getOnboardedClientReportData?.amlInformation?.length || 0} Result(s) found for ${getOnboardedClientReportData?.create_search_details?.search_term}`}
                            />

                        </div>

                        <div style={{ marginTop: 15 }}>
                            {
                                getOnboardedClientReportData?.amlInformation && getOnboardedClientReportData?.amlInformation.map((obj, index) => {
                                    return (<MatchContentData
                                        match_head1='Matched:'
                                        match_text1={obj?.name}
                                        imagesource1={CautionImg}
                                        match_head2='Matched: '
                                        match_text2={obj?.name}
                                        imagesource2={InfoImg}
                                        match_head3='Relevance:'
                                        match_text3={obj?.search_results?.match_types?.map(type => type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())).join(', ') || ''}
                                        imagesource3={PersonIcon}
                                        match_head4='Entity Type: '
                                        match_text4={(obj?.search_results?.doc?.entity_type || '').replace(/\b\w/g, char => char.toUpperCase())}
                                        matched={obj?.search_results?.doc?.types?.map(type => type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()))}
                                        imagesource4={CommentIcon}
                                        match_head5='Comments:'
                                        match_text5=''
                                        imagesource5={DocumentIcon}
                                        matchfoundText='Match found on'
                                        matchno={`${getOnboardedClientReportData?.amlInformation.length}`}
                                        listname='list'
                                    />)
                                })
                            }
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />

                    </div>

                    {/* {Page -15-----} */}
                    <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Entity Certificate'
                            showblueBox={true}
                            headerText='Search Information'
                        />
                        {
                            getOnboardedClientReportData?.amlDetailsList && getOnboardedClientReportData?.amlDetailsList.map((obj, index) => {
                                return (<>
                                    {index != 0 && <div style={{ marginTop: 15 }}>
                                        <HeaderBox
                                            showHeader={true}
                                            showblueBox={true}
                                            headerText='Search Information'
                                        />
                                    </div>}
                                    <div style={{ marginTop: 15 }}>
                                        {tabledata8.map((data, index) => (
                                            <TableComponent
                                                showBoxes={true}
                                                countriesText={true}
                                                insertText={true}
                                                {...data}
                                            />
                                        ))}
                                        {[{
                                            text1: 'case management',
                                            text3: 'Is Whitelisted:',
                                            text4: 'Match Status:',
                                            text5: 'Risk Level:',
                                            text6: (getOnboardedClientReportData?.is_whitelisted) ? 'yes' : 'no',
                                            text7: getOnboardedClientReportData?.create_search_details?.match_status,
                                            text8: getOnboardedClientReportData?.create_search_details?.risk_level,
                                        }, {
                                            text1: 'key information',
                                            text3: 'Full name:',
                                            text4: 'Entity type:',
                                            text5: 'Also Known As (AKA):',
                                            text9: 'Countries:',
                                            text6: obj?.name,
                                            text7: (obj?.search_results?.doc?.entity_type || '').replace(/\b\w/g, char => char.toUpperCase()),
                                            text8: obj?.search_results?.doc?.aka
                                                ? obj.search_results.doc.aka.map(item => item.name).join(', ')
                                                : '',
                                            text10: obj?.search_results?.doc?.fields.find(field => field.tag === "country_names")?.value || 'None'
                                        }].map((data, index) => (
                                            <TableComponent
                                                showBoxes={true}
                                                countriesText={true}
                                                insertText={true}
                                                {...data}
                                            />
                                        ))}
                                    </div>

                                    <div style={{ marginTop: 15 }}>
                                        <HeaderBox
                                            showHeader={true}
                                            // headerName='' //Bank Account Details
                                            showblueBox={true}
                                            headerText='LISTING'
                                        />
                                    </div>

                                    {obj && obj.listing && obj?.listing.map((item, index) => (
                                        <div>
                                            <DoubleTable
                                                header1={item?.title}
                                                header2="Currently on"
                                                blueText=""
                                                SmallBox={false}
                                                smallTableData={true}
                                                useCol6={true}
                                                headers={item?.fields}
                                            />
                                        </div>
                                    ))}
                                </>)
                            })}


                        <FooterBox
                            footerText='Private and Confidential** '
                        />

                    </div>

                    {/* {Page -16-----} */}
                    {
                        getOnboardedClientReportData?.amlDetailsList && getOnboardedClientReportData?.amlDetailsList.map((obj, index) => {
                            const dobFields = obj?.search_results?.doc?.fields
                                .filter(field => field.tag === "date_of_birth")
                                .map(field => field.value);
                            return (<>
                                <div className='page-break'>
                                    <HeaderBox
                                        showHeader={true}
                                        headerName='Entity Key Data'
                                        showblueBox={true}
                                    />


                                    <div style={{ marginTop: 15 }}>
                                        <SubHeaderBox
                                            showBox1
                                            customColor='#0094FF'
                                            contentText={getOnboardedClientReportData?.create_search_details?.search_term}
                                        />

                                        <EntityData
                                            searchterm='Search Term'
                                            searchtext={getOnboardedClientReportData?.create_search_details?.search_term}
                                            createdhead='Created At:'
                                            date_and_time={getOnboardedClientReportData?.create_search_details?.created_at} //'Jul 17, 2023 4:00 PM'
                                            intervalhead='Fuzziness Interval:'
                                            interval_percent={(getOnboardedClientReportData?.create_search_details?.filters?.fuzziness ?? 0) * 100 + '%'}
                                            // sanctiontext='Sanction'
                                            // peptext='pep'
                                            // warningtext='Warning'
                                            matched={obj?.search_results?.doc?.types
                                                ?.map(type => type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()))}
                                            adversemediatext='Adverse Media'
                                            search_ref_label='Search Ref:'
                                            search_ref_no={getOnboardedClientReportData?.create_search_details?.ref}
                                            entity_label='Entity ID:'
                                            entity_no={getOnboardedClientReportData?.search_id}
                                        />
                                    </div>

                                    <div style={{ marginTop: 15 }}>
                                        <HeaderBox
                                            showHeader={true}
                                            showblueBox={true}
                                            headerText='Key Information'
                                        />
                                    </div>

                                    <div style={{ marginTop: 15 }}>
                                        <TableComponent firstBox={true} text1={'Full Name:'} text2={obj?.name} />
                                        <TableComponent firstBox={true} text1={'Entity Type:'} text2={(obj?.search_results?.doc?.entity_type || '').replace(/\b\w/g, char => char.toUpperCase())} />
                                        <TableComponent firstBox={true} text1={'AKA:'} text2={obj?.search_results?.doc?.aka
                                            ? obj.search_results.doc.aka.map(item => item.name).join(', ')
                                            : ''} />
                                        <TableComponent firstBox={true} text1={'Countries:'} text2={obj?.search_results?.doc?.fields.find(field => field.tag === "country_names")?.value || null} />
                                        <TableComponent firstBox={true} text1={'Date Of Birth:'} text2={dobFields.join(",   ")} />
                                        <TableComponent firstBox={true} text1={'Associates:'} text2={(obj?.associates) ? obj?.associates.map(({ name, association }) => `${name} (${association})`).join('') : 'Not Avaliable'} />
                                    </div>


                                    <FooterBox
                                        footerText='Private and Confidential** '
                                    />

                                </div>

                                {/* {Page -17-----} */}
                                <div className='page-break'>
                                    <HeaderBox
                                        showHeader={true}
                                        headerName='' //Entity Key Data
                                        showblueBox={true}
                                        headerText='LISTING'
                                    />

                                    {obj && obj.listing && obj?.listing.map((item, index) => (
                                        <div>
                                            <DoubleTable
                                                header1={item?.title}
                                                header2="Currently on"
                                                blueText=""
                                                SmallBox={false}
                                                smallTableData={true}
                                                useCol6={true}
                                                headers={item?.fields}
                                            />
                                        </div>
                                    ))}

                                    <FooterBox
                                        footerText='Private and Confidential** '
                                    />
                                </div>
                            </>)
                        }
                        )}

                    {/* {Page -18-----} */}
                    {/* <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Entity Key Data'
                            showblueBox={true}
                            headerText='LISTING'
                        />

                        <div>
                            <DoubleTable
                                header1="4 WARNING LISTS"
                                header2="Currently on"
                                blueText="UNITED KINGDOM SERIOUS FRAUD OFFICE CASES"
                                SmallBox={false}
                                smallTableData={true}
                                useCol6={true}
                                showFlag={true}
                                ImageBox={true}
                                headers={headers4}
                            />
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />
                    </div> */}

                    {/* {Page -19-----} */}
                    {/* <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Entity Key Data'
                            showblueBox={true}
                            headerText='LISTING'
                        />

                        <div style={{ marginTop: 15 }}>
                            <DoubleTable
                                tableHeader={false}
                                blueText="united states department of justice foreign corrupt practices act related emforcements persons"
                                SmallBox={false}
                                smallTableData={true}
                                useCol6={true}
                                showFlag={true}
                                ImageBox={true}
                                headers={headers5}
                            />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <DoubleTable
                                tableHeader={false}
                                blueText="united states department of justice foreign corrupt practices act related emforcements persons"
                                SmallBox={false}
                                smallTableData={true}
                                useCol6={true}
                                showFlag={true}
                                ImageBox={true}
                                headers={headers5}
                            />
                        </div>

                        <div style={{ marginTop: 15 }}>
                            <ContentData
                                SummaryBox
                                summarylabel='summary:'
                                implicatedlabel='uncac offenses implicated:'
                                summarytext='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
                                implicatedtext='art. 16 art. art. 23'
                            />
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />
                    </div> */}

                    {/* {Page -20-----} */}
                    <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Entity Key Data'
                            showblueBox={true}
                        />

                        <div style={{ marginTop: 15 }}>
                            <SubHeaderBox
                                showBox1
                                customColor='#0094FF'
                                contentText={getOnboardedClientReportData?.create_search_details?.search_term}
                            />

                            <EntityData
                                searchterm='Search Term'
                                searchtext={getOnboardedClientReportData?.create_search_details?.search_term}
                                createdhead='Created At:'
                                date_and_time={getOnboardedClientReportData?.create_search_details?.created_at}
                                intervalhead='Fuzziness Interval:'
                                interval_percent={(getOnboardedClientReportData?.create_search_details?.filters?.fuzziness ?? 0) * 100 + '%'}
                                matched={getOnboardedClientReportData?.create_search_details?.filters?.types?.map(type => type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()))}
                                search_ref_label='Search Ref:'
                                search_ref_no={getOnboardedClientReportData?.create_search_details?.ref}
                                entity_label='Entity ID:'
                                entity_no={getOnboardedClientReportData?.search_id}
                            />
                        </div>

                        <div style={{ marginTop: 15 }}>
                            <HeaderBox
                                showHeader={false}
                                showblueBox={true}
                                headerText={`total comments-${getOnboardedClientReportData?.entityAdverseMediaComments?.length || 0}`}
                            />
                        </div>

                        <div style={{ marginTop: 15 }}>
                            <PdfTable
                                data={getOnboardedClientReportData?.entityAdverseMediaComments?.map((obj) => {
                                    const titleArray = (obj?.titles.split(",")) || [];
                                    const commentString = `COMMENT: ${obj?.comment}`;
                                    if (!titleArray.includes(commentString)) {
                                        titleArray.push(commentString);
                                    }
                                    return {
                                        published: moment(obj?.created_at).format("DD/MM/YYYY HH:mm:ss"), //'26/07/2020 12:22:00', 
                                        title: titleArray
                                    }
                                }) || []}
                                // data={[{ published: '26/07/2020 12:22:00', title: ['"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', '"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', '"Usbekische Prinzessin" kaufte weltweit Immobilien – mit Bestechungsgeld', 'COMMENT: Checker check the document and found photo copy not maching'] }]}
                                headers={headers6}
                                showtablecss={true}
                                showOrderedList={true}
                                showActions={true}
                                showRelevant={true}
                            />
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />
                    </div>

                    {/* {Page -21-----} */}
                    <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Entity Comments '
                            showblueBox={true}
                            headerText='Master comments'
                        />

                        <div style={{ marginTop: 15 }}>
                            <DoubleTable
                                header1="CARD 1"
                                header2="MAIN CARD COMMENT"
                                type="RELEVANT"
                                text="Durch Korruption soll Gulnara Karimowa ein riesiges Vermögen angehäuft haben. 240 Millionen US-Dollar hat sie für Immobilien von London bis Hongkong ausgegeben, so ein Bericht."
                                subHeading="Sub-Comment"
                                headers={headers7}
                            />
                        </div>

                        <div style={{ marginTop: 15 }}>
                            <DoubleTable
                                header1="CARD 1"
                                header2="MAIN CARD COMMENT"
                                type="NOT RELEVANT"
                                text="Durch Korruption soll Gulnara Karimowa ein riesiges Vermögen angehäuft haben. 240 Millionen US-Dollar hat sie für Immobilien von London bis Hongkong ausgegeben, so ein Bericht."
                                subHeading="Sub-Comment"
                                headers={headers7}
                            />
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />
                    </div>

                    {/* {Page -22-----} */}
                    <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Uploaded Documents List'
                            showblueBox={true}
                        />

                        <div style={{ marginTop: 15 }}>
                            <PdfTable
                                data={getOnboardedClientReportData?.uploadedDocuments?.map((obj) => {
                                    return {
                                        id: moment(obj?.created_at).format("DD/MM/YYYY HH:mm:ss"),
                                        fullName: obj?.filename,
                                        email: obj?.document_type,
                                        phone: obj?.user_type
                                    }
                                }) || []}
                                headers={tableHeaders}
                                showtablecss={true}
                                showIcon={true}
                            />
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />
                    </div>

                    {/* {Page -23-----} */}
                    <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Comments (Open Comments)  '
                            showblueBox={true}
                            headerText='Open Comments '
                        />

                        <div style={{ marginTop: 15 }}>
                            <PdfTable
                                data={getOnboardedClientReportData?.openComments?.map((obj) => {
                                    return { published: moment(obj?.created_at).format("DD/MM/YYYY HH:mm:ss"), actionType: "", title: [`COMMENT: ${obj?.comment} `], userDetails: obj?.user_type }
                                }) || []}
                                headers={headers8}
                                showtablecss={true}
                                showNormalText={true}
                            />
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />
                    </div>


                    {/* {Page -24-----} */}
                    <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Comments (Aml)'
                            showblueBox={true}
                            headerText='AML Comments'
                        />

                        <div style={{ marginTop: 15 }}>
                            <PdfTable
                                data={getOnboardedClientReportData?.entityAdverseMediaComments?.map((obj) => {
                                    const titleArray = (obj?.titles.split(",")) || [];
                                    const commentString = `COMMENT: ${obj?.comment}`;
                                    if (!titleArray.includes(commentString)) {
                                        titleArray.push(commentString);
                                    }
                                    return { published: moment(obj?.created_at).format("DD/MM/YYYY HH:mm:ss"), type: 'ADVERSE MEDIA', title: titleArray, user: 'AMIT' }
                                }) || []}
                                headers={headers9}
                                showtablecss={true}
                                showOrderedList={true}
                                showActions={true}
                                showRelevant={true}
                            />
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />
                    </div>

                    {/* {Page -25-----} */}
                    {/* <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Comments (Aml)'
                            showblueBox={true}
                            headerText='AML Comments'
                        />

                        <div style={{ marginTop: 15 }}>
                            <PdfTable
                                data={data13}
                                headers={headers10}
                                showtablecss={true}
                                showNormalText={true}
                                showActions={true}
                                showRelevant={true}
                            />
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />
                    </div> */}

                    {/* {Page -26-----} */}
                    <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Comments (Ghost Comments)'
                            showblueBox={true}
                            headerText='Ghost Comments'
                        />

                        <div style={{ marginTop: 15 }}>
                            <PdfTable
                                data={getOnboardedClientReportData?.ghostComments?.map((obj) => {
                                    const titleArray = [];
                                    const commentString = `COMMENT: ${obj?.comment}`;
                                    if (!titleArray.includes(commentString)) {
                                        titleArray.push(commentString);
                                    }
                                    return { published: moment(obj?.created_at).format("DD/MM/YYYY HH:mm:ss"), type: '', title: titleArray, user: obj?.user_type }
                                }) || []}
                                headers={headers10}
                                showtablecss={true}
                                showNormalText={true}
                                showActions={true}
                                showRelevant={false}
                            />
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />
                    </div>

                    {/* {Page -27-----} */}
                    {/* <div className='page-break'>
                        <HeaderBox
                            showHeader={true}
                            headerName='Comments (Ghost Comments)'
                            showblueBox={true}
                            headerText='Ghost Comments'
                        />

                        <div style={{ marginTop: 15 }}>
                            <PdfTable
                                data={data14}
                                headers={headers11}
                                showtablecss={true}
                                showNormalText={true}
                            />
                        </div>

                        <FooterBox
                            footerText='Private and Confidential** '
                        />
                    </div> */}

                    {/* {Page -28-----} */}
                    <div className='page-break'>
                        {getOnboardedClientReportData?.oldData && Object.entries(getOnboardedClientReportData?.oldData).map(([key, value]) => {
                            // console.log("dsfdsf", value.map((obj) => {
                            //     return {
                            //         [obj.column_name] : obj.old_value
                            //     }
                            // }))
                            return (<>
                                <HeaderBox
                                    showHeader={true}
                                    headerName='Old Data'
                                    showblueBox={true}
                                    headerText={`${key} information`}
                                />

                                <div style={{ marginTop: 15 }}>
                                    <BasicInfo
                                        data={
                                            value.reduce((acc, obj) => {
                                                acc[obj.column_name] = obj.old_value;
                                                return acc;
                                            }, {})
                                        }
                                    />
                                </div>
                            </>)
                        }
                        )}

                        {/* <div style={{ marginTop: 15 }}>
                            <HeaderBox
                                showHeader={false}
                                showblueBox={true}
                                headerText='Bank details'
                            />
                        </div>

                        <div style={{ marginTop: 15 }}>
                            <BankInfoDetails
                                bankname_label='Bank Name:'
                                bankname='Kotak'
                                accountno_label='Account No:'
                                accountno='12345676678'
                                ibanlabel='IBAN:'
                                ibanno='1233330000'
                                sortcode_label='Sort Code:'
                                sortcode='123333'
                                swiftcode_label='Swift/BIC Code:'
                                swiftcodeno='123333'
                                addresslabel='Address Registered with Bank:'
                                imagesource={TickCircle}
                                homeaddressText='Home Address'
                                addressText='201, Daimler street, Greater Birmingham, England'
                            />
                        </div> */}

                        {/* <div style={{ marginTop: 15 }}>
                            <HeaderBox
                                showHeader={false}
                                showblueBox={true}
                                headerText='Bank details'
                            />
                        </div>

                        <div>
                            <PdfTable
                                data={userData2}
                                headers={tableHeaders2}
                                showtablecss={true}
                                showIcon={true}
                            />
                        </div> */}

                        <FooterBox
                            footerText='Private and Confidential** '
                        />
                    </div>

                </div>
            </div>

        </>
    )
}

export default ProjectReport
const styles = `
    .page-break {
        page-break-after: always;
    }
    
    #footer {
        display:flex;
        align-items:flex-end;
    }
`;

document.head.insertAdjacentHTML("beforeend", `<style>${styles}</style>`);