import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import ThumbIcon from '../../assets/images/thumb_icon.png'
import { useNavigate } from 'react-router-dom';

function AddNewUsers() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/manage_users`;
        navigate(path);
    }
    return (
        <div className='grey-background'>
            <div className='white-background p-3 mb-3'>
                <h2 className='text-blue fw-normal pt-2'>Add New User</h2>
                <hr />
                <form >
                    <div className="row g-3 mb-3">
                        <div className="col-lg-4 col-md-12">
                            <div className="form-group">
                                <label className="form-label" for="exampleInputEmail1">First Name</label>
                                <input type="text" className="form-control" placeholder="First name"></input>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="form-group">
                                <label className="form-label" for="exampleInputEmail1">Last Name</label>
                                <input type="text" className="form-control" placeholder="Last name"></input>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="form-group">
                                <label className="form-label" for="exampleInputEmail1">Email</label>
                                <input type="text" className="form-control" placeholder="Email"></input>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="form-group">
                                <label className="form-label" for="exampleInputEmail1">Password</label>
                                <input type="text" className="form-control" placeholder="Password"></input>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="form-group ">
                                <label className="form-label" for="exampleInputEmail1">Mobile</label>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-3'>
                                        <select id="statusDropdown"
                                            className="form-control form-select">
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-9 col-md-9'>
                                        <div className="form-group">
                                            <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter number" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="form-group ">
                                <label className="form-label" for="exampleFormControlSelect1">Active</label>
                                <select id="statusDropdown" className="form-control form-select">
                                    <option></option>
                                    <option></option>
                                    <option></option>
                                    <option></option>
                                    <option></option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="form-group ">
                                <label className="form-label" for="exampleFormControlSelect1">Assign to Oomero Company</label>
                                <select id="statusDropdown" className="form-control form-select">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className='white-background p-3'>
                <h2 className='text-blue fw-normal pt-2'>Permission Assigned </h2>
                <hr />
                <form>
                    <div className="row px-2">
                        <div className='col-lg-12 col-md-12 pt-3 pb-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Dashboard
                                </label>
                            </div>
                        </div>
                    </div>
                    <h2 className='text-blue fw-normal pt-2'>Profile</h2>
                    <hr />
                    <div className="row px-2">
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Create Profile
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    View Profile
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Edit Profile
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Deactive Profile
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Print Profile
                                </label>
                            </div>
                        </div>
                    </div>
                    <h2 className='text-blue fw-normal pt-2'>Screening</h2>
                    <hr />
                    <div className="row px-2">
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Create Profile
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    View Profile
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Edit Profile
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Deactive Profile
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Print Profile
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Create Profile
                                </label>
                            </div>
                        </div>
                    </div>
                    <h2 className='text-blue fw-normal pt-2'>Client Setup</h2>
                    <hr />
                    <div className="row px-2">
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Create Profile
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    View Profile
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Edit Profile
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Deactive Profile
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Print Profile
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Create Profile
                                </label>
                            </div>
                        </div>
                    </div>
                    <h2 className='text-blue fw-normal pt-2'>Configuration</h2>
                    <hr />
                    <div className="row px-2">
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Create Package
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    View Package
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Edit Package
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Deactive Package
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row px-2">
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Print Package
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Assign Package
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Create Service Provider
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row px-2">
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    View Service Provider
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Edit Service Provider
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Deactive Service Provider
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row px-2">
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Print Service Provider
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Cost Price Table
                                </label>
                            </div>
                        </div>
                    </div>
                    <h2 className='text-blue fw-normal pt-2'>Create User</h2>
                    <hr />
                    <div className="row px-2">
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Create User
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Edit User
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Deactive User
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Print User
                                </label>
                            </div>
                        </div>
                    </div>
                    <h2 className='text-blue fw-normal pt-2'>Reports</h2>
                    <hr />
                    <div className="row px-2">
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Audit Report
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Sales Report
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Print User
                                </label>
                            </div>
                        </div>
                    </div>
                    <h2 className='text-blue fw-normal pt-2'>FX Setup</h2>
                    <hr />
                    <div className="row px-2">
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Create Currency
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Modify Currency
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Print User
                                </label>
                            </div>
                        </div>
                    </div>
                    <h2 className='text-blue fw-normal pt-2'>TAX Setup</h2>
                    <hr />
                    <div className="row px-2">
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Create Tax
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Modify Tax
                                </label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12 my-3'>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                <label className="form-check-label" for="defaultCheck1">
                                    Print User
                                </label>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
            <div className="d-flex flex-row-reverse">
                <button className="btn btn-primary mt-3 mb-1" onClick={handleShow}>Save User</button>
            </div>
            <Modal show={show} onHide={handleClose} centered >
                <Modal.Body>
                    <div className='d-flex justify-content-center'>
                        <img src={ThumbIcon} style={{ width: 70, height: 70, objectFit: 'contain' }} />
                    </div>
                    <h3 className='text-blue text-center mt-3 mb-0 fw-normal'>Changes done successfully !!</h3>
                    <div className="d-flex justify-content-center">
                        <button onClick={routeChange} className="btn-primary w-50 my-3">Ok</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default AddNewUsers
