import React, { useEffect, useState } from 'react'
import EditIcon from '../../assets/images/edit_icon.png'
import { useNavigate } from 'react-router-dom';
import PrintIcon from '../../assets/images/Print_Icon.png'
import ViewIcon from '../../assets/images/view_icon.png'
import DeleteIcon from '../../assets/images/delete_icon.png'
import { deleteRole, fetchAllRoles, resetData } from './roleSlice'
import Loader from '../../components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { decodeToken } from 'react-jwt';
import TablePagination from '../../components/Pagination';
import SearchIcon from '../../assets/images/search_icon.png';


function UsersRole() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPge, SetPostsPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const [searchType, setSearchType] = useState(false);
    const { isLoading, roleLists } = useSelector(state => state.roleReducer);
    const userToken = localStorage.getItem('token');
    const decodedToken = decodeToken(userToken);
    const routeChange = () => {
        let path = `/user/add_user_role`;
        dispatch(resetData());
        navigate(path, { state: { viewOnly : false, isOomero : decodedToken?.isOomero, isEnterpriseAdmin : decodedToken?.isEnterpriseAdmin, isSuperAdmin : decodedToken?.isSuperAdmin } });
    }

    useEffect(() => {
        const searchQuery = search ? `&search=${search}` : '';
        const queryParams = `?page=${currentPage}&limit=${postsPerPge}${searchQuery}`;
        dispatch(fetchAllRoles(queryParams));
    }, [postsPerPge,currentPage,search]);

    const handleEdit = (item) => {
        let path = `/user/add_user_role`;
        dispatch(resetData());
        navigate(path, { state: { roleId: item?.role_id, viewOnly : false, isOomero : item?.is_oomero, isEnterpriseAdmin : !item?.is_oomero, isSuperAdmin : item?.is_oomero} });
    }

    const handleDelete = (id) => {
        const queryParams=`?roleId=${id}&entity_id=${decodedToken?.entityId}`
        dispatch(deleteRole(queryParams)).unwrap().then((res) => {
            toast.success(res?.message);
            dispatch(fetchAllRoles(`?page=${currentPage}&limit=${postsPerPge}`))
        }).catch((error) => {
            toast.error(error?.message);
        });
    }

    const handleView = (item) => {
        let path = `/user/add_user_role`;
        dispatch(resetData());
        navigate(path, { state: { roleId: item?.role_id, viewOnly : true, isOomero : item?.is_oomero } });
    }

    const handlePagination = (event, value) => {
        setCurrentPage(value);
    }

    const onSearchFormSubmit = (data) => {
        setSearchType(true);
        setCurrentPage(1);
        setSearch(data);
    };
    
    return (
        <div className='grey-background'>
            {isLoading && !searchType && <Loader/>}
            <div className='row'>
                <div className='col-lg-12 col-md-12'>
                    <div className='white-background mt-2 p-3'>
                        <h1 className='text-blue px-2 fw-normal'>Roles List</h1>
                        <hr />
                        {
                            (decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.create_user_role) &&
                            <div className="d-flex flex-row-reverse">
                                <button onClick={routeChange} className="btn btn-primary mt-3 mb-1">Add Role</button>
                            </div>
                        }
                         <div className="form-group position-relative m-4">
                            <input type="text" className="form-control" placeholder='Search' onKeyUp={(event)=>onSearchFormSubmit(event.target.value)}></input>
                            <div className="position-absolute" style={{ right: 20, top: '20%' }}>
                                <img src={SearchIcon} style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
                            </div>
                        </div>
                        <div className='col-lg-12 col-md-12 mt-3'>
                            <div className='custom-table'>
                                <div className='custom-table-head'>
                                    <div className='row'>
                                        <div className='col'>
                                            <h6 className='text-blue'>Roles</h6>
                                        </div>
                                        <div className='col'>
                                            <h6 className='text-blue'>Description</h6>
                                        </div>
                                        {/* <div className='col'>
                                            <h6 className='text-blue'>Role Type</h6>
                                        </div> */}
                                        <div className='col'>
                                            <h6 className='text-blue'>Entity Name</h6>
                                        </div>
                                        <div className='col'>
                                            <h6 className='text-blue'>Active</h6>
                                        </div>
                                        {(decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.view_user_role || decodedToken?.roles?.permissions?.edit_user_role || decodedToken?.roles?.permissions?.deactivate_user_role) && <div className='col-1'>
                                            <h6 className='text-blue' style={{ paddingLeft: 15 }}>Action</h6>
                                        </div>}
                                    </div>
                                </div>
                                {roleLists && roleLists?.rows?.length > 0 &&
                                    roleLists?.rows?.map((element, index) => (
                                        <div className='custom-table-body' key={'role'+index}>
                                            <div className='row align-items-center'>
                                                <div className='col'>
                                                    <h6 className='text-black'>{element?.role_name}</h6>
                                                </div>
                                                <div className='col'>
                                                    <h6 className='text-black'>{element?.description}</h6>
                                                </div>
                                                <div className='col'>
                                                    <h6 className='text-black'>{element?.entity_name}</h6>
                                                </div>
                                                {/* <div className='col'>
                                                    <h6 className='text-black'>{element?.role_type}</h6>
                                                </div> */}
                                                <div className='col'>
                                                    <h6 className='text-black'>{(element?.is_active)?'Yes':'No'}</h6>
                                                </div>
                                                <div className='col-1'>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        {(decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.view_user_role_details) &&
                                                            <img src={ViewIcon} onClick={() => handleView(element)} style={{ width: 22, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                                        }

                                                        {(decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.edit_user_role) && element?.is_active &&
                                                            <img src={EditIcon} onClick={() => handleEdit(element)} style={{ width: 15, height: 15.64, objectFit: 'contain', cursor: 'pointer', marginRight: 10 }} />
                                                        }

                                                        {(decodedToken?.isSuperAdmin || decodedToken?.isEnterpriseAdmin || decodedToken?.roles?.permissions?.deactivate_user_role) && element?.is_active && element?.is_editable &&
                                                            <img src={DeleteIcon} onClick={() => handleDelete(element?.role_id)} style={{ width: 20, height: 15, objectFit: 'contain', cursor: 'pointer' }} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                        {roleLists && roleLists?.rows?.length > 0 &&
                            <TablePagination totalPages={parseInt(roleLists?.totalPages)} currentPage={currentPage} color="primary" handlePagination={handlePagination} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsersRole
