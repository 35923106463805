const AWS = require('aws-sdk');

AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    region: process.env.REACT_APP_REGION
});

const s3 = new AWS.S3({
    signatureVersion: 'v4',
    region: process.env.REACT_APP_REGION,
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET }
});

const getSignedUrls = async (s3Url, expires) => {
    try {
        const urlParts = new URL(s3Url);
        const bucketName = urlParts.hostname.split('.')[0];
        const key = decodeURIComponent(urlParts.pathname.substring(1));

        const signedUrl = await s3.getSignedUrlPromise('getObject', {
            Bucket: bucketName,
            Key: key,
            Expires: expires // Default expiration time set to 600 seconds
        });

        return signedUrl;
    } catch (error) {
        console.error('Error generating signed URL:', error);
        throw error; // Throw the error for better debugging
    }
};

module.exports = { getSignedUrls };
