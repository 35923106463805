import React, { useEffect, useState } from 'react';
import TablePagination from '../Pagination';

function InnerComments({ globalSearchDetail, amlComments, fetchAllAmlComments }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPge, setPostsPerPage] = useState(10);
    const [section, setSection] = useState('');

    useEffect(() => {
        if (section) {
            fetchAllAmlComments(globalSearchDetail?.global_search_results_id, section, currentPage, postsPerPge);
        }
    }, [globalSearchDetail, section, currentPage]);

    const handlePagination = (event, value) => {
        setCurrentPage(value);
    };

    const handleSectionChange = (event) => {
        const selectedSection = event.target.value;
        setSection(selectedSection);
        setCurrentPage(1);
    };

    // Format date function
    const formatDate = (dateString) => {
        if (!dateString) return '';

        const originalDate = new Date(dateString);
        const formattedDate = `${originalDate.getDate()}/${originalDate.getMonth() + 1}/${originalDate.getFullYear()} - ${originalDate.getHours()}:${originalDate.getMinutes()}:${originalDate.getSeconds()}`;

        return formattedDate;
    };

    return (
        <div>
            <div className='col-2'>
                <select
                    name="type"
                    className='form-control form-select'
                    id="type"
                    onChange={handleSectionChange}
                    value={section}
                >
                    <option value="">Select Type</option>
                    <option value="adverse_media">Adverse Media</option>
                    <option value="document">Document</option>
                    <option value="key_data">Key data</option>
                </select>
            </div>

            {section && amlComments?.rows?.map((item, index) => (
                <div className='white-background m-2 p-4' key={index}>
                    <div className='row mt-4 d-flex justify-content-between'>
                        <h2 className='col-3'>{formatDate(item?.created_at)}</h2>
                        <h2 className='col-4'>
                            {section === 'key_data' ? 'Type : Key Data' : section === 'document' ? 'Type : Document' : 'Type : Adverse Media'}
                        </h2>
                        <h2 className='col-2'>{item?.first_name}</h2>
                    </div>
                    {section === 'adverse_media' && item.titles && (
                        <div className='mt-3'>
                            <div className='col-lg-6' style={{ width: '11.666667%' }}>
                                <h2>Title:</h2>
                            </div>
                            <ul>
                                {item?.titles?.split(', ').map((title, titleIndex) => (
                                    <li className='text-blue' style={{ listStyle: 'none' }} key={titleIndex}>{`${titleIndex + 1}. ${title}`}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <div className='mt-3'>
                        <div className='col-lg-6' style={{ width: '11.666667%' }}>
                            <h2>Comment:</h2>
                        </div>
                        <div className='col-lg-6' style={{ width: '88.333333%' }}>
                            <h4 className='mt-1'>{item?.comment}</h4>
                        </div>
                    </div>
                </div>
            ))}

            {section === 'adverse_media' && amlComments && amlComments?.rows?.length > 0 && (
                <TablePagination
                    totalPages={parseInt(amlComments?.totalPages)}
                    currentPage={currentPage}
                    color="primary"
                    handlePagination={handlePagination}
                />
            )}
        </div>
    );
}

export default InnerComments;
