import React, { useState } from 'react'
import '../../Profile/Profile.scss';
import OnboardClientBar from '../../../components/OnboardClientBar/OnboardClientBar';
import upload from '../../../assets/images/upload.png'
import PersonalDetail from '../../../assets/images/personalDetails.png'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '../../../assets/images/Cancel_icon.png'
import Logo from '../../../assets/images/logo.png'

function WebformPersonalDetails() {
    const [show, setShow] = useState(false);
    const [showModalTwo, setShowModalTwo] = useState(false);
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/individual_onboarding_web_address`;
        navigate(path);
    }
    const goBack = () => {
        navigate(-1); // This navigates back to the previous page
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseModalTwo = () => setShowModalTwo(false);
    const handleShowModalTwo = () => setShowModalTwo(true);
    return (
        <div>
            <div className='white-background py-2 position-fixed w-100' style={{ borderRadius: 0 }}>
                <div className="d-flex justify-content-center">
                    <img src={Logo} style={{ objectFit: 'contain', width: 150 }} />
                </div>
            </div>
            <div style={{padding:20}}>
                <div className='white-background mt-5 mb-3 p-3'>
                    <h1 className='text-blue px-2 fw-normal text-center'>Hi <b>abc@gmail.com</b> ,<br></br> below is the webform to onboard individual client</h1>
                </div>
                <OnboardClientBar
                    mainTitle={`Onboard Individual Client`}
                    title1={`PERSONAL DETAILS`}
                    title2={`ADDRESS`}
                    title3={`BANK DETAILS`}
                    title4={`KYC Verification`}
                    backgroundColor1={'#037AD0'} />
                <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                        <div className='white-background mt-4 p-3'>
                            <h1 className='text-blue px-2 fw-normal'>Please select all that may apply</h1>
                            <hr />
                            <div className='row'>
                                <div className='col-lg-6 col-md-12'>
                                    <div onClick={handleShowModalTwo} className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Politically exposed person
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <div onClick={handleShow} className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            High Net worth Individual
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className='white-background  mt-4 p-3'>
                            <div className='row align-items-center mb-2'>
                                <div className='col-lg-2 col-mg-2'>
                                    <div className="d-flex align-items-center justify-content-center" style={{ width: '80px', height: '80px', borderRadius: '10px', backgroundColor: '#01C9A51A' }}>
                                        < img src={upload} style={{ width: '35px', height: '35px' }} />
                                    </div>
                                </div>
                                <div className='col-lg-10 col-mg-10'>
                                    <div className="ps-4 d-flex flex-column justify-content-center">
                                        <a className='upload' style={{ color: '#037924' }}> Upload File</a>
                                        <p className='resend mb-0'>PDF, JPEG or PNG less than 5MB</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='white-background p-3 mt-4'>
                            <div className='d-flex align-items-center'>
                                <img src={PersonalDetail} style={{ width: 35, objectFit: 'contain', marginRight: 20 }} />
                                <h1 className='text-ambed fw-normal'>Personal Details</h1>
                            </div>
                            <hr />
                            <div className='form'>
                                <form>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group">
                                                <label className="form-label" for="exampleFormControlSelect1">Title</label>
                                                <select id="statusDropdown" className="form-control form-select">
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group">
                                                <label className="form-label" for="exampleFormControlSelect1">Country of Residency</label>
                                                <select id="statusDropdown" className="form-control form-select">
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group">
                                                <label className="form-label" for="exampleFormControlSelect1">Country of Nationality</label>
                                                <select id="statusDropdown" className="form-control form-select">
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">First Name</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter First Name" />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Middle Name</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Middle Name" />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Last Name</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Last Name" />
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Previous  Name / AKA</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Previous  Name / AKA" />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Date of Birth</label>
                                                <input type="date" className="form-control" id="exampleInputEmail1" placeholder="Date" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                        <div className='white-background mt-4 p-3'>
                            <div className="form-group">
                                <label className="form-label" for="exampleInputEmail1">Aadhar Number</label>
                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Addhar No." />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className='white-background  mt-4 p-3'>
                            <div className="form-group">
                                <label className="form-label" for="exampleInputEmail1">Pan Number</label>
                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Pan Number" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                        <div className='white-background mt-4 p-3'>
                            <div className="form-group ">
                                <label className="form-label" for="exampleInputEmail1">SSN No.</label>
                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="SSN No.   " />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className='white-background  mt-4 p-3'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-lg-4'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <label className="form-check-label" for="defaultCheck1">
                                            Legal Entity
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-8'>
                                    <div className="form-group">
                                        <label className="form-label" for="exampleInputEmail1">Legal Entity Number</label>
                                        <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Legal Entity Number" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='white-background mt-4 p-3'>
                    <div className="form-check my-3">
                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                        <label className="form-check-label" for="defaultCheck1">
                            Are you a Sole Trader?
                        </label>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12'>
                            <div className="form-group ">
                                <label className="form-label" for="exampleInputEmail1">VAT</label>
                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="VAT " />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div className="form-group ">
                                <label className="form-label" for="exampleInputEmail1">Sandeep Golechha Trading as</label>
                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Trading name" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='white-background p-3 mt-4'>
                            <p className='form-label mb-2'>Social Media links</p>
                            <div className='form'>
                                <form>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Facebook</label>
                                                <input type="text" className="form-control" placeholder="Enter Link" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">LinkedIn</label>
                                                <input type="text" className="form-control" placeholder="Enter Link" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Instagram</label>
                                                <input type="text" className="form-control" placeholder="Enter Link" />
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="form-group mt-3">
                                                <label className="form-label" for="exampleInputEmail1">Twitter</label>
                                                <input type="text" className="form-control" placeholder="Enter Link" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-end my-4'>
                    <div className='d-flex align-items-center'>
                        <button onClick={goBack} className='btn-secondary' style={{ marginRight: 16 }}>Previous</button>
                        <button onClick={routeChange} className='btn-primary'>Save & Next</button>
                    </div>
                </div>

                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header >
                        <h5 className="modal-title text-center text-blue"><b>High Net worth Individual</b></h5>
                        <div onClick={handleClose} className='position-absolute top-0 end-0'>
                            <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="form-group mt-3 p-2">
                                <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Source of Wealth" rows="3" />
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer >
                        <button onClick={handleClose} type="button" className="btn btn-primary w-50 mb-3">Submit</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showModalTwo} onHide={handleCloseModalTwo} centered>
                    <Modal.Header >
                        <h5 className="modal-title text-center text-blue"><b>Politically Exposed Person (PEP)</b></h5>
                        <div onClick={handleCloseModalTwo} className='position-absolute top-0 end-0'>
                            <img src={CancelIcon} style={{ width: 40, objectFit: 'contain', marginTop: 10, marginRight: 10, cursor: 'pointer' }} />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="form-group mt-3 p-2">
                                <textarea type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Source of Wealth" rows="3" />
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer >
                        <button onClick={handleCloseModalTwo} type="button" className="btn btn-primary w-50 mb-3">Submit</button>
                    </Modal.Footer>
                </Modal>

            </div>
        </div>

    )
}

export default WebformPersonalDetails
